<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Zeiterfassungssystem > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="#/time-tracking" selected>Erfasste Zeitwerte</option>
                        <option value="#/time-tracking-absence">Abwesenheiten</option>
                    </select>
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:120px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.user"
                            id="searchUser"
                            style="width:150px;"
                            tabindex="2">
                        <option value="-1">Alle Benutzer</option>
                        <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                    </select>

                    <span app-date-picker
                            [(model)]="searchParameter.dateFrom"
                            [(boundDatepicker)]="searchParameter.dateTo"
                            id="dateFrom"
                            tabindex="102"
                            first="true">
                    </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">


                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="103">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:120px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="DATUM" sortColumn="timestamp"></span>
                        </th>
                        <th style="width:120px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="UHRZEIT" sortColumn="timestamp"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="TYPE"  sortColumn="type"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width:120px;">{{ x.date_string }}</td>

                        <td (click)="edit($event,x)" style="width:120px;">{{ x.time_string }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.type_name }}</td>

                        <td (click)="edit($event,x)">{{ x.name }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="showNewItemForm">Neuen Zeitwert erfassen</span>
                    <span *ngIf="!showNewItemForm && currentSelected.id > 0">Zeitwert bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <table class="form" style="width: 100%;">
                                    <tr>
                                        <td *ngIf="showNewItemForm">
                                            <span class="formCaption">Benutzer *</span><br>
                                            <select [(ngModel)]="currentSelected.fk_core_user"
                                                    id="currentSelectedUser"
                                                    style="width:150px;"
                                                    tabindex="101">
                                                <option value="-1">Bitte wählen</option>
                                                <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <span class="formCaption">Uhrzeit *</span><br>
                                            <input type="time"
                                                   [(ngModel)]="currentSelected.timeString"
                                                   id="currentSelectedTimeString"
                                                   class="cp-textbox"
                                                   style="width:120px; font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;"
                                                   tabindex="102" >
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <span class="formCaption">Datum *</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.dateString"
                                      id="currentSelectedDateString"
                                      tabindex="103">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Kommentar</span>

                                <editor [(ngModel)]="currentSelected.text"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit"
                                >
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Status *</span><br>

                                <select [(ngModel)]="currentSelected.fk_time_tracking_type"
                                        id="currentSelectedState"
                                        style="width: 150px;"
                                        tabindex="104">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataType" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/clock_stop.png"><span style=""> {{messageboxTitle}}</span><BR><BR></div>
    </div>
    <div class="modalContent">{{messageboxContent}}</div>
    <div class="modalFooter form" ><button (click)="closeMessageBox()" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="deleteMessageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/delete.png"><span style="">Zeitwert löschen</span><BR><BR></div>
    </div>
    <div class="modalContent">Sind Sie sicher, dass die diesen Zeitwert unwiderruflich löschen wollen ?</div>
    <div class="modalFooter form" >
        <button (click)="closeDeleteMessageBox()" style="float: right; margin-top: 3px;">Abbrechen</button>
        <button (click)="delete(itemToDelete)" style="float: right; margin-top: 3px;">Löschen</button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="openDeleteMessageBox($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>

