import { Component, OnInit  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';

@Component({
  selector: 'app-time-tracking-user-statement',
  templateUrl: './time-tracking-user-statement.component.html',
  styleUrls: ['./time-tracking-user-statement.component.css']
})
export class TimeTrackingUserStatementComponent implements OnInit {
  public data = [];
  public currentSelected;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataCategory: any[];
  public httpOptions = { };
  public messageBoxContent;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', fk_time_tracking_record : '', fk_time_tracking_state : '',
    fk_time_tracking_error_type : '', errorString : '', timeString : '', dateString: '', text: '', request_timeString: '',
    request_dateString: ''};
  }

  initMessageBoxContent(): void {
      this.messageBoxContent = { title: '', text: '', image: 'information.png'};
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    this.initCurrentSelected();
    this.setRightContainerWidth(40);
    this.initMessageBoxContent();
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.http.post<any[]>(settings.restBaseUrl + 'template/category/suggest', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategory = data;
        }
    );
  }

  ngOnInit() {
    this.search();
    setTimeout(() => {
      this.edit(this.data[0]);
    }, 300);
  }


  search(): void {
    this.http.post<any[]>(this.settings.restBaseUrl + 'timeTracking/userResponse', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data;
        }
    );
  }

  openMessageBox(): void {
      this.message.open('messageBox');
  }

  closeMessageBox(): void {
      this.message.close('messageBox');
      this.messageBoxContent.title = '';
      this.messageBoxContent.text = '';
  }

  save(): void {
      if (this.currentSelected.request_dateString === '') {
          this.messageBoxContent.title = 'Datum fehlt !';
          this.messageBoxContent.text = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie ein Datum an.';
          this.openMessageBox();
          return;
      } else if (this.currentSelected.request_timeString === '') {
          this.messageBoxContent.title = 'Uhrzeit fehlt !';
          this.messageBoxContent.text = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie eine Uhrzeit an.';
          this.openMessageBox();
          return;
      } else if (this.currentSelected.text === '') {
          this.messageBoxContent.title = 'Begründung fehlt !';
          this.messageBoxContent.text = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie eine Begründung an.';
          this.openMessageBox();
          return;
      }

      const responseData = {
          responseId: this.currentSelected.id,
          text: this.currentSelected.text,
          timeString: this.currentSelected.request_timeString,
          dateString: this.currentSelected.request_dateString
      };
      this.http.put<any[]>(
        this.settings.restBaseUrl + 'timeTracking/error/' + this.currentSelected.id,
        responseData,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search();
          this.initCurrentSelected();
          setTimeout(() => {
            if (this.data.length === 1) { this.edit(this.data[0]); }
          }, 300);
        }
      );
  }

  edit(item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'timeTracking/userResponse/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.currentSelected.request_dateString = this.currentSelected.recordDateString;
          this.currentSelected.request_timeString = this.currentSelected.recordTimeString;
          setTimeout(() => {
            document.getElementById('currentSelectedTimeString').focus();
          }, 300);
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }
}
