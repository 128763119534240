<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>
        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    ERP > Belegportal > Buchungstexte

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:120px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>


                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>



                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Buchungstext" sortColumn="name"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)">{{ x.name }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>


        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">


                    <span *ngIf="showNewItemForm || currentSelected.id>0">
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">
                            <span *ngIf="showNewItemForm">Neuen Buchungstext anlegen</span>
                            <span *ngIf="currentSelected.id>0">Buchungstext bearbeiten</span>
                        </button>
                    </span>

                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="( showNewItemForm || currentSelected.id>0) && currentSelectedTab == 1" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Buchungstext *</span>
                                <input [(ngModel)]="currentSelected.name"
                                       [ngClass]="{ 'error' : currentSelected.name == '' && formError }"
                                       type="text"
                                       id="currentSelectedName"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>


            </div>
        </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="deleteBookingtext" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Warnung
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
        Sind sie sicher, dass sie den Buchungstext wirklich unwiderruflich Löschen wollen?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="delete();" style="margin-top: 3px;margin-left: 3px; float: right;">
            Ja
        </button>
        <button (click)="message.close('deleteBookingtext');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Abbrechen
        </button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem let-item (execute)="openDeleteDialog($event.value)">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>
