<div id="leftContainerDefinition" class="header3Row footer1Row" style="margin-right:4px;">
    <div id="leftContainerTop">
                <span class="currentLocation">
                    Vorgangsmanagement > Zeitleiste

                </span>
        <span class="form search">
                    <select id="fk_user"
                            tabindex="102"
                            [(ngModel)]="searchParameter.fk_user">
                        <option value="-1">Alle Benutzer</option>
                    </select>

                    <span app-date-picker
                          [(model)]="searchParameter.searchDate"
                          id="currentSelectedTimestamp"
                          tabindex="102">
                    </span>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:1px;"
                            tabindex="103">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="104">
                        <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                    </button>
                </span>
    </div>

    <div id="leftContainerContent">
        >>{{viewMinTimestamp}}-{{viewMaxTimestamp}}<<
<table style="width:100%" >
    <tr *ngFor="let x of user">
        <td>
            <table style="width:100%" border="1">
                <tr>
                    <ng-container *ngFor="let y of data; let i = index">
                        <ng-container *ngIf="y.assigned == x">
                            <td style="width:{{viewRangePercent * y.time_duration}}%">
                            {{y.tid}}
                            </td>
                            <td style="width:{{viewRangePercent * (data[i+1].send_time - (y.send_time + y.time_duration))}}%">

                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </table>
        </td>
    </tr>
</table>

    </div>

    <div id="leftContainerBottom">

    </div>
</div>
