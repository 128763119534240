import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';

@Component({
  selector: '[app-resize-iframe]',
  templateUrl: './resize-iframe.component.html',
  styleUrls: ['./resize-iframe.component.css']
})
export class ResizeIframeComponent implements OnInit, OnChanges {


  // MODEL
  public modelValue = '';
  @Output() modelChange: EventEmitter<string>;
  public overlayId: string;
  private iFrame = null;

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value + '<style> body {font-family: Verdana, Arial, Helvetica, Geneva, sans-serif; font-size: 13px; padding:0; margin:0; height:unset !important;} img { max-width:100%; height:unset !important;} html { height:unset !important;} </style>');
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.modelValue = value;
  }

  constructor() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.modelChange = new EventEmitter();
  }

  changeModel(value): void {
    this.modelValue = value ;
    this.modelChange.emit(this.modelValue);
    this.resizeIframe();
  }
  // ENDE MODEL

  checkLoadDone() {
    const readyStateCheckInterval = setInterval(() => {
      if (document.readyState === 'complete') {
        if(this.iFrame==null) { this.iFrame = document.getElementById('iframe' + this.overlayId) as HTMLIFrameElement; }
        if( this.iFrame !== null && this.iFrame.contentWindow && this.iFrame.contentWindow.document && this.iFrame.contentWindow.document.body != null && this.iFrame.contentWindow.document.readyState === 'complete') {
            clearInterval(readyStateCheckInterval);
            setTimeout(() => {
              this.resizeIframe();
            }, 600);
            const resizeObserver = new ResizeObserver(entries =>
                this.resizeIframe()
            )
            resizeObserver.observe(this.iFrame.contentWindow.document.body)
       }
      }
    }, 200);
  }

  resizeIframe() {
    // Größe dem Inhalt anpassen
    let height
    if(this.iFrame && this.iFrame.contentWindow && this.iFrame.contentWindow.document.documentElement) {
      height = this.iFrame.contentWindow.document.documentElement.offsetHeight;
    } else {
      setTimeout(() => {
        this.resizeIframe();
      }, 600);
      return;
    }

    height += 20; // Bissel mehr Platz nach unten.
    // Andere mögliche Höhen: body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight;
    if(height<100) {
      this.iFrame.style.height = 100 + '%';
    } else {
      this.iFrame.style.height = height + 'px';
    }

  }

  ngOnInit(): void {
   this.checkLoadDone();
  }


}
