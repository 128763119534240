import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/_modal';

@Component({
  selector: 'app-core-user-profile-management',
  templateUrl: './core-user-profile-management.component.html',
  styleUrls: ['./core-user-profile-management.component.css']
})
export class CoreUserProfileManagementComponent implements OnInit {

  public data = [];
  public currentSelected;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser = [];
  public httpOptions = { };
  public currentSelectedSignaturTab = 1;
  public salutations = [];
  public userProjects = [];
  public possibleSubstituteUsers = [{ id: 0, fk_user: 0, substitution_fk_user: 0, priority: 0, name: '' }];
  public activeSubstituteUserProcess = 0;
  public selectedSubstituteUserId = 0;
  public newPassword1: any;
  public newPassword2: any;
  public newPasswordCheck = { length : false, special: false, upper: false, lower: false, digits: false, same: false };
  public nickExists = false;
  public selectedTextblock;
  public textblocksData;
  public textBlockToDelete;
  public showDocumentOption = false;
  public showProcessOption = false;
  public showErpOption = false;
  public showInventoryOption = false;
  public dropdownDataEntrypointMenu;
  public trackedUserTimeValues;
  public searchParameter;
  public vacation;
  public vacationDropdownData;
  public editVacationRequest = false;
  public vacationRequestId;
  public vacationEnded = false;
  public requestDataMissing;
  public demandedWorkTimeThisMonth;
  public workedTimeThisMonth;
  public timeTrackingModuleActiveState;
  public showVacation = false;
  public vacationRequestData;
  public requestChangeEntryItem;
  public dropdownDataState;
  public dropdownErrorType;
  public currentSetting = 'base';
  public absenceData: any[];
  public selectedAbsence: any;
  public modelErrorMessage: any;
  public signatureData: any;
  public selectedSignature: any;
  public signaturePart: number;
  public signatureAssignedData = undefined;
  public activeSubstituteUserDocument = 0;

  initSelectedSignature(): void {
      this.selectedSignature = { id : null, name : '', header : '', footer : ''};
  }

  initSelectedAbsence(): void {
      this.selectedAbsence = {text:'', id:'', timestampStringFrom:'', timestampStringTo:''};
  }

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', first_name : '', nick : '', salutation : '', titel: '', active: '', company: '', position: '', profession: '', search_number: '', birth_date: '', autocreate: '', startPage: '-1', header: '', footer: ''};
  }

  initSearchParameter(): void {
      this.searchParameter = { user: -2, dateFrom: '', dateTo: '', sort: 'timestamp'};
  }

  initVacationData(): void {
      this.vacation = { id: '', type: '-1', dateFrom: '', dateTo: '', text: '' };
  }

  initRequestDataMissing(): void {
      this.requestDataMissing = { type: false, dateFrom: false, dateTo: false };
  }

  initRequestChangeEntryItem(): void {
      this.requestChangeEntryItem = { id : '', dateString: '', timeString: '', state: '', text: '', errorString: '', fk_time_tracking_error_type: '0', request_timeString: '', request_dateString: '', request_state: '0'};
  }

  constructor(public http: HttpClient, public settings: SettingsService, public route: ActivatedRoute, public message: ModalService) {
    this.absenceData = [];
    this.initCurrentSelected();
    this.initSearchParameter();
    this.setRightContainerWidth(45);
    this.initVacationData();
    this.initRequestDataMissing();
    this.initRequestChangeEntryItem();
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSelectedAbsence();
    this.getAbsenceData();

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'contact/salutation', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.salutations = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'timeTracking/state', {}, this.httpOptions)
      .pipe(map(data => data)).subscribe(
      data => {
          this.dropdownDataState = data;
      }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'timeTracking/error/type', {}, this.httpOptions)
      .pipe(map(data => data)).subscribe(
      data => {
          this.dropdownErrorType = data;
      }
    );
  }

  ngOnInit() {
    this.getProfileData();
    this.getTextblocks();
    this.getUserRightsDropdownData();
    this.getTrackedTimeValues();
    this.getVacationDropdownData();
    this.getThisMonthWorktime();
    this.checkActiveTimeTrackingModule();
    this.getVacationRequestData();
    this.getAbsenceData();
    this.getSignatureData();
  }

  getProfileData() {
    this.http.get<any[]>(this.settings.restBaseUrl + 'user/profile', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.getUserProjects();
          this.getPossibleUserSubstitution();
          this.getActiveSubstituteUser();
          this.getDropdownUserData(this.currentSelected.company);
        }
    );
  }

  checkActiveTimeTrackingModule() {
      this.http.get<any[]>(this.settings.restBaseUrl + 'module/17', this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                // @ts-ignore
              this.timeTrackingModuleActiveState = data.active;
              if (this.timeTrackingModuleActiveState === '1') {
                  this.currentSelectedSignaturTab = 1; } else { this.currentSelectedSignaturTab = 3; }
          }
      );
  }


  getUserRightsDropdownData() {
      this.http.post<{status, data, count}>(this.settings.restBaseUrl + 'user/entrypoint', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                data.data.forEach((item) => {
                    // tslint:disable-next-line:triple-equals
                    if (item.id == 4) { this.showDocumentOption = true;
                        // tslint:disable-next-line:triple-equals
                    } else if (item.id == 6) { this.showProcessOption = true;
                        // tslint:disable-next-line:triple-equals
                    } else if (item.id == 14) { this.showErpOption = true;
                    // tslint:disable-next-line:triple-equals
                    } else if (item.id == 12) { this.showInventoryOption = true; }
                    this.dropdownDataEntrypointMenu = [{ id: '1', name: 'Vorgangsmanagement > Eigene', showOption: this.showProcessOption },
                                                       { id: '11', name: 'Vorgangsmanagement > Team', showOption: this.showProcessOption },
                                                       { id: '31', name: 'Dokumente > Suche', showOption: this.showDocumentOption },
                                                       { id: '3', name: 'Dokumente > Freigabe', showOption: this.showDocumentOption },
                                                       { id: '21', name: 'CRM > Eingangsbelege', showOption: this.showErpOption },
                                                       { id: '22', name: 'CRM > Ausgangsbelege', showOption: this.showErpOption },
                                                       { id: '2', name: 'ERP > Warenwirtschaft > Ausgangsbelege', showOption: this.showErpOption },
                                                       { id: '4', name: 'Inventarverwaltung > Anstehende Prüfungen', showOption: this.showInventoryOption }];
                    if (this.currentSelected.startPage === '' || this.currentSelected.startPage == null) {
                        this.currentSelected.startPage = '-1';
                    }
                });
          }
      );
  }

  initTextBlock() {
      this.selectedTextblock = { id: 0 , name: '' , text: '' };
  }

    newTextBlock() {
        this.initTextBlock();
        this.selectedTextblock.id = 0;
        this.selectedTextblock.name = 'Name Vergeben';
        this.selectedTextblock.text = 'Neuer Textblock Text';
    }

    editTextblock($event: MouseEvent, item: any) {
        this.http.get<any[]>(this.settings.restBaseUrl + 'textblock/' + item.id, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedTextblock = data;
            }
        );
    }

    getTextblocks() {
        this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'textblock', { currentUser: true }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.textblocksData = data.data;
                this.selectedTextblock = data.data[0];
            }
        );
    }

    saveTextBlock() {
        this.selectedTextblock.currentUser = true;
        this.http.put<any[]>(this.settings.restBaseUrl + 'textblock', JSON.stringify(this.selectedTextblock), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if ( this.selectedTextblock.id < 1) {
                    this.getTextblocks();
                } else {
                    for (const item of this.textblocksData) {
                        if (item.id === this.selectedTextblock.id) {
                            item.name = this.selectedTextblock.name;
                            item.text = this.selectedTextblock.text;
                        }
                    }
                }
                this.settings.initSettings();
            }
        );
    }

    deleteTextBlock(id) {
      if (this.textBlockToDelete > 0) {
          this.http.delete<{status, message}>(this.settings.restBaseUrl + 'textblock/' + this.textBlockToDelete,  this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.textBlockToDelete = 0;
                  this.message.close('deleteTextBlock');
                  this.getTextblocks();
                  this.settings.initSettings();
              }
          );
      } else {
          this.textBlockToDelete = id;
          this.message.open('deleteTextBlock');
      }
    }

    checkPassword() {
        if (this.newPassword1.length >= 8) { this.newPasswordCheck.length = true; } else { this.newPasswordCheck.length = false; }
        if (this.newPassword1.replace(/[^\W]/g, '').length >= 1) { this.newPasswordCheck.special = true; } else { this.newPasswordCheck.special = false; }
        if (this.newPassword1.replace(/[^A-Z]/g, '').length >= 1) { this.newPasswordCheck.upper = true; } else { this.newPasswordCheck.upper = false; }
        if (this.newPassword1.replace(/[^a-z]/g, '').length >= 1) { this.newPasswordCheck.lower = true; } else { this.newPasswordCheck.lower = false; }
        if (this.newPassword1.replace(/\D/g, '').length >= 2) { this.newPasswordCheck.digits = true; } else { this.newPasswordCheck.digits = false; }
        if (this.newPassword1 === this.newPassword2) { this.newPasswordCheck.same = true; } else { this.newPasswordCheck.same = false; }
    }

    changePassword() {
        if (!(this.newPasswordCheck.length && this.newPasswordCheck.special && this.newPasswordCheck.upper && this.newPasswordCheck.lower && this.newPasswordCheck.digits && this.newPasswordCheck.same)) {
            alert('Das Passwort entspreicht nicht den Komplexätitsregeln. Es müssen alle Punkte grün Markiert sein!');
            return;
        } else {
            this.http.put<{ status: string, message: string }>(this.settings.restBaseUrl + 'user/passwordChange/' + this.currentSelected.id , { type: 'coreUserManager', password: this.newPassword1 }, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                (data => {
                    if (data.status === 'success') {
                        alert('Passwort erfolgreich geändert.');
                        this.newPassword1 = '';
                        this.newPassword2 = '';
                        this.newPasswordCheck.length = false;
                        this.newPasswordCheck.special = false;
                        this.newPasswordCheck.upper = false;
                        this.newPasswordCheck.lower = false;
                        this.newPasswordCheck.digits = false;
                        this.newPasswordCheck.same = false;
                    } else {
                        alert('Passwortänderung Fehlgeschlagen.');
                    }
                    }
                ));
        }
    }

    getTrackedTimeValues() {
        this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'timeTracking', JSON.stringify(this.searchParameter), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                    this.trackedUserTimeValues = data.data;
            }
        );
    }

    getVacationRequestData() {
        this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'timeTracking/absence',
            { dateFrom: this.searchParameter.dateFrom, dateTo: this.searchParameter.dateTo, request: 1,
              requires_approval: 1, processed: '-1', type: '-1', sort: 'start_timestamp' },
            this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.vacationRequestData = data.data;
            }
        );
    }

    getThisMonthWorktime() {
        this.http.get<any>(this.settings.restBaseUrl + 'timeTracking/worktime/month', this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                    this.demandedWorkTimeThisMonth = data.demandedWorktime;
                    this.workedTimeThisMonth = data.workedTime;
            });
    }

  resetTimeTrackingSearch() {
    this.initSearchParameter();
    if (!this.showVacation) {
        this.getTrackedTimeValues();
    } else {
        this.getVacationRequestData();
    }
  }

  putVacationRequest() {
      // tslint:disable-next-line:triple-equals
      if (this.vacation.type == '-1') {
          this.requestDataMissing.type = true;
          return;
      } else {
          this.requestDataMissing.type = false;
      }
      if (this.vacation.dateFrom === '') {
          this.requestDataMissing.dateFrom = true;
          return;
      } else {
          this.requestDataMissing.dateFrom = false;
      }
      if (this.vacation.dateTo === '') {
          this.requestDataMissing.dateTo = true;
          return;
      } else {
          this.requestDataMissing.dateTo = false;
      }

      let putData = {};
      if (this.editVacationRequest) {
          putData = {
              id: this.vacation.id,
              request: 1,
              type: this.vacation.type,
              dateFrom: this.vacation.dateFrom,
              dateTo: this.vacation.dateTo,
              text: this.vacation.text
          };
      } else {
          putData = {
              request: 1,
              type: this.vacation.type,
              dateFrom: this.vacation.dateFrom,
              dateTo: this.vacation.dateTo,
              text: this.vacation.text
          };
      }

      this.http.put<any>(this.settings.restBaseUrl + 'timeTracking/absence', putData, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.editVacationRequest = false;
              this.message.close('vacationMessageBox');
              this.getVacationRequestData();
          });
  }

  editVacationRequestData(item) {
      this.http.get<any>(this.settings.restBaseUrl + 'timeTracking/absence/' + item.id, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.vacation = data;
              this.editVacationRequest = true;
              this.message.open('vacationMessageBox');
          });
  }

  getVacationDropdownData() {
      this.vacationDropdownData = [];
      this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'timeTracking/absence/type', {requires_approval: 1}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                    this.vacationDropdownData = data;
              });
  }

  openVacationRequestModal() {
      this.initVacationData();
      this.message.open('vacationMessageBox');
  }

  openDeleteWarningModal(item) {
      this.vacationRequestId = item.id;
      this.message.open('deleteMessage');
  }

  deleteVacationRequest() {
      this.http.delete<{status, message}>(this.settings.restBaseUrl + 'timeTracking/absence/' + this.vacationRequestId, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if (data.status === 'error') {
                  this.vacationEnded = true;
              } else {
                  this.message.close('deleteMessage');
                  this.vacationEnded = false;
              }

          }
      );
  }

  closeDeleteMessageModal() {
      this.message.close('deleteMessage');
      this.vacationEnded = false;
  }

  getDropdownUserData(id) {
    this.dropdownDataUser = [];
    this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', {active: 1, company: id, sort: 'name'}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const interimUserArray = data.data;
          interimUserArray.forEach((element) => {
            if (element.id !== this.currentSelected.id) {
              this.dropdownDataUser.push(element);
            }
          });
        }
    );
  }

  getUserProjects() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'user/project/' + this.currentSelected.id, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.userProjects = data;
        }
    );
  }

  getPossibleUserSubstitution() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id , {},  this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.possibleSubstituteUsers = data;
        }
    );
  }

  getActiveSubstituteUser() {
    this.activeSubstituteUserProcess = 0;
    this.activeSubstituteUserDocument = 0;
    this.http.get<any>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            data.forEach((item) => {
                if (item.module === '1') {
                    this.activeSubstituteUserProcess = item.substitution_fk_user;

                } else if (item.module === '2') {
                    this.activeSubstituteUserDocument = item.substitution_fk_user;
                }
                return;
            });
        }
    );
  }

  putSelectedSubstituteUserAsPossible() {
    if (this.selectedSubstituteUserId < 1) {
      alert('Wählen Sie einen Kontakt aus um diesen als Vertretung hinzuzufügen.');
      return;
    } else {
      this.possibleSubstituteUsers.forEach((item) => {
        if (item.substitution_fk_user === this.selectedSubstituteUserId) {
          alert('Der Kontakt befindet sich Bereits in der Vertretungsliste für diesen Benutzer.');
          return;
        }
      });
    }


    if (this.selectedSubstituteUserId > 0) {
      this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution/possible/' + this.selectedSubstituteUserId + '/' + this.currentSelected.id ,  {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getPossibleUserSubstitution();
          });
    }
  }

  setSubstituteUser(item, module) {
    const substituteUserData = {
      fk_user: this.currentSelected.id,
      substitution_fk_user: item.substitution_fk_user,
      module
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution' , substituteUserData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.getPossibleUserSubstitution();
          this.getActiveSubstituteUser();
        }
    );
  }

  unsetSubstituteUser(module) {
    this.http.delete<any[]>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id + '/' + module, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.getPossibleUserSubstitution();
          this.getActiveSubstituteUser();
        }
    );
  }

  deleteSubstituteUser(item) {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(
          this.settings.restBaseUrl + 'user/substitution/possible/' + this.currentSelected.id + '/' + item.substitution_fk_user, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getPossibleUserSubstitution();
          });
    }
  }

  increaseSubstituteUserPriority(item) {
    item.priority--;
    const increasingItem = this.possibleSubstituteUsers.findIndex(i => i.fk_user === item.fk_user && i.substitution_fk_user === item.substitution_fk_user);

    this.possibleSubstituteUsers[increasingItem - 1].priority++;
    this.putSubstituteUserPriority();
  }

  decreaseSubstituteUserPriority(item) {
    item.priority++;
    const decreasingItem = this.possibleSubstituteUsers.findIndex(i => i.fk_user === item.fk_user && i.substitution_fk_user === item.substitution_fk_user);

    this.possibleSubstituteUsers[decreasingItem + 1].priority--;
    this.putSubstituteUserPriority();
  }

  putSubstituteUserPriority() {
    this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution/priority/' + this.possibleSubstituteUsers[0].priority + '/' + this.possibleSubstituteUsers[0].id , {},  this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.possibleSubstituteUsers.shift();
          if (this.possibleSubstituteUsers.length > 0) {
            this.putSubstituteUserPriority();
          } else {
            this.getPossibleUserSubstitution();
          }
        });
  }

  save(): void {
    if (this.nickExists) {
        alert('Der gewählte Anmeldename ist bereits vorhanden.\nBitte wählen Sie einen anderen Anmeldenamen.');
        return;
    }

    let saveMobileNumber = 0;

    if(this.currentSelected.mobileNumber != undefined){
    if(this.currentSelected.mobileNumber != '' && this.currentSelected.mobileNumber.indexOf("0049") != 0){saveMobileNumber = 1;};
    if(isNaN(this.currentSelected.mobileNumber) === true){saveMobileNumber = 1;}
    }


    if(saveMobileNumber === 0) {
        this.currentSelected.userData = 1;
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'contact/' + this.currentSelected.id,
            JSON.stringify(this.currentSelected),
            this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data1 => {
                this.http.put<any[]>(
                    this.settings.restBaseUrl + 'user/' + this.currentSelected.id, {
                        company: this.currentSelected.company,
                        nick: this.currentSelected.nick,
                        startPage: this.currentSelected.startPage,
                        mobileNumber: this.currentSelected.mobileNumber
                    }, this.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data2 => {
                        alert('Kontoinformationen gespeichert');
                    }
                );
            }
        );
    } else{alert('Die Handynummer mit Ländervorwahl und rein numerisch eingeben (0049xxxxxxx)');}


  }

  checkNickExists() {
      this.http.post<{status, message}>(this.settings.restBaseUrl + 'user/nickCheck' , { nick: this.currentSelected.nick } , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if (data.message > 0 && data.message !== this.currentSelected.id) { this.nickExists = true; } else { this.nickExists = false; }
          }
      );
  }

  openRequestChangeModal(item): void {
      this.requestChangeEntryItem = item;
      this.requestChangeEntryItem.id = item.id;
      this.requestChangeEntryItem.request_dateString = item.dateString;
      this.requestChangeEntryItem.request_state = item.fk_time_tracking_state;
      this.requestChangeEntryItem.fk_time_tracking_error_type = '0';
      this.message.open('requestChangeMessage');
  }

    saveSignature(): void {
    this.http.put<any[]>(this.settings.restBaseUrl + 'user/signature' ,
        JSON.stringify(this.selectedSignature),
        this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.selectedSignature = undefined;
                    this.settings.initSettings();
                    this.getSignatureData();
                }
            );
  }

  putChangeRequest(): void {
      if (this.requestChangeEntryItem.request_dateString === '') {
          this.requestChangeEntryItem.errorString = 'Geben Sie ein Datum an !';
          return;
      } else if (this.requestChangeEntryItem.request_timeString === '') {
          this.requestChangeEntryItem.errorString = 'Geben Sie eine Uhrzeit an !';
          return;
      } else if (this.requestChangeEntryItem.request_state === '0') {
          this.requestChangeEntryItem.errorString = 'Wählen Sie einen Status aus !';
          return;
      } else if (this.requestChangeEntryItem.fk_time_tracking_error_type === '0') {
          this.requestChangeEntryItem.errorString = 'Wählen Sie einen Fehlertyp aus !';
          return;
      } else if (this.requestChangeEntryItem.text === '') {
          this.requestChangeEntryItem.errorString = 'Geben Sie ein Statement ab !';
          return;
      } else {
          this.requestChangeEntryItem.errorString = '';
      }

      this.http.put<{status, message}>(
          this.settings.restBaseUrl + 'timeTracking/error/' + this.requestChangeEntryItem.id,
          { type: this.requestChangeEntryItem.fk_time_tracking_error_type }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              const responseData = {
                  responseId: data.message,
                  text: this.requestChangeEntryItem.text,
                  timeString: this.requestChangeEntryItem.request_timeString,
                  dateString: this.requestChangeEntryItem.request_dateString
              };
              this.http.put<any[]>(
                  this.settings.restBaseUrl + 'timeTracking/error' , responseData, this.httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  data2 => {
                      setTimeout(() => {
                          this.closeRequestChangeModal();
                      }, 300);
                  });
          });
  }

  closeRequestChangeModal(): void {
      this.message.close('requestChangeMessage');
      this.initRequestChangeEntryItem();
  }

  toggleShowVacation() {
      this.showVacation = !this.showVacation;
  }


  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }


    changeCurrentSetting(setting: string) {
        this.currentSetting = setting;
    }

    private getAbsenceData() {
        this.http.post<any[]>(this.settings.restBaseUrl + 'user/absence' , {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedAbsence.text = data[0].text;

                if(data[0].timestampStringFrom != '01.01.1970'){
                this.selectedAbsence.timestampStringFrom = data[0].timestampStringFrom;} else{ this.selectedAbsence.timestampStringFrom = '';}
                if(data[0].timestampStringTo != '01.01.1970'){
                this.selectedAbsence.timestampStringTo = data[0].timestampStringTo;}else{ this.selectedAbsence.timestampStringTo = '';}

            }
        );
    }

    editAbsence($event: MouseEvent, x) {
        this.selectedAbsence = x;
    }

    toggleAbsence($event: MouseEvent, x) {
        if(x.text === '') {
            this.modelErrorMessage = 'Sie müssen erst einen Text hinterlegen, <BR>bevor sie die Abwesenheit für dieses Team Aktivieren können!';
            this.message.open('errorMessage');
            return;
        }
        let setTo = '1';
        if(x.active === '1') { setTo = '0';}
        const postData = {
            id: x.id,
            active: setTo
        }
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'user/team/absence/active', postData, this.httpOptions)
            .pipe(map(data2 => data2)).subscribe(
            data2 => {
                x.active = setTo;
            });
    }

    saveAbsence(active) {
      if(active == '1'){
          if(this.selectedAbsence.timestampStringFrom != '' && this.selectedAbsence.timestampStringTo != '' && this.selectedAbsence.text != ''){
        this.http.put<{status, message}>(
            this.settings.restBaseUrl + 'user/absence', this.selectedAbsence, this.httpOptions)
            .pipe(map(data2 => data2)).subscribe(
            data2 => {
                if(data2.message > 0) {
                    this.selectedAbsence.id = data2.message;
                }
            });}  else{  this.modelErrorMessage = 'Alle Felder müssen ausgefüllt sein um eine Abwesenheitsbenachrichtigung einzurichten.';
          this.message.open('errorMessage');
          return;}
          }
        if(active == '0'){
            this.selectedAbsence.timestampStringFrom = 0;
            this.selectedAbsence.timestampStringTo = 0;

            this.http.put<{status, message}>(
                this.settings.restBaseUrl + 'user/absence', this.selectedAbsence, this.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                    if(data2.message > 0) {
                        this.selectedAbsence.id = data2.message;
                    }
                });
        this.getAbsenceData();
        }
    }

    editSignature($event: MouseEvent, x) {
        this.selectedSignature = x;
        this.loadSignatureAssignedData();
    }

    private getSignatureData() {
        this.http.post<any[]>(this.settings.restBaseUrl + 'user/signature' , {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.signatureData = data;
            }
        );
    }

    newSignatur() {
      this.initSelectedSignature();
    }


    setBaseSignature(x) {
        if(x.base === '1') { return; }
        this.http.put<any[]>(this.settings.restBaseUrl + 'user/signature/base/' + x.id , {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.settings.initSettings();
                this.getSignatureData();
            }
        );
    }

    changeTabButton(tabNumber: number) {
        this.currentSelectedSignaturTab = tabNumber;
        if(tabNumber === 2 && this.signatureAssignedData === undefined ) {
            // Load data
            this.loadSignatureAssignedData();
        }
    }

    loadSignatureAssignedData() {
        this.http.post<any[]>(this.settings.restBaseUrl + 'user/signature/contact' , { id:  this.selectedSignature.id}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.signatureAssignedData = data;
            }
        );
    }

    deleteSignatureAssigned(x) {
        this.http.delete<any[]>(this.settings.restBaseUrl + 'user/signature/contact/' + x.id , this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.loadSignatureAssignedData();
            }
        );
    }

}
