import { Component, ViewChild } from '@angular/core';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService} from './services/settings.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import {Location} from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Company Plus';
  public showTopBar: any;
  public httpOptions;

  constructor(private location: Location,
              public http: HttpClient,
              public router: Router,
              public settings: SettingsService) {

    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.showTopBar = false;
    // Umleiten zum startpunkt des Users wenn neu eingeloggt
    if(this.location.path() === '/whistleblower-report' ||  this.location.path() === '/whistleblower'){
      this.showTopBar = false;
    }
    else if (settings.sessionId !== '' && this.location.path() === '/login' && this.location.path() != '/whistleblower-report') {
      this.showTopBar = true;
    } else if (settings.sessionId !== '' && this.location.path() !== '/login' && this.location.path() != '/whistleblower-report') {
      this.showTopBar = true;
      this.router.navigate([decodeURIComponent(this.location.path())]);
    } else {
      this.router.navigate(['/login']);
    }

  }
}
