<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header2Row footerNone" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Zeiterfassungssystem > Abweichungen begründen
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:80px;">
                            DATUM
                        </th>
                        <th style="width:80px;">
                            UHRZEIT
                        </th>
                        <th style="width:150px;">
                            FEHLERTYP
                        </th>
                        <th>
                            FEHLERMELDUNG
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td (click)="edit(x)" style="width:80px;">&nbsp;{{ x.recordDateString }}</td>

                        <td (click)="edit(x)" style="width:80px;">&nbsp;{{ x.recordTimeString }}&nbsp;Uhr</td>

                        <td (click)="edit(x)" style="width:150px;">&nbsp;{{ x.errorNameString }}</td>

                        <td (click)="edit(x)">&nbsp;{{ x.errorString }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="currentSelected.id>0">
                        Begründung verfassen
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Datum*</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.request_dateString"
                                      id="currentSelectedDateString"
                                      tabindex="102">
                                </span>
                            </td>

                            <td>
                                <span class="formCaption">Uhrzeit*</span><br>
                                <input type="time"
                                       [(ngModel)]="currentSelected.request_timeString"
                                       id="currentSelectedTimeString"
                                       class="cp-textbox"
                                       style="width:120px; font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;"
                                       tabindex="101" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Begründung*</span>

                                <editor [(ngModel)]="currentSelected.text"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit"
                                >
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/{{messageBoxContent.image}}"><span style=""> {{messageBoxContent.title}}</span><BR><BR></div>
    </div>
    <div class="modalContent">{{messageBoxContent.text}}</div>
    <div class="modalFooter form" ><button (click)="closeMessageBox()" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>
