<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footer1Row" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Inventar > Hardware > Druckerabrechnung
                </span>

        <span class="form search">

        <input type="text"
               (keydown)="searchOnReturnPressed($event)"
               [(ngModel)]="searchParameter.text"
               id="searchText"
               style="width:256px;"
               tabindex="1"
               [ngClass]="{ 'error' : searchParameter.text != '' }">

    <select  [(ngModel)]="searchParameter.bill_cleared" (change)="search(false)">
        <option [value]="0">
            Nicht Abgerechnete
        </option>
        <option *ngFor="let x of dropdownPrinterDate" [value]="x.bill_cleared">
            {{x.datename}}
        </option>
    </select>


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>



                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                     <button (click)="clearBill()"
                             style="float:right; margin-right:8px;"
                             tabindex="6">
                        <img src="../design/icons/add.png"> Abrechenen
                    </button>

        </span>

                <table style="width: 100%;" class="data_tabel" cellpadding="6" >
                    <tr>
                        <th style="width: 24%">Typ</th>
                        <th style="width: 24%">Name</th>
                        <th style="width: 23%">Standort</th>
                        <th style="width: 23%">Zimmer</th>
                        <th style="width: 6%">Seiten</th>
                        <th style="width: 7px"></th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">

                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>



                <div class="loading" *ngIf="false"></div>
                <table style="width: 100%;" class="data_tabel" cellpadding="6" >
                    <tr *ngFor="let x of data">
                        <td style="width: 24%">{{x.typename}}</td>
                        <td style="width: 24%">{{x.name}}</td>
                        <td style="width: 23%">{{x.site_short_name}}</td>
                        <td style="width: 23%">{{x.location}}</td>
                        <td [ngClass]="{'error' : x.div_page_count==0}"
                            style="width: 6%">{{x.div_page_count}}</td>
                    </tr>
                </table>
            </div>

            <div id="leftContainerBottom">

            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">

                </div>
            </div>

            <div id="rightContainerContent">
                <table cellpadding="6" style="  width: 100%; " class="data_tabel">
                    <tr>
                        <th style="width: 130px"></th>
                        <th style="width: 33%">Laser Schwarz Weiß</th>
                        <th style="width: 33%">Laser Farbe</th>
                        <th style="width: 33%">Tinte</th>
                    </tr>
                    <tr>
                        <th>Gartenstadt({{gartenstadtCount}})</th>
                        <td> {{gartenstadtLaserSW}}</td>
                        <td> {{gartenstadtLaserFarbe}}</td>
                        <td> {{gartenstadtTinte}}</td>
                    </tr>
                    <tr>
                        <th>Altrip({{altripCount}})</th>
                        <td> {{altripLaserSw}}</td>
                        <td> {{altripLaserFrabe}}</td>
                        <td> {{altripTinte}}</td>
                    </tr>
                    <tr>
                        <th>Römerberg({{roemerbergCount}})</th>
                        <td> {{roemerbergLaserSW}}</td>
                        <td> {{roemerbergLaserFrabe}}</td>
                        <td> {{roemerbergTinte}}</td>
                    </tr>
                    <tr>
                        <th>Zentralverwaltung({{zentralverwaltungCount}})</th>
                        <td> {{zentralverwaltungLaserSW}}</td>
                        <td> {{zentralverwaltungLaserFarbe}}</td>
                        <td> {{zentralverwaltungTinte}}</td>
                    </tr>
                </table>


            </div>
        </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="editorQuestion" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="billclearExecute()" style="margin-top: 3px;margin-left: 3px; float: right;">
            Weiter
        </button>
        <button (click)="message.close('editorQuestion');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Abbrechen
        </button>
    </div>
</jw-modal>
