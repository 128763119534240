import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-core-help',
  templateUrl: './core-help.component.html',
  styleUrls: ['./core-help.component.css']
})
export class CoreHelpComponent implements OnInit {
  public helpUrl: string;
  public showChangeLog = false;
  public data = [];
  public httpOptions: { headers: any };
  public rightContainerWidth;
  public leftContainerWidth;
  public editorWarningString = '';
  // Suchleiste
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;

  public currentSelected = null;
  public showLoading = false;
  public dropdownDataYears: any;
  public currentHelpView: number;
  public vadata: any = [];
  public vaUrl: string = '';

  constructor(public http: HttpClient,
              public user: UserService,
              public settings: SettingsService) {
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.setRightContainerWidth(60);
    this.initSearchParameter();
    this.initDropdownDataYear();
    this.initProcessInstructions();
    this.currentHelpView=2;
  }

  ngOnInit(): void {
    this.helpUrl = '';
  }

  changeHelpView(link: number) {
    this.currentHelpView = link;
    if ( link === 1) {
      this.helpUrl = 'https://company-plus.de/wp-content/uploads/2020/11/Company_Plus_Benutzerhandbuch_v20_2.pdf';
    } else if ( link === 2) {
      this.helpUrl = 'https://company-plus.de/?page_id=466';
    } else if ( link === 3) {
      this.helpUrl = 'https://company-plus.de/?page_id=2';
    } else if ( link === 4) {
      this.showChangeLog = true;
    }
  }


  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  search(loadNextSearchResults: boolean) {
    this.showLoading = true;
    if (loadNextSearchResults) {
      this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else {
      this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0;
    }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'help/changelog', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data:{status, count, data}) => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
          this.showLoading = false;
        }
    );
  }

  resetSearch() {
    this.initSearchParameter();
    this.search(false);
  }

  initSearchParameter(): void {
    this.searchParameter = {
      text: '', version: '', year:''
    };
  }

  initDropdownDataYear() {
    this.dropdownDataYears = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }

  searchOnReturnPressed(event): void {
    if (event.key === 'Enter') { this.search(false); }
  }
  view(item) {
    this.http.get<any>(this.settings.restBaseUrl + 'help/changelog/' + item.id,  this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
        }
    );
  }

  private initProcessInstructions() {
    this.showLoading = true;
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'help/processinstructions', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data:{status, count, data}) => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.vadata = this.vadata.concat(data.data);
          console.log(this.vadata);
          this.showLoading = false;
        }
    );
  }

  viewVa(x: any) {
    this.vaUrl = x.path;
  }
}
