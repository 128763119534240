import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-time-tracking-error',
  templateUrl: './time-tracking-error.component.html',
  styleUrls: ['./time-tracking-error.component.css']
})
export class TimeTrackingErrorComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public dropdownErrorType;
  public httpOptions = { };
  public itemToDelete;
  public messageBoxTitle;
  public messageBoxContent;
  public messageBoxImage;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', statement : '', description : '', recordId : '', recordTimeString : '',
    recordDateString: '', correctionTimeString : '', correctionDateString: '', fk_time_tracking_error_type: '-1',
    fk_time_tracking_state: '-1', userString: '', errorTypeString: '', stateString: '', correctionTimestamp: 0 };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , text: '', sort : 'id', processed: '0',
      year : '', fk_core_user : '-1', dateFrom : '', dateTo : '', fk_time_tracking_error_type: '-1', approved: '-1', processedByUser: '1' };
  }


  constructor(public http: HttpClient, public settings: SettingsService, public router: Router, public message: ModalService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;

    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'user/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'timeTracking/error/type', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownErrorType = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'timeTracking/error', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

  save(): void {
    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'timeTracking/error/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          if (this.currentSelected.correctionTimestamp < 1) {
            this.currentSelected.correctionDateString = '-';
            this.currentSelected.correctionTimeString = '-';
          }
        });
  }

  approve(item, state): void  {
    const approveData = {
      fk_core_user: item.fk_core_user,
      timestamp: item.timestamp
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'timeTracking/error/approve/' + item.id + '/' + state, approveData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            item.approve = state;
        });
  }

  delete(item): void {
    const deleteData = {
      fk_core_user: item.fk_core_user,
      timestamp: item.timestamp
    };

    this.http.post<any>(this.settings.restBaseUrl + 'timeTracking/delete/error/' + item.id , deleteData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.closeMessageBox();
          this.search(false);
        });
  }

  openDeleteModal(item): void {
    this.message.open('messageBox');
    this.messageBoxImage = 'delete.png';
    this.messageBoxTitle = 'Eintrag löschen';
    this.messageBoxContent = 'Sie sind dabei diesen Eintrag unwiderruflich zu löschen. Sind Sie sicher, dass sie fortfahren möchten ?';
    this.message.open('messageBox');
    this.itemToDelete = item;
  }

  closeMessageBox(): void {
    this.message.close('messageBox');
    this.itemToDelete = null;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }
}
