<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Firma > Grunddaten
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="search(false)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.company"
                            style="width:80px;"
                            tabindex="2">
                            <option value="1">Firmen</option>
                            <option value="0">Kontakte</option>
                            <option value="-1">Alle</option>
                    </select>

                       <select (change)="search(false)"
                               [(ngModel)]="searchParameter.site"
                               style="width:110px;"
                               tabindex="2">
                            <option value="0">Standortfilter</option>
                           <option value="{{x.id}}" *ngFor="let x of siteData">{{x.site_short_name}}</option>
                    </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px; "
                            tabindex="3">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="4">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="5">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span style="width: 75%">Name</span>
                        </th>
                        <th style="width:25%">
                            <span>Standort Begrenzung</span>
                        </th>
                        <th></th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">
                        <td *ngIf="x.type == '1'" (click)="edit(x)">&nbsp;{{ x.salutationName }} {{ x.name }} {{ x.first_name }}, {{ x.company }}</td>
                        <td *ngIf="x.type == '0'" (click)="edit(x)">&nbsp;{{ x.name }}</td>

                        <td *ngIf="x.type == '1'" (click)="edit(x)" style="width:250px;">{{ x.site_short_name }}</td>
                        <td *ngIf="x.type == '0'" (click)="edit(x)" style="width:250px;">{{ x.site_short_name }}</td>
                        <td> <img align="right" src="../design/icons/delete.png" (click)="delete(x)" ></td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="showNewItemForm" style="margin-left:5px; margin-top:6px;">
                    <span>Neuen Favorit anlegen</span>
                </div>
                <div *ngIf="currentSelected.id>0" style="margin-left:10px; margin-top:0px;">
                    <span>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Favorit bearbeiten</button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px; overflow-x: hidden; height: 100%;">
                    <table style="width:99%; " cellpadding="6" *ngIf="currentSelectedTab === 1">
                        <tr style="height: 30px;">
                            <td style="width: 100%;">
                                <span class="formCaption">Favorit</span>

                                <app-contact-manager
                                        (contactChange)="handleContactStringChange($event)"
                                        (companyChange)="handleCompanyStringChange($event)"
                                        [mode]="'free'"
                                        [(company)]="formData.company"
                                        [(site)]="formData.site"
                                        [(contact)]="formData.contact"
                                        [(connectId)]="formData.connectId"
                                        style="width: 100%;"
                                        tabindex="200">
                                </app-contact-manager>

                            </td></tr>
                        <tr style="height: 30px;">

                            <td style="width: 100%;">
                                <span class="formCaption">Standort Begrenzung</span>
                                <select
                                        [(ngModel)]="currentSelected.site"
                                        style="width:100%;"
                                        tabindex="2">
                                    <option value="0">Bitte wählen</option>
                                    <option *ngFor="let x of siteData" value="{{x.id}}">{{x.site_short_name}}</option>
                                </select>
                            </td>
                        </tr>


                        <tr style="height: 50px;">
                            <td colspan="2">
                                <span class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="108">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>
                        </tr>

                    </table>

                </div>
            </div>
        </div>
    </as-split-area>
</as-split>


