
<div style="width: 100%; left: 0; top: 0; right: 0; height: 22px; position: absolute;">
    <table style="width: 100%; margin-left: 0; margin-right: 0;" cellpadding="0" cellspacing="0" class="form_tabel light">
        <tr style="height: 22px;">
            <th>Rechte</th>
            <th style="width: 120px;">Erhalten</th>
        </tr>
    </table>
</div>
<DIV style="width:100%; left: 0; right: 0; top: 22px; bottom: 295px; position: absolute; overflow: auto;" id="{{overlayId}}Panel">

    <table style="width:100%; margin-left: 0; margin-right: 0;" class="data_tabel" cellspacing="0" cellpadding="4" cellspacing="0">
        <ng-template ngFor let-x [ngForOf]="userRights" let-i="index">
            <tr *ngIf="(userRights[(i-1)] == undefined || userRights[(i-1)].module!=x.module) && modeValue != 'document'">
                <td colspan="2" style="font-weight: bold; font-size: 1.2em; background-color: #dddddd">&nbsp;{{x.module}}</td>
            </tr>
            <tr *ngIf="(userRights[(i-1)] == undefined || userRights[(i-1)].module!=x.module) && modeValue === 'document'">
                <td colspan="3" style="font-weight: bold; font-size: 1.2em; background-color: #dddddd">&nbsp;{{x.module}}</td>
            </tr>
            <tr>
            <td (click)="getUserRightsDescription(x)">&nbsp;{{x.gruppe}}</td>
            <td style="width: 78px;"><img src="../design/icons/tick.png" *ngIf="x.marked != null" style="padding-left: 30px;" (click)="deactivateUserRights(x)"><img src="../design/icons/tick_inactive.png" *ngIf="x.marked == null" style="padding-left: 30px;" (click)="activateUserRights(x)"></td>
            <td style="width: 5%" *ngIf="x.mainGroup > 0 && x.id == x.mainGroup ">
                <img (click)="changeMainGroup(x.mainGroup, x.id, x.userId)" src="../design/icons/star.png" >
            </td>
            <td style="width: 5%" (click)="changeMainGroup(x.mainGroup, x.id, x.userId)" *ngIf="modeValue === 'document' && (x.mainGroup < 1 || x.id != x.mainGroup)">
                <img src="../design/icons/star_unactive.png" >
            </td>
        </tr>
        </ng-template>
    </table>


</DIV>
<div style="width: 100%; height: 30px; left: 0; right: 0; bottom: 265px; position: absolute; border-top: 2px solid #ceced2;">
    <table class="form_tabel light" cellpadding="2" cellspacing="0" style="width:100%;"><tr><th>Beschreibung des gewählten Rechtes</th></tr></table>
</div>
<div style="width: 100%; height: 275px; position: absolute; bottom: 2px; left: 0; right: 0; overflow: auto; background-color: #fcfcfc;">
    <div style="margin:10px;">
        <div style="margin-top: -10px;">
            <h3 *ngIf="userRightsDescriptionTitle">{{userRightsDescriptionTitle}}</h3>
            <h3 *ngIf="!userRightsDescriptionTitle">Kein Recht gewählt </h3>
        </div>
        <ul>
            <li *ngFor="let x of userRightsDescription">{{x.beschreibung}}</li>
        </ul>
    </div>
</div>
