import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-urlframe',
  templateUrl: './urlframe.component.html',
  styleUrls: ['./urlframe.component.css']
})
export class UrlframeComponent implements OnInit {
  public url: string;

  constructor( public router: Router, public route: ActivatedRoute ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.url = this.route.snapshot.params.url;
    this.url = decodeURIComponent(this.url);
  }

  ngOnInit(): void {
  }

}
