<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span class="currentLocation" *ngIf="ticketId == '' && informationId == ''">
                    Dokumente >  <select #modSel class="modul_selector" (change)="goTo(modSel, ticketId)">
                        <option value="document-search">Suche</option>
                        <option value="document-assign" selected>Eingang</option>
                        <option value="document-portal">Dokumentenportal</option>
                    </select>

                </span>

                <span class="form currentLocation" *ngIf="ticketId != ''" style="display: inline-block; color: white;" >
                    Dokumente > Anhängen aus Freigabe >
                    <button (click)="goTo('document-search', ticketId)" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_switch.png"> Aus Suche Anhängen
                    </button>

                    <button (click)="backToProcess()" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_left.png"> Dokumente anhängen beenden
                    </button>
                </span>

                <span class="form currentLocation" *ngIf="informationId != ''" style="display: inline-block; color: white;" >
                    Dokumente > Anhängen aus Freigabe >
                    <button (click)="goTo('document-search', informationId)" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_switch.png"> Aus Suche Anhängen
                    </button>
                </span>

                <span style="float:right; display: inline-block; margin-right:10px; margin-top: 8px;">{{searchResultCount}}</span>

                <div class="form" style="margin-top:3px; margin-left:8px;">
                    <input type="text" [(ngModel)]="searchParameter.text">
                    <select (change)="loadDocumentTagDataSource()"
                            [(ngModel)]="searchParameter.group"
                            id="searchParameterDocumentGroup"
                            style="width:150px;"
                            tabindex="7">
                        <option value="-1">Alle Dokumentengruppen</option>
                        <option *ngFor="let x of documentGroupDataSource" [value]="x.id">{{x.name}}</option>
                    </select>
                    <button id="processSearchReset" (click)="resetSearch(false)" (contextmenu)="resetSearch($event)"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-left:-1px;">
                    </button>
                    <button (click)="search(false)"><img src="../design/icons/magnifier.png" > Suchen</button>

                    <button (click)="uploadFile()" style="float: right; margin-right: 6px;width:100px; visibility: hidden;"><img src="../design/icons/add.png" > Hochladen</button>

                    <span id="processListFilter" app-options [(model)]="tableSettings" (change)="tableOptionChanged()"  style="float: right; margin-right:2px;"></span>

                </div>
                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width: 22px;">
                        </th>
                        <th *ngIf="tableSettings.debug.state" style="width: 60px;">
                        </th>
                        <th *ngIf="tableSettings.date.state" style="width: 110px;">
                            Eingegangen
                        </th>
                        <th *ngIf="tableSettings.rightGroup.state" style="width: 145px;">
                            Dokumentengruppe
                        </th>
                        <th *ngIf="tableSettings.name.state">
                            Name
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="listMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit(x)" style="width: 17px;">
                            <span *ngIf="x.typ == 'pdf'">
                                <img src="../design/icons/doc_pdf.png">
                            </span>
                        </td>
                        <td *ngIf="tableSettings.debug.state" style="width: 60px;">
                            <img *ngIf="x.fileExist==true" src="../design/icons/accept.png" tooltip="PDF vorhanden.">
                            <img *ngIf="x.fileExist==false"src="../design/icons/delete.png" tooltip="PDF fehlt!">

                            <img *ngIf="x.txtExist==true" src="../design/icons/accept.png" tooltip="Externe Erkennung > Textdatei vorhanden.">
                            <img a *ngIf="x.txtExist==false" src="../design/icons/delete.png" tooltip="Externe Erkennung > Textdatei fehlt!">

                            <img *ngIf="x.hasText=='1'" src="../design/icons/accept.png" tooltip="Text in DB vorhanden.">
                            <img (dblclick)="resetState(x)" *ngIf="x.hasText=='0'" src="../design/icons/delete.png" tooltip="Kein Text in DB vorhanden. Status:  {{x.status}}">
                        </td>
                        <td *ngIf="tableSettings.date.state" (click)="edit(x)" style="width:110px; text-align: center;">{{ x.date }}</td>
                        <td *ngIf="tableSettings.rightGroup.state" (click)="edit(x)" style="width: 145px;">{{ x.rightGroup }}</td>


                        <td *ngIf="tableSettings.name.state" (click)="edit(x)">

                            <span  [ngClass]="{'textMarker' : x.status==15}" *ngIf="x.status==15" style="width: 2px"></span>
                            <span *ngIf="x.status==15" tooltip="Texterkennung läuft noch! Sie können das Dokument bereits Freigeben. Es verschwindet dann automatisch, wenn die Texterkennung abgeschlossen wurde.">&nbsp;<B>Texterkennung läuft!  </B><br></span>

                            <span  [ngClass]="{'newMarker' : x.status==6}" *ngIf="x.status==6" style="width: 1px;margin-top: -5px;display: inline-block;height: 25px;margin-bottom: -8px;margin-left: -4px;"></span>

                            <span *ngIf="x.status==6">&nbsp;<B>Dokument existiert bereits </B></span>
                            <span *ngIf="x.inCount>0 && x.inCount < 999999999"><B>{{x.inCount}}</B> mal im System - </span>
                            <span *ngIf="x.process_count==6 && false">- <B>Datei ist nicht lesbar für die Texterkennung!</B></span>
                            <span *ngIf="x.document_missing==1">- <B>Datei Fehlt!</B></span>
                            <span style="display: inline-block;margin-left: 6px;">{{ x.name }}</span></td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span>Zusätzliche Daten erfassen</span>

                    <span style="float: right; margin-right: 10px;">{{currentSelected.id}}</span>

                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden;">
                <div *ngIf="currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%; margin-top: 7px;">
                        <tr>
                            <td style="width: 250px;">
                                <span class="formCaption">Titel</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.title"
                                       id="currentSelectedMetaTitle"
                                       class="cp-textbox"
                                       style="width: calc( 100% - 8px );"
                                       tabindex="101" >
                            </td>

                            <td>
                                <span class="formCaption">Firma / Ansprechpartner</span>
                                <app-contact-manager
                                        mode="dms_assign"
                                        [(company)]="currentSelected.meta_fk_company"
                                        [(contact)]="currentSelected.meta_fk_contact"
                                        settabindex="102"
                                        style="width: 100%;" >
                                </app-contact-manager>
                            </td>
                            <td style="width: 130px;">
                                <span class="formCaption">Datum *</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.meta_document_date"
                                      id="currentSelectedTimestamp"
                                      settabindex="103">
                                </span>
                            </td>
                            <td style="width: 200px;">
                                <span class="formCaption">Dokumentengruppe *</span>
                                <select [(ngModel)]="currentSelected.meta_fk_documentgroup"
                                        (change)="getTagList()"
                                        id="currentSelectedMetaRightGroup"
                                        style="width:100%;"
                                        tabindex="104">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataDocumentGroupUser" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="4">
                                <span class="formCaption">Tags</span><br>
                                <span class="tagline"><span *ngFor="let x of currentSelected.tags" class="form"><button>{{x.name}} <img
                                        src="../design/icons/delete.png" (click)="tagRemove(x)"></button></span></span>
                                <select *ngIf="tagList.length < 4"
                                        [(ngModel)]="documentTag"
                                        (keydown)="dropdownKeyboardHandler($event)"
                                        (change)="documentTagClicked()"
                                        id="currentSelectedMetaTags"
                                        style="width: 150px;"
                                        tabindex="105">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of documentTagDataSource" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" class="form">
                                <button *ngIf="ticketId == '' && informationId == ''" (click)="documentCheck(1)" style="margin-top:6px;width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/accept.png"> Speichern
                                </button>

                                <button *ngIf="ticketId != ''" (click)="documentCheck(2)" style="fmargin-top:6px;loat: right;" tabindex="106">
                                    <img src="../design/icons/accept.png"> Speichern und an E-Mail anhängen
                                </button>

                                <button *ngIf="informationId != ''" (click)="documentCheck(2)" style="fmargin-top:6px;loat: right;" tabindex="106">
                                    <img src="../design/icons/accept.png"> Speichern und an Benachrichtigung anhängen
                                </button>

                                <button (click)="this.message.open('changeRightGroupMessageBox')" style="margin-top:6px;width: fit-content; float: right; margin-right:6px;" tabindex="109">
                                    <img src="../design/icons/user_go.png"> In Dokumentengruppe verschieben
                                </button>

                                <button (click)="toggleExtraMetaText()"
                                        [ngClass]="{'marked' : hastMetaExtraInfos }"
                                        style="float:right;min-width:24px !important;width:24px;height:30px; margin-right:7px;margin-top: 6px;"
                                        tabindex="107"
                                        tooltip="Zusatzinformationen erfassen die dem Suchtext angehängt werden.">
                                    <img *ngIf="documentTop==140" src="../design/icons/sort_arrow_down.png">
                                    <img *ngIf="documentTop>140" src="../design/icons/sort_arrow_up.png">
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="form" colspan="4">
                                <span class="formCaption">Zusatzinformationen</span><br>
                                <textarea id="meta_extra_text_id" tabindex="108" [(ngModel)]="currentSelected.meta_extra_text" style="width:calc( 100% - 8px ); height:120px;"></textarea>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="currentSelected.id > 0" style="position: absolute;top:{{documentTop}}px; left:0px;right:0px;bottom:0px; background: gray;">
                    <iframe [src]="fileToLoad | safeurl" id="documentView" name="documentView" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="messageBox" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/delete.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Löschen bestätigen</strong>
    </div>
    <div class="modalContent">
        {{messageContent}}
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="deleteDocumentFromList();" style="margin-top: 3px;margin-left: 3px; float: right;"><img src="../design/icons/delete.png"> Bestätigen</button>
        <button (click)="closeMessageBox();" style="margin-top: 3px;margin-right: 3px; float: right;"><img src="../design/icons/cross.png"> Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="changeRightGroupMessageBox" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/user_go.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>In Dokumentengruppe Verschieben</strong>
    </div>
    <div class="modalContent">
        Wählen Sie die Dokumentengruppe in die Sie das Dokument Verschieben wollen.<BR>
        Es bleibt weiterhin im Eingang und kann dann von einem anderen Mitarbeiter <BR>
        mit Zugriff auf diese Gruppe bearbeitet werden. Beachten Sie, das durch das <BR>
        Verschieben in eine Gruppe zu der Sie keinen Zugriff haben, Sie das Dokument <BR>
        dann auch nicht mehr bearbeiten können!<BR><BR>

        <span class="formCaption">Dokumentengruppe *</span>
        <select [(ngModel)]="changeRightGroup"
                [ngClass]="{'error' : changeRightGroupError}"
                id="changeRightGroup"
                style="width:100%;"
                tabindex="105">
            <option value="-1">Bitte wählen</option>
            <option *ngFor="let x of dropdownDataDocumentGroup" [value]="x.id">{{x.name}}</option>
        </select>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="startChangeRightGroup();" style="margin-top: 3px;margin-left: 3px; float: right;"><img src="../design/icons/user_go.png"> Bestätigen</button>
        <button (click)="this.message.close('changeRightGroupMessageBox');" style="margin-top: 3px;margin-right: 3px; float: right;"><img src="../design/icons/cross.png"> Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="noDocumentGroup" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/user_go.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Keine Dokumentengruppe gewählt</strong>
    </div>
    <div class="modalContent">
        Bitte eine Dokumentengruppe wählen.
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="this.message.close('noDocumentGroup');" style="margin-top: 3px;margin-left: 3px; float: right;"><img src="../design/icons/user_go.png"> Bestätigen</button>
    </div>
</jw-modal>

<jw-modal id="tagDependencyDialog" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/user_go.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Zu diesem Tag existieren Abhängigkeiten!</strong>
    </div>
    <div class="modalContent">
        <span *ngIf="this.tagDependency != undefined && this.tagDependency[0].type==1">
                    Sie müssen mindestens 1 Tag aus der Liste wählen.
        </span>
        <span *ngIf="this.tagDependency != undefined && this.tagDependency[0].type==0">
                    Wählen Sie optional einen Tag aus der Liste wählen.
        </span>
        <table style="width:100%;" class="data_tabel" cellpadding="6" cellspacing="0">
            <tr *ngFor="let x of this.tagDependency" (click)="addTagDependency(x);">
                <td>{{x.name}}</td>
            </tr>
        </table>
    </div>
</jw-modal>

<jw-modal id="SaveDocDouble" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/delete.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Doppeltes Dokument</strong>
    </div>
    <div class="modalContent">
        Es existiert schon ein Dokument mit gleichem Namen, Datum und Kontakt. Wollen Sie wirklich speichern?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="saveDoubleDocument();" style="margin-top: 3px;margin-left: 3px; float: right;"><img src="../design/icons/delete.png"> Bestätigen</button>
        <button (click)="this.message.close('SaveDocDouble');" style="margin-top: 3px;margin-right: 3px; float: right;"><img src="../design/icons/cross.png"> Abbrechen</button>
    </div>
</jw-modal>


<context-menu #listMenu>

    <ng-template contextMenuItem (execute)="openDeleteItemMessageBox($event.value)">
        <span style="color: black;">
                    <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;Löschen
        </span>
    </ng-template>

</context-menu>
