import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterStatisticComponent } from './printer-statistic/printer-statistic.component';
import {UiModule} from "../ui/ui.module";
import {PipesModule} from "../pipes/pipes.module";
import {ModalModule} from "../_modal";
import {MyDefaultTooltipOptions} from "../tooltip-options";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';



@NgModule({
  declarations: [
    PrinterStatisticComponent
  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule, BrowserAnimationsModule,
    PipesModule, ModalModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AngularSplitModule,
    ContextMenuModule
  ]
})
export class HardwareModule { }
