<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter"><span *ngIf="searchResultCount>=1">{{searchResultCount}} </span>
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Firma > Benutzer

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:128px;"
                           tabindex="1">

                        <select (change)="changeSearchType()"
                                [(ngModel)]="searchParameter.type"
                                style="width:90px;"
                                tabindex="2">
                            <option value="1">Mitarbeiter</option>
                            <option value="0">Kunden</option>
                        </select>

                        <select (change)="getSearchparameterSiteslist()"
                                [(ngModel)]="searchParameter.company"
                                class="cp-textbox"
                                style="width:100px;"
                                tabindex="3" *ngIf="searchParameter.type == 0">
                             <option value="">Firma wählen</option>
                             <option [ngClass]="{'marked' : x.login_exist == 1}" *ngFor="let x of companyData" value="{{x.id}}">{{x.name}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.site"
                                style="width:80px;"
                                *ngIf="siteSearchParameterList.length > 1">
                            <option value="-1">Alle</option>
                            <option *ngFor="let x of siteSearchParameterList" value="{{x.id}}">{{x.site_short_name}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="5">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="6">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="8">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:160px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Login" sortColumn="nick"></span>
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ANREDE" sortColumn="salutation"></span>
                        </th>
                        <th style="width:130px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:130px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="VORNAME" sortColumn="first_name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="STANDORT" sortColumn="site_short_name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="POSITION" sortColumn="position"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">
                        <td (click)="edit(x)" style="width:160px;">&nbsp;{{ x.nick }}</td>
                        <td (click)="edit(x)" style="width:80px;">&nbsp;{{ x.salutation }}</td>

                        <td (click)="edit(x)" style="width:130px;">&nbsp;{{ x.name }}</td>

                        <td (click)="edit(x)" style="width:130px;">{{ x.first_name }}</td>

                        <td (click)="edit(x)" style="width:150px;"> {{ x.site_short_name }}</td>

                        <td (click)="edit(x)" style="width:150px;">{{ x.position }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length < searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-top:0px;">
                    <div *ngIf="showNewItemForm" style="padding-top: 5px;margin-left: 5px;">Neuen <span *ngIf="searchParameter.type == 0">Kunde</span><span *ngIf="searchParameter.type == 1">Mitarbeiter</span> Anlegen</div>
                    <div *ngIf="showCopyUserForm" style="padding-top: 5px;margin-left: 5px;">Benutzer kopieren</div>
                    <div *ngIf="pwChangeForm" style="padding-top: 5px;margin-left: 5px;">Passwort ändern für {{ currentSelected.name }}, {{ currentSelected.first_name }}</div>
                    <span *ngIf="currentSelected.id>0 && !pwChangeForm">
                        <div style="margin-left: 10px;">
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Grunddaten</button>
                        <button *ngIf="!showCopyUserForm" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Rechte</button>
                        <button *ngIf="!showCopyUserForm" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 3 }" style="margin-right:5px;" (click)="changeTabButton(3)">Dokumentengruppen</button>
                        <button *ngIf="searchParameter.type == 1 && !showCopyUserForm" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 4 }" style="margin-right:5px;" (click)="changeTabButton(4)">Teams</button>
                        <button *ngIf="!showCopyUserForm" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 5 }" style="margin-right:5px;" (click)="changeTabButton(5)">Vertretung</button>
                    <span class="form">


                    <button style="float:right; margin-right:8px; margin-top: 2px; height:21px !important;"
                            *ngIf="!showCopyUserForm"
                            (click)="testUserRights()">
                            Rechte Testen
                    </button>
                    </span>
                    </div>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div id="EmailSelectModal" class="eMailModal">

                    <div class="eMailModal-content">
                        <span style="float: right;"><img src="../design/icons/cross.png" (click)="closeModal()"></span>
                        <table class="data_tabel" style="width: 100%" cellspacing="0" cellpadding="4">
                            <tr>
                                <th>Bitte wählen Sie die zu verwendende Adresse.</th>
                            </tr>
                            <tr *ngFor="let x of possibleEmailAddresses">
                                <td (click)="sendforgotPwEmailForm(x)">
                                    {{x.target}}
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>


                <div *ngIf="showNewItemForm" class="form" style="margin-left:10px;">
                    <table style="width:99%;"  cellpadding="2">
                        <tr>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="2">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Anrede *</span>
                                            <select [disabled]="saveFromExistingContact == true"
                                                    [(ngModel)]="currentSelected.salutation"
                                                    id="NewItemFormSalutation"
                                                    class="cp-textbox"
                                                    style="width:100%; margin-left: -2px;"
                                                    tabindex="100" >
                                                <option value="0">Anrede Wählen</option>
                                                <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>

                                        <td>
                                            <span class="formCaption">Titel</span>
                                            <select [disabled]="saveFromExistingContact == true"
                                                    [(ngModel)]="currentSelected.title"
                                                    id="NewItemFormTitle"
                                                    class="cp-textbox"
                                                    style="width:80%; margin-left: 8px;"
                                                    tabindex="101" >
                                                <option value="0">Titel Wählen</option>
                                                <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                </tr>
                                </table>
                            </td>

                            <td>
                                <span class="formCaption">Vorname</span>
                                <input [disabled]="saveFromExistingContact == true"
                                       type="text"
                                       [(ngModel)]="currentSelected.first_name"
                                       id="NewItemFormFirstName"
                                       class="cp-textbox"
                                       style="width:98%;"
                                       tabindex="102" >
                            </td>

                            <td>
                                <span class="formCaption">Name *</span>
                                <input [disabled]="saveFromExistingContact == true"
                                       type="text"
                                       [(ngModel)]="currentSelected.name"
                                       (keyup)="checkExistingContact()"
                                       (blur)="closeExistingContactOverlay()"
                                       id="NewItemFormName"
                                       class="cp-textbox"
                                       style="width:98%; margin-left: 3px;"
                                       tabindex="103">
                                <div *ngIf="showExistingContactDropdown" id="existingContactOverlayNewCompanyForm" class="shadow" style=" margin-top:2px; width: 350px; height: fit-content; max-height: 500px; color: #000000; position: fixed; overflow: auto; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
                                    <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
                                        <tr *ngFor="let x of existingContact">
                                            <td (click)="checkContact(x)">{{x.name}}, {{x.first_name}}  <span style="float: right;" *ngIf="x.favorite != null"><img src="../design/icons/star.png"></span><br>
                                                <b *ngIf="x.companyId!='0'">{{x.company}}</b><b *ngIf="!x.companyId">Privatperson</b><br>
                                                <span *ngIf="x.email">E-Mail : {{x.email}} <br></span>
                                                <span *ngIf="x.phone">Telefon: {{x.phone}} </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Login *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.nick"
                                       id="NewItemFormNick"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       (blur)="checkUserNickExistence()"
                                       tabindex="104" >
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <span class="formCaption">Passwort *</span>
                                <input type="password"
                                       [(ngModel)]="currentSelected.password"
                                       (keyup)="checkPassword(currentSelected.password, currentSelected.password_confirmation)"
                                       id="NewItemFormPassword"
                                       style="width:100%; margin-bottom: 7px;"
                                       tabindex="105" >

                                <span class="formCaption">Passwort bestätigen *</span>
                                <input type="password"
                                       [(ngModel)]="currentSelected.password_confirmation"
                                       (keyup)="checkPassword(currentSelected.password, currentSelected.password_confirmation)"
                                       id="NewItemFormPasswordConfirmation"
                                       style="width:100%;"
                                       tabindex="106" >
                            </td>
                            <td>
                                <div class="checkText" style="color: #000000 !important; margin-top: 12px; margin-left:30px;"><img *ngIf="!newPasswordCheck.length" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.length" src="../design/icons/accept.png"> 8 Zeichen lang </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:30px;"><img *ngIf="!newPasswordCheck.special" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.special" src="../design/icons/accept.png">   Sonderzeichen </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:30px;"><img *ngIf="!newPasswordCheck.upper" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.upper" src="../design/icons/accept.png">   Großbuchstaben </div>
                            </td>
                            <td>
                                <div class="checkText" style="color: #000000 !important; margin-top: 12px;"><img *ngIf="!newPasswordCheck.lower" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.lower" src="../design/icons/accept.png">  Kleinbuchstaben </div>
                                <div class="checkText" style="color: #000000 !important;"><img *ngIf="!newPasswordCheck.digits" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.digits" src="../design/icons/accept.png">  2 Ziffern </div>
                                <div class="checkText" style="color: #000000 !important;"><img *ngIf="!newPasswordCheck.same" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.same" src="../design/icons/accept.png">  Passwörter gleich </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">E-Mail </span>
                                <input [disabled]="saveFromExistingContact == true"
                                        type="text"
                                       [(ngModel)]="currentSelected.e_mail"
                                       id="NewItemFormEmail"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="107" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption"> weitere E-Mail </span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.other_e_mail"
                                       id="NewItemFormOtherEmail"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="108" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Telefon privat</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.phone_public"
                                       id="NewItemFormPhonepublic"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="109" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Telefon Mobil privat</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.mobile_public"
                                       id="NewItemFormMobilepublic"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="110" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Telefon geschäftlich</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.phone_business"
                                       id="NewItemFormPhoneBusiness"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="111" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Telefon Mobil geschäftlich</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.mobile_business"
                                       id="NewItemFormMobileBusiness"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="112" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Straße</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.street"
                                       id="NewItemFormStreet"
                                       class="cp-textbox"
                                       style="width:98%;"
                                       tabindex="113" >
                            </td>

                            <td style="width: 30%;">
                                <table style="width: 100%;" cellspacing="2" cellpadding="2">
                                    <tr>
                                        <td>
                                <span class="formCaption">Hausnummer</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.street_number"
                                       id="NewItemFormStreetNumber"
                                       class="cp-textbox"
                                       style="width:100%; margin-left: -4px;"
                                       tabindex="114" >
                                        </td>
                                        <td>
                                <span class="formCaption">PLZ</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.postcode"
                                       id="NewItemFormPostcode"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="115">
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td>
                                <span class="formCaption">Ort</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.city_name"
                                       id="NewItemFormCity"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="116" >
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Position</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.position"
                                       id="NewItemFormPosition"
                                       class="cp-textbox"
                                       style="width:98%;"
                                       tabindex="117" >
                            </td>

                            <td colspan="2">
                                <span class="formCaption">Beruf</span>
                                <input type="text"
                                       [disabled]="saveFromExistingContact == true"
                                       [(ngModel)]="currentSelected.profession"
                                       id="NewItemFormProfession"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="118" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Geburtsdatum</span> <br>
                                <span *ngIf="saveFromExistingContact == false" app-date-picker [(model)]="currentSelected.birth_date" tabindex="119"></span>
                            </td>



                            <td>
                                <span class="formCaption">Wird deaktiviert am</span> <br>
                                <span app-date-picker [(model)]="currentSelected.outDate" tabindex="120"></span>
                            </td>
                        </tr>
                        <tr *ngIf="searchParameter.type == 1">
                            <td colspan="3">
                            <span class="formCaption">Standardteam *</span>
                            <select [(ngModel)]="currentSelected.default_team"
                                    id="NewItemFormdefault_team"
                                    class="cp-textbox"
                                    style="width: calc(100% + 7px);"
                                    tabindex="121" >
                                <option value="-1">Bitte Standardteam auswählen</option>
                                <option *ngFor="let x of newUserTeams" value="{{x.id}}">{{x.name}}</option>
                            </select>
                            </td>
                        </tr>
                        <tr *ngIf="searchParameter.type == 0">
                            <td colspan="3">
                                <span class="formCaption">Firma *</span>
                                <select (change)="getCompanySites()"
                                        [(ngModel)]="currentSelected.company"
                                        id="NewItemForm"
                                        class="cp-textbox"
                                        style="width:calc(100% + 7px);"
                                        tabindex="121" >
                                    <option value="">Bitte Firma auswählen</option>
                                    <option *ngFor="let x of companyData" value="{{x.id}}" >{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span *ngIf="siteData.length>1">
                                    <span class="formCaption">Standort *</span>
                                    <select [(ngModel)]="currentSelected.site"
                                            (change)="selectSite()"
                                            id="currentUserSite"
                                            style="width:calc(100% + 7px);"
                                            tabindex="107">
                                        <option *ngFor="let x of siteData" value="{{x.id}}" (click)="selectSite()">{{x.site_short_name}}</option>
                                    </select>
                                </span>
                                <input *ngIf="siteData.length<2" type="hidden" [(ngModel)]="currentSelected.site" value="siteData[0].id">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="form">
                                <button (click)="saveFromExistingContactFunction()"
                                        style="width: 100px; float: right; margin-right: -7px; margin-top: 10px;" tabindex="123">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>

                        </tr>
                    </table>

                </div>
                <div *ngIf="currentSelected.id>0 || showCopyUserForm" class="form">

                    <table style="width:97.5%; margin-left: 10px;" cellpadding="6" *ngIf="currentSelectedTab === 1 && !pwChangeForm">
                        <tr>
                            <td colspan="3">
                            <table style="width: 100%;" cellspacing="2" cellpadding="0">
                                <tr>
                            <td style="width: 20%">
                                <span class="formCaption">Anrede *</span>
                                <select [(ngModel)]="currentSelected.salutation"
                                        id="currentSelectedSalutation"
                                        class="cp-textbox"
                                        style="width:100%; margin-left: -2px;"
                                        tabindex="101" >
                                    <option *ngIf="showCopyUserForm" value="">Anrede Wählen</option>
                                    <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                </select>
                            </td>

                            <td style="width: 40%">
                                <span class="formCaption">Vorname</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.first_name"
                                       id="currentSelectedFirstName"
                                       class="cp-textbox"
                                       style="width:98%;"
                                       tabindex="102" >
                            </td>
                            <td style="width: 40%">
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:calc(99% - 1px); margin-left: 5px;"
                                       tabindex="103">
                            </td>
                                </tr>
                            </table>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Login *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.nick"
                                       id="currentSelectedNick"
                                       class="cp-textbox"
                                       maxlength="100"
                                       style="width:100%;"
                                       (blur)="checkUserNickExistence()"
                                       tabindex="104">
                            </td>
                        </tr>
                        <tr *ngIf="showCopyUserForm">
                            <td colspan="3">
                                <span class="formCaption">Passwort *</span>
                                <input type="password"
                                       [(ngModel)]="currentSelected.password"
                                       (ngModelChange)="checkPasswordComplexity(currentSelected.password)"
                                       id="copyUserFormPassword"
                                       style="width:100%;"
                                       tabindex="105" >
                            </td>
                        </tr>
                        <tr *ngIf="showCopyUserForm">
                            <td colspan="3">
                                <span class="formCaption">Passwort bestätigen *</span>
                                <input type="password"
                                       [(ngModel)]="currentSelected.password_confirmation"
                                       id="copyUserFormPasswordConfirmation"
                                       style="width:100%;"
                                       tabindex="106" >
                            </td>
                        </tr>
                        <tr *ngIf="searchParameter.type == 0">
                            <td colspan="3">
                                <span class="formCaption">Firma *</span>
                                <select (change)="getCompanySites()"
                                        [(ngModel)]="currentSelected.company"
                                        id="currentSelectedCompany"
                                        style="width:calc(100% + 7px);"
                                        tabindex="106" >
                                    <option value="">Bitte Firma auswählen</option>
                                    <option *ngFor="let x of companyData" value="{{x.id}}">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="siteData.length > 0 && !showCopyUserForm">
                            <td colspan="3">
                                <span *ngIf="siteData.length>1">
                                    <span class="formCaption">Standort *</span>
                                    <select [(ngModel)]="currentSelected.site"
                                            (change)="selectSite()"
                                            id="currentUserSiteEditor"
                                            style="width:calc(100% + 7px);"
                                            tabindex="107">
                                        <option *ngFor="let x of siteData" value="{{x.id}}" (click)="selectSite()">{{x.site_short_name}}</option>
                                    </select>
                                </span>
                                <input *ngIf="siteData.length<2" type="hidden" [(ngModel)]="currentSelected.site" value="siteData[0].id">
                            </td>
                        </tr>
                        <tr *ngIf="!showCopyUserForm">
                            <td>
                                <span class="formCaption">Wird deaktiviert am</span> <br>
                                <span app-date-picker [(model)]="currentSelected.outDate" ></span>
                            </td>
                            <td>
                                <table style="width: 100%" cellpadding="2" cellspacing="0">
                                    <tr>
                                        <td>
                                            <span *ngIf="timeTrackingModuleActiveState == 1" class="formCaption">Arbeitszeitmodell *<br></span>
                                            <select [(ngModel)]="currentSelected.fk_time_tracking_model"
                                                    *ngIf="timeTrackingModuleActiveState == 1"
                                                    id="currentUserTimeTrackingModel"
                                                    style="width: fit-content; min-width: 150px;"
                                                    tabindex="108">
                                                <option value="-1">Kein Modell</option>
                                                <option *ngFor="let x of dropdownWorkTimeModelData" [value]="x.id">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td class="form">
                                            <button (click)="checkDeaktivationDate()" style="width: 100px; float: right; margin-right: -9px; margin-top: 17px;" tabindex="111">
                                                <img src="../design/icons/disk.png"> Speichern
                                            </button>
                                        </td>
                                    </tr>
                                </table>



                            </td>
                        </tr>
                        <tr *ngIf="showCopyUserForm">
                            <td colspan="2">
                                <span class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -9px; margin-top: 17px;" tabindex="111">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>
                        </tr>
                    </table>

                    <table style="width: calc(100% - 20px); margin-left: 10px;" cellpadding="4" cellspacing="0" *ngIf="currentSelectedTab === 1 && pwChangeForm">
                        <tr>
                            <td style="width:33%;">
                                <span class="formCaption">Passwort</span>
                                <input [(ngModel)]="newPassword1" type="password" style="width:calc(100% - 18px);" (keyup)="checkPassword(newPassword1,newPassword2)" tabindex="101" id="changePasswordOne">

                                <div class="checkText" style="color: #000000 !important; margin-top: 12px; margin-left:5px;"><img *ngIf="!newPasswordCheck.length" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.length" src="../design/icons/accept.png"> 8 Zeichen lang </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:5px;"><img *ngIf="!newPasswordCheck.special" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.special" src="../design/icons/accept.png">   Sonderzeichen </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:5px;"><img *ngIf="!newPasswordCheck.upper" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.upper" src="../design/icons/accept.png">   Großbuchstaben </div>
                            </td>
                            <td style="width: 33%;">
                                <span class="formCaption" style="margin-left: -3px;">Passwort Wiederholung</span>
                                <input [(ngModel)]="newPassword2" type="password" style="width: calc(100% + 9px); margin-left: -10px;" (keyup)="checkPassword(newPassword1,newPassword2)" tabindex="102">

                                <div class="checkText" style="color: #000000 !important; margin-top: 12px;"><img *ngIf="!newPasswordCheck.lower" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.lower" src="../design/icons/accept.png">  Kleinbuchstaben </div>
                                <div class="checkText" style="color: #000000 !important;"><img *ngIf="!newPasswordCheck.digits" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.digits" src="../design/icons/accept.png">  2 Ziffern </div>
                                <div class="checkText" style="color: #000000 !important;"><img *ngIf="!newPasswordCheck.same" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.same" src="../design/icons/accept.png">  Passwörter gleich </div>
                            </td>
                            <td style="width: 33%; text-align: right; vertical-align: top;" class="form">

                                <button (click)="backPasswordChange()" style="width: 100px; margin-right: 7px;  margin-top: 16px; " tabindex="103">
                                    <img src="../design/icons/cross.png">
                                    Abbrechen
                                </button>

                                <button (click)="saveChangedPassword()" style="width: 100px; margin-right: 7px; margin-top: 16px; " tabindex="104">
                                    <img src="../design/icons/disk.png">
                                    Speichern
                                </button>

                            </td>
                        </tr>

                    </table>

                    <table style="width:100%;" cellpadding="0" *ngIf="currentSelectedTab === 2">
                        <tr><td>
                            <span app-core-right-modul-user-right [(model)]="currentSelected.id"></span>
                        </td></tr>
                    </table>

                    <table style="width:100%;" cellpadding="0" *ngIf="currentSelectedTab === 3">
                        <tr><td>
                            <span app-core-right-modul-user-right [(model)]="currentSelected.id" [mode]="'document'"></span>
                        </td></tr>
                    </table>

                    <div *ngIf="currentSelectedTab === 4" style="left: 0; right: 0; top: 0; bottom: 0; position: absolute;">
                        <div style="left: 0; right: 0; top: 0; height: 54px; position: absolute;">
                            <table style="width: 100%;" class="form_tabel light" cellpadding="4" cellspacing="0">
                                <tr>
                                    <td colspan="3">
                                        <select [(ngModel)]="selectedTeam"
                                                id="assignableTeam"
                                                class="cp-textbox"
                                                tabindex="101"
                                                style="width: 99%;">
                                            <option value="-1">Bitte Team wählen</option>
                                            <option *ngFor="let x of selectableTeams" value="{{x.id}}">{{x.name}}</option>
                                        </select>
                                    </td>
                                    <td style="width: 100px;" class="form">
                                        <button (click)="setTeamLinkUser(currentSelected.id)"
                                                style="margin-top: 0;">
                                            <img src="../design/icons/add.png"> Hinzufügen
                                        </button>
                                    </td>
                                </tr>
                            <tr>
                                <td colspan="4">
                                <table style="width: calc(100% + 11px); margin-left: -6px;" class="data_tabel" cellspacing="0">
                                    <tr style="height: 20px;">
                                        <th>Name</th>
                                        <th style="width: 80px;">Standard</th>
                                        <th style="width: 150px;">Rolle</th>
                                        <th style="width: 24px;"></th>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                            </table>
                        </div>
                        <div style="left: 0; right: 0; top: 56px; bottom: 0; position: absolute;" >
                            <table style="width:calc(100% + 1px);" class="data_tabel" cellpadding="2" cellspacing="0">
                                <tr *ngFor="let x of userTeams">
                                    <td>&nbsp;{{x.name}}</td>

                                    <td style="width: 80px;"><img src="../design/icons/tick.png" *ngIf="x.default_team == 1" style="padding-left: 30px;"><img src="../design/icons/tick_inactive.png" *ngIf="x.default_team != 1" style="padding-left: 30px;" (click)="setStandardTeam(x)"></td>
                                    <td style="width: 150px;">
                                        <select id="selectableRole"
                                                [(ngModel)]="x.fk_team_role"
                                                (ngModelChange)="checkUserTeamRole(x)"
                                                class="cp-textbox"
                                                style="width: 140px; margin-left: 5px;"
                                                tabindex="109" >
                                            <option *ngFor="let x of roleData" value="{{x.id}}">{{x.name}}</option>
                                        </select>
                                    </td>
                                    <td style="width: 24px;"><img src="../design/icons/delete.png" style="margin-left: 4px;" *ngIf="x.default_team != 1 && x.fk_team_role != 5" (click)="deleteTeamLink(x)"></td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div *ngIf="currentSelectedTab === 5" style="left: 0; right: 0; top: 0; height: 54px; position: absolute;">
                    <table style="width:100%;" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="4">
                                <tr *ngIf="searchParameter.type == 1">
                                    <td>
                                        <select [(ngModel)]="selectedSubstituteUserId"
                                                style="width: 99%;"
                                                tabindex="101">
                                            <option value="0">Benutzer Wählen</option>
                                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.userName}}, {{x.userFirstName}}</option>
                                        </select>
                                    <td style="width: 100px;" class="form">
                                    <button (click)="putSelectedSubstituteUserAsPossible()" style="margin-top: 0;" tabindex="102">
                                        <img src="../design/icons/add.png"> Hinzufügen
                                    </button>
                                    </td>
                                </tr>
                                <tr *ngIf="searchParameter.type == 0">
                                    <td>
                                        <select [(ngModel)]="selectedSubstituteUserId"
                                                style="width:calc(100% - 10px); margin-left: 10px;"
                                                tabindex="101">
                                            <option value="0">Benutzer Wählen</option>
                                            <option *ngFor="let x of customerDropdownDataUser" [value]="x.id">{{x.userName}}, {{x.userFirstName}}</option>
                                        </select>
                                    <td style="width: 100px;" class="form">
                                        <button (click)="putSelectedSubstituteUserAsPossible()" style="margin-top: 0;" tabindex="102">
                                            <img src="../design/icons/add.png"> Hinzufügen
                                        </button>
                                    </td>
                                </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                        <td>
                            <table style="width:100%;" class="form_tabel light" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <table style="width: calc(100% + 1px);" class="data_tabel" cellpadding="0" cellspacing="0">
                                            <tr style="height: 24px;">
                                                <th>Name</th>
                                                <th style="width: 80px;">Vertretung</th>
                                                <th style="width: 80px;">Priorität</th>
                                                <th style="width: 24px;"></th>
                                            </tr>
                                            <tr *ngFor="let x of possibleSubstituteUsers">
                                                <td>
                                                    &nbsp;{{x.name}}, {{x.first_name}}
                                                </td>
                                                <td>
                                                    <img src="../design/icons/tick.png" *ngIf="x.vertretung == activeSubstituteUser.vertretung" style="padding-left: 30px;" (click)="unsetSubstituteUser()">
                                                    <img src="../design/icons/tick_inactive.png" *ngIf="x.vertretung != activeSubstituteUser.vertretung" style="padding-left: 30px;" (click)="setSubstituteUser(x)">
                                                </td>
                                                <td>
                                                    <img src="../design/icons/arrow_up.png" *ngIf="x.priority >= 2" (click)="increaseSubstituteUserPriority(x)">
                                                    <img src="../design/icons/arrow_down.png" *ngIf="x.priority < possibleSubstituteUsers.length" [ngClass]="{'priorityArrowDown': x.priority < 2 }" (click)="decreaseSubstituteUserPriority(x)">
                                                </td>
                                                <td style="width: 24px;">
                                                    <img src="../design/icons/delete.png" style="margin-left: 4px;" (click)="deleteSubstituteUser(x)">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        </tr>
                    </table>
                    </div>

                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="openContactQuestionDialogOnActivateUser($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="openContactQuestionDialogOnDeactivateUser($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changePassword($event.value)" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/lock_edit.png" style="vertical-align: middle">&nbsp;&nbsp;Passwort ändern
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="forgotPassword($event.value)" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/lock_break.png" style="vertical-align: middle">&nbsp;&nbsp;Passwort Reset / E-Mail an Benutzer senden
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="copyUser($event.value)">
        <span *ngIf="!showCopyUserForm" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/group.png" style="vertical-align: middle">&nbsp;&nbsp;Benutzer kopieren
        </span>
    </ng-template>
</context-menu>

<jw-modal id="deactivationDateWarning" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">{{deactivationDateMessageContent}}</div>
    <div class="modalFooter form" ><button (click)="saveWithDeactivate(); closeMessageBox()" style="float: right; margin-top: 3px;">OK</button>
    <button (click)="closeMessageBox()" style="float: right; margin-top: 3px;">Abbrechen</button></div>
</jw-modal>

<jw-modal id="teamResponsibleWarning" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Sie haben den letzten Verantwortlichen aus dem Team entfernt!</div>
    <div class="modalFooter form" ><button (click)="message.close('teamResponsibleWarning')" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="privateContact" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Der gewählte Kontakt ist ein Privatkontakt. Weißen Sie diesen im Kontaktmanager Ihrer Firma zu um einen Login für diesen Kontakt zu erstellen.</div>
    <div class="modalFooter form" ><button (click)="message.close('privateContact')" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="contactChoose" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Wollen Sie zu diesem Kontakt ein Benutzerkonto erstellen?</div>
    <div class="modalFooter form" ><button (click)="message.close('contactChoose'); unsetUser()" style="float: right; margin-top: 3px;">Abbrechen</button><button (click)="chooseContact()" style="float: right; margin-top: 3px;">Ja</button></div>
</jw-modal>

<jw-modal id="contactDeactivate" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Benutzerkonto deaktivieren.<BR>Soll der zugehörige Kontakt auch deaktiviert werden?</div>
    <div class="modalFooter form" >
        <button (click)="deactivateUser(0)" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="deactivateUser(2)" style="float: right; margin-top: 3px;">Nein</button>
        <button (click)="message.close('contactDeactivate')" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="contactActivate" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll das Benutzerkonto wieder aktiviert werden?</div>
    <div class="modalFooter form" >
        <button (click)="activateUser()" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('contactActivate')" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>
