import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { InventoryCheckTypeManagementComponent } from './inventory-check-type-management/inventory-check-type-management.component';
import { InventoryPlaceManagementComponent } from './inventory-place-management/inventory-place-management.component';
import { InventoryTypeManagementComponent } from './inventory-type-management/inventory-type-management.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    InventoryCheckTypeManagementComponent,
    InventoryPlaceManagementComponent,
    InventoryTypeManagementComponent,
  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule,
    AngularSplitModule,
    ContextMenuModule
  ]
})
export class InventoryModule { }
