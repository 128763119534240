


<div class="freeContainerDefinition" style="width: 40% !important; position:absolute; top:200px; left: calc(50% - 250px); width:500px !important; height:170px; ">
    <span class="header1Row footerNone">
        <div class="freeContainerTop" style="font-size: 1.1em;">
            <div style="margin:5px;">
                <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
            </div>
        </div>

        <div class="freeContainerContent" style="overflow: hidden;">
            <div style="margin: 20px;">
                Ihr Konto ist zwar aktiv, <BR>aber leider wurden Ihnen keine Modulrechte zugeteilt.<BR><BR>
                Bitte kontaktieren Sie Ihren Systembetreuer <BR>damit er die Einrichtung Ihres Kontos fertigstellt!
            </div>
        </div>
    </span>
</div>
