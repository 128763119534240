import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerDocumentComponent } from './customer-document/customer-document.component';



@NgModule({
  declarations: [
    CustomerDocumentComponent
  ],
  imports: [
    CommonModule
  ]
})
export class CustomerPortalModule { }
