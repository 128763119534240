<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Vorgangsmanagement > Teams
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;margin-left:-1px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px;margin-right:3px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>

                        <th style="width: 300px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Team" sortColumn="name"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="E-Mail Adresse" sortColumn="email"></span>
                        </th>
                        <th *ngIf="securityLevelRight == true" style="width: 150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Sicherheitslevel"></span>
                        </th>

                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width: 300px;">&nbsp;{{ x.name }}</td>

                        <td (click)="edit($event,x)">{{ x.email }}</td>

                        <td style="width: 150px; text-align: right;" *ngIf="x.securityLevel == 1 && securityLevelRight == true"><img src="../design/icons/bullet_blue.png"></td>

                        <td style="width: 150px; text-align: right;" *ngIf="x.securityLevel == 2 && securityLevelRight == true">HinSchG <img src="../design/icons/bullet_blue.png"></td>

                        <td style="width: 150px; text-align: right;" *ngIf="x.securityLevel == 0 && securityLevelRight == true"><img src="../design/icons/bullet_white.png"></td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">
                    <div style="margin-left: -5px; padding-top: 5px;" *ngIf="showNewItemForm">Neues Team anlegen</div>

                    <span *ngIf="currentSelected.id>0">
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Grunddaten</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Teammitglieder</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 3 }" style="margin-right:5px;" (click)="changeTabButton(3)">Kategorien</button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="!showNewItemForm && currentSelected.id>0 && currentSelectedTab === 2" class="form" style="left: 0; right: 0; top: 0; height: 55px; position: absolute;">
                    <table class="form_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                        <tr>
                            <td class="form">
                                <input type="text"
                                       (keydown)="teamMemberSearchOnReturnPressed($event)"
                                       [(ngModel)]="teamMemberSearchParameter.text"
                                       id="teamMemberSearchText"
                                       style="width:120px;"
                                       tabindex="1">
                                <button (click)="resetTeamMemberSearch()"
                                        style="min-width:24px !important;width:24px;height:30px;"
                                        tabindex="4">
                                    <img src="../design/icons/cross_black.png" style="margin-left:-1px;">
                                </button>

                                <button (click)="getTeamUserList()"
                                        tabindex="5"
                                        style="margin-left:2px;">
                                    <img src="../design/icons/magnifier.png"> Suchen
                                </button>
                            </td>
                            <td class="form" style="text-align: right;">
                                <button *ngIf="!this.teamMemberSearchParameter.showAll" (click)="changeToAddUserToTeamMode(true)" style="margin-right: 10px;" tabindex="102">
                                    <img src="../design/icons/add.png"> Benutzer hinzufügen
                                </button>
                                <button *ngIf="this.teamMemberSearchParameter.showAll" (click)="changeToAddUserToTeamMode(false)" style="margin-right: 10px;" tabindex="102">
                                    <img src="../design/icons/stop.png"> Hinzufügen beenden
                                </button>
                            </td>
                        </tr>
                    </table>

                    <table class="form_tabel light" style="width:100%;" cellpadding="0" cellspacing="0">
                        <tr style="height: 20px;">
                            <th style="width: 200px;">Name</th>
                            <th>Position</th>
                            <th>Standort</th>
                            <th style="width: 145px;">Rolle</th>
                        </tr>
                    </table>
                </div>
                <div style="left: 0; right: 0; top: 57px; bottom: 0; position: absolute; overflow: auto; overflow-x: hidden;" class="form" *ngIf="!showNewItemForm && currentSelected.id>0 && currentSelectedTab === 2">
                        <table class="data_tabel" style="width: calc(100% + 1px)" cellpadding="4" cellspacing="0">

                        <tr *ngFor="let x of teamUserList"
                            [contextMenu]="memberMenu"
                            [contextMenuValue]="x">
                            <td style="width: 200px;">&nbsp;{{x.user_name}}</td>

                            <td>{{x.position}}</td>
                            <td>{{x.site}}</td>

                            <td style="width: 120px; height: 24px;">
                                <button *ngIf="x.fk_team_role < 1" (click)="addUserToTeam(x.user_id)"
                                        style="width:116px; margin-left: 2px;">
                                    <img src="../design/icons/add.png"> Hinzufügen
                                </button>
                                <select *ngIf="x.fk_team_role > 0 && teamMemberSearchParameter.text == ''" (change)="checkUserTeamRole(x)"
                                        [(ngModel)]="x.fk_team_role"
                                        style="width:116px; margin-left: 2px;"
                                        tabindex="103">
                                    <option *ngFor="let y of teamRoleList" value="{{y.id}}">{{y.name}}</option>
                                </select>
                                <span *ngIf="x.fk_team_role > 0 && teamMemberSearchParameter.text != ''">
                                    Wurde hinzugefügt!
                                </span>
                            </td>

                        </tr>
                    </table>
                </div>
                <div *ngIf="showNewItemForm || currentSelected.id>0 && currentSelectedTab === 1" class="form" style="margin-left:10px;">
                    <table style="width:calc( 100% - 20px );" cellpadding="3" >
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [ngClass]="{'error' : teamFormError && currentSelected.name==''}"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>
                        </tr>


                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Beschreibung</span>
                                <textarea  rows="3"
                                           [(ngModel)]="currentSelected.description"
                                           id="currentSelectedDescription"
                                           style="width:calc(100% + 1px); border-radius: 3px 3px 3px 3px; border: 1px solid #ceced2;"
                                           tabindex="103" ></textarea>
                            </td>
                        </tr>

                        <tr *ngIf="showNewItemForm">
                            <td>
                                <span class="formCaption">Standardkategorie *</span>
                                <select [(ngModel)]="currentSelected.fk_category"
                                        [ngClass]="{'error' : teamFormError && currentSelected.fk_category=='0'}"
                                        id="currentSelectedfk_category"
                                        style="width: calc(100% + 2px);"
                                        tabindex="116">
                                    <option *ngIf="currentSelected.fk_category == '0'" value="0">Bitte Standardkategorie wählen</option>
                                    <option *ngFor="let x of dropdownDataCategories" value="{{x.id}}">{{x.name}}</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption" style="width:100%; display: inline-block;">Erster benutzer / Rolle</span>
                                <select [(ngModel)]="currentSelected.teamleader"
                                        style="width: calc( 50% - 5px); margin-top: 1px; margin-left: 5px;"
                                        tabindex="102">
                                    <option value="">Benutzer Wählen</option>
                                    <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.userName}}, {{x.userFirstName}}</option>
                                </select>
                                <select [(ngModel)]="currentSelected.teamleader_role"
                                        style="width: calc( 50% - 5px); margin-top: 1px; margin-right: -6px;float: right"
                                        tabindex="102">
                                    <option value="4">Verantwortlicher</option>
                                    <option value="5">Teamleiter</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <th colspan="2" style="border-bottom: 1px solid #808080; vertical-align: bottom; height:30px;">
                                Einstellungen
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption" style="padding-left: 5px;">Abrechnungsart</span>
                                <br>
                                <select [(ngModel)]="currentSelected.fk_process_billing_type"
                                        id="currentSelectedProcessBillingType"
                                        style="width: calc(100% + 2px);"
                                        tabindex="104">
                                    <option *ngFor="let x of billingTypes" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption" style="padding-left: 5px;">Telefon Zeiterfassung</span>
                                <br>
                                <select [(ngModel)]="currentSelected.phone_time_start"
                                        id="currentSelectedPhoneTimeStart"
                                        style="width: calc(100% + 2px); margin-left: 5px;"
                                        tabindex="105">
                                    <option value="0">Zeit muss manuell gestartet werden</option>
                                    <option value="1">Zeit wird automatisch Erfasst</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2" style="border-bottom: 1px solid #808080; vertical-align: bottom; height:30px;">
                                E-Mail (optional)
                            </th>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                <span class="formCaption">E-Mail Adresse</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.email"
                                       [ngClass]="{'error' : teamFormError == 2 && currentSelected.email==''}"
                                       id="currentSelectedEmail"
                                       class="cp-textbox"
                                       style="width:calc(100% - 5px);"
                                       tabindex="106" >

                            </td>
                            <td style="width: 50%;">
                                <span class="formCaption" style="padding-left: 5px;">Benutzername</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.username1"
                                       [ngClass]="{'error' : teamFormError == 2 && currentSelected.username1==''}"
                                       id="currentSelectedUsername"
                                       class="cp-textbox"
                                       style="width:calc(100% - 5px); margin-left: 5px;"
                                       tabindex="107" >

                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span class="formCaption">Passwort</span>
                                <input type="password"
                                       [(ngModel)]="currentSelected.password1"
                                       [ngClass]="{'error' : teamFormError == 2 && currentSelected.password1==''}"
                                       id="currentSelectedPassword"
                                       class="cp-textbox"
                                       style="width:calc(100% - 5px);"
                                       tabindex="108" >

                            </td>
                            <td>
                                <span class="formCaption" style="padding-left: 5px;">Mailserver IP</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.host1"
                                       [ngClass]="{'error' : teamFormError == 2 && currentSelected.host1==''}"
                                       id="currentSelectedHost"
                                       class="cp-textbox"
                                       style="width:calc(100% - 5px); margin-left: 5px;"
                                       tabindex="109" >

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Absendername</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.mailboxname"
                                       [ngClass]="{'error' : teamFormError == 2 && currentSelected.mailboxname==''}"
                                       id="currentSelectedMailboxname"
                                       class="cp-textbox"
                                       style="width:calc(100% - 5px);"
                                       tabindex="110" >
                            </td>
                            <td>
                                <span class="formCaption" style="padding-left: 5px;">Absender-Format</span>
                                <br>
                                <select [(ngModel)]="currentSelected.sender_name_format"
                                        [ngClass]="{'error' : teamFormError == 2 && currentSelected.sender_name_format==0}"
                                        id="currentSelectedSenderNameFormat"
                                        style="width: calc(100% + 2px); margin-left: 5px;"
                                        tabindex="111">
                                    <option value="0">Bitte wählen</option>
                                    <option value="1">Teambezeichnung</option>
                                    <option value="2">Name des Senders</option>
                                    <option value="3">Teambezeichnung und Name des Senders</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                        <span class="formCaption">Verschlüsselung: <b>IMAP</b></span>
                                        <br>
                                        <select [(ngModel)]="currentSelected.ssl1"
                                                (change)="setImapPort(false)"
                                                id="currentSelectedSsl1"
                                                style="width:140px; margin-left: -3px;"
                                                tabindex="112">
                                            <option *ngFor="let x of port1Options" value="{{x.encryption}}">{{x.name}}</option>
                                        </select>
                                        </td>
                                        <td>
                                        <span class="formCaption">Port <b>IMAP</b></span><br>
                                        <input type="text"
                                               [(ngModel)]="currentSelected.port1"
                                               [ngClass]="{'error' : teamFormError == 2 && currentSelected.port1==''}"
                                               id="currentSelectedPortIMAP"
                                               class="cp-textbox"
                                               style="width: 60px;"
                                               tabindex="113" >
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <input type="checkbox"
                                       [(ngModel)]="currentSelected.noautoresponse"
                                       id="currentSelectedNoAutoResponse"
                                       style="margin:0px; padding: 0px; margin-left: 8px;" tabindex="114">
                                <label for="currentSelectedNoAutoResponse"> Keine automatische Antwort</label>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                        <span class="formCaption">Verschlüsselung: <b>SMTP</b></span>
                                        <br>
                                        <select [(ngModel)]="currentSelected.ssl2"
                                                (change)="setSmtpPort(false)"
                                                id="currentSelectedPort2"
                                                style="width:140px; margin-left: -3px;"
                                                tabindex="115">
                                            <option *ngFor="let x of port2Options" value="{{x.encryption}}">{{x.name}}</option>
                                        </select>
                                        </td>
                                        <td>
                                        <span class="formCaption">Port <b>SMTP</b></span><br>
                                        <input type="text"
                                               [(ngModel)]="currentSelected.port2"
                                               [ngClass]="{'error' : teamFormError == 2 && currentSelected.port2==''}"
                                               id="currentSelectedPortSMTP"
                                               class="cp-textbox"
                                               style="width: 60px;"
                                               tabindex="116" >
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <input type="checkbox"
                                       [(ngModel)]="currentSelected.delete_mails"
                                       id="currentSelectedDeleteMails"
                                       style="margin:0px; padding: 0px; margin-left: 8px;" tabindex="117">
                                <label for="currentSelectedDeleteMails"> Mails automatisch nach drei Monaten löschen</label>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span class="formCaption">Ordner</span>
                                <br>
                                <select [(ngModel)]="currentSelected.folder"
                                        [ngClass]="{'error' : teamFormError == 2 && currentSelected.folder==''}"
                                        id="currentSelectedFolder"
                                        style="width:140px;"
                                        tabindex="118">
                                    <option value="">Bitte Wählen</option>
                                    <option>INBOX</option>
                                    <option>Gesendete Objekte</option>
                                </select>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="119">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSelectedTab === 3 && currentSelected.id > 0" class="form" style="left: 0; right: 0; top: 0; height: 55px; position: absolute;">
                    <table class="form_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                        <tr>
                            <td>
                                <select [(ngModel)]="categoryAddId"
                                        style="width: calc(100% - 10px); margin-top: 5px; margin-left: 10px;"
                                        tabindex="101">
                                    <option value="-1">Kategorie Wählen</option>
                                    <option *ngFor="let x of dropdownDataCategories" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td style="width: 100px;" class="form">
                                <button (click)="checkTeamCategoryLinK()" style="margin-top: 5px; margin-right: 10px;" tabindex="102">
                                    <img src="../design/icons/add.png"> Hinzufügen
                                </button>
                            </td>
                        </tr>
                    </table>
                    <table class="form_tabel light" style="width: 100%;" cellspacing="0" cellpadding="0">
                        <tr style="height: 20px;">
                            <th>Name</th>
                            <th style="width: 100px;">Standardkategorie</th>
                            <th style="width: 30px;"></th>
                        </tr>
                        <tr>
                    </table>
                </div>
                <div *ngIf="currentSelectedTab === 3 && currentSelected.id > 0" style="left: 0; right: 0; top: 57px; bottom: 0; position: absolute; overflow: auto;">
                    <table class="data_tabel" style="width: 100%;" cellspacing="0" cellpadding="4">
                        <tr *ngFor="let x of teamCategories">
                            <td>&nbsp;{{x.name}}</td>
                            <td style="width: 100px;" (click)="changeMainCategory(x)">
                                <span *ngIf="x.base_category == 1"><img src="../design/icons/tick.png" style="margin-left: 42px;"></span>
                                <span *ngIf="x.base_category == 0"><img src="../design/icons/tick_inactive.png" style="margin-left: 42px;"></span>
                            </td>
                            <td style="width: 25px;">
                                <span *ngIf="x.base_category != 1">
                                    <img src="../design/icons/delete.png" style="margin-left: 5px;" (click)="deleteCategoryLink(x)">
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="infoBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> {{teamLeaderChangeTitle}}</span><BR><BR></div>
    </div>
    <div class="modalContent" [innerHTML]="infoBoxText | safe"></div>
    <div class="modalFooter form" ><button (click)="message.close('infoBox')" style="float: right; margin-top: 3px;">OK</button>
    </div>
</jw-modal>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> {{teamLeaderChangeTitle}}</span><BR><BR></div>
    </div>
    <div class="modalContent">{{teamLeaderChangeContent}}</div>
    <div class="modalFooter form" ><button (click)="getTeamUserList(); message.close('messageBox')" style="float: right; margin-top: 3px;">Abbrechen</button>
        <button *ngIf="teamLeaderChange" (click)="changeTeamLeader(); message.close('messageBox')" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="messageBoxTeamLeaderRole" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style="">&nbsp;Rolle des Teamleiters</span><BR><BR></div>
    </div>
    <div class="modalContent">{{teamLeaderRoleContent}}</div>
    <div class="modalFooter form" >
        <button (click)="message.close('messageBoxTeamLeaderRole')" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="messageBoxSMTPPort" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style="">&nbsp;Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll der für die gewählte Verschlüsselungseinstellung gängige Port <B>{{messageBoxSSLPort}}</B> gesetzt werden?</div>
    <div class="modalFooter form" >
        <button (click)="setSmtpPort(true)" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('messageBoxSMTPPort')" style="float: right; margin-top: 3px;">Nein</button>
    </div>
</jw-modal>

<jw-modal id="messageBoxImapPort" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style="">&nbsp;Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll der für die gewählte Verschlüsselungseinstellung gängige Port <B>{{messageBoxSSLPort}}</B> gesetzt werden?</div>
    <div class="modalFooter form" >
        <button (click)="setImapPort(true)" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('messageBoxImapPort')" style="float: right; margin-top: 3px;">Nein</button>
    </div>
</jw-modal>

<jw-modal id="messageBoxDeactivateTeam" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style="">&nbsp;Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll das Team wirklich deaktiviert werden?</div>
    <div class="modalFooter form" >
        <button (click)="executeDeactivate()" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('messageBoxDeactivateTeam')" style="float: right; margin-top: 3px;">Nein</button>
    </div>
</jw-modal>

<jw-modal id="messageBoxDeleteTeamMember" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style="">&nbsp;Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll der Benutzer wirklich aus dem Team entfernt werden?</div>
    <div class="modalFooter form" >
        <button (click)="deleteTeamMember()" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('messageBoxDeleteTeamMember')" style="float: right; margin-top: 3px;">Nein</button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="switchSecurityLevel($event.value, '0')" >
        <span *ngIf="item?.securityLevel == 1 && securityLevelRight == true" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_blue.png" style="vertical-align: middle">&nbsp; Sicherheitsstufe des Teams entfernen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="switchSecurityLevel($event.value, '1')">
        <span *ngIf="item?.securityLevel == 0 && securityLevelRight == true" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_white.png" style="vertical-align: middle">&nbsp;&nbsp; Sicherheitsstufe des Teams setzen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="switchSecurityLevel($event.value, '2')">
        <span *ngIf="item?.securityLevel == 0 && securityLevelRight == true" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_white.png" style="vertical-align: middle">&nbsp;&nbsp; HinSchG
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>

<context-menu #memberMenu>
    <ng-template contextMenuItem let-item (execute)="deleteUserLink($event.value.id)">
        <span *ngIf="item?.fk_team_role != '5'" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>
