import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-process-management-project',
  templateUrl: './process-management-project.component.html',
  styleUrls: ['./process-management-project.component.css']
})
export class ProcessManagementProjectComponent  implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataTeam = [];
  public dropdownProjectType = [];
  public dropdownDataUser = [];
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public companyId: string;
  public companyCheckId: string;
  public addToProjectUserId = 0;
  public currentProjectUsers = [];
  public newProjectUsers = [];
  public userCount = 0;
  public selectedTeamid;
  public projectResponsibleUserId;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', fk_project_type : '-1', type_name: '', user : '-1', fk_team: '0', sort: '', active: '1', create_date: '', start_date: '',  end_date : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '' };
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Action1'}, { value : '2', name : 'Action2'}];
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<{count, data, status}>(settings.restBaseUrl + 'team', {mailbox: true, active: 1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataTeam = this.dropdownDataTeam.concat(data.data);
        }
    );

    this.http.post<{status, count, data}>(settings.restBaseUrl + 'process/project/types', { 'active':1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownProjectType = this.dropdownProjectType.concat(data.data);
        }
    );
  }

  ngOnInit() {
    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = data;
          this.companyCheckId = data;

          this.search(false);
          setTimeout(() => {
            document.getElementById('searchText').focus();
            this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', {active: 1, company: this.companyId}, this.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                  this.dropdownDataUser = data2.data;
                }
            );
          }, 200);
        }
    );
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/project', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.currentProjectUsers = [];
    this.newProjectUsers = [];
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 200);
  }

  save(): void {
    let userId;
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie einen Projektnamen ein.');
      return;
    } else if (this.currentSelected.fk_project_type < 1) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein. Bitte wählen Sie einen Projekttypen aus.');
      return;
    } else if (this.currentProjectUsers.length < 1 && this.newProjectUsers.length < 1) {
      alert('Zum anlegen eines Projekts muss mindestens ein Projektmitglied als Verantwortliche/r hinzugefügt werden.');
      return;
    } else if (this.currentSelected.id > 0) {
      userId = this.projectResponsibleUserId;
    } else {
      userId = this.newProjectUsers[0].id;
    }

    const projectData = {
      name: this.currentSelected.name,
      user: userId,
      fk_project_type: this.currentSelected.fk_project_type,
      fk_team : this.currentSelected.fk_team,
      start_timestamp: this.currentSelected.start_date,
      end_timestamp: this.currentSelected.end_date,
      active: this.searchParameter.active,
      id: this.currentSelected.id
    };
    this.http.put<{message, status}>(this.settings.restBaseUrl + 'process/project', projectData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const newID = data.message;
          if (this.currentSelected.id > 0) {
            this.search(false);
            this.initCurrentSelected();
            this.showNewItemForm = false;
            this.addToProjectUserId = 0;
          } else {
            this.putNewProjectUserLinks(newID);
          }
        }
    );
  }

  putNewProjectUserLinks(id) {
    let ticketValue;
    let managementValue;
    if (this.userCount === 0) {
      ticketValue = 1;
      managementValue = 1;
    } else {
      ticketValue = 0;
      managementValue = 0;
    }
    const projectUserData = {
      fk_user: this.newProjectUsers[0].id,
      fk_project: id,
      ticket: ticketValue,
      management: managementValue,
      customer: 0
    };
    this.http.put<any[]>(this.settings.restBaseUrl + 'process/project/user', projectUserData, this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          this.newProjectUsers.shift();
          if (this.newProjectUsers.length > 0) {
            this.userCount++;
            this.putNewProjectUserLinks(id);
          } else {
            this.search(false);
            this.initCurrentSelected();
            this.showNewItemForm = false;
            this.userCount = 0;
            this.addToProjectUserId = 0;
          }
        }
    );
  }


  edit($event: MouseEvent, item: any): void  {
    this.newProjectUsers = [];
    this.addToProjectUserId = 0;
    this.http.get<any[]>(this.settings.restBaseUrl + 'process/project/' + item.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.selectedTeamid = this.currentSelected.fk_team;
          this.projectResponsibleUserId = this.currentSelected.user;
          if (this.currentSelected.end_date === '01.01.1970') { this.currentSelected.end_date = ''; }
          if (this.currentSelected.start_date === '01.01.1970') { this.currentSelected.start_date = ''; }
          if (this.currentSelected.fk_project_type === '0') { this.currentSelected.fk_project_type = '-1'; }
          this.showNewItemForm = false;
          this.getProjectUserList();
        });
  }

  getProjectUserList() {
    this.currentProjectUsers = [];
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/project/user', {fk_project: this.currentSelected.id}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentProjectUsers = this.currentProjectUsers.concat(data.data);
          setTimeout(() => {
            document.getElementById('currentSelectedName').focus();
          }, 200);
        }
    );
  }

  checkIfUserAlreadyAssigned() {
    // tslint:disable-next-line:triple-equals
    if (this.addToProjectUserId == 0) {
      alert('Es ist kein Benutzer Ausgewählt. Bitte Wählen Sie einen Benutzer aus um Ihn hinzuzufügen.');
      return;
    } else {
      this.http.post<{status, message}>(this.settings.restBaseUrl + 'process/project/user/check', {fk_project: this.currentSelected.id, fk_user: this.addToProjectUserId}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            const userExistsInProject = data.message;

            if (userExistsInProject === true) {
              alert('Der Benutzer ist diesem Projekt Bereits zugewiesen.');
              return;
            } else {
              if (this.currentSelected.id > 0) {
                this.assignUserToProject();
              } else {
                this.assignUserToNewProject();
              }
            }
          }
      );
    }

  }

  assignUserToNewProject() {
    let userNameValue = '';
    let userCheck = false;
    this.newProjectUsers.forEach((item) => {
      if (this.addToProjectUserId === item.id) {
        userCheck = true;
      }
    });

    if (userCheck) {
      alert('Der Benutzer ist diesem Projekt Bereits zugewiesen.');
      return;
    }

    this.http.get<{id, name, first_name, position}>(this.settings.restBaseUrl + 'contact/' + this.addToProjectUserId, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          userNameValue = userNameValue.concat(data.name, ', ', data.first_name);

          let isCustomer = 0;
          if (this.companyId !== this.companyCheckId) {
            isCustomer = 1;
          }
          const userAssignData = {
            id: data.id,
            user_name: userNameValue,
            position: data.position,
            fk_user: this.addToProjectUserId,
            ticket: 0,
            management: 0,
            customer: isCustomer
          };
          this.newProjectUsers.push(userAssignData);
        }
    );

  }

  assignUserToProject() {
    let isCustomer = 0;
    if (this.companyId !== this.companyCheckId) {
      isCustomer = 1;
    }

    const userAssignData = {
      fk_project: this.currentSelected.id,
      fk_user: this.addToProjectUserId,
      ticket: 0,
      management: 0,
      customer: isCustomer
    };

    this.http.put<{status, message}>(this.settings.restBaseUrl + 'process/project/user', userAssignData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = this.companyCheckId;
          this.getProjectUserList();
        }
    );
  }

  deleteProjectuserLink(item) {
    const deleteItem = window.confirm('Sind Sie sicher, dass Sie diesen Benutzer aus diesem Projekt entfernen möchten?');
    if (deleteItem) {
      this.http.delete<any[]>(this.settings.restBaseUrl + 'process/project/user/' + item.id , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.companyId = this.companyCheckId;
            this.getProjectUserList();
          }
      );
    }
  }

  deleteNewProjectuser(id) {
    for (let i = this.newProjectUsers.length - 1; i >= 0; --i) {
      if (this.newProjectUsers[i].id === id) {
        this.newProjectUsers.splice(i, 1);
      }
    }
  }

  checkProjectResponsibleUser(item) {
    let changeUser = false;
    this.currentProjectUsers.forEach((element) => {
      if (element.fk_user === this.currentSelected.user) {
        changeUser = true;
      }
    });
    if (this.currentSelected.user !== item.fk_user && changeUser) {
      const changeResponsibleUser = window.confirm('Es ist bereits ein anderer Benutzer als Projektverantwortliche/r ausgewählt.\nMöchten Sie den Projektverantwortlichen wirklich wechseln ?');
      if (changeResponsibleUser) {
        this.setProjectResponsibleUser(item);
      }
    } else {
      this.setProjectResponsibleUser(item);
    }
  }

  setProjectResponsibleUser(item) {
    const userData = {
      fk_user: item.fk_user,
      fk_project: this.currentSelected.id
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'process/project/responsible/' + item.id , userData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.currentSelected.user = item.fk_user;
        }
    );
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll das markierte Projekt wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'process/project/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll das markierte Projekt wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'process/project/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'process/project/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxShiftStartElement = false;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxSwapAll(): void  {
    for (const item of this.data) {
      item.checked = this.checkboxAllState;
    }
    this.handleCheckboxChanged(false);
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }
    if (this.checkboxShiftStartElement == null) { this.checkboxShiftStartElement = clickedItem;
    } else {
      let direction = 'backwards';

      for (const item of this.data) {
        if (item === clickedItem)                       {direction = 'forward'; }
        if (item === this.checkboxShiftStartElement)    {direction = 'backwards'; }
      }

      if (direction === 'backwards') {
        const temp = this.checkboxShiftStartElement;
        this.checkboxShiftStartElement = clickedItem;
        clickedItem = temp;
      }

      let mark = false;

      for (const item of this.data) {
        if (item === this.checkboxShiftStartElement) {mark = true; }
        if (mark) {item.checked = true; }
        if (item === clickedItem) { mark = false; }
      }

      this.checkboxShiftStartElement = null;
    }
    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.data.filter((item) => item.checked);
  }

  checkboxActionDropdownChanged(): void {
    if (this.checkboxActionDropdown === '1') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action1_Parameter1'},
        {value: '2', name: 'Action1_Parameter2'},
        {value: '3', name: 'Action1_Parameter3'}];
    } else if (this.checkboxActionDropdown === '2') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action2_Parameter1'},
        {value: '2', name: 'Action2_Parameter2'},
        {value: '3', name: 'Action2_Parameter3'}];
    }
  }

  handleCheckboxAction(): void  {
    this.handleCheckboxChanged(false);
    let alertString =   'Checkboxes Action: ' + this.checkboxActionDropdown +
        ' Parameter: ' + this.checkboxActionParameterDropdown +
        ' - Boxes: ';
    for (const item of this.checkboxData) {
      alertString += ' - ' + item.id;
    }
    alert(alertString);
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
