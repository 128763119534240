import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsService} from "../../services/settings.service";
import {ModalService} from "../../_modal";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-whistleblower',
  templateUrl: './whistleblower.component.html',
  styleUrls: ['./whistleblower.component.css']
})
export class WhistleblowerComponent implements OnInit {

  public rightContainerWidth;
  public leftContainerWidth;
  public reporttext;
  public editorWarningString;
  public text;
  public whistleblowerList;

  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router, public route: ActivatedRoute) {

            this.whistleblowerLoadList();


  }

  ngOnInit(): void {
  }

  whistleblowerLoadList() {

    this.text = {reporttext: this.reporttext, sessionId: this.settings.sessionId};

    this.http.post<any[]>(this.settings.restBaseUrl + 'whistleblower/reportlist', JSON.stringify(this.text), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.whistleblowerList = data;
        }
    );

  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  saveReply()
  {
    this.text = {reporttext: this.reporttext, sessionId: this.settings.sessionId};

    this.http.put<{data, message}>(
        this.settings.restBaseUrl + 'whistleblower/reply' , JSON.stringify(this.text), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.whistleblowerLoadList();
        });

    this.reporttext = "";

  }

}
