import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateComponent } from './core/template/template.component';
import { DocumentRightGroupComponent } from './document/document-right-group/document-right-group.component';
import { TextBlockComponent } from './core/text-block/text-block.component';
import { GlobalFavoriteComponent } from './core/core-global-favorite-management/core-global-favorite-management.component';
import { ProcessStatisticWorktimeComponent } from './process/process-statistic-worktime/process-statistic-worktime.component';
import { TagsComponent } from './document/tags/tags.component';
import { ErpCostCentreComponent } from './erp/erp-cost-centre/erp-cost-centre.component';
import { ErpReceiptTypeComponent } from './erp/erp-receipt-type/erp-receipt-type.component';
import { CoreMailQueueComponent } from './core/core-mail-queue/core-mail-queue.component';
import { ErpStoreComponent } from './erp/erp-store/erp-store.component';
import { ErpNumberRangeComponent } from './erp/erp-number-range/erp-number-range.component';
import { ErpProductGroupComponent } from './erp/erp-product-group/erp-product-group.component';
import { ErpPrintTemplateComponent } from './erp/erp-print-template/erp-print-template.component';
import { ErpUnitComponent } from './erp/erp-unit/erp-unit.component';
import { ProcessProjectListComponent } from './process/process-project-list/process-project-list.component';
import { ProcessSearchComponent } from './process/process-search/process-search.component';
import { DocumentInFolderComponent } from './document/document-in-folder/document-in-folder.component';
import { UserManagerComponent } from './core/user-manager/user-manager.component';
import { GroupManagerComponent } from './core/group-manager/group-manager.component';
import { ProcessTeamManagementComponent } from './process/process-team-management/process-team-management.component';
import { ProcessCategoryAndStatusManagementComponent } from './process/process-category-and-status-management/process-category-and-status-management.component';
import { InventoryCheckTypeManagementComponent } from './inventory/inventory-check-type-management/inventory-check-type-management.component';
import { InventoryPlaceManagementComponent } from './inventory/inventory-place-management/inventory-place-management.component';
import { InventoryTypeManagementComponent } from './inventory/inventory-type-management/inventory-type-management.component';
import { CoreLicenceManagementComponent } from './core/core-licence-management/core-licence-management.component';
import { ProcessWorkflowManagementComponent } from './process/process-workflow-management/process-workflow-management.component';
import { CoreBaseDataManagementComponent } from './core/core-base-data-management/core-base-data-management.component';
import { CoreInformationComponent}  from './core/core-information/core-information.component';
import { ErpAccountSystemManagementComponent } from './erp/erp-account-system-management/erp-account-system-management.component';
import { DocumentAutoRecognitionManagementComponent } from './document/document-auto-recognition-management/document-auto-recognition-management.component';
import { ProcessComponent } from './process/process/process.component';
import { LoginComponent } from './core/login/login.component';
import { ProcessProjectTypeManagementComponent } from './process/process-project-type-management/process-project-type-management.component';
import { CompanyManagementComponent } from './core/company-management/company-management.component';
import { ProcessManagementProjectComponent } from './process/process-management-project/process-management-project.component';
import { DocumentAssignComponent } from './document/document-assign/document-assign.component';
import { DocumentSearchComponent } from './document/document-search/document-search.component';
import { ProcessManagementBillingCategoryComponent } from './process/process-management-billing-category/process-management-billing-category.component';
import { ProcessManuallyAssignComponent } from './process/process-manually-assign/process-manually-assign.component';
import { UrlframeComponent } from './core/urlframe/urlframe.component';
import { CoreUserProfileManagementComponent } from './core/core-user-profile-management/core-user-profile-management.component';
import { ProcessFinishWorkdocumentComponent } from './process/process-finish-workdocument/process-finish-workdocument.component';
import { ProcessMergeComponent } from './process/process-merge/process-merge.component';
import { ProcessSearchWorkdocumentComponent } from './process/process-search-workdocument/process-search-workdocument.component';
import { CoreHelpComponent } from './core/core-help/core-help.component';
import { CoreNoRightWarningComponent } from './core/core-no-right-warning/core-no-right-warning.component';
import { ErpOutgoingBillComponent } from './erp/erp-outgoing-bill/erp-outgoing-bill.component';
import { TimeTrackingTypeManagementComponent } from './time_tracking/time-tracking-type-management/time-tracking-type-management.component';
import { TimeTrackingStateManagementComponent } from './time_tracking/time-tracking-state-management/time-tracking-state-management.component';
import { TimeTrackingAbsenceTypeManagementComponent } from './time_tracking/time-tracking-absence-type-management/time-tracking-absence-type-management.component';
import { TimeTrackingAbsenceComponent } from './time_tracking/time-tracking-absence/time-tracking-absence.component';
import { TimeTrackingErrorComponent } from './time_tracking/time-tracking-error/time-tracking-error.component';
import { TimeTrackingUserStatementComponent } from './time_tracking/time-tracking-user-statement/time-tracking-user-statement.component';
import { DocumentAutoRecognitionTestComponent } from './document/document-auto-recognition-test/document-auto-recognition-test.component';
import { PasswordComponent } from './crm/password/password.component';
import { WorkflowComponent } from './workflow/workflow/workflow.component';
import { WorkflowRunningComponent } from './workflow/workflow-running/workflow-running.component';
import { WorkflowFormComponent } from './workflow/workflow-form/workflow-form.component';
import { DocumentPortalComponent } from './document/document-portal/document-portal.component';
import { KnowhowComponent } from './crm/knowhow/knowhow.component';
import { PrinterStatisticComponent } from './hardware/printer-statistic/printer-statistic.component';
import { CoreSessionComponent } from './core/core-session/core-session.component';
import { MeetingRunComponent } from './meeting/meeting-run/meeting-run.component';
import { MeetingArchiveComponent } from './meeting/meeting-archive/meeting-archive.component';
import { MeetingGroupComponent } from './admin/meeting-group/meeting-group.component';
import { ProcessTimelineComponent } from './controlling/process-timeline/process-timeline.component';
import { InventoryPlaceComponent } from './admin/inventory-place/inventory-place.component';
import { ErpBookingTextTemplateComponent } from './admin/erp-booking-text-template/erp-booking-text-template.component';
import { TimeTrackingComponent } from "./time_tracking/time-tracking/time-tracking.component";
import {AdminRightComponent} from "./admin/admin-right/admin-right.component";
import {WhistleblowerComponent} from "./whistleblower/whistleblower/whistleblower.component";
import {WhistleblowerReportComponent} from "./whistleblower/whistleblower-report/whistleblower-report.component";
import {CustomerDocumentComponent} from "./customer-portal/customer-document/customer-document.component";
import {UnlockComponent} from "./admin/unlock/unlock.component";
import {AdminSpamComponent} from "./admin/admin-spam/admin-spam.component";


const routes: Routes = [
  { path: '', component: ProcessComponent, pathMatch: 'full'},

  { path: 'template', component: TemplateComponent},

  { path: 'inventory-place', component: InventoryPlaceComponent},


  { path: 'login/:hash', component: LoginComponent},
  { path: 'login', component: LoginComponent},

  { path: 'core-help', component: CoreHelpComponent},
  { path: 'core-no-right-warning', component: CoreNoRightWarningComponent},
  { path: 'core-frame/:url', component: UrlframeComponent},
  { path: 'core-text-block', component: TextBlockComponent},
  { path: 'core-global-favorite-management', component: GlobalFavoriteComponent},
  { path: 'core-base-data-management', component: CoreBaseDataManagementComponent},
  { path: 'core-information/:id', component: CoreInformationComponent},
  { path: 'core-information/:id/:space', component: CoreInformationComponent},

  // Admin Bereich
  { path: 'core-user-manager', component: UserManagerComponent},
  { path: 'core-user-manager/:action/:id', component: UserManagerComponent},
  { path: 'core-user-manager/:action/:id/:name', component: UserManagerComponent},

  { path: 'user-group-management', component: GroupManagerComponent},

  { path: 'admin-right', component: AdminRightComponent},
  { path: 'admin-unlock', component: UnlockComponent},
  { path: 'admin-spam', component: AdminSpamComponent},

  { path: 'core-licence-management', component: CoreLicenceManagementComponent},
  { path: 'core-mail-queue', component: CoreMailQueueComponent},
  { path: 'core-company', component: CompanyManagementComponent},

  { path: 'core-user-profile-management', component: CoreUserProfileManagementComponent},
  { path: 'core-session', component: CoreSessionComponent},

  { path: 'process/:list', component: ProcessComponent},
  { path: 'process/:id/:action/:list/:active', component: ProcessComponent},
  { path: 'process/:id/:action', component: ProcessComponent},

  { path: 'process-statistic-worktime', component: ProcessStatisticWorktimeComponent},
  { path: 'process-project-list/:project', component: ProcessProjectListComponent},
  { path: 'process-search', component: ProcessSearchComponent},
  { path: 'process-team-management', component: ProcessTeamManagementComponent},
  { path: 'process-category-and-status-management', component: ProcessCategoryAndStatusManagementComponent},
  { path: 'process-project-management', component: ProcessProjectListComponent},
  { path: 'process-project-type-management', component: ProcessProjectTypeManagementComponent},
  { path: 'process-workflow-management', component: ProcessWorkflowManagementComponent},
  { path: 'process-management-project', component: ProcessManagementProjectComponent},
  { path: 'process-management-billing-category', component: ProcessManagementBillingCategoryComponent},
  { path: 'process-manually-assign', component: ProcessManuallyAssignComponent},
  { path: 'process-finish-workdocument', component: ProcessFinishWorkdocumentComponent},
  { path: 'process-merge/:list', component: ProcessMergeComponent},
  { path: 'process-search-workdocument', component: ProcessSearchWorkdocumentComponent},

  { path: 'controlling-process-timeline', component: ProcessTimelineComponent},

  { path: 'customer-portal', component: CustomerDocumentComponent},

  { path: 'whistleblower', component: WhistleblowerComponent},
  { path: 'whistleblower-report', component: WhistleblowerReportComponent},

  { path: 'workflow', component: WorkflowComponent},
  { path: 'workflow-form', component: WorkflowFormComponent},
  { path: 'workflow-running', component: WorkflowRunningComponent},

  { path: 'meeting-run', component: MeetingRunComponent},
  { path: 'meeting-archive', component: MeetingArchiveComponent},
  { path: 'meeting-group', component: MeetingGroupComponent},

  { path: 'knowhow', component: KnowhowComponent},
  { path: 'password', component: PasswordComponent},

  { path: 'erp-cost-centre', component: ErpCostCentreComponent},
  { path: 'erp-receipt-type', component: ErpReceiptTypeComponent},
  { path: 'erp-store', component: ErpStoreComponent},
  { path: 'erp-unit', component: ErpUnitComponent},
  { path: 'erp-product-group', component: ErpProductGroupComponent},
  { path: 'erp-print-template', component: ErpPrintTemplateComponent},
  { path: 'erp-number-range', component: ErpNumberRangeComponent},
  { path: 'erp-account-system-management', component: ErpAccountSystemManagementComponent},
  { path: 'erp-outgoing-bill', component: ErpOutgoingBillComponent},
  { path: 'erp-booking-text-template', component: ErpBookingTextTemplateComponent},

  { path: 'document-right-group', component: DocumentRightGroupComponent},
  { path: 'document-tags', component: TagsComponent},
  { path: 'document-in-folder', component: DocumentInFolderComponent},
  { path: 'document-auto-recognition-management', component: DocumentAutoRecognitionManagementComponent},
  { path: 'document-auto-recognition-test/:id', component: DocumentAutoRecognitionTestComponent},
  { path: 'document-assign', component: DocumentAssignComponent},
  { path: 'document-assign/:id', component: DocumentAssignComponent},
  { path: 'document-assign/:action/:id', component: DocumentAssignComponent},
  { path: 'document-search', component: DocumentSearchComponent},
  { path: 'document-search/:id', component: DocumentSearchComponent},
  { path: 'document-portal', component: DocumentPortalComponent},

  { path: 'hardware-printer-statistic', component: PrinterStatisticComponent},

  { path: 'inventory-place', component: InventoryPlaceComponent},
  { path: 'inventory-type-management', component: InventoryTypeManagementComponent},
  { path: 'inventory-place-management', component: InventoryPlaceManagementComponent},
  { path: 'inventory-check-type-management', component: InventoryCheckTypeManagementComponent},

  { path: 'time-tracking', component: TimeTrackingComponent},
  { path: 'time-tracking-absence', component: TimeTrackingAbsenceComponent},
  { path: 'time-tracking-type-management', component: TimeTrackingTypeManagementComponent},
  { path: 'time-tracking-state-management', component: TimeTrackingStateManagementComponent},
  { path: 'time-tracking-absence-type-management', component: TimeTrackingAbsenceTypeManagementComponent},
  { path: 'time-tracking-error', component: TimeTrackingErrorComponent},
  { path: 'time-tracking-user-statement', component: TimeTrackingUserStatementComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  // imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
}
