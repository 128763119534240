<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <div class="form" style="margin-left:10px; margin-top:5px;">
                    <button *ngIf="false" (click)="changeHelpView(1)">Handbuch</button>
                    <button (click)="changeHelpView(2)">Interne VAs</button>
                    <button *ngIf="false" (click)="changeHelpView(3)" style="margin-left:5px;">FAQ</button>
                    <button *ngIf="false" (click)="changeHelpView(4)" style="margin-left:5px;">Lernvideos</button>
                    <button (click)="changeHelpView(5)">Änderungshistorie</button>
                </div>

                <ng-container *ngIf="currentHelpView==5">
                    <span class="form search">
                        <input type="text"
                               (keydown)="searchOnReturnPressed($event)"
                               [(ngModel)]="searchParameter.text"
                               id="searchText"
                               style="width:256px;"
                               tabindex="1"
                               [ngClass]="{ 'error' : searchParameter.text != '' }">

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.year"
                                style="width:80px;"
                                tabindex="2"
                                [ngClass]="{ 'error' : searchParameter.year != '' }">
                            <option value="">Alle Jahre</option>
                            <option *ngFor="let x of dropdownDataYears" [value]="x">{{x}}</option>
                        </select>

                        <button (click)="resetSearch()"
                                style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                                tabindex="4">
                            <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                        </button>

                        <button (click)="search(false)"
                                                    tabindex="5">
                            <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                        </button>
                    </span>

                    <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                        <tr>
                            <th style="width:90px; text-align: center;">
                                Modul
                            </th>
                            <th>
                                Änderung
                            </th>
                            <th style="width:90px;">
                                Entwickler
                            </th>
                        </tr>
                    </table>
                </ng-container>
            </div>


            <div id="leftContainerContent">


                <ng-container *ngIf="currentHelpView==2">
                    <div class="loading" ng-show="!vadata"></div>
                    <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                        <tr *ngFor="let x of vadata" (click)="viewVa(x)">
                            <td>
                                {{x.name}}
                            </td>
                        </tr>
                    </table>
                </ng-container>

                <ng-container *ngIf="currentHelpView==5">
                    <div class="loading" ng-show="!data"></div>
                    <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                        <tr *ngFor="let x of data" (click)="view(x)">
                            <td>
                                {{x.module}}
                            </td>
                            <td>
                                {{x.name}}
                            </td>
                            <td>
                                {{x.developer}}
                            </td>
                        </tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="currentHelpView==1 || currentHelpView==3 || currentHelpView==4">
                    <iframe *ngIf="showChangeLog==false" [src]="helpUrl | safeurl"  id="documentView" name="documentView" style="width:100%;height:100%; overflow: hidden; border: none;"></iframe>
                </ng-container>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">
                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden;">
                <ng-container *ngIf="currentHelpView==2">
                    <iframe [src]="vaUrl | safeurl"  id="documentView" name="documentView" style="width:100%;height:100%; overflow: hidden; border: none;"></iframe>
                </ng-container>

                <div style="margin: 20px;" *ngIf="currentSelected!=null">
                    {{currentSelected.text}}
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>
