import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import {ModalService} from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-time-tracking-absence',
  templateUrl: './time-tracking-absence.component.html',
  styleUrls: ['./time-tracking-absence.component.css']
})
export class TimeTrackingAbsenceComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public httpOptions = { };
  public dropdownAbsenceType;
  public itemToDelete;
  public messageboxTitle: string;
  public messageboxContent: string;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', fk_core_user: '-1', text: '', startDateString : '', endDateString : '', fk_time_tracking_absence_type: '-1', approved: '0' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'start_timestamp', text: '', year : '', user : '-1', type: '-1', dateFrom : '', dateTo : '', processed: '0', requires_approval: '-1' };
  }

  constructor(public http: HttpClient, public settings: SettingsService, public router: Router, public message: ModalService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'user/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'timeTracking/absence/type', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownAbsenceType = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'timeTracking/absence', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

  save(): void {
    if (this.currentSelected.fk_core_user === '-1') {
      this.messageboxTitle = 'Benutzer auswählen';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgewählt werden. Bitte wählen Sie einen Benutzer aus.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.startDateString === '') {
      this.messageboxTitle = 'Anfangsdatum auswählen';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgewählt werden. Bitte wählen Sie einen Anfangsdatum aus.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.endDateString === '') {
      this.messageboxTitle = 'Enddatum auswählen';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgewählt werden. Bitte wählen Sie einen Enddatum aus.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.fk_time_tracking_absence_type === '-1') {
      this.messageboxTitle = 'Abwesenheits-Typ auswählen';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgewählt werden. Bitte wählen Sie einen Abwesenheits-Typ aus.';
      this.message.open('messageBox');
      return;
    }


    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }

    const saveData = {
      request: 0,
      fk_core_user: this.currentSelected.fk_core_user,
      dateFrom: this.currentSelected.startDateString,
      dateTo: this.currentSelected.endDateString,
      text: this.currentSelected.text,
      fk_time_tracking_absence_type: this.currentSelected.fk_time_tracking_absence_type,
      approved: this.currentSelected.approved,
      id: this.currentSelected.id
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'timeTracking/absence' + endpointIdAdd, saveData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(this.settings.restBaseUrl + 'timeTracking/absence/' + item.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          document.getElementById('currentSelectedName').focus();
        });
  }

  approve(item, state): void  {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'timeTracking/absence/approval/' + item.id + '/' + state, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
  }

  openDeleteMessageBox(item) {
    this.itemToDelete = item;
    this.message.open('deleteMessageBox');
  }

  closeDeleteMessageBox() {
    this.itemToDelete = null;
    this.message.close('deleteMessageBox');
  }

  delete(item): void  {
    this.message.close('deleteMessageBox');
    this.http.delete<any[]>(this.settings.restBaseUrl + 'timeTracking/absence/' + item.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // @ts-ignore
          if (data.status === 'error') {
            this.messageboxTitle = 'Abwesenheit abgeschlossen';
            this.messageboxContent = 'Die Abwesenheit kann nicht gelöscht werden, da sie bereits komplett abgeschlossen ist /' +
                ' in der Vergangenheit liegt';
            this.message.open('messageBox');
          } else {
            this.initCurrentSelected();
            this.showNewItemForm = false;
            this.itemToDelete = null;
            setTimeout(() => {
              this.search(false);
            }, 300);
          }
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }
}
