import {Component, Input, Output, OnInit, OnChanges, EventEmitter, ViewChild} from '@angular/core';
import {ContextMenuService, ContextMenuComponent} from '@perfectmemory/ngx-contextmenu';
import {SettingsService} from '../../services/settings.service';
import {ModalService} from '../../_modal';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
    selector: '[app-form]',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

    @Input() id: number;
    @Input() workflowStep: number;
    @Input() assignedUser: number;
    @Output() finishAction: EventEmitter<any> = new EventEmitter();

    private httpOptions: { headers: any };
    public formDefinition = null;
    public formData = {};
    public redirectToUser = {id:-1, error:0};
    public workflowUserRedirectData: any;
    public userId = "";
    private saveInProgress = false;


    constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
        this.httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json', token: settings.sessionId})};

    }

    ngOnInit(): void {
        // Formular definition laden
        if (this.workflowStep) {
            this.http.get<any>(this.settings.restBaseUrl + 'workflow/form/' + this.workflowStep, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.id = data.message;
                    this.loadForm(this.id);
                }
            );
        } else {
            this.loadForm(this.id);
        }
        this.http.post<any>(this.settings.restBaseUrl + 'workflow/user/redirect/' + this.workflowStep, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.workflowUserRedirectData = data.data;
            }
        );

        this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    this.userId = data.data.userId;
                }
            ));

    }

    loadForm(id) {
        this.http.get<any>(this.settings.restBaseUrl + 'ui/form/definition/' + id, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.formDefinition = data;
            }
        );
    }

    save() {
        if ( this.saveInProgress == true) { return; }
        this.saveInProgress = true;
        // Daten an Workflow Endpunkt senden
        if (this.assignedUser < 1) {
            this.assignedUser = 0;
        }

        this.http.put<any>(this.settings.restBaseUrl + 'workflow/form/' + this.workflowStep + '/' + this.assignedUser, JSON.stringify(this.formData), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.finishAction.emit();
                this.saveInProgress = false;
            }
        );
    }

    redirectToSelectedUser() {
        if(this.redirectToUser.id==-1) { this.redirectToUser.error = 1; return; }
        this.http.put<any>(this.settings.restBaseUrl + 'workflow/user/redirect/' + this.workflowStep + '/' + this.redirectToUser.id, JSON.stringify(this.formData), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.finishAction.emit();
            }
        );
    }

    cancelWorkflow() {
        this.http.put<any>(this.settings.restBaseUrl + 'workflow/cancel/' + this.workflowStep, JSON.stringify(this.formData), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.finishAction.emit();
            }
        );
    }
}
