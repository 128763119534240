import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import {ActivatedRoute, Router } from '@angular/router';
import {TutorialService} from '../../services/tutorial.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-process-timeline',
  templateUrl: './process-timeline.component.html',
  styleUrls: ['./process-timeline.component.css']
})
export class ProcessTimelineComponent implements OnInit {
  public searchParameter;
  public data: any;
  public user;
  public viewMinTimestamp: number;
  public viewMaxTimestamp: number;
  public viewRangeTimestamp: number;
  public viewRangePercent: number;
  constructor(public router: Router,
              public http: HttpClient,
              public settings: SettingsService,
              public tutorial: TutorialService,
              public route: ActivatedRoute,
              public message: ModalService,
              public sanitizer: DomSanitizer) {
    this.initSearchParameter();
  }

  ngOnInit(): void {
    this.search(false);
  }

  search(b: boolean) {
    this.http.post<{status,data}>(
        this.settings.restBaseUrl + 'process/controlling/timeline', JSON.stringify(this.searchParameter), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data.data;
          let currentUser = 0;
          this.user = [];
          this.viewMinTimestamp = 0;
          this.viewMaxTimestamp = 0;
          // Alle User und die kleinste und Größte Zeit ermitteln
          for (const row of data.data) {
            if(row.send_time === 0) {
              row.send_time = 100;
            }
            if(row.send_time < this.viewMinTimestamp || this.viewMinTimestamp === 0) {
              this.viewMinTimestamp = row.send_time;
            }
            if((row.send_time + row.time_duration) > this.viewMaxTimestamp || this.viewMaxTimestamp === 0) {
              this.viewMaxTimestamp = row.send_time + row.time_duration;
            }
            if(currentUser !== row.creator) {
              currentUser = row.creator;
              this.user.push(row.creator)
            }
          }
          this.viewRangeTimestamp = this.viewMaxTimestamp - this.viewMinTimestamp;
          this.viewRangePercent = 100 / this.viewRangeTimestamp;
        });
  }

  resetSearch() {
    this.initSearchParameter();
  }

  private initSearchParameter() {
    const todayDate  = new Date();
    let day = todayDate.getDate().toString();
    if (day.toString().length < 2) { day = '0' + day; }
    let month = (todayDate.getMonth()+1).toString();
    if (month.toString().length < 2) { month = '0' + month; }
    const searchDate = day + '.' + month + '.' + todayDate.getFullYear().toString();
    this.searchParameter = {searchDate , fk_user: '-1'};
  }
}
