<div class="form">
    <select style="width:150px;" [(ngModel)]="internalBillingType" (change)="changeSelect();">
        <option *ngFor="let x of dropdownDataBillingType" value="{{x.id}}">{{x.name}}</option>
    </select>

<button *ngIf="!timerRunning && mode != 'manual'" (click)="changeTimer('start')"><img src="../design/icons/control_play_blue.png"> Start </button>
<button *ngIf="timerRunning && mode != 'manual'" (click)="changeTimer('pause')"><img src="../design/icons/control_pause_blue.png"> Pause </button>
<span *ngIf="mode != 'manual'"><div style="display:inline-block;margin-left:5px;  margin-right: 5px; font-weight: bold;">{{ time * 1000 | date:'H:mm:ss':'UTC'}}</div> ➤</span>
<span *ngIf="mode != 'manual' && currentBillingData!=undefined"><input id="workingUnitsElement" type="text" [readonly]="timeReadonly" style="width:50px;text-align: right;" [(ngModel)]="workingUnits" (click)="changeToManualTime()" (blur)="setManualEntry()"> {{currentBillingData.unit}}</span>
<span *ngIf="mode == 'manual' && currentBillingData!=undefined"><input id="workingUnitsManualElement" type="text" style="width:50px; text-align: right;" [(ngModel)]="workingUnits" (keyup)="setManualEntry()"> {{currentBillingData.unit}}</span>
</div>

<jw-modal class="jw-modal shadow" id="timeModeChange">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Warnung!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Sind Sie sicher das sie in den manuellen Erfassungsmodus für die Zeit wechseln wollen?<br>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button  (click)="cancelManualTime()" style="margin-top: 3px;margin-left: 3px; float: right;" >Abbrechen</button>
        <button  (click)="setManualTime()" style="margin-top: 3px;margin-left: 3px; float: right;" id="setTimeManual">Zeit manuell erfassen</button>
    </div>
</jw-modal>
