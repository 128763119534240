import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import {ModalService} from '../../_modal';


@Component({
  selector: 'app-process-finish-workdocument',
  templateUrl: './process-finish-workdocument.component.html',
  styleUrls: ['./process-finish-workdocument.component.css']
})
export class ProcessFinishWorkdocumentComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public selectedDocument;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public httpOptions = { };
  public companyId;
  public fileToLoad;
  public processBillingTypes;
  public saveWithoutWorkingTime = false;


  initCurrentSelected(): void {
    this.currentSelected = { text : '', time_no_charge: '0', engineer : '-1', date: '', fk_process_billing_type: '1', time_duration: '' };
  }

  initSelectedDocument(): void {
    this.selectedDocument = { id : '', tid: '', doc_id: '', extern: '', filepath: ''};
  }

  initSearchParameter(): void {
    this.searchParameter = { text: '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    this.initCurrentSelected();
    this.initSelectedDocument();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data1 => data1)).subscribe(
        data1 => {
          this.companyId = data1;

          this.http.post<{count, status, data}>(settings.restBaseUrl + 'user', { company: this.companyId, active: 1}, this.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
                this.dropdownDataUser = data2.data;
        }
    );
  }
);

    this.http.post<any[]>(settings.restBaseUrl + 'process/billing/type', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.processBillingTypes = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<any[]>(this.settings.restBaseUrl + 'process/pendingDocument', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data;
          setTimeout(() => {
            if ( this.data && this.data.length > 0 ) { this.edit(this.data[0]); }
          }, 300);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  doSaveWithoutWorkingTime() {
    this.saveWithoutWorkingTime = true;
    this.message.close('withoutWorkingTime');
    this.save();
  }

  save(): void {
    if ( (this.currentSelected.time_duration === '' || this.currentSelected.time_duration < 1) && this.saveWithoutWorkingTime === false ) {
      this.message.open('withoutWorkingTime'); return;
    }
    if ( this.saveWithoutWorkingTime ) { this.currentSelected.time_duration = 0; this.saveWithoutWorkingTime = false; } // Wenn bestätigt Arbeitszeit auf 0 setzen
    if ( this.currentSelected.time_duration === '' || this.currentSelected.text === '' || this.currentSelected.date === '' || this.currentSelected.engineer === '-1' || this.currentSelected.fk_process_billing_type === '' || this.currentSelected.time_no_charge === '') {
      alert('Die Felder Abschlusstext, Datum, Arbeitswerte, Abrechnungseinheit und Erledigt von müssen ausgefüllt werden.');
      return;
    }
    const saveData = {
      type: 'document',
      tid: this.selectedDocument.tid,
      extern: this.selectedDocument.extern,
      date: this.currentSelected.date,
      time_duration: this.currentSelected.time_duration,
      text: this.currentSelected.text,
      engineer: this.currentSelected.engineer,
      fk_process_billing_type: this.currentSelected.fk_process_billing_type,
      time_no_charge: this.currentSelected.time_no_charge
    };

    this.http.patch<any[]>(
        this.settings.restBaseUrl + 'process/entry/' + this.selectedDocument.id, saveData ,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.initSelectedDocument();
          setTimeout(() => {
            if (this.data.length < 1 || !this.data) {
              this.fileToLoad = null;
              // @ts-ignore
              document.getElementById('documentView').src = 'about:blank';
            } else {
              this.edit(this.data[0]);
            }
          }, 300);        }
    );
  }

  edit(item: any): void  {
    this.initCurrentSelected();
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'process/workdocument/finish/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.selectedDocument = data;
          this.fileToLoad = this.selectedDocument.filepath;
          setTimeout(() => {
            document.getElementById('currentSelectedText').focus();
          }, 300);
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
