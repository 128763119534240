<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Workflow > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="workflow" selected>Workflow</option>
                        <option value="workflow-form">Formulare</option>
                        <option value="workflow-running">Laufende Workflows</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2"
                            [ngClass]="{ 'error' : searchParameter.year != '' }">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <app-contact-manager
                            mode="contact"
                            onChange="ngOnChanges()"
                            [(contact)]="searchParameter.fk_contact"
                            [markOnSet]="true"
                            style="width:170px;" >
                    </app-contact-manager>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;"></span>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4"
                                [ngClass]="{ 'error' : searchParameter.user != '' }">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th *ngIf="tableSettings.id.state" style="width:50px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="AKTIV"  sortColumn="active"></span>
                        </th>
                        <th *ngIf="tableSettings.sort.state" style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="SORT" sortColumn="sort"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_fk_user"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td  *ngIf="tableSettings.id.state" (click)="edit($event,x)" style="width:50px; text-align: center;">
                            <img (click)="setActive(x,1)" *ngIf="x.active==0" src="../design/icons/tick_inactive.png" style="vertical-align: sub">
                            <img (click)="setActive(x,0)" *ngIf="x.active==1" src="../design/icons/tick.png" style="vertical-align: sub">
                        </td>
                        <td  *ngIf="tableSettings.sort.state" (click)="edit($event,x)" style="width:80px;">{{ x.sort }}</td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)">{{ x.name }}</td>

                        <td  *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)" style="width:150px;">{{ x.create_user }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="currentSelected && showNewItemForm" style="margin-left:10px; margin-top:6px;"> Neuen Workflow anlegen </div>
                <div *ngIf="currentSelected && !showNewItemForm" style="margin-left:10px; margin-top:6px;"> Workflow bearbeiten </div>
            </div>

            <div id="rightContainerContent" style="vertical-align: center; overflow-x: hidden;">
                <BR>
                <span *ngIf="showNewItemForm">
                    <div style="width:95%; border:1px solid lightgrey;" class="card form">
                      <table style="width:100%;" cellpadding="6" cellspacing="0">
                            <tr>
                                <td style="width:66%;">
                                    <img src="../design/icons/start-event-message.256x256.png" style="width: 24px;display: inline-block;margin-right:8px; margin-left: 2px; vertical-align: top;">
                                    <span style="display: inline-block; width: calc( 100% - 45px )"><span class="formCaption">Bezeichnung des neuen Workflows:</span><BR>
                                        <input type="text" [(ngModel)]="currentSelected.name" style="width: 100%;">
                                    </span>
                                </td>
                                <td>
                                    <span class="formCaption">Start Event:</span><BR>
                                        <select style="width: 100%;" [(ngModel)]="currentSelected.fk_workflow_event">
                                            <option value="-1">Bitte wählen</option>
                                            <option *ngFor="let x of dataWorkflowEvent" [value]="x.id">{{x.name}}</option>
                                        </select>
                                </td>
                            </tr>
                          <tr>
                              <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                              </td>
                          </tr>
                        </table>
                    </div>
                </span>
                <span *ngIf="currentSelected.id>0">

                    <div style="width:95%; border:1px solid lightgrey;" class="card">
                        <table class="data_tabel" style="width:100%;margin-bottom: -2px;" cellpadding="4">
                            <tr>
                                <td colspan="2">
                                    <img src="../design/icons/start-event-message.256x256.png" style="width: 24px;display: inline-block;margin-right:8px;">
                                    <span style="display: inline-block;"><b>Workflow:</b><BR>
                                    {{currentSelected.name}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%;"><b>Erstellt von:</b> {{currentSelected.create_user_string}}</td>
                                <td style="width:50%;"><b>Erstellt am:</b> {{currentSelected.create_timestamp_string}}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Modul:</b> {{currentSelected.module_name}}
                                </td>
                                <td>
                                    <b>Ereigniss:</b> {{currentSelected.event_name}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"  >
                                    <div style="min-height: 40px;" class="form">
                                        <b>Filter:</b> <button *ngIf="!editWorkflowFilter" (click)="editWorkflowFilterActivate()" tooltip="Filter bearbeiten" style="border-top: 1px solid lightgray !important; position: absolute; right: 5px; top: 91px; width: 32px; min-width: 32px; border-radius: 0px 0px 3px 3px !important; -moz-border-radius: 0px 0px 3px 3px !important; -webkit-border-radius: 0px 0px 3px 3px !important;" ng-reflect-tooltip-value="Bearbeiten" class="ng-star-inserted"><img _ngcontent-imd-c156="" src="../design/icons/pencil.png"></button>

                                        <div *ngIf="!editWorkflowFilter" [innerHTML]="currentSelected.event_filter | prettyjson"></div>
                                        <div *ngIf="editWorkflowFilter" class="form">
                                            <div *ngFor="let x of eventEditData.possible_filter">
                                                <B>{{x.description}}:</B><BR>
                                                <select (change)="elementToAdd(x)" [(ngModel)]="elementToAddId">
                                                    <option value="-1">Hinzufügen</option>
                                                    <option *ngFor="let y of eventEditData[x.target]" [value]="y.id">{{y.name}}</option>
                                                </select>

                                                <span *ngFor="let z of currentSelected.event_filter[x.name]" style="display: inline-block">
                                                    <button>{{z.id}} - {{z.name}}</button>
                                                </span>
                                                <HR>

                                            </div>
                                                <button (click)="saveFilter()" style="width: 100px; float: right;">
                                                    <img src="../design/icons/disk.png"> Speichern
                                                </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>
                    <div *ngFor="let x of currentSelected.step" style="width:95%; border:1px solid lightgrey;" class="card">
                        <table class="data_tabel" style="width:100%;margin-bottom: -2px;" cellpadding="4">
                            <tr>
                                <td colspan="2">
                                   <img *ngIf="x.fk_workflow_action > 0"  src="../design/icons/task.256x213.png" style="width: 24px;display: inline-block;margin-right:8px;">
                                   <img *ngIf="x.fk_workflow_action == 0" src="../design/icons/end-event-none.256x255.png" style="width: 24px;display: inline-block;margin-right:8px;">

                                   <span style="display: inline-block;">
                                        <B>Aktion:</B> {{x.module_name}}<BR>
                                        {{x.name}}
                                   </span>
                                </td>
                            </tr>
                            <tr  *ngIf="x.fk_workflow_action > 0" >
                                <td style="width:50%;"><b>Erstellt von:</b> {{x.create_user_string}}</td>
                                <td style="width:50%;"><b>Erstellt am:</b> {{x.create_timestamp_string}}</td>
                            </tr>
                            <tr *ngIf="x.fk_workflow_action > 0 && x.fk_form>0">
                                <td colspan="2"><b>Formular:</b> {{x.form_name}}</td>
                            </tr>
                            <tr *ngIf="x.fk_workflow_action > 0 && x.workflow_target_user">
                                <td colspan="2">
                                    <b>Ziel Benutzer:</b>
                                </td>
                            </tr>
                            <ng-container *ngFor="let u of x.workflow_target_user">
                                <tr>
                                    <td>{{u.create_user_string}}
                                    </td>
                                    <td>
                                        <span *ngIf="u.filter==''"><B>Kein Filter</B></span>
                                        <span *ngIf="u.filter!='' && u.filter_source==0"><B>Filter:</B> Event Daten</span>
                                        <span *ngIf="u.filter!='' && u.filter_source==1"><B>Filter:</B> vorherige Aktions-Daten</span>
                                        <span *ngIf="u.filter!=''"> - {{u.filter}}</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <div *ngIf="x.fk_workflow_action > 0" style="position: relative;left: calc( 50% - 9px );margin-top: -24px;top: 22px;z-index: 999;"><img src="../design/icons/arrow_downBig.png"></div>
                    </div>
                </span>
            </div>
        </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="editWorkflow($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/pencil.png" style="vertical-align: middle">&nbsp;&nbsp;Bearbeiten
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
