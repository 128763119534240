import { Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock',
  templateUrl: './unlock.component.html',
  styleUrls: ['./unlock.component.css']
})
export class UnlockComponent implements OnInit {

  public rightContainerWidth;
  public leftContainerWidth;
  public editorWarningString = '';
  public httpOptions = { };
  // Suchleiste
  public searchParameter;
  public searchParameterFilter;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public dropdownDataCategory: any[];
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public readonly searchQuantity: number;
  public searchResultCount: number;
  // Liste
  public data = [];
  public tableSettings;
  public checkboxLastSelectedElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  // New und Edit Formular
  public currentSelected;
  public showNewItemForm: boolean;
  public currentSelectedTab = 2; // Optional > entfernen, wenn keine Tabs im Editor Bereich genutzt wereden
  public formError = false;
  public showLoading: boolean;
  private currentSaving: number;
  private saveInterval: number;

  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router) {
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxLastSelectedElement = null;
    this.setRightContainerWidth(40);
    this.initCurrentSelected();
    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
    this.initDropdownDataCategory();
    this.initDropdownDataUser();
    this.initDropdownDataYear();
    this.initTableSettings();
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }

  ngOnDestroy() {
    if(this.showNewItemForm || this.currentSelected.id) {

    }
  }

  search(loadNextSearchResults: boolean) {
    this.showLoading = true;
    if (loadNextSearchResults) {
      this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else {
      this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0;
    }

    this.checkExtendedSearch();

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'unlock', JSON.stringify(this.searchParameter), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data:{status, count, data}) => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
          this.showLoading = false;
        }
    );
  }

  startTextSearchFromContextmenu(element) {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch() {
    this.initSearchParameter();
    this.search(false);
  }

  new() {
    this.initCurrentSelected();
    this.currentSelectedTab = 1;
    this.showNewItemForm = true;
    setTimeout(() => {     document.getElementById('currentSelectedName').focus(); }, 300);
  }

  edit($event: MouseEvent, item: any): void  {
    if ($event != null) {
      if ($event.shiftKey) {
        let toState = true;
        if ($event.ctrlKey) {
          toState = false;
        } // wenn shift und strg zusammen dann entmarkieren
        if (!this.currentSelected || this.currentSelected.id < 1) {
          return;
        }
        this.checkboxRangeSelection(item);
        window.getSelection().removeAllRanges();
        return;
      }
      if ($event.ctrlKey) {
        item.checked = !item.checked;
        this.checkboxLastSelectedElement = item;
        return;
      }
    }
    this.currentSelectedTab = 1;
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'unlock/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          document.getElementById('currentSelectedName').focus();
        });
  }

  save() {
    if (this.currentSaving > 0) {
      // Wenn bereits gespeichert wird verlassen
      return;
    }
    // Fehlerüberprüfung
    if( this.currentSelected.text === '' ||
        this.currentSelected.name === '' ||
        this.currentSelected.timestampString === '' ||
        this.currentSelected.fk_contact === 0 ||
        this.currentSelected.category1 === '-1' ||
        this.currentSelected.category2 === '-1' ) {
      this.formError = true;
      this.editorWarningString = 'Sie müssen alle Pflichtfelder ausfüllen! <br>Diese sind mit einem * markiert.';
      this.message.open('editorWarning');
      return;
    }

    // Current saving setzen
    this.currentSaving = 1;
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'unlock',
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.currentSaving = 0;
          this.currentSelectedTab = 2;
          this.formError = false;
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  cancelEdit() :void
  {
    /* Sperren und Entsperren deaktiviert.
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/lock/' + this.currentSelected.id,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
        */
    this.currentSaving = 0;
    this.initCurrentSelected();
    this.showNewItemForm = false;
    /*
  }
);*/
  }

  unlock(item): void  {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'unlock/' + item.id , {}, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            const index = this.data.findIndex(  (element) => element == item );
            if (index > -1) {
              this.data.splice(index, 1);
              this.searchResultCount--;
              this.searchResultCount = this.searchResultCount -1;

            }
          });

  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'unlock/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'unlock/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  listDragAndDropItem($event: any) {
    if(this.searchParameter.sort != 'sort') {
      this.editorWarningString = 'Sie können die Liste nur umsortieren wenn sie diese vorher nach der Spalte Priorität sortieren!';
      this.message.open('editorWarning');
      return;
    }
    let sourceIndex = 0;
    let targetIndex = 0;
    let sourceSort = 0;
    let targetSort = 0;
    for (let i = 0; i < this.data.length; i++) {
      if(this.data[i].id == $event.dragData.id) {
        sourceIndex = i;
        sourceSort = this.data[i].sort;
      }
      if(this.data[i].id == $event.nativeEvent.target.parentNode.id) {
        targetIndex = i;
        targetSort = this.data[i].sort;
      }
    }
    // Element auf dem Server verschieben
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'unlock/sort/' + $event.dragData.sort + '/' + targetSort, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // Element auch in der Liste Bewegen
          if (targetIndex >= this.data.length) {
            var k = targetIndex - this.data.length + 1;
            while (k--) {
              this.data.push(undefined);
            }
          }
          for (let i = 0; i < this.data.length; i++) {
            if(sourceSort>targetSort) {
              if(this.data[i].sort == sourceSort) { this.data[i].sort = targetSort }
              else if(this.data[i].sort >= targetSort && this.data[i].sort < sourceSort  ) { this.data[i].sort ++ }
            } else if(sourceSort<targetSort) {
              if(this.data[i].sort > sourceSort && this.data[i].sort < targetSort  ) { this.data[i].sort -- }
              else if(this.data[i].sort == sourceSort) { this.data[i].sort = targetSort-1 }
            }
          }
          if(sourceSort<targetSort) { targetIndex--; }
          this.data.splice(targetIndex, 0, this.data.splice(sourceIndex, 1)[0]);
        });
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxLastSelectedElement = item;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxSwapAll(): void  {
    for (const item of this.data) {
      item.checked = this.checkboxAllState;
    }
    this.handleCheckboxChanged(false);
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }

    let direction = 'backwards';

    for (const item of this.data) {
      if (item === clickedItem)                       {direction = 'forward'; }
      if (item === this.checkboxLastSelectedElement)    {direction = 'backwards'; }
    }

    if (direction === 'backwards') {
      const temp = this.checkboxLastSelectedElement;
      this.checkboxLastSelectedElement = clickedItem;
      clickedItem = temp;
    }

    let mark = false;

    for (const item of this.data) {
      if (item === this.checkboxLastSelectedElement) {mark = true; }
      if (mark) {item.checked = true; }
      if (item === clickedItem) { mark = false; }
    }

    this.checkboxLastSelectedElement = clickedItem;

    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.data.filter((item) => item.checked);
  }

  checkboxActionDropdownChanged(): void {
    if (this.checkboxActionDropdown === '1') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Entsperren'}
      ];
    }
  }

  handleCheckboxAction(): void  {
    this.handleCheckboxChanged(false);
    if(this.checkboxActionDropdown == '1'){
    let sendString = '';
      for (const item of this.checkboxData) {

        sendString += item.id+ ';';
      }
      sendString = sendString.slice(0, sendString.length - 1);

      this.http.put<any[]>(
          this.settings.restBaseUrl + 'unlock/group',
          {groupIds:sendString},
          this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data2 => {
            this.checkboxActionDropdown = '-1';
          this.search(false);

          }
      );


    }
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  searchOnReturnPressed(event): void {
    if (event.key === 'Enter') { this.search(false); }
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }

  favoriteSelected() {
    setTimeout(() => {
      this.search(false);
    }, 100);
  }

  tableOptionChanged() {
    this.settings.set( this.constructor.name , JSON.stringify(this.tableSettings), 1);
  }

  private checkExtendedSearch() {
    // Wenn ein Parameter in der erweiterten Suche aktiv ist, dann diese auch aufklappen.
    if( this.searchParameter.dateFrom !=='' || this.searchParameter.dateTo !=='' ||
        this.searchParameter.active !== '1' || this.searchParameter.user !== '')
    {
      if(this.expandedSearch === false) {
        this.toggleExpandedSearch();
      }
    }
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', text : '', fk_contact : 0, category1 : '-1', category2 : '', timestampString : '' };
    this.formError = false;
  }

  initSearchParameter(): void {
    this.searchParameter = {
      company: '0', site: '0', fk_contact: '0', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'lock_timestamp', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : ''
    };
    this.searchParameterFilter = {
      company: '0', site: '0', fk_contact: '0', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : ''
    };
    // Fix to clear ContactManager
    setTimeout(() => {
      this.searchParameter.contact = '';
      setTimeout(() => {
        this.searchParameter.contact = 0;
      }, 200);
    }, 100);
  }

  initTableSettings(): void {
    const sessionSettings = this.settings.get(this.constructor.name);
    if (sessionSettings) {
      this.tableSettings = JSON.parse(sessionSettings);
    } else {
      this.tableSettings = {
        id: {name: 'Id', state: true, locked: false},
        sort: {name: 'Sortierung', state: true, locked: false},
        name: {name: 'Name', state: true, locked: true},
        create_fk_user: {name: 'Ersteller', state: true, locked: false}
      };
    }
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Entsperren'}];
  }

  initDropdownDataUser() {
    this.http.post<{status,count,data}>(this.settings.restBaseUrl + 'unlock/user', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data.data;
        }
    );
  }

  initDropdownDataCategory() {
    this.http.post<{status,count,data}>(this.settings.restBaseUrl + 'unlock/category', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategory = data.data;
        }
    );
  }

  initDropdownDataYear() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }



}
