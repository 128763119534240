import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PipesModule } from '../pipes/pipes.module';
import { MyDefaultTooltipOptions } from '../tooltip-options';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { ModalModule } from '../_modal';
import { ProcessTimelineComponent } from './process-timeline/process-timeline.component';

@NgModule({
  declarations: [
    ProcessTimelineComponent
  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule, BrowserAnimationsModule,
    PipesModule, ModalModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AngularSplitModule,
    ContextMenuModule
  ],
  exports: [
  ]
})
export class ControllingModule { }
