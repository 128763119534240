<div *ngIf="formDefinition" class="form"><B style="margin-left:6px;">{{formDefinition.form_data.name}}</B><BR>
    <table cellpadding="6" class="form" style="width:calc( 100% - 12px );">
        <ng-container *ngFor="let field of formDefinition.form_field; let i = index">
            <tr>
                <td colspan="2">
                    <label *ngIf="field.label!=''" class="formCaption">{{field.label}}</label>
                    <input type="text" *ngIf="field.type==1" style="width:100%;" [(ngModel)]="formData[field.name]">
                    <textarea *ngIf="field.type==2" style="width:100%;" [(ngModel)]="formData[field.name]"></textarea>
                </td>
            </tr>
        </ng-container>
        <tr>
            <td>
                <button (click)="this.message.open('formRedirectModal');" *ngIf="workflowUserRedirectData && workflowUserRedirectData.length > 0" tooltip="An einen anderen Benutzer weiterleiten.">Weiterleiten</button>
            </td>
            <td>
                <button *ngIf="userId == '100'" (click)="this.message.open('cancelWorkflow');">Workflow abbrechen</button>
            </td>
            <td style="text-align: right;">
                <button (click)="save()">{{formDefinition.form_data.button_name}}</button>
            </td>
        </tr>

    </table>
</div>

<jw-modal id="formRedirectModal" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> An einen anderen Benutzer Weiterleiten.&nbsp;&nbsp;
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
        <select [(ngModel)]="redirectToUser.id" [ngClass]="{ 'error' : this.redirectToUser.error == 1 }" style="width:100%;">
            <option value="-1">Benutzer wählen</option>
            <option *ngFor="let x of workflowUserRedirectData" [value]="x.id">{{x.name}}</option>
        </select>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="redirectToSelectedUser()" style="margin-top: 3px;margin-left: 3px; float: right;">
            Speichern
        </button>
        <button (click)="message.close('formRedirectModal');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Abbrechen
        </button>
    </div>
</jw-modal>

<jw-modal id="cancelWorkflow" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;">&nbsp;&nbsp;Workflow abbrechen
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
    Wollen Sie den Workflow wirklich abbrechen?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="cancelWorkflow()" style="margin-top: 3px;margin-left: 3px; float: right;">
            Ja
        </button>
        <button (click)="message.close('cancelWorkflow');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Nein
        </button>
    </div>
</jw-modal>
