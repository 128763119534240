import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import {SettingsService} from '../../services/settings.service';

@Component({
  selector: 'app-erp-unit',
  templateUrl: './erp-unit.component.html',
  styleUrls: ['./erp-unit.component.css']
})
export class ErpUnitComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = { };

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', description : '', sort : ''};
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', text: '' };
  }


  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'erp/unit', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  public save() {

    if ( this.currentSelected.name == null || this.currentSelected.name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'erp/unit' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'erp/unit/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          setTimeout(() => {
            document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'erp/unit/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
