import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';
import { ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-document-assign',
  templateUrl: './document-assign.component.html',
  styleUrls: ['./document-assign.component.css']
})
export class DocumentAssignComponent implements OnInit {
  public data = [];
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = {};
  public dropdownDataCompany;
  public dropdownDataContact;
  public dropdownDataDocumentGroup;
  public selectedDocument;
  public documentTagDataSource = [];
  public documentTag;
  public documentTagKeyboardPause = false;
  public tagList = [];
  public fileToLoad;
  public messageContent = '';
  public changeRightGroupError = false;
  public searchParameter;
  public tableSettings;
  public itemToDelete: any;
  public ticketId = '';
  public informationId = '';
  public selectOnLoad: any;
  public documentGroupDataSource = [];
  public showLoading = false;
  public tagDependency: any;
  public changeRightGroup = -1;
  public dropdownDataDocumentGroupUser = [];
  public documentTop: any;
  public hastMetaExtraInfos = false;
  public saveInProgress = false;
  public documentSaver;

  initCurrentSelected(): void {
    this.currentSelected = {
      id: '',
      name: '',
      title: '',
      meta_fk_company: '',
      meta_fk_contact: '',
      meta_fk_documentgroup: '-1',
      timestampString: '',
      filepath: '',
      meta_document_date: '',
      meta_extra_text: ''
    };
    this.selectedDocument = '';
    this.documentTag = '-1';
    this.tagList = [];
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService, public router: Router, public route: ActivatedRoute) {
    this.documentTop=140;
    this.initTableSettings();
    this.initSearchParameter();
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(60);
    this.httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json', token: settings.sessionId})};

    this.http.post<any[]>(settings.restBaseUrl + 'contact/company/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCompany = data;
        }
    );

    this.http.post<{ status, data, count }>(settings.restBaseUrl + 'document/rightgroups', {onlyUser:1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataDocumentGroupUser = data.data;
        }
    );

    this.http.post<{ status, data, count }>(settings.restBaseUrl + 'document/rightgroups', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataDocumentGroup = data.data;
        }
    );
  }

  initSearchParameter(): void {
    const sort = 'timestamp_in';
    this.searchParameter = {loadFrom: 0, loadQuantity: this.searchQuantity, sort, debug: false, text: ''};
    this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'document/rightgroups', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentGroupDataSource = data.data;
          this.searchParameter.group = -1;
        }    );
  }

  ngOnInit() {
    this.search(false);


    this.handleRouteParameter();
    setTimeout(() => {
      if (this.data.length > 0) {
        this.currentSelected.meta_title = '';
        setTimeout(() => {
          document.getElementById('currentSelectedMetaTitle').focus();
        }, 300);
      }
    }, 300);
  }

  handleRouteParameter() {
    // Übergabewerte in der route verarbeiten.
    this.route.paramMap.subscribe(params => {
      if ( params.get('id') ) {
       let information = params.get('id').match('information')
        if(!information){
        this.ticketId = params.get('id');} else{this.informationId = params.get('id');}
      }
      if ( params.get('action') === 'select' ) {
        this.selectOnLoad = params.get('id');
      }
    });
  }

  search(loadNextSearchResults: boolean): void {
    this.showLoading = true;
    this.searchParameter.debug = this.tableSettings.debug.state;
    this.http.post<{status, count, data, message}>(this.settings.restBaseUrl + 'document/approvallist', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.showLoading = false;
          this.data = data.data;
          this.searchResultCount = data.count;
          if (this.data.length > 0) {
            if(this.selectOnLoad > 0) {
              for (const item of this.data) {
                if (this.selectOnLoad === item.id) {
                  this.edit(item);
                }
              }
            } else {
              this.edit(this.data[0]);
            }
          }
        }
    );
  }

  resetSearch(saveText): void {
    this.initSearchParameter();
    this.search(false);
  }

  documentCheck(saveType): void
  {
    const checkData = {
      company: this.currentSelected.meta_fk_company,
      contact: this.currentSelected.meta_fk_contact,
      titel: this.currentSelected.title,
      date: this.currentSelected.meta_document_date
    };

    this.http.put<number>(
        this.settings.restBaseUrl + 'document/checkForExistingDocument', checkData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if(data == 0){  if(saveType == 1){this.save();}
                          if(saveType == 2){this.saveAndAttach();}}
          if(data > 0){this.documentSaver = saveType; this.message.open('SaveDocDouble');}
        }
    );

  }

  saveDoubleDocument() {
    this.message.close('SaveDocDouble');
    if(this.documentSaver == 1){this.save();}
    if(this.documentSaver == 2){this.saveAndAttach();}
  }

  save(): void {
    if(this.saveInProgress == false){
    this.saveInProgress = true;
    this.documentTop = 140;
    this.hastMetaExtraInfos = false;
    let nextEntryIndex = 0;

    if (this.currentSelected.meta_fk_company === '-1') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Firma aus.');
      return;
    } else if (this.currentSelected.timestampString === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie ein Datum aus.');
      return;
    } else if (this.currentSelected.meta_fk_documentgroup === '-1') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Dokumentengruppe aus.');
      return;
    }

    let tagIds = '';
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.tagList.length; ++index) {
      tagIds += this.tagList[index].id + ';';
    }

    const saveData = {
      id: this.selectedDocument,
      company: this.currentSelected.meta_fk_company,
      contact: this.currentSelected.meta_fk_contact,
      doctitel: this.currentSelected.title,
      group: this.currentSelected.meta_fk_documentgroup,
      date: this.currentSelected.meta_document_date,
      tags: this.currentSelected.tags,
      meta_extra_text: this.currentSelected.meta_extra_text
    };
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'document/saveMetadata', saveData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // Aktuellen Eintrag entfernen und nächstn auswähle
          for (let i = 0; i < this.data.length; i++) {
            if (this.currentSelected.id === this.data[i].id) {
              this.data.splice(i, 1);
              nextEntryIndex = i;
            }
          }
          // this.search(false);
          this.initCurrentSelected();

          setTimeout(() => {
            if (this.data.length < 1 || !this.data) {
              this.fileToLoad = null;
              // @ts-ignore
              document.getElementById('documentView').src = 'about:blank';
            } else {
              if (this.data && this.data.length > 0) {
                this.initCurrentSelected();
                this.edit(this.data[nextEntryIndex]);
              }
            }
            this.saveInProgress = false;
          }, 300);
        }
    );}
  }

  saveAndAttach()
  {
    if(this.informationId != ''){

      this.http.put<any>(this.settings.restBaseUrl + 'information/attach', {
        tid: this.informationId.replace('information:', ''),
        did: this.selectedDocument
      }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                          this.save();
                          this.router.navigate(['/core-information/' + this.informationId.replace('information:', '') + '/creatorSpace']);
          }
      );
    }

    else if(this.ticketId != ''){
    this.http.put<any>(this.settings.restBaseUrl + 'document/attach', {
      tid: this.ticketId,
      did: this.selectedDocument
    }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if(data.status === 'error') {
            alert(data.message);
          } else {
            this.save();
            this.router.navigate(['/' + data.message]);
          }

        }
    );}
  }

  backToProcess()
  {
    this.http.put<any>(this.settings.restBaseUrl + 'document/process', {
      tid: this.ticketId
    }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.router.navigate(['/' + data.message]);
        }
    );
  }

  startChangeRightGroup() {
    if(this.changeRightGroup === -1) {
      this.changeRightGroupError = true;
      return;
    }
    this.changeRightGroupError = false;
    this.http.put<any[]>(this.settings.restBaseUrl + 'document/rightgroups/assign', {
      id: this.currentSelected.id,
      fk_documentgroup: this.changeRightGroup
    }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.changeRightGroup = -1;
          this.message.close('changeRightGroupMessageBox');
          this.initCurrentSelected();
          this.search(false);
        }
    );
  }

  private UrlExists(url) {
    const http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }

  edit(item: any): void {
    this.documentTop = 140;
    this.hastMetaExtraInfos = false;
    this.initCurrentSelected();

    setTimeout(() => {
      this.selectedDocument = item.id;
      this.currentSelected = JSON.parse(JSON.stringify(item));

      if( this.currentSelected.meta_extra_text !== undefined && this.currentSelected.meta_extra_text.length > 0) {
        this.hastMetaExtraInfos = true;
        this.documentTop = 290;
      }

      if (!this.UrlExists(item.filepath)) {
        this.fileToLoad = item.filepath.replace('process', 'process/extProcess');
      } else {
        this.fileToLoad = item.filepath;
      }

      if (item.meta_fk_documentgroup > 0) {
        this.currentSelected.meta_fk_documentgroup = item.meta_fk_documentgroup;
      } else {
        this.currentSelected.meta_fk_documentgroup = -1;
      }
      this.getTagList();
      setTimeout(() => {
        document.getElementById('currentSelectedMetaTitle').focus();
      }, 400);
    }, 100);
  }

  getCompanyContacts() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'contact/list/' + this.currentSelected.meta_fk_company, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataContact = data;
          this.documentTag = '-1';
        }
    );
  }

  getTagList() {
    this.http.post<{ status, data, count }>(this.settings.restBaseUrl + 'tag', {
      modul_dependency: this.currentSelected.meta_fk_documentgroup,
      active: 1
    }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentTagDataSource = data.data;
        }
    );
  }

  tagRemove(item) {
    const index = this.currentSelected.tags.indexOf(item);
    this.currentSelected.tags.splice(index, 1);
  }

  dropdownKeyboardHandler(event: KeyboardEvent) {
    if(event.key!=='Enter') {
      // Wenn nicht Return, dann verhindere den onChange.
      // Dies ermögliche es mit den Pfeiltasten zu navigieren und Return setzt erst den Wert.
      this.documentTagKeyboardPause = true;
    } else {
      event.preventDefault();
      this.documentTagKeyboardPause = false;
      this.documentTagClicked();
    }
  }

  documentTagClicked() {
    if (this.documentTagKeyboardPause == true) {
      this.documentTagKeyboardPause = false;
      return;
    }
    // tslint:disable-next-line:triple-equals
    if (this.documentTag != '-1') {
      let tagExists = false;
      this.currentSelected.tags.forEach((element) => {
        if (element.id === this.documentTag) {
          alert('Dieser Tag ist bereits zugewiesen.');
          tagExists = true;
        }
      });
      setTimeout(() => {
        if (!tagExists) {
          this.setDocumentTag();
        }
      }, 100);
    }
  }

  setDocumentTag() {
    this.http.get<{ id, name,tags_dependency }>(this.settings.restBaseUrl + 'tag/' + this.documentTag, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected.tags.push(data);
          if(data.tags_dependency.length > 0 ) {
            // Es gibt Abhängigkeiten > Dialog öffnen
            this.tagDependency = data.tags_dependency;
            this.message.open('tagDependencyDialog');
          }
        }
    );
  }

  loadDocumentTagDataSource() {
    this.documentTagDataSource = [];
    this.searchParameter.documentTag = -1;
    this.http.post<any[]>(this.settings.restBaseUrl + 'document/tag/' + this.searchParameter.group, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentTagDataSource = data;
        }
    );
  }

  openDeleteItemMessageBox(item) {
    this.itemToDelete = item;
    this.messageContent = 'Sind Sie sicher, dass Sie das Dokument wirklich löschen wollen?';
    this.message.open('messageBox');
  }

  closeMessageBox() {
    this.message.close('messageBox');
    this.messageContent = '';
  }

  deleteDocumentFromList() {
    this.closeMessageBox();
    this.http.delete<any>(this.settings.restBaseUrl + 'document/deleteFromApprovallist/' + this.itemToDelete.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search(false);
          this.initCurrentSelected();
        }
    );
  }

  setRightContainerWidth(width): void {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) {
      this.search(false);
    }
  }

  goTo(selector,tid) {
    let destination;
    if (typeof selector === 'string' || selector instanceof String) {
      destination = selector;
    } else {
      destination = selector.options[selector.selectedIndex].value;
    }
    this.router.navigate(['/' + destination + '/' + tid]);
  }

  createProcessFromDocument() {
    this.http.put<any>(this.settings.restBaseUrl + 'process/createFromDocumentPath', JSON.stringify(this.currentSelected), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.router.navigate(['/' + data.message]);
        }
    );
  }

  tableOptionChanged() {
    this.settings.set('documentAssignTableSettings', JSON.stringify(this.tableSettings), 1);
    this.search(false);
  }

  initTableSettings(): void {
    const sessionSettings = this.settings.get('documentAssignTableSettings');
    if (sessionSettings) {
      this.tableSettings = JSON.parse(sessionSettings);
    } else {
      this.tableSettings = {
        debug: {name: 'Fehler', state: false, locked: false},
        date: {name: 'Eingegangen', state: true, locked: false},
        rightGroup: {name: 'Dokumentengruppe', state: true, locked: false},
        name: {name: 'Name', state: true, locked: true}
      }
    }
  }

  uploadFile() {
    alert('Diese Funktion steht noch nicht zur verfügung.')
  }

  resetState(x) {
    this.http.put<any>(this.settings.restBaseUrl + 'document/state', {id: x.id}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search(false);        }
    );
  }

  moveBackToExtRecognition(x) {
    this.http.put<any>(this.settings.restBaseUrl + 'document/move', {id: x.id}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search(false);
        }
    );
  }

  addTagDependency(tag) {
    this.message.close('tagDependencyDialog');
    this.documentTag = tag.id;
    this.documentTagClicked();
  }

  toggleExtraMetaText() {
    if(this.documentTop == 140) {
      this.documentTop = 290;
      setTimeout(() => {
        document.getElementById('meta_extra_text_id').focus();
      }, 300);
    } else{
      this.documentTop = 140;
    }
  }


}
