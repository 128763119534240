import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { TutorialService } from '../../services/tutorial.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'app-process-progress',
  templateUrl: './process-progress.component.html',
  styleUrl: './process-progress.component.css'
})
export class ProcessProgressComponent {

  public processData;
  public processTypes;
  public currentSelected;
  public fileUrl;
  public fullscreenFilename;
  public iframeFullscreenSource;
  public documentObject;
  public timelineColumnWidth;
  public showInfosInProgress = false;
  private tid: number = 0;

  @Input()
  get processDataSource() {
    return this.processData;
  }
  set processDataSource(data: any) {
    this.processData = data;
  }

  @Input()
  get loadTid() {
    return this.tid;
  }
  set loadTid(setTid: any) {
    if(this.tid == setTid) { return;}
    this.tid = setTid;
    this.getProcess();
  }


  initCurrentSelected(): void {
    this.currentSelected = { id: '', tid: '', contact: '', contact_username: '', email: '', titel: '', text: '', typ: '', attachments: '', send_time: '', time_duration: '', fk_process_billing_type: '',  main: '', user: '', create_username: '', site: '', company: '', active: '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService, public tutorial: TutorialService, public message: ModalService, public sanitizer: DomSanitizer) {
    this.initCurrentSelected();
  }
  ngOnInit(): void {

    if(this.processData!=undefined) { this.processGivenData();}

  }

  private processGivenData() {
    const fileData = 'FileDownload Data';
    const blob = new Blob([fileData], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    this.http.post<any[]>(this.settings.restBaseUrl + 'process/type' , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.processTypes = data;

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.processData.data.length; i++) {
            // tslint:disable-next-line:triple-equals
            if (this.processData.data[i].typ == 33) {
              this.processData.data.forEach((x) => {
                if (x.send_time < this.processData.data[i].send_time) { x.isCharged = true; } else { x.isCharged = false; }
              });
            }
          }
          this.processData.data.forEach((element) => {
            const datePipe = new DatePipe('de-De');
            element.time = datePipe.transform(element.send_time * 1000, 'H:mm', 'CET', 'de');
            element.send_date = datePipe.transform(element.send_time * 1000, 'dd.MM.yyyy', 'CET', 'de');
            this.processTypes.forEach((item) => {
              if (element.typ === item.id) { element.img = item.img; }
            });
          });
        }
    );
  }


  viewInline($event: MouseEvent, attachemnts: any) {
    for (const t of attachemnts) {
      t.inline = 0;
    }
  }

  checkForInlineAttachments(attachemnts: any) {
    for (const t of attachemnts) {
      if (t.inline === '1') { return true; }
    }
    return false;
  }

  sendToDMS($event: MouseEvent, item: any) {
    this.http.put<any>(
        this.settings.restBaseUrl + 'process/attachment/dms/' + this.currentSelected.header.tid , JSON.stringify(item), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          for (const currentItem of this.processData) {
            if ( currentItem.tid === this.currentSelected.header.tid ) {
              //setTimeout(() => { this.view(currentItem); }, 200);
              break;
            }
          }
        });
  }

  public externViewFullscreen(event, filepath, filename) {
    if (event != null && event.ctrlKey) {
      window.open(filepath, '_blank');
    } else {
      this.fullscreenFilename = filename;
      this.iframeFullscreenSource = filepath;
      this.message.open('fullscreenReading');
    }
  }

  downloadFile(item) {
    const link = document.createElement('a');
    link.setAttribute('href', item.path + '/' + item.name);
    link.setAttribute('download', item.name);
    document.body.appendChild(link);
    link.click();
  }

  callFullScreen($event: MouseEvent, itemPath: string, itemName: string) {
    this.documentObject = {$event: null, path: itemPath, name: itemName};

  }

  toggleShowMoreTime(item) {
    if (document.getElementById('moreTime' + item.id).style.visibility === 'hidden') {
      document.getElementById('moreTime' + item.id).style.visibility = 'visible';
    } else {
      document.getElementById('moreTime' + item.id).style.visibility = 'hidden';
    }
  }

  toggleShowInfosInProgress() {
    this.showInfosInProgress = !this.showInfosInProgress;
  }

  private getProcess() {
      this.http.get<{ contact, header: { tid: '', state: '', contact }, data: [{ id: '', typ: string, last_changed: '', user: '', main: string }] }>(
          this.settings.restBaseUrl + 'process/2/' + this.tid,
          this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if(data.data) {
              this.processData = data;
              this.processGivenData();
            } else {
              this.processData = null;

            }

          });
  }
}
