<div class="form time_picker" style="display: inline-block; width:50px;margin-right: 20px;">
    <input [ngModel]="ngModel"
           (keyup)="manualEnter($event)"
           (change)="modelChanged($event)"
           (focus)="activateHourOverlay()"
           (blur)="deactivateOverlays()"
           type="text"
           style="width:50px; vertical-align: middle !important;"
           id="timePicker{{overlayId}}">
    <div *ngIf="showHour" class="pickerOverlayHour shadow roundCorner">
        <div (click)="moveHour('up')" class="time_select_arrow" style="border-bottom: 1px solid #7c7c7c;"><img src="../design/icons/bullet_arrow_up.png"></div>
        <div style="height:183px;">
            <div (click)="selectHour(i+hourStart,false)"
                 (dblclick)="selectHour(i+hourStart,true)"
                 *ngFor="let item of [].constructor(13); let i = index"
                 [ngClass]="{'time_select_box':true ,'marked_hour':currentHour == i+hourStart}" >
                {{i+hourStart | number:'2.0'}}
            </div>
        </div>
        <div (click)="moveHour('down')" class="time_select_arrow" style="border-top: 1px solid gray;"><img src="../design/icons/bullet_arrow_down.png"></div>
    </div>

    <div *ngIf="showMinutes" class="pickerOverlayMinutes shadow roundCorner">
        <div (click)="selectMinute('00')" [ngClass]="{'time_select_box':true ,'marked_hour':currentMinute == '00'}">
            :00
        </div>
        <div (click)="selectMinute('15')" [ngClass]="{'time_select_box':true ,'marked_hour':currentMinute == '15'}">
            :15
        </div>
        <div (click)="selectMinute('30')" [ngClass]="{'time_select_box':true ,'marked_hour':currentMinute == '30'}">
            :30
        </div>
        <div (click)="selectMinute('45')" [ngClass]="{'time_select_box':true ,'marked_hour':currentMinute == '45'}">
            :45
        </div>
    </div>
</div>

<jw-modal class="jw-modal shadow" id="timeWarning">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;"> Sie haben keine gültige Zeit eingegeben!</div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="closeWarning()" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
