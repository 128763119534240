import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';


@Component({
    selector: 'app-process-team-management',
    templateUrl: './process-team-management.component.html',
    styleUrls: ['./process-team-management.component.css']
})
export class ProcessTeamManagementComponent implements OnInit {
    public data = [];
    public searchParameter;
    public readonly searchQuantity: number;
    public searchResultCount: number;
    public currentSelected;
    public showNewItemForm: boolean;
    public expandedSearch = false;
    public expandedSearchPreviousPanelHeight = '';
    public rightContainerWidth;
    public leftContainerWidth;
    public dropdownDataYears = [];
    public dropdownDataCategories = [];
    public checkboxShiftStartElement: any;
    public checkboxData: any;
    public checkboxAllState: boolean;
    public checkboxActionDropdown = '-1';
    public checkboxActionParameterDropdown = '-1';
    public checkboxActionParameterDropdownData;
    public httpOptions = { };
    public currentSelectedTab = 1;
    public teamUserList = [ { id: '', user_id: '', user_name: '', position: '', fk_team_role: '' } ];
    public teamRoleList = [];
    public defaultCompany = 1;
    public teamUserLinkExists = false;
    public existingLeader;
    public port1Options = [ { id: 143, name: 'Keine', encryption: '' }, { id: 993, name: 'SSL/TLS (Empfohlen)' , encryption: 'SSL' } ];
    public port2Options = [ { id: 25, name: 'Keine' , encryption: '' }, { id: 465, name: 'SSL/TLS (Empfohlen)' , encryption: 'ssl' }, { id: 587, name: 'TLS/STARTTLS' , encryption: 'tls' } ];
    public returnedTeamId;
    public interimImapPort;
    public interimSmtpPort;
    public passwordCompare;
    public companyId: string;
    public customImapPort;
    public customSmtpPort;
    public categoryAtSelect;
    public dropdownDataUser = [];
    public teamCategories = [];
    public categoryAddId = -1;
    public teamLeaderChangeTitle;
    public teamLeaderChangeContent;
    public interimTeamLeaderData = {id: '', fk_team_role: ''};
    public teamLeaderChange = false;
    public teamLeaderRoleContent;
    public messageBoxSSLPort: string;
    public teamMemberSearchParameter: any;
    public teamFormError = 0;
    public infoBoxText = '';
    public teamToDelete: any;
    public userToDeleteFromTeam: any;
    public securityLevelRight = false;
    public billingTypes: any;

    initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', description: '', fk_process_billing_type: '1', phone_time_start: '0', mailbox_id: '', email : '', username1: '', password1: '', host1: '', port1: 993, port2: 465, ssl1: 'SSL', ssl2: 'ssl', noautoresponse: false, delete_mails: false, teamleader: '', teamleader_role: '4', fk_category: '0', mailboxname: '', sender_name_format: '0', folder: 'INBOX' };
    }

    initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'name', active : '1', text: '' };
    }

    initTeamMemberSearchParameter(): void {
        this.teamMemberSearchParameter = { text : '', active: 1, showAll: false};
    }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    this.initCurrentSelected();
    this.initTeamMemberSearchParameter();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<{data}>(settings.restBaseUrl + 'team/role', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.teamRoleList = data.data;
        }
    );

    this.http.post<{status, data, count}>(settings.restBaseUrl + 'process/category', {active: 1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategories = data.data;
        }
    );
  }

  ngOnInit() {
    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = data;

          this.search(false);
          this.getDropdownUserData();
        }
    );
    // Abrechnungsarten laden
    this.http.post<{ status, message, data }>(this.settings.restBaseUrl + 'process/billingtype', {},this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.billingTypes = data.data;
        }
    );

    document.getElementById('searchText').focus();
    this.currentSelectedTab = 2;
  }

  search(loadNextSearchResults: boolean): void {


      this.http.post<{status, data}>(this.settings.restBaseUrl + 'team/securityRight', JSON.stringify(this.searchParameter), this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.securityLevelRight = data.data;
          }
      );


    this.initCurrentSelected();
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'team', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);

          this.data.forEach((item) => {
            if (item.email == null) {
              item.email = 'Kein E-Mail eingerichtet';
            }
          });
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  getDropdownUserData() {
    this.dropdownDataUser = [];
    this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', { active: 1, company: this.companyId, sort: 'name' }, this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          const interimUserArray = data2.data;
          interimUserArray.forEach((element) => {
            if (element.id !== this.currentSelected.id) {
              this.dropdownDataUser.push(element);
            }
          });
        }
    );
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
      // tslint:disable-next-line:variable-name
    let sender_name_format = true;
    let mailboxname = true;
    let email = true;
    let username = true;
    let password = true;
    let host = true;
    let port1 = true;
    let port2 = true;
      this.teamFormError = 0;
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
      this.teamFormError = 1;
    }
    if ( ( this.currentSelected.fk_category === '0' || this.currentSelected.fk_category == null ) && this.currentSelected.id < 1) {
        this.teamFormError = 1;
    }

    let mailSettingsCounter = 0 ;

    if (this.currentSelected.email !== '' && this.currentSelected.email != null) { mailSettingsCounter++; }
    if (this.currentSelected.username1 !== '' && this.currentSelected.username1 != null) { mailSettingsCounter++; }
    if (this.currentSelected.password1 !== '' && this.currentSelected.password1 != null) { mailSettingsCounter++; }
    if (this.currentSelected.host1 !== '' && this.currentSelected.host1 != null) { mailSettingsCounter++; }
    if (this.currentSelected.mailboxname !== '' && this.currentSelected.mailboxname != null) { mailSettingsCounter++; }
    if (this.currentSelected.sender_name_format != 0 && this.currentSelected.sender_name_format != null) { mailSettingsCounter++; }

    if(mailSettingsCounter > 0 && mailSettingsCounter != 6) {
      this.teamFormError = 2;
    } else if( mailSettingsCounter > 0 ) {
          if (this.currentSelected.port1 == '' || this.currentSelected.port1 == null ||
              this.currentSelected.port2 == '' || this.currentSelected.port2 == null ||
              this.currentSelected.folder == '' || this.currentSelected.folder == null) {
              this.teamFormError = 2;
          }
      }

    if( this.teamFormError == 1) {
        this.infoBoxText = 'Sie haben nicht alle Pflichtfelder ausgefüllt.';
        this.message.open('infoBox');
        return;
    }

    if( this.teamFormError == 2) {
        this.infoBoxText = 'Sie haben nicht alle Pflichtfelder ausgefüllt. <BR>Sobald Sie eines der Felder der E-Mail Einstellungen ausgefüllt haben, müssen Sie alle definieren!';
        this.message.open('infoBox');
        return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    if (this.currentSelected.description == null) {
      this.currentSelected.description = '';
    }

    const teamData = {
        name: this.currentSelected.name,
        description: this.currentSelected.description,
        fk_category: this.currentSelected.fk_category,
        fk_process_billing_type: this.currentSelected.fk_process_billing_type,
        phone_time_start: this.currentSelected.phone_time_start
    };

    this.http.put<{status, message}>(this.settings.restBaseUrl + 'team' + endpointIdAdd, teamData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const newId = data.message;
          if (this.showNewItemForm) {
            this.setLeaderNewTeam(newId);
          }
          if (mailboxname && email && username && password && host && port1 && port2 && mailboxname && sender_name_format) {
            this.putMailboxData(newId);
            return;
          } else {
            if (this.currentSelected.email === '' || this.currentSelected.email == null && this.currentSelected.username1 === '' || this.currentSelected.username1 == null && this.currentSelected.password1 === '' || this.currentSelected.password1 == null && this.currentSelected.host1 === '' || this.currentSelected.host1 == null && this.currentSelected.id > 0) {
              this.deleteMailbox();
            }
          }
            setTimeout(() => {
                this.search(false);
                this.showNewItemForm = false;
                this.initCurrentSelected();
            }, 300);
        }
    );
  }

  deleteMailbox() {
    this.http.delete<any>(this.settings.restBaseUrl + 'team/mailbox/' + this.currentSelected.id + '/' + this.currentSelected.mailbox_id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {});
  }

  setStandardCategory(id) {
    let categoryExist = false;
    // tslint:disable-next-line:variable-name
    let fk_team_id;
    if (this.currentSelected.id > 0) {
      fk_team_id = this.currentSelected.id;
    } else { fk_team_id = id; }

    this.teamCategories.forEach((element) => {
        if (this.currentSelected.fk_category === element.fk_category) { categoryExist = true; }
    });

    if (categoryExist == false) {
        const categoryData = {
            fk_category: this.currentSelected.fk_category,
            fk_team: fk_team_id,
            fk_project_type: 0,
            base_category: 1
        };

        this.http.put<{status, message}>(this.settings.restBaseUrl + 'process/category/link', categoryData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                const itemData = {
                    fk_category: this.currentSelected.fk_category,
                    fk_team: fk_team_id,
                    base_category: 1,
                    linkId: data.message
                };
                this.changeMainCategory(itemData);
            }
        );
    }
  }

  setLeaderNewTeam(teamId) {
    let autoPreset = 0;
    let teamAsStandard = 0;
    this.http.post<any[]>(this.settings.restBaseUrl + 'team/standard/exist/' + this.currentSelected.teamleader , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const existingPresets = data;
          if (existingPresets.length < 1) {
            autoPreset = 1;
            teamAsStandard = 1;
          }

          const teamleaderData = {
            fk_groupid: teamId,
            fk_userid: this.currentSelected.teamleader,
            default_team: autoPreset,
            fk_team_role: this.currentSelected.teamleader_role,
            standardteam: teamAsStandard
          };

          this.http.put<any[]>(this.settings.restBaseUrl + 'team/role', teamleaderData, this.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
              }
          );
        }
    );
  }

  putMailboxData(id) {
    let endpointIdAdd = '';
    if (this.currentSelected.mailbox_id > 0) {
      endpointIdAdd = '/' + this.currentSelected.mailbox_id;
    }
    let noAutoResponseValue;
    let deleteMailsValue;
    if (this.currentSelected.noautoresponse === true) {
      noAutoResponseValue = 1;
    } else {
      noAutoResponseValue = 0;
    }
    if (this.currentSelected.delete_mails === true) {
      deleteMailsValue = 1;
    } else {
      deleteMailsValue = 0;
    }
    if (!this.showNewItemForm) {
      id = this.currentSelected.id;
    }
    const mailboxData = {
      fk_core_user_teams: id,
      sendname: this.currentSelected.name,
      mailboxname: this.currentSelected.mailboxname,
      sender_name_format: this.currentSelected.sender_name_format,
      email: this.currentSelected.email,
      host1: this.currentSelected.host1,
      username1: this.currentSelected.username1,
      password1: this.currentSelected.password1,
      port1: this.currentSelected.port1,
      port2: this.currentSelected.port2,
      ssl1: this.currentSelected.ssl1,
      ssl2: this.currentSelected.ssl2,
      noautoresponse: noAutoResponseValue,
      delete_mails: deleteMailsValue,
      folder: this.currentSelected.folder
    };

    this.http.put<{status, message}>(this.settings.restBaseUrl + 'team/mailbox' + endpointIdAdd, mailboxData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            const newId = data.message;
            if (this.showNewItemForm || this.currentSelected.mailbox_id > 0) {
              this.returnedTeamId = id;
              this.updateMailId(newId);
              return;
            } else {
              this.search(false);
              this.initCurrentSelected();
              this.showNewItemForm = false;
            }
        }
    );
  }

  updateMailId(id) {
    if (!this.showNewItemForm) {
      id = this.currentSelected.mailbox_id;
    }
    this.http.put<{status, message}>(this.settings.restBaseUrl + 'team/updateMail/' + id + '/' + this.returnedTeamId , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.search(false);
            this.initCurrentSelected();
            this.showNewItemForm = false;
            this.returnedTeamId = null;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.initTeamMemberSearchParameter();
    this.customImapPort = '';
    this.customSmtpPort = '';
    this.getTeamCategories(item.id);
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'team/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          this.getTeamUserList();
          if (this.currentSelected.port1 == null) {
            this.currentSelected.port1 = '';
          }
          if (this.currentSelected.port2 == null) {
            this.currentSelected.port2 = '';
          }
          // tslint:disable-next-line:triple-equals
          if (this.currentSelected.noautoresponse == 1) {
            this.currentSelected.noautoresponse = true;
          } else {
            this.currentSelected.noautoresponse = false;
          }
          // tslint:disable-next-line:triple-equals
          if (this.currentSelected.delete_mails == 1) {
            this.currentSelected.delete_mails = true;
          } else {
            this.currentSelected.delete_mails = false;
          }
          if (this.currentSelected.fk_category == null) {
            this.currentSelected.fk_category = '0';
          }
          this.interimImapPort = this.currentSelected.port1;
          this.interimSmtpPort = this.currentSelected.port2;
          this.passwordCompare = this.currentSelected.password1;
          this.categoryAtSelect = this.currentSelected.fk_category;

          if (this.currentSelected.sender_name_format == null) { this.currentSelected.sender_name_format = '0'; }

          setTimeout(() => {
            if (this.currentSelected.port1 !== '143' || this.currentSelected.port1 !== '993' || this.currentSelected.port1 !== '') {
              this.customImapPort = this.currentSelected.port1;
            } else { this.customImapPort = ''; }
            if (this.currentSelected.port2 !== '25' || this.currentSelected.port2 !== '465' || this.currentSelected.port2 !== '587' || this.currentSelected.port2 !== '') {
              this.customSmtpPort = this.currentSelected.port2;
            } else { this.customSmtpPort = ''; }
            if (this.currentSelectedTab === 1) {
              document.getElementById('currentSelectedName').focus();
            }
          }, 300);
        });
  }

  getTeamCategories(id) {
    this.http.post<any[]>(this.settings.restBaseUrl + 'process/category/link', { fk_team: id }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.teamCategories = data;
        }
    );
  }

  putTeamCategoryLink() {
    let base = 0;
    if (this.teamCategories.length < 1) {
      base = 1;
    }
    const linkData = {
      fk_team: this.currentSelected.id,
      fk_project_type: 0,
      fk_category: this.categoryAddId,
      base_category: base
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'process/category/link', linkData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.getTeamCategories(this.currentSelected.id);
          this.categoryAddId = -1;
        });
  }

  checkTeamCategoryLinK() {
      if (this.categoryAddId < 1 ) {
          alert('Bitte wählen Sie eine Kategorie aus.');
          return;
      } else {
          let categoryCheck = false;
          this.teamCategories.forEach((element) => {
              if (element.fk_category === this.categoryAddId) {
                  categoryCheck = true;
              }
          });
          setTimeout(() => {
              if (categoryCheck) {
                  alert('Diese Kategorie befindet sich bereits in der Liste.');
                  return;
              } else { this.putTeamCategoryLink(); }
          }, 200);
      }
  }

  deleteCategoryLink(item) {
    const deleteItems = window.confirm('Soll diese Kategoriezuordnung wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(this.settings.restBaseUrl + 'process/category/link/' + item.linkId, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getTeamCategories(this.currentSelected.id);
            this.categoryAddId = -1;
          });
    }
  }

  changeMainCategory(item) {
    this.http.put<any[]>(this.settings.restBaseUrl + 'process/category/link/changeMain/' + item.linkId, { fk_team: this.currentSelected.id }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.teamCategories.forEach((element) => {
                element.base_category = 0;
            });
            item.base_category = 1;
            this.currentSelected.fk_category = item.fk_category;
        });
  }

  deactivate(item): void  {
      this.teamToDelete = item;
      this.message.open('messageBoxDeactivateTeam');
  }

  switchSecurityLevel(item, securityLevel): void
  {
      this.http.put<{status, message}>(
          this.settings.restBaseUrl + 'team/securityLevel/' +  item.id + '/' + securityLevel, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if(data.message == 'Es kann nur ein Team als HinSchG markiert werden.'){this.infoBoxText = data.message; this.message.open('infoBox');}
              this.search(false);
          });

  }

  executeDeactivate() {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'team/active/' +  this.teamToDelete.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.message.close('messageBoxDeactivateTeam');
              this.teamToDelete = null;
              setTimeout(() => {
                  this.search(false);
              }, 300);
              this.initCurrentSelected();
              this.showNewItemForm = false;
          });
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'team/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  setImapPort(confirmed: boolean) {
      if (confirmed) {
          this.currentSelected.port1 = this.messageBoxSSLPort;
          this.messageBoxSSLPort = '';
          this.message.close('messageBoxImapPort');
      } else {
          for (const item of this.port1Options) {
              if ( item.encryption === this.currentSelected.ssl1 ) {
                  this.messageBoxSSLPort = item.id.toString();
                  this.message.open('messageBoxImapPort');
              }
          }
      }
  }

  setSmtpPort(confirmed: boolean) {
      if (confirmed) {
          this.currentSelected.port2 = this.messageBoxSSLPort;
          this.messageBoxSSLPort = '';
          this.message.close('messageBoxSMTPPort');
      } else {
          for (const item of this.port2Options) {
              if ( item.encryption === this.currentSelected.ssl2 ) {
                  this.messageBoxSSLPort = item.id.toString();
                  this.message.open('messageBoxSMTPPort');
              }
          }
      }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'team/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  deleteUserLink(id) {
    this.userToDeleteFromTeam = id;
    this.message.open('messageBoxDeleteTeamMember');
  }

    deleteTeamMember() {
            this.http.delete<any[]>(this.settings.restBaseUrl + 'team/link/' + this.userToDeleteFromTeam, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.message.close('messageBoxDeleteTeamMember');
                    this.userToDeleteFromTeam = null;
                    this.getTeamUserList();
                });
    }

  getTeamUserList() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'team/user/' + this.currentSelected.id, JSON.stringify(this.teamMemberSearchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
              this.teamUserList = data;
        }
    );
  }

  checkTeamUserLinkExistence(userId) {
    /*
    let leaderExist = 0;
    this.teamUserList.forEach((element) => {
      if (element.fk_team_role === '5' || element.fk_team_role === '4') { leaderExist++; }
    });

    if (leaderExist < 1 && this.teamUserList.length > 0) {
      alert('Es muss ein Verantwortlicher oder Hauptverantwortlicher für das bestehende Team geben. Wählen Sie einen Verantwortlichen aus bevor sie andere Änderungen an den Teammitgliedern vornehmen.');
      return;
    }
    */

    this.teamUserLinkExists = false;

    this.addUserToTeam(userId);
  }

  addUserToTeam(userId) {

    this.teamUserList.forEach((item) => {
      if (item.user_id === userId && item.fk_team_role !== null) {
          alert('Der Benutzer ist bereits in diesem Team.');
          return;
      }
    });

    const selectedTeamsdata = {
    fk_groupid: this.currentSelected.id,
    fk_userid: userId,
    default_team: 0,
    standartteam: 0,
    fk_team_role: 3
    };

    this.http.put<any[]>(
      this.settings.restBaseUrl + 'user/team' , selectedTeamsdata, this.httpOptions)
      .pipe(map(data => data)).subscribe(
      data => {
        this.getTeamUserList();
      });
    }

  checkUserTeamRole(item) {
    this.teamMemberSearchParameter.showAll = false;
    let leaderId = '';
    this.teamUserList.forEach((element) => {
        if (element.fk_team_role === '5') { leaderId = element.user_id; }
    });
    let leaderExist = 0;
    let startRole = 0;
    let leaderChange = false;
    this.teamUserList.forEach((element) => {
        if (element.fk_team_role === '5' || element.fk_team_role === '4') { leaderExist++; }
        if (element.fk_team_role !== '5' && leaderId === item.user_id ) { leaderChange = true; }
    });
    if (item.fk_team_role !== '5' && leaderChange) {
        this.openNoTeamLeaderWarning();
    }
    this.http.get<any[]>(this.settings.restBaseUrl + 'team/link/' + item.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            // @ts-ignore
          startRole = data.fk_team_role;

          if (leaderExist < 1 && item.fk_team_role !== '5') {
            this.teamLeaderRoleContent = 'Sie haben den letzten Verantwortlichen aus dem Team entfernt!';
            this.message.open('messageBoxTeamLeaderRole');
          }
          if (item.fk_team_role === '5' ) {
            this.http.get<{ id }>(this.settings.restBaseUrl + 'team/leaderCheck/' + this.currentSelected.id, this.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                  this.existingLeader = data2;
                  if (this.existingLeader != null) {
                    this.openTeamLeaderChangeWarning();
                    this.interimTeamLeaderData = {id: item.id, fk_team_role: item.fk_team_role};
                    return;
                  } else {
                    this.setUserTeamRole(item.fk_team_role, item.id);
                  }
                  setTimeout(() => {
                    this.getTeamUserList();
                  }, 300);
                }
            );
          } else {
            this.setUserTeamRole(item.fk_team_role, item.id);
            setTimeout(() => {
              this.getTeamUserList();
            }, 300);
          }
        }
    );
  }

  openTeamLeaderChangeWarning() {
    this.teamLeaderChangeTitle = 'Teamleiter wechseln ?';
    this.teamLeaderChangeContent = 'Es existiert bereits in Leiter für dieses Team!\nSind Sie sicher, dass Sie den Leiter Wechseln möchten ?';
    this.teamLeaderChange = true;
    this.message.open('messageBox');
  }

  changeTeamLeader() {
      this.setUserTeamRole(this.interimTeamLeaderData.fk_team_role, this.interimTeamLeaderData.id);
      this.setUserTeamRole(4, this.existingLeader.id);
      setTimeout(() => {
          this.getTeamUserList();
      }, 300);
  }

  openNoTeamLeaderWarning() {
      this.teamLeaderChangeTitle = 'Kein Teamleiter !';
      this.teamLeaderChangeContent = 'Sie haben den Teamleiter aus dem Team entfernt!\n In Zukunft gehen alle mails ohne automatisch zugewiesenen Verantwortlichen in den Teameingang..';
      this.teamLeaderChange = false;
      this.message.open('messageBox');
  }

  setUserTeamRole(role, id) {
    this.http.put<any[]>(this.settings.restBaseUrl + 'team/role/' + id + '/' + role, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        }
    );
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxShiftStartElement = false;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxSwapAll(): void  {
    for (const item of this.data) {
      item.checked = this.checkboxAllState;
    }
    this.handleCheckboxChanged(false);
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }
    if (this.checkboxShiftStartElement == null) { this.checkboxShiftStartElement = clickedItem;
    } else {
      let direction = 'backwards';

      for (const item of this.data) {
        if (item === clickedItem)                       {direction = 'forward'; }
        if (item === this.checkboxShiftStartElement)    {direction = 'backwards'; }
      }

      if (direction === 'backwards') {
        const temp = this.checkboxShiftStartElement;
        this.checkboxShiftStartElement = clickedItem;
        clickedItem = temp;
      }

      let mark = false;

      for (const item of this.data) {
        if (item === this.checkboxShiftStartElement) {mark = true; }
        if (mark) {item.checked = true; }
        if (item === clickedItem) { mark = false; }
      }

      this.checkboxShiftStartElement = null;
    }
    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.data.filter((item) => item.checked);
  }

  checkboxActionDropdownChanged(): void {
    if (this.checkboxActionDropdown === '1') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action1_Parameter1'},
        {value: '2', name: 'Action1_Parameter2'},
        {value: '3', name: 'Action1_Parameter3'}];
    } else if (this.checkboxActionDropdown === '2') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action2_Parameter1'},
        {value: '2', name: 'Action2_Parameter2'},
        {value: '3', name: 'Action2_Parameter3'}];
    }
  }

  handleCheckboxAction(): void  {
    this.handleCheckboxChanged(false);
    let alertString =   'Checkboxes Action: ' + this.checkboxActionDropdown +
        ' Parameter: ' + this.checkboxActionParameterDropdown +
        ' - Boxes: ';
    for (const item of this.checkboxData) {
      alertString += ' - ' + item.id;
    }
    alert(alertString);
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(KeyboardEvent): void {
    if (KeyboardEvent.which === 13) { this.search(false); }
  }

    changeToAddUserToTeamMode( changeTo ) {
        this.initTeamMemberSearchParameter();
        this.teamMemberSearchParameter.showAll = changeTo;
        this.getTeamUserList();
    }

    teamMemberSearchOnReturnPressed(KeyboardEvent) {
        if (KeyboardEvent.which === 13) { this.getTeamUserList(); }
    }

    resetTeamMemberSearch() {
        this.initTeamMemberSearchParameter();
        this.getTeamUserList();
    }


}
