import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit, OnChanges {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');
  public overlayId: string;
  public modelValue : string;
  @Output() modelChange: EventEmitter<string>;
  @Input()  lable : string;
  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    if (typeof value === 'string' ) {
      this.modelValue = value;
    }
  }

  constructor() {
    this.modelChange = new EventEmitter();
  }

  changeModel(): void {
    if(this.modelValue ==='1') {
      this.modelChange.emit('0');
    } else {
      this.modelChange.emit('1');
    }
  }

  ngOnInit() {
  }
}
