<DIV style="display: inline-block; vertical-align: top; " id="{{overlayId}}ListSearchFilterPanel" >

    <div class="form" >
        <button (click)="activateOverlay()"
                tooltip="Menü zum Laden und Speichern der Sucheinstellungen"
                style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                tabindex="4">
            <img src="../design/icons/filter.png" style="margin-left:-1px;">
        </button>
    <div id="{{overlayId}}" class="shadow" style="overflow: auto; max-height:200px; width:250px; visibility:hidden; color: #000000; position: absolute; margin-left:-20px;z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
        <div class="form" style="width:100%; height:28px; border-bottom: 1px solid black;">
            <span *ngIf="!newItemMode">
            <div style="margin-left:4px; margin-top:5px; display: inline-block;"><b>Suchfilter</b></div>
                <button (click)="addNewItem()" style="float: right;min-width:32px; margin-right:2px;margin-top:2px; ">
                    <img src="../design/icons/add.png">
                </button>
            </span>

            <span *ngIf="newItemMode">
                <input (keydown)="checkForReturn($event)" id="newListFavoriteName" type="text" placeholder="Name hier eingeben" [(ngModel)]="newItemName" style="height: 19px !important;margin-left: 2px;margin-top: 2px;width: calc( 100% - 48px);">
                <button (click)="saveItem();" style="float: right;min-width:32px; margin-right:2px;margin-top:2px; ">
            <img src="../design/icons/disk.png">
        </button>
            </span>
        </div>
        <table class="data_tabel" style="width:100%; margin-top:0 !important; font-size: 12px;" cellspacing="0" cellpadding="2">
            <tr *ngFor="let x of filterList; let i = index">
                <td (click)="selectItem(x)">{{x.name}}</td>
                <td style="width:19px;" (click)="openDeleteMessage(x)"><img src="../design/icons/delete.png"></td>
            </tr>
        </table>

    </div>

    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>
    </div>
</DIV>

<jw-modal id="ListSearchFilterDeleteMessage" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/delete.png"><span style=""> Löschen</span><BR><BR></div>
    </div>
    <div class="modalContent">
        <b> <span *ngIf="deleteItem">{{deleteItem.name}}</span></b><br>
        Wollen Sie diesen Favoriten Eintrag wirklich Löschen?
    </div>
    <div class="modalFooter form" >
        <button (click)="executeDeleteItem();" style="float: right; margin-top: 3px;">Löschen</button>
        <button (click)="closeDeleteItemMessage()" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="ListSearchFilterNoNameErrorMessage" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/exclamation.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sie müssen einen Namen angeben um den Filter zu Speichern!
    </div>
    <div class="modalFooter form" >
        <button (click)="message.close('ListSearchFilterNoNameErrorMessage')" style="float: right; margin-top: 3px;">Ok</button>
    </div>
</jw-modal>
