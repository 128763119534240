import { Component, OnInit, HostListener, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';

@Component({
  selector: 'app-process-search-workdocument',
  templateUrl: './process-search-workdocument.component.html',
  styleUrls: ['./process-search-workdocument.component.css']
})
export class ProcessSearchWorkdocumentComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public fullscreenFilename = '';
  public iframeFullscreenSource: any;
  public showFinishedWorkdocument = false;
  public selectedWorkdocument;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', tid: '', send_time : '', tid_workdocument : '', title : '', typ : '', contact : '', contact_company: '', contact_site: '', engineer: '', time_duration: '', billing_type: '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { company: 0, site: 0, contact: 0, loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'send_time DESC', text: '', year : '', type : '-1', dateFrom : '', dateTo : '', closed: 0 };
  }

  @HostListener('document:keydown ', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
        this.closeFullscreen();
        return;
    }
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 500;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/workdocument/search', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.title;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

  save(): void {
    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.currentSelected = item;
    this.showFinishedWorkdocument = false;
  }

  getSelectedWorkdocument(item) {
    this.http.get<{location, filename}>(
        this.settings.restBaseUrl + 'process/workdocument/search/' + item.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.selectedWorkdocument = data.location + data.filename;
            this.currentSelected = item;
            this.showFinishedWorkdocument = true;
        }
    );
  }

  openWorkdocument(id, tid, documentType, tidWorkdocument) {
    if ( documentType === 8 ) { documentType = 1; } else { documentType = 0; }
    const filename = 'Drucken';
    const filepath = '../ticket/processDocument.php?session_id=' + this.settings.sessionId + '&id=' + tid + '&aussenid=' + id + '&extern=' + documentType + '&reprintId=' + tidWorkdocument ;
    this.viewFullscreen( null, filepath, filename);
  }

  public viewFullscreen(event, filepath, filename) {
    if (event != null && event.ctrlKey) {
      window.open(filepath, '_blank');
    } else {
      this.fullscreenFilename = filename;
      this.iframeFullscreenSource = filepath;
      this.message.open('fullscreenReading');
    }
  }

  closeFullscreen() {
    this.fullscreenFilename = '';
    this.iframeFullscreenSource = '';
    this.message.close('fullscreenReading');
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'template/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  delayedSearch() {
    setTimeout(() => {
      this.search(false);
    }, 300);
  }

    changeSort(id, start: string) {
      // todo : Implement
      alert('Nicht Implementiert');
    }

  activate(item) {
    // todo : Implement
    alert('Nicht Implementiert');
  }
}
