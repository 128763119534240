<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone">
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}}
                    von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    CRM > Passwörter
                </span>

                <span class="form search">
                    <input type="text"
                        (keydown)="searchOnReturnPressed($event)"
                        [(ngModel)]="searchParameter.text"
                        id="searchText"
                        style="width:100px;"
                        tabindex="1"
                        [ngClass]="{ 'error' : searchParameter.text != '' }">

                    <app-contact-manager id="searchContact"
                         mode="free"
                         [(contact)]="searchParameter.fk_contact"
                         [(company)]="searchParameter.fk_contact_company"
                         [markOnSet]="true"
                         style="width: 250px;">
                    </app-contact-manager>

                    <select  (change)="search(false)"
                             [(ngModel)]="searchParameter.category"
                             [ngClass]="{ 'error' : searchParameter.category > -1 }"
                             style="width:190px;"
                             tabindex="3">
                        <option value="-1">Alle Kategorien</option>
                        <option *ngFor="let x of dropDownCategoryData; let i = index" [value]="x.id">{{x.name}}</option>
                    </select>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="5">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="6">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="9">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;" tabindex="8"></span>

                    <button (click)="searchPublic()"
                            [ngClass]="{ 'marked' : searchParameter.public == 2 }"
                            tooltip="Öffentliche Passwörter anzeigen"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;float: right;"
                            tabindex="7">
                        <img src="../design/icons/book_open.png" style="margin-left: -1px;">
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">

                        <select (change)="search(false)"
                                                    [(ngModel)]="searchParameter.year"
                                                    style="width:90px;"
                                                    tabindex="2"
                                                    [ngClass]="{ 'error' : searchParameter.year != '' }">
                            <option value="">Alle Jahre</option>
                            <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4"
                                [ngClass]="{ 'error' : searchParameter.user != '' }">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th *ngIf="tableSettings.create_timestamp.state" style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="DATUM" sortColumn="create_timestamp"></span>
                        </th>
                        <th *ngIf="tableSettings.fk_type.state" style="width:180px;text-align: center">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="KATEGORIE" sortColumn="fk_type"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="NAME" sortColumn="name"></span>
                        </th>
                        <th *ngIf="tableSettings.fk_contact_company.state" style="width:350px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="KONTAKT / FIRMA" sortColumn="fk_contact_company"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="ERSTELLER" sortColumn="create_fk_user"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="6" cellspacing="0">
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td *ngIf="tableSettings.create_timestamp.state"
                            (click)="show(x,false)"
                            style="width:80px; text-align: center;">
                            {{ x.create_timestamp_string }}
                        </td>

                        <td *ngIf="tableSettings.fk_type.state" (click)="show(x,false)"
                            style="width:180px;">{{ x.typ }}</td>

                        <td *ngIf="tableSettings.name.state" (click)="show(x,false)">{{ x.name }}</td>

                        <td *ngIf="tableSettings.fk_contact_company.state" (click)="show(x,false)" style="width:350px;">
                           {{ x.company_name_string }}
                            <span *ngIf="x.user_name_string">  <b>({{ x.user_name_string }})</b></span>
                        </td>

                        <td *ngIf="tableSettings.create_fk_user.state" (click)="show(x,false)" style="width:150px;">
                            {{ x.create_fk_user_string }}
                        </td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length < searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>


        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone">
            <div id="rightContainerTop">
                  <div style="margin-left: 10px;margin-top:6px;" *ngIf="showNewItemForm || currentSelected.id>0">
                            <span *ngIf="showNewItemForm">Neues Passwort anlegen</span>
                            <span *ngIf="currentSelected.id>0 && showNewItemForm">Passwort bearbeiten</span>
                            <span *ngIf="currentSelected.id>0 && !showNewItemForm">Passwort anzeigen</span>
                            <span *ngIf="login.show">Identität bestätigen</span>
                  </div>
            </div>


            <div id="rightContainerContent">
                <div *ngIf="( showNewItemForm || currentSelected.id>0) && currentSelected.edit == true" class="form" style="margin-left:10px;" >
                    <table style="width:99%;" cellpadding="6">
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input [(ngModel)]="currentSelected.name"
                                       [ngClass]="{ 'error' : currentSelected.name == '' && formError }"
                                       type="text"
                                       id="currentSelectedName"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                            <td>
                                <span class="formCaption">Typ *</span>
                                <select [(ngModel)]="currentSelected.fk_type"
                                        id="currentSelectedCategory1"
                                        style="width:100%;"
                                        tabindex="104"
                                        [ngClass]="{ 'error' : currentSelected.fk_type == '-1' && formError }">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropDownCategoryData" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Firma oder Kontakt *</span>
                                <app-contact-manager id="processSelectedContact"
                                                     mode="free"
                                                     [(contact)]="currentSelected.fk_contact"
                                                     [(company)]="currentSelected.fk_contact_company"
                                                     style="width: 100%;"
                                                     [mark]="formError && currentSelected.fk_contact_company == 0">
                                </app-contact-manager>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Kommentare</span>

                                <editor [(ngModel)]="currentSelected.comments"
                                        tabindex="103"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit">
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%;">
                                <span class="formCaption">Username *</span>
                                <input [(ngModel)]="currentSelected.username"
                                       [ngClass]="{ 'error' : currentSelected.username == '' && formError }"
                                       type="text"
                                       id="currentSelectedUsername"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                            <td style="width:50%;">
                                <span class="formCaption">Password *</span>
                                <input [(ngModel)]="currentSelected.password"
                                       [ngClass]="{ 'error' : currentSelected.password == '' && formError }"
                                       type="text"
                                       id="passwordDataList"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label> Öffentlich <input type="checkbox"
                                                          [value]="currentSelected.public"
                                                          [(ngModel)]="currentSelected.public"
                                                          [ngModelOptions]="{standalone: true}">
                                </label>
                            </td>
                            <td>
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="( showNewItemForm || currentSelected.id>0) && currentSelected.edit == false" class="card" style="margin-left:20px;margin-right:20px;margin-top: 20px; width: calc( 100% - 42px);">

                    <table cellpadding="8" cellspacing="0" class="data_tabel" style="width:100%">

                        <tr>
                            <td style="width: 25px"><b>Name:</b></td>
                            <td>{{ currentSelected.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 25px;"><b>Kontakt/Firma:</b></td>
                            <td>{{ currentSelected.contact_name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 25px"><b>Typ:</b></td>
                            <td>{{currentSelected.typeName}}</td>
                        </tr>
                        <tr>
                            <td style="width: 25px; vertical-align: top;"><b>Kommentare:</b></td>
                            <td><span [innerHTML]=" currentSelected.comments | safe"></span></td>
                        </tr>
                        <tr>
                            <td colspan="2" style="height:10px;"> </td>
                        </tr>
                        <tr>
                            <td style="width: 25px"><b>Benutzername:</b></td>
                            <td>{{currentSelected.username}}</td>
                        </tr>
                        <tr>
                            <td style="width: 25px"><b>Passwort:</b></td>
                            <td>{{currentSelected.password}}</td>
                        </tr>
                    </table>
                </div>

                <DIV *ngIf="login.show" class="card" style="width:300px;margin: 100px auto; margin-top: 50px;" >
                    <div style="margin:20px;" class="form">

                        <div class="label">
                            Benutzer
                            <span class="blinkingWhiteToRed" *ngIf="capsActive" style=" display:inline-block; float: right; margin-right:10px;">Feststelltaste ist aktiviert!</span>
                        </div>
                        <div class="textfild">
                            <input readonly style="width: calc( 100% - 5px );"
                                   onfocus="this.removeAttribute('readonly');" autocomplete="off" id="username" name="value_480B861BE049E2EE358C18DD78999AF6" type="text" [(ngModel)]="loginData.username" >
                        </div>
                        <div class="label">
                            Passwort
                        </div>
                        <div class="textfild">
                            <input readonly style="width: calc( 100% - 5px );"
                                   onfocus="this.removeAttribute('readonly');"
                                   autocomplete="off" id="password" name="value_480B861BE049E2EE358C18DD78999AF6"
                                   type="password" [(ngModel)]="loginData.password" (keydown)="checkForReturn($event)">
                        </div>
                        <div style="width: 100%; height:15px; margin-top: 15px;">
                            <button style="float: right;" (click)="startLogin()"><img src="../design/icons/key.png" >Anmelden</button>
                        </div>
                        </div>
                </DIV>

            </div>


            <jw-modal id="editorWarning" class="jw-modal shadow">
                <div class="modalHeader">
                    <div>
                        <img src="../design/icons/exclamation.png"
                             style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
                    </div>
                </div>
                <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
                </div>
                <div class="modalFooter form" style="text-align: right">
                    <button (click)="message.close('editorWarning');"
                            style="margin-top: 3px;margin-left: 3px; float: right;">
                        Schließen
                    </button>
                </div>
            </jw-modal>

             <context-menu #basicMenu>
                <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)">
                    <span style="margin: 6px; display: inline-block;">
                        <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
                    </span>
                </ng-template>

                 <ng-template contextMenuItem (execute)="show($event.value,true)">
                    <span style="margin: 6px; display: inline-block;">
                        <img src="../design/icons/pencil.png" style="vertical-align: middle">&nbsp;&nbsp;Bearbeiten
                    </span>
                 </ng-template>

                 <ng-template *ngIf="deleteRight" contextMenuItem (execute)="delete($event.value)">
                    <span style="margin: 6px; display: inline-block;">
                        <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
                    </span>
                 </ng-template>
            </context-menu>

        </div>
    </as-split-area>
</as-split>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">{{messageContent}}</div>
    <div class="modalFooter form" ><button (click)="closeMessageBox()" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>