import { Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector:     'app-group-manager',
  templateUrl:  './group-manager.component.html',
  styleUrls:   ['./group-manager.component.css']
})
export class GroupManagerComponent implements OnInit{

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public addToSiteUserId = '-1';
  public currentSiteUsers = [];
  public companyId: string;
  public selectableUsers = [];
  public editorWarningString = '';

  initCurrentSelected(): void {
    this.currentSelected = { id : '', type: '', name : '', site_short_name : '', postcode: '', city: '', street: '', street_number: '', fk_company_id: 1, email_signature: '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'name', active: '1' };
  }

  constructor(public http: HttpClient,
              public settings: SettingsService, public message: ModalService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }

  ngOnInit() {
    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = data;

          this.search(false);
        }
    );
    document.getElementById('searchText').focus();

    setTimeout(() => {
      if (this.data.length === 1) {
        this.edit(this.data[0]);
      }
    }, 300);

  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'groupmanager', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.currentSelectedTab = 1;
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.data.length > 0) { this.currentSelected.type = 11; } else { this.currentSelected.type = 10; }
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    }

    let signatureDataId = this.currentSelected.id;
    const companySiteData = {
      name: this.currentSelected.name
    };

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'groupmanager/group' + endpointIdAdd, companySiteData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        if(data.message > 0){
            if(data.message == 0.5){ this.editorWarningString = 'Eine Gruppe mit diesem Namen existiert bereits. Anlegen nicht möglich.';
                this.message.open('editorWarning');
                 return;}else{
        this.search(false);
        this.showNewItemForm == false;
        this.currentSelected.id = data.message;
        this.currentSelectedTab = 2;
        this.edit(data.message);}}else {
            this.search(false);
        }}
    );
  }

  edit(item): void  {
    this.addToSiteUserId = '-1';
    this.getSelectableUsers();
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'groupmanager/group/' + item,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
                this.showNewItemForm = false;

                this.getGroupUsers();

                if ( this.currentSelectedTab === 1) {
                  setTimeout(() => {
                    document.getElementById('currentSelectedName').focus();
                    if (this.currentSelected.email_signature == null) { this.currentSelected.email_signature = ''; }
                  }, 100);
                } else {
                  document.getElementById('addToSiteUserId').focus();
                }
        });
  }

  getGroupUsers() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'groupmanager/group/user/' + this.currentSelected.id , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSiteUsers = data;
        }
    );
  }

  deleteUser(id){
      this.http.get<{status, message}>(this.settings.restBaseUrl + 'groupmanager/group/user/unreadInformations/' + id + '/' + this.currentSelected.id , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if (data.message === true) {
                  this.editorWarningString = 'Der Benutzer hat noch ungelesene Benachrichtigungen und kann deshalb nicht entfernt werden.';
                  this.message.open('editorWarning');
                  return;
              } else if(data.message === false) {this.getGroupUsers(); return; }
          }
      );
  }

  getSelectableUsers() {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/company/user' , {company: this.companyId}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.selectableUsers = data.data;
        }
    );
  }

  checkIfUserAlreadyAssigned() {
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'groupmanager/group/user/checkAssigned/' + this.addToSiteUserId + '/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (data.message === true) {
              this.editorWarningString = 'Der Benutzer ist bereits dieser Gruppe zugewiesen.';
              this.message.open('editorWarning');
              return;
               } else { this.addUserSiteLink(); }
        }
    );
  }

  addUserSiteLink() {

      this.http.put<any[]>(this.settings.restBaseUrl + 'groupmanager/group/user' , {contactId: this.addToSiteUserId, siteId: this.currentSelected.id}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getGroupUsers();
          }
      );

  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<{status, message}>(
          this.settings.restBaseUrl + 'groupmanager/group/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if (data.message === false) {
                  this.editorWarningString = 'Zu dieser Gruppe gibt es noch ungelesene Benachrichtigungen, deshalb kann diese nicht deaktiviert werden.';
                  this.message.open('editorWarning');
                  return;
              }


            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'groupmanager/group/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
