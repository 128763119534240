<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Firma > Benutzergruppen
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.active"
                            style="width:80px;"
                            tabindex="2">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                    </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            style=""
                            tabindex="4">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="5">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Name" sortColumn="name"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">
                        <td (click)="edit(x.id)">&nbsp;{{ x.name }}</td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="showNewItemForm" style="margin-left:5px; margin-top:6px;">
                    <span>Neuen Gruppe anlegen</span>
                </div>
                <div *ngIf="currentSelected.id>0" style="margin-left:10px; margin-top:0px;">
                    <span>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Gruppe bearbeiten</button>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Gruppenmitglieder</button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px; overflow-x: hidden; height: 100%;">
                    <table style="width:99%; height: 10%;" cellpadding="6" *ngIf="currentSelectedTab === 1">
                        <tr style="height: 30px;">
                            <td style="width: 50%;">
                                <span class="formCaption">Gruppenname *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td> </tr>

                        <tr style="height: 50px;">
                            <td colspan="2">
                                <span class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="108">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <div style="left: 0; right: 0; top: 0; height: 56px; position: absolute; overflow-y: hidden; overflow-x: hidden;" *ngIf="currentSelectedTab === 2">
                        <table style="width:100%; margin-left: -5px;" class="form_tabel light" cellpadding="4" cellspacing="0">
                            <tr>
                                <td>
                                    <select [(ngModel)]="addToSiteUserId"
                                            id="addToSiteUserId"
                                            class="cp-textbox"
                                            style="width: calc(100% - 8px); margin-left: 10px;"
                                            tabindex="101" >
                                        <option value="-1">Benutzer Wählen</option>
                                        <option *ngFor="let x of selectableUsers" value="{{x.id}}">{{x.name}},{{x.first_name}}</option>
                                    </select>
                                </td>
                                <td style="width: 100px;" class="form">
                                    <button (click)="checkIfUserAlreadyAssigned()" style="margin-left: 10px; width: 110px;" tabindex="102">
                                        <img src="../design/icons/add.png"> Hinzufügen
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <table style="width: calc(102%); margin-left: -4px;" class="form_tabel light" cellpadding="2" cellspacing="0">
                                        <tr>
                                            <th style="width: 300px;">Name</th>
                                            <th>Position</th>
                                            <th style="width: 30px"></th>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style="left: 0; right: 0; bottom: 0; top: 55px; position: absolute; overflow-y: auto; overflow-x: hidden;" *ngIf="currentSelectedTab === 2">
                        <table style="width:102%; margin-left: -5px;" class="form_tabel light" cellpadding="6" cellspacing="0">
                            <tr>
                                <td>
                                    <table style="width: calc(100%); margin-left: -4px; margin-top: -6px;" class="data_tabel" cellpadding="2" cellspacing="0">
                                        <tr *ngFor="let x of currentSiteUsers">
                                            <td style="width: 300px;">
                                                &nbsp;{{x.name}}
                                            </td>
                                            <td>
                                                &nbsp;{{x.position}}
                                            </td>
                                            <td style="width: 30px" (click)="deleteUser(x.fk_core_user)">
                                                <img style="margin-left: 8px" src="../design/icons/delete.png">
                                             </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<context-menu #basicMenu>
    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>
</context-menu>


<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
