<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footer1Row spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Workflow > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="workflow">Workflow</option>
                        <option value="workflow-form">Formulare</option>
                        <option value="workflow-running" selected>Laufende Workflows</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2"
                            [ngClass]="{ 'error' : searchParameter.year != '' }">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <app-contact-manager
                            mode="contact"
                            onChange="ngOnChanges()"
                            [(contact)]="searchParameter.fk_contact"
                            [markOnSet]="true"
                            style="width:250px;" >
                    </app-contact-manager>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;"></span>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4"
                                [ngClass]="{ 'error' : searchParameter.user != '' }">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th *ngIf="tableSettings.workflow_template_name.state" style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Workflow" sortColumn="workflow_template_name"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Quelle" sortColumn="target_name"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:140px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Gestartet" sortColumn="create_timestamp"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td  *ngIf="tableSettings.workflow_template_name.state" (click)="edit($event,x)" ><B>{{x.module_name}}</B> - {{ x.workflow_template_name }}<br>
                            {{x.step_description}} </td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)">
                            <b>{{ x.target_name }}</b>
                            <span *ngIf="x.target_title!=''"> - {{x.target_title}}</span>
                            <span *ngIf="x.assigned_fk_user>0"><BR><B>Bearbeiter Dokumentenportal: </B>{{x.assigned_fk_user_string}}</span>
                        </td>

                        <td  *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)" style="width:140px;text-align: center;">{{ x.create_timestamp * 1000 | date:"dd.MM.y HH.mm" }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">

                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden;">
                <span *ngIf="currentSelected.id>0"><BR>
                    <div style="width:95%; border:1px solid lightgrey; " class="card" >
                        <table class="data_tabel" style="width:100%;margin-bottom: -2px;" cellpadding="4">
                            <tr>
                                <td colspan="2">
                                    <img src="../design/icons/start-event-message.256x256.png" style="width: 24px;display: inline-block;margin-right:8px;">
                                    <span style="display: inline-block;"><b>Workflow gestartet von:</b> {{currentSelected.user_name}}<BR>
                                        <b>Quelle:</b> {{ currentSelected.target_name }}
                                        <span *ngIf="currentSelected.target_title!=''"> - {{currentSelected.target_title}}</span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%">
                                    <b>Modul:</b> {{currentSelected.module_name}}
                                </td>
                                <td style="width: 50%">
                                    <b>Ereigniss:</b> {{currentSelected.event_name}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Gestartet:</b> {{currentSelected.create_timestamp * 1000 | date:"dd.MM.y HH.mm"}}
                                </td>
                                <td>
                                    <b>Durchgeführt:</b>
                                    <span *ngIf="currentSelected.finish_timestamp=='0'"> Läuft noch</span>
                                    <span *ngIf="currentSelected.finish_timestamp!='0'"> {{currentSelected.finish_timestamp * 1000 | date:"dd.MM.y HH.mm"}}</span>
                                </td>
                            </tr>
                        </table>

                    </div>
                    <div *ngFor="let x of currentSelected.step" style="width:95%; border:1px solid lightgrey;" class="card">
                        <table class="data_tabel" style="width:100%;margin-bottom: -2px;" cellpadding="4">
                            <tr>
                                <td colspan="2">
                                   <img *ngIf="x.fk_workflow_action > 0"  src="../design/icons/task.256x213.png" style="width: 24px;display: inline-block;margin-right:8px;">
                                   <img *ngIf="x.fk_workflow_action == 0" src="../design/icons/end-event-none.256x255.png" style="width: 24px;display: inline-block;margin-right:8px;">

                                   <span style="display: inline-block;">
                                        <B>Aktion:</B> {{x.module_name}}<BR>
                                       {{x.name}}
                                   </span>
                                </td>
                            </tr>
                            <tr *ngIf="x.name != 'Ende'">
                                <td style="width: 50%">
                                    <b>Gestartet:</b> {{x.create_timestamp * 1000 | date:"dd.MM.y HH.mm"}}
                                </td>
                                <td style="width: 50%">
                                    <b>Durchgeführt:</b>
                                    <span *ngIf="x.finish_timestamp=='0'"> Läuft noch</span>
                                    <span *ngIf="x.finish_timestamp!='0'"> {{x.finish_timestamp * 1000 | date:"dd.MM.y HH.mm"}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="x.fk_workflow_action > 0 && x.fk_form>0">
                                <td colspan="2"><b>Formular:</b> {{x.form_name}}</td>
                            </tr>
                            <tr *ngIf="x.data != null">
                                <td colspan="2"><b>Daten:</b><BR>
                                    <div [innerHTML]="x.data | prettyjson"></div>
                                </td>
                            </tr>
                        </table>
                        <div *ngIf="x.name != 'Ende' && x.finish_timestamp!='0'" style="position: relative;left: calc( 50% - 9px );margin-top: -24px;top: 22px;z-index: 999;">
                            <img src="../design/icons/arrow_downBig.png">
                        </div>
                    </div>
                </span>
            </div>
        </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="deleteWorkflow" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">Bitte Bestätigen sie das Löschen des Workflows!<BR>
        <BR>
        <label *ngIf="workflowToDelete.module_name=='DMS'" for="alsoDeleteSource">
        <input type="checkbox" [(ngModel)]="workflowToDelete.alsoDeleteSource" id="alsoDeleteSource" name="alsoDeleteSource" value="1">
         Auch das Quelldokumente unwiderruflich aus dem System löschen.</label>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="startDelete();" style="margin-top: 3px;margin-left: 3px; float: right;">
            Bestätigen
        </button>
        <button (click)="message.close('deleteWorkflow');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Abbrechen
        </button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="delete($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>
