import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { DocumentAutoRecognitionManagementComponent } from './document-auto-recognition-management/document-auto-recognition-management.component';
import { DocumentInFolderComponent } from './document-in-folder/document-in-folder.component';
import { DocumentRightGroupComponent } from './document-right-group/document-right-group.component';
import { TagsComponent } from './tags/tags.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DocumentAssignComponent } from './document-assign/document-assign.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { PipesModule } from '../pipes/pipes.module';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { ModalModule } from '../_modal';
import { MyDefaultTooltipOptions } from '../tooltip-options';
import { DocumentAutoRecognitionTestComponent } from './document-auto-recognition-test/document-auto-recognition-test.component';
import { DocumentPortalComponent } from './document-portal/document-portal.component';


@NgModule({
  declarations: [
    DocumentAutoRecognitionManagementComponent,
    DocumentInFolderComponent,
    DocumentRightGroupComponent,
    TagsComponent,
    DocumentAssignComponent,
    DocumentSearchComponent,
    DocumentAutoRecognitionTestComponent,
    DocumentPortalComponent,

  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule, PipesModule, ModalModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AngularSplitModule,
    ContextMenuModule
  ]
})
export class DocumentModule { }
