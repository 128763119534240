<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header1Row" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Ihre Meldung
                </span>


            </div>

            <div id="leftContainerContent" style="height: 96.5%">

                <span *ngFor="let x of whistleblowerList">
                      <div class="form" style="margin-top: 20px; margin-bottom: 20px;">
                        <div class="card form"  style="scroll-margin: 15px;">
                        <div class="header">
                        <div *ngIf="x.user > 0" style="font-size: 0.8em;margin-bottom: 3px;">Ihre Nachricht - {{x.last_changed}}</div>
                        <div *ngIf="x.user == 0" style="font-size: 0.8em;margin-bottom: 3px;">Antwort der Meldestelle - {{x.last_changed}}</div>
                        </div>
                            <div class="text" [innerHTML]="x.text"></div>
                         </div>
                      </div>
                </span>

                <div class="loading" *ngIf="false"></div>

            </div>

        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" style="height: 30%" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:4px;">
                    Nachricht verfassen
                </div>
            </div>

            <div id="rightContainerContent">

                <div>

                    <table cellpadding="0" cellspacing="0" class="data_tabel noContext" style="width: calc( 100% - 2px); height: 98%;">
                        <tr><td colspan="4">
                            <editor onfocus="let elem = this.getElementsByTagName('iframe')[0];elem.tabIndex = this.tabIndex;setTimeout(() => { elem.contentWindow.document.body.focus(); }, 100);"
                                    [(ngModel)]="reporttext"
                                    [init]="settings.tinyMceInit"
                                    apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                    tabindex="104"
                                    style="height:95%;">
                            </editor></td></tr></table>

                    <div id="leftContainerBottom">
                        <button (click)="saveReply()"
                                style="float: right; margin-right: 1px;"><img src="../design/icons/email_go.png">
                            Absenden
                        </button>
                    </div>

                </div>

            </div>


        </div>


    </as-split-area>
</as-split>