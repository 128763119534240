<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu>123</span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Dokumente > Rechtegruppen

                    <span *ngIf="searchResultCount>data.length" style="float:right; margin-right:10px;">{{data.length}} von {{searchResultCount}} Einträgen</span>
                    <span *ngIf="searchResultCount<=data.length" style="float:right; margin-right:10px;">{{searchResultCount}} Einträge</span>
                </span>
                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1">

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>
                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>
                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>

                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="AUFBEWAHRUNG" sortColumn="min_retention_years"></span>
                        </th>
                        <th *ngIf="securityLevelRight == true" style="width: 150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Sicherheitslevel"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">


                        <td (click)="edit($event,x)">&nbsp;{{ x.name }}</td>


                        <td (click)="edit($event,x)" style="width:150px; text-align: right;">{{ x.min_retention_years }} Jahre&nbsp;</td>

                        <td style="width: 150px; text-align: right;" *ngIf="x.securityLevel > 0 && securityLevelRight == true"><img src="../design/icons/bullet_blue.png"></td>

                        <td style="width: 150px; text-align: right;" *ngIf="x.securityLevel == 0 && securityLevelRight == true"><img src="../design/icons/bullet_white.png"></td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone">
            <div id="rightContainerTop">
                <div style="margin-left:5px; margin-top:6px;">
                    <span *ngIf="showNewItemForm">Rechtegruppe erstellen</span>
                    <span *ngIf="currentSelected.id > 0">Rechtegruppe bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>

                            <td>
                               <span class="formCaption">Laufzeit in Jahre *</span><br>
                                <input type="text"
                                       [(ngModel)]="currentSelected.min_retention_years"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="102">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Text</span>

                                <textarea type="text"
                                          [(ngModel)]="currentSelected.comment"
                                          id="currentSelectedText"
                                          style="width:calc(100% + 1px); border: 1px solid #ceced2; border-radius: 3px;"
                                          rows="4"
                                          tabindex="103" >
                                </textarea>
                            </td>
                        </tr>



                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div [hidden]="currentSelected.id < 1 || selectedDocumentRightGroupUserList.length < 1" class="form" style="left: 0; right: 0; bottom: 0; top: 150px; overflow-x: hidden; overflow-y: auto;">
                    <hr style="width: 100%;">
                    <table style="width: 100%;" cellpadding="4" cellspacing="0">
                        <tr>
                            <td>
                                <select style="width: calc(100% - 14px); margin-left: 14px;"
                                        [(ngModel)]="addToDocumentRightGroupUserId"
                                        tabindex="107">
                                    <option value="0">Benutzer Wählen</option>
                                    <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.userName}}, {{x.userFirstName}}</option>
                                </select>
                            </td>
                            <td style="width: 100px;" class="form">
                                <button (click)="checkIfUserAlreadyAssigned()" style="margin-left: 10px; width: 110px; margin-right: 6px;" tabindex="108">
                                    <img src="../design/icons/add.png"> Hinzufügen
                                </button>
                            </td>
                        </tr>
                    </table>
                    <table class="form_tabel light" cellpadding="4" cellspacing="0" style="width: 100%;">
                        <th>
                            Benutzer
                        </th>
                        <th>
                            Position
                        </th>
                        <th style="width: 5%">
                            Hauptgruppe
                        </th>
                    </table>
                    <table class="data_tabel" cellpadding="4" cellspacing="0" style="width: 100%;">
                        <tr *ngFor="let x of selectedDocumentRightGroupUserList"
                            [contextMenu]="userMenu"
                            [contextMenuValue]="x">
                            <td>{{x.name}}</td>
                            <td>{{x.position}}</td>
                            <td style="width: 5%" *ngIf="x.mainGroup > 0 && currentSelected.id == x.mainGroup ">
                                <img (click)="changeMainGroup(x.mainGroup, currentSelected.id, x.userId)" src="../design/icons/star.png" >
                            </td>
                            <td style="width: 5%" (click)="changeMainGroup(x.mainGroup, currentSelected.id, x.userId)" *ngIf="x.mainGroup < 1 || currentSelected.id != x.mainGroup ">
                                <img src="../design/icons/star_unactive.png" >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

     <ng-template contextMenuItem let-item (execute)="switchSecurityLevel($event.value, '0')" >
        <span *ngIf="item?.securityLevel > 0 && securityLevelRight == true" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_blue.png" style="vertical-align: middle">&nbsp; Sicherheitsstufe der Dokumentengruppe entfernen
        </span>
     </ng-template>

     <ng-template contextMenuItem let-item (execute)="switchSecurityLevel($event.value, '1')">
        <span *ngIf="item?.securityLevel == 0 && securityLevelRight == true" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_white.png" style="vertical-align: middle">&nbsp;&nbsp; Sicherheitsstufe der Dokumentengruppe setzen
        </span>
     </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>

<context-menu #userMenu>
    <ng-template contextMenuItem let-item (execute)="deleteDocumentRightGroupUserLink($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>
