<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Vorgangsmanagement > Projekte
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px; "
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px; "
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ENDDATUM" sortColumn="end_timestamp"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="PROJEKTTYP" sortColumn="project_name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="VERANTWORTLICH" sortColumn="user"></span>
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)">{{ x.name }}</td>

                        <td (click)="edit($event,x)" style="width:150px;"><span *ngIf="x.end_date != '01.01.1970'">{{ x.end_date }}</span></td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.type_name }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.user }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="showNewItemForm">Neues Projekt anlegen</span>

                    <span *ngIf="currentSelected.id>0">
                        Projekt bearbeiten
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="100" >
                            </td>
                            <td style="width: 50%;">
                                <span class="formCaption">Projekttyp *</span>

                                <select [(ngModel)]="currentSelected.fk_project_type"
                                        id="currentSelectedProjectTypeId"
                                        style="width:100%;"
                                        tabindex="101">
                                    <option value="-1" *ngIf="currentSelected.fk_project_type < 1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownProjectType" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <table cellpadding="2" cellspacing="0" style="margin-left: -2px; padding-top: -1px;">
                                    <tr>
                                        <td>
                                        <span class="formCaption">Startdatum</span><br>
                                            <span app-date-picker
                                                  [(model)]="currentSelected.start_date"
                                                  id="currentSelectedStartDate"
                                                  tabindex="103"></span>
                                        </td>
                                        <td>
                                        <span class="formCaption" style="margin-left: 12px;">Enddatum</span><br>
                                        <span app-date-picker
                                              [(model)]="currentSelected.end_date"
                                              style="margin-left: 8px;"
                                              id="currentSelectedEndDate"
                                              tabindex="104">
                                        </span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td>
                                <span class="formCaption">E-Mail Team</span>

                                <select [(ngModel)]="currentSelected.fk_team"
                                        id="currentSelectedProjectTeam"
                                        style="width:100%;"
                                        tabindex="105">
                                    <option value="0">Kein Team gewählt</option>
                                    <option *ngFor="let x of dropdownDataTeam" [value]="x.id">{{x.name}} ({{x.email}})</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: 0; margin-bottom: 10px;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <hr style="width: calc(100% + 8px); margin-left: -10px;">
                    <div class="form">
                        <table style="width: calc(100% + 6px); margin-left: -10px;" class="form_tabel light" cellpadding="4" cellspacing="0">
                            <tr>
                                <td>
                                    <select style="width: calc(100% - 14px); margin-left: 14px;"
                                            [(ngModel)]="addToProjectUserId"
                                            tabindex="107">
                                        <option value="0">Benutzer Wählen</option>
                                        <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.userName}}, {{x.userFirstName}}</option>
                                    </select>
                                </td>
                                <td style="width: 100px;" class="form">
                                    <button (click)="checkIfUserAlreadyAssigned()" style="margin-left: 10px; width: 110px; margin-right: 6px;" tabindex="108">
                                        <img src="../design/icons/add.png"> Hinzufügen
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table style="width: calc(102% + 1px); margin-left: -4px;" class="form_tabel light" cellpadding="2" cellspacing="0">
                                        <tr>
                                            <th style="width: 300px;">Name</th>
                                            <th>Position</th>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style="left: 0; right: 0; bottom: 0; top: 230px; position: absolute; overflow: auto;">
                        <table style="width: calc(100% + 6px); margin-left: -5px;" class="form_tabel light" cellpadding="6" cellspacing="0">
                            <tr *ngIf="currentSelected.id > 0">
                                <td>
                                    <table style="width: calc(100% + 10px); margin-left: -4px;" class="data_tabel" cellpadding="2" cellspacing="0">
                                        <tr *ngFor="let x of currentProjectUsers"
                                            [contextMenu]="userMenu"
                                            [contextMenuValue]="x">
                                            <td style="width: 300px;">
                                                &nbsp;{{x.user_name}}
                                            </td>
                                            <td>
                                                &nbsp;{{x.position}}
                                                <span *ngIf="x.fk_user == currentSelected.user">
                                                <img src="../design/icons/star.png" style="float: right; height: 12px; width: 12px;">
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr *ngIf="currentSelected.id < 1">
                                <td>
                                    <table style="width: calc(100% + 10px); margin-left: -4px;" class="data_tabel" cellpadding="2" cellspacing="0">
                                        <tr *ngFor="let x of newProjectUsers"
                                            [contextMenu]="newProjectMenu"
                                            [contextMenuValue]="x">
                                            <td style="width: 300px;">
                                                &nbsp;{{x.user_name}}
                                            </td>
                                            <td>
                                                &nbsp;{{x.position}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                </div>
            </div>

    </as-split-area>
</as-split>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>

<context-menu #userMenu>
<ng-template contextMenuItem let-item (execute)="deleteProjectuserLink($event.value)" >
        <span *ngIf="item?.fk_user != currentSelected.user" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
</ng-template>

<ng-template contextMenuItem let-item divider="true"></ng-template>


<ng-template contextMenuItem let-item (execute)="checkProjectResponsibleUser($event.value)" >
<span *ngIf="currentSelected.id > 0 && item?.fk_user != currentSelected.user" style="margin: 6px; display: inline-block;">
    <img src="../design/icons/star.png" style="vertical-align: middle">&nbsp;&nbsp;Verantwortlich
</span>
</ng-template>
</context-menu>

<context-menu #newProjectMenu>
    <ng-template contextMenuItem let-item (execute)="deleteNewProjectuser($event.value.id)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>
