<div id="top_bar" style="position: absolute; top:0; left:0; right:0; height:40px; margin-left: 5px; margin-right: 5px; margin-top:10px;">
<div style="float: left;">
    <app-contact-manager onlyIcon="true"></app-contact-manager>

    <button *ngIf="showCalendar==1 && !settings.dev_mode" class="round_button" (click)="openCalendar()" style="margin-left:4px;margin-top:2px;" >
        <img src="../design/icons/calendar.png" style="margin-top:2px; width:24px; height:24px; margin-left: 2px;">
    </button>
    <app-calendar *ngIf="settings.dev_mode" style="margin-left:4px;margin-top:2px; display: inline-block;"></app-calendar>


    <div style="display: inline-block; position: absolute; left:86px; top:2px;">
     <app-top-bar-notification></app-top-bar-notification>
    </div>

</div>

<div style="width:fit-content; position: absolute; left:50%; margin-left: -19px; text-align: center; ">
    <span app-menu></span>
</div>

    <div  style="float: right;">
        <button *ngIf="showAdministration==1" class="round_button" (click)="openAdmin()" style="margin-right:10px;">
            <img src="../design/icons/admin.png" style="margin-top:2px; width:20px; height:20px; margin-left: 2px;">
        </button>

        <button class="round_button" (click)="openHelp()" style="margin-right:10px;" >
            <img src="../design/icons/help_icon.png" style="margin-top:1px; width:23px; height:23px; margin-left: 1px;">
        </button>

        <button class="round_button" (click)="activateUserMenu()">
            <img src="../design/icons/user_menu.png" style="margin-top:2px; width:22px; height:22px; margin-left: 2px;">
        </button>
        <div *ngIf="showUserMenu" style="position: fixed;z-index: 999999;right:6px; top:40px; width:130px;" class="shadow">
            <table class="data_tabel" cellspacing="0" cellpadding="6" style="width: 100%;">
                <tr *ngIf="true" (click)="openUserTimetracking()">
                    <td>
                    <img src="../design/icons/time.png" style="vertical-align: sub">
                    <span *ngIf="currentTimetrackingStatus==0 || currentTimetrackingStatus==3">Eingestochen</span>
                    <span *ngIf="currentTimetrackingStatus==1">Ausgestochen</span>
                    <span *ngIf="currentTimetrackingStatus==2">Pause</span>
                    </td>
                </tr>
                <tr (click)="openUserProfil()"><td>
                    <img src="../design/icons/user.png" style="vertical-align: sub"> Profil
                </td></tr>
                <tr (click)="logout()"><td>
                    <img src="../design/icons/key_go.png" style="vertical-align: sub"> Abmelden
                </td></tr>
            </table>
        </div>
    </div>
</div>
<div (click)="closeAll()" *ngIf="showUserMenuOverlay" [ngClass]="{ 'menuIsBlack' : showUserMenuOverlayBlack  }" id="overlayFullscreenTopMenu" style="width:100%; height:100%; position: fixed; top:0; left:0; right:0; bottom:0; z-index: 99;"></div>
<div *ngIf="showTimetrackingQuickDialog" style="
    width: 600px;
    height: 350px;
    position: fixed;
    z-index: 999;
    background-color: white;
    border: 1px solid gray;
    margin-left: calc( 50% - 300px );
    margin-top: 150px;" class="shadow form">

    <div style="width:100%; height:50px;">
        <div style="height:16px; margin: 10px;">
            <img src="../design/icons/time.png" style="vertical-align: sub">  <b> Zeiterfassung Heute</b>
        </div>

        <table class="data_tabel light" style="width: 100%;" cellpadding="6" cellspacing="0">
            <tr>
                <th style="width:150px;">
                    Aktion
                </th>
                <th>
                    Typ
                </th>
                <th style="width:120px;">
                    Zeit
                </th>
            </tr>
        </table>
    </div>
    <div style="width:100%; height:250px; overflow: auto; border-bottom: 1px solid gray;">
        <table class="data_tabel light" style="width: 100%;" cellpadding="6" cellspacing="0">
            <tr *ngFor="let x of timeTrackingToday">
                <td style="width:150px;">
                    <span *ngIf="x.type==0">Eingestochen</span>
                    <span *ngIf="x.type==2">Pause</span>
                    <span *ngIf="x.type==3">Zurück</span>
                    <span *ngIf="x.type==1">Ausgestochen</span>
                </td>
                <td>
                    <span *ngIf="x.create_fk_user==0">Stechuhr</span>
                    <span *ngIf="x.create_fk_user!=0">Manuell</span>
                </td>
                <td style="width:120px; text-align: right;">
                    {{ x.timestamp * 1000 | date:"dd.MM.y HH.mm" }}
                </td>
            </tr>
        </table>
    </div>

    <div style="height:50px;">
            <button *ngIf="currentTimetrackingStatus==1" style="margin-top:10px; margin-left:4px;" (click)="timeTrackingSend(0)">Einstechen</button>
            <button *ngIf="currentTimetrackingStatus==0 || currentTimetrackingStatus==3" style="margin-top:10px; margin-left:4px;" (click)="timeTrackingSend(2)">Pause</button>
            <button *ngIf="currentTimetrackingStatus==2" style="margin-top:10px; margin-left:4px;" (click)="timeTrackingSend(3)">Zurück</button>
            <button *ngIf="currentTimetrackingStatus==0 || currentTimetrackingStatus==3" style="margin-top:10px; margin-left:4px;" (click)="timeTrackingSend(1)">Ausstechen</button>
        <div style="float: right; display: inline-block;width:180px;margin-top:10px; margin-right:10px; text-align: right;" [innerHTML]="worktimeToday|safe"></div>
    </div>

</div>
