<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Vorgangsmanagement > Kategorien und Status
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameterCategory.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                        <select (change)="search(false)"
                                [(ngModel)]="searchActive"
                                style="width:70px;"
                                tabindex="2">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="resetTeamProjectTypeValue()"
                                [(ngModel)]="teamProjectTypesFilterSwitch"
                                style="width:80px;"
                                tabindex="3">
                            <option value="-1">Alle</option>
                            <option value="team">Teams</option>
                            <option value="projectType">Projekttypen</option>
                        </select>

                        <select [hidden]="teamProjectTypesFilterSwitch == '-1'"
                                (change)="search(false)"
                                [(ngModel)]="teamProjectTypesFilterValue"
                                style="width:80px;"
                                tabindex="3">
                            <option value="-1">Alle</option>
                            <option [hidden]="teamProjectTypesFilterSwitch == 'projectType' || teamProjectTypesFilterSwitch == '-1'" *ngFor="let x of dropdownTeamData" [value]="x.id">{{x.name}}</option>
                            <option [hidden]="teamProjectTypesFilterSwitch == 'team' || teamProjectTypesFilterSwitch == '-1'" *ngFor="let x of dropdownProjectTypeData" [value]="x.id">{{x.name}}</option>
                        </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

    <div *ngIf="newItemFormSelect" id="overlay" class="shadow" style="right: 7px;width: 100px; height: 60px; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
        <table class="data_tabel" style="width: 100%; margin-top: 5px; font-size: 10pt" cellspacing="0" cellpadding="4">
            <tr>
                <td (click)="selectFormType('category')">Kategorie</td>
            </tr>
            <tr>
                <td (click)="selectFormType('state')">Status</td>
            </tr>
        </table>
    </div>
                </span>



                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width: 50%">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameterCategory.sort" name="Kategorie"  sortColumn="name"></span>
                        </th>
                        <th>
                            Sort
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div style="width: 50%; height: 100%; position: absolute; border-right: 5px solid #3454AE !important; top: 0; left: 0; bottom: 0; overflow: auto;">
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of categoryData"
                        [ngClass]="{'selected' : selectedCategory.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="categoryList">
                        <td (click)="editCategory(x)">&nbsp;{{ x.name }}</td>

                    </tr>
                    <button style="margin:20px auto; display: block;" (click)="search(true)"
                            *ngIf="selectedCategory.length > 0 && selectedCategory.length<searchResultCount">Weitere Ergebnisse Anzeigen
                    </button>
                </table>
                </div>

                <div style="width: calc(50% - 5px); height: 100%; position: absolute; top: 0; right: 0; bottom: 0; overflow: auto;">
                <table class="data_tabel" style="width: calc(99% + 3px)" cellpadding="4" cellspacing="0" *ngIf="selectedCategory.id > 0" >
                    <tr *ngFor="let x of statusData"
                        [ngClass]="{'selected' : selectedStatus.id == x.id}"
                        [contextMenu]="statusMenu"
                        [contextMenuValue]="x"
                        id="statusList">
                        <td> {{x.sort}}</td>
                        <td (click)="editStatus($event,x)">&nbsp;{{ x.state_name }}
                            <span style="display: inline-block; float:right; width:60px; text-align: right;">
                                <img *ngIf="x.start=='1'" src="../design/icons/control_play_blue.png" tooltip="Startstatus der Kategorie" [options]="{'show-delay': '1000'}">
                                <img *ngIf="x.autoclosed=='1'" src="../design/icons/stop.png" tooltip="Automatisches schliessen des Vorgangs" [options]="{'show-delay': '1000'}">
                            </span>
                            <div style="width: 102.5%; height: 1px; bottom: 0; left: 0; right: 0; margin-left: -4px; margin-top: 3px; margin-bottom: -6px;" *ngIf="x.progress > 0">
                                <table id="statusProgressBar" style="width: {{x.progress}}%; height: 1px; background-color: #00a000;" ><tr></tr></table>
                            </div>
                        </td>
                    </tr>
                </table>

                    <button style="margin:20px auto; display: block;" (click)="search(true)"
                            *ngIf="selectedStatus.length > 0 && selectedStatus.length<searchResultCount">Weitere Ergebnisse Anzeigen
                    </button>
                </div>
            </div>

        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="padding-top: 5px; margin-left: 5px;">
                    <span *ngIf="formType == 'category'">Neue Kategorie anlegen</span>
                    <span *ngIf="formType == 'state'">Neuen Status anlegen</span>
                    <span *ngIf="selectedCategory.id > 0 && selectedStatus.id < 1 && formType == '0'">Kategorie bearbeiten</span>
                    <span *ngIf="selectedStatus.id > 0">Kategorie / Status bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="selectedCategory.id>0 && formType != 'state' || formType == 'category'" class="form">
                    <table class="form_tabel light" style="width:100%;" cellpadding="6" cellspacing="0" >
                        <tr>
                            <th colspan="2" >Kategorie</th>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption" style=" margin-left: 16px;">Name *</span>
                                <input type="text"
                                       [(ngModel)]="selectedCategory.name"
                                       id="selectedCategoryName"
                                       class="cp-textbox"
                                       style="width:calc( 100% - 20px ); margin-left: 10px;"
                                       tabindex="101" >
                            </td>

                        </tr>
                        <tr *ngIf="selectedCategory.id < 1 && formType == 'category'">
                            <td style="width: 25%;">
                                <span class="formCaption" style=" margin-left: 16px;">Team / Projekttyp *</span>
                                <select (change)="resetNewTeamProjectTypeValue()"
                                        [(ngModel)]="teamProjectTypeSelectSwitch"
                                        style="width:100%;margin-left: 10px;"
                                        tabindex="102">
                                    <option [value]="1">Teams</option>
                                    <option [value]="2">Projekttypen</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption"><span *ngIf="teamProjectTypeSelectSwitch == 1">Team</span><span *ngIf="teamProjectTypeSelectSwitch == 2">Projekttyp</span> *</span>
                                <select [(ngModel)]="teamProjectTypeNewSelect"
                                        style="width: calc(100% - 3px);"
                                        tabindex="103">
                                    <option [hidden]="teamProjectTypeSelectSwitch==2" *ngFor="let x of newCategoryTeamData" [value]="x.id">{{x.name}}</option>
                                    <option [hidden]="teamProjectTypeSelectSwitch==1" *ngFor="let x of newCategoryProjectTypeData" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="saveCategory()" style="width: 100px; float: right;" tabindex="104">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                        <div style="left: 0; right: 0; bottom: 0; height: 400px; position: absolute; margin: 0; padding: 0;" *ngIf="selectedCategory.id > 0 && categoryTeam.length > 0 || selectedCategory.id > 0 && categoryProject.length > 0">
                            <div style="top: 0; left: 0; bottom: 350px; width:50%; position: absolute;">
                                <table style="width:calc(100% + 1px);" class="form_tabel light" cellspacing="0" cellpadding="4">
                                    <tr>
                                        <th colspan="2">
                                            Teams
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="width: 60%;">
                                            Name
                                        </th>
                                        <th style="width: 40%;">
                                            Hauptkategorie
                                        </th>
                                    </tr>
                                </table>
                            </div>

                            <div style="left: 0; top: 44px; left: 0; bottom: 0; width:50%; position: absolute; overflow: auto; margin-left: 0; margin-right: 0;">
                                <table style="width:calc(100% + 1px);" class="data_tabel" cellpadding="4" cellspacing="0">

                                    <tr *ngFor="let x of categoryTeam">
                                        <td style="width: 60%;">
                                            &nbsp;{{ x.name }}
                                        </td>
                                        <td style="width: 40%;">
                                            <img src="../../design/icons/tick_inactive.png" *ngIf="x.base_category == 0" style="padding-left: 50px;">
                                            <img src="../../design/icons/tick.png" *ngIf="x.base_category == 1" style="padding-left: 50px;">
                                        </td>
                                    </tr>
                                </table>
                                </div>
                            <div style="top: 0; right: 0; bottom: 350px; width:50%; position: absolute; border-left: 1px solid #ceced2;">
                                <table style="width:calc(100% + 1px);" class="form_tabel light" cellpadding="4" cellspacing="0">
                                    <tr>
                                        <th colspan="2">
                                            Projekttypen
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="width: 60%;">
                                            Name
                                        </th>
                                        <th style="width: 40%;">
                                            Hauptkategorie
                                        </th>
                                    </tr>
                                </table>
                            </div>
                                <div style="top: 44px; right: 0; bottom: 0; width: 50%; position: absolute; overflow: auto; margin-right: 0; border-left: 1px solid #ceced2;">
                                <table style="width:calc(100% + 1px);" class="data_tabel" cellspacing="0" cellpadding="4">
                                    <tr *ngFor="let y of categoryProject">
                                        <td style="width: 60%;">
                                            &nbsp;{{ y.name }}
                                        </td>
                                        <td style="width: 40%;">
                                            <img src="../../design/icons/tick_inactive.png" *ngIf="y.base_category == 0" style="padding-left: 50px;">
                                            <img src="../../design/icons/tick.png" *ngIf="y.base_category == 1" style="padding-left: 50px;">
                                        </td>
                                    </tr>
                                </table>
                                </div>
                        </div>
                </div>
                <div *ngIf="selectedStatus.id>0 && selectedCategory.id>0 || formType == 'state'" class="form">
                <table style="width:calc(100% + 1px);" class="form_tabel light" cellpadding="6" cellspacing="0">
                    <tr *ngIf="formType != 'state'">
                        <th colspan="2">Status</th>
                    </tr>
                    <tr>
                        <td style="width: 70%;">
                            <span class="formCaption" style="padding-left: 10px !important;">Name *</span>
                            <input type="text"
                                   [(ngModel)]="selectedStatus.name"
                                   id="selectedStatusName"
                                   class="cp-textbox"
                                   style="width:100%; margin-left: 10px;"
                                   tabindex="101" >
                        </td>
                        <td style="width: 30%;">
                            <span class="formCaption" style="margin-left: 15px !important;">Fortschritt (in Prozent)</span>
                            <br>
                            <input type="text"
                                   [(ngModel)]="selectedStatus.progress"
                                   (ngModelChange)="checkProgress()"
                                   (keydown)="checkProgressValue($event)"
                                   id="selectedStatusProgress"
                                   maxlength="3"
                                   class="cp-textbox"
                                   style="width: 90%; margin-left: 10px;"
                                   tabindex="102" >
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <label>
                            <input type="checkbox"
                                   [(ngModel)]="selectedStatus.send_customer_notification"
                                   id="selectedSendCustomerNotification"
                                   class="cp-textbox"
                                   style="margin-left: 10px;"
                                   tabindex="102" >

                            Kundenbenachrichtigung bei Statusänderung auf diesen Status.</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <label>
                            <input type="checkbox"
                                   [(ngModel)]="selectedStatus.start"
                                   id="selectedStatusStart"
                                   class="cp-textbox"
                                   style="margin-left: 10px;"
                                   tabindex="103" >

                            Startstatus der Kategorie.</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <label>
                            <input type="checkbox"
                                   [(ngModel)]="selectedStatus.required_to_close"
                                   id="selectedStatusRequiredToClose"
                                   class="cp-textbox"
                                   style="margin-left: 10px;"
                                   tabindex="104" >

                            Pflichtstatus. Muss gesetzt werden um Vorgang zu beenden.</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <label>
                                <input type="checkbox"
                                       [(ngModel)]="selectedStatus.autoclosed"
                                       id="selectedStatusAutoClosed"
                                       class="cp-textbox"
                                       style="margin-left: 10px;"
                                       tabindex="104" >

                                Automatisches schliessen des Vorgangs.</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="form">
                            <button (click)="saveStatus()" style="width: 100px; float: right; margin-right: 1px;" tabindex="106">
                                <img src="../design/icons/disk.png"> Speichern
                            </button>
                        </td>
                    </tr>
                </table>
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activateCategory($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivateCategory($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>
</context-menu>

<context-menu #statusMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromStatusContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activateState($event.value)" >
        <span *ngIf="item?.active == 0 && selectedCategory.active != 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivateState($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="stateChangeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="stateChangeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="stateChangeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="stateChangeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
