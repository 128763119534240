<DIV style="display: inline-block; vertical-align: middle;  margin-left: 10px; margin-right: -1px;" id="{{overlayId}}Panel">

    <button *ngIf="processModuleActive==1" type="button" class="round_button" (click)="openInbox()" style="margin-right: 10px;"  tooltip="Vorgänge für die Sie direkt zuständig sind">
        <img *ngIf="data.newMail=='0'" src="../design/icons/mails.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
        <img *ngIf="data.newMail=='1'" src="../design/icons/mails_new.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
    </button>

    <button *ngIf="showTeam==1" class="round_button" (click)="openTeam()" tooltip="Teams auf die Sie Zugriff haben">
        <img *ngIf="data.isNotAssignedTeamMail=='0'" src="../design/icons/team_menu.png" style="margin-top:1px; width:24px; height:24px; margin-left: 2px;">
        <img *ngIf="data.isNotAssignedTeamMail!='0'" src="../design/icons/team_menu_unassigned.png" style="margin-top:1px; width:24px; height:24px; margin-left: 2px;">
    </button>

    <button type="button" class="round_button" (click)="toggleOverlay()" style="margin-left:10px;" tooltip="Benachrichtigungscenter">
        <img *ngIf="data.newNotification=='0'" src="../design/icons/notification.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
        <img *ngIf="data.newNotification=='1'" src="../design/icons/notification_1.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
        <img *ngIf="data.newNotification=='2'" src="../design/icons/notification_2.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
        <img *ngIf="data.newNotification!='0' && data.newNotification!='1' && data.newNotification!='2'" src="../design/icons/notification_3.png" style="margin-top:3px; width:24px; height:24px; margin-left: 2px;">
    </button>

    <button type="button" class="round_button" (click)="toggleNotification()" style="margin-left:10px;" >
        <img *ngIf="notificationActive"  src="../design/icons/moon.png" tooltip="Ruhemodus ist nicht aktiv">
        <img *ngIf="!notificationActive" src="../design/icons/moon_active.png" tooltip="Ruhemodus ist aktiv">
    </button>

    <div id="{{overlayId}}" style="position: absolute; z-index: 999999">
        <div *ngIf="OverlayActive" style="text-align: left;margin-left: 180px;font-size: 30px;color: white; height: 25px; margin-top:-12px;">⯅</div>
        <div *ngIf="OverlayActive" class="shadow" style="text-align:left; overflow: hidden; width:700px; height:fit-content; color: #000000; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">

            <table *ngIf="OverlayActive" cellpadding="0" cellspacing="0" style="width:100%;">
                <tr>
                    <td style="width:33%; vertical-align: top;">
                        <table class="data_tabel light"  style="width: 100%;" cellspacing="0" cellpadding="4">
                            <tr >
                                <th colspan="2">Systemaufgaben</th>
                            </tr>
                            <tr *ngIf="data.unfinishedWorkDocument != '0'" (click)="openFinishWorkdocument()">
                                <td style="width:20px;"> <img src="../design/icons/car.png" style="vertical-align: middle;"></td><td><b>{{data.unfinishedWorkDocument}}</b> offene Arbeitsbelege</td>
                            </tr>
                            <tr *ngIf="data.unassignedWorkDocument != '0'" (click)="openManuallyAssign()">
                                <td> <img src="../design/icons/doc_pdf.png" style="vertical-align: middle;"></td><td><b>{{data.unassignedWorkDocument}}</b> nicht erkannte Arbeitsbelege</td>
                            </tr>
                            <tr *ngIf="data.notApprovedDocuments != '0'" (click)="openApproveDocument()">
                                <td> <img src="../design/icons/doc_pdf.png" style="vertical-align: middle;"></td><td><b>{{data.notApprovedDocuments}}</b> nicht zugewiesene Dokumente</td>
                            </tr>
                            <tr *ngIf="data.documentPortalActive!='0'" (click)="openDocumentPortal()">
                                <td> <img src="../design/icons/doc_pdf.png" style="vertical-align: middle;"></td><td><b>{{data.documentPortalActive}}</b> offene Dokumente im Dokumentenportal</td>
                            </tr>
                            <tr *ngIf="data.newNotification=='0'">
                                <td> <img src="../design/icons/tick.png" style="vertical-align: middle;"></td><td>Keine offenen Aufgaben</td>
                            </tr>

                        </table>
                    </td>
                    <td style="border-left: 1px solid gray;width:33%; vertical-align: top;">
                        <div>
                            <table class="data_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                            <tr>
                                <th>In Bearbeitung</th>
                                <th></th>
                            </tr></table></div><div style="overflow-y: scroll; overflow-x: hidden; width:320px; height:357px;">
                            <table class="data_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                            <tr *ngFor="let x of markData">
                                <td *ngIf="x.main!=-1" (click)="markedAction(x,0)" tooltip="Vorgang bearbeiten">
                                    <span *ngIf="x.main==1" style="color: red;">Entwurf: </span>
                                    <span *ngIf="x.main==0" style="color: orange;">Entwurf: </span>
                                    <b>{{x.name}}</b>
                                </td>
                                <td *ngIf="x.main==-1" (click)="markedAction(x,1)" tooltip="Vorgang bearbeiten">
                                    <span><img src="../design/icons/bullet_green.png"> </span>
                                    <b>{{x.name}}</b>
                                </td>
                                <td *ngIf="x.main!=-1" (click)="markedAction(x,1)" style="width:22px;" tooltip="Vorgang anzeigen">
                                    <img *ngIf="x.id>0" src="../design/icons/page_white_go.png" >
                                </td>
                                <td *ngIf="x.main==-1" (click)="deleteMarked(x)" style="width:22px;" tooltip="Vorgang aus Bearbeitung entfernen">
                                    <img src="../design/icons/delete.png">
                                </td>
                            </tr>
                            </table>
                        </div>
                    </td>
                    <td style="border-left: 1px solid gray;width:33%; vertical-align: top;">
                        <div>
                            <table class="data_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                                <tr>
                                    <th>Benachrichtigungen</th>
                                </tr> </table> </div><div style="width:100%; height:357px; overflow-y: scroll; overflow-x: hidden;">
                                <table><tr *ngFor="let x of notification" >
                                    <td (click)="notificationAction(x); deactivateOverlay()"><b>{{x.name}}</b></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <DIV name="bottomOverlayPanel"  (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}"   style=" z-index: 9;">
    </DIV>
</DIV>

<jw-modal id="notificationCenterMessage" class="shadow form glow" style="width:600px !important;">
    <div class="modalHeader" style="width:600px !important;">
        <div><img src="../design/icons/exclamation.png"><span style=""> Erinnerung</span><BR><BR></div>
    </div>

    <div class="modalContent" style="margin: 0;width:600px !important;">
        <table  *ngIf="(data.messages && data.messages.length > 0) || (data.popupMessages && data.popupMessages.length > 0)" class="data_tabel light" style="width: 100%;" cellspacing="0" cellpadding="8">
            <tr *ngFor="let x of data.messages" >
                <td>
                    Termin um <b>{{x.start_time}}</b> am <b>{{x.start_date}}</b><BR>
                    <span *ngIf="x.active==0">
                        <img src="../design/icons/lock.png" style="vertical-align: sub;width:14px;">
                        Der Vorgang zu diesem Termin wurde ist bereits geschlossen!<br>
                    </span>
                    <span  style="width:410px; display: inline-block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-size: 1.2em; font-weight: bold;">{{x.name}}</span>
                </td>
                <td *ngIf="x.cid > 0" style="width:150px; text-align: center;">
                    <select style="width:140px;" (change)="handlePopupEvent(x)" [(ngModel)]="x.nextRemindInSeconds">
                        <option value="-1">Aktion?</option>
                        <option value="0">Nicht mehr Erinnern</option>
                        <option value="999" *ngIf="x.tid > 0">Vorgang öffnen und in 5 Minuten erinnern</option>
                        <option value="888">Kalender öffnen und in 5 Minuten erinnern</option>
                        <option value="300">in 5 Minuten erinnern</option>
                        <option value="900">in 15 Minuten erinnern</option>
                        <option value="1800">in 30 Minuten erinnern</option>
                        <option value="3600">in 1 Stunde erinnern</option>
                        <option value="7200">in 2 Stunde erinnern</option>
                        <option value="14400">in 4 Stunde erinnern</option>
                    </select>
                </td>
            </tr>

            <tr *ngFor="let x of data.popupMessages" >
                <td>
                    <span  style="width:410px; display: inline-block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-size: 1.2em; font-weight: bold;">{{x.name}}</span>
                </td>
                <td *ngIf="x.id > 0" style="width:150px; text-align: center;">
                    <select style="width:140px;" (change)="handleMessagePopupEvent(x)" [(ngModel)]="x.nextRemindInSeconds">
                        <option selected value="-1">Aktion?</option>
                        <option value="888">Benachrichtigung öffnen</option>
                        <option value="900">in 15 Minuten erinnern</option>
                        <option value="7200" *ngIf="x.popup == 3">in 2 Stunden erinnern</option>
                        <option value="14400" *ngIf="x.popup == 3">in 4 Stunden erinnern</option>
                        <option value="28800" *ngIf="x.popup == 3">in 2 Stunden erinnern</option>
                    </select>
                </td>
            </tr>

        </table>
    </div>

    <div class="modalFooter form" style="width:600px !important;">
        <!--<button (click)="message.close('notificationCenterMessage')" style="float: right; margin-top: 3px;">Ok</button>-->
    </div>
</jw-modal>

<jw-modal id="notificationCenterTimeoverMessage" class="shadow form glow" style="width:400px !important;">
    <div class="modalHeader" style="width:100% !important;">
        <div><img src="../design/icons/exclamation.png"><span style=""> Erinnerung</span><BR><BR></div>
    </div>

    <div class="modalContent" style="margin: 30px;width:100% !important;">
        Es befinden sich bereits längere Zeit<BR>
        Vorgänge ohne Zuständigen in einem Team Eingang!
    </div>

    <div class="modalFooter form" style="width:100% !important;">
        <button (click)="message.close('notificationCenterTimeoverMessage')" style="float: right; margin-top: 3px;">Ok</button>
    </div>
</jw-modal>

