<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Dokumente > Eingangsordner
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.meta_fk_documentgroup"
                            id="searchGroup"
                            style="width:150px;"
                            tabindex="2">
                         <option value="-1">Alle Gruppen</option>
                         <option *ngFor="let x of documentGroupDataSource" [value]="x.id">{{x.name}}</option>
                    </select>



                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                    style="margin-top: 3px;">

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.meta_fk_contact_company"
                                id="searchCompany"
                                style="width:150px;"
                                tabindex="7">
                            <option value="">Alle Firmen</option>
                            <option *ngFor="let x of companyDataSource" [value]="x.id">{{x.name}}</option>
                        </select>

                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="folder"></span>
                        </th>
                        <th style="width: 25px;">
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)">&nbsp;{{ x.folder }}</td>
                    </tr>

                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:5px; margin-top:6px;">
                    <span *ngIf="showNewItemForm">Neuen Eingangsordner anlegen</span>
                    <span *ngIf="!showNewItemForm && currentSelected.id > 0">Eingangsordner bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >

                        <tr>
                            <td style="width: 40%">
                                <span class="formCaption">Ordnername *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.folder"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%; margin-bottom: -1px;"
                                       tabindex="101" >
                            </td>
                            <td style="width: 60%">
                                <span class="formCaption">Gruppe *</span><BR>
                                <select id="documentGroup"
                                        [(ngModel)]="currentSelected.meta_fk_documentgroup"
                                        (change)="getTagDatasource()"
                                        style="width:100%;"
                                        tabindex="102">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of documentGroupDataSource" value="{{x.id}}">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td style="width:40%;">
                                <span class="formCaption">Vorbelegung Dateititel</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.meta_title"
                                       id="currentSelectedMetaTitle"
                                       class="cp-textbox"
                                       style="width:100%; margin-bottom: -1px;"
                                       tabindex="103" >
                            </td>
                            <td style="width:60%;">
                                <span class="formCaption">Firma / Ansprechpartner</span>
                                <app-contact-manager
                                        mode="free"
                                        [(company)]="currentSelected.meta_fk_contact_company"
                                        [(contact)]="currentSelected.meta_fk_contact"
                                        style="width: 100%;" >
                                </app-contact-manager>

                            </td>
                        </tr>

                    <tr>
                        <td colspan="4" style="height:33px;" nowrap="nowrap">

                            <span class="formCaption">Tags</span><BR>

                            <span class="tagline">
                                <span *ngFor="let x of tagList" class="form">
                                <button (click)="removeTag(x)">{{x.name}} <img src="../design/icons/delete.png">
                                </button>
                                </span>
                            </span>

                            <select *ngIf="tagList.length<4"
                                    id="folderTag"
                                    [(ngModel)]="folderTag"
                                    (ngModelChange)="setTag()"
                                    style="width: 150px;"
                                    tabindex="106">
                                <option value="-1">Bitte wählen</option>
                                <option *ngFor="let x of documentTagDataSource" value="{{x.id}}">{{x.name}}</option>
                            </select>
                        </td>
                        <td>

                        </td>
                    </tr>
                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 130px; float: right; margin-right: 0;" tabindex="107">
                                    <img src="../design/icons/accept.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>
</context-menu>
