<DIV style="display: inline-block; vertical-align: middle; margin-right: -1px;" id="{{overlayId}}Panel">
<table style="border-collapse: collapse; border: none;margin-top:-1px;">
    <tr>
        <td>
    <input type="text"
           id="{{overlayId}}productGroupSelector"
           name="{{overlayId}}productGroupSelector"
           style="width: 100%"
           (keyup)="changeProductGroupStringManually($event)"
           [(ngModel)]="productGroupString"
           (blur)="blurAction()">
        </td>
        <td>
            <span class="form">
    <button (click)="toggleOverlay()" style="margin-top: 0; margin-left: -1px; width:24px; min-width: 24px;">
        <img src="../../../design/icons/application_side_tree.png">
    </button></span>
        </td>
    </tr>
</table>
    <div id="{{overlayId}}" class="shadow" style=" margin-top: -2px; margin-left: 2px; overflow: auto;width:0px; height:0px; visibility:hidden; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">

        <span app-tree-view [(model)]="modelValue" [data]="dataSource" (callback)="callSearch()" (click)="getProductGroupName()" *ngIf="!showSearch"></span>

        <span *ngIf="showSearch">
        <table class="data_tabel" style="width:100%; font-size: 1.2em" cellpadding="2" cellspacing="0">
            <tr *ngFor="let x of search" (click)="selectProductGroup(x)" [ngClass]="{ true: 'selected' }[x.selected]">
                <td>{{x.name}}</td>
            </tr>
        </table>

    </span>

    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>

</DIV>
