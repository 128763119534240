<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    ERP > Kostenstellen

                    <span *ngIf="searchResultCount>data.length" style="float:right; margin-right:10px;">{{data.length}} von {{searchResultCount}} Einträgen</span>
                    <span *ngIf="searchResultCount<=data.length" style="float:right; margin-right:10px;">{{searchResultCount}} Einträge</span>
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.group"
                            id="searchGroup"
                            style="width:150px;"
                            tabindex="2">
                            <option value="">Alle Gruppen</option>
                            <option *ngFor="let x of dropdownDataGroup" [value]="x.name">{{x.name}}</option>
                        </select>


                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.type"
                            id="searchType"
                            style="width:150px;"
                            tabindex="2">
                            <option value="0">Eingang</option>
                            <option value="1">Ausgang</option>
                        </select>



                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">

                         <select (change)="search(false)"
                                 [(ngModel)]="searchParameter.active"
                                 style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NUMMER" sortColumn="number"></span>
                        </th>

                        <th style="width:200px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="GRUPPE" sortColumn="group"></span>
                        </th>

                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)">&nbsp;<span *ngIf="x.number && x.number > 0">{{x.number}}&nbsp;</span>{{ x.name }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.number }}</td>

                        <td (click)="edit($event,x)" style="width:200px;">{{ x.group }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:5px; margin-top:6px;">
                    <span *ngIf="showNewItemForm">Kostenstelle anlegen</span>
                    <span *ngIf="currentSelected.id > 0">Kostenstelle bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellspacing="0" cellpadding="4" >
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:98%;"
                                       tabindex="101" >
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 70%;">
                                <span class="formCaption">Nummer</span>
                                <input    type="text"
                                          [(ngModel)]="currentSelected.number"
                                          id="currentSelectedNumber"
                                          style="width:99%;"
                                          tabindex="102" >
                            </td>
                            <td style="width: 30%;">
                                <span class="formCaption">Gruppe *</span><br>
                                <span app-dropdown [(model)]="currentSelected.group" dataEndpoint="erp/costcentre/group" tabindex="103" activateTextMode="true"></span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: 6px !important;" tabindex="104">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
