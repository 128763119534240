<ng-container *ngIf="processData && processData.header">
    <div class="card form" style="width: calc(100% - 40px); margin-left: 20px; margin-top: 20px;" >
        <table style="margin-top: 15px; margin-left: 20px; margin-right: 20px; margin-bottom: 15px; width:calc( 100% - 40px );">
            <tr>
                <td>
                    <strong>Betrifft</strong>
                </td>
                <td colspan="2" class="form">
                    {{processData.header.contact_username}} <span *ngIf="processData.header.company_name!=''"><strong>({{processData.header.company_name}})</strong></span>
                    <button (click)="toggleShowInfosInProgress()" *ngIf="showInfosInProgress"
                            style="float: right;min-width: 24px !important; position: relative; top: -18px; left: 10px; border-top: none;" tooltip="Informationen Verbergen"><img
                            src="../design/icons/information.png"></button>
                    <button (click)="toggleShowInfosInProgress()" *ngIf="!showInfosInProgress"
                            style="float: right;min-width: 24px !important; position: relative; top: -18px; left: 10px; border-top: none;" tooltip="Informationen anzeigen"><img
                            src="../design/icons/information_off.png"></button>
                </td>
            </tr>
            <tr>
                <td>
                    <strong *ngIf="processData.header.fk_team_id!=0">Team</strong>
                    <strong *ngIf="processData.header.project!=0">Projekt:</strong>
                    <strong>/Kat./Stat.</strong>
                </td>
                <td colspan="2" >
                    {{processData.header.state}}
                </td>
            </tr>
            <tr>
                <td style="width:140px;"><strong>TID</strong> {{processData.header.tid}}</td>
                <td><strong>Zuständiger</strong> {{processData.header.assigned_username}}</td>
            </tr>
        </table>
    </div>
    <ng-container *ngFor="let currentEntry of processData.data" >
        <div class="card form" style="min-height: 200px; width: calc(100% - 40px); margin-left: 20px; margin-top: 20px;"  *ngIf="showInfosInProgress || currentEntry.information === '0'">
        <table style="width: 100%; border: unset;" cellpadding="0" cellspacing="0" border="0">
            <tr>
                <td>
                    <div class="header">
                    <span class="info">
                    <img src="{{currentEntry.icon}}">&nbsp;{{currentEntry.send_date}}&nbsp;-&nbsp;{{currentEntry.time}}&nbsp;|&nbsp;{{currentEntry.contact_username}}<br></span>
                        <span *ngIf="currentEntry.main!='1' && currentEntry.time_duration != 0" class="title" style="float:left;"><B>{{currentEntry.time_name}}:</B> {{currentEntry.time_value }} {{currentEntry.time_unit}} -&nbsp;</span>
                        <span *ngIf="currentEntry.titel!==''" class="title"> <b>{{currentEntry.titel}}</b></span>
                        <div *ngIf="currentEntry.create_username" style="font-size: 0.8em;margin-bottom: 3px;">Erstellt von: {{currentEntry.create_username}}</div>

                        <span>
                        <span>
                            <span *ngFor="let a of currentEntry.attachemnts; " >
                                <div *ngIf="a.inline==0" class="attachmentBox" style="width:200px;">
                                    <div *ngIf="a.ending=='pdf'" class="attachmentBoxImage" (click)="sendToDMS($event,a)" ><img src="../design/icons/folder_page_white.png"></div>
                                    <div *ngIf="a.icon!='' && a.ending=='pdf' || a.ending=='jpg' || a.ending=='png' || a.ending=='gif' || a.ending=='bmp'" class="attachmentBoxImage" (click)="externViewFullscreen($event,a.path + '/' + a.name, a.name)" ><img src="{{a.icon}}"></div>
                                    <div *ngIf="a.icon=='' && a.ending=='pdf' || a.ending=='jpg' || a.ending=='png' || a.ending=='gif' || a.ending=='bmp'" class="attachmentBoxImage" (click)="externViewFullscreen($event,a.path + '/' + a.name, a.name)" ><img src="../design/icons/page_white.png"></div>
                                    <div *ngIf="a.ending=='pdf' || a.ending=='jpg' || a.ending=='png' || a.ending=='gif' || a.ending=='bmp'" (click)="externViewFullscreen($event,a.path + '/' + a.name, a.name)"  class="attachmentBoxText">{{a.name}} <br> {{a.size}} </div>
                                    <div *ngIf="a.ending!='pdf' || a.ending!='jpg' || a.ending!='png' || a.ending!='gif' || a.ending!='bmp'" class="attachmentBoxImage" (click)="downloadFile(a)"><img src="../design/icons/page_white.png"></div>
                                    <div *ngIf="a.ending!='pdf' || a.ending!='jpg' || a.ending!='png' || a.ending!='gif' || a.ending!='bmp'"  class="attachmentBoxText" (click)="downloadFile(a)">{{a.name}} <br> {{a.size}}</div>
                                </div>
                            </span>
                                <div *ngIf="checkForInlineAttachments(currentEntry.attachemnts)" style="font-size: 12px;" tooltip="Es gibt verborgene Inline Anhänge. Klicken um diese anzuzeigen!">
                                    <div (click)="viewInline($event,currentEntry.attachemnts)" style="cursor: pointer; " >
                                        <img src="../design/icons/information.png" style="vertical-align: text-bottom;"> Verborgene Anhänge. Hier klicken zum Einblenden.
                                    </div>
                                </div>
                            </span>
                        </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="text">
                            <span *ngIf="currentEntry.mail_uid>0" app-resize-iframe [(model)]="currentEntry.text"></span>
                            <span *ngIf="currentEntry.mail_uid==0" class="noMailText" [innerHTML]="currentEntry.text | safe"></span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
</ng-container>