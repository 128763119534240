<DIV style="display: inline-block;vertical-align: top; border: 1px solid darkgray; border-left:unset !important; background-color: #fdf9f9;" id="{{overlayId}}Panel">
<div (click)="toggleOverlay()" class="attachmentMenuButton">
    <img src="../design/icons/download.png" style="margin-top:6px;">
</div>

    <div id="{{overlayId}}" class="shadow" style="overflow: hidden;width:0px; height: fit-content; width: fit-content; visibility:hidden; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999; margin-left:-100px !important;">

         <table class="data_tabel" cellspacing="0" cellpadding="8">
             <tr (click)="downloadFile()" ><td>Anhang herunterladen</td></tr>
             <tr (click)="sendToDMS()" *ngIf="attachment?.ending == 'pdf'" ><td>Anhang ins DMS verschieben</td></tr>
         </table>


    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" style="" >
    </DIV>


    <jw-modal id="attachmentMBoxDoubled" class="jw-modal shadow">
        <div class="modalHeader">
            <div>
                <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
            </div>
        </div>
        <div style="margin:20px; line-height: 1.4;" class="form">
            Dieses Dokument existiert bereits im Dokumentenmanagement.
        </div>
        <div class="modalFooter form" style="text-align: right">
            <button (click)="message.close('attachmentMBoxDoubled');" style="margin-top: 3px;margin-right: 3px;">Schließen</button>
        </div>
    </jw-modal>

    <jw-modal id="attachmentMBoxNoGroup" class="jw-modal shadow">
        <div class="modalHeader">
            <div>
                <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
            </div>
        </div>
        <div style="margin:20px; line-height: 1.4;" class="form">
            Das Dokument kann nicht ins Dokumentenmanagement verschoben werden,<BR>da für Sie keine Hauptgruppe gesetzt ist.<BR><BR>Bitte Kontaktieren Sie Ihren Systemverwalter.
        </div>
        <div class="modalFooter form" style="text-align: right">
            <button (click)="message.close('attachmentMBoxNoGroup');" style="margin-top: 3px;margin-right: 3px;">Schließen</button>
        </div>
    </jw-modal>

    <jw-modal id="attachmentMBoxDone" class="jw-modal shadow">
        <div class="modalHeader">
            <div>
                <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
            </div>
        </div>
        <div style="margin:20px; line-height: 1.4;" class="form">
            Das Dokument wurde in das DMS verschoben.
        </div>
        <div class="modalFooter form" style="text-align: right">
            <button (click)="message.close('attachmentMBoxDone');" style="margin-top: 3px;margin-right: 3px;">Schließen</button>
        </div>
    </jw-modal>
</DIV>

