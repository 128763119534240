<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Zeiterfassungssystem > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="#/time-tracking-error" selected>Abweichungen</option>
                        <option value="#/time-tracking">Erfasste Arbeitszeiten</option>
                        <option value="#/time-tracking-absence">Abwesenheiten</option>
                    </select>
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.fk_core_user"
                            id="searchUser"
                            style="width:150px;"
                            tabindex="4">
                        <option value="-1">Alle Benutzer</option>
                        <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                    </select>

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.processed"
                            style="width:fit-content;">
                        <option value="1">Bearbeitet</option>
                        <option value="0">Unbearbeitet</option>
                        <option value="-1">Alle</option>
                    </select>

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.processedByUser"
                                style="width:fit-content;">
                            <option value="1">Von Benutzer Bearbeitet</option>
                            <option value="0">Von Benutzer Unbearbeitet</option>
                            <option value="-1">Alle</option>
                        </select>

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.fk_time_tracking_error_type"
                            style="width:fit-content;">
                        <option value="-1">Alle</option>
                        <option *ngFor="let x of dropdownErrorType" [value]="x.id">{{x.name}}</option>
                    </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.year"
                                style="width:80px;"
                                tabindex="2">
                            <option value="">Alle Jahre</option>
                            <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="DATUM"  sortColumn="timestamp"></span>
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="UHRZEIT" sortColumn="timestamp"></span>
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="STATUS" sortColumn="fk_time_tracking_state"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="BENUTZER" sortColumn="fk_core_user"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="FEHLERTYP" sortColumn="fk_time_tracking_error_type"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="KORREKTUR" sortColumn="correctionTimestamp"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="BEARBEITET VON" sortColumn="approved_by"></span>
                        </th>
                        <th style="width:70px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="BEWILLIGT" sortColumn="approved"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width:80px;">&nbsp;{{ x.recordDateString }}</td>

                        <td (click)="edit($event,x)" style="width:80px;">&nbsp;{{ x.recordTimeString }}&nbsp;Uhr</td>

                        <td (click)="edit($event,x)" style="width:80px;">&nbsp;{{ x.stateString }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">&nbsp;{{ x.userNameString }}</td>

                        <td (click)="edit($event,x)">&nbsp;{{ x.errorNameString }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">&nbsp;{{ x.correctionTimeString }}&nbsp;Uhr</td>

                        <td (click)="edit($event,x)" style="width:150px;">&nbsp;{{ x.approverNameString }}</td>

                        <td style="width:70px;">
                            <span *ngIf="x.correctionTimestamp > 0">
                            <img *ngIf="x.approved== 1" src="../design/icons/tick.png" style="margin-left: 25px;">
                            <img *ngIf="x.approved== 0" (click)="approve(x, 1)" src="../design/icons/tick_inactive.png"
                                 style="margin-right: 5px; margin-left: 10px;">
                            <img *ngIf="x.approved== 0" (click)="approve(x, -1)" src="../design/icons/cross_black.png">
                            <img *ngIf="x.approved== -1" src="../design/icons/cross.png" style="margin-left: 25px;">
                            </span>
                        </td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="showNewItemForm">Neuen Fehlereintrag anlegen</span>

                    <span *ngIf="currentSelected.id>0">
                        Fehlereintrag bearbeiten
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr *ngIf="!showNewItemForm">
                            <td colspan="4">
                                <table style="width: 100%;" cellpadding="2" cellspacing="0">
                                    <tr>
                                        <td><span class="formCaption">Benutzer</span></td>
                                        <td><span class="formCaption">Uhrzeit</span></td>
                                        <td><span class="formCaption">Datum</span></td>
                                        <td><span class="formCaption">Fehlertyp</span></td>
                                    </tr>
                                    <tr>
                                        <td>{{currentSelected.userString}}</td>
                                        <td>{{currentSelected.recordTimeString}}</td>
                                        <td>{{currentSelected.recordDateString}}</td>
                                        <td>{{currentSelected.errorTypeString}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <span class="formCaption">Fehlerbeschreibung <span [hidden]="!showNewItemForm">*</span></span><br>
                                            {{currentSelected.description}}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr style="height: 50px;">
                            <th colspan="4" *ngIf="!showNewItemForm"><span style="float: bottom;">Gewünschte Korrekturwerte</span></th>
                        </tr>
                        <tr *ngIf="!showNewItemForm">
                            <td colspan="4">
                                <table style="width: 100%;" cellpadding="2" cellspacing="0">
                                    <tr>
                                        <td><span class="formCaption">Benutzer</span></td>
                                        <td><span class="formCaption">Uhrzeit</span></td>
                                        <td><span class="formCaption">Datum</span></td>
                                        <td style="width: 260px;"></td>
                                    </tr>
                                    <tr>
                                        <td>{{currentSelected.userString}}</td>
                                        <td>{{currentSelected.correctionTimeString}}</td>
                                        <td>{{currentSelected.correctionDateString}}</td>
                                        <td style="width: 260px;"></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr *ngIf="!showNewItemForm">
                            <td colspan="4">
                                <span class="formCaption">Begründung</span>
                                <editor [(ngModel)]="currentSelected.statement"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit">
                                </editor>
                            </td>
                        </tr>
                        <tr *ngIf="showNewItemForm">
                            <td>
                                <span class="formCaption">Benutzer *</span>
                                <select [(ngModel)]="currentSelected.fk_core_user"
                                        id="currentSelectedUser"
                                        style="width:100%;"
                                        tabindex="101">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption">Uhrzeit *</span><br>
                                <input type="time"
                                       [(ngModel)]="currentSelected.correctionTimeString"
                                       id="currentSelectedTimeString"
                                       class="cp-textbox"
                                       style="width:120px; font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;"
                                       tabindex="102" >
                            </td>
                            <td>
                                <span class="formCaption">Datum *</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.correctionDateString"
                                      id="currentSelectedTimestamp"
                                      tabindex="103">
                                </span>
                            </td>
                            <td>
                                <span class="formCaption">Fehlertyp *</span>
                                <select [(ngModel)]="currentSelected.fk_time_tracking_error_type"
                                        id="currentSelectedErrorType"
                                        style="width:100%;"
                                        tabindex="104">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownErrorType" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="showNewItemForm">
                            <td colspan="4">
                                <span class="formCaption">Text</span>

                                <editor [(ngModel)]="currentSelected.text"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit">
                                </editor>

                            </td>
                        </tr>
                        <tr *ngIf="!showNewItemForm && currentSelected.correctionTimestamp > 0">
                            <td class="form">
                                <button (click)="approve(currentSelected, 1)" style="width: 100px; float: right; margin-left: 10px;" tabindex="102">
                                    <img src="../design/icons/tick.png"> Bewilligen
                                </button>
                                <button (click)="approve(currentSelected, -1)" style="width: 100px; float: right;" tabindex="101">
                                    <img src="../design/icons/cross.png"> Ablehnen
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="showNewItemForm">
                            <td colspan="4" class="form">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/{{messageBoxImage}}"><span style=""> {{messageBoxTitle}}</span><BR><BR></div>
    </div>
    <div class="modalContent">{{messageBoxContent}}</div>
    <div class="modalFooter form" >
        <button *ngIf="messageBoxImage != 'delete.png'" (click)="closeMessageBox()" style="float: right; margin-top: 3px;">OK</button>
        <button *ngIf="messageBoxImage == 'delete.png'" (click)="delete(itemToDelete)" style="float: right; margin-top: 3px; margin-left: 5px;">
            <img src="../design/icons/delete.png">Löschen</button>
        <button *ngIf="messageBoxImage == 'delete.png'" (click)="closeMessageBox()" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>


<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="openDeleteModal($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item (execute)="approve($event.value, 1)" >
        <span *ngIf="item?.approved == -1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/tick.png" style="vertical-align: middle">&nbsp;&nbsp;Bewilligen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="approve($event.value, -1)" >
        <span *ngIf="item?.approved == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/cross.png" style="vertical-align: middle">&nbsp;&nbsp;Ablehnen
        </span>
    </ng-template>

</context-menu>
