import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-knowhow',
  templateUrl: './knowhow.component.html',
  styleUrls: ['./knowhow.component.css']
})
export class KnowhowComponent implements OnInit {
  public helpTab = 0; // remove if done. Change the View of the information Tab
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public dropdownDataCategory1: any[];
  public dropdownDataCategory2: any[];
  public dropdownCurrentSelectedDataCategory2: any[];
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public fileToUpload: File = null;

  public cmtest = [{mode: 'contact'}, {mode: 'phone'}, {mode: 'email'}, {mode: 'company'}, {mode: 'site'}];
  public testcompany: any;
  public testsite: any;
  public testcontact: any;
  public nameTest = { company: '-',  company_short: '-', site: '-', site_short: '-', contact_salutation: '-', contact_title: '-', contact_first_name: '-', contact_name: '-' , phone: '-', email: '-'  };
  public dropdownSearchParameterStateData: any;
  public dropdownTestValue: any;
  public markSchema: {};
  public showItem: boolean;
  public editorWarningString = '';

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', text : '', fk_knowhow_category1 : '-1', fk_knowhow_category2 : '-1', timestampString : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { company: '', site: '', contact: '', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'create_timestamp DESC', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '', fk_knowhow_category1 : '-1', fk_knowhow_category2 : '-1' };
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Action1'}, { value : '2', name : 'Action2'}];
  }

  constructor(public http: HttpClient, public settings: SettingsService,  public message: ModalService, public router: Router) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<{data,status,count}>(settings.restBaseUrl + 'knowhow/user', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data.data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'knowhow/category', {fk_knowhow_category:'0'}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategory1 = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
    this.getDropdownSearchParameterStateData();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'knowhow', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.initCurrentSelected();
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showItem = false;
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

  save(): void {
    if(this.currentSelected.name ==='' || this.currentSelected.text ==='' || this.currentSelected.category1 ==='-1' || this.currentSelected.category2 ==='-1' ){
      this.editorWarningString = 'Sie müssen alle Felder ausfüllen!';
      this.message.open('editorWarning')
      return;
    }
    this.showNewItemForm = false;
    if( this.currentSelected.public === true ) { this.currentSelected.public = '1'; } else { this.currentSelected.public= '0';}
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'knowhow',
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
        }
    );
  }


  show($event: MouseEvent, item: any) {
    this.showNewItemForm = false;
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'knowhow/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showItem = true;
        });
  }

  edit($event: MouseEvent, item: any): void  {
    this.showItem = false;
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'knowhow/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.dropdownCurrentSelectedDataCategory2 = null;
          this.changeDropdownCurrentSelectedCategory1();
          this.showNewItemForm = true;
          if( this.currentSelected.public === '1' ) { this.currentSelected.public = true; } else { this.currentSelected.public= false;}
        });
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'knowhow/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich wiederhergestellt werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'knowhow/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeDropdownCategory1(){
    this.searchParameter.fk_knowhow_category2 = '-1';
    if(this.searchParameter.fk_knowhow_category1 !== '-1') {
      this.http.post<any[]>(
          this.settings.restBaseUrl + 'knowhow/category' , {fk_knowhow_category1: this.searchParameter.fk_knowhow_category1 }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {

            this.dropdownDataCategory2 = data;
            this.search(false);
          }
      );
    }else{
      this.dropdownDataCategory2 = null
      this.search(false);};
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxShiftStartElement = false;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxSwapAll(): void  {
    for (const item of this.data) {
      item.checked = this.checkboxAllState;
    }
    this.handleCheckboxChanged(false);
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }
    if (this.checkboxShiftStartElement == null) { this.checkboxShiftStartElement = clickedItem;
    } else {
      let direction = 'backwards';

      for (const item of this.data) {
        if (item === clickedItem)                       {direction = 'forward'; }
        if (item === this.checkboxShiftStartElement)    {direction = 'backwards'; }
      }

      if (direction === 'backwards') {
        const temp = this.checkboxShiftStartElement;
        this.checkboxShiftStartElement = clickedItem;
        clickedItem = temp;
      }

      let mark = false;

      for (const item of this.data) {
        if (item === this.checkboxShiftStartElement) {mark = true; }
        if (mark) {item.checked = true; }
        if (item === clickedItem) { mark = false; }
      }

      this.checkboxShiftStartElement = null;
    }
    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.data.filter((item) => item.checked);
  }

  checkboxActionDropdownChanged(): void {
    if (this.checkboxActionDropdown === '1') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action1_Parameter1'},
        {value: '2', name: 'Action1_Parameter2'},
        {value: '3', name: 'Action1_Parameter3'}];
    } else if (this.checkboxActionDropdown === '2') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action2_Parameter1'},
        {value: '2', name: 'Action2_Parameter2'},
        {value: '3', name: 'Action2_Parameter3'}];
    }
  }

  handleCheckboxAction(): void  {
    this.handleCheckboxChanged(false);
    let alertString =   'Checkboxes Action: ' + this.checkboxActionDropdown +
        ' Parameter: ' + this.checkboxActionParameterDropdown +
        ' - Boxes: ';
    for (const item of this.checkboxData) {
      alertString += ' - ' + item.id;
    }
    alert(alertString);
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onNameString(event: any) {
    this.nameTest = event;
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }



  getDropdownSearchParameterStateData() {

    this.markSchema = { field : 'required_to_close_flag', condition : [
        { value: 0 , icon: ''},
        { value: 1 , icon: '../design/icons/tick_inactive.png'},
        { value: 2 , icon: '../design/icons/tick.png'}
      ]};

    this.http.post<{status, count, data}>(
        this.settings.restBaseUrl + 'process/category/state',  { fk_category: this.searchParameter.category , active: 1, tid: '72826823'}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownSearchParameterStateData = data.data;
          this.searchParameter.state_fk_id = '-1';
        });
  }

  updateState() {
    this.getDropdownSearchParameterStateData();
    this.dropdownTestValue = -1;
  }


  changeDropdownCurrentSelectedCategory1() {
    if(this.currentSelected.fk_knowhow_category1 !== '-1') {
      this.http.post<any[]>(
          this.settings.restBaseUrl + 'knowhow/category' , {fk_knowhow_category1: this.currentSelected.fk_knowhow_category1 }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.dropdownCurrentSelectedDataCategory2 = data;
          }
      );
    }else{this.dropdownCurrentSelectedDataCategory2 = null};
  }
}
