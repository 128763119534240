import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ModalService } from 'src/app/_modal';

@Component({
  selector: 'app-attachment-menu',
  templateUrl: './attachment-menu.component.html',
  styleUrls: ['./attachment-menu.component.css']
})
export class AttachmentMenuComponent implements OnInit {

    public overlayId: string;
    public OverlayActive = false;

    @Input() attachment;
    @Input() processData;

    constructor(public message: ModalService, public http: HttpClient, public settings: SettingsService) {
    }

    ngOnInit() {
        const dateNow = new Date();
        this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    }

    downloadFile() {
        this.deactivateOverlay();
        const link = document.createElement('a');
        link.setAttribute('href', this.attachment.path + '/' + this.attachment.name);
        link.setAttribute('download', this.attachment.name);
        document.body.appendChild(link);
        link.click();
    }

    sendToDMS() {
        this.deactivateOverlay();
        this.http.put<any>(
            this.settings.restBaseUrl + 'process/attachment/dms/' + this.processData.header.tid , JSON.stringify(this.attachment), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (data.status === 'error') {
                    if (data.message === '1') {
                        this.message.open('attachmentMBoxDoubled');
                    } else {
                        this.message.open('attachmentMBoxNoGroup');
                    }
                } else {
                    this.message.open('attachmentMBoxDone');
                }
            });
    }

    activateOverlay(): void {
        this.OverlayActive = true;
        document.getElementById(this.overlayId).style.visibility = 'visible';
    }

    deactivateOverlay(): void {
        this.OverlayActive = false;
        setTimeout(() => {
            document.getElementById(this.overlayId).style.visibility = 'hidden';
        }, 100);
    }

    toggleOverlay() {
        if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
    }
}
