import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import {ModalService} from "../../_modal";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-process-merge',
  templateUrl: './process-merge.component.html',
  styleUrls: ['./process-merge.component.css']
})
export class ProcessMergeComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public openWorkdocumentFound = false;
  public list;
  public formData: any;
  public editorWarningString: string;


  initCurrentSelected(): void {
    this.currentSelected = { id : 1, tid: 1, name : '', text : '', category1 : '', category2 : '', timestampString : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { company: '', site: '', contact: '', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '' };
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Action1'}, { value : '2', name : 'Action2'}];
  }

  initFormData(): void {
    this.formData = { titel: '', text: ''};
  }

  loadMergedProgressView(): void {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/merge/result', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data.data;
        }
    );
  }

  constructor(public http: HttpClient, public settings: SettingsService, public router: Router, public route: ActivatedRoute, public message: ModalService, public sanitizer: DomSanitizer) {
    this.initCurrentSelected();
    this.initFormData();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
    this.handleRouteParameter();
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('titleTextField').focus();
  }

  handleRouteParameter() {
    this.route.paramMap.subscribe(params => {
      if ( params.get('list') != '' ) {
        this.list = params.get('list');
      } else {
        this.list = 6;
      }
    });
  }

  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/merge', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
          this.formData.titel = data.data[0].titel;
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  view($event: MouseEvent, item: any): void  {
    this.currentSelected = item;
  }



  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  checkForOpenWorkdocuments() {
    const tidArray = [];
    this.data.forEach((element) => {
      tidArray.push(element.tid);
    });

    this.http.post<any>(
        this.settings.restBaseUrl + 'process/workdocument/exist' , { tids: tidArray }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            if (data.exist === false) { this.startMerge(); } else {
              this.editorWarningString = 'Die Vorgänge können nicht zusammengeführt werden.<BR>Es befinden sich noch offene Arbeitsbelege in den Vorgängen: <BR><BR>' + data.openDocumentProcesses;
              this.message.open('editorWarning');
              return;
            }
        });
  }

  startMerge() {
    this.http.put<any>(
        this.settings.restBaseUrl + 'process/merge/merge/' + this.data[0].tid, JSON.stringify(this.formData), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.router.navigate(['/process/' + this.list]);
        });
  }

  delete($event: MouseEvent, item: any) {
    this.http.delete<any>(
        this.settings.restBaseUrl + 'process/merge/' + item.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          for ( let i = 0; i < this.data.length; i++ ) {
            if ( this.data[i].id === item.id ) {
              this.data.splice( i, 1);
              this.searchResultCount--;
            }
          }
        });
  }

  cancelMerge() {
    this.router.navigate(['/process/' + this.list ]);
  }
}
