<DIV style="display: inline-block; margin-bottom: 1px; margin-right: -1px;" id="{{overlayId}}Panel">

    <img src="../design/icons/attach.png" (click)="toggleOverlay()">

    <div id="{{overlayId}}" class="shadow" style="overflow: hidden;width:0px; height:0px; visibility:hidden; color: #000000; position: fixed; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
        <div style="margin: 10px; margin-bottom: 6px;">
            <div (click)="viewFullscreen($event,a)" class="attachmentBox" *ngFor="let a of attachments" style="width:200px;">
                <div *ngIf="a.icon!=''" class="attachmentBoxImage"><img src="{{a.icon}}"></div>
                <div *ngIf="a.icon==''" class="attachmentBoxImage"><img src="../design/icnos/page_white.png"></div>
                <div class="attachmentBoxText">{{a.name}} <br> {{a.size}} </div></div>
        </div>
    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>
</DIV>
