import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-document-in-folder',
  templateUrl: './document-in-folder.component.html',
  styleUrls: ['./document-in-folder.component.css']
})
export class DocumentInFolderComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataUser;
  public documentGroupDataSource = [];
  public companyDataSource = [];
  public contactDataSource = [];
  public documentTagDataSource = [];
  public httpOptions = { };
  public tagList = [];
  public folderTag = -1;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', folder : '', meta_fk_documentgroup : '-1', meta_fk_contact : '-1', meta_fk_contact_company : '-1', meta_title: '', meta_tag : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', meta_fk_contact : '', meta_fk_contact_company : '', meta_fk_documentgroup : '-1' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();

    this.http.post<any[]>(settings.restBaseUrl + 'user/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data;
        }
    );

    this.http.post<{status, count, data}>(settings.restBaseUrl + 'document/groups/search', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentGroupDataSource = data.data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/company/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyDataSource = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'document/inFolder', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data:{status, count, data}) => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.folder;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.tagList = [];
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.currentSelected.folder == null || this.currentSelected.folder === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt werden.\nBitte geben Sie einen Ordnernamen ein.');
      return;
    } else if (this.currentSelected.meta_fk_documentgroup < 0) {
      alert('Alle Pflichtfelder müssen ausgefüllt werden.\nBitte wählen Sie eine Dokumentengruppe aus.');
      return;
    }
    if (this.currentSelected.meta_fk_contact === '-1') { this.currentSelected.meta_fk_contact = 0; }
    if (this.currentSelected.meta_fk_contact_company === '-1') { this.currentSelected.meta_fk_contact_company = 0; }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }

    let tagIDs = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tagList.length; i++) {
      tagIDs += this.tagList[i].id + ';';
    }
    tagIDs = tagIDs.slice(0, -1);
    const putData = {
      id: this.currentSelected.id,
      folder: this.currentSelected.folder,
      meta_fk_contact_company: this.currentSelected.meta_fk_contact_company,
      meta_fk_contact: this.currentSelected.meta_fk_contact,
      meta_title: this.currentSelected.meta_title,
      meta_fk_documentgroup: this.currentSelected.meta_fk_documentgroup,
      meta_tag: tagIDs
    };

    this.http.put<any[]>(
        this.settings.restBaseUrl + 'document/inFolder' + endpointIdAdd, putData,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.tagList = [];
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'document/inFolder/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
         // @ts-ignore
         for (const element of data.meta_tag) { this.tagList.push(element); }
         this.currentSelected = data;
         this.showNewItemForm = false;
         this.getContacts();
         setTimeout(() => {  this.getTagDatasource();
                             document.getElementById('currentSelectedName').focus();
         }, 300);
    });
  }

  setTag() {
    // tslint:disable-next-line:triple-equals
    if (!this.tagList.some(item => item.id == this.folderTag)) {
      this.http.get<{id, name}>(
          this.settings.restBaseUrl + 'tag/' + this.folderTag,
          this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if (this.folderTag !== -1) {
              const tagToAdd = {
                id: data.id,
                name: data.name
              };
              this.tagList.push(tagToAdd);
              this.folderTag = -1;
            }
          });
    } else {
      alert('Dieser Tag befindet sich bereits in der Liste.');
      this.folderTag = -1;
    }
  }

  removeTag(item) {
    const index = this.tagList.indexOf(item);
    this.tagList.splice(index, 1);
  }

  getContacts() {
    this.contactDataSource = [];
    this.http.post<{count, status, data}>(this.settings.restBaseUrl + 'contact' , {company: this.currentSelected.meta_fk_contact_company}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.contactDataSource = this.contactDataSource.concat(data.data);
        }
    );
  }

  getTagDatasource() {
    this.http.post<{count, status, data}>(this.settings.restBaseUrl + 'tag' , {modul_dependency: this.currentSelected.meta_fk_documentgroup}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.documentTagDataSource = data.data;
        }
    );
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
