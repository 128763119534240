import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-erp-outgoing-bill',
  templateUrl: './erp-outgoing-bill.component.html',
  styleUrls: ['./erp-outgoing-bill.component.css']
})
export class ErpOutgoingBillComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataUser;
  public dropdownDataCategory: any[];
  public httpOptions = { };
  public dropdownDataMailbox;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', mailbox: '-1' };
  }

  initSearchParameter(): void {
    this.searchParameter = { company: '', site: '', contact: '', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'name', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();

    this.http.post<any[]>(settings.restBaseUrl + 'team/mailbox', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataMailbox = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/ownCompanies' , JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data.data;
        }
    );
  }

  save(): void {
    this.http.put<any[]>(this.settings.restBaseUrl + 'template', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search(false);
          this.initCurrentSelected();
        }
    );
  }

  edit(item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'contact/ownCompanies/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          setTimeout(() => {
            document.getElementById('currentSelectedMailbox').focus();
          }, 300);
        });
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

    activate(item) {
        // todo implement
        alert('Nicht implementiert');
    }

    deactivate(item) {
        // todo implement
        alert('Nicht implementiert');
    }

    startTextSearchFromContextmenu(item) {
        // todo implement
        alert('Nicht implementiert');
    }
}
