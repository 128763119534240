        <ul class="tree">
            <ng-template #recursiveList let-dataSource>
                <li *ngFor="let item of dataSource" (click)="handleClickEvent(item)">
                    <span class="row">
                    <span *ngIf="item.nodes !== null && !item.open" (click)="toggle(item)" style="font-weight: bold; margin-left: -12px;">+</span>
                    <span *ngIf="item.nodes !== null && item.open" (click)="toggle(item)" style="font-weight: bold; margin-left: -12px;">-</span>
                    <span (click)="getProductId(item)" style="margin-left: 3px;" [ngClass]="{'selectable':item.selectable==1}">{{item.name}}</span>
                    </span>
                    <ul *ngIf="item.nodes !== null">
                        <ng-container *ngIf="item.open" class="open">
                            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.nodes }"></ng-container>
                        </ng-container>
                    </ul>
                </li>
            </ng-template>

            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: dataSource }"></ng-container>
        </ul>