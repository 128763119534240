import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-printer-statistic',
  templateUrl: './printer-statistic.component.html',
  styleUrls: ['./printer-statistic.component.css']
})
export class PrinterStatisticComponent implements OnInit {
    public rightContainerWidth;
    public leftContainerWidth;
    public httpOptions = {};
    public editorWarningString = '';
    public pageSum;
    public data = [];
    public altripLaserSw = 0;
    public altripLaserFrabe = 0;
    public altripTinte = 0;
    public gartenstadtLaserSW = 0;
    public gartenstadtLaserFarbe = 0;
    public gartenstadtTinte = 0;
    public roemerbergLaserSW = 0;
    public roemerbergLaserFrabe = 0;
    public roemerbergTinte = 0;
    public zentralverwaltungLaserSW = 0;
    public zentralverwaltungLaserFarbe = 0;
    public zentralverwaltungTinte = 0;
    public altripCount = 0;
    public gartenstadtCount = 0;
    public roemerbergCount = 0;
    public zentralverwaltungCount = 0;
    public searchParameter;
    public readonly searchQuantity: number;
    public searchResultCount: number;
    public dropdownPrinterDate;
    showLoading: boolean;



    constructor(public http: HttpClient,
                public settings: SettingsService,
                public message: ModalService,
                public router: Router) {
        this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
        this.initSearchParameter();
        this.searchResultCount = 0;
        this.searchQuantity = 50000;
        this.setRightContainerWidth(40);
        this.search(false);
        this.http.post<any>(this.settings.restBaseUrl + 'hardware/printer/statistic/date', {}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    this.dropdownPrinterDate = data.data;
                }
            ));
    }

    initSearchParameter(): void {
        this.searchParameter = {
            text: '',
            bill_cleared: '0',
            sort: 'id'
        };
    }

    ngOnInit(): void {
    }

    setRightContainerWidth(width): void {
        this.rightContainerWidth = width;
        this.leftContainerWidth = 100 - this.rightContainerWidth;
    }


    goTo(selector) {
        const destination = selector.options[selector.selectedIndex].value;
        this.router.navigate(['/' + destination]);
    }

    billclearExecute() {
        this.message.close('editorQuestion');
        this.http.put<{status, count, data}>(this.settings.restBaseUrl + 'hardware/printer/statistic/billclear', {} , this.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data:{status, count, data}) => {
                this.editorWarningString='Es wurden alle Druckerseiten abgerechnet ';
                this.message.open('editorWarning');
            }
        );
    }

    searchOnReturnPressed(event): void {
        if (event.key === 'Enter') {
            this.search(false);
        }
    }

    search(loadNextSearchResults: boolean) {
        this.altripLaserSw = 0;
        this.altripLaserFrabe = 0;
        this.altripTinte = 0;
        this.gartenstadtLaserSW = 0;
        this.gartenstadtLaserFarbe = 0;
        this.gartenstadtTinte = 0;
        this.roemerbergLaserSW = 0;
        this.roemerbergLaserFrabe = 0;
        this.roemerbergTinte = 0;
        this.zentralverwaltungLaserSW = 0;
        this.zentralverwaltungLaserFarbe = 0;
        this.zentralverwaltungTinte = 0;
        this.altripCount = 0;
        this.gartenstadtCount = 0;
        this.roemerbergCount = 0;
        this.zentralverwaltungCount = 0;
        this.showLoading = true;
        this.pageSum = {};
        this.http.post<any>(this.settings.restBaseUrl + 'hardware/printer/statistic', JSON.stringify(this.searchParameter) , this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                this.data = data.data;
                this.searchResultCount = data.count;
                this.data.forEach((x) => {
                    x.div_page_count = x.div_page_count - 1 + 1;
                    if (x.typename === 'Drucker Laser SW' && x.site_short_name === 'Altrip') {
                        this.altripLaserSw += x.div_page_count;
                        this.altripCount ++ ;
                    }
                    if (x.typename === 'Drucker Laser Farbe' && x.site_short_name === 'Altrip') {
                        this.altripLaserFrabe += x.div_page_count;
                        this.altripCount ++ ;
                    }
                    if (x.typename === 'Drucker Tinte' && x.site_short_name === 'Altrip') {
                        this.altripTinte += x.div_page_count;
                        this.altripCount ++ ;
                    }
                    if (x.typename === 'Drucker Laser SW' && x.site_short_name === 'Gartenstadt') {
                        this.gartenstadtLaserSW += x.div_page_count;
                        this.gartenstadtCount ++;
                    }
                    if (x.typename === 'Drucker Laser Farbe' && x.site_short_name === 'Gartenstadt') {
                        this.gartenstadtLaserFarbe += x.div_page_count;
                        this.gartenstadtCount ++;
                    }
                    if (x.typename === 'Drucker Tinte' && x.site_short_name === 'Gartenstadt') {
                        this.gartenstadtTinte += x.div_page_count;
                        this.gartenstadtCount ++;
                    }
                    if (x.typename === 'Drucker Laser SW' && x.site_short_name === 'Römerberg') {
                        this.roemerbergLaserSW += x.div_page_count;
                        this.roemerbergCount ++;
                    }
                    if (x.typename === 'Drucker Laser Farbe' && x.site_short_name === 'Römerberg') {
                        this.roemerbergLaserFrabe += x.div_page_count;
                        this.roemerbergCount ++;
                    }
                    if (x.typename === 'Drucker Tinte' && x.site_short_name === 'Römerberg') {
                        this.roemerbergTinte += x.div_page_count;
                        this.roemerbergCount ++;
                    }
                    if (x.typename === 'Drucker Laser SW' && x.site_short_name === 'Zentralverwaltung') {
                        this.zentralverwaltungLaserSW += x.div_page_count;
                        this.zentralverwaltungCount ++;
                    }
                    if (x.typename === 'Drucker Laser Farbe' && x.site_short_name === 'Zentralverwaltung') {
                        this.zentralverwaltungLaserFarbe += x.div_page_count;
                        this.zentralverwaltungCount ++;
                    }
                    if (x.typename === 'Drucker Tinte' && x.site_short_name === 'Zentralverwaltung') {
                        this.zentralverwaltungTinte += x.div_page_count;
                        this.zentralverwaltungCount ++;
                    }
                })
                    this.showLoading = false;
                }
            ));
    }
    printerCounter(){

    }
    resetSearch() {
        this.initSearchParameter();
        this.search(false);
    }

    clearBill() {
        this.editorWarningString='Wollen sie wirklich alle offenen Druckseiten abrechenen? ';
        this.message.open('editorQuestion');
    }
}
