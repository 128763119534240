<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>
        <div id="leftContainerDefinition" class="header3Row footer1Row spaceForAdminMenu">
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}}
                    von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    System > Sperrliste

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">



                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>



                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png" style="vertical-align: sub;"> Suchen
                    </button>



                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>


                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState"
                                   id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th *ngIf="tableSettings.sort.state" style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="ID" sortColumn="id"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="Titel" sortColumn="title"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="Firma" sortColumn="company_name"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="Gesperrt von" sortColumn="lock_fk_core_user"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort"
                                  name="Gesperrt am" sortColumn="lock_timestamp"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img
                        src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0">
                    <tr *ngFor="let x of data"
                        id="{{x.id}}"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td *ngIf="tableSettings.sort.state" (click)="edit($event,x)"
                            style="width:80px;">{{ x.id }}</td>

                        <td *ngIf="tableSettings.name.state" (click)="edit($event,x)">{{ x.title }}</td>

                        <td *ngIf="tableSettings.name.state" (click)="edit($event,x)"
                            style="width:150px;">{{ x.company_name }}</td>

                        <td *ngIf="tableSettings.name.state" (click)="edit($event,x)"
                            style="width:150px;">{{ x.user_name }}</td>

                        <td *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)"
                            style="width:150px;">{{ x.lock_timestamp * 1000 | date:"dd.MM.y"  }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>


                    <button *ngIf="checkboxActionDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>


</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="saveOnExit" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
        Es gibt noch ungespeicherte Daten!<BR>
        Sollen diese gesichert werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Weiter
        </button>
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Speichern
        </button>
    </div>
</jw-modal>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="unlock($event.value)">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/lock_open.png" style="vertical-align: middle">&nbsp;&nbsp;Entsperren
        </span>
    </ng-template>


</context-menu>
