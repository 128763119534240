import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';

@Component({
  selector: '[app-sort-switch]',
  templateUrl: './sort-switch.component.html',
  styleUrls: ['./sort-switch.component.css']
})
export class SortSwitchComponent implements OnInit, OnChanges {

  public fullSortString: string;
  public overlayId: string;
  public sortState = 0;

  @Output() onSortChanged = new EventEmitter<boolean>();
  @Input('name') name: string;
  @Input('sortColumn') sortColumn;
  @Input('desc') desc;

  @Output() modelChange: EventEmitter<string>;

  @Input()
  get model() {
    return this.fullSortString;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.fullSortString = value;
    if (this.fullSortString !== '') {
      if (this.fullSortString === this.sortColumn) {
        if ( this.desc === '1') { this.sortState = 2; } else { this.sortState = 1; }
      } else if (this.fullSortString === this.sortColumn + ' DESC') {
        if ( this.desc === '1') { this.sortState = 1; } else { this.sortState = 2; }
      } else { this.sortState = 0; }
    }
  }

  constructor() {
    this.modelChange = new EventEmitter();
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = +dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    if ( this.desc !== '1') { this.desc = 0; }
  }

  changeSort(): void {
    if ( this.sortState === 1 ) {
      this.sortState = 2;
      if ( this.desc === '1') {
        this.fullSortString = this.sortColumn;
      } else {
        this.fullSortString = this.sortColumn + ' DESC';
      }
    } else {
      this.sortState = 1;
      if ( this.desc === '1') {
        this.fullSortString = this.sortColumn + ' DESC';
      } else {
        this.fullSortString = this.sortColumn ;
      }
    }
    this.modelChange.emit(this.fullSortString);
    this.onSortChanged.emit(null);
  }

}
