import {Component, OnInit, HostListener, OnDestroy} from '@angular/core';
import {map, filter, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {SettingsService} from '../../services/settings.service';
import {ModalService} from '../../_modal';
import {TutorialService} from '../../services/tutorial.service';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.css']
})

export class ProcessComponent implements OnInit, OnDestroy {
    public fullscreenProcessView: string;
    public data = [];
    public searchParameter;
    public searchQuantity: number;
    public searchResultCount: number;
    public currentSelected;
    public showNewItemForm: boolean;
    public expandedSearch = true;
    public expandedSearchPreviousPanelHeight = '';
    public rightContainerWidth;
    public leftContainerWidth;
    public dropdownDataUser;
    public dropdownDataCategory: any[];
    public checkboxData: any;
    public checkboxAllState: boolean;
    public checkboxActionDropdown = '-1';
    public checkboxActionDropdownData = [];
    public checkboxActionParameterDropdown = '-1';
    public checkboxActionParameterDropdownData;
    public currentSelectedTab = 1;
    public project = '0';
    public dropdownDataProject = null;
    public list;
    public tableSettings;
    public viewEditor = '';
    public newEntry: any;
    public showInfosInProgress = false;
    public currentSelectedId = 0;
    public iframeFullscreenSource: any;
    public fullscreenFilename = '';
    public showFullscreen = 0;
    public formData: any;
    public toggleTo = 0;
    public toggleCC = 0;
    public toggleBCC = 0;
    public newBCC = {company: 0, site: 0, contact: 0, name: ''};
    public newTo = {company: 0, site: 0, contact: 0, name: '', connectId: ''};
    public newCC = {company: 0, site: 0, contact: 0, name: ''};
    public newGroup = {id: 0, name: ''};
    public showMoreProcessActions = false;
    public showProcessOverview = false;
    public states: any;
    public teams = null;
    public projects: any;
    public categorys: any;
    public teamusers: any;
    public costCentres: any;
    public destroyed = new Subject<any>();
    public messageBox: any;
    public currentSaving = 0;
    public setSelected = '0';
    public bccNameString: any;
    public toNameString: any;
    public toGroupString: any;
    public ccNameString: any;
    public fileCountToUpload: number;
    public quickMark: any;
    public processSortReverse = '0';
    public deleteActionState: number;
    public renameProcessActive: boolean;
    public contactChangeProcess;
    public processStartConditions;
    public deleteProcess = 0;
    public dropdownSearchParameterCategoryData;
    public dropdownSearchParameterStateData;
    public selectedContactManagerMode = 'email';
    public withdrawWorkdocumentSelect;
    public searchTeamDropdownDatasource;
    public cantBillWorktimeMessageString;
    public editorWarningString;
    public backupEditorData;
    public showLoading = false;
    public closeWithWorktimeMessage;
    public openWorktimeAcknowledged = false;
    public showMoreTime = false;
    public saveInterval: number;
    public loadNewInterval: number;
    public preAssignedFormdataTextLength = 0;
    public fileUrl;
    public closingState = 0;
    public searchParameterFilter;
    public searchStarted = false;
    public searchFinished = false;
    public lastAction = '';
    public iframeFullscreenHTML: any;
    public move: any;
    public moveUserDropdownDatasource: any;
    public isCheckedNoMail: boolean;
    public listViewMode = 1;
    public fastNewProcessEditor = false;
    public boardDefinition: any;
    public isCheckedNoContact: any;
    public teamMail: any;
    public entryId;
    public processMainIndex = 0;
    public editorContentChanged = false;
    public intervalTimeEditor = 30000;
    public intervalTimeLoadNew = 12000;
    private doClearEditorData = false;
    public combinedAttachmentSize = 0;
    private maxAttachmentSizeOneFile = 15;
    private maxAttachmentSizeAllFiles = 20;
    public  fileUploading = false;
    private donNotStartSearch = false;
    public lastNewId;
    public allTicketCount;
    public checkboxLastSelectedElement: any;
    private blurTime: Date = null;
    private storedProcessNumber: any;
    private max_last_changed: number;
    private searchRequestSend: boolean = false;
    userProjectList: any;

    @HostListener('window:focus', ['$event'])
    onFocus(_event: FocusEvent): void {
        if(this.blurTime == null) { return; }
        let divMinutes = Math.round((((new Date().getTime() - this.blurTime.getTime()) % 86400000) % 3600000) / 60000);
        if(divMinutes>= 5) { this.blurTime = null; this.search(0); }
    }

    @HostListener('window:blur', ['$event'])
    onBlur(_event: FocusEvent): void {
        if(this.blurTime == null) { this.blurTime = new Date(); }
    }


    @HostListener('document:keydown ', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.renameProcessActive) { return;}
        if (event.altKey === false) {
            if (event.key === 'End') {
                this.view(null, this.data[(this.data.length - 1)]);
                document.getElementById('endOfProcessList').scrollIntoView();
                return;
            } else if (event.key === 'Home') {
                this.view(null, this.data[0]);
                document.getElementById('startOfProcessList').scrollIntoView();
                return;
            } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                if (this.showFullscreen === 0 && this.viewEditor === '' && this.currentSelected.header.tid === '') {
                    this.view(null, this.data[0]);
                    document.getElementById('startOfProcessList').scrollIntoView();
                    return;
                }
                if (this.showFullscreen === 0 && this.viewEditor === '' && this.currentSelected.header.tid !== '') {
                    let last = null;
                    let next = false;
                    for (const t of this.data) {
                        if (next) {
                            this.view(null, t);
                            document.getElementById('scrollTo' + this.currentSelected.header.tid).scrollIntoView({behavior: 'smooth'});
                            return;
                        }
                        if (t.tid === this.currentSelected.header.tid) {
                            if (event.key === 'ArrowUp') {
                                if (last === null) {
                                    break;
                                }
                                this.view(null, last);
                                document.getElementById('scrollTo' + this.currentSelected.header.tid).scrollIntoView({
                                    block: 'end',
                                    behavior: 'smooth'
                                });
                                return;
                            } else if (event.key === 'ArrowDown') {
                                next = true;
                            }
                        }
                        last = t;
                    }
                }
            } else if (event.key === 'Escape') {
                if (this.showFullscreen === 1) {
                    this.closeProcessInFullscreen();
                    return;
                } else if (this.fullscreenFilename !== '') {
                    this.closeFullscreen();
                    return;
                } else if (this.messageBox.action !== '') {
                    this.message.close('messageBox');
                    return;
                } else if (this.viewEditor !== '') {
                    this.message.close('discardMDI');
                    return;
                }
            }
        }
        if (event.altKey === true) {
            if (event.key === 'r') {
                if (this.currentSelected.tid === '') {
                    return;
                }
                for (const t of this.data) {
                    if (t.tid === this.currentSelected.header.tid) {
                        this.renameProcess(t);
                    }
                }
            } else if (event.key === 'ArrowLeft') {
                if (this.renameProcessActive) {
                    return;
                }
                if (this.currentSelected.tid !== '') {
                    this.showProcessInFullscreen();
                    return;
                }
            } else if (event.key === 'ArrowRight') {
                if (this.renameProcessActive) {
                    return;
                }
                if (this.currentSelected.tid === '' || this.showFullscreen === 0) {
                    return;
                }
                this.closeProcessInFullscreen();
                return;
            } else if (event.key === 'c') {
                if (this.currentSelected.tid === '') {
                    return;
                }
                for (const t of this.data) {
                    if (t.tid === this.currentSelected.header.tid) {
                        this.mergeProcess(t, 0);
                    }
                }
            } else if (event.key === 'n') {
                this.handleProcessAction('new', '');
            } else if (event.key === 't') {
                if (this.currentSelected.tid === '') {
                    return;
                }
                for (const t of this.data) {
                    if (t.tid === this.currentSelected.header.tid) {
                        this.startContextmenuAction(t, 'assignToMe');
                    }
                }
            }
        }
    }

    constructor(public router: Router, public http: HttpClient, public settings: SettingsService, public tutorial: TutorialService, public route: ActivatedRoute, public message: ModalService, public sanitizer: DomSanitizer) {
        this.searchQuantity = 200;
        this.searchResultCount = 0;
        this.move = {fk_team_id: '-1', fk_user: '-1', type: '1'};
        this.moveUserDropdownDatasource = [];
        this.data = [];
        this.searchStarted = false;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.fullscreenProcessView = 'inline';
        this.checkboxLastSelectedElement = null;
        this.initCurrentSelected();
        this.initNewEntry();
        this.initFormData();
        this.initNewBCC();
        this.initNewTo();
        this.initNewCC();
        this.initNewGroup();
        this.initMessageBox();
        this.initBoardDefinition();
        this.checkboxAllState = false;
        this.setRightContainerWidth(45);
        this.initBackupEditorData();
        this.toggleTo = 1;
        this.toggleCC = 0;
        this.toggleBCC = 0;
        this.doClearEditorData = false;
        this.max_last_changed = 0;
        this.http.post<any['data']>(settings.restBaseUrl + 'user', {
            active: 1,
            currentCompany: 1,
            team: -1
        }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownDataUser = data.data;
            }
        );

        this.http.post<any[]>(settings.restBaseUrl + 'user/team', {secured:'0'}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                const teamArray = data;
                this.http.post<any[]>(this.settings.restBaseUrl + 'ui/menu/project', {}, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        this.userProjectList = data;
                        this.searchTeamDropdownDatasource = data.concat(teamArray);
                    }
                );
            }
        );

        this.handleRouteParameter();
    }

    ngOnInit() {
        this.updateTeams();
        const data = 'FileDownload Data';
        const blob = new Blob([data], {type: 'application/octet-stream'});
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        // Aktion wenn sich die route ändert. Also die Liste neu geladen wird oder die Liste geändert wird.
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.destroyed)
        ).subscribe(() => {
            clearInterval(this.saveInterval);
            this.message.close('discardMDI');
        });


        clearInterval(this.saveInterval);
        this.editorIntervallSave();
        this.saveInterval = setInterval(() => {
            this.editorIntervallSave();
        }, this.intervalTimeEditor);
        if (this.settings.get('processSortReverse')) {
            this.processSortReverse = this.settings.get('processSortReverse');
        }
        this.initProcessStartConditions();
        this.getDropdownSearchParameterCategoryData();
        if (this.settings.get('processListSortSettings')) {
            this.searchParameter.sort = this.settings.get('processListSortSettings');
        }
        if (this.settings.get('processListMarked')) {
            this.searchParameter.markedFilter = this.settings.get('processListMarked');
        }
        this.initTableSettings();
        this.editorContentChanged = false;
        this.startListUpdateInterval();

    }

    ngOnDestroy(): void {
        clearInterval(this.saveInterval);
        this.destroyed.next(true);
        this.destroyed.complete();
    }

    startListUpdateInterval() {
        if(this.list==3 || this.list==6) {
            this.loadNewInterval = setInterval(() => {
                if(this.viewEditor || ( this.searchParameter.markedFilter && this.list==6) ) { return; } // Wenn ein Vorgang bearbeitet wird oder die Liste auf zum Bearbeiten gefiltert, dann kein Nachladen eines Vorgangs.
                if(this.list==3 || this.list==6) {
                    this.search(2);
                } else {
                    this.max_last_changed=0;
                    clearInterval(this.loadNewInterval);
                }
            }, this.intervalTimeLoadNew);
        } else {
            clearInterval(this.loadNewInterval);
        }
    }

    handleRouteParameter() {
        //this.max_last_changed = 0;
        this.viewEditor = '';
        this.initFormData();
        // Übergabewerte in der route verarbeiten.
        this.editorContentChanged = false;
        this.route.paramMap.subscribe(params => {
            this.initCheckboxActionDropdownData();
            if (params.get('id')) {
                this.searchStarted = false;
                if (params.get('id') === '9') {
                    this.list = 9;
                    this.project = String(params.get('action'));
                    this.initCurrentSelected();
                    this.initSearchParameter();
                } else {
                    if (params.get('action') === 'edit') {
                        this.list = 6;
                        this.initCurrentSelected();
                        this.initSearchParameter();
                        setTimeout(() => {
                            this.edit(params.get('id'));
                        }, 500);

                    } else if (params.get('action') === 'search') {
                        this.list = 3;
                        this.initSearchParameter();
                        this.searchParameter.contact = params.get('id');
                        this.donNotStartSearch = true;
                        setTimeout(() => {
                            this.searchParameter.contact = params.get('id');
                            this.searchParameter.text = this.currentSelected.header.tid
                            this.expandedSearch = true;
                            this.searchParameter.active = -1;
                            this.search(0);
                        }, 1200);
                    }
                    else if (params.get('action') === 'searchTeams') {
                        this.list = 3;
                        this.initSearchParameter();
                        this.donNotStartSearch = true;
                        setTimeout(() => {
                            this.searchParameter.contact = params.get('id');
                            this.searchParameter.text = this.currentSelected.header.tid
                            this.expandedSearch = true;
                            this.searchParameter.fk_team_id = -1;
                            this.searchParameter.active = 1;
                            this.search(0);
                        }, 500);
                    } else if (params.get('action') === 'view') {
                        this.list = params.get('list');
                        this.initCurrentSelected();
                        this.currentSelected.header.tid = params.get('id');
                        this.initSearchParameter();
                        this.view(null, {tid: params.get('id')});
                        if(params.get('active') === '1' && this.data.length < 1) {
                            this.search(0);
                        } else {
                            this.donNotStartSearch = true;
                        }
                        setTimeout(() => {
                            if (params.get('action') === 'view') {

                                if (params.get('active') === '1') {
                                    document.getElementById('scrollTo' + this.currentSelected.header.tid).scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth'
                                    });
                                }
                                if (params.get('active') === '0') {
                                    this.searchParameter.text = this.currentSelected.header.tid
                                    this.expandedSearch = true;
                                    this.searchParameter.active = -1
                                    this.search(0);
                                }
                            }
                        }, 2000);

                    } else {
                        this.viewEditor = '';
                        if (this.list !== 6) {
                            this.list = 6;
                            this.initSearchParameter();
                            this.resetSearch(false);
                        }
                        this.donNotStartSearch = true;
                        this.setSelected = params.get('id');

                        // this.search(0);

                        setTimeout(() => {
                            document.getElementById('processSearchText').focus();
                        }, 200);
                    }
                }

            } else if (params.get('list') === '-1') {
                if (this.list !== 6) {
                    this.list = 6;
                    this.initSearchParameter();
                    this.resetSearch(false);
                }
                setTimeout(() => {
                    this.handleProcessAction('new', '');
                }, 200);
            } else {
                this.list = params.get('list');
                this.clearEditorData();
                this.initCurrentSelected();
                this.initSearchParameter();
                // this.executesearch(0);
                setTimeout(() => {
                    document.getElementById('processSearchText').focus();
                }, 200);
            }
        });
        if(!this.searchParameter) {
            this.initSearchParameter();
        }

        if(this.list === undefined) {
            this.router.navigate(['/process/6']);
        }
        if(this.list == 3 && this.searchParameter.fk_team_id == -1) {
            //Setzen des Standardteams wieder entfernt wegen Einsichts Recht. Hier belassen falls doch wieder gewünscht.
            // todo Entfernen wenn wirklich nicht mehr gebraucht.
            // this.setBaseTeam();
        }

    }

    public setBaseTeam() {
        if(this.settings && this.settings.data && this.settings.data.baseTeam && this.settings.data.baseTeam > 0) {
            this.searchParameter.fk_team_id = this.settings.data.baseTeam;
        } else {
            setTimeout(() => {
                this.setBaseTeam();
            }, 400);
        }

    }

    public updateProject() {
        if (this.dropdownDataProject === null) {
            this.http.post<[]>(this.settings.restBaseUrl + 'ui/menu/project', {}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.dropdownDataProject = data;
                });
        }

        this.updateUserList(0, this.formData.project);
        this.updateCategoriesList(0, this.formData.project);
    }

    public updateTeams() {
        if (this.teams === null) {
            this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'team', {
                active: 1,
                usersTeams: 2
            }, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.teams = data.data;
                    this.checkTeams();
                });
        } else {
            this.checkTeams();
        }
    }

    public checkTeams() {
        let setTeamId = 0;
        if (this.formData.fk_team_id > 0) {
            setTeamId = this.formData.fk_team_id;
            this.formData.fk_team_id = null;
        } else if (this.formData.fk_team_id === undefined) {
            this.formData.fk_team_id = null;
            for (const t of this.teams) {
                // tslint:disable-next-line:triple-equals
                if (t.default_team == '1') {
                    this.formData.fk_team_id = t.id;
                    break;
                }
            }
            if (this.formData.fk_team_id < 1) {
                setTeamId = this.teams[0].id;
            }
        }
        setTimeout(() => {
            this.formData.fk_team_id = setTeamId;
            this.updateUserList(this.formData.fk_team_id, 0);
            this.updateCategoriesList(this.formData.fk_team_id, 0);
        }, 100);
    }


    public getCostCentreNumbers() {
        this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'erp/costcentre', {
            active: 1,
            fk_team_role_bigger: 1,
            sort: 'group'
        }, this.settings.httpOptions)
            .pipe(map(data => data.data)).subscribe(
            data => {
                this.costCentres = data;
                this.formData.costCentre = 0;
            });
    }

    public updateUserList(teamId, projectId) {
        if (projectId > 0) {
            this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'process/project/user', {fk_project: projectId}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.teamusers = data.data;
                    let setUserId = 0;
                    if (this.formData.assigned > -1) {
                        // Prüfen ob der zu setzende User in den neu geladenen Daten enthalten ist
                        for(let i = 0; i < this.teamusers.length; i++) {
                            if(this.formData.assigned == this.teamusers[i].user_id) {
                                setUserId = this.formData.assigned;
                            }
                        }
                    }

                    this.formData.assigned = 0;
                    setTimeout(() => {
                        if (setUserId === undefined) {
                            setUserId = -1;
                        } else {
                            this.formData.assigned = setUserId;
                        }
                    }, 120);
                });
        } else {
            this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'team/user/' + teamId, {
                active: 1,
                fk_team_role_bigger: 1
            }, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.teamusers = data;
                    let setUserId = 0;
                    if (this.formData.assigned > 0) {
                        // Prüfen ob der zu setzende User in den neu geladenen Daten enthalten ist
                        for(let i = 0; i < this.teamusers.length; i++) {
                            if(this.formData.assigned == this.teamusers[i].user_id) {
                                setUserId = this.formData.assigned;
                            }
                        }
                        this.formData.assigned = 0;
                    } else {
                        let lastTeamRole = 0;
                        let HeightestRoleId = 0;
                        for (const t of this.teamusers) {
                            if (t.activeUser === '1') {
                                setUserId = t.user_id;
                                break;
                            }
                            if (lastTeamRole < t.fk_team_role) {
                                lastTeamRole = t.fk_team_role;
                                HeightestRoleId = t.user_id;
                            }
                        }
                        if (setUserId === -1 && HeightestRoleId > 0) {
                            setUserId = HeightestRoleId;
                        }
                    }

                    if(this.formData.editor != 'new') {
                        setUserId = this.currentSelected.header.assigned;
                    }
                    setTimeout(() => {
                        this.formData.assigned = setUserId;
                    }, 100);
                });
        }
    }

    public updateCategoriesList(teamId, projectId) {
        this.categorys = [];
        this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'process/category', {
            fk_project_id: projectId,
            team: teamId,
            active: 1
        }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.categorys = data.data;
                setTimeout(() => {
                    if (this.formData.kategorie === undefined || this.formData.kategorie < 1) {
                        for (const c of this.categorys) {
                            if (c.base_category === '1') {
                                this.formData.kategorie = c.id;
                                break;
                            }
                        }
                    }
                    // this.formData.state_fk_id = undefined;
                    this.updateStateList(this.formData.kategorie);
                }, 200);
            });
    }

    public categoryChanged() {
        this.formData.state_fk_id = 0;
        this.updateStateList(this.formData.kategorie);
    }

    public updateStateList(selectedCategory) {
        this.states = [];
        this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'process/category/state', {
            fk_category: selectedCategory,
            active: 1
        }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.states = data.data;
                setTimeout(() => {
                    if (this.viewEditor === 'closeWithWorktime') {
                        this.formData.state_fk_id = this.closingState;
                    } else {
                        // Es muss bei einer gesetzten ID gerpfüt werden ob es diese in der aktuell geladenen
                        // // Statusliste existiert. Wenn nicht dann auf 0 setzen.
                        if(this.formData.state_fk_id > 0) {
                            let isInList = false;
                            for (const s of this.states) {
                                if (s.id === this.formData.state_fk_id) {
                                    isInList = true;
                                }
                            }
                            if(isInList === false) { this.formData.state_fk_id = 0; }
                        }
                        if (this.formData.editor === 'new' || this.formData.state_fk_id === undefined || this.formData.state_fk_id < 1) {
                            // Startstatus setzen wenn aktueller status 0
                            for (const s of this.states) {
                                if (s.start === '1') {
                                    this.formData.state_fk_id = s.id;
                                    return;
                                }
                            }
                        }
                    }
                }, 200);
            });
    }

    public viewFullscreenFromUiElement($event) {
        const item = $event.value;
        const event = $event.event;
        this.viewFullscreen(event, item.path + '/' + item.name, item.name);
    }

    public viewFullscreen(event, filepath, filename) {
        this.fullscreenFilename = null;
        this.iframeFullscreenHTML = null;
        this.iframeFullscreenSource = null;
        if (event != null && event.ctrlKey) {
            window.open(filepath, '_blank');
        } else {
            this.fullscreenFilename = filename;
            this.iframeFullscreenSource = filepath;
            if (filepath.split('.').pop().toLowerCase() === 'pdf') {
                // PDFs
                this.iframeFullscreenHTML = '<embed src="' + filepath + '" style="height:100%; width:100%;" />';
            } else {
                // Bilder
                this.iframeFullscreenHTML = '<embed src="' + filepath + '" style="width:100%;max-width:100%;max-height:100%; object-fit: contain;border:1px solid black;" />';
            }
            this.message.open('fullscreenReading');
        }
    }

    downloadFile(item) {
        const link = document.createElement('a');
        link.setAttribute('href', item.path + '/' + item.name);
        link.setAttribute('download', item.name);
        document.body.appendChild(link);
        link.click();
    }

    public toggleToValue() {
        this.toggleTo = 0;
        setTimeout(() => {
            this.toggleTo = 1;
        }, 0.5);
    }

    public toggleCCValue() {
        this.toggleCC = 0;
        setTimeout(() => {
            this.toggleCC = 1;
        }, 0.5);
    }

    public toggleBCCValue() {
        this.toggleBCC = 0;
        setTimeout(() => {
            this.toggleBCC = 1;
        }, 0.5);
    }

    closeFullscreen() {
        this.fullscreenFilename = '';
        this.iframeFullscreenSource = '';
        this.message.close('fullscreenReading');
    }

    public contactChanged() {
        if(this.donNotStartSearch === false) {
            this.search(0);
        }
    }

    searchSortChange(loadType: number) {
        this.settings.set('processListSortSettings', this.searchParameter.sort, 0);
        this.search(loadType);
    }

    autoTimeRangeSetOnSpecificSearchParameters() {
        // Wenn Datumssuche bereits gesetzt tuhe nichts
        if(this.searchParameter.dateFrom != '') { return; }
        // Wenn gewisse Parameter gesetzt sind belege die Datumssuche vor.
        if(this.searchParameter.active == 0 || this.searchParameter.active == -1 || this.searchParameter.fk_team_id==-2) {

            const dateToday = new Date();
            let month = dateToday.getMonth() + 1;
            let monthString = month + ''; if (month < 10) { monthString = '0' + monthString; }
            let dayString = dateToday.getDate() + ''; if (dateToday.getDate() < 10) { dayString = '0' + dayString; }
            let endDate = dayString + '.' + monthString + '.' + dateToday.getFullYear();
            const yesterday = new Date();
            yesterday.setDate(dateToday.getDate() - 90);
            month = yesterday.getMonth() + 1;
            monthString = month + ''; if (month < 10) { monthString = '0' + monthString; }
            dayString = yesterday.getDate() + ''; if (yesterday.getDate() < 10) { dayString = '0' + dayString; }
            let startDate = dayString + '.' + monthString + '.' + yesterday.getFullYear();

            this.searchParameter.dateFrom = startDate;
            this.searchParameter.dateTo = endDate;
        }

    }


    public search(loadType: number): void {
        this.autoTimeRangeSetOnSpecificSearchParameters();



        if(loadType==2 && this.list!=6 && this.list!=3) {
            // Nachladen nur in den Listen 6 (Eigene) und 3 (Team)
            return;
        }
        if (this.isSearchActive()) {
            this.searchStarted = true;
            this.searchFinished = false;
        } else {
            this.searchStarted = false;
            this.searchFinished = true;
        }
        this.executeSearch(loadType);
    }

    public deactivateMailView()
    {
        this.searchFinished = false;
    }

    public executeSearch(loadType: number): void {
        this.searchFinished = true;
        if(loadType!=2) {
            setTimeout(() => {
                this.showLoading = true;
            }, 50);
        }

        // this.checkExtendedSearch(); Im Moment nicht genutzt. Immer erweiterte Suche
        // Sortierung bei Liste 1/Zeitleiste nicht änderbar
        if (this.list === '1' && this.searchParameter.sort !== 'send_time DESC') {
            this.showLoading = false;
            this.searchParameter.sort = 'send_time DESC';
            this.editorWarningString = 'In der Zeitleiste ist keine andere Sortierung als nach der Eingangszeit möglich!';
            this.message.open('editorWarning');
            return;
        }

        if (loadType==1) {
            this.searchParameter.loadFrom = this.searchParameter.loadFrom + this.searchQuantity;
        } else if (loadType==2) {
        } else {
            this.searchResultCount = 0;
            this.data = [];
            this.searchParameter.loadFrom = 0;
        }

        this.loadProcessSearchResults(loadType);

    }

    switchToTeams()
    {
        this.router.navigate(['/process/' + this.searchParameter.contact + '/searchTeams']);
    }

    loadProcessSearchResults(loadType): void {
        if(this.searchRequestSend) {
            return;
        }
        let searchParameter;
        // Neue Ergebnisse nachladen
        if(loadType==2 && this.max_last_changed > 0) {
            searchParameter = { max_last_changed: this.max_last_changed, list: this.list };
        } else {
            searchParameter = JSON.stringify(this.searchParameter);
        }
        this.searchRequestSend = true;
        this.http.post<{ status, count, data, message, highestId, allTicketCount, max_last_changed }>(this.settings.restBaseUrl + 'process',searchParameter , this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (data.status === 'error') {
                    setTimeout(() => {
                        this.showLoading = false;
                    }, 50);
                    return;
                }
                if (this.searchResultCount === 0) {
                    this.searchResultCount = data.count;
                }

                if( data.max_last_changed && data.max_last_changed!=false && data.max_last_changed > this.max_last_changed ) {
                    this.max_last_changed = data.max_last_changed;
                }
                if(loadType != 2){
                    this.allTicketCount = data.allTicketCount;
                }

                if(loadType==2) {
                    // Nachladen von Vorgängen mit last_changed > max_last_changed
                    if(data.count > 0) {
                        let reloadAfterUpdate = 0;
                        // Vorhandene Aktualisieren
                        for(let n = 0; n < this.data.length; n++) {
                            for(let i = 0; i < data.count; i++) {
                                if(data.data[i].id == this.data[n].id) {
                                    // Wenn new in Eigener Liste, dann entfernen und neu einfügen
                                    let checkForReload = false;
                                    if(data.data[i].new==1 && this.list==6) {
                                        this.data.splice(n, 1);
                                        data.data[i].updated = 1;
                                        this.data = [data.data[i]].concat(this.data);
                                        checkForReload=true;
                                    } else {
                                        this.data[n] = data.data[i];
                                        data.data[i].updated = 1;
                                        checkForReload=true;
                                    }
                                    if( checkForReload && this.currentSelected && this.currentSelected.header.tid == data.data[i].tid  ) {
                                        // Aktuell ausgewählten Vorgang aktualisieren, wenn sich darin etwas geändert hat.
                                        this.view(null, data.data[i]);
                                        if(data.data[i].new == 1 && this.list==6) {
                                            // Wenn der Eintrag mit new markiert ist, dann einen Warnhinweis ausgeben.
                                            this.editorWarningString = 'Der Vorgang den Sie gerade geöffnet haben enthält neue Einträge<BR> und wurde daher neu geladen!';
                                            this.message.open('editorWarning')
                                        }
                                    }
                                }
                            }
                        }
                        // Restliche am Anfang der Liste voranstellen
                        this.data = data.data.filter((item) => item.updated != 1).concat(this.data);
                        this.searchResultCount = Number(this.searchResultCount) + Number(data.count);
                    }
                } else {
                    // Suchergebnisse an Liste anhängen
                    this.data = this.data.concat(data.data);
                }
                setTimeout(() => {
                    // Projekt String setzen
                    this.showLoading = false;
                    this.searchRequestSend = false;
                    this.donNotStartSearch = false;
                    if (this.setSelected !== '0') {
                        for (const item of this.data) {
                            if (item.tid == this.setSelected) {
                                this.view(null, item);
                                this.setSelected = '0';
                                break;
                            }
                        }
                    }
                }, 50);
            }
        );
    }

    startSearchForContact(element): void {
        this.searchParameter.contact = element.user;
        this.search(0);
    }

    resetSearch(saveText): void {
        this.searchStarted = false;
        let savedText;
        if (saveText !== false) {
            savedText = this.searchParameter.text;
        }
        this.initSearchParameter();
        setTimeout(() => {
            if (saveText !== false) {
                this.searchParameter.text = savedText;
            }
            this.getDropdownSearchParameterCategoryData();
            this.search(0);
        }, 100);

    }

    private checkExtendedSearch() {

        // Wenn ein Parameter in der Erweiterten Suche aktiv, dann diese auch aufklappen.
        if( this.searchParameter.dateFrom !=='' || this.searchParameter.dateTo !=='' || this.searchParameter.year !== '' ||
            // tslint:disable-next-line:triple-equals
            ( this.list != '1' && this.searchParameter.active != '1' ) ||
            // tslint:disable-next-line:triple-equals
            ( this.list == '1' && this.searchParameter.active != '-1') )
        {
            if(this.expandedSearch === false) {
                this.toggleExpandedSearch();
            }
        }
    }

    toggleExpandedSearch(): void {
        // Wird im Moment nicht mehr genutzt. Erweiterte Suche immer eingeblendet.
        let newValue = '106px';
        this.expandedSearch = !this.expandedSearch;
        this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
        if (this.expandedSearchPreviousPanelHeight === '80px') {
            newValue = '106px';
        }
        if (this.expandedSearchPreviousPanelHeight === '106px') {
            newValue = '80px';
        }
        document.getElementById('leftContainerContent').style.top = newValue;
    }

    toggleListViewMode(): void {
        if (this.listViewMode === 1) {
            this.listViewMode = 2;
            this.resetSearch(false);
        } else {
            this.listViewMode = 1;
        }
    }

    toggleFastNewProcessEditor(): void {
        this.fastNewProcessEditor = !this.fastNewProcessEditor;
    }

    doSaveTelephone(chargeTime: boolean) {
        if (chargeTime === false) {
            this.formData.time_duration = 0;
            this.formData.fk_process_billing_type = 0;
        }
        this.message.close('telephoneWithoutWorkingTime');
        this.save(2);
    }
  attachFromDMS(item)
  {
    this.formData.attachmentsExist = true;
    this.save(0);
      setTimeout(() => {
          this.router.navigate(['/document-assign', this.entryId]);
      }, 700);
  }

  public uploadFile($event) {
    this.formData.attachmentsExist = true;
    this.save(0);
    if (this.formData.id === undefined) {
      setTimeout(() => {
        this.uploadFile($event);
      }, 300);
    } else {
      let files: FileList;
      files = $event.srcElement.files;
      this.sendUploadFile(files,$event);
    }
  }

  sendUploadFile(files: FileList,$event) {
    this.fileCountToUpload = Array.from(files).length;

    Array.from(files).forEach(file => {
      const uploadData = new FormData();
      uploadData.append('files', file);
      this.http.post<any['id']>( this.settings.restBaseUrl + 'file/' + this.formData.tid + '/' + this.formData.id , uploadData, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.fileCountToUpload--;
            this.formData.attachment.push({ id: data.id, name: file.name, forward: 0 });
            // @ts-ignore
            document.getElementById('inputId').value = '';
          }
      );
    });
    $event.target.value = null
  }

    checkCharacters() {
        if(this.formData.text.replace('&nbsp;', '').length >= 8) {
            this.save(1);
        } else {
            this.editorWarningString = 'Bitte schreiben Sie eine Nachricht für den Empfänger des Tickets mit mindestens 10 Zeichen.!';
            this.message.open('editorWarning');

        }
    }

    public editorIntervallSave() {
        if(this.viewEditor === 'edit' && this.formData.mdi === 0)
            return; // Im Edit Modus kein Interwall Speichern.
        if (!this.formData.noMail) {
            this.selectedContactManagerMode = 'email';
        }
        if (this.formData.noMail) {
            this.selectedContactManagerMode = '';
        }
        // Nur ausführen wenn Editor auch aktiv
        if (!this.viewEditor) {
            clearInterval(this.saveInterval);
            return;
        }
        // Es muss mindestens der Text gesetzt sein
        if (this.formData.text === '') {
            return;
        }
        // Nur wenn der Text verändert wurde, oder es ein Editor mit Zeiterfassung ist.
        if (this.editorContentChanged === false && this.preAssignedFormdataTextLength === this.formData.text.length && this.viewEditor !== 'time' && this.viewEditor !== 'call') {
            return;
        } // Der TinyMCE-Editor muss mindestens eine Eingabe haben.
        this.save(0);
    }

    public save(finalize: any) {
        // Save Button mehrfaches klicken verhindern. Auser finalize > 1. Reroute aus Dialog
        if(this.formData.clicked == 1 && finalize == 1) { return; } else { this.formData.clicked = 1; }

        // Prüfen, ob bereits durch den Interval gespeichert wird, dann warten und in 200 ms erneut versuchen.
        if (this.currentSaving === 1 && finalize === 1) {
            clearInterval(this.saveInterval);
            setTimeout(() => {
                this.save(1);
            }, 200);
            return;
        }

        if(this.viewEditor === 'comment' && finalize !== 0 && this.formData.assigned == 0 && finalize !== 7) {
                this.message.open('assignedNotChanged');
                return;
        }

        if(finalize === 7)
        {
            finalize = 1;
        }

        // Wenn im Zuständige, Projekt oder Team Ändern Modus, dann prüfen, ob sich mindestens eins der drei Felder geändert hat.
        if(this.viewEditor === 'changeMainData' && finalize !== 0) {
            if( this.formData.pre_team_id == this.formData.fk_team_id && this.formData.pre_project == this.formData.project && this.formData.pre_assigned == this.formData.assigned ) {
                this.editorWarningString = 'Sie müssen mindestens den Zuständigen, das Team oder das Projekt ändern! ';
                this.message.open('editorWarning');
                return;
            }
        }

        // Finalize
        // 0 Interval Save
        // 1 Final Save mit E-Mail
        // 2 Save zurück vom Dialog, ob nach einem Telefonat die Arbeitszeit abgerechnet wird
        // 3 Save zurück von Dialog zur Bestätigung eines Fremdteam Eintrags
        let clearCurrentSelected = false;
        // Wenn Team dann auf Fremdteam prüfen, und wenn nötig Warnung öffnen.
        if(finalize == 1 && this.viewEditor === 'changeMainData' && this.formData.fk_team_id > 0 ) {
            for(let i=0; i<this.teams.length;i++) {
                // finde Eintarg zu gewähltem Team
                if(this.teams[i].id == this.formData.fk_team_id) {
                    if(this.teams[i].fk_userid < 1) {
                        // Es ist Fremdteam. Dialog öffnen und save beenden
                        this.message.open('changeToForeignTeam');
                        return;
                    }
                }
            }
        } else if(finalize == 3) {
            // Finalize 4 kommt vom Dialog und sagt save trotz anderem Team. Dialog zu finalize auf 1
            this.message.close('changeToForeignTeam');
            console.log('clear 1 changeToForeignTeam');
            clearCurrentSelected = true;
            finalize = 1;
        }

        // Wenn der Vorgang jemandem anderen zugewiesen wird, dann auch entladen und entfernen.
        if (finalize == 1 && this.list == 6 && this.formData.editor != 'edit' && this.formData.assigned != this.settings.data.userId) {
            clearCurrentSelected = true; console.log('clear 2');
        }

        // Anhänge prüfen, wenn eine mail und finalize = 1
        if(finalize !== 0 && this.combinedAttachmentSize > this.maxAttachmentSizeAllFiles ) {
            finalize = 0;
            this.editorWarningString = 'Ihre Anhänge überschreiten zusammen die maximale Dateigröße von <B>' + this.maxAttachmentSizeAllFiles + ' MB</B>!<BR>Entfernen Sie Dateien bis die Gesamtgröße unter <b>' + this.maxAttachmentSizeAllFiles + ' MB</b> liegt. ';
            this.message.open('editorWarning');
        }

        this.lastAction = '';
        let closeWithWorktime = false;
        // Projekt oder Team Sicherheitsabfrage damit Vorgänge nicht verschwinden
        if (this.formData.fk_team_id < 1 && this.formData.project < 1) {
            if (finalize === 1) {
                this.editorWarningString = 'Sie müssen entweder ein Team oder ein Projekt auswählen!';
                this.message.open('editorWarning');
            }
            return;
        }
        if (this.viewEditor === 'comment') {
            // tslint:disable-next-line:triple-equals
            this.states.forEach((element) => {
                if (this.formData.state_fk_id === element.id && element.autoclosed === 1 && this.currentSelected.worktime.length > 0) {
                    this.backupEditorData = this.formData;
                    this.backupEditorData.viewEditor = this.viewEditor;
                    closeWithWorktime = true;
                    this.closingState = element.id;
                }
            });
        }

        setTimeout(() => {
            if (closeWithWorktime && finalize === 1 && !this.openWorktimeAcknowledged) {
                this.closeWithWorktimeMessage = 'Sie sind im Begriff einen Vorgang mit nicht abgerechneter Arbeitszeit zu schließen. Sind Sie sich sicher ?';
                this.message.open('closeWithWorktimeWarning');
                return;
            } else {
                if (closeWithWorktime) {
                    return;
                }

                if (finalize === 1 && this.viewEditor === 'new' && this.formData.noContact != true && ( this.formData.contact == undefined || this.formData.contact == 0 ) ){
                    this.editorWarningString = 'Sie haben keinen Betreffenden gewählt! Wenn Sie einen Vorgang ohne Betreffenden erstellen wollen, dann setzen Sie den Haken kein Betreffender.';
                    this.message.open('editorWarning');
                    return;
                }

                if (finalize === 1 && this.viewEditor === 'new' && this.formData.noMail != true ){
                    // NoMail ist inaktiv und es wurde keine Mail-Adresse eingetragen.
                    if(this.formData.to.length<1 && this.formData.cc.length<1 &&this.formData.bcc.length<1) {
                        this.editorWarningString = 'Sie müssen mindestens eine E-Mail Adresse angeben <BR>oder die Option keine E-Mail aktivieren.';
                        this.message.open('editorWarning');
                        return;
                    }
                }

                if (finalize === 1 && ( this.viewEditor === 'reply' || this.viewEditor === 'email' || this.viewEditor === 'forward' || this.viewEditor === 'reply-item') && (this.formData.to.length < 1 &&this.formData.cc.length < 1 &&this.formData.bcc.length < 1 )) {

                    this.editorWarningString = 'Sie müssen mindestens eine E-Mail Adresse im Feld AN, CC oder BCC angeben.';
                    this.message.open('editorWarning');
                    return;
                }

                if (finalize === 1 && this.formData.noMail!=true && ((this.viewEditor === 'new' && this.formData.noContact !== true ) || this.viewEditor === 'reply' || this.viewEditor === 'email' || this.viewEditor === 'forward' || this.viewEditor === 'reply-item') ) {
                    for(let i=0;i<this.teams.length;i++) {
                        if(this.teams[i].id == this.formData.fk_team_id) {
                            if(this.teams[i].email==null || this.teams[i].email == '') {
                                this.editorWarningString = 'Sie versuchen eine E-Mail über ein Team zu senden, welches kein Postfach hinterlegt hat!<BR><BR>- Wenn Sie eine E-Mail versenden wollen, dann wählen Sie ein Team mit einem Postfach.<BR>- Wenn Sie nur einen Vorgang ohne einen Kontakt öffnen wollen, dann setzen sie den Haken oben rechts.<BR><B>Vorgang öffnen aber keine E-Mail senden.</B>';
                                this.message.open('editorWarning');
                                return;
                            }
                        }
                    }
                }
                if (this.processStartConditions.fk_team_id !== this.formData.fk_team_id) {
                    this.formData.start_fk_team_id_value = this.processStartConditions.fk_team_id;
                }
                if (this.processStartConditions.project !== this.formData.project) {
                    this.formData.start_project_value = this.processStartConditions.project;
                }
                if (this.processStartConditions.kategorie !== this.formData.kategorie) {
                    this.formData.start_kategorie_value = this.processStartConditions.kategorie;
                }
                if (this.processStartConditions.state_fk_id !== this.formData.state_fk_id) {
                    this.formData.start_state_fk_id_value = this.processStartConditions.state_fk_id;
                }
                if (this.processStartConditions.assigned !== this.formData.assigned) {
                    this.formData.start_assigned_value = this.processStartConditions.assigned;
                }

                if (finalize !== 1 && this.viewEditor === 'closeWithWorktime') {
                    return;
                }
                if (this.formData.text === undefined) {
                    this.formData.text = '';
                }
                if (finalize === 0 && this.preAssignedFormdataTextLength === this.formData.text.length && this.formData.attachmentsExist !== true && this.editorContentChanged === false) {
                    return;
                }
                if (finalize === 1 && this.viewEditor === 'closeWithWorktime' && this.formData.text.length < 1) {
                    this.editorWarningString = 'Sie müssen einen Grund für die Schließung des Vorgangs mit nicht abgerechneten Arbeitszeiten angeben.';
                    this.message.open('editorWarning');
                    return;
                }
                if (finalize === 1 && this.formData.titel.length < 1 && this.viewEditor === 'new' || finalize === 1 && this.formData.titel.length < 1 && this.viewEditor === 'email' || finalize === 1 && this.formData.titel.length < 1 && this.viewEditor === 'reply' || finalize === 1 && this.formData.titel.length < 1 && this.viewEditor === 'forward') {
                    this.editorWarningString = 'Alle Pflichtfelder müssen ausgefüllt sein. Geben Sie einen Titel an';
                    this.message.open('editorWarning');
                    return;
                }
                if (finalize === 1 && this.formData.editor === 'call' && this.formData.time_duration > 0) {
                    this.message.open('telephoneWithoutWorkingTime');
                    return;
                }
                if (this.formData.editor !== 'edit' && finalize === 1 && this.formData.fk_team_id < 1 && this.formData.project < 1) {
                    this.editorWarningString = 'Alle Pflichtfelder müssen ausgefüllt sein. Wählen Sie ein Team oder Projekt um fortzufahren.';
                    this.message.open('editorWarning');
                    return;
                }
                if ( (this.formData.editor !== 'edit' && finalize === 1 ) && (this.formData.assigned === '' || this.formData.assigned == null) ) {
                    this.editorWarningString = 'Alle Pflichtfelder müssen ausgefüllt sein. Wählen Sie eine Option für den Zuständigen Mitarbeiter.';
                    this.message.open('editorWarning');
                    return;
                }
                if (this.formData.editor !== 'edit' && finalize === 1 && this.formData.kategorie < 1) {
                    this.editorWarningString = 'Alle Pflichtfelder müssen ausgefüllt sein. Wählen Sie eine Kategorie.';
                    this.message.open('editorWarning');
                    return;
                }
                if (this.formData.editor !== 'edit' && finalize === 1 && this.formData.state_fk_id < 1) {
                    this.editorWarningString = 'Alle Pflichtfelder müssen ausgefüllt sein. Wählen Sie einen Status.';
                    this.message.open('editorWarning');
                    return;
                }
                if (finalize === 2) {
                    finalize = 1;
                }

                let errorText = '';
                if (this.formData.text === '') {
                    errorText += 'Nachrichte oder Beschreibungs Text\n';
                }

                if (errorText !== '' && finalize === 1) {
                    this.editorWarningString = 'Sie müssen folgende Felder ausfüllen: \n\n' + errorText;
                    this.message.open('editorWarning');
                    return;
                }

                if (this.currentSaving > 0) {
                    return;
                }

                if(finalize === 1) {
                    this.currentSaving = 2;
                } else {
                    this.currentSaving = 1;
                }

                this.formData.finalize = finalize;
                if (finalize === 1) {
                    this.viewEditor = '';
                }

                if (this.currentSelected.header.tid !== '') {
                    this.formData.unbilledWorktime = this.currentSelected.worktime.length;
                }

                const editorType = this.formData.editor;

                this.http.put<{ status, message, id, tid, attachment,mdi,hint, justClosed, category_name, state_name, progress, update, finalize }>(
                    this.settings.restBaseUrl + 'process',
                    JSON.stringify(this.formData),
                    this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data2 => {

                        if(data2.hint !==undefined && data2.hint !=='') {
                            this.editorWarningString = data2.hint;
                            this.message.open('editorWarning');
                        }

                        this.currentSaving = 0;
                        this.formData.clicked = 0
                        if(data2.mdi > 0) {
                            this.formData.mdi = data2.mdi;
                        } else { this.formData.mdi = 0; }
                        if (data2.id > 0 && this.formData.finalize === 0) {
                            this.entryId = data2.id;
                            this.formData.id = data2.id;
                            this.formData.tid = data2.tid;
                            if (data2.attachment !== null && Array.isArray(data2.attachment) && data2.attachment.length > 0) {
                                this.formData.attachment = data2.attachment;
                            }
                        }
                        if (this.formData.finalize === 1 && ( data2.mdi == 0 || data2.mdi==undefined ) ) {

                            // Wenn Edit Url dann reload und nicht update
                            if(this.router.url.includes("edit")) {
                                this.router.navigate(['/process/' + this.list]);
                                return;
                            }
                            console.log('justClosed check');
                            // Vorgang wurde geschlossen oder in Fremdteam verschoben. Daher entladen.
                            if(data2.justClosed === true || clearCurrentSelected == true) {
                                console.log('Close: ' + data2.justClosed + ' - ' + clearCurrentSelected);
                                this.updateListData(this.currentSelected.header.id,true);
                                this.initCurrentSelected();
                                this.doClearEditorData = false;
                                this.clearEditorData();
                                return;
                            } else {
                                console.log('reload stuff' + data2.tid);
                                this.setSelected = data2.tid;
                                setTimeout(() => {
                                    this.search(2);
                                }, 300);

                            }
                        }
                        setTimeout(() => {
                            this.openWorktimeAcknowledged = false;
                        }, 300);
                        if (this.doClearEditorData === true) {
                            this.doClearEditorData = false;
                            this.clearEditorData();
                            setTimeout(() => {
                                this.search(0);
                            }, 400);

                            this.view(null, this.currentSelected);
                        }
                    }
                );
            }
        }, 200);
    }


    updateListData(id, remove) {
        if(id=='') { return }
        // Aktualisiert den Eintrag in der Liste mit der ID
        // Wenn remove, dann ID entfernen
        if( remove == true ) {
            for(let i=0; i< this.data.length;i++) {
                if(this.data[i].id == id) {
                    this.data.splice(i,1);
                    this.searchResultCount--;
                    if(this.data[i]!=undefined) {
                        // Wenn weiteres element dann dieses laden
                        this.view(null, this.data[i]);
                    } break;
                }
            }
        }
    }



    openWorkingDocument(id, tid, documentType, action) {
        // Action 1 print , 2 download
        // tslint:disable-next-line:triple-equals
        if(action==2) {
            window.open('../pdf_output/pdf_fill.php?id=' + id, '_blank').focus();
        } else {
            if (documentType == 8) {
                documentType = 1;
            } else {
                documentType = 0;
            }
            const filename = 'Drucken';
            const filepath = '../ticket/processDocument.php?session_id=' + this.settings.sessionId + '&id=' + tid + '&aussenid=' + id + '&extern=' + documentType;

            this.http.get(filepath, {responseType: 'blob'})
                .subscribe((response: Blob) => {
                    const file = response;
                    const fileURL = (window.URL || window.webkitURL).createObjectURL(file);
                    const viewURL = '../pdfviewer/print.html?file=' + fileURL;
                    this.viewFullscreen(null, viewURL, filename);
                });
        }

    }


    openWithdrawWorkDocumentEditor(item) {
        this.initWithdrawWorkdocumentSelect();
        for (let i = 0; i < this.currentSelected.data.length; i++) {
            if (this.currentSelected.data[i].id === item.id) {
                if (this.processSortReverse === '0') {
                    this.withdrawWorkdocumentSelect.titel = this.currentSelected.data[i - 1].titel;
                }
                if (this.processSortReverse === '1') {
                    this.withdrawWorkdocumentSelect.titel = this.currentSelected.data[i + 1].titel;
                }
            }
        }
        this.withdrawWorkdocumentSelect.id = item.id;
        this.withdrawWorkdocumentSelect.tid = item.tid;
        this.viewEditor = 'withdrawWorkdocument';
    }

    withdrawWorkdocument() {
        if (this.withdrawWorkdocumentSelect.text === '') {
            this.editorWarningString = 'Für den Widerruf eines Arbeitsbeleges muss eine Begründung angegeben werden.';
            this.message.open('editorWarning');
            return;
        }
        const withdrawData = {
            tid: this.withdrawWorkdocumentSelect.tid,
            title: this.withdrawWorkdocumentSelect.titel,
            text: this.withdrawWorkdocumentSelect.text
        };
        this.http.put<any[]>(this.settings.restBaseUrl + 'process/workdocument/withdraw/' + this.withdrawWorkdocumentSelect.id, withdrawData, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.view(null, this.currentSelected);
                this.viewEditor = '';
            });
    }


    setProcessTeamOrProjectRight() {
        this.currentSelected.processRight = false;
        if(this.list==6 || this.list==9) {
            // in der Eigene Liste und der Projekt-liste kann man alle Vorgänge bearbeiten.
            this.currentSelected.processRight = true;
            return;
        }
        if(this.currentSelected.header.assigned == this.settings.data.userId) {
            // Eigene kann man auch immer bearbeiten
            this.currentSelected.processRight = true;
            return;
        }

        // Sonst Team oder Projekt Rolle ermitteln
        for(let i=0;i<this.teams.length;i++) {
            if(this.teams[i].id == this.currentSelected.header.fk_team_id) {
                //Wenn Rolle > 3 (nicht Einsicht oder Standard) dann true. Eingeschränkt wurde mit Test vorher bereits ausgeschlossen
                if(this.teams[i].fk_team_role > 3) {
                    this.currentSelected.processRight = true;
                    this.currentSelected.processRightRole = this.teams[i].fk_team_role;
                    return;
                } else {
                    this.currentSelected.processRight = false;
                    this.currentSelected.processRightRole = this.teams[i].fk_team_role;
                    return;
                }
            }
        }

    }

    view($event: MouseEvent, item: any): void {
        this.initBackupEditorData();
        this.showMoreTime = false;
        if ($event != null) {
            if ($event.shiftKey) {
                let toState = true;
                if ($event.ctrlKey) {
                    toState = false;
                } // wenn shift und strg zusammen dann entmarkieren
                if (this.currentSelectedId < 1) {
                    return;
                }
                this.checkboxRangeSelection(item);
                window.getSelection().removeAllRanges();
                return;
            }
            if ($event.ctrlKey) {
                item.checked = !item.checked;
                this.checkboxLastSelectedElement = item;
                return;
            }
        }

        this.currentSelected.header.tid = ' ';
        this.initFormData();
        if( item.header !== undefined ) {
            item = item.header
        }
        this.http.get<{ contact, header: { tid: '', state: '', contact, categoryStateChangeRight: boolean, groupAssignedChangeRight: boolean }, data: [{ id: '', typ: string, last_changed: '', user: '', main: string }] }>(
            this.settings.restBaseUrl + 'process/' + this.list + '/' + item.tid,
            this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (data.data && data.data.length > 0) {
                    let lastChangeMissing = false;
                    let eMailCount = 0;
                    const nonInfoEntryArray = [];
                    data.contact = data.header.contact;
                    data.data.forEach((element) => {
                        if (element.typ === '28' || element.typ === '1') {
                            eMailCount++;
                        }
                        if (element.last_changed !== '' && element.last_changed != null) {
                            lastChangeMissing = true;
                        }
                        if (!this.showInfosInProgress) {
                            if (element.typ !== '11') {
                                nonInfoEntryArray.push(element);
                            }
                        }
                    });
                    this.processMainIndex = 0
                    let mainEntryIndex = 0;  // Main ist nicht zwangsläufig der Erten Eintrag.... Nach Zusammenführen
                    if (this.processSortReverse === '1') {
                        for (let i = 0; i < data.data.length; i++) {
                            if (data.data[i].main === '1') {
                                mainEntryIndex = i;
                                this.processMainIndex = i
                            }
                        }
                    }

                    this.currentSelected = data;

                    // <A mit Targt anreichern
                    this.currentSelected.data.forEach(currentItem => {
                        currentItem.text = currentItem.text.replaceAll('<A ', '<A target="_blank" ');
                        currentItem.text = currentItem.text.replaceAll('<a ', '<a target="_blank" ');
                    });
                    if( this.currentSelected.header.id == undefined ) {
                        this.currentSelected.header.id = item.id;
                    }
                    this.formData = data.data[mainEntryIndex];

                    this.currentSelectedId = this.currentSelected.data[mainEntryIndex].id;
                    let lastChangedEntry;
                    if (this.showInfosInProgress) {
                        if (!lastChangeMissing) {
                            lastChangedEntry = this.currentSelected.data.reduce((p, c) => p.last_changed > c.last_changed ? p : c);
                        } else {
                            lastChangedEntry = this.currentSelected.data.reduce((p, c) => p.send_time > c.send_time ? p : c);
                        }
                    } else {
                        if (!lastChangeMissing) {
                            lastChangedEntry = nonInfoEntryArray.reduce((p, c) => p.last_changed > c.last_changed ? p : c);
                        } else {
                            lastChangedEntry = nonInfoEntryArray.reduce((p, c) => p.send_time > c.send_time ? p : c);
                        }
                    }
                    this.setProcessTeamOrProjectRight();
                    if (this.currentSelected.data[mainEntryIndex].fk_team_id === '0') {
                        this.formData.type = 'project';
                        this.updateProject();
                    } else {
                        this.updateTeams();
                    }
                    if (this.processSortReverse === '1') {
                        this.resortProcessItems();
                    }

                    setTimeout(() => {
                        const scrolledToEntry = document.getElementById('processEntry' + lastChangedEntry.id);
                        if(scrolledToEntry) { scrolledToEntry.scrollIntoView({block: 'start'}); }
                    }, eMailCount * 150);

                    const selectedId = this.currentSelectedId;
                    setTimeout(() => {
                        // discard the new mark in the searchlist.
                        this.unsetNewOnProcess(item);
                    }, 3000);

                    // Wenn letzter Eintrag in der Lite geladen wird und es mehr auf dem Server gibt, dann automatisch nachladen.
                    if(this.data && this.data[this.data.length-1].id == item.id && this.searchResultCount > this.data.length) {
                        setTimeout(() => {
                            this.search(1);
                        }, 500);

                    }
                } else {
                    this.currentSelected = null;
                    this.currentSelectedId = 0;
                }
            });
        this.quickMark = 0;
        setTimeout(() => {
            if (item.main === '0') {
                this.showProcessEntry(item);
            }
        }, 1000);
    }

    deactivate(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'template/active/' + item.id + '/0', {}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    setTimeout(() => {
                        this.search(0);
                    }, 300);
                    this.initCurrentSelected();
                    this.showNewItemForm = false;
                });
        }
    }

    activate(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'template/active/' + item.id + '/1', {}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    setTimeout(() => {
                        this.search(0);
                    }, 300);
                    this.initCurrentSelected();
                    this.showNewItemForm = false;
                });
        }
    }

    changeSort(itemId, sortAction): void {
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                setTimeout(() => {
                    this.search(0);
                }, 300);
                this.initCurrentSelected();
                this.showNewItemForm = false;
            });
    }

    checkboxSwap($event, item) {
        if ($event.shiftKey) {
            this.checkboxRangeSelection(item);
            if ($event.target.type === 'checkbox') {item.checked = false; }
            return;
        }

        this.checkboxLastSelectedElement = item;

        if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
        this.handleCheckboxChanged(true);
        if ($event.target.type !== 'checkbox') { return false; }
    }

    checkboxSwapAll(): void {
        for (const item of this.data) {
            item.checked = this.checkboxAllState;
        }
        this.handleCheckboxChanged(false);
    }

    checkboxRangeSelection(clickedItem): void  {
        if (this.checkboxAllState) { return; }

        let direction = 'backwards';

        for (const item of this.data) {
            if (item === clickedItem)                       {direction = 'forward'; }
            if (item === this.checkboxLastSelectedElement)    {direction = 'backwards'; }
        }

        if (direction === 'backwards') {
            const temp = this.checkboxLastSelectedElement;
            this.checkboxLastSelectedElement = clickedItem;
            clickedItem = temp;
        }

        let mark = false;

        for (const item of this.data) {
            if (item === this.checkboxLastSelectedElement) {mark = true; }
            if (mark) {item.checked = true; }
            if (item === clickedItem) { mark = false; }
        }

        this.checkboxLastSelectedElement = clickedItem;

        this.handleCheckboxChanged(true);
    }

    handleCheckboxChanged(clearAllState): void {
        if (clearAllState) {
            this.checkboxAllState = false;
        }
        this.checkboxData = this.data.filter((item) => item.checked);
    }

    checkboxActionDropdownChanged(): void {
        if (this.checkboxActionDropdown === '1') {
            this.saveCheckboxAsBookmarks();
        } else if (this.checkboxActionDropdown === '2') {
            setTimeout(() => {
                this.checkboxActionDropdown = '-1';
            }, 100);
            if (this.checkboxData !== undefined && this.checkboxData.length > 0) {
                this.deleteActionState = 2;
            } else {
                this.deleteActionState = 1;
            }
            this.message.open('deleteAction');
        } else if(this.checkboxActionDropdown === '3') {
            this.message.open('deleteMDI');
        }
        else if(this.checkboxActionDropdown === '4') {
            this.message.open('massClose');
        }
        else if(this.checkboxActionDropdown === '5') {
            this.message.open('markSpam');
        }
        else if(this.checkboxActionDropdown === '6') {
            this.message.open('unmarkSpam');
        }
    }

    startMassAction() {
        this.checkboxActionDropdown = '-1';
        this.message.close('massClose');
        for (const item of this.checkboxData) {
            this.http.put<{data, message}>(
                this.settings.restBaseUrl + 'process/massclose/' + item.tid,{}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id === item.id) {
                            this.data.splice(i, 1);
                            this.searchResultCount--;
                        }
                    }
                });
        }
        setTimeout(() => {
            this.initFormData();
            this.initCurrentSelected();
        }, 300);
    }

    startSpamAction() {
        this.checkboxActionDropdown = '-1';
        this.message.close('markSpam');
        for (const item of this.checkboxData) {
            this.http.put<{data, message}>(
                this.settings.restBaseUrl + 'process/markSpam/' + item.tid,{}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id === item.id) {
                            if(data.message != ''){
                            this.editorWarningString = data.message;
                            this.message.open('editorWarning');}else{
                                this.data.splice(i, 1);
                                this.searchResultCount--;}
                        }
                    }
                });
        }
        setTimeout(() => {
            this.initFormData();
            this.initCurrentSelected();
        }, 300);
    }

    startRemoveSpamAction() {
        this.checkboxActionDropdown = '-1';
        this.message.close('unmarkSpam');
        for (const item of this.checkboxData) {
            this.http.put<{data, message}>(
                this.settings.restBaseUrl + 'process/unmarkSpam/' + item.tid,{}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id === item.id) {
                                this.data.splice(i, 1);
                                this.searchResultCount--;
                        }
                    }
                });
        }
        setTimeout(() => {
            this.initFormData();
            this.initCurrentSelected();
        }, 300);
    }

    saveCheckboxAsBookmarks() {
        const JSONSendData = {bookmarks: []};
        for (const item of this.checkboxData) {

            JSONSendData.bookmarks.push({tid: item.tid});
        }

        this.http.put<any>(
            this.settings.restBaseUrl + 'process/merge', JSON.stringify(JSONSendData), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.router.navigate(['/process-merge/' + this.list]);
            });
    }

    closeDeleteAction() {
        this.checkboxActionDropdown = '-1';
        this.message.close('deleteAction');
    }

    startDeleteAction() {
        this.closeDeleteAction();
        for (const item of this.checkboxData) {
            this.http.delete<any[]>(
                this.settings.restBaseUrl + 'process/' + item.tid, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id === item.id) {
                            this.data.splice(i, 1);
                            this.searchResultCount--;
                        }
                    }
                });
        }
        setTimeout(() => {
            this.initFormData();
            this.initCurrentSelected();
        }, 300);
    }

    changeTabButton(tabId): void {
        this.currentSelectedTab = tabId;
    }

    setRightContainerWidth(width): void {
        this.rightContainerWidth = width;
        this.leftContainerWidth = 100 - this.rightContainerWidth;
    }

    searchOnReturnPressed(event): void {
        if (event.which === 13) {
            this.search(0);
        }
    }

    checkProcessActionsConditions(action: string, item: any) {

        if (this.lastAction === action) {
            this.closeShowMoreProcessActions();
            this.editorWarningString = 'Dieser Editor ist bereits gewählt';
            this.message.open('editorWarning');
            return;
        }

        if(action === 'changeMainData' && ( this.currentSelected.header.mainMdi != '0' || this.currentSelected.header.subMdi != '0') ) {
            this.editorWarningString = 'Es befinden sich Entwürfe im Vorgang!<BR>Daher können Sie den Zuständigen nicht ändern.<BR>Bitte entfernen Sie zuerst alle Entwürfe.';
            this.message.open('editorWarning');
            return;
        }

        this.lastAction = action;
        let ownOrNoContact = false;
        if (action === 'bill') {
            // Abrechnen des Vorgangs
            // Wenn Entwurf enthalten, dann Abbruch und Warnung.
            if( this.currentSelected.header.mainMdi != '0' || this.currentSelected.header.subMdi != '0' ) {
                this.closeShowMoreProcessActions();
                this.editorWarningString = 'Es befinden sich Entwürfe im Vorgang!<BR>Daher können Sie den Vorgang nicht Abrechnen.<BR>Bitte entfernen Sie zuerst alle Entwürfe.';
                this.message.open('editorWarning');
                return;
            }
            
            let openWorkdocumentExist = false;
            this.currentSelected.data.forEach((element) => {
                // tslint:disable-next-line:triple-equals
                if (element.typ == 9 && element.attachemnts.length < 1 || element.typ == 26 && element.attachemnts.length < 1) {
                    this.closeShowMoreProcessActions();

                    this.editorWarningString = 'Es befinden sich offene Arbeitsbeleg in dem Vorgang.\nSchließen Sie die offenen Arbeitsbelege ab oder widerrufen Sie diese um abrechnen zu können.';
                    this.message.open('editorWarning');

                    openWorkdocumentExist = true;
                    return;
                }
            });

            if (openWorkdocumentExist) {
                this.lastAction = '';
                return;
            }

            if (this.currentSelected.worktimelength < 1) {
                this.toggleShowMoreProcessActions();
                this.closeShowMoreProcessActions();

                this.editorWarningString = 'Der Vorgang enthält keine Arbeitszeit, daher kann nicht Abgerechnet werden!';
                this.message.open('editorWarning');

                this.lastAction = '';
                return;
            }
            this.http.get<{ status, response }>(
                this.settings.restBaseUrl + 'process/bill/checkCompany/' + this.currentSelected.header.tid, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    if (data.response !== '' && data.response != null) {
                        this.cantBillWorktimeMessageString = data.response;
                        this.lastAction = '';
                        this.closeShowMoreProcessActions();
                        this.message.open('workingTimeOnOwnOrNoContact');
                        ownOrNoContact = true;
                    }
                    this.getCostCentreNumbers();
                });
        }

        if (action === 'extern_work' || action === 'intern_work') {
            let mainEntry = {user: ''};
            this.currentSelected.data.forEach((element) => {
                if (element.main === '1') {
                    mainEntry = element;
                }
            });
            // tslint:disable-next-line:triple-equals
            if (mainEntry.user == '0' || mainEntry.user == null) {
                this.editorWarningString = 'Arbeitsbelege können nur erstellt werden wenn der Vorgang einem Kontakt zugewiesen ist!';
                this.message.open('editorWarning');
                this.closeShowMoreProcessActions();
                this.lastAction = '';
                return;
            }
        }

        if ( action === 'meeting' ||
             action === 'delegate' ||
             action === 'state' ||
             action === 'split') {
            this.editorWarningString = 'Noch nicht implementiert!';
            this.message.open('editorWarning');
            this.lastAction = '';
            return;
        }

        this.editorContentChanged = false;
        setTimeout(() => {
            if (ownOrNoContact) {
                this.lastAction = '';
                return;
            }
            this.handleProcessAction(action, item);
        }, 300);
    }

    createCalendarEntryFromProcess() {
            this.settings.calendar.createDateFromProcess(this.currentSelected.data[0].tid);
    }

    handleProcessAction(action: string, item: any) {
        // Schnellschließen ausbelden sobald ein Editor für den VOrgang geöffnet wurde.
        for(let i=0; i < this.data.length;i++) {
            if(this.data[i].id == this.currentSelected.header.id) {
                if(this.data[i].second_process == null) { this.data[i].second_process = '1'; }

            }
        }

        //item.second_process++;
        if (action === 'mdiEdit') {
            clearInterval(this.saveInterval);
            this.saveInterval = setInterval(() => {
                this.editorIntervallSave();
            }, this.intervalTimeEditor);
            this.edit(item.id);
            return;
        }

        if(action === 'createNewTicketFromIncomingMail')
        {
           let ticketData = {
                itemId: item.id
            };

            this.http.put<{data, message}>(this.settings.restBaseUrl + 'process/createNewTicketFromIncomingMail', JSON.stringify(ticketData), this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    if(data.message > 0){
                    this.editorWarningString = 'Ticket erfolgreich aufgeteilt.';
                    this.message.open('editorWarning');
                        setTimeout(() => {
                            this.search(0);
                        }, 300);
                    }
                }
            );
            return;
        }

        let mainEntryIndex = 0;
        this.backupEditorData.state_fk_id = this.processStartConditions.state_fk_id;
        this.backupEditorData.assigned = this.processStartConditions.assigned;
        this.backupEditorData.kategorie = this.processStartConditions.kategorie;
        if (this.processSortReverse === '1') {
            for (let i = 0; i < this.currentSelected.data.length; i++) {
                if (this.currentSelected.data[i].main === '1') {
                    mainEntryIndex = i;
                    this.processMainIndex = i;
                }
            }
        }

        let currentContact = 0;
        this.initFormData();
        this.closeShowMoreProcessActions();

        this.formData.mdi = 0;
        if(item.mdi > 0) this.formData.mdi = item.mdi;

        if (action === 'calendar') {
            this.router.navigate(['/core-frame', encodeURIComponent('../calendar/calendar.php' + '?session_id=' + this.settings.sessionId + '&send_tid=' + this.currentSelected.data[0].tid)]);
        }

        if (this.currentSelected.data[mainEntryIndex].project > 0) {
            this.formData.type = 'project';
            this.formData.project = this.currentSelected.data[mainEntryIndex].project;
        }
        if (action === 'reply-last') {
            currentContact = this.currentSelected.data[mainEntryIndex].user;
            item = this.currentSelected.data[mainEntryIndex];
            item.from_address = this.currentSelected.data[mainEntryIndex].contactMail;
            item.contact_username = item.contact_username;
        }

        if (action === 'reply' || action === 'reply-last') {
            this.formData.tid = this.currentSelected.data[mainEntryIndex].tid;
            this.formData.state_fk_id = this.currentSelected.data[mainEntryIndex].state_fk_id;
            this.formData.project = this.currentSelected.data[mainEntryIndex].project;
            this.formData.assigned = this.currentSelected.data[mainEntryIndex].assigned;
            this.formData.kategorie = this.currentSelected.data[mainEntryIndex].kategorie;
            this.formData.fk_team_id = this.currentSelected.data[mainEntryIndex].fk_team_id;

            // Wenn Main keine eingegangene E-Mail ist, dann ermitteln, ob es eine eingegangene E-Mail zum Vorgangskontakt gibt und dessen Titel nutzen. Sonst wieder den des Vorgangs
            // Erste keine eingegangene Mail?
            let lastIncomingMailFromContact = 0;
            if(this.currentSelected.data[0].typ != '28') {
                //Suche nach eingegangenen Mails zum Kontakt
                for(let i=0; i < this.currentSelected.data.length;i++) {
                    if(this.currentSelected.data[i].typ == '28' && this.currentSelected.data[i].user == this.currentSelected.header.contact) {
                        lastIncomingMailFromContact = i;
                    }
                }
                // Wenn eingegangene Mail vorhanden dann titel(name) kopieren
                if( lastIncomingMailFromContact > 0 ) {
                    this.formData.titel = 'Re: ' + this.currentSelected.data[lastIncomingMailFromContact].titel;
                }
            }
            if(this.formData.titel == '' && lastIncomingMailFromContact == 0) {
                // Main Titel setzen, wenn keine submail gefunden oder nicht Typ 28
                this.formData.titel = 'Re: ' + this.currentSelected.data[mainEntryIndex].titel;
            }
        }

        if (action === 'reply-item') {
            this.formData.tid = this.currentSelected.data[mainEntryIndex].tid;
            currentContact = item.user;
            this.formData.titel = 'Re: ' + item.titel;
            this.formData.state_fk_id = this.currentSelected.data[mainEntryIndex].state_fk_id;
            this.formData.project = this.currentSelected.data[mainEntryIndex].project;
            this.formData.assigned = this.currentSelected.data[mainEntryIndex].assigned;
            this.formData.kategorie = this.currentSelected.data[mainEntryIndex].kategorie;
            this.formData.fk_team_id = this.currentSelected.data[mainEntryIndex].fk_team_id;
        }

        if (action === 'forward') {
            this.formData.tid = this.currentSelected.data[mainEntryIndex].tid;
            this.formData.titel = 'Re: ' + this.currentSelected.data[mainEntryIndex].titel;
            this.formData.state_fk_id = this.currentSelected.data[mainEntryIndex].state_fk_id;
            this.formData.project = this.currentSelected.data[mainEntryIndex].project;
            this.formData.assigned = this.currentSelected.data[mainEntryIndex].assigned;
            this.formData.kategorie = this.currentSelected.data[mainEntryIndex].kategorie;
            this.formData.fk_team_id = this.currentSelected.data[mainEntryIndex].fk_team_id;
            this.formData.contact = '';
            // Beim Weiterleiten > Anhänge dazu und Kontakt raus.
            currentContact = 0;
            const position = item.titel.indexOf('[ID:');
            if (position > 0) {
                this.formData.titel = 'Fw: ' + item.titel.substr(0, position);
            } else {
                this.formData.titel = 'Fw: ' + item.titel;
            }
            for (const attachment of item.attachemnts) {
                this.formData.attachment.push({
                    id: attachment.id,
                    name: attachment.name,
                    forward: 1,
                    inline: attachment.inline,
                    ending: attachment.ending,
                    icon: attachment.icon,
                    path: attachment.path,
                    size: attachment.size,
                });
            }
        }
        if (action === 'email' || action === 'comment' || action === 'bill' || action === 'time' || action === 'call' || action === 'escalate' || action === 'extern_work' || action === 'intern_work' || action === 'closeWithWorktime' || action === 'document' || action === 'changeMainData') {
            this.formData.tid = this.currentSelected.data[mainEntryIndex].tid;
            if (action === 'closeWithWorktime') {
                this.formData.titel = 'Vorgang wurde mit nicht abgerechneten Arbeitszeiten geschlossen.';
                this.openWorktimeAcknowledged = false;
            } else {
                this.formData.titel = '';
            }

            if (action === 'email') {
                currentContact = this.currentSelected.data[mainEntryIndex].contact;
                this.formData.contact = '';
                setTimeout(() => {
                    this.formData.text = this.generateMailText(this.formData.text);
                }, 300);
            } else {
                this.formData.text = '';
            }
            this.formData.state_fk_id = this.currentSelected.data[mainEntryIndex].state_fk_id;
            this.formData.project = this.currentSelected.data[mainEntryIndex].project;
            this.formData.assigned = this.currentSelected.data[mainEntryIndex].assigned;
            this.formData.kategorie = this.currentSelected.data[mainEntryIndex].kategorie;
            this.formData.fk_team_id = this.currentSelected.data[mainEntryIndex].fk_team_id;
        }
        if (action === 'new') {
            this.initCurrentSelected();
            if (this.list !== 9) {
                this.formData.type = 'team';
                this.formData.text = this.generateMailText(this.formData.text);
                this.formData.fk_team_id = this.settings.data.baseTeam;
            } else {
                this.formData.type = 'project';
                this.formData.project = this.searchParameter.project;
                this.formData.text = '';
                this.formData.fk_team_id = 0;
                this.formData.noContact = true;
                this.formData.noMail = true;
                this.isCheckedNoContact = true;
                this.isCheckedNoMail = true;
            }
        }

        if (action === 'time' || action === 'call') {
            this.formData.time_duration = 0;
            // Prüfen, ob es bereits einen Eintrag im Vorgang gab und dann den fk_process_billing_type auf dessen Type stellen.
            let processHadABillingType = false;
            for(let i= this.currentSelected.data.length -1; i >= 0;i--) {
                if(this.currentSelected.data[i].fk_process_billing_type != '0' ) {
                    this.formData.fk_process_billing_type = this.currentSelected.data[i].fk_process_billing_type;
                    processHadABillingType = true;
                    break;
                }
            }
                if(this.formData.fk_team_id > 0 ) {
                    // Team Vorgang
                    for(let i= this.searchTeamDropdownDatasource.length -1; i >= 0;i--) {
                        if(this.searchTeamDropdownDatasource[i].fk_groupid ==  this.formData.fk_team_id ) {
                            if(processHadABillingType == false) {
                                // Wenn nicht durch vorherigen Eintrag gesetzt, dann den Team-Standard für die Arbeitszeiterfassung belegen
                                this.formData.fk_process_billing_type = this.searchTeamDropdownDatasource[i].fk_process_billing_type;
                            }
                            if(action === 'call') {
                                // Wenn Anruf das die Uhr nur im Auto Modus Starten
                                // Team Einstellung, ob die Uhr für die Zeiterfassung automatisch startet seten
                                this.formData.autoStartClock = this.searchTeamDropdownDatasource[i].phone_time_start;
                            }
                            break;
                        }
                    }
                } else {
                    // Projekt Vorgang
                    if(processHadABillingType == false) {
                    for(let i= this.dropdownDataProject.length -1; i >= 0;i--) {
                        if(this.dropdownDataProject[i].id ==  this.formData.project) {
                                // Wenn nicht durch vorherigen Eintrag gesetzt, dann den Team-Standard für die Arbeitszeiterfassung belegen
                                this.formData.fk_process_billing_type = this.dropdownDataProject[i].fk_process_billing_type;
                            }
                            break;
                        }
                    }
                }
        }

        if (this.formData.assigned < 1 && action !== 'comment' && action !== 'split' && action !== 'change_contact' && action !== 'document') {
            this.formData.assigned = this.settings.user;
        }

        if (this.formData.type === 'project') {
            this.updateProject();
        } else {
            this.updateTeams();
        }
        setTimeout(() => {
            this.formData.editor = action;
        }, 200);
        if (this.currentSelected.header.tid) {
            setTimeout(() => {
                this.http.get<any>(
                    this.settings.restBaseUrl + 'process/' + this.list + '/' + this.currentSelected.header.tid, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        if (this.processSortReverse === '0') {
                            this.processMainIndex = 0;
                            this.processStartConditions.fk_team_id = data.data[0].fk_team_id;
                            this.processStartConditions.project = data.data[0].project;
                            this.processStartConditions.kategorie = data.data[0].kategorie;
                            this.processStartConditions.state_fk_id = data.data[0].state_fk_id;
                            this.processStartConditions.assigned = data.data[0].assigned;
                            this.preAssignedFormdataTextLength = this.formData.text.length;
                        } else {
                            for (let i = 0; i < data.data.length; i++) {
                                if (data.data[i].main === '1') {
                                    this.processMainIndex = i;
                                }
                            }
                            setTimeout(() => {
                                this.processStartConditions.fk_team_id = data.data[this.processMainIndex].fk_team_id;
                                this.processStartConditions.project = data.data[this.processMainIndex].project;
                                this.processStartConditions.kategorie = data.data[this.processMainIndex].kategorie;
                                this.processStartConditions.state_fk_id = data.data[this.processMainIndex].state_fk_id;
                                this.processStartConditions.assigned = data.data[this.processMainIndex].assigned;
                                this.preAssignedFormdataTextLength = this.formData.text.length;
                            }, 200);
                        }

                    });
            }, 500);
        }

        if (action === 'reply' || action === 'reply-item' || action === 'reply-last') {
            this.formData.to.push({
                id: currentContact,
                name: item.contact_username,
                email: item.from_address,
                first: '1'
            });
            this.newTo.contact = currentContact;
            this.formData.contact = currentContact;
        }

        if (action === 'bill') {
            this.formData.costCentre = 0;
            let billDate = 0;
            if(this.currentSelected.data[this.currentSelected.data.length-1].main==1) {
                billDate = this.currentSelected.data[this.currentSelected.data.length-1].send_time;
            } else {
                billDate = this.currentSelected.data[0].send_time;
            }
            const dateObject = new Date(billDate * 1000);
            const humanDateFormat = ('0' + dateObject.getDate()).slice(-2) + '.' + ('0' + (dateObject.getMonth()+1)).slice(-2) + '.' + dateObject.getFullYear();
            this.formData.receiptDate = humanDateFormat;
        }

        if (action === 'edit') {
            this.formData.text = item.text;
            this.formData.titel = item.titel;
            this.formData.id = item.id;
        }

        if (action === 'reply' || action === 'reply-last' || action === 'reply-item' || action === 'forward') {
            // Text vom Server holen
            this.formData.text = '';
            if(action === 'reply-last') {
                this.formData.text = this.generateMailText(this.formData.text) + '<br>';
                action = 'reply';
            } else {
                this.http.get<any>(
                    this.settings.restBaseUrl + 'process/entry/' + item.id, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        let fromToText = '';
                        if (item.typ === '28') {
                            // Ausgegangene Mail
                            fromToText = 'Am <B>' + item.send_time_string + '</B> schrieb <B>' + item.contact_username + '</B> <' + item.contactMail + '> an <' + item.to_addresses + '>';
                        } else if (item.typ === '1') {
                            // Eingegangene Mail
                            fromToText = 'Am <B>' + item.send_time_string + '</B> schrieb <B>' + item.create_username + '</B> an <b>' + item.contact_username + '</B> <' + item.contactMail + '>';
                        } else {
                            fromToText = 'Am <B>' + item.send_time_string + '</B> von <B>' + item.create_username + '</B> erstellter Vorgang.';
                        }
                        this.formData.text = this.generateMailText(this.formData.text) + '<br>' + fromToText + ':<BR><blockquote style="border-left:2px solid lightgrey; margin-left:10px; padding: 15px;">' + data.text + '</blockquote>';

                        if (data.attachments.length > 0) {
                            this.editorContentChanged = true;
                            this.editorIntervallSave();
                            clearInterval(this.saveInterval);
                            this.saveInterval = setInterval(() => {
                                this.editorIntervallSave();
                            }, this.intervalTimeEditor);
                            return;
                        }
                    });
            }
        }

        this.closeProcessInFullscreen();
        this.editorContentChanged = false;
        this.viewEditor = action;
        this.formData.editor = action;
        clearInterval(this.saveInterval);
        this.saveInterval = setInterval(() => {
            this.editorIntervallSave();
        }, this.intervalTimeEditor);
        // Pre Daten für den Test im Zuständigkeits Editor ob sich Team, Projekt oder Zuständiger geändert hat.
        setTimeout(() => {
            if(action === 'changeMainData' || action === 'comment') {
                this.formData.pre_team_id = this.formData.fk_team_id;
                this.formData.pre_project = this.formData.project;
                this.formData.pre_assigned = this.formData.assigned;
            }
        }, 600);

    }

    generateMailText(originalText) {
        let mailText = '';
        if (this.settings.data.header !== null) {
            mailText += this.settings.data.header + '<br>';
        }
        if (originalText !== '') {
            mailText += originalText;
        }
        if (this.settings.data.footer !== null) {
            mailText += this.settings.data.footer + '<br>';
        }
        if (this.settings.data.footer == null) {
            mailText += '<br>';
        }
        if (this.settings.data.email_signature !== '') {
            mailText += '<div class="myclass mceNonEditable">' + this.settings.data.email_signature + '</div>';
        }
        return mailText;
    }

    toggleShowInfosInProgress() {
        this.showInfosInProgress = !this.showInfosInProgress;
    }

    fastClose(tid) {
        this.closeProcessInFullscreen();
        // Fast close send process
        this.http.put<any>(
            this.settings.restBaseUrl + 'process/close/' + tid, {}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.searchResultCount--;
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].tid === tid && this.data[i].second_process < 1) {
                        this.data.splice(i, 1);
                        if (i < this.data.length - 1) {
                            this.view(null, this.data[i]);
                        }
                    }
                }
            });
    }

    exitEditor(doDelete) {
        if(this.formData.id>0 && this.formData.mdi===0) {
            this.clearEditorData();
        } else {
            if (doDelete === true) {
                // Wenn bereits gespeichern dann entwurf wieder entfernen
                if (this.formData.id > 0 && this.formData.tid > 0) {
                    this.http.delete<any[]>(this.settings.restBaseUrl + 'process/mdi/' + this.formData.id, this.settings.httpOptions)
                        .pipe(map(data => data)).subscribe(
                        data => {
                            this.initCurrentSelected();
                            this.clearEditorData();
                            this.search(0);
                            // this.teamMail = data;
                        });
                }
            } else {
                this.doClearEditorData = true;
                clearInterval(this.saveInterval);
                this.editorContentChanged = true;
                this.editorIntervallSave();
            }
        }

    }

    clearEditorData() {
        this.lastAction = '';
        this.viewEditor = '';
        this.formData.editor = '';
        this.formData.text = '';
        this.formData.titel = '';
        this.formData.bcc = [];
        this.formData.attachment = [];
        this.message.close('discardMDI');
    }

    returnToFormerEditor() {
        this.handleProcessAction(this.backupEditorData.editor, this.formData);
        setTimeout(() => {
            this.formData = this.backupEditorData;
            this.viewEditor = this.backupEditorData.viewEditor;
            if (this.formData.fk_team_id !== '0') {
                this.updateTeams();
            } else {
                this.updateProject();
            }
            this.formData.assigned = this.backupEditorData.assigned;
            this.updateCategoriesList(this.backupEditorData.fk_team_id, this.backupEditorData.project);
            setTimeout(() => {
                const ticketCategory: number = +this.backupEditorData.kategorie;
                this.formData.kategorie = ticketCategory;
                this.updateStateList(ticketCategory);
                setTimeout(() => {
                    const ticketState: number = +this.backupEditorData.state_fk_id;
                    this.formData.state_fk_id = ticketState;
                    this.formData.company = this.backupEditorData.company;
                    this.formData.site = this.backupEditorData.site;
                    this.formData.contact = this.backupEditorData.contact;
                    this.formData.connectId = this.backupEditorData.connectId;
                }, 400);
            }, 400);
        }, 300);
    }

    closeProcessInFullscreen() {
        this.showFullscreen = 0;
        setTimeout(() => {
            const element = document.getElementById('fullscreenProcessScrollBox');
            if(element !== null) element.blur(); // damit scollen mit der Tastatur geht nicht mher zieht sondern wieder die Vorgangsliste
        }, 100);
    }

    showProcessInFullscreen() {
        this.showFullscreen = 1;
        setTimeout(() => {
            document.getElementById('fullscreenProcessScrollBox').focus(); // damit scollen mit der Tastatur geht
        }, 100);
    }

    deleteBcc(item: any) {
        const index = this.formData.bcc.indexOf(item);
        this.formData.bcc.splice(index, 1);
        this.editorIntervallSave();
    }

    addBCC() {
        setTimeout(() => {
            this.delayAddBCC();
        }, 900);
    }

    delayAddBCC() {

        this.http.get<any[]>(this.settings.restBaseUrl + 'team/' + this.formData.fk_team_id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.teamMail = data;
            });

        setTimeout(() => {
            if(this.checkForTeamMailAndWarn(this.bccNameString.email)) { this.newBCC.contact = this.newBCC.company = this.newBCC.site = 0; return;}

            if (this.bccNameString.groupId === undefined || this.bccNameString.groupId === 'undefined' || this.bccNameString.groupId === '') {
                    let newBCCName = '';
                    if (this.bccNameString.contact_salutation !== '') {
                        newBCCName += this.bccNameString.contact_salutation + ' ';
                    }
                    if (this.bccNameString.contact_title !== '') {
                        newBCCName += this.bccNameString.contact_title + ' ';
                    }
                    if (this.bccNameString.contact_first_name !== '') {
                        newBCCName += this.bccNameString.contact_first_name + ' ';
                    }
                    if (this.bccNameString.contact_name !== '') {
                        newBCCName += this.bccNameString.contact_name + ' ';
                    }
                    if (newBCCName === '') {
                        newBCCName = this.bccNameString.email;
                    }

                    let doubleContact = 0;

                    if (this.formData.bcc[0]) {
                        this.formData.bcc.forEach((element) => {
                            if (element.id === this.newBCC.contact) {
                                this.editorWarningString = 'Der Kontakt ist bereits als BCC gesetzt.';
                                this.message.open('editorWarning');
                                doubleContact = 1;
                            }
                        });
                    }

                    if (this.formData.cc[0]) {
                        this.formData.cc.forEach((element) => {
                            if (element.id === this.newBCC.contact) {
                                this.editorWarningString = 'Der Kontakt ist bereits als CC gesetzt.';
                                this.message.open('editorWarning');
                                doubleContact = 1;
                            }
                        });
                    }

                    if (this.formData.to[0]) {
                        this.formData.to.forEach((element) => {
                            if (element.id === this.newBCC.contact) {
                                this.editorWarningString = 'Der Kontakt ist bereits als Empfänger gesetzt.';
                                this.message.open('editorWarning');
                                doubleContact = 1;
                            }
                        });
                    }

                    if (doubleContact === 0) {
                        this.formData.bcc.push({
                            id: this.newBCC.contact,
                            name: newBCCName,
                            email: this.bccNameString.email
                        });
                    }

                    this.newBCC.contact = 0;
                    this.newBCC.company = 0;
                    this.newBCC.site = 0;
                    this.toggleBCCValue();
                    this.editorContentChanged = true;
                    this.editorIntervallSave();

            } else {
                this.delayAddGroup(this.bccNameString.groupId, 'bcc', this.formData.fk_team_id);
            }
        }, 500);
    }

    deleteTo(item: any) {
        const index = this.formData.to.indexOf(item);
        this.formData.to.splice(index, 1);
        this.editorIntervallSave();
    }

    addTo() {
        setTimeout(() => {
            this.delayAddTo();
        }, 200);
    }

    delayAddTo() {
        this.http.get<any[]>(this.settings.restBaseUrl + 'team/' + this.formData.fk_team_id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.teamMail = data;
            });

        setTimeout(() => {
            if (this.toNameString === 0 || this.toNameString === undefined || this.toNameString === 'undefinded') {
                this.addTo();
                return;
            }

            if(this.checkForTeamMailAndWarn(this.toNameString.email)) { this.newTo.contact = this.newTo.company = this.newTo.site = 0; return;}

            if (this.toNameString.groupId === undefined || this.toNameString.groupId === 'undefined' || this.toNameString.groupId === '') {
                if (this.toNameString.email !== this.teamMail.email) {
                    let newToName = '';
                    if (this.toNameString.contact_salutation !== '') {
                        newToName += this.toNameString.contact_salutation + ' ';
                    }
                    if (this.toNameString.contact_title !== '') {
                        newToName += this.toNameString.contact_title + ' ';
                    }
                    if (this.toNameString.contact_first_name !== '') {
                        newToName += this.toNameString.contact_first_name + ' ';
                    }
                    if (this.toNameString.contact_name !== '') {
                        newToName += this.toNameString.contact_name + ' ';
                    }
                    if (newToName === '') {
                        newToName = this.toNameString.email;
                    }
                    let doubleContact = 0;
                    let firstContact = 0;
                    if (this.formData.bcc[0]) {
                        this.formData.bcc.forEach((element) => {
                            if (element.id === this.newTo.contact) {
                                this.message.open('existingContactbcc');
                                doubleContact = 1;
                            }
                        });
                    }

                    if (this.formData.cc[0]) {
                        this.formData.cc.forEach((element) => {
                            if (element.id === this.newTo.contact) {
                                this.message.open('existingContactcc');
                                doubleContact = 1;
                            }
                        });
                    }

                    if (this.formData.to[0]) {
                        this.formData.to.forEach((element) => {
                            if (element.id === this.newTo.contact) {
                                this.editorWarningString = 'Der Kontakt ist bereits als Empfänger gesetzt.';
                                this.message.open('editorWarning');
                                doubleContact = 1;
                            }
                        });

                    } else if(this.formData.contact==undefined) {

                        this.formData.company = this.newTo.company;
                        this.formData.site = this.newTo.site;
                        this.formData.contact = this.newTo.contact;
                    }

                    if (doubleContact === 0) {
                        this.formData.to.push({
                            id: this.newTo.contact,
                            name: newToName,
                            email: this.toNameString.email,
                            first: firstContact
                        });
                    }
                    this.newTo.contact = 0;
                    this.newTo.company = 0;
                    this.newTo.site = 0;
                    this.setToNames(0);
                    this.toggleToValue();
                    this.editorContentChanged = true;
                    this.editorIntervallSave();
                }
            } else {
                this.delayAddGroup(this.toNameString.groupId, 'to', this.formData.fk_team_id);
            }
        }, 500);
    }


    delayAddGroup(groupId :any, type :any, team :any) {

        this.http.get<any[]>(this.settings.restBaseUrl + 'team/' + team, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.teamMail = data;
            });

        let first = 1;
        this.http.get<any>(
            this.settings.restBaseUrl + 'contact/group/emails/' + groupId, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {

                for (const t of data.data) {
                    let exists = 0;

                    if (this.teamMail.email !== t.email) {

                        if (this.formData.bcc[0]) {
                            this.formData.bcc.forEach((element) => {
                                if (element.id === t.contact) {
                                    exists = 1;
                                }
                            });
                        }
                        if (this.formData.cc[0]) {
                            this.formData.cc.forEach((element) => {
                                if (element.id === t.contact) {
                                    exists = 1;
                                }
                            });
                        }
                        if (this.formData.to[0]) {
                            this.formData.to.forEach((element) => {
                                if (element.id === t.contact) {
                                    exists = 1;
                                }
                            });
                        }

                        if (exists === 0) {
                            if (type === 'to') {
                                this.formData.to.push({id: t.contact, name: t.email, email: t.email, first});
                            }
                            if (type === 'cc') {
                                this.formData.cc.push({id: t.contact, name: t.email, email: t.email});
                            }
                            if (type === 'bcc') {
                                this.formData.bcc.push({id: t.contact, name: t.email, email: t.email});
                            }
                            if (first === 1 && type === 'to') {
                                this.formData.contact = t.contact;
                                this.formData.email = t.email;
                            }
                            first = 0;
                        }
                    }
                }
            });
        this.editorIntervallSave();
    }

    deletecc(item: any) {
        const index = this.formData.to.indexOf(item);
        this.formData.cc.splice(index, 1);
        this.editorIntervallSave();
    }

    addCC() {
        setTimeout(() => {
            this.delayAddCC();
        }, 900);
    }

    delayAddCC() {

        this.http.get<any[]>(this.settings.restBaseUrl + 'team/' + this.formData.fk_team_id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.teamMail = data;
            });

        setTimeout(() => {
            if(this.checkForTeamMailAndWarn(this.ccNameString.email)) { this.newCC.contact = this.newCC.company = this.newCC.site = 0; return;}

            if (this.ccNameString.groupId === undefined || this.ccNameString.groupId === 'undefined' || this.ccNameString.groupId === '') {
                    let newCCName = '';
                    if (this.ccNameString.contact_salutation !== '') {
                        newCCName += this.ccNameString.contact_salutation + ' ';
                    }
                    if (this.ccNameString.contact_title !== '') {
                        newCCName += this.ccNameString.contact_title + ' ';
                    }
                    if (this.ccNameString.contact_first_name !== '') {
                        newCCName += this.ccNameString.contact_first_name + ' ';
                    }
                    if (this.ccNameString.contact_name !== '') {
                        newCCName += this.ccNameString.contact_name + ' ';
                    }
                    if (newCCName === '') {
                        newCCName = this.ccNameString.email;
                    }
                    let doubleContact = 0;
                    if (this.formData.bcc[0]) {
                        this.formData.bcc.forEach((element) => {
                            if (element.id === this.newCC.contact) {
                                this.message.open('existingContactbcc');
                                doubleContact = 1;
                            }
                        });
                    }
                    if (this.formData.cc[0]) {
                        this.formData.cc.forEach((element) => {
                            if (element.id === this.newCC.contact) {
                                this.message.open('existingContactcc');
                                doubleContact = 1;
                            }
                        });
                    }
                    if (this.formData.to[0]) {
                        this.formData.to.forEach((element) => {
                            if (element.id === this.newCC.contact) {
                                this.editorWarningString = 'Der Kontakt ist bereits als Empfänger gesetzt.';
                                this.message.open('editorWarning');
                                doubleContact = 1;
                            }
                        });
                    }
                    if (doubleContact === 0) {
                        this.formData.cc.push({
                            id: this.newCC.contact,
                            name: newCCName,
                            email: this.ccNameString.email
                        });
                    }
                    this.newCC.contact = 0;
                    this.newCC.company = 0;
                    this.newCC.site = 0;
                    this.toggleCCValue();
                    this.editorContentChanged = true;
                    this.editorIntervallSave();
            } else {
                this.delayAddGroup(this.ccNameString.groupId, 'cc', this.formData.fk_team_id);
            }
        }, 500);
    }

  changeTo() {
    this.editorContentChanged = true;
    this.editorIntervallSave();
    // Change from new Process to Send mail
  }

    toggleShowMoreProcessActions() {
        this.showMoreProcessActions = !this.showMoreProcessActions;
    }

    closeShowMoreProcessActions() {
        this.showMoreProcessActions = false;
    }

    toggleShowProcessOverview() {
        this.quickMark = 0;
        this.showProcessOverview = !this.showProcessOverview;
    }

    projectChanged() {
        if (this.formData.project === '-2') {
            this.updateTeams();
            setTimeout(() => {
                this.formData.type = 'team';
                this.formData.project = 0;
                this.formData.kategorie = 1111;
            }, 20);
        }
        setTimeout(() => {
            this.formData.kategorie = -1;
            this.updateUserList(0, this.formData.project);
            this.updateCategoriesList(0, this.formData.project);
        }, 50);
    }

    teamChanged() {
        if (this.formData.fk_team_id === '-2') {
            this.updateProject();
            this.formData.fk_team_id = 0;
            this.formData.type = 'project';
            this.formData.project = -1;
        } else {
            if(this.formData.fk_team_id>0) {
                // Mail senden deaktivieren, wenn kein eingenes Team
                // 1 ist es ein eigenes oder nicht
                for(let i=0; i< this.teams.length;i++) {
                    if(this.teams[i].id == this.formData.fk_team_id) {
                        if(this.teams[i].fk_userid==null) {
                            // Mail send deaktivieren
                            this.deactivateMail();
                        } else {
                            this.isCheckedNoMail = false;
                            this.formData.noMail = false;
                        }
                    }
                }
            }
        }
        setTimeout(() => {
            this.formData.kategorie = -1;
            this.updateUserList(this.formData.fk_team_id, 0);
            this.updateCategoriesList(this.formData.fk_team_id, 0);
        }, 10);
    }



    addNewSubTasks() {
        this.currentSelected.subTasks.push({id: '-1', active: '0', edit: '1', tid: this.currentSelected.header.tid, name: ''});
        setTimeout(() => {
            document.getElementById('subTaskEdit-1').focus();
        }, 100);
    }

    saveSubTask( event: any, t: any ) {
        if( event != null) {
            // Methode wurde durch Tastendruck aufgerufen.
            // Wenn nicht Return oder Tab dann beenden. Sonst weiter imt speichern.
            if(event.key != 'Enter' && event.key != 'Tab' && event.key != 'Escape') {
                return;
            }
            // Wenn Tab dann eine weitere Unteraufgabe hinzufügen, aber save verlassen, da dies nochmals von blur event ausgeführt wird. Sonst doppeltes speichern.
            if (event.key == 'Tab'){
                this.addNewSubTasks();
                return;
            }
            // Wenn Escape und noch keine ID dann Eintrag wieder löschen
            if (event.key == 'Escape'){
                if(t.id==-1) {
                    this.currentSelected.subTasks.pop();
                    return;
                } else {
                    return;
                }
            }
        }
        // Wenn Text leer dann aufgabe entfernen
        if(t.name=='') {
            if(t.id==-1) {
                this.currentSelected.subTasks.pop();
                return;
            } else {
                this.editorWarningString = 'Sie müssen einen Text eingeben!';
                this.message.open('editorWarning');
                return;
            }
        }
        this.http.put<{ name, create_username, create_timestamp_string, active, tid, id }>(
            this.settings.restBaseUrl + 'process/subTask/' + t.id, JSON.stringify(t), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                t.edit = 0;
                t.name = data.name;
                t.create_username = data.create_username;
                t.create_timestamp_string = data.create_timestamp_string;
                t.active = data.active;
                t.tid = data.tid;
                t.id = data.id;
            });
    }



    mark(item: any, value: number) {
        if(this.searchParameter.markedFilter) {
            // Wenn Filter an dann Element etnfernen
            for(let i=0; i< this.data.length;i++) {
                if(this.data[i].tid == item.tid) {
                    this.data.splice(i,1);
                    break;
                }
            }
        } else {
            // Sonst Icon ändern
            if(value==1) { item.marked = 1; } else { item.marked = null; }
        }

        this.http.put<any>(
            this.settings.restBaseUrl + 'process/mark', {ticketId: item.id, value}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            });
    }

    renameProcess(element: any){

        // Nach einer Vorgangsnummer im Titel suchen, wenn vorhanden zwischenspeichern und aus dem Titel entfernen
        const searchResult = element.titel.match(/[\[][I][D][:]\d\d\d\d\d\d\d\d[\]]/);
        if(searchResult && searchResult.length > 0) {
            this.storedProcessNumber = searchResult[0];
            element.titel = element.titel.replace(this.storedProcessNumber , '');
        } else {
            this.storedProcessNumber = '';
        }

        this.renameProcessActive = true;
        element.titleEdit = '1';
        setTimeout(() => {
            document.getElementById('titelEdit' + element.id).focus();
        }, 100);
    }

    saveProcessTitle(element: any) {
        element.titel = element.titel.trim();

        if(this.storedProcessNumber != ''){
            element.titel = element.titel+ ' ' +this.storedProcessNumber;
        }

        if(element.titel === '') {
            this.editorWarningString = 'Das Titel Feld darf nicht leer sein!';
            this.message.open('editorWarning');
            return;
        }
        this.renameProcessActive = false;
        this.http.put<{ name, create_username, create_timestamp_string, active, fk_process, id }>(
            this.settings.restBaseUrl + 'process/entry/title', {
                id: element.id,
                title: element.titel
            }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.view(null, element);
                element.titleEdit = '0';
            });
    }

    changeSendMail() {
        // Wenn ein Fremdteam gewählt, dann E-Mail nicht wieder aktivieren und mit Dialog darauf hinweisen
        for(let i=0; i< this.teams.length;i++) {
            if(this.teams[i].id == this.formData.fk_team_id) {
                if(this.teams[i].fk_userid==null) {
                    this.editorWarningString = 'Sie können keine E-Mails über Teams versenden, in dehnen Sie sich selbst nicht Mitglied sind!';
                    this.message.open('editorWarning');
                    setTimeout(() => {
                        this.isCheckedNoMail = true;
                        this.formData.noMail = true;
                    }, 200);
                    return;
                }
            }
        }
        if(this.formData.noMail == true) {
            this.deactivateMail();
        } else {
           this.activateMail();
        }
    }

    activateMail() {
        this.isCheckedNoMail = false;
        this.formData.noMail = false;
        // Wenn E-Mail dann muss es auch einen Zuständigen geben
        this.isCheckedNoContact = false;
        this.formData.noContact = false;
    }

    deactivateMail() {
        this.isCheckedNoMail = true;
        this.formData.noMail = true;
        this.formData.bcc = [];
        this.formData.cc = [];
        this.formData.to.splice(0);
        this.message.close('deactivateMail');
    }

    toggleSubTaskState(x: any) {
        if(!this.currentSelected.processRight) { return; }
        if (x.active === '1') {
            x.active = '0';
        } else {
            x.active = '1';
        }
        this.http.put<any>(
            this.settings.restBaseUrl + 'process/subTask', JSON.stringify(x), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            });
    }

    subTaskDelete(item: any) {
        this.http.delete<any>(
            this.settings.restBaseUrl + 'process/subTask/' + item.id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                for (const t of this.data) {
                    if (t.tid === this.currentSelected.header.tid) {
                        this.view(null, t);
                    }
                }
            });
    }

    subTaskEdit(item: any) {
        item.edit = '1';
        setTimeout(() => {
            document.getElementById('subTaskEdit' + item.id).focus();
        }, 100);
    }

    openAddress(userId) {
        alert(userId);
    }

    public unsetNewOnProcess(listItem: any) {
        listItem.new = 0;
        let stillOneMarkedAsNew = false;
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].new == 1) {
                stillOneMarkedAsNew = true; break;
            }
        }
        if(stillOneMarkedAsNew == false) {
            this.settings.notificationManager.updateNotificationData();
        }
    }

    public updateItem(item) {
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].tid === item.tid) {
                this.data[i] = item;
            }
        }
    }

    startContextmenuAction(item: any, action: string) {
        if (action === 'assignToMe' && item.assigned === '0') {
            this.http.put<any>(
                this.settings.restBaseUrl + 'process/assign', {tid: item.tid}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    item.assigned = data.userid;
                });
            return;
        }

        if (action === 'changeContact') {
            this.formData.user = item.user;
            this.formData.company = item.company;
            this.formData.site = item.site;
            this.formData.tid = item.tid;
            this.contactChangeProcess = item;
            this.messageBox.action = action;
            this.message.open('messageBox');
        }
    }

    processContactSave(doSave: boolean) {
        if(doSave) {
            this.currentSelected.header.contact = this.currentSelected.contact;
            this.http.put<any>(
                this.settings.restBaseUrl + 'process/changeContact/' + this.currentSelected.header.tid, {user: this.currentSelected.contact}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.http.post<{ status, count, data, message, highestId }>(this.settings.restBaseUrl + 'process', {'id':this.currentSelected.header.id,'list':this.list} , this.settings.httpOptions)
                        .pipe(map(data => data)).subscribe(
                        data => {
                            for(let i=0; i< this.data.length;i++) {
                                if(this.data[i].id == this.currentSelected.header.id) {
                                    this.data[i] = data.data[0];
                                    break;
                                }
                            }
                        });
                    this.view(null, this.currentSelected);
                });
        } else {
            this.currentSelected.contact = this.currentSelected.header.contact;
        }
        this.message.close('changeProcessContact');
        this.message.close('deleteProcessContact');
    }

    actionCategoryChanged() {
        setTimeout(() => {
            this.formData = this.backupEditorData;
        }, 200);
    }

    public edit(id: any) {

        this.initFormData();
        this.combinedAttachmentSize = 0;
        this.viewEditor = 'new';
        this.http.get<any>(
            this.settings.restBaseUrl + 'process/entry/' + id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.formData.mdi = data.mdi;
                this.formData.id = data.id;
                this.formData.tid = data.tid;
                this.formData.company = data.company;
                this.formData.site = data.site;
                this.formData.contact = data.user;
                if(this.formData.contact == 0) {
                    this.isCheckedNoContact = true;
                }
                this.formData.titel = data.titel;
                this.formData.text = data.text;
                this.preAssignedFormdataTextLength = this.formData.text;
                this.formData.to = data.to;
                this.formData.cc = data.cc;
                this.formData.bcc = data.bcc;

                let thereAreNoMails = true;
                if (this.formData.to && this.formData.to.length > 0) { thereAreNoMails = false; }
                if (this.formData.cc && this.formData.cc.length > 0) { thereAreNoMails = false; this.toggleCC = 1; }
                if (this.formData.bcc && this.formData.bcc.length > 0) { thereAreNoMails = false; this.toggleBCC = 1; }
                if( this.formData.noMail == 1 && thereAreNoMails == true) { this.isCheckedNoMail = true; this.formData.noMail = true; } else { this.isCheckedNoMail = false; this.formData.noMail = false; }
                if( this.formData.contact == 0 && thereAreNoMails) { this.isCheckedNoContact = true; this.formData.noContact = true; this.isCheckedNoMail = true; this.formData.noMail = true; } else { this.isCheckedNoContact = false; this.formData.noContact = false; }


                this.formData.fk_team_id = data.fk_team_id;
                this.formData.project = data.project;
                this.formData.kategorie = data.kategorie;
                this.formData.state_fk_id = data.state_fk_id;

                this.formData.fk_process_billing_type = data.fk_process_billing_type;
                this.formData.time_duration = data.time_duration;
                if (data.typ == '1' || data.typ == '29') {
                    if(data.main==1) {
                        this.formData.editor = this.viewEditor = 'new';
                    } else {
                        this.formData.editor = this.viewEditor = 'email';
                    }

                } else if (data.typ === '2') {
                    this.formData.editor = this.viewEditor = 'comment';
                } else if (data.typ === '3') {
                    this.formData.editor = this.viewEditor = 'call';
                } else if (data.typ === '4') {
                    this.formData.editor = this.viewEditor = 'time';
                }

                if (data.attachments !== undefined && data.attachments.length > 0) {
                    this.formData.attachment = data.attachments;
                    this.formData.attachmentsExist = true;
                    this.calculateCombinedAttachmentSize();
                }
                if (this.formData.text === '') {
                    setTimeout(() => {
                        this.formData.text = this.generateMailText(this.formData.text);
                    }, 200);
                }
                this.editorContentChanged = true;

                if(this.formData.project !== '0') {
                    this.formData.type = 'project';
                    this.updateProject();
                } else {
                    this.formData.type = 'team';
                    this.updateTeams();
                }
                setTimeout(() => {
                    this.formData.assigned = data.assigned;
                }, 600);


            });
    }

    setBCCNames(event: any) {
        this.bccNameString = event;
    }

    setToNames(event: any) {
        this.toNameString = event;
    }

    setToGroup(event: any) {
        this.toGroupString = event;
    }

    setCCNames(event: any) {
        this.ccNameString = event;
    }


    handelContactStringChange(event: any) {
        this.formData.email = event.email;
    }

    showProcessEntry(entry: any) {
        if (entry.id > 0) {
            if (entry.information === '1') {
                this.showInfosInProgress = true;
            }
            setTimeout(() => {
                const itemToScrollTo = document.getElementById('processEntry' + entry.id);
                if (itemToScrollTo) {
                    itemToScrollTo.scrollIntoView(true);
                }
                this.quickMark = entry.id;
            }, 100);

        }
    }

    toggleProgressSort() {
        if (this.processSortReverse === '0') {
            this.processSortReverse = '1';
        } else {
            this.processSortReverse = '0';
        }
        this.settings.set('processSortReverse', this.processSortReverse, 1);
        this.resortProcessItems();
    }

    resortProcessItems() {
        if (this.processSortReverse === '0') {
            this.currentSelected.data.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
            this.processMainIndex = 0;
        }
        if (this.processSortReverse === '1') {
            this.currentSelected.data.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
            this.processMainIndex = this.currentSelected.data.length - 1;
        }
    }

    tableOptionChanged() {
        this.settings.set('processTableSettings' + this.list, JSON.stringify(this.tableSettings), 1);
    }

    viewPrintView(x: any) {
        let filepath = null;
        const filename = 'Drucken';
        filepath = '../ticket/singleTicketPrintview.php?x=' + Date.now() + '&session_id=' + this.settings.sessionId + '&tid=' + x.id;
        this.viewFullscreen(null, filepath, filename);
        setTimeout(() => {
            this.closeFullscreen();
        }, 1000);
    }

    quickSaveTeamCategory() {
        let hasOpenWorkDocument = false;
        this.currentSelected.data.forEach((element) => {
            if( ( element.type === '9' || element.typ === '27' ) && element.titel === 'Noch nicht abgeschlossen.') {
                hasOpenWorkDocument = true;
            }
        });
        let closeWithWorktime = false;
        // tslint:disable-next-line:triple-equals
        this.states.forEach((element) => {
            if (this.formData.state_fk_id === element.id && element.autoclosed === '1') {
                // Der gewählte status will den Vorgang schließen.
                if(hasOpenWorkDocument) {
                    // Nicht schließen wenn offene Arbeitsbelege
                    this.view(null, this.currentSelected);
                    this.editorWarningString = 'Der Vorgang kann nicht geschlossen werden! <br>Er enthält mindestens einen offenen Arbeitsbeleg.';
                    this.message.open('editorWarning');
                    return;
                } else if(this.currentSelected.worktime.length > 0) {
                    // Oder warnen wenn Arbeitszeit vorhanden
                    closeWithWorktime = true;
                    this.closingState = element.id;
                }
            }
        });
        setTimeout(() => {
            if (closeWithWorktime && !this.openWorktimeAcknowledged) {
                if(this.currentSelected.header.subMdi !== '0') {
                    this.editorWarningString =  'Der Vorgang enthält Entwürfe.<BR>' +
                        'Der Status hätte den Vorgang geschlossen.<BR>\n' +
                        'Darum konnten die Änderungen nicht gespeichert werden.';
                    this.message.open('editorWarning');
                    this.view(null,this.currentSelected);
                    return;
                }
                this.closeWithWorktimeMessage = 'Sie sind im Begriff einen Vorgang mit nicht abgerechneter Arbeitszeit zu schließen. Sind Sie sich sicher ?';
                this.message.open('closeWithWorktimeWarning');
                return;
            } else {
                this.openWorktimeAcknowledged = false;
                if (this.formData.project > 0) {
                    // Fix wenn projekt dann kein Team
                    this.formData.fk_team_id = 0;
                }

                const sendData = {
                    type: 'team',
                    fk_team_id: this.formData.fk_team_id,
                    project: this.formData.project,
                    assigned: this.formData.assigned,
                    kategorie: this.formData.kategorie,
                    state_fk_id: this.formData.state_fk_id
                };

                this.http.put<any>(
                    this.settings.restBaseUrl + 'process/conditions/' + this.formData.tid, sendData, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        // Fehlerbehandlung und Ausgabe wenn eine Warnungsmeldung an Benutzer
                        if(data.status==='error') {
                            this.editorWarningString = data.message;
                            this.message.open('editorWarning');
                        }
                        // Vorgang wurde geschlossen oder an anderen User verschoben. Dann entladen und aus Liste entfernen
                        if(data.justClosed == true || (this.list === '6' && sendData.assigned !== this.settings.data.userId)) {
                            for(let i=0; i< this.data.length;i++) {
                                if(this.data[i].tid == this.currentSelected.header.tid) {
                                    this.data.splice(i,1);
                                    this.searchResultCount--;
                                    if(this.data[i]!=undefined) {
                                        this.view(null, this.data[i]);
                                    }
                                    break;
                                }
                            }
                            this.initCurrentSelected();
                            this.doClearEditorData = false;
                            this.clearEditorData();
                            return;
                        } else {
                            // Sonmst Daten aktualisieren
                            for (let i = 0; i < this.data.length; i++) {
                                if (this.data[i].tid === this.formData.tid) {
                                    this.data[i].kategorie = sendData.kategorie;
                                    this.data[i].state_fk_id = sendData.state_fk_id;
                                    this.data[i].assigned = sendData.assigned;
                                    this.data[i].assigned_user = data.assigned_user;
                                    this.data[i].project_name = data.project_name;
                                    this.data[i].team_name = data.team_name;
                                    this.data[i].project = sendData.project;
                                    this.data[i].fk_team_id = sendData.fk_team_id;

                                    if (data.category_name) {
                                        this.data[i].category_name = data.category_name;
                                    }
                                    if (data.state_name) {
                                        this.data[i].state_name = data.state_name;
                                        this.data[i].progress = data.progress;
                                    }
                                    this.showInfosInProgress = true;
                                    this.view(null, this.data[i]);
                                    break;
                                }
                            }
                        }

                        setTimeout(() => {
                            this.openWorktimeAcknowledged = false;
                        }, 300);
                    });
            }
        }, 200);
    }

    sendToDMS($event: MouseEvent, item: any) {
        this.http.put<any>(
            this.settings.restBaseUrl + 'process/attachment/dms/' + this.currentSelected.header.tid, JSON.stringify(item), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (data.status === 'error') {
                    this.editorWarningString = data.message;
                    this.message.open('editorWarning');
                } else {
                    this.editorWarningString = 'Das Dokument wurde in das DMS verschoben.';
                    this.message.open('editorWarning');
                }
                this.showInfosInProgress = true;
                for (const currentItem of this.data) {
                    if (currentItem.tid === this.currentSelected.header.tid) {
                        setTimeout(() => {
                            this.view(null, currentItem);
                        }, 200);
                        break;
                    }
                }
            });
    }

    public mergeProcess(t: any, action: number) {
        this.http.put<any>(
            this.settings.restBaseUrl + 'process/merge', {tidsToMerge: [{tid: t.tid}]}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (action === 1) {
                    this.router.navigate(['/process-merge']);
                }
            });
    }

    public getDropdownSearchParameterCategoryData() {

        this.http.post<any['data']>(this.settings.restBaseUrl + 'user', {
            active: 1,
            currentCompany: 1,
            team: this.searchParameter.fk_team_id
        }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownDataUser = data.data;
            }
        );

        this.searchParameter.category = -1;
        this.http.post<{ status, count, data }>(
            this.settings.restBaseUrl + 'process/category', {
                processSearchCategory: 1,
                fk_project_id: this.searchParameter.project,
                team: this.searchParameter.fk_team_id
            }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownSearchParameterCategoryData = data.data;
                if ( ( this.searchParameter.fk_team_id === '-1' || this.searchParameter.fk_team_id === '-2' ) && this.list !== 9) {
                    for (let i = 0; i < this.dropdownSearchParameterCategoryData.length; i++) {
                        if (this.searchTeamDropdownDatasource && this.searchTeamDropdownDatasource.length > 0) {
                            // tslint:disable-next-line:prefer-for-of
                            for (let o = 0; o < this.searchTeamDropdownDatasource.length; o++) {
                                // :todo > Warum ist hier this.searchTeamDropdownDatasource[o] == undefined ? Das killt dann die Suche
                                if (this.searchTeamDropdownDatasource[o] != undefined && this.dropdownSearchParameterCategoryData[i].fk_team === this.searchTeamDropdownDatasource[o].fk_groupid) {
                                    this.dropdownSearchParameterCategoryData[i].name = this.searchTeamDropdownDatasource[o].name + ' - ' + this.dropdownSearchParameterCategoryData[i].name;
                                    this.dropdownSearchParameterCategoryData.splice(i, 1, this.dropdownSearchParameterCategoryData[i]);
                                }
                            }
                        }
                        this.dropdownSearchParameterCategoryData.sort((a, b) => {
                            return a.fk_team - b.fk_team;
                        });
                    }
                }

                setTimeout(() => {
                 if(this.donNotStartSearch == false){   this.search(0);}
                }, 300);

            });
    }

    getDropdownSearchParameterStateData() {
        this.http.post<{ status, count, data }>(
            this.settings.restBaseUrl + 'process/category/state', {
                fk_category: this.searchParameter.category,
                active: 1
            }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownSearchParameterStateData = data.data;
                this.searchParameter.state_fk_id = '-1';
                this.search(0);
            });
    }

    fullscreenView($event: MouseEvent, x: any) {
        this.view($event, x);
        this.showProcessInFullscreen();
    }

    viewInline($event: MouseEvent, attachments: any) {
        for (const t of attachments) {
            t.inline = 0;
        }
    }

    checkForInlineAttachments(attachments: any) {
        for (const t of attachments) {
            if (t.inline === '1') {
                return true;
            }
        }
        return false;
    }

    showProcessTimeline() {
        this.showProcessInFullscreen();
    }

    contactProcessChanged() {
        this.editorWarningString = 'Geändert zu ' + this.currentSelected.header.contact ;
        this.message.open('editorWarning');
    }

    searchInArchive() {
        this.searchParameter.active = -1; // Alle Suchen
        this.expandedSearch = true;
        this.search(0);
    }

    favoriteSelected() {
        const storeCategory = this.searchParameter.category;
        this.donNotStartSearch = true;
        this.getDropdownSearchParameterCategoryData();
        setTimeout(() => {
            this.searchParameter.category = storeCategory;
            setTimeout(() => {
                this.search(0);
            }, 200);
        }, 100);
    }

    getDropdownSearchMoveUserData() {
        this.http.post<{ data: [] }>(this.settings.restBaseUrl + 'team/user/' + this.move.fk_team_id, {active: 1}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.moveUserDropdownDatasource = data;
            });
    }

    moveMailsToUser() {
        this.http.put<any[]>(this.settings.restBaseUrl + 'process/move', JSON.stringify(this.move), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.search(null);
                this.editorWarningString = 'Alle Mails wurden verschoben!';
                this.message.open('editorWarning');
            });
    }

    showListWithFilter($event: MouseEvent, b) {
        this.expandedSearch = true;
        this.searchParameter.category = b.id;
        this.getDropdownSearchParameterStateData();
        this.listViewMode = 1;
    }

    changeNoContact() {
        if(this.formData.noContact==true) {
            this.formData.company = 0;
            this.formData.site = 0;
            this.formData.contact = 0;
            this.isCheckedNoMail = true;
            this.formData.noMail = true;
            this.changeSendMail();
        } else {
            this.isCheckedNoMail = false;
            this.formData.noMail = false;
        }


    }

    goTo(selector) {
        const destination = selector.options[selector.selectedIndex].value;
        this.router.navigate(['/' + destination]);
    }

    editSubTask(t) {
        if(!this.currentSelected.processRight) { return; }
        t.edit = '1';
        setTimeout(() => {
            document.getElementById('subTaskEdit'+t.id).focus();
        }, 100);
    }

    deleteSubTask(del) {
        this.http.delete<any[]>(this.settings.restBaseUrl + 'process/subTask/' + del.id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            });
        for (let i = 0; i < this.currentSelected.subTasks.length; i++) {
            if (this.currentSelected.subTasks[i].id === del.id) {
                this.currentSelected.subTasks.splice(i, 1);
            }
        }
    }

    changeProject() {
        this.searchParameter.project = this.project;
        setTimeout(() => {
            this.executeSearch(0);
        }, 300);

    }

    public setEditorChanged() {
        if (this.editorContentChanged === false) {
            this.editorContentChanged = true;
            this.editorIntervallSave();
        }
    }

    public uploadAttachment($event) {
        this.formData.attachmentsExist = true;
        this.save(0);
        if (this.formData.id === undefined) {
            setTimeout(() => {
                this.uploadAttachment($event);
            }, 300);
        } else {
            let files: FileList;
            files = $event.srcElement.files;
            this.uploadAttachmentAfterProcessIsSaved(files, $event);
        }
    }

    uploadAttachmentAfterProcessIsSaved(files: FileList, $event) {
        // this.fileCountToUpload = files.length;
        let attachmentCount = files.length;
        this.fileUploading = true;
        Array.from(files).forEach(file => {
            const uploadData = new FormData();
            uploadData.append('files', file);
            this.http.post<any['id']>(this.settings.restBaseUrl + 'file/' + this.formData.tid + '/' + this.formData.id, uploadData, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    attachmentCount--;
                    if(attachmentCount===0) {
                        this.fileUploading = false;
                    }
                    this.formData.attachment.push({
                        id: data.id,
                        name: data.name,
                        path: data.path.substring(0, data.path.length - 1),
                        icon: data.icon,
                        ending: data.ending,
                        forward: 0,
                        inline: 0,
                        size: data.size
                    });
                    // in Vorgang schreiben!
                    this.currentSelected.data.forEach(processEntry => {
                        if (processEntry.id === this.formData.id) {
                            processEntry.attachemnts.push({
                                id: data.id,
                                name: data.name,
                                path: data.path.substring(0, data.path.length - 1),
                                icon: data.icon,
                                ending: data.ending,
                                forward: 0,
                                inline: 0,
                                size: data.size
                            });
                        }
                    });
                    // @ts-ignore
                    document.getElementById('inputId').value = '';
                    // Dateigröße ermitteln
                    if( (data.size / 1048576) > this.maxAttachmentSizeOneFile) {
                        this.deleteAttachment(data);
                        this.editorWarningString = 'Sie können keine Datei hochladen die größer als <B>' + this.maxAttachmentSizeOneFile +' MB</B> ist!<BR> Die Datei <B>' + data.name + '</B> hat eine Größe <B>' + (data.size/1048576).toFixed(2) + 'MB</B>';
                        this.message.open('editorWarning');
                    } else {
                        this.calculateCombinedAttachmentSize();
                    }
                }
            );
        });
        $event.target.value = null
    }

    private calculateCombinedAttachmentSize() {
        this.combinedAttachmentSize = 0;
        this.formData.attachment.forEach(attachmentEntry => {
            if( (parseFloat(attachmentEntry.size) / 1048576) > this.maxAttachmentSizeOneFile) {
                this.editorWarningString = 'Es können keine Dateien größer als <B>' + this.maxAttachmentSizeOneFile + ' MB</B> angehängt werden.<BR> Daher wurde die Datei  <B>' + attachmentEntry.name + ' - ' + (parseFloat(attachmentEntry.size) / 1048576).toFixed(2) + ' MB</B> entfernt.<BR>';
                this.message.open('editorWarning');
                this.deleteAttachment(attachmentEntry);
                return;
            }
            this.combinedAttachmentSize += parseFloat(attachmentEntry.size);
        });
        this.combinedAttachmentSize = parseFloat((this.combinedAttachmentSize / 1048576).toFixed(2));
    }

    renameAttachment(att) {
        // Dateiendung abschneiden
        const endingLength = att.ending.length + 1;
        att.name = att.name.substring(0, att.name.length - endingLength);
        att.edit = 1;
        setTimeout(() => {
            document.getElementById('addInput' + att.id).focus();
        }, 100);
    }

    saveAttachmentAfterRenameKeydown($event: KeyboardEvent, att) {
        if ($event.key === 'Enter') {
            this.saveAttachmentAfterRename(att);
        }
    }

    saveAttachmentAfterRename(att) {
        // Dateiendung anhängen
        att.name = att.name + '.' + att.ending;
        // Name speichern
        this.http.put<any[]>(this.settings.restBaseUrl + 'process/attachment', JSON.stringify(att), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                att.edit = 0;
                // in Vorgang zurück schreiben!
                this.currentSelected.data.forEach(processEntry => {
                    if (processEntry.id === this.formData.id) {
                        processEntry.attachemnts.forEach(attachment => {
                            if (attachment.id === att.id) {
                                attachment.name = att.name;
                            }
                        });
                    }
                });
            });
    }

    viewAttachment(att: any) {
        if (att.ending === 'pdf' || att.ending === 'jpg' || att.ending === 'png' || att.ending === 'gif' || att.ending === 'bmp' || att.ending === 'wav' || att.ending === 'mp3') {
            const filename = att.name;
            const filepath = att.path + '/' + att.name;
            this.viewFullscreen(null, filepath, filename);
        } else {
            this.downloadFile(att);
        }
    }

    deleteAttachment(att: any) {
        this.http.delete<any>(
            this.settings.restBaseUrl + 'file/' + att.id, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                const index = this.formData.attachment.indexOf(att);
                this.formData.attachment.splice(index, 1);
                // in Vorgang zurück schreiben!
                this.currentSelected.data.forEach(processEntry => {
                    if (processEntry.id === this.formData.id) {
                        processEntry.attachemnts.forEach(attachment => {
                            if (attachment.id === att.id) {
                                const attachmentIndex = processEntry.attachemnts.indexOf(attachment);
                                processEntry.attachemnts.splice(attachmentIndex, 1);
                                this.calculateCombinedAttachmentSize();
                            }
                        });
                    }
                });
                this.editorContentChanged = true;
                this.editorIntervallSave();
            });
    }

    contactEditActivate() {
        this.currentSelected.header.contact = -1;
    }

    processContactDelete() {
        this.currentSelected.header.contact = this.currentSelected.contact = 0;
        this.processContactSave(true);
    }

    updateContactData() {
        this.currentSelected.header.contact = this.currentSelected.contact;
    }



    clearHiddenState() {
        this.http.put<any[]>(this.settings.restBaseUrl + 'process/hidden', { tid : this.currentSelected.header.tid }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.search(0);
            });
    }

    changeSearchCategorySelectType() {
        if(this.searchParameter.categoryType==='0')
            this.searchParameter.categoryType = '1';
        else
            this.searchParameter.categoryType = '0';
        this.search(0);
    }

    startDeleteMDI() {
        this.checkboxActionDropdown = '-1';
        this.message.close('deleteMDI');
        for (const item of this.checkboxData) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/mdi/clear/' + item.tid,{}, this.settings.httpOptions)
                .pipe(map(data => data)).subscribe(
                () => {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id === item.id) {
                            this.data[i].checked = 0;
                            this.data[i].mdi = 0;
                            this.data[i].draft = null;
                        }
                    }
                });
        }
        setTimeout(() => {
            this.initFormData();
            this.initCurrentSelected();
        }, 300);
    }

    stopDeleteMDI() {
        this.checkboxActionDropdown = '-1';
        this.message.close('deleteMDI');
    }

    public isItemEndingPDF(item: any): boolean {
        return item?.ending == 'pdf';
    }

    public isItemAssigned(item: any): boolean {
        return item?.assigned;
    }

    public isItemActive(item: any): boolean {
        return item?.active == 1;
    }

    public isItemProcessMarked(item: any): boolean {
        if(item.marked != undefined && item.marked > 0) { return true; } else { return false; }
    }

    public isItemProcessNotMarked(item: any): boolean {
        if(item.marked == null || item.marked == 0) { return true; } else { return false; }
    }

    checkNoUser() {
        this.isCheckedNoContact = false;
        this.changeNoContact();
    }

    toggeleMarkedFilter() {
        if(this.searchParameter.markedFilter) {
            this.searchParameter.markedFilter=false;
        } else {
            this.searchParameter.markedFilter=true;
        }
        this.settings.set('processListMarked', this.searchParameter.markedFilter, 1);
        this.search(0);
    }


    initNewBCC(): void {
        this.newBCC = {company: 0, site: 0, contact: 0, name: ''};
    }

    initNewTo(): void {
        this.newTo = {company: 0, site: 0, contact: 0, name: '', connectId: ''};
    }

    initNewGroup(): void {
        this.newGroup = {id: 0, name: ''};
    }

    initNewCC(): void {
        this.newCC = {company: 0, site: 0, contact: 0, name: ''};
    }

    initBackupEditorData() {
        this.backupEditorData = null;
        this.backupEditorData = {
            id: '',
            itemId: '',
            tid: '',
            bcc: [],
            to: [],
            cc: [],
            company: '',
            site: '',
            contact: '',
            noMail: false,
            email: '',
            connectId: '',
            titel: '',
            text: '',
            attachment: [],
            type: '',
            editor: '',
            typ: '',
            fk_team_id: '',
            project: '',
            assigned: '',
            kategorie: '',
            state_fk_id: '',
            time_duration: 0,
            fk_process_billing_type: '',
            viewEditor: ''
        };
    }

    initCurrentSelected(): void {
        this.currentSelectedId = 0;
        this.quickMark = 0;
        this.currentSelected = {
            contact: 0,
            processRight:false,
            processRightRole: 0,
            header: { id: '', tid: '', state: '', assigned:0, categoryStateChangeRight:false, groupAssignedChangeRight:false ,fk_team_role:String },
            subTasks: [],
            data: [{
                id: '',
                name: '',
                text: '',
                category1: '',
                category2: '',
                timestampString: '',
                user: '',
                subTasks: []
            }]
        };
    }

    initTableSettings(): void {
        let sessionSettings = this.settings.get('processTableSettings' + this.list);
        if (sessionSettings) {
            sessionSettings = JSON.parse(sessionSettings);
            // todo: In 2025 entfernen
            let updateSessionVar = false;
            if(sessionSettings.sort) { sessionSettings['prio'] == sessionSettings['sort']; delete sessionSettings['sort']; updateSessionVar = true; }
            if(sessionSettings.prio==undefined) { sessionSettings['prio'] = {name: 'Priorität', state: false, locked: false}; updateSessionVar = true; }
            if(updateSessionVar) { this.settings.set('processTableSettings' + this.list, sessionSettings, 1); }
            // todo: ende
            this.tableSettings = sessionSettings;
        } else {
            this.tableSettings = {
                tid: {name: 'Tid', state: false, locked: false},
                prio: {name: 'Priorität', state: false, locked: false},
                title: {name: 'Title', state: true, locked: true},
                group: {name: 'Gruppe', state: false, locked: false},
                team: {name: 'Team/Projekt', state: false, locked: false},
                assigned_user: {name: 'Zuständiger', state: false, locked: false},
                state: {name: 'Status', state: true, locked: false}
            };
        }
    }

    initSearchParameter(): void {
        let sort = 'last_changed DESC';
        let active = 1;
        if (this.list === null) {
            this.list = '6';
        }
        if (this.list === '9') {
            sort = 'sort';
        }
        if (this.list === '1') {
            sort = 'send_time DESC';
            active = -1;
        }
        if (this.settings.get('processListSortSettings')) {
            sort = this.settings.get('processListSortSettings');
        }
        this.searchParameter = {
            type: -1,
            list: this.list,
            project: this.project,
            loadFrom: 0,
            loadQuantity: this.searchQuantity,
            sort,
            text: '',
            company: 0,
            contact: 0,
            site: 0,
            year: '',
            searchAssigned: '-1',
            dateFrom: '',
            dateTo: '',
            active,
            category: '-1',
            categoryType: '0',
            state_fk_id: '-1',
            fk_team_id: '-1',
            markedFilter: false
        };
        this.searchParameterFilter = {
            type: -1,
            project: 0,
            text: '',
            company: 0,
            contact: 0,
            site: 0,
            year: '',
            searchAssigned: '-1',
            dateFrom: '',
            dateTo: '',
            active,
            category: '-1',
            categoryType: '0',
            state_fk_id: '-1',
            fk_team_id: '-1'
        };
        this.searchParameter.company = 0;
        this.searchParameter.site = 0;
        this.searchParameter.contact = 0;
        // Fix to clear ContactManager
        setTimeout(() => {
            this.searchParameter.contact = '';
            setTimeout(() => {
                this.searchParameter.contact = 0;
            }, 200);
        }, 100);
    }

    isSearchActive() {
        return this.searchParameter.text !== '' ||
            this.searchParameter.company > 0 ||
            this.searchParameter.contact > 0 ||
            this.searchParameter.site > 0 ||
            this.searchParameter.year !== '' ||
            this.searchParameter.searchAssigned !== '-1' ||
            this.searchParameter.dateFrom !== '' ||
            this.searchParameter.dateTo !== '' ||
            this.searchParameter.active !== 1 ||
            this.searchParameter.category !== -1 ||
            this.searchParameter.state_fk_id !== '-1' ||
            this.searchParameter.fk_team_id !== '-1';

    }

    initMessageBox(): void {
        this.messageBox = {action: '', item: ''};
    }

    initNewEntry(): void {
        this.newEntry = {
            list: this.list,
            project: this.project,
            text: '',
            year: '',
            user: '',
            dateFrom: '',
            dateTo: ''
        };
    }

    initCheckboxActionDropdownData(): void {

        this.checkboxActionDropdownData = [];

        this.checkboxActionDropdownData.push({value: '1', name: 'Zusammenführen'});

        this.http.get<{ status, message }>(this.settings.restBaseUrl + 'user/right/Tickets/delete', this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.message === '1') {
                        this.checkboxActionDropdownData.push({ value: '2', name: 'Löschen'});
                        this.checkboxActionDropdownData.push({value: '3', name: 'Entwurf Entfernen'});
                    }
                }
            ));

        this.http.get<{ status, message }>(this.settings.restBaseUrl + 'user/right/Tickets/massClose', this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.message === '1') {
                        this.checkboxActionDropdownData.push({ value: '4', name: 'Tickets schließen'});
                    }
                }
            ));

        this.http.get<{ status, message }>(this.settings.restBaseUrl + 'user/right/Tickets/markSpam', this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.message === '1' && this.searchParameter.active != 5) {
                        this.checkboxActionDropdownData.push({ value: '5', name: 'Als Spam markieren'});
                    }
                    if (data.message === '1' && this.searchParameter.active == 5) {
                        this.checkboxActionDropdownData.push({ value: '6', name: 'Spam markierung aufheben'});
                    }
                }
            ));

    }

    public initFormData() {
        this.currentSaving = 0;
        this.toggleCC = 0;
        this.toggleBCC = 0;
        this.bccNameString = '';
        this.toNameString = '';
        this.toGroupString = '';
        this.ccNameString = '';

        this.initNewBCC();
        this.initNewTo();
        this.initNewGroup();
        this.initNewCC();

        this.formData = {
            clicked: 0,
            id: undefined,
            itemId: undefined,
            tid: undefined,
            mdi: 0,
            bcc: [],
            cc: [],
            to: [],
            company: undefined,
            site: undefined,
            contact: undefined,
            noMail: false,
            email: '',
            connectId: undefined,
            titel: '',
            text: '',
            attachment: [],
            type: 'team',
            editor: '',
            typ: undefined,
            time_duration: 0,
            fk_process_billing_type: undefined,
            attachmentsExist: false,
            receiptDate: '',
            costCentre: '',
            autoStartClock: true
        };
    }

    initProcessStartConditions(): void {
        this.processStartConditions = {project: '', fk_team_id: '', kategorie: '', state_fk_id: '', assigned: ''};
    }

    initWithdrawWorkdocumentSelect(): void {
        this.withdrawWorkdocumentSelect = {id: '', tid: '', titel: '', text: ''};
    }

    initBoardDefinition(): void {
        this.boardDefinition = [{name: 'Nächste Features', filter: '2 Nächste Features zu Besprechen', id: '97'},
            {name: 'In Entwicklung', filter: '3 In Entwicklung', id: '88'},
            {name: 'Testen', filter: '4 Testen', id: '98'},
            {name: 'Erledigt', filter: '5 Erledigt', id: '99'}];
    }

    onDrop(event: CdkDragDrop<any[], any>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        const sourcePrio = Number(this.data[event.currentIndex].priority_user);
        let destPrio     = Number(this.data[event.currentIndex-1].priority_user);
        if(sourcePrio > destPrio) { destPrio++; }
        // console.dir(event.item.data);
        // console.log(sourcePrio);
        // console.log(destPrio);
        for(let i=0;i < this.data.length;i++) {
            if(sourcePrio < destPrio) {
                // down
                if(Number(this.data[i].priority_user) > sourcePrio && Number(this.data[i].priority_user) <= destPrio) {this.data[i].priority_user = Number(this.data[i].priority_user) -1;}
                else if(Number(this.data[i].priority_user) == sourcePrio) {this.data[i].priority_user = destPrio;}
            } else  if(sourcePrio > destPrio) {
                // up
                if(Number(this.data[i].priority_user) == sourcePrio) {this.data[i].priority_user = destPrio;} else
                if(Number(this.data[i].priority_user) >= destPrio&& Number(this.data[i].priority_user) < sourcePrio ) {this.data[i].priority_user = Number(this.data[i].priority_user) +1;}
            }
        }
        this.http.put<any[]>( this.settings.restBaseUrl + 'process/priority' ,{'list': this.list,'source':sourcePrio,'dest':destPrio}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            () => {
            });
    }

    getFromText() {
        if(this.formData.project > 0) {
            // Projekt E-Mail Text ermitteln und zurückgeben
            const index = this.dropdownDataProject.findIndex((obj => obj.id === this.formData.project));
            if(!this.dropdownDataProject[index] || this.dropdownDataProject[index].email==null) { return 'Keine E-Mail senden'; } else { return '<b>' + this.dropdownDataProject[index].team_name + '</b> - ' + this.dropdownDataProject[index].email; }
        } else {
            // Team E-Mail Text ermitteln und zurückgeben
            const index = this.teams.findIndex((obj => obj.id === this.formData.fk_team_id));
            if(!this.teams[index] || this.teams[index].email==null) { return 'Keine E-Mail senden'; } else { return '<b>' + this.teams[index].name + '</b> - ' + this.teams[index].email; }
        }
    }

    private checkForTeamMailAndWarn(email) {
        if( email == this.teamMail.email) {
            this.editorWarningString = "Sie können die E-Mail Adresse <B>" + this.teamMail.email + "</B> <BR>nicht als Empfänger hinzufügen, da Sie über die gleiche Adresse versenden!";
            this.message.open('editorWarning');
            return true;
        }
        return false;
    }

}
