import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { UiModule } from '../ui/ui.module';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CoreBaseDataManagementComponent } from './core-base-data-management/core-base-data-management.component';
import { UserManagerComponent } from './user-manager/user-manager.component';
import { CoreRightModulUserRightComponent } from './core-right-modul-user-right/core-right-modul-user-right.component';
import { TextBlockComponent } from './text-block/text-block.component';
import { CoreLicenceManagementComponent } from './core-licence-management/core-licence-management.component';
import { CoreMailQueueComponent } from './core-mail-queue/core-mail-queue.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from '../_modal';
import { UrlframeComponent } from './urlframe/urlframe.component';
import { PipesModule } from '../pipes/pipes.module';
import { CoreUserProfileManagementComponent } from './core-user-profile-management/core-user-profile-management.component';
import { CoreHelpComponent } from './core-help/core-help.component';
import { CoreInformationComponent } from './core-information/core-information.component';
import { GroupManagerComponent } from './group-manager/group-manager.component';
import { CoreNoRightWarningComponent } from './core-no-right-warning/core-no-right-warning.component';
import { GlobalFavoriteComponent } from './core-global-favorite-management/core-global-favorite-management.component';
import { TemplateComponent } from './template/template.component';
import { TemplateCleanComponent } from './template-clean/template-clean.component';
import { CoreSessionComponent } from './core-session/core-session.component';
import { AngularSplitModule } from 'angular-split';



@NgModule({
  declarations: [
      LoginComponent,
      CompanyManagementComponent,
      CoreBaseDataManagementComponent,
      UserManagerComponent,
      CoreRightModulUserRightComponent,
      TextBlockComponent,
      CoreLicenceManagementComponent,
      CoreMailQueueComponent,
      CoreUserProfileManagementComponent,
      UrlframeComponent,
      CoreHelpComponent,
      CoreInformationComponent,
      GroupManagerComponent,
      CoreNoRightWarningComponent,
      GlobalFavoriteComponent,
      TemplateComponent,
      TemplateCleanComponent,
      CoreSessionComponent
  ],
    imports: [
        CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule, BrowserAnimationsModule, ModalModule, PipesModule,
        AngularSplitModule,
        ContextMenuModule
    ],
  exports: [
      LoginComponent
  ]
})
export class CoreModule { }
