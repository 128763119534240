<span id="{{overlayId}}Panel" class="form">

    <button (click)="toggleOverlay()"
            style="width:24px; min-width:24px;"
            tabindex="6">
        <img src="../design/icons/table_gear.png">
    </button>

    <div id="{{overlayId}}" class="shadow form" style="overflow: hidden;width:0px; height:0px; visibility:hidden; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
        <div style="border-bottom: 1px solid darkgrey;">
            <button (click)="setState(true)" style="margin:5px;width:70px;"><img src="../design/icons/accept.png"> Alle</button>
            <button (click)="setState(false)" style="margin:5px;width:70px;"><img src="../design/icons/accept_inactive.png"> Keine</button><br>
        </div>
        <div>
            <span *ngFor="let x of modelValue.model.currentValue | keys" >
                <div class="optionCheckbox">
                    <div>
                        <label *ngIf="!x.value.locked" style="display: block;">
                            <input type="checkbox" [(ngModel)]="x.value.state" (change)="optionChanged()" [ngModelOptions]="{standalone: true}"/> {{x.value.name}}
                        </label>
                        <label *ngIf="x.value.locked" style="display: block;">
                            <img src="../design/icons/lock.png" style="margin-left:2px; margin-right: 1px; "> {{x.value.name}}
                        </label>
                    </div>
                </div>
            </span>
        </div>
    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>
</span>
