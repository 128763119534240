<div style="display: inline-block;" style="width:100%; background: #0a36e9" *ngIf="!onlyIcon" class="form">
    <input type="text"
           id="suggestSearchBox{{overlayId}}"
           name="suggestSearchBox{{overlayId}}"
           style="width:calc(100% - 39px); float: left; margin:0; border-radius: 3px 0 0 3px !important; -moz-border-radius: 3px 0 0 3px !important; -webkit-border-radius: 3px 0 0 3px !important;  border-right: 1px solid lightgray !important;"
           autocomplete="new-password"
           [tabIndex]="settabindex"
           (focus)="activateSuggestSearch()"
           (keyup)="suggestSearchKeyUp($event)"
           (blur)="blurSuggestSearch($event)"
           [(ngModel)]="suggestSearchString"
           [ngClass]="{ 'contactManagerHideInput' : suggestSearchActive==false }">

    <div (click)="activateSuggestSearch()"
         (mouseover)="activateTooltip(displayTooltipContent)"
         (mouseout)="deactivateTooltip()"
         id="displayDiv{{overlayId}}"
         [ngClass]="{ 'error' : (markOnSet && (contact > 0 || company > 0 ) ) || mark, 'displayDiv' : true }"
         style="width:calc(100% - 39px); float: left; margin:0; " *ngIf="!suggestSearchActive"
         [innerHTML]="displayDivContent">
    </div>
    <button class="contactButton" style="float:left;height:24px; width:29px ; min-width: 29px; margin:0; background: #eeeeee !important; " (click)="toggleOverlay()" >
        <img src="../design/icons/vcard.png" style="width:22px;">
    </button>
</div>

<div id="{{domainOverlayId}}" *ngIf="showSuggestedDomain" class="shadow" style="overflow: auto; width: fit-content; height: fit-content; max-height: 155px; color: #000000; margin-top: 220px; margin-left: 175px; position: absolute; z-index: 777777; background-color: #ffffff; border: 1px solid #999999; min-width: 200px;">
    <table class="form_tabel light" style="width: 100%; height: 16px !important;" cellpadding="4" cellspacing="0">
        <tr>
            <th>
                Meinen Sie
            </th>
        </tr>
    </table>
    <table class="data_tabel" style="width: 100%; height: fit-content; margin-top: 0" cellpadding="4" cellspacing="0">
        <tr *ngFor="let x of suggestedDomain" (click)="quickSaveSelectDomain(x)">
            <td>
                <b>{{x.mail_domain}}</b>
                <br>{{x.name}}
            </td>
        </tr>
    </table>
</div>

<div id="{{nameOverlayId}}" *ngIf="showSuggestedName" class="shadow" style="width: fit-content; height: fit-content; max-height: 165px; color: #000000; margin-top: 172px; margin-left: 175px; position: absolute; z-index: 555; background-color: #ffffff; border: 1px solid #999999;min-width: 200px;">
    <div style="top: 0; left: 0; right: 0; height: 30px; position: absolute; ">
        <table class="form_tabel light" style="width: 100%;" cellpadding="4" cellspacing="0">
            <tr>
                <th colspan="2">
                    <span style="display: inline-block; margin-top: 5px; margin-left: 5px; ">Meinen Sie</span>
                    <span style="float: right;" class="form"><button (click)="closeQuickSaveSuggestNameOverlay()" style="margin-right: -1px; margin-top: 0px; width: 24px !important; min-width: 24px !important; max-width: 24px !important;"><img src="../design/icons/cross.png"></button></span>
                </th>
            </tr>
        </table>
    </div>
    <div style="margin-top: 30px; overflow-y: auto; width: fit-content; max-height: 135px;">
        <table class="data_tabel" style="width: 100%; height: fit-content; margin-top: 0" cellpadding="4" cellspacing="0">
            <tr *ngFor="let x of suggestedName" (click)="quickSaveSelectName(x)">
                <td>
                    {{x.salutationName}}&nbsp;<span *ngIf="x.titelName">{{x.titelName}}&nbsp;</span><b>{{x.first_name}}&nbsp;{{x.name}}</b>
                    <span *ngIf="x.company"><br>{{x.company}}</span><span *ngIf="!x.company"><br>(Privatperson)</span>
                    <span *ngIf="x.position"><br>{{x.position}}</span>
                </td>
            </tr>
        </table>
    </div>
</div>

<button class="round_button" (click)="toggleOverlay()" style="margin-right:5px; margin-top: 2px;" *ngIf="onlyIcon">
    <img src="../design/icons/vcard.png" style="margin-top:1px; height:20px; margin-left: 0px;">
</button>

<div class="shadow"
     id="tooltip{{overlayId}}"
     style="overflow: hidden;
     width:fit-content;
     height:0;
     visibility:hidden;
     position: absolute;
     z-index: 555;
-webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    border: 1px solid #ceced2 !important;
    background-color: #ffffff !important;
    color: #000 !important;
     margin-top: 23px; white-space: pre;"
><div style="width:100%; height:100%; margin-left: 10px;" [innerHTML]="toolTipContent" class="tooltipContent"></div>
</div>
<div id="{{overlayId}}" class="shadow" style="overflow: auto; visibility:hidden; color: #000000; position: fixed; z-index: 555; background-color: #ffffff; border: 1px solid #626161;margin-top: 24px; width:350px; max-height: calc( 60% + 15px );">
    <div *ngIf="showQuickSave" class="form" style="width: 100%; position: relative; font-size: 12px;">
        <table class="form_tabel light" style="width: 100%;" cellpadding="4" cellspacing="0">
            <tr>
                <th colspan="2" style="height:20px !important;">
                    <span style="display: inline-block; margin-left: 5px; ">
                        Kein Kontakt gefunden - Kontakt anlegen ?
                    </span>
                    <span style="float: right;" class="form">
                        <button (click)="closeQuickSaveOverlay()" style="margin-right: -1px; margin-top: 0px; width: 24px !important; min-width: 24px !important; max-width: 24px !important; margin-top: -4px; margin-bottom:-4px;"><img src="../design/icons/cross.png"></button>
                    </span>
                </th>
            </tr>
            <tr>
                <td colspan="2">
                    <table style="width:100%;">
                        <tr>
                            <td style="width:33%" class="form">
                                <button (click)="switchQuickInsertMode('public')" [ngClass]="{'error' : quickSaveData.type == 'public'}" > Privatkontakt</button>
                            </td>
                            <td style="width:33%" class="form">
                                <button (click)="switchQuickInsertMode('company')" [ngClass]="{'error' : quickSaveData.type == 'company'}" >Firmenkontakt</button>
                            </td>
                            <td style="width:33%" class="form">
                                <button (click)="switchQuickInsertMode('companyOnly')" *ngIf="(modeValue === 'free' && modeValue != 'email' && modeValue != 'phone' && showQuickSaveCompanySelected == false)" [ngClass]="{'error' : quickSaveData.type == 'companyOnly'}" >Firma</button>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr *ngIf="showContactData">
                <th colspan="2">
                    Kontaktdaten <span class="form quickSave"><button (click)="backToSelectCompany()" style="float: right;" *ngIf="quickSaveData.companyId > 0 && quickSaveData.siteId > 0">Neue Firma</button></span>
                </th>
            <tr>
            <tr *ngIf="showContactData">
                <td>
                    <span class="formCaption">Anrede*</span><br>
                    <select [(ngModel)]="quickSaveData.salutation"
                            class="cp-textbox"
                            style="width: calc(100% - 5px);"
                            tabindex="49" >
                        <option value="-1">Anrede Wählen</option>
                        <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                    </select>
                </td>
                <td>
                    <span class="formCaption" style="padding-left: 3px;">Titel</span><br>
                    <select [(ngModel)]="quickSaveData.title"
                            class="cp-textbox"
                            style="width: calc(100% - 5px); margin-left: 5px;"
                            tabindex="50" >
                        <option value="-1">Titel Wählen</option>
                        <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                    </select>
                </td>
            </tr>
            <tr *ngIf="showContactData">
                <td>
                    <span class="formCaption">Vorname</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.first_name" style="width: calc(100% - 12px);" tabindex="51">
                </td>
                <td>
                    <span class="formCaption" style="padding-left: 3px;">Name*</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.name" style="width: calc(100% - 12px); margin-left: 5px;" tabindex="52" (keyup)="checkExistingContact()" (blur)="closeExistingContactOverlay()">
                </td>
            </tr>
            <tr *ngIf="showContactData">
                <td>
                    <span class="formCaption">Telefon</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.phone" (blur)="closeExistingContactOverlay()" style="width: calc(100% - 12px);" tabindex="53">
                </td>
                <td>
                    <span class="formCaption" style="padding-left: 3px;">E-Mail</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.email" style="width: calc(100% - 12px); margin-left: 5px;" tabindex="54" (keyup)="checkExistingContact()" (blur)="closeExistingContactOverlay()">
                </td>
            </tr>
            <tr  *ngIf="showCompanyData">
                <th colspan="2">
                    Firmendaten <span class="form quickSave"><button (click)="backToSelectCompany()" style="float: right;" *ngIf="quickSaveData.companyId > 0 && quickSaveData.siteId > 0">Neue Firma</button></span>
                </th>
            </tr>
            <tr *ngIf="showCompanyData && !showQuickSaveCompanySelected">
                <td>
                    <span class="formCaption">Firma*</span><br>
                    <input autocomplete="new-password" type="text" id="companyNameUnselected"  [(ngModel)]="quickSaveData.company_name" (keyup)="suggestCompany($event)" style="width: calc(100% - 12px);" tabindex="55">
                    <div id="{{companyOverlayId}}" *ngIf="showCompanyOverlay" class="shadow" style="overflow: hidden; width: 200px; height:fit-content; max-height: 150px; color: #000000; z-index: 999999998; position: absolute; background-color: #ffffff; border: 1px solid #999999;">
                        <table class="form_tabel light" style="width: 100%; height: 16px !important;" cellpadding="4" cellspacing="0">
                            <tr>
                                <th>
                                    Meinen Sie
                                </th>
                            </tr>
                        </table>
                        <div style="overflow-x: auto; width: 100%; height:fit-content; max-height: 133px;">
                            <table class="data_tabel" style="width: 100%" cellspacing="0" cellpadding="4">
                                <tr *ngFor="let x of suggestedCompany" (click)="quickSaveSelectCompany(x)"><td><b>{{x.name}}</b></td></tr>
                            </table>
                        </div>
                    </div>
                </td>
                <td>
                    <span class="formCaption">Kurzname*</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.company_nickname" style="width: calc(100% - 12px); margin-left: 5px;" tabindex="56">
                </td>
            </tr>
            <tr *ngIf="showCompanyData && showQuickSaveCompanySelected">
                <td colspan="2">
                    <span class="formCaption">Firma*</span><br>
                    <input type="text" id="companyNameSelected" [(ngModel)]="quickSaveData.company_name" (keyup)="suggestCompany($event)" style="width: calc(100% - 12px);" tabindex="55">
                </td>
            </tr>
            <tr *ngIf="showCompanyData && showQuickSaveCompanySelected && quicksaveSiteData.length > 1">
                <td colspan="2">
                    <span class="formCaption">Standort</span><br>
                    <select [(ngModel)]="quickSaveData.siteId"
                            class="cp-textbox"
                            style="width: calc(100% - 5px);"
                            tabindex="57" >
                        <option *ngFor="let x of quicksaveSiteData" value="{{x.id}}">{{x.site_short_name}}</option>
                    </select>
                </td>
            </tr>
            <tr *ngIf="showCompanyData && !showQuickSaveCompanySelected">
                <td>
                    <span class="formCaption">Straße</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.street" style="width: calc(100% - 12px);" tabindex="59">
                </td>
                <td>
                    <span class="formCaption">Hausnr.</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.street_number" style="width: calc(100% - 12px); margin-left: 5px;" tabindex="60">
                </td>
            </tr>
            <tr *ngIf="showCompanyData && !showQuickSaveCompanySelected">
                <td>
                    <span class="formCaption">PLZ</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.postcode" style="width: calc(100% - 12px);" tabindex="61">
                </td>
                <td>
                    <span class="formCaption">Ort</span><br>
                    <input type="text" [(ngModel)]="quickSaveData.city" style="width: calc(100% - 12px); margin-left: 5px;" tabindex="62">
                </td>
            </tr>
            <tr>
                <td colspan="2" class="form">
                    <button (click)="quickSave()"
                            style="float: right; margin-top: 10px; position: relative;"
                            tabindex="63">
                        <img src="../design/icons/add.png"> Hinzufügen
                    </button>
                </td>
            </tr>
        </table>
    </div>


    <div *ngIf="suggestSearchResult && suggestSearchResult.length > 0" style="width:100%; position: relative; max-height: 500px; overflow-y: scroll;">
        <table class="data_tabel light" style="height:24px;position: fixed; width:350px;z-index: 9999999; margin-top: -6px !important;" cellpadding="2" cellspacing="0">
            <tr>
                <th>Suchergebnisse
                    <span *ngIf="suggestMode==1"> aus Favoriten</span>
                    <span *ngIf="suggestMode==2"> aus allen Kontakten</span>
                    <img *ngIf="suggestMode==2"
                         src="../design/icons/add.png"
                         style="float:right; margin-right:4px;"
                         (click)="activateQuickEdit()"
                        tooltip="Neue Kontakt anlegen">
                </th>
            </tr>
        </table>
        <table class="data_tabel" style="width:100%; position: relative; margin-top: 18px; " cellpadding="0" cellspacing="0">

        <tr *ngFor="let x of suggestSearchResult"
             id="suggestelement{{x.id}}"
             class="suggestSelectElement"
             [ngClass]="{'selected' : suggestKeyCurrentSelected == x}" >
            <td>
            <div style="width:34px; height:22px; float: right; margin-bottom:-40px; margin-top:4px; z-index: 999999; position:relative;">
                <span *ngIf="x.favorite > 0" class="form">
                    <button (mouseover)="$event.relatedTarget.focus()" tooltip="Favorit entfernen" style="min-width: 30px; width: 30px; float: right; margin-right: 4px; "  (click)="$event.stopPropagation();deleteFavouriteDialog(x)">
                        <img src="../design/icons/star.png">
                    </button>
                </span>
            </div>
        <div (click)="selectSuggestEntry(x)" style="width: calc( 100% - 38px );float: left; position: relative;">
            <div style="display:inline-block;width: calc(100% - 12px)"
                 [ngClass]="{
                     'contactGroup'     : x.groupId,
                     'contactContact'   : x.mark === 'P',
                     'contactCompany'   : x.mark === 'C',
                     'contactSite'      : x.mark === 'S'
                     }">
                <div >
                    <div style="margin:6px; overflow: hidden; ">
                        <span *ngIf="mode!='site' && mode!='company' && x.name || mode!='site' && mode!='company' && x.first_name">
                            <span>{{x.salutationName}} {{x.titelName}} {{x.first_name}} <b>{{x.name}}</b></span>
                            <span *ngIf="x.active == 0"><br>(Inaktiv)</span>
                            <span *ngIf="suggestViewAdditionalInformation=='phone' || mode == 'phone' || mode == 'free' && x.phone"><br>{{x.phone}}</span>
                            <span *ngIf="(suggestViewAdditionalInformation=='email' || mode=='email' || mode == 'free' && x.email) && x.type2 == 0 "><br>{{x.email}}</span>
                            <span *ngIf="(suggestViewAdditionalInformation=='email' || mode=='email' || mode == 'free' && x.email) && x.type2 == 1 "><br>{{x.email}}<img _ngcontent-hle-c139="" src="../design/icons/bullet_green.png" style="height: 12.5px;" class="ng-tns-c139-0 ng-star-inserted"></span>
                            <span *ngIf="x.site_name && x.site_short_name"><br><b>{{x.site_name}}</b></span>
                            <span *ngIf="x.companyId && x.companyId != 0 && !x.site_name && !x.site_short_name"><br><b>{{x.company}}</b></span>
                            <span *ngIf="x.companyId && x.companyId != 0 && x.site_name && x.site_short_name"><br>{{x.company}}</span>
                            <span *ngIf="x.companyId < 1"><br><i style="font-size: 7pt;">(Privatperson)</i></span>
                            </span>
                            <span *ngIf="mode=='site' || mode == 'free' && x.site_name && x.site_short_name && x.companyShortName && !x.name">
                            <b>{{x.site_name}}</b>
                        </span>
                    <span *ngIf="mode=='company' || mode == 'free' && x.fk_company_id && x.fk_company_id > 0 "><br>{{x.company}}</span>
                    <span *ngIf="mode == 'free' && x.type && x.fk_company_id && !x.first_name && !x.nickname && x.site_name && x.site_short_name"><br><i style="font-size: 7pt;">(Standort)</i></span>
                    <span *ngIf="mode == 'free' && !x.first_name && x.nickname && !x.site_name && !x.site_short_name"><br><i style="font-size: 7pt;">(Firma)</i></span>
                    <span *ngIf="x.groupId"><br><i style="font-size: 7pt;">(Empfängergruppe)</i></span>
                </div>
                </div>
            </div>
        </div>
            </td>
        </tr>
        </table>

    </div>
    <div *ngIf="suggestSearchResult!='' && suggestMode==1 && showQuickSave==false" class="form" style="width:100%;height:100%; position: relative; font-size: 12px;">
        <button style="margin:5px; width:calc( 100% - 10px) ;" (click)="suggestSearchAll()">Alle Kontakte durchsuchen</button>
    </div>

    <div *ngIf="showQuickSave == false && suggestSearchResult=='' && suggestMode==1" class="form" style="width:100% ; height:100%; position: relative; font-size: 12px;">
        <table class="data_tabel light" style="height:22px;width:350px;z-index: 9999999; " cellpadding="4" cellspacing="0" >
            <tr>
                <th>Hinweis</th>
            </tr>
        </table>
        <div style="margin-left: 20px; margin-top: 20px; margin-right:20px; display: inline-block;" class="form">
            Es konnte kein Kontakt in den Favoriten gefunden werden.<BR>
            <button style="float: right; margin-bottom: 15px;" (click)="suggestSearchAll()">Alle Kontakte durchsuchen</button>
        </div>
    </div>


</div>
<div *ngIf="showExistingContactDropdown && showQuickSave" id="existingContactOverlayQuicksave" class="shadow" style="left: 250px;right: 0; top: 153px; width: 350px; height: fit-content; max-height: 200px; color: #000000; position: relative; overflow: auto; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">

    <div style="height:22px;">
        <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
            <tr>
                <th>
                    Bitte Prüfen Sie, ob es den Kontakt bereits gibt!
                </th>
            </tr>
        </table>
    </div>
    <div style="height:178px; overflow: auto;">
        <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
            <tr *ngFor="let x of existingContact" (click)="openExistingContact($event, x)">
                <td class="error">{{x.name}}, {{x.first_name}} <span [hidden]="x.favorite == null"><img src="../design/icons/star.png" style="float: right; margin-right:30px;"></span><br>
                    <b *ngIf="x.companyId!='0'">{{x.company}}</b><i *ngIf="!x.companyId" style="font-size: 7pt;">Privatperson</i><br>
                    <span *ngIf="x.email">E-Mail : {{x.email}} <br></span>
                    <span *ngIf="x.phone">Telefon: {{x.phone}} </span>
                </td>
            </tr>
        </table>
    </div>

</div>
<DIV class="contactManager" style="z-index:9999999;display: inline-block; vertical-align: middle; margin-bottom: 1px; margin-right: -1px;" id="{{overlayId}}Panel" *ngIf="OverlayActive">
    <DIV class="contactManagerOverlayOpen" >
        <div class="topContainer form">
            <input (keyup)="searchKeyUp($event)" [(ngModel)]="contactManagerSearch.text" type="text" style="width:130px; margin-top:10px !important; margin-left: 10px;" id="contactManagerSearch" name="contactManagerSearch" autocomplete="off">
            <select [attr.disabled]="showAssignContact ? '' : null"
                    [(ngModel)]="contactManagerSearch.active"
                    style="width:84px; margin-top: 10px !important;">
                <option value="1">Aktive</option>
                <option value="0">Archivierte</option>
                <option value="-1">Alle</option>
            </select>
            <select (change)="tagSearchChanged()"
                    [(ngModel)]="contactManagerSearch.tag"
                    style="width:110px;">
                <option value="0">Kein Tag</option>
                <option *ngFor="let x of contactTag" value="{{x.id}}">{{x.name}}</option>
            </select>
            <button (click)="resetSearch()"
                    style="min-width:24px !important;width:24px;height:30px; margin-top:10px"
                    tabindex="4">
                <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
            </button>

            <button style="margin-left:2px; margin-top:10px; min-width: 80px;" (click)="search()" ><img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen</button>

            <button (click)="activateView(1)" style=" margin-left:30px; margin-top:10px; min-width:24px; width:24px;"
                    tooltip="In die Suchansicht wechseln"
                    [options]="{'placement': 'bottom'}"
                    [ngClass]="{'buttonActivated': viewMode === 1, 'buttonDeactivated': viewMode !== 1 } ">
                <img src="../design/icons/layout_sidebar.png" style="margin:0 0 0 -1px; " >
            </button>
            <button (click)="activateView(2)" style=" margin-left:2px; margin-top:10px; min-width:24px; width:24px;"
                    tooltip="In die Listenansicht wechseln"
                    [options]="{'placement': 'bottom'}"
                    [ngClass]="{'buttonActivated': viewMode === 2, 'buttonDeactivated': viewMode !== 2 } ">
                <img src="../design/icons/table.png" style="margin:0 0 0 -1px; ">
            </button>

            <button (click)="activateView(3)" style=" margin-left:2px; margin-top:10px; min-width:24px; width:24px;"
                    tooltip="Empfängergruppen verwalten"
                    [options]="{'placement': 'bottom'}"
                    [ngClass]="{'buttonActivated': viewMode === 3, 'buttonDeactivated': viewMode !== 3 } ">
                <img src="../design/icons/group.png" style="margin-left: -2px;">
            </button>

            <button (click)="showFavorites()" style=" margin-left:2px; margin-top:10px; min-width:24px; width:24px;"
                    tooltip="Favoriten anzeigen">
                <img src="../design/icons/star.png" style="margin-left: -1px;">
            </button>

            <button (click)="add()" style=" position:absolute; left:calc(50% - 45px); margin-top:10px;text-align:  left; min-width: 90px;">
                <img src="../design/icons/add.png" style="margin-left:3px; margin-right:5px; "> Neu <img src="../design/icons/sort_arrow_down.png" style="margin-right:-3px; float: right;border-left: 1px solid gray;">
            </button>

            <button (click)="deactivateOverlay()" style="float: right; margin-right:10px; margin-top:10px; min-width:24px; width:24px;">
                <img src="../design/icons/cross.png" style="margin:0 0 0 -1px; ">
            </button>

            <button (click)="applySelectedValues()" style="float: right; margin-right:10px; margin-top:10px; min-width:110px; width:110px;" *ngIf="!onlyIcon">
                <img src="../design/icons/accept.png" style="margin:0 0 0 -1px; "> Übernehmen
            </button>

            <div *ngIf="showNewItemDropdown" style="position: fixed; left:0; right:0; bottom:0; top:0; z-index: 99;" (click)="add()">
                <div class="shadow" style="left:calc(50% - 100px); margin-top:36px;width: 200px; height: fit-content; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
                    <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
                        <tr tooltip="Eine neue Firma anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="selectNewItemType('addNewCompany')">Firma</td>
                        </tr>
                        <tr tooltip="Eine neue Privatperson anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="selectNewItemType('AddNewContact')">Privatkontakt</td>
                        </tr>
                        <tr tooltip="Eine neue Empfängergruppe anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="newContactGroup()">Empfängergruppe</td>
                        </tr>
                        <tr>
                            <th>
                                zur aktuellen Firma
                            </th>
                        </tr>

                        <tr tooltip="Einen neuen Kontakt zur aktuell ausgewählten Firma anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentCompany('addContactToCompany')">Kontakt hinzufügen</td>
                        </tr>
                        <tr tooltip="Einen neuen Standort zur aktuell ausgewählten Firma anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentCompany('addSiteToCompany')">Standort hinzufügen</td>
                        </tr>
                        <tr>
                            <th>
                                zu aktuellem Kontakt
                            </th>
                        </tr>
                        <tr tooltip="Eine Telefonnummer zum aktuell ausgewählten Kontakt anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentContact('connectionPhonenumber')">Telefonnummer hinzufügen</td>
                        </tr>
                        <tr>
                            <td (click)="addInfoToCurrentContact('connectionFaxnumber')">Faxnummer hinzufügen</td>
                        </tr>
                        <tr tooltip="Eine E-Mail Adresse zum aktuell ausgewählten Kontakt anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentContact('connectionEmail')">E-Mail hinzufügen</td>
                        </tr>
                        <tr tooltip="Eine Mobilnummer zum aktuell ausgewählten Kontakt anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentContact('connectionMobilenumber')">Mobilnummer hinzufügen</td>
                        </tr>
                        <tr tooltip="Eine Adresse zum aktuell ausgewählten Kontakt anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentContact('connectionAddress')">Adresse hinzufügen</td>
                        </tr>
                        <tr tooltip="Eine andere Information zum aktuell ausgewählten Kontakt anlegen"
                            [options]="{'placement': 'right'}">
                            <td (click)="addInfoToCurrentContact('connectionOthers')">Information hinzufügen</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="showExistingContactDropdown && showNewItemContactForm" id="existingContactOverlayNewContactForm" class="shadow" style="left: 593px; top: 138px; width: 250px; height: fit-content; max-height: 200px; color: #000000; position: absolute; overflow: auto; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
            <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
                <tr *ngFor="let x of existingContact" (click)="openExistingContact($event, x)">
                    <td>{{x.name}}, {{x.first_name}} <span [hidden]="x.favorite == null"><img src="../design/icons/star.png" style="float: right;"></span><br>
                        <b *ngIf="x.companyId!='0'">{{x.company}}</b><b *ngIf="!x.companyId">Privatperson</b><br>
                        <span *ngIf="x.email">E-Mail : {{x.email}} <br></span>
                        <span *ngIf="x.phone">Telefon: {{x.phone}} </span>
                    </td>
                </tr>
            </table>
        </div>

        <div *ngIf="showExistingContactDropdown && showCompanyAddContact" id="existingContactOverlayAddContactForm" class="shadow" style="right: 38px; top: 138px; width: 350px; height: fit-content; max-height: 200px; color: #000000; position: absolute; overflow: auto; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
            <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
                <tr *ngFor="let x of existingContact" (click)="openExistingContact($event, x)">
                    <td>{{x.name}}, {{x.first_name}} <span [hidden]="x.favorite == null"><img src="../design/icons/star.png" style="float: right;"></span><br>
                        <b *ngIf="x.companyId != null && x.companyId!='0'">{{x.company}}</b><b *ngIf="x.companyId == null || !x.companyId">Privatperson</b><br>
                        <span *ngIf="x.email">E-Mail : {{x.email}} <br></span>
                        <span *ngIf="x.phone">Telefon: {{x.phone}} </span>
                    </td>
                </tr>
            </table>
        </div>
        <span *ngIf="viewMode==1">
        <div class="leftContainer">

            <div class="leftContainerDefinition header1Row footer1Row" *ngIf="showLeft1Column">
                <div class="freeContainerTop" style="height: 32px !important;">
                    <div *ngIf="showNewItemCompanyForm && !companyFromContact" style="margin-top: 8px; margin-left: 10px; font-size: 14px;" >Neue Firma anlegen</div>
                    <div *ngIf="showNewItemCompanyForm && companyFromContact" style="margin-top: 8px; margin-left: 10px; font-size: 14px;" >Kontakt in eine Firma wandeln</div>
                    <div *ngIf="showNewItemContactForm" style="margin-top: 8px; margin-left: 10px; font-size: 14px; ">Neuen Kontakt anlegen</div>
                    <div *ngIf="showAssignToCompany && currentSelectedContactData.company == 0" style="margin-top: 5px; margin-left: 10px; font-size: 14px;" >Den Kontakt einer Firma zuweisen</div>
                    <div *ngIf="showMergeContact" style="margin-top: 8px; margin-left: 10px; font-size: 14px;" >Suchen Sie hier den Zielkontakt mit dem Sie zusammenführen möchten</div>
                </div>

                <div id="leftContainerContent" style="top: 32px !important; background-color: #F5F5F5;">
                    <div *ngIf="showAssignContact || showAssignToCompany" style="top: 0; left: 0; right: 0; height: 20px; position: absolute;">
                        <table cellspacing="0" cellpadding="4" class="data_tabel" style="width: 100%; margin-top:-6px;">
                            <tr>
                                <th>Name</th>
                                <th style="width: 320px;">Standort</th>
                                <th style="width: 130px;">Ansprechpartner</th>
                                <th style="width: 130px;">Ort</th>
                            </tr>
                        </table>
                    </div>

                    <div style="top: 0; left: 0; right: 0; bottom: 0; position: absolute; overflow: auto !important;">

                    <span *ngIf="showAssignToCompany && assignableSites!=null">
                        <table *ngIf="assignableSites" cellspacing="0" cellpadding="4" class="data_tabel" style="width: 100%;margin-top:0px;">
                            <tr *ngFor="let x of assignableSites" (click)="selectCompanyToAssign(x)" (dblclick)="dblClickSelectCompanyToAssign(x)" [ngClass]="{'selected' : siteIdToAssignContact == x.site_id && currentSelectedCompanyId != null && currentSelectedCompanyId == x.company_id }">
                                <td >&nbsp;{{x.name}}</td>
                                <td style="width: 320px;">{{x.site_name}}<span *ngIf="x.site_name==null">Firma hat nur einen Standort</span></td>
                                <td style="width: 130px;">{{x.supporter}}</td>
                                <td style="width: 130px;">{{x.city}}</td>
                            </tr>
                        </table>
                    </span>

                    <div *ngIf="showMergeContact" style="width: 100%;margin-top:0px;">
                        <table cellspacing="0" cellpadding="4" class="data_tabel" style="width: 100%; margin-top:-6px;">
                            <tr>
                                <th style="width: 200px;">Name</th>
                                <th style="width: 200px;">Firma</th>
                            </tr>
                        </table>
                    </div>

                         <div *ngIf="showMergeContact || showAssignToCompany && (assignableSites!= undefined && assignableSites.length < 1 && contactManagerSearch.text == '' && contactManagerSearch.tag == 0)" style="border-bottom: 1px solid darkgray; width:calc( 100% - 3px );" class="error">
                            <div style="margin:15px; display: inline-block; ">
                                Nutzen Sie bitte die Suche oben links um nach der Firma in die Sie den Kontakt verschieben wollen zu suchen.
                            </div>
                        </div>
                        <div *ngIf="currentSelectedContact != null && currentSelectedContact.companyId>0" style="width:calc( 100% - 3px );border-bottom:1px solid darkgray;" class="error" >
                            <div style="margin:15px; display: inline-block;">
                                Da der Benutzer der Firma <B>{{currentSelectedContact.company}}</B> zugewiesen ist, <BR>
                                kann er nur mit einen Kontakt der gleichen Firma zusammengeführt werden! <BR>
                                Daher ist die Suche automatisch auf diese Firme begrenzt.
                            </div>
                        </div>
                        <table *ngIf="showMergeContact" cellspacing="0" cellpadding="4" class="data_tabel" style="width: 100%;margin-top:0px;">
                            <tr *ngFor="let x of assignableSites" (click)="selectContactToMerge(x)" (dblclick)="dblClickselectContactToMerge(x)"  [ngClass]="{'selected' : mergeContactId == x.id}">
                                 <td style="width:20px;">
                                    <span *ngIf="x.login==0" style="float: right;" (click)="userAction(1,x)">
                                        <img tooltip="Kontakt hat ein deaktiviertes Anmeldekonto." src="../design/icons/user_deactivated.png">
                                    </span>
                                    <span *ngIf="x.login==1" style="float: right;" (click)="userAction(2,x)">
                                        <img tooltip="Kontakt hat ein Anmeldekonto." src="../design/icons/user.png">
                                    </span>
                                    <span *ngIf="usermanagmentRight == true && x.login != 0 && x.login != 1" (click)="userAction(3,x)">
                                        <img tooltip="Kontakt hat kein Anmeldekonto." src="../design/icons/add.png">
                                    </span>
                                </td>
                                <td>&nbsp;
                                    {{x.name}}, {{x.first_name}}
                                </td>
                                <td style="width: 40%x;">
                                    {{x.companyName}}
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div *ngIf="showNewItemCompanyForm" style="left: 0; right: 0; top: 0; bottom: 0; position: absolute; overflow: auto;">
                    <table class="form" style="width: calc(100% - 30px); margin-top: 5px; margin-left: 10px;" cellspacing="0" cellpadding="8">
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input [(ngModel)]="companyContentData.name"
                                       type="text"
                                       id="newCompanyName"
                                       (keyup)="suggestCompaney($event)"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >


                        <div id="{{companyOverlayId}}" *ngIf="showCompanyOverlay" class="shadow" style="position: absolute; width: 48%;">
                        <table class="form_tabel light" style="width: 100%; height: 16px !important;" cellpadding="4" cellspacing="0">
                            <tr>
                                <th>
                                    Vorhandene Firmen:
                                </th>
                            </tr>
                        </table>
                        <div style="overflow-x: auto; width: 100%; height:fit-content; max-height: 133px;">
                            <table class="data_tabel" style="width: 100%" cellspacing="0" cellpadding="4">
                                <tr *ngFor="let x of suggestedCompany" (click)="assignContactToCompanyDialogFromCompany(x.id)"><td><b>{{x.name}}</b></td></tr>
                            </table>
                        </div>
                    </div></td>
                            <td>
                                <span class="formCaption">Kurzname *</span>
                                <input [(ngModel)]="companyContentData.nickname"
                                       type="text"
                                       id="newCompanyNickName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="102" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Kundennummer</span>
                                <input [(ngModel)]="companyContentData.customer_id"
                                       type="text"
                                       id="newCompanyCustomerId"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="105" >
                            </td>
                            <td>
                                <span class="formCaption">Bemerkung</span>
                                <input [(ngModel)]="companyContentData.notice"
                                       type="text"
                                       id="newCompanyNotice"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="106" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <table cellspacing="0" cellpadding="0">
                                <tr>
                                    <td style="width: 80%;">
                                        <span class="formCaption">Straße</span>
                                        <input [(ngModel)]="companyContentData.street"
                                               type="text"
                                               id="newCompanyStreet"
                                               class="cp-textbox"
                                               style="width:95%;"
                                               tabindex="107" >
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="formCaption">Hausnr.</span>
                                        <input [(ngModel)]="companyContentData.street_number"
                                               type="text"
                                               id="newCompanyStreetNumber"
                                               class="cp-textbox"
                                               style="width:100%;"
                                               tabindex="108" >
                                    </td>
                                </tr>
                            </table>
                            </td>
                            <td>
                            <table cellspacing="0" cellpadding="0">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="formCaption">PLZ</span>
                                        <input [(ngModel)]="companyContentData.postcode"
                                               type="text"
                                               id="newCompanyPostcode"
                                               class="cp-textbox"
                                               style="width:100%;"
                                               tabindex="109" >
                                    </td>
                                    <td style="width: 80%;">
                                        <span class="formCaption" style="padding-left: 15px;">Ort</span>
                                        <input [(ngModel)]="companyContentData.city"
                                               type="text"
                                               id="newCompanyCity"
                                               class="cp-textbox"
                                               style="width:calc(100% - 15px); margin-left: 15px;"
                                               tabindex="110" >
                                    </td>
                                </tr>
                            </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Kreditoren-Nummer</span>
                                <input [(ngModel)]="companyContentData.receivable_accounts"
                                       type="text"
                                       id="newCompanyReceivableAccounts"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="111" >
                            </td>
                            <td>
                                <span class="formCaption">Debitoren-Nummer</span>
                                <input [(ngModel)]="companyContentData.payable_accounts"
                                       type="text"
                                       id="newCompanyPayableAccounts"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="112" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 50%;">
                                            <span class="formCaption">Sollkonto 1</span>
                                            <select style="width:100%;" [(ngModel)]="companyContentData.bookAccount1" tabindex="113">
                                                <option value="-1">Konto wählen</option>
                                                <option *ngFor="let account of bookAccountsList" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                            </select>
                                        </td>
                                        <td style="width: 50%;">
                                            <span class="formCaption" style="padding-left: 8px;">Sollkonto 2</span>
                                            <select style="width:100%; margin-left: 7px;" [(ngModel)]="companyContentData.bookAccount2" tabindex="114">
                                                <option value="-1">Konto wählen</option>
                                                <option *ngFor="let account of bookAccountsList" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 50%;">
                                            <span class="formCaption">Sollkonto 3</span>
                                            <select style="width:100%;" [(ngModel)]="companyContentData.bookAccount3" tabindex="115">
                                                <option value="-1">Konto wählen</option>
                                                <option *ngFor="let account of bookAccountsList" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                            </select>
                                        </td>
                                        <td style="width: 50%;">
                                            <span class="formCaption" style="padding-left: 8px;">Sollkonto 4</span>
                                            <select style="width:100%; margin-left: 7px;" [(ngModel)]="companyContentData.bookAccount4" tabindex="116">
                                                <option value="-1">Konto wählen</option>
                                                <option *ngFor="let account of bookAccountsList" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table *ngIf="!companyFromContact" class="form" style="width: calc(100% - 30px); margin-top: 15px; margin-left: 10px;" cellspacing="0" cellpadding="8">
                        <tr>
                            <td style="width: 50%;">
                                 <span style="text-align: left; font-size: 16px; font-weight: bold;">
                                    Erster Kontakt zu dieser Firma (optional)
                                 </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Anrede *</span>
                                            <select [(ngModel)]="contactContentData.salutation"
                                                    id="newCompanyContactSalutation"
                                                    class="cp-textbox"
                                                    style="width: calc(100% - 8px);"
                                                    tabindex="117" >
                                                <option value="-1">Anrede Wählen</option>
                                                <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <span class="formCaption">Titel</span>
                                            <select [(ngModel)]="contactContentData.title"
                                                    id="newCompanyContactTitle"
                                                    class="cp-textbox"
                                                    style="width: calc(100% + 6px); margin-left: 1px;"
                                                    tabindex="118" >
                                                <option value="-1">Titel Wählen</option>
                                                <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                               <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Vorname</span>
                                            <input [(ngModel)]="contactContentData.first_name"
                                                   type="text"
                                                   id="newCompanyContactFirstName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px);"
                                                   tabindex="119" >
                                        </td>
                                        <td>
                                            <span class="formCaption" style="padding-left: 8px;">Name *</span>
                                            <input [(ngModel)]="contactContentData.name"
                                                   (keyup)="checkExistingContact()"
                                                   (blur)="closeExistingContactOverlay()"
                                                   type="text"
                                                   id="newCompanyContactName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px); margin-left: 8px;"
                                                   tabindex="120" >
                                             <div *ngIf="showExistingContactDropdown && showNewItemCompanyForm" id="existingContactOverlayNewCompanyForm" class="shadow" style="margin-left:-123px; margin-top:2px; width: 350px; height: fit-content; max-height: 500px; color: #000000; position: fixed; overflow: auto; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
                                                <table class="data_tabel light" style="width: 100%; font-size: 10pt; margin: 0;" cellspacing="0" cellpadding="4">
                                                    <tr *ngFor="let x of existingContact">
                                                        <td>{{x.name}}, {{x.first_name}}  <span style="float: right;" *ngIf="x.favorite != null"><img src="../design/icons/star.png"></span><br>
                                                            <b *ngIf="x.companyId!='0'">{{x.company}}</b><b *ngIf="!x.companyId">Privatperson</b><br>
                                                                <span *ngIf="x.email">E-Mail : {{x.email}} <br></span>
                                                                <span *ngIf="x.phone">Telefon: {{x.phone}} </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                         </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">E-Mail</span>
                                <input [(ngModel)]="contactContentData.email"type="text"
                                       (keyup)="checkExistingContact()"
                                       (blur)="closeExistingContactOverlay()"
                                       id="newCompanyContactEmail"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="121" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.phoneBusiness"
                                        type="text"
                                        id="newCompanyContactPhoneBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="122" >
                            </td>
                            <td>
                                 <span class="formCaption">Fax geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.faxBusiness"
                                        type="text"
                                        id="newCompanyContactFaxBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="123" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon privat</span>
                                 <input [(ngModel)]="contactContentData.phonepublic"
                                        type="text"
                                        id="newCompanyContactPhonepublic"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="124" >
                            </td>
                            <td>
                                <span class="formCaption">Telefon mobil</span>
                                <input [(ngModel)]="contactContentData.phoneMobile"
                                       type="text"
                                       id="newCompanyContactPhoneMobile"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="125" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 80%;">
                                            <span class="formCaption">Straße</span>
                                            <input [(ngModel)]="contactContentData.street"
                                                   type="text"
                                                   id="newCompanyContactStreet"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="126" >
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="formCaption">Hausnr.</span>
                                            <input [(ngModel)]="contactContentData.street_number"
                                                   type="text"
                                                   id="newCompanyContactStreetNumber"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="127" >
                                        </td>
                                    </tr>
                                 </table>
                            </td>
                             <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 30%;">
                                            <span class="formCaption">PLZ</span>
                                            <input [(ngModel)]="contactContentData.postcode"
                                                   type="text"
                                                   id="newCompanyContactpostcode"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="128" >
                                        </td>
                                        <td style="width: 70%;">
                                            <span class="formCaption">Ort</span>
                                            <input [(ngModel)]="contactContentData.city"
                                                   type="text"
                                                   id="newCompanyContactCity"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="129" >
                                       </td>
                                    </tr>
                                 </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Position</span>
                                <input [(ngModel)]="contactContentData.position"
                                       type="text"
                                       id="newCompanyContactPosition"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="130" >
                            </td>
                            <td>
                                <span class="formCaption">Beruf</span>
                                <input [(ngModel)]="contactContentData.profession"
                                       type="text"
                                       id="newCompanyContactProfession"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="131" >
                            </td>
                        </tr>
                    </table>
                    </div>

                    <div *ngIf="showNewItemContactForm" style="left: 0; right: 0; top: 0; bottom: 0; position: absolute; overflow: auto;">
                     <table class="form" style="width: calc(100% - 30px); margin-top: 15px; margin-left: 10px;" cellspacing="0" cellpadding="8">
                        <tr>
                            <td style="width: 50%;">
                                <table style="width: 100%;" cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Anrede *</span>
                                            <select [(ngModel)]="contactContentData.salutation"
                                                    id="newContactSalutation"
                                                    class="cp-textbox"
                                                    style="width: calc(100% - 8px);"
                                                    tabindex="115" >
                                                <option value="-1">Anrede Wählen</option>
                                                <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <span class="formCaption">Titel</span>
                                            <select [(ngModel)]="contactContentData.title"
                                                    id="newContactTitle"
                                                    class="cp-textbox"
                                                    style="width: calc(100% + 6px); margin-left: 1px;"
                                                    tabindex="116" >
                                                <option value="-1">Titel Wählen</option>
                                                <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="width: 50%;">
                               <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Vorname</span>
                                            <input [(ngModel)]="contactContentData.first_name"
                                                   type="text"
                                                   id="newContactFirstName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px);"
                                                   tabindex="117" >
                                        </td>
                                        <td>
                                            <span class="formCaption" style="padding-left: 8px;">Name *</span>
                                            <input [(ngModel)]="contactContentData.name"
                                                   (keyup)="checkExistingContact()"
                                                   (blur)="closeExistingContactOverlay()"
                                                   type="text"
                                                   id="newContactName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px); margin-left: 8px;"
                                                   tabindex="118" >
                                         </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">E-Mail</span>
                                <input [(ngModel)]="contactContentData.email"
                                       (keyup)="checkExistingContact()"
                                       (blur)="closeExistingContactOverlay()"
                                       type="text"
                                       id="newContactEmail"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="119" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.phoneBusiness"
                                        type="text"
                                        id="newContactPhoneBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="120" >
                            </td>
                            <td>
                                 <span class="formCaption">Fax geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.faxBusiness"
                                        type="text"
                                        id="newContactFaxBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="121" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon privat</span>
                                 <input [(ngModel)]="contactContentData.phonepublic"
                                        type="text"
                                        id="newContactPhonepublic"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="122" >
                            </td>
                            <td>
                                <span class="formCaption">Telefon mobil</span>
                                <input [(ngModel)]="contactContentData.phoneMobile"
                                       type="text"
                                       id="newContactPhoneMobile"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="123" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 80%;">
                                            <span class="formCaption">Straße</span>
                                            <input [(ngModel)]="contactContentData.street"
                                                   type="text"
                                                   id="newContactStreet"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="124" >
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="formCaption">Hausnr.</span>
                                            <input [(ngModel)]="contactContentData.street_number"
                                                   type="text"
                                                   id="newContactStreetNumber"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="125" >
                                        </td>
                                    </tr>
                                 </table>
                            </td>
                             <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 30%;">
                                            <span class="formCaption">PLZ</span>
                                            <input [(ngModel)]="contactContentData.postcode"
                                                   type="text"
                                                   id="newContactpostcode"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="126" >
                                        </td>
                                        <td style="width: 70%;">
                                            <span class="formCaption">Ort</span>
                                            <input [(ngModel)]="contactContentData.city"
                                                   type="text"
                                                   id="newContactCity"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="127" >
                                       </td>
                                    </tr>
                                 </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Position</span>
                                <input [(ngModel)]="contactContentData.position"
                                       type="text"
                                       id="newContactPosition"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="128" >
                            </td>
                            <td>
                                <span class="formCaption">Beruf</span>
                                <input [(ngModel)]="contactContentData.profession"
                                       type="text"
                                       id="newContactProfession"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="129" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="form" style="margin-top: 15px;">
                                <button (click)="checkSaveNewPublicContact()" style="width: 100px; float: right; margin-right: -8px;" tabindex="138">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>

                                <button (click)="newItemBack()" style="width: 105px; float: right; margin-right: 10px;" tabindex="137">
                                    <img src="../design/icons/cross.png"> Abbrechen
                                </button>
                                </div>
                            </td>
                        </tr>

                    </table>
                    </div>
                </div>


               <div class="freeContainerBottom">

                    <div class="form" *ngIf="showNewItemCompanyForm" style="width: 100%; text-align: right; margin-top:2px;">
                        <button (click)="newItemBack()" tabindex="132">
                            <img src="../design/icons/cross.png"> Abbrechen
                        </button>
                        <button (click)="checkSaveNewCompany()"  tabindex="133">
                            <img src="../design/icons/disk.png"> Speichern
                        </button>
                    </div>

                    <div class="form" *ngIf="showMergeContact" style="width: 100%; text-align: right; margin-top:2px;">
                        <button (click)="mergeContactBack()">
                            <img src="../design/icons/cross.png"> Abbrechen
                        </button>
                        <button (click)="mergeContactQuestion()">
                            <img src="../design/icons/disk.png"> Zusammenführen
                        </button>
                    </div>

                    <div class="form" *ngIf="showAssignToCompany" style="width: 100%; text-align: right; margin-top:2px;">
                        <button (click)="assignContactBack()">
                            <img src="../design/icons/cross.png"> Abbrechen
                        </button>
                        <button (click)="assignContactToCompanyDialog()">
                            <img src="../design/icons/disk.png"> Zuweisen
                        </button>
                    </div>
               </div>

            </div>

            <div style="position: absolute; top:0; left:0;right:0; height:230px;" *ngIf="!showLeft1Column" [ngClass]="{'leftBigTop' : editCompany || editSite}">
                <div class="freeContainerDefinition" *ngIf="!showLeft1Column" id="123">
                    <span class="header1Row footerNone">
                    <div class="freeContainerTop" *ngIf="!showLeft1Column">

                        <span *ngIf="currentSelectedCompanyId>0 && editTab == 0" style=" display:inline-block;margin-left:10px; margin-top:3px;">
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedCompanyTab == 0 }" style="margin-right:10px;" (click)="changeCompanyTab(0)">Gesamtübersicht</button>
                            <span *ngIf="currentSelectedCompanyData.address.length > 1">
                            <button *ngFor="let x of currentSelectedCompanyData.address" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedCompanyTab == x.id }" style="margin-right:5px;" (click)="changeCompanyTab(x.id)">{{x.site_short_name}}</button>
                            </span>
                        </span>

                        <span *ngIf="currentSelectedCompanyId>0 && editTab > 0 && editCompanyDomains == 1" style=" display:inline-block;margin-left:10px; margin-top:3px;">
                            <button [ngClass]="{'tab' : true , 'tab_selected' : editTab == 1 }" style="margin-right:5px;" (click)="changeCompanyEditTab(1)">Grunddaten</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : editTab == 2 }" style="margin-right:5px;" (click)="changeCompanyEditTab(2)">Kundendaten</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : editTab == 3 }" style="margin-right:5px;" (click)="changeCompanyEditTab(3)">Händlerdaten</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : editTab == 4 }" style="margin-right:5px;" (click)="changeCompanyEditTab(4)">Firmendomains</button>
                         </span>

                        <table class="data_tabel" cellpadding="4" cellspacing="0" style="width:100%; margin-top: 12px;" *ngIf="!currentSelectedContact">
                            <tr>
                                <th>Gefundene Firmen</th>
                                <th style="width:150px">Kundennummer</th>
                                <th style="width:200px">Ansprechpartner</th>
                                <th style="width:200px">Telefonnummer</th>
                            </tr>
                        </table>
                    </div>

                    <div class="freeContainerContent" *ngIf="!showLeft1Column" style="overflow-x: hidden;overflow-y: auto;">
                        <div *ngIf="currentSelectedContact && !currentSelectedCompanyId" class="card" style="width:calc(100% - 30px); margin-top:10px; margin-bottom: 6px;">
                        <h3 style="margin-left:6px;">Privatperson</h3>
                        </div>

                        <table class="data_tabel" cellpadding="4" cellspacing="0" style="width:100%;" *ngIf="!currentSelectedCompanyId && !currentSelectedContact">
                            <tr *ngFor="let x of searchResultCompany" [hidden]="showCompanyFavoriteContacts && x.favorite == null">
                                <td (click)="viewCompany(x.id)">{{x.name}}</td>
                                <td style="width:150px" (click)="viewCompany(x.id)">{{x.customer_id}}</td>
                                <td style="width:200px" (click)="viewCompany(x.id)">{{x.supporter}}</td>

                                <td *ngIf="!showEmptyFavoriteFieldCompany && x.favorite == null" style="width:200px" (click)="viewCompany(x.id)">{{x.phone}}</td>
                                <td *ngIf="x.favorite != null || showEmptyFavoriteFieldCompany && x.favorite == null" style="width:180px" (click)="viewCompany(x.id)">{{x.phone}}</td>
                                <td *ngIf="x.favorite != null && x.fk_site == null" style="width: 20px;" (click)="unsetFavoriteSearchResultCompany(x)">
                                    <img src="../design/icons/star.png" style="float: right; margin-right: 3px;">
                                </td>
                                <td *ngIf="x.favorite != null && x.fk_site != null " style="width: 20px;">
                                    <img src="../design/icons/star_green.png" (click)="message.open('globalFavorite'  + this.overlayId)" style="float: right; margin-right: 3px;">
                                </td>
                                <td *ngIf="showEmptyFavoriteFieldCompany && x.favorite == null" style="width: 20px;"></td>
                            </tr>
                        </table>
                        <div *ngIf="currentSelectedCompanyId > 0 && currentSelectedCompanyTab == 0 && editCompany==false" class="card" style="width:calc(100% - 30px); position: absolute; top:20px; bottom: 0;">
                         <table cellpadding="0" cellspacing="0" style="width:100%; height:100%; position: absolute; top:0; bottom: 0;">
                                <tr style="height: 30px;">
                                    <td colspan="2" style="height:30px; border-bottom: 1px solid lightgrey;  font-size: 16px; font-weight: bold;">
                                        <div style="margin-left:6px; margin-top: 2px;" class="form">
                                            <span >{{currentSelectedCompanyData.name}}</span>
                                            <span *ngIf="currentSelectedCompanyData.intern==1" style="font-size: 10px; font-weight: 100;"> (eigene Firma)</span>
                                            <button tooltip="Firma Bearbeiten" style="min-width: 30px; width: 30px; float: right; margin-right: 6px;" (click)="editCompanyData(currentSelectedCompanyData)">
                                                <img src="../design/icons/pencil.png">
                                            </button>
                                            <button tooltip="Firma Löschen" style="min-width: 30px; width: 30px; float: right; margin-right: 2px;" (click)="deleteCompany()">
                                                <img src="../design/icons/delete.png">
                                            </button>
                                            <button *ngIf="currentSelectedCompanyData.favorite == null" tooltip="Favoriten Hinzufügen" style="min-width: 30px; width: 30px; float: right; margin-right: 6px;" (click)="setFavorite(0, currentSelectedCompanyData.id)">
                                                <img src="../design/icons/star_unactive.png">
                                            </button>
                                            <button *ngIf="currentSelectedCompanyData.favorite != null" tooltip="Favorit entfernen" style="min-width: 30px; width: 30px; float: right; margin-right: 6px;" (click)="unsetFavorite(currentSelectedCompanyData)">
                                                <img src="../design/icons/star.png">
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%;">
                                        <table class="data_tabel light" cellpadding="6" cellspacing="0" style="height:100%;width:100%;" >

                                            <tr  style="height: 30px;" *ngIf="currentSelectedCompanyData.address.length == 1" >
                                                <td style="vertical-align:top; width:150px;">
                                                    <b>Anschrift</b>
                                                    <span *ngIf="currentSelectedCompanyData.address.length>0" style="float: right;">
                                                        <a tooltip="Adresse in Google Maps anzeigen" target="_blank" href="https://maps.google.com/maps?q={{currentSelectedCompanyData.address[0].postcode}}+{{currentSelectedCompanyData.address[0].city}}+{{currentSelectedCompanyData.address[0].street}}+{{currentSelectedCompanyData.address[0].street_number}}">
                                                            <img src="../design/icons/house.png" style="vertical-align:middle">
                                                        </a>
                                                        <a tooltip="Route zu dieser Adresse in Google Maps anzeigen" target="_blank" href="https://www.google.com/maps/dir/Horst-Schork-Stra%C3%9Fe+69,+67069+Ludwigshafen%2FRhein/{{currentSelectedCompanyData.address[0].postcode}}+{{currentSelectedCompanyData.address[0].city}}+{{currentSelectedCompanyData.address[0].street}}+{{currentSelectedCompanyData.address[0].street_number}}">
                                                            <img src="../design/icons/car.png" style="vertical-align:middle">
                                                        </a>
                                                    </span>
                                                </td>
                                                <td class="form">
                                                    <span *ngIf="currentSelectedCompanyData.address.length>0">
                                                    {{currentSelectedCompanyData.address[currentSelectedCompanyData.mainAdressIndex].street}}
                                                        {{currentSelectedCompanyData.address[currentSelectedCompanyData.mainAdressIndex].street_number}} <br>
                                                        {{currentSelectedCompanyData.address[currentSelectedCompanyData.mainAdressIndex].postcode}}
                                                        {{currentSelectedCompanyData.address[currentSelectedCompanyData.mainAdressIndex].city}}
                                                    </span>
                                                    <span *ngIf="!editCompany && currentSelectedCompanyData.address.length==0">
                                                        Keine Adresse eingetragen
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr style="height: 30px;">
                                                <td><b>Ansprechpartner</b></td>
                                                <td class="form">
                                                    <span>{{currentSelectedCompanyData.point_of_contact_string}}</span>
                                                </td>
                                            </tr>

                                            <tr style="height: 30px;">
                                                <td> <b>Kundennummer</b> </td>
                                                <td class="form">
                                                    <span>{{currentSelectedCompanyData.customer_id}}</span>
                                                    <span style="float: right;">
                                                        <span *ngFor="let x of starCount;index as i;">
                                                            <img (click)="setStarCustomer(i+1)" *ngIf="i < currentSelectedCompanyData.customer" src="../design/icons/star.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                            <img (click)="setStarCustomer(i+1)" *ngIf="i >= currentSelectedCompanyData.customer" src="../design/icons/star_unactive.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style="height: 30px;">
                                                <td> <b>Lieferantennummer</b> </td>
                                                <td class="form">
                                                    <span>{{currentSelectedCompanyData.distributor_id}}</span>
                                                    <span style="float: right;">
                                                        <span *ngFor="let x of starCount;index as i;">
                                                            <img (click)="setStarDistributor(i+1)" *ngIf="i < currentSelectedCompanyData.distributor" src="../design/icons/star.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                            <img (click)="setStarDistributor(i+1)" *ngIf="i >= currentSelectedCompanyData.distributor" src="../design/icons/star_unactive.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>

                                    </td>
                                    <td style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top; background-color: white;">
                                        <div style="display:inline-block; width:100%; height: auto;">
                                            <div style="margin:5px; white-space: pre-wrap; font-size: 11px;">{{currentSelectedCompanyData.notice}}</div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div *ngIf="currentSelectedCompanyId > 0 && editCompany && editTab == 1" class="card" style="width:calc(100% - 30px); position: absolute; top:20px; bottom: 0;">
                            <table class="data_tabel light" cellpadding="6" cellspacing="0" style="width:100%;" >
                               <tr style="height: 30px;">
                                    <td> <b>Name</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.name"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >
                                    </td>
                                </tr>
                                <tr *ngIf="editCompany" style="height: 30px;">
                                    <td> <b>Kurzname</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.nickname"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >
                                    </td>
                                </tr>
                                <tr  style="height: 30px;" *ngIf="currentSelectedCompanyData.address.length == 1" >
                                    <td style="vertical-align:top; width:150px;">
                                        <b>Anschrift</b>
                                    </td>
                                    <td class="form">
                                        <span *ngIf="currentSelectedCompanyData.address.length > 1">
                                            <select style="width:150px;" [(ngModel)]="currentSelectedCompanyData.main_fk_addresses">
                                                <option *ngFor="let a of currentSelectedCompanyData.address" value="{{a.id}}">{{a.site_short_name}}</option>
                                            </select>
                                        </span>

                                        <span *ngIf="currentSelectedCompanyData.address.length == 1">
                                            <table width="100%" cellpadding="0" cellspacing="0">
                                                <tr *ngIf="currentSelectedCompanyData.address.length > 0">
                                                    <td style="border:unset;">
                                                        <span class="formCaption">Straße</span>
                                                        <input  [(ngModel)]="currentSelectedCompanyData.address[0].street"
                                                                type="text"
                                                                style="width: 95% ;"
                                                                tabindex="103" >
                                                    </td>
                                                    <td style="width:150px;border:unset;">
                                                       <span class="formCaption">Hausnummer</span>
                                                       <input  [(ngModel)]="currentSelectedCompanyData.address[0].street_number"
                                                               type="text"
                                                               style="width: 95% ;"
                                                               tabindex="103" >
                                                    </td>
                                                    <td style="width:150px;border:unset;text-align: right;">
                                                        <span class="formCaption" style="float: left">PLZ</span>
                                                        <input  [(ngModel)]="currentSelectedCompanyData.address[0].postcode"
                                                                type="text"
                                                                style="width: 95% ;"
                                                                tabindex="103" >
                                                    </td>
                                                    <td style="border:unset; text-align: right;">
                                                        <span class="formCaption" style="float: left">Ort</span>
                                                        <input  [(ngModel)]="currentSelectedCompanyData.address[0].city"
                                                                type="text"
                                                                style="width: 96.4% ;"
                                                                tabindex="103" >
                                                    </td>
                                                </tr>
                                            </table>
                                        </span>
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td><b>Ansprechpartner</b></td>
                                    <td class="form">
                                        <select *ngIf="editCompany" [(ngModel)]="currentSelectedCompanyData.point_of_contact">
                                            <option value="">Kein Ansprechpartner gewählt</option>
                                            <option *ngFor="let c of searchResultContact" value="{{c.id}}">
                                                {{c.name}}, {{c.first_name}} </option>
                                        </select>
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <th colspan="2"> <b>Bemerkungen</b> </th>
                                </tr>

                                <tr style="height: inherit;" style="height:200px;">
                                    <td colspan="2" style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top;">
                                        <textarea [(ngModel)]='currentSelectedCompanyData.notice' style="height:calc( 100% - 6px ); width:calc( 100% - 6px );"></textarea>
                                    </td>
                               </tr>
                               <tr style="height: 30px;">
                                    <td class="form" colspan="2" style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top; text-align: right;">
                                        <button style="margin-right:6px;" (click)="editCompanyData(-1)">
                                           <img src="../design/icons/cross.png"> Abbrechen
                                        </button>
                                        <button style=" margin-right:6px;" (click)="updateCompanyData(true)">
                                           <img src="../design/icons/disk.png"> Speichern
                                        </button>
                                    </td>
                               </tr>
                            </table>
                        </div>

                        <div *ngIf="currentSelectedCompanyId > 0 && editCompany && editTab == 2" class="card" style="width:calc(100% - 30px); position: absolute; top:20px; bottom: 0;">
                            <table class="data_tabel light" cellpadding="6" cellspacing="0" style="width:100%;" >
                                <tr style="height: 30px;">
                                    <td> <b>Kundennummer</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.customer_id"
                                                type="text"
                                                style="width: calc(100% - 120px);"
                                                tabindex="103" >
                                        <span style="float: right;">
                                            <span *ngFor="let x of starCount;index as i;">
                                                <img (click)="setStarCustomer(i+1)" *ngIf="i < currentSelectedCompanyData.customer" src="../design/icons/star.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                <img (click)="setStarCustomer(i+1)" *ngIf="i >= currentSelectedCompanyData.customer" src="../design/icons/star_unactive.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr style="height: 30px;">
                                    <td> <b>Debitoren-Konto</b> </td>
                                    <td class="form">
                                        <input  *ngIf="editCompany"
                                                [(ngModel)]="currentSelectedCompanyData.payable_accounts"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >

                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td> <b>Zahlungsart</b> </td>
                                    <td class="form">
                                        <select [(ngModel)]="currentSelectedCompanyData.customer_fk_erp_payment_methods" style="width:160px;">
                                            <option value="0">Keine definiert</option>
                                            <option *ngFor="let u of paymentMethods" value="{{u.id}}">{{u.first_name}} {{u.name}}</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td> <b>Zahlungsziel in Tagen</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.customer_payment_days"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td> <b>Rechnungsempfänger</b> </td>
                                    <td class="form" style="height:28px;">
                                                               <input  *ngIf="editCompany"
                                                                       [(ngModel)]="currentSelectedCompanyData.bill_receiver"
                                                                       type="text"
                                                                       style="width: 99% ;"
                                                                       tabindex="103" >
                                    </td>
                                </tr>
                                <tr style="height: 30px;">
                                    <td class="form" colspan="2" style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top; text-align: right;">
                                        <button style="margin-right:6px;" (click)="editCompanyData(-1)">
                                           <img src="../design/icons/cross.png"> Abbrechen
                                        </button>
                                        <button style=" margin-right:6px;" (click)="updateCompanyData(true)">
                                           <img src="../design/icons/disk.png"> Speichern
                                        </button>
                                    </td>
                               </tr>
                            </table>
                        </div>

                       <div *ngIf="currentSelectedCompanyId > 0 && editCompany && editTab == 3" class="card" style="width:calc(100% - 30px); position: absolute; top:20px; bottom: 0;">
                            <table class="data_tabel light" cellpadding="6" cellspacing="0" style="width:100%; height:100%" >
                                <tr style="height: 30px;">
                                    <td> <b>Lieferantennummer</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.distributor_id"
                                                type="text"
                                                style="width: calc(100% - 120px);"
                                                tabindex="103" >
                                        <span style="float: right;">
                                            <span *ngFor="let x of starCount;index as i;">
                                                <img (click)="setStarDistributor(i+1)" *ngIf="i < currentSelectedCompanyData.distributor" src="../design/icons/star.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                                <img (click)="setStarDistributor(i+1)" *ngIf="i >= currentSelectedCompanyData.distributor" src="../design/icons/star_unactive.png" style="width:14px; height:14px;vertical-align: sub;margin-top: 4px;">
                                            </span>
                                        </span>
                                    </td>
                                </tr>

                                <tr style="height:30px;">
                                    <td> <b>Kreditoren-Konto</b> </td>
                                    <td class="form">
                                        <span *ngIf="!editCompany">{{currentSelectedCompanyData.receivable_accounts}}</span>
                                        <input  *ngIf="editCompany"
                                                [(ngModel)]="currentSelectedCompanyData.receivable_accounts"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td> <b>Zahlungsart</b> </td>
                                    <td class="form">
                                        <select [(ngModel)]="currentSelectedCompanyData.distributor_fk_erp_payment_methods" style="width:160px;">
                                            <option value="0">Keine definiert</option>
                                            <option *ngFor="let u of paymentMethods" value="{{u.id}}">{{u.first_name}} {{u.name}}</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr style="height: 30px;">
                                    <td> <b>Zahlungsziel in Tagen</b> </td>
                                    <td class="form">
                                        <input  [(ngModel)]="currentSelectedCompanyData.distributor_payment_days"
                                                type="text"
                                                style="width: 99% ;"
                                                tabindex="103" >
                                    </td>
                                </tr>

                                <tr  style="height: 30px;">
                                    <th colspan="2"> <b>Sollkonten</b> </th>
                                </tr>
                                <tr  style="height: 30px;">
                                    <td colspan="2" class="form" style="border-bottom: 2px solid darkgray;">
                                        <select style="width:calc( 100% - 136px ); " [(ngModel)]="currentSelectedCompanyData.newAccount" tabindex="112">
                                            <option value="-1">Konto wählen</option>
                                            <option *ngFor="let account of bookAccountsList" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                        </select>
                                        <button style="width:130px;" (click)="addDistributorBookAccount()"><img src="../design/icons/add.png"> Konto hinzufügen</button>
                                    </td>
                                </tr>

                                <tr style="height: 100%;">
                                    <td colspan="2" style="padding: 0;">
                                        <div style="width:100%;height:100%; overflow: auto; background-color: white;">
                                            <table class="data_tabel" style="width:100%;" cellspacing="0" cellpadding="6">
                                                <tr *ngFor="let acc of currentSelectedCompanyData.book_accounts">
                                                    <td style="width:90px; text-align: right;">
                                                        {{acc.account_number}}
                                                    </td>
                                                    <td>
                                                       {{acc.title}}
                                                    </td>
                                                    <td style="width:24px;" (click)="deleteDistributorBookAccount(acc)">
                                                        <img src="../design/icons/delete.png">
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>

                               <tr style="height: 30px;">
                                    <td class="form" colspan="2" style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top; text-align: right;">
                                        <button style="margin-right:6px;" (click)="editCompanyData(-1)">
                                           <img src="../design/icons/cross.png"> Abbrechen
                                        </button>
                                        <button style=" margin-right:6px;" (click)="updateCompanyData(true)">
                                           <img src="../design/icons/disk.png"> Speichern
                                        </button>
                                    </td>
                               </tr>
                            </table>
                        </div>
                        <div *ngIf="currentSelectedCompanyId > 0 && editCompany && editTab == 4" class="card" style="width:calc(100% - 30px); position: absolute; top:20px; bottom: 0;">
                            <table class="data_tabel" style="width:100%;" cellpadding="6" cellspacing="0">
                                <tr>
                                    <td>
                                        E-Mail Domains
                                    </td>
                                </tr>

                                <tr>
                                    <td class="form">
                                        <textarea [(ngModel)]="currentSelectedCompanyData.mail_domains"
                                                style="height:140px; width: 99%;">
                                        </textarea>
                                    </td>
                                </tr>

                                 <tr style="height: 30px;">
                                    <td class="form" colspan="2" style="border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top; text-align: right;">
                                        <button style="margin-right:6px;" (click)="editCompanyData(-1)">
                                           <img src="../design/icons/cross.png"> Abbrechen
                                        </button>
                                        <button style=" margin-right:6px;" (click)="updateCompanyData(true)">
                                           <img src="../design/icons/disk.png"> Speichern
                                        </button>
                                    </td>
                               </tr>
                            </table>
                        </div>

                        <div *ngIf="currentSelectedCompanyId > 0 && currentSelectedCompanyTab > 0 && !showDeleteSiteAction" class="card" style="width:calc(100% - 30px); margin-top:20px; margin-bottom: 6px; overflow: hidden;">
                            <table cellpadding="0" cellspacing="0" style="width:100%; height:100%;" class="data_tabel light">
                                <tr *ngIf="!editSite">
                                    <td colspan="2" style="height:30px; border-bottom: 1px solid lightgrey;  font-size: 16px; font-weight: bold;">
                                        <div style="margin-left:6px; margin-top: 2px;font-size: 16px; font-weight: bold;" class="form">
                                            <span>{{currentSelectedSiteData.site_name}}</span>
                                            <button style="min-width: 30px; width: 30px; float: right; margin-right: 6px;" (click)="editSiteData(currentSelectedSiteData)">
                                                <img src="../design/icons/pencil.png">
                                            </button>
                                            <button style="min-width: 30px; width: 30px; float: right; margin-right: 2px;" (click)="deleteSite('open')">
                                                <img src="../design/icons/delete.png">
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="editSite">
                                    <td>
                                        <div style="margin-left:6px; margin-top: 2px;" class="form">
                                        <b>Standortname</b>
                                        </div>
                                    </td>
                                    <td class="form" style="height:30px; border-bottom: 1px solid lightgrey;  font-size: 16px; font-weight: bold;">
                                            <input  *ngIf="editSite"
                                                    [(ngModel)]="currentSelectedSiteData.site_name"
                                                    type="text"
                                                    style="width: calc( 100% - 18px ); margin-left: 4px;"
                                                    tabindex="103" >
                                    </td>
                                </tr>
                                <tr *ngIf="editSite">
                                    <td>
                                        <div style="margin-left:6px; margin-top: 2px;" class="form">
                                        <b>Standortkurzname</b>
                                        </div>
                                    </td>
                                    <td class="form" style="height:30px; border-bottom: 1px solid lightgrey;  font-size: 16px; font-weight: bold;">
                                        <input  *ngIf="editSite"
                                                [(ngModel)]="currentSelectedSiteData.site_short_name"
                                                type="text"
                                                style="width: calc( 100% - 18px ); margin-left: 4px;"
                                                tabindex="103" >
                                    </td>
                                </tr>
                                <tr *ngIf="editSite">
                                    <td>
                                        <div style="margin-left:6px; margin-top: 2px;" class="form">
                                        <b>Anschrift</b>
                                        </div>
                                    </td>
                                    <td class="form" style="height:30px; border-bottom: 1px solid lightgrey;  font-size: 1.2em; font-weight: bold;">
                                        <table cellspacing="0" cellpadding="0" style="width:99%; margin-left: 4px;">
                                            <tr>
                                                <td style="border-bottom: 0; border-right: 0;">
                                                <input [(ngModel)]="currentSelectedSiteData.street"
                                                       type="text"
                                                       id="newContactAddressStreet"
                                                       style="width:95%;"
                                                       tabindex="101"
                                                       placeholder="Straße">
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0;">
                                                <input  [(ngModel)]="currentSelectedSiteData.street_number"
                                                        type="text"
                                                        id="newContactAddressStreetNumber"
                                                        style="width:95%;"
                                                        tabindex="102"
                                                        placeholder="Hausnummer">
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0;">
                                                <input  [(ngModel)]="currentSelectedSiteData.postcode"
                                                        type="text"
                                                        id="newContactAddressPostcode"
                                                        style="width:95%;"
                                                        tabindex="103"
                                                        placeholder="PLZ">
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0;">
                                                <input  [(ngModel)]="currentSelectedSiteData.city"
                                                        type="text"
                                                        id="newContactAddressCity"
                                                        style="width:95%;"
                                                        tabindex="104"
                                                        placeholder="Stadt">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr *ngIf="!editSite">
                                    <td style="vertical-align:top; width:50%; border-right:0;">
                                        <table class="data_tabel" cellpadding="6" cellspacing="0" style="width:100%;" >
                                            <tr>
                                                <td style="vertical-align:top;width:150px;">
                                                    <b>Anschrift</b>
                                                    <span style="float: right;">
                                                        <a target="_blank" href="https://maps.google.com/maps?q={{currentSelectedSiteData.postcode}}+{{currentSelectedSiteData.city}}+{{currentSelectedSiteData.street}}+{{currentSelectedSiteData.street_number}}">
                                                            <img src="../design/icons/house.png" style="vertical-align:middle">
                                                        </a>
                                                        <a target="_blank" href="https://www.google.com/maps/dir/Horst-Schork-Stra%C3%9Fe+69,+67069+Ludwigshafen%2FRhein/{{currentSelectedSiteData.postcode}}+{{currentSelectedSiteData.city}}+{{currentSelectedSiteData.street}}+{{currentSelectedSiteData.street_number}}">
                                                            <img src="../design/icons/car.png" style="vertical-align:middle">
                                                        </a>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="!editSite">
                                                        {{currentSelectedSiteData.street}}
                                                        {{currentSelectedSiteData.street_number}}<br>
                                                        {{currentSelectedSiteData.postcode}}
                                                        {{currentSelectedSiteData.city}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td  *ngIf="!editSite" style="background-color: white; border-left: 1px solid lightgrey; height:126px; vertical-align: top; ">
                                        <div style="display:inline-block; width:100%; height: auto;">
                                            <div style="margin:5px; white-space: pre-wrap; font-size: 11px;">
                                                <span>{{currentSelectedCompanyData.notice}}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="editSite" style="height: 30px;">
                                    <th colspan="2"> <b>Bemerkungen</b> </th>
                                </tr>
                                <tr *ngIf="editSite"  style="height: inherit;">
                                    <td colspan="2" style="height:200px;border-left: 1px solid lightgrey;border-bottom: 1px solid lightgrey; vertical-align: top;">
                                        <textarea [(ngModel)]='currentSelectedCompanyData.notice' style="height:95%; width:98%; margin: 5px"></textarea>
                                    </td>
                               </tr>
                                <tr *ngIf="editSite">
                                    <td colspan="2" style="height:30px;">
                                        <span *ngIf="editSite" class="form" style="float: right;" >
                                            <button style="margin-right:6px;" (click)="editSiteData(-1)">
                                                <img src="../design/icons/cross.png"> Abbrechen
                                            </button>
                                            <button style="margin-right:6px;" (click)="updateSiteData()">
                                                <img src="../design/icons/disk.png"> Speichern
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    </span>
                </div>
            </div>

            <div *ngIf="currentSelectedCompanyId > 0 && currentSelectedCompanyTab > 0 && showDeleteSiteAction && showLeft1Column" class="card" style="top: 0; left: 0; right: 0; bottom: 0; width:calc(100% - 30px); margin-top:45px; margin-bottom: 6px;">
                <table style="width: 100%; height: 100%; margin-top: 20px; margin-bottom: 20px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <td colspan="2">
                            <span class="form" style="margin-left: 160px;" *ngIf="searchResultContact.length > 0 && currentSelectedSiteData.id != currentSelectedCompanyData.main_fk_addresses">
                                Bitte wählen Sie eine Aktion für die Kontakte des Standorts.</span>
                            <span class="form" style="margin-left: 160px;" *ngIf="searchResultContact.length < 1 && currentSelectedSiteData.id == currentSelectedCompanyData.main_fk_addresses">
                                Bitte wählen Sie eine neue Anschrift für die Firma aus.</span>
                            <span class="form" style="margin-left: 160px;" *ngIf="searchResultContact.length > 0 && currentSelectedSiteData.id == currentSelectedCompanyData.main_fk_addresses">
                                Bitte wählen Sie gewünschten Aktionen aus.</span>
                        </td>
                    </tr>
                    <tr *ngIf="searchResultContact.length > 0">
                        <td style="width: 50%; padding-top: 20px;">
                            <span class="form">
                            &nbsp;&nbsp;<input type="radio" [(ngModel)]="deleteSiteAction" id="deleteSiteActionDeleteContacts" name="deleteSiteAction" value="delete" tabindex="101">
                            <label for="deleteSiteActionDeleteContacts"> Kontakte löschen</label>
                            </span>
                        </td>
                        <td style="width: 50%; padding-top: 20px;">
                            <span class="form">
                            &nbsp;&nbsp;<input type="radio" [(ngModel)]="deleteSiteAction" id="deleteSiteActionReassignContacts" name="deleteSiteAction" value="reassign" tabindex="102">
                            <label for="deleteSiteActionReassignContacts"> Kontakte anderen Standort zuweisen</label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span *ngIf="currentSelectedSiteData.id == currentSelectedCompanyData.main_fk_addresses && selectableNewMainAddresses.length > 1" class="form">
                        <div class="formCaption" style="margin-left: 15px; margin-top: 20px;">Neue Anschrift *</div>
                        <select [(ngModel)]="newSelectedMainAddress"
                                id="currentSelectedCompanyMainAddress"
                                class="cp-textbox"
                                style="width: 88%; margin-left: 15px;"
                                tabindex="103" >
                            <option value="">&nbsp;Bitte Anschrift auswählen</option>
                            <option *ngFor="let x of selectableNewMainAddresses" value="{{x.id}}">&nbsp;{{x.site_short_name}}</option>
                        </select>
                        </span>
                        </td>
                        <td *ngIf="deleteSiteAction == 'reassign' && selectableNewMainAddresses.length > 1">
                        <span class="form">
                        <div class="formCaption" style="margin-left: 15px; margin-top: 20px;">Standort für Kontakte *</div>
                        <select [(ngModel)]="siteIdToReassign"
                                id="siteIdToReassign"
                                class="cp-textbox"
                                style="width: 88%; margin-left: 15px;"
                                tabindex="104" >
                            <option value="">&nbsp;Bitte Standort auswählen</option>
                            <option *ngFor="let x of selectableNewMainAddresses" value="{{x.id}}">&nbsp;{{x.site_short_name}}</option>
                        </select>
                        </span>
                        </td>
                    </tr>
                </table>
                <span class="form">
                            <button style="float: right; margin-top: 15px; margin-bottom: 10px; margin-right: 10px;" (click)="deleteSite('execute')" tabindex="106">
                                Ausführen
                            </button>

                            <button style="float: right; margin-top: 15px; margin-bottom: 10px; margin-right: 10px;" (click)="deleteSiteBack()" tabindex="105">
                               <img src="../design/icons/cross.png">Abbrechen
                            </button>
                </span>
            </div>

            <div style="position: absolute; top:240px; left:0;right:0; bottom:0px;" *ngIf="!showLeft1Column" [ngClass]="{'leftBigBottom' : editCompany || editSite}">
                <div class="freeContainerDefinition" *ngIf="!showLeft1Column">
                    <span class="header1Row footerNone">
                    <div class="freeContainerTop" *ngIf="!showLeft1Column">
                        <table class="data_tabel" cellpadding="4" cellspacing="0" style="width:100%; margin-top: 12px;"  >
                            <tr>
                                <th>Kontakte / Login</th>
                                <th style="width:220px">Firma</th>
                                <th style="width:150px">Position</th>
                                <th style="width:160px">Telefonnummer</th>
                            </tr>
                        </table>
                    </div>

                    <div class="freeContainerContent" *ngIf="!showLeft1Column" style="overflow: auto !important;">
                        <table class="data_tabel" cellpadding="4" cellspacing="0" style="width:100%;" *ngIf="searchResultContact">
                            <tr *ngFor="let x of searchResultContact" [hidden]="showCompanyFavoriteContacts && x.favorite == null"
                                [ngClass]="{'selected' : currentSelectedContact && currentSelectedContact.id == x.id}"
                                id="contact_{{x.id}}">
                                <td style="width:20px;">
                                    <span *ngIf="x.login==0" style="float: right;" (click)="userAction(1,x)">
                                        <img tooltip="Kontakt hat ein deaktiviertes Anmeldekonto." src="../design/icons/user_deactivated.png">
                                    </span>
                                    <span *ngIf="x.login==1" style="float: right;" (click)="userAction(2,x)">
                                        <img tooltip="Kontakt hat ein Anmeldekonto." src="../design/icons/user.png">
                                    </span>
                                    <span *ngIf="usermanagmentRight == true && x.login != 0 && x.login != 1" (click)="userAction(3,x)">
                                        <img tooltip="Kontakt hat kein Anmeldekonto." src="../design/icons/add.png">
                                    </span>
                                </td>
                                <td (click)="view(x)">
                                    {{x.salutationName}} {{x.titelName}} {{x.first_name}} <b>{{x.name}}</b>
                                </td>
                                <td style="width:220px" (click)="view(x)">
                                    <span style="font-weight: bold;"> {{x.companyShortName}}</span>
                                    <span *ngIf="x.site_short_name!=null && x.site_short_name!=x.companyShortName"> - {{x.site_short_name}}</span>
                                    <span *ngIf="!x.companyShortName"><b>(Privatperson)</b></span>
                                </td>
                                <td style="width:150px" (click)="view(x)">{{x.position}}</td>
                                <td *ngIf="!showEmptyFavoriteFieldContact && x.favorite == null" style="width:160px" (click)="view(x)">{{x.phone}}</td>
                                <td *ngIf="x.favorite != null || showEmptyFavoriteFieldContact && x.favorite == null" style="width:140px" (click)="view(x)">{{x.phone}}</td>
                                <td *ngIf="x.favorite != null && x.fk_site == null " style="width: 20px;" (click)="unsetFavoriteSearchResultContact(x)">
                                    <img src="../design/icons/star.png" style="float: right; margin-right: 3px;">
                                </td>
                                    <td *ngIf="x.favorite != null && x.fk_site != null " style="width: 20px;">
                                    <img src="../design/icons/star_green.png" (click)="message.open('globalFavorite' + this.overlayId)" style="float: right; margin-right: 3px;">
                                </td>
                                <td *ngIf="showEmptyFavoriteFieldContact && x.favorite == null" style="width: 20px;"></td>
                            </tr>
                        </table>
                    </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="rightContainer">
            <div class="freeContainerDefinition" >
                <span class="header1Row footerNone">
                <div class="freeContainerTop form">
                    <div *ngIf="( showLeft1Column && !showAssignContact && !showSitestoAssignContact && !showDeleteSiteAction && !showMergeContact ) || ( currentSelectedCompanyId > 0 && editCompany)" style="margin-top: 8px; margin-left: 10px;">Zwischenablage</div>
                    <div *ngIf="showLeft1Column && showAssignContact" style="padding-top: 5px; margin-left: 10px;">Kontakt einer Firma zuweisen</div>
                    <div *ngIf="showLeft1Column && showSitestoAssignContact" style="padding-top: 5px; margin-left: 10px;">Kontakt einem Standort zuweisen</div>
                    <div *ngIf="showCompanyAddContact" style="padding-top: 5px; margin-left: 10px;">Kontakt zu dieser Firma anlegen</div>
                    <div *ngIf="showCompanyNewSiteForm" style="padding-top: 5px; margin-left: 10px;">Neuen Standort anlegen</div>
                    <div *ngIf="showMergeContact" style="padding-top: 5px; margin-left: 10px">Gewählter Hauptkontakt</div>
                </div>

                <div class="freeContainerContent">
                    <span *ngIf="( showLeft1Column && !showAssignContact && !showSitestoAssignContact && !showDeleteSiteAction && !showMergeContact ) || ( currentSelectedCompanyId > 0 && editCompany)">
                        <textarea style="width: calc(100% - 25px); height: calc(100% - 25px); margin-top: 10px; margin-left: 10px; border: 1px solid #ceced2;border-radius: 5px 5px 5px 5px;"></textarea>
                    </span>

                    <span *ngIf="currentSelectedContactData">
                    <div id="contactBase" name="contactBase" class="card form" style="margin-top:15px;" *ngIf="currentSelectedContact">
                        <table class="data_tabel light" cellspacing="0" cellpadding="6" style="width:100%;">
                            <tr>
                                <td colspan="4" style="font-size: 1.4em; line-height: 1.6;">
                                    <span *ngIf="!showEditContactData">
                                        {{currentSelectedContactData.salutationString}} {{currentSelectedContactData.titleString}} {{currentSelectedContactData.first_name}} <b>{{currentSelectedContactData.name}}</b>
                                    </span>
                                    <span >
                                        <table *ngIf="showEditContactData" style="width: 100%;" cellspacing="0" cellpadding="0">
                                            <tr>
                                                <td style="border: none;">
                                                    <select [(ngModel)]="currentSelectedContactData.salutation"
                                                            id="currentContactSalutation"
                                                            class="cp-textbox"
                                                            style="width: 98%;"
                                                            tabindex="101" >
                                                        <option value="-1">Anrede Wählen</option>
                                                        <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                                    </select>
                                                </td>
                                                <td style="border: none;">
                                                    <select [(ngModel)]="currentSelectedContactData.title"
                                                            id="currentContactTitle"
                                                            class="cp-textbox"
                                                            style="width: 98%;"
                                                            tabindex="102" >
                                                        <option value="-1" *ngIf="currentSelectedContactData.title < 0">Titel Wählen</option>
                                                        <option value="0">Kein Titel</option>
                                                        <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                                    </select>
                                                </td>
                                                <td style="border: none;">
                                                   <input [(ngModel)]="currentSelectedContactData.first_name"
                                                          type="text"
                                                          id="currentContactFirstName"
                                                          class="cp-textbox"
                                                          style="width: 96%;"
                                                          tabindex="103" >
                                                </td>
                                                <td style="border: none;">
                                                    <input [(ngModel)]="currentSelectedContactData.name"
                                                           type="text"
                                                           id="currentContactName"
                                                           class="cp-textbox"
                                                           style="width: 99%;"
                                                           tabindex="104" >
                                                </td>
                                                <td style="width: 50px; text-align: right;border: none;" class="form">
                                                    <button style="min-width: 30px" (click)="updateContactData()">
                                                        <img src="../design/icons/disk.png">
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>

                                    </span>
                                    <div *ngIf="!showEditContactData"  class="form" style="width: fit-content !important; float: right;">
                                        <button *ngIf="currentSelectedContactData != null && currentSelectedContactData.id > 0 && !showMergeContact && !showAssignContact && currentSelectedContactData.company == 0"
                                                (click)="showCompaniesToAssignList()"
                                                style="min-width: fit-content;"
                                                tooltip="Den Kontakt einer Firma zuweisen"
                                                [options]="{'placement': 'bottom'}">
                                            <img src="../design/icons/house_link.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData != null && currentSelectedContactData.id > 0 && !showMergeContact && !showAssignContact && currentSelectedContactData.company == 0"
                                                (click)="changePrivateContactToCompany()"
                                                style="min-width: fit-content;"
                                                tooltip="Kontakt in eine Firma wandeln"
                                                [options]="{'placement': 'bottom'}">
                                            <img src="../design/icons/building_go.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData != undefined && currentSelectedContactData.id > 0 && !showMergeContact && !showAssignContact"
                                                (click)="showContactsToAssignList()"
                                                style="min-width: fit-content;"
                                                tooltip="Den Kontakt mit einem anderen Kontakt zusammenführen"
                                                [options]="{'placement': 'bottom'}">
                                            <img src="../design/icons/group_add.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData.favorite == null" tooltip="Favoriten Hinzufügen" style="min-width: 30px; width: 30px; margin-left:10px;" (click)="setFavorite(currentSelectedContactData.id, 0)">
                                            <img src="../design/icons/star_unactive.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData.favorite != null" tooltip="Favorit entfernen" style="min-width: 30px; width: 30px; margin-left:10px;" (click)="unsetFavorite(currentSelectedContactData)">
                                            <img src="../design/icons/star.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData.active==1" style="min-width: fit-content" (click)="deleteContactData()" tooltip="Kontakt archivieren.">
                                            <img src="../design/icons/delete.png">
                                        </button>
                                        <button *ngIf="currentSelectedContactData.active==0" style="min-width: fit-content" (click)="activateContact()" tooltip="Archivierung zum Kontakt aufheben.">
                                            <img src="../design/icons/accept.png">
                                        </button>
                                        <button style="min-width: fit-content" (click)="editContactData()">
                                            <img src="../design/icons/pencil.png">
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 150px;"><b>Position</b></td>
                                <td colspan="3">
                                    <span *ngIf="!showEditContactData">{{currentSelectedContactData.position}}</span>
                                    <input *ngIf="showEditContactData"
                                           [(ngModel)]="currentSelectedContactData.position"
                                           type="text"
                                           tabindex="105"
                                           style="width: 100%">
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 150px;"><b>Beruf</b></td>
                                <td colspan="3">
                                    <span *ngIf="!showEditContactData">{{currentSelectedContactData.profession}}</span>
                                    <input *ngIf="showEditContactData"
                                           [(ngModel)]="currentSelectedContactData.profession"
                                           type="text"
                                           tabindex="105"
                                           style="width: 100%">
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 150px;"><b>Standort</b></td>
                                <td colspan="3">
                                    <span *ngIf="!showEditContactData">{{currentSelectedContactData.site_name}}</span>
                                    <select [(ngModel)]="currentSelectedContactData.site"
                                            *ngIf="currentSelectedContactData.company > 0 && showEditContactData"
                                            class="cp-textbox"
                                            tabindex="107"
                                            style="width: 100%">
                                        <option *ngFor="let x of currentSelectedCompanyData.address" value="{{x.id}}">{{x.site_short_name}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 150px;"><b>Erstellt von</b></td>
                                <td>
                                    <span *ngIf="!showEditContactData">{{currentSelectedContactData.creator}}</span>
                                </td>
                                <td style="width: 150px;"><b>Suchnummer</b></td>
                                <td style="width: 150px;">
                                    <span *ngIf="!showEditContactData">{{currentSelectedContactData.search_number}}</span>
                                    <input *ngIf="showEditContactData"
                                           [(ngModel)]="currentSelectedContactData.search_number"
                                           type="text"
                                           tabindex="105"
                                           style="width: 100%">
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div id="contactConnection" name="contactConnection" class="card form" style="margin-top:15px;" *ngIf="currentSelectedContact">
                        <table class="data_tabel light" cellspacing="0" cellpadding="6" style="width:100%;">
                            <tr>
                                <th colspan="2" style="line-height: 22px; padding: 4px;">Informationen
                                <button style="width: 22px; height: 22px; float: right;" (click)="addInfoToCurrentContact('connectionOthers');" tooltip="Informationseintrag hinzufügen.">
                                    <img src="../design/icons/add.png" style="vertical-align: sub; vertical-align: sub; margin-left: -5px;">
                                </button>
                                </th>
                            </tr>
                            <tr *ngFor="let x of currentSelectedContactData.connection" [ngClass]="{ 'selected' : mode == 'email' && selectedMailId == x.id || mode == 'phone' && selectedPhoneId == x.id }">
                                <td style="width: 150px;" *ngIf="!showEditContactInformation || showEditContactInformation && x.id != currentlyEditingContactInfo" (click)="selectCurrentConnectionTarget(x)">
                                    <b>{{x.name}}</b>
                                    <span *ngIf="x.private_team!=0 || x.private_user != 0" style="float: right; margin-right:-2px;">
                                        <img *ngIf="x.private_team!=0" src="../design/icons/key.png" tooltip="Zugriff nur durch das Team <B>{{x.private_team_name}}</B>">
                                        <img *ngIf="x.private_user!=0" src="../design/icons/key.png" tooltip="Zugriff nur durch den Benutzer <B>{{x.private_user_name}}</B>">
                                    </span>
                                </td>
                                <td style="line-height: 2.4" *ngIf="!showEditContactInformation || showEditContactInformation && x.id != currentlyEditingContactInfo" (click)="selectCurrentConnectionTarget(x)">
                                    {{x.target}}
                                    <span *ngIf="x.target==null">
                                        Zugriff nur durch
                                        <span *ngIf="x.private_team!=0">das Team <B>{{x.private_team_name}}</B></span>
                                        <span *ngIf="x.private_user!=0">den Benutzer <B>{{x.private_user_name}}</B></span>
                                    </span>
                                <div class="form" style="width: 96px; float: right;">
                                    <button style="min-width: 30px; float: right;" (click)="editContactInformation(x)" *ngIf="x.target!=null">
                                        <img src="../design/icons/pencil.png">
                                    </button>
                                    <button style="min-width: 30px; float: right;" (click)="deleteContactInformation(x)" *ngIf="x.target!=null">
                                        <img src="../design/icons/delete.png">
                                    </button>
                                    <button style="min-width: 30px; float: right;" (click)="setMainContact(x)" *ngIf="x.target!=null && x.type1 == 6 || x.type1 == 8 || x.type1 == 13 || x.type1 == 14 || x.type1 == 18 || x.type1 == 20 || x.type1 == 29">
                                        <img src="../design/icons/bullet_white.png" *ngIf="x.type2 == 0">
                                        <img src="../design/icons/bullet_green.png" *ngIf="x.type2 == 1">
                                    </button>
                                </div>
                                </td>
                                <td style="width: 150px;" *ngIf="showEditContactInformation && x.id == currentlyEditingContactInfo">
                                <select [(ngModel)]="x.type1"
                                        id="editInfoType"
                                        style="width: 100%;"
                                        tabindex="101">
                                    <option value="-1">Typ wählen</option>
                                    <option *ngFor="let x of contactFormTypesData" value="{{x.id}}">{{x.name}}</option>
                                </select>
                                </td>
                                <td *ngIf="showEditContactInformation && x.id == currentlyEditingContactInfo">
                                  <input [(ngModel)]="x.target"
                                         (keyup.enter)="updateContactInformation(x);"
                                         type="text"
                                         id="editInfoValue"
                                         style="width:calc(100% - 218px); float: left;"
                                         tabindex="102">
                                    <select style="width:120px;" [(ngModel)]="x.private_team">
                                        <option value="0">Jeder</option>
                                        <option value="-1">Nur für Mich</option>
                                        <option *ngFor="let t of contactTeams" value="{{t.id}}">Team: {{t.name}}</option>
                                    </select>
                                    <span class="form">
                                    <button style="min-width: 30px; float: right;" (click)="updateContactInformation(x)">
                                        <img src="../design/icons/disk.png" tabindex="103">
                                    </button>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="showContactInfoInputToAdd">
                                <td style="width: 150px;">
                                <select [(ngModel)]="selectedInfoType.id"
                                        (change)="checkType()"
                                        id="newContactInfoType"
                                        style="width: 100%;"
                                        tabindex="101">
                                    <option value="-1">Typ wählen</option>
                                    <option *ngFor="let x of contactFormTypesData" value="{{x.id}}">{{x.name}}</option>
                                </select>
                                </td>
                                <td>
                                    <input [(ngModel)]="selectedInfoType.value"
                                           (keyup.enter)="addContactConnection();"
                                           type="text"
                                           id="newContactInfoValue"
                                           style="width:calc(100% - 218px); float: left;"
                                           tabindex="102">
                                    <select style="width:120px;" [(ngModel)]="selectedInfoType.public_team">
                                        <option value="0">Jeder</option>
                                        <option value="-1">Nur für Mich</option>
                                        <option *ngFor="let t of contactTeams" value="{{t.id}}">Team: {{t.name}}</option>
                                    </select>
                                    <span class="form">
                                        <button style="min-width: 30px !important; float: right;" (click)="addContactConnection()">
                                            <img src="../design/icons/disk.png">
                                        </button>
                                    </span>
                                </td>

                            </tr>
                            <tr *ngIf="showContactAddressInputToAdd">
                            <td style="width: 150px;">
                            <div style="margin-bottom: -13px !important;">
                            <select [(ngModel)]="selectedInfoType.id"
                                    (change)="checkType()"
                                    style="width: 100%;"
                                    tabindex="100">
                                 <option value="-1">Typ wählen</option>
                                 <option *ngFor="let x of contactFormTypesData" value="{{x.id}}">{{x.name}}</option>
                            </select>
                            </div>
                            </td>
                            <td>
                                <table cellspacing="0" cellpadding="4" style="margin-left: -3px;">
                                    <tr>
                                        <td style="border-bottom: 0; border-right: 0;">
                                        <table cellspacing="0" cellpadding="4" style="width: 100%;">
                                            <tr>
                                                <td style="border-bottom: 0; border-right: 0; width: 35%;">
                                                <span class="formCaption">Straße*</span>
                                                <input [(ngModel)]="addContactAddressData.street"
                                                       type="text"
                                                       id="contactNewAddressStreet"
                                                       style="width:95%;"
                                                       tabindex="101" >
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0; width: 15%;">
                                                <span class="formCaption">Hausnr.*</span><br>
                                                <input  [(ngModel)]="addContactAddressData.street_number"
                                                        type="text"
                                                        id="contactNewAddressStreetNumber"
                                                        style="width:95%;"
                                                        tabindex="102" >
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0; width: 15%;">
                                                <span class="formCaption">PLZ*</span><br>
                                                <input  [(ngModel)]="addContactAddressData.postcode"
                                                        type="text"
                                                        id="contactNewAddressPostcode"
                                                        style="width:95%;"
                                                        tabindex="103" >
                                                </td>
                                                <td style="border-bottom: 0; border-right: 0; width: 35%;">
                                                <span class="formCaption">Ort*</span>
                                                <input  [(ngModel)]="addContactAddressData.city"
                                                        type="text"
                                                        id="contactNewAddressCity"
                                                        style="width:95%;"
                                                        tabindex="104" >
                                                </td>
                                            </tr>
                                        </table>

                                        <td style="border-bottom: 0; border-right: 0;">
                                        <select style="width:120px; margin-top: 13px;" [(ngModel)]="addContactAddressData.public_team">
                                            <option value="0">Jeder</option>
                                            <option value="-1">Nur für Mich</option>
                                            <option *ngFor="let t of contactTeams" value="{{t.id}}">Team: {{t.name}}</option>
                                        </select>
                                        </td>
                                        <td style="border-bottom: 0; border-right: 0;">
                                        <div class="form" style="width: 65px; float: right; margin-right: -3px; margin-top: 13px;">
                                            <button style="min-width: 30px !important;" (click)="addAddressToContact()">
                                            <img src="../design/icons/disk.png">
                                            </button>
                                        </div>
                                        </td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                        <tr *ngFor="let x of currentSelectedContactData.address">
                            <td style="width: 150px;" *ngIf="!showAddressEdit || showAddressEdit && x.id != currentlyEditingContactAddress"><b>Adresse</b><span *ngIf="x.public!=null" style="float: right; margin-right:-2px;"><img src="../design/icons/key.png"></span></td>
                            <td style="line-height: 2.4" *ngIf="!showAddressEdit || showAddressEdit && x.id != currentlyEditingContactAddress">
                                <span style="float: left;" *ngIf="x.city != null">
                                {{x.street}} {{x.street_number}}<BR>
                                    {{x.postcode}} {{x.city}}
                                </span>
                                <span *ngIf="x.city==null"><b>Diese Information ist geschützt</b></span>
                                <span class="form">
                                <button style="min-width: 30px; float: right;" (click)="editContactAddress(x)" *ngIf="x.city!=null">
                                        <img src="../design/icons/pencil.png">
                                </button>
                                    <button style="min-width: 30px; float: right;" (click)="deleteContactAddress(x)" *ngIf="x.city!=null">
                                        <img src="../design/icons/delete.png">
                                </button>
                                </span>
                            </td>
                            <td *ngIf="showAddressEdit && x.id == currentlyEditingContactAddress">
                                <b>Adresse</b>
                            </td>
                            <td *ngIf="showAddressEdit && x.id == currentlyEditingContactAddress">
                                <table cellspacing="0" cellpadding="4" style="margin-left: -3px; width: calc(100% - 78px); float: left;">
                                    <tr>
                                        <td style="border-bottom: 0; border-right: 0;">
                                        <table cellspacing="0" cellpadding="4" style="width: 100%; float: left;">
                                        <tr>
                                        <td style="border-bottom: 0; border-right: 0; width: 35%">
                                        <span class="formCaption">Straße*</span>
                                        <input [(ngModel)]="x.street"
                                               type="text"
                                               id="editAddressStreet"
                                               style="width:95%;"
                                               tabindex="101" >
                                            </td>
                                            <td style="border-bottom: 0; border-right: 0; width: 15%">
                                        <span class="formCaption">Hausnr.*</span>
                                        <input  [(ngModel)]="x.street_number"
                                                type="text"
                                                id="editAddressStreetNumber"
                                                style="width:95%;"
                                                tabindex="102" >
                                        </td>
                                        <td style="border-bottom: 0; border-right: 0; width: 15%">
                                        <span class="formCaption">PLZ*</span>
                                        <input  [(ngModel)]="x.postcode"
                                                type="text"
                                                id="editAddressPostcode"
                                                style="width:95%;"
                                                tabindex="103" >
                                        </td>
                                        <td style="border-bottom: 0; border-right: 0; width: 35%">
                                        <span class="formCaption">Ort*</span>
                                        <input  [(ngModel)]="x.city"
                                                type="text"
                                                id="editAddressCity"
                                                style="width:95%;"
                                                tabindex="104" >
                                        </td>
                                        </tr>
                                        </table>
                                        </td>
                                        <td style="border-bottom: 0; border-right: 0;">
                                        <select style="width:120px; margin-top: 13px;" [(ngModel)]="x.public_team">
                                            <option value="0">Jeder</option>
                                            <option value="-1">Nur für Mich</option>
                                            <option *ngFor="let t of contactTeams" value="{{t.id}}">Team: {{t.name}}</option>
                                        </select>
                                        </td>
                                    </tr>
                                </table>
                                <span class="form" >
                                    <button style="min-width: 30px !important; margin-top: 17px; float: right;" (click)="updateContactAddress(x)">
                                        <img src="../design/icons/disk.png">
                                    </button>
                                </span>
                            </td>
                        </tr>
                        </table>

                    </div>
                    </span>

                    <div *ngIf="showCompanyAddContact" style="left: 0; right: 0; top: 0; bottom: 0; position: absolute; background-color: #f6f6f6 !important;">
                     <table class="form" style="width: calc(100% - 30px); margin-top: 15px; margin-left: 10px;" cellspacing="0" cellpadding="8">
                        <tr>
                            <td style="width: 50%;">
                                <table style="width: 100%;" cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Anrede *</span>
                                            <select [(ngModel)]="contactContentData.salutation"
                                                    id="addCompanyContactSalutation"
                                                    class="cp-textbox"
                                                    style="width: calc(100% - 8px);"
                                                    tabindex="115" >
                                                <option value="-1">Anrede Wählen</option>
                                                <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <span class="formCaption">Titel</span>
                                            <select [(ngModel)]="contactContentData.title"
                                                    id="addCompanyContactTitle"
                                                    class="cp-textbox"
                                                    style="width: calc(100% + 6px); margin-left: 1px;"
                                                    tabindex="116" >
                                                <option value="-1">Titel Wählen</option>
                                                <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="width: 50%;">
                               <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Vorname</span>
                                            <input [(ngModel)]="contactContentData.first_name"
                                                   type="text"
                                                   id="addCompanyContactFirstName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px);"
                                                   tabindex="117" >
                                        </td>
                                        <td>
                                            <span class="formCaption" style="padding-left: 8px;">Name *</span>
                                            <input [(ngModel)]="contactContentData.name"
                                                   (keyup)="checkExistingContact()"
                                                   (blur)="closeExistingContactOverlay()"
                                                   type="text"
                                                   id="addCompanyContactName"
                                                   class="cp-textbox"
                                                   style="width: calc(100% - 8px); margin-left: 8px;"
                                                   tabindex="118" >
                                         </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">E-Mail</span>
                                <input [(ngModel)]="contactContentData.email"
                                       (keyup)="checkExistingContact()"
                                       (blur)="closeExistingContactOverlay()"
                                       type="text"
                                       id="addCompanyContactEmail"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="119" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.phoneBusiness"
                                        type="text"
                                        id="addCompanyContactPhoneBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="120" >
                            </td>
                            <td>
                                 <span class="formCaption">Fax geschäftlich</span>
                                 <input [(ngModel)]="contactContentData.faxBusiness"
                                        type="text"
                                        id="addCompanyContactFaxBusiness"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="121" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <span class="formCaption">Telefon privat</span>
                                 <input [(ngModel)]="contactContentData.phonepublic"
                                        type="text"
                                        id="addCompanyContactPhonepublic"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="122" >
                            </td>
                            <td>
                                <span class="formCaption">Telefon mobil</span>
                                <input [(ngModel)]="contactContentData.phoneMobile"
                                       type="text"
                                       id="addCompanyContactPhoneMobile"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="123" >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 80%;">
                                            <span class="formCaption">Straße</span>
                                            <input [(ngModel)]="contactContentData.street"
                                                   type="text"
                                                   id="addCompanyContactStreet"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="124" >
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="formCaption">Hausnr.</span>
                                            <input [(ngModel)]="contactContentData.street_number"
                                                   type="text"
                                                   id="addCompanyContactStreetNumber"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="125" >
                                        </td>
                                    </tr>
                                 </table>
                            </td>
                             <td>
                                 <table cellspacing="0" cellpadding="0">
                                    <tr>
                                        <td style="width: 30%;">
                                            <span class="formCaption">PLZ</span>
                                            <input [(ngModel)]="contactContentData.postcode"
                                                   type="text"
                                                   id="addCompanyContactpostcode"
                                                   class="cp-textbox"
                                                   style="width:calc(100% - 15px);"
                                                   tabindex="126" >
                                        </td>
                                        <td style="width: 70%;">
                                            <span class="formCaption">Ort</span>
                                            <input [(ngModel)]="contactContentData.city"
                                                   type="text"
                                                   id="addCompanyContactCity"
                                                   class="cp-textbox"
                                                   style="width:100%;"
                                                   tabindex="127" >
                                       </td>
                                    </tr>
                                 </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Position</span>
                                <input [(ngModel)]="contactContentData.position"
                                       type="text"
                                       id="addCompanyContactPosition"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="128" >
                            </td>
                            <td>
                                <span class="formCaption">Beruf</span>
                                <input [(ngModel)]="contactContentData.profession"
                                       type="text"
                                       id="addCompanyContactProfession"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="129" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <span *ngIf="currentSelectedCompanyData.address.length > 1">
                                <span class="formCaption">Standort</span><br>
                                <select
                                        [(ngModel)]="siteIdToAssignContact"
                                        style="width: 220px; float: left;"
                                        tabindex="136">
                                    <option *ngFor="let x of currentSelectedCompanyData.address" [value]="x.id">{{x.site_short_name}}</option>
                                </select>
                                </span>

                                <div class="form" style="margin-top: 15px;">
                                <button (click)="checkAddContactToCompany(currentSelectedCompanyId)" style="width: 100px; float: right; margin-right: -8px;" tabindex="137">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>

                                <button (click)="companyAddFormBack()" style="width: 105px; float: right; margin-right: 10px;" tabindex="136">
                                    <img src="../design/icons/cross.png"> Abbrechen
                                </button>
                                </div>
                            </td>
                        </tr>

                    </table>
                    </div>

                    <div *ngIf="showCompanyNewSiteForm" style="left: 0; right: 0; top: 0; bottom: 0; position: absolute; overflow: auto; background-color: #f6f6f6 !important;">
                         <table class="form" style="width: calc(100% - 30px); margin-top: 5px; margin-left: 10px; background-color: #f6f6f6 !important;" cellspacing="0" cellpadding="4">
                             <tr>
                                 <td>
                                    <span class="formCaption">Name *</span>
                                    <input [(ngModel)]="addSiteData.site_name"
                                           type="text"
                                           id="newSiteName"
                                           class="cp-textbox"
                                           style="width:98%;"
                                           tabindex="101" >
                                 </td>
                                 <td style="width: 49%;">
                                    <span class="formCaption">Kurzname *</span>
                                    <input [(ngModel)]="addSiteData.site_short_name"
                                           type="text"
                                           id="newSiteShortName"
                                           class="cp-textbox"
                                           style="width:100%;"
                                           tabindex="102" >
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                    <span class="formCaption">Straße *</span>
                                    <input [(ngModel)]="addSiteData.street"
                                           type="text"
                                           id="newSiteStreet"
                                           class="cp-textbox"
                                           style="width:98%;"
                                           tabindex="103" >
                                 </td>
                                 <td style="width: 49%;">
                                    <span class="formCaption">Hausnummer *</span>
                                    <input [(ngModel)]="addSiteData.street_number"
                                           type="text"
                                           id="newSiteStreetNumber"
                                           class="cp-textbox"
                                           style="width:100%;"
                                           tabindex="104" >
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                    <span class="formCaption">PLZ *</span>
                                    <input [(ngModel)]="addSiteData.postcode"
                                           type="text"
                                           id="newSitePostcode"
                                           class="cp-textbox"
                                           style="width:98%;"
                                           tabindex="105" >
                                 </td>
                                 <td style="width: 49%;">
                                    <span class="formCaption">Ort *</span>
                                    <input [(ngModel)]="addSiteData.city"
                                           type="text"
                                           id="newSiteCity"
                                           class="cp-textbox"
                                           style="width:100%;"
                                           tabindex="106" >
                                 </td>
                             </tr>
                             <tr>
                                <td colspan="4">
                                    <div class="form" style="margin-top: 15px;">
                                    <button (click)="addSiteToCompany()" style="width: 100px; float: right; margin-right: -8px;" tabindex="137">
                                        <img src="../design/icons/disk.png"> Speichern
                                    </button>

                                    <button (click)="companyAddFormBack()" style="width: 105px; float: right; margin-right: 10px;" tabindex="136">
                                        <img src="../design/icons/cross.png"> Abbrechen
                                    </button>
                                    </div>
                                </td>
                             </tr>
                         </table>
                    </div>
                </div>
                </span>
            </div>
        </div>
        </span>

        <span *ngIf="viewMode==2">
            <div></div>
            <div class="freeContainerDefinition" style="position: absolute; left:10px; right:10px; bottom:10px; top:43px; height: unset;">
                <span class="header2Row footerNone">
                <div class="freeContainerTop">
                <span class="form search" style="display: inline-block; margin-top:10px; width:calc( 100% - 10px) ;">
                        <select (change)="updateList()"
                                [(ngModel)]="listFilter.type"
                                style="width:100px;">
                            <option value="3">Kontaktliste</option>
                            <option value="1">Firmenliste</option>
                            <option value="2">Standortliste</option>
                        </select>
                        <select *ngIf="listFilter.type==3"
                                (change)="updateList()"
                                [(ngModel)]="listFilter.public"
                                style="width:110px;">
                            <option value="0">Alle</option>
                            <option value="1">Firmenkontakte</option>
                            <option value="2">Privatpersonen</option>
                        </select>
                        <button (click)="listPrint()" style=" margin-right:10px; width:110px; float: right;">
                            <img src="../design/icons/printer.png" style="margin:0 0 0 -1px; "> Drucken
                        </button>
                        <button (click)="listExcelExport()" style=" margin-right:4px; width:110px; float: right;">
                            <img src="../design/icons/page_white_excel.png" style="margin:0 0 0 -1px; "> Excel Export
                        </button>
                        <span *ngIf="listData && listData.length > 0" style="margin-top:4px;color:white;display: inline-block; float: right; margin-right: 10px;">
                            {{listData.length}} gefunden.
                        </span>

                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%; margin-top: 2px;">
                    <tr>
                        <th style="width:5%;">
                            <span app-sort-switch (onSortChanged)="updateList()" [(model)]="listFilter.sort" name="ANREDE"  sortColumn="salutation"></span>
                        </th>
                        <th style="width:12.5%;">
                            <span app-sort-switch (onSortChanged)="updateList()" [(model)]="listFilter.sort" name="VORNAME" sortColumn="first_name"></span>
                        </th>
                        <th style="width:12.5%;">
                            <span app-sort-switch (onSortChanged)="updateList()" [(model)]="listFilter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:12.5%;">
                            <span app-sort-switch (onSortChanged)="updateList()" [(model)]="listFilter.sort" name="POSITION" sortColumn="position"></span>
                        </th>
                        <th style="width:12.5%;">
                            E-MAIL
                        </th>
                        <th style="width:12.5%;">
                            TELEFON
                        </th>
                        <th style="width:16.25%;">
                            <span app-sort-switch (onSortChanged)="updateList()" [(model)]="listFilter.sort" name="FIRMA/PRIVAT" sortColumn="company"></span>
                        </th>
                        <th style="width:16.25%;">
                            STANDORT
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
                </div>


                <div class="freeContainerContent">
                    <div class="loading" *ngIf="showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 15%; width: 50px;">
                    </div>


                    <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                        <ng-container *ngFor="let x of listData">
                            <tr *ngIf="x.listEdit!=1 && x.listEdit!=2">
                                <td style="width:8%;" (click)="startListEdit(x,1)">{{ x.salutationName }}</td>
                                <td style="width:12.5%;" (click)="startListEdit(x,2)">{{ x.first_name }}</td>
                                <td style="width:12.5%;" (click)="startListEdit(x,3)">{{x.titelName}} {{ x.name }}</td>
                                <td style="width:12.5%;" (click)="startListEdit(x,4)">{{ x.position }}</td>
                                <td style="width:12.5%;" (click)="startListEdit(x,5)">{{ x.email }}</td>
                                <td style="width:12.5%;" (click)="startListEdit(x,6)">{{ x.phone }}</td>
                                <td style="width:14.25%;" (click)="startListEdit(x,0)">
                                    {{ x.company }}
                                    <span *ngIf="x.companyId<1">Privatperson</span>
                                </td>
                                <td style="width:14.25%;" (click)="startListEdit(x,0)">{{ x.site_name }}</td>
                            </tr>

                            <tr *ngIf="x.listEdit==1 || x.listEdit==2">
                                <td style="width:8%;">
                                    <select id="salutation_{{x.id}}" (change)="changedListEdit(null)" [(ngModel)]="x.salutation"
                                            class="cp-textbox"
                                            tabindex="{{x.id}}1"
                                            style="width:100%; margin: 0;">
                                            <option value="0">Anrede Wählen</option>
                                            <option *ngFor="let x of salutations" value="{{x.id}}">{{x.name}}</option>
                                    </select>
                                </td>
                                <td style="width:12.5%;" >
                                    <input id="firstName_{{x.id}}" type="text" [(ngModel)]="x.first_name" (keyup)="changedListEdit($event)" tabindex="{{x.id}}2" style="width:96%;">
                                </td>
                                <td style="width:12.5%;">
                                    <select (change)="changedListEdit(null)" [(ngModel)]="x.titel"
                                            class="cp-textbox"
                                            tabindex="{{x.id}}3"
                                            style="width:90px;">
                                            <option value="0">Kein Titel</option>
                                            <option *ngFor="let x of titles" value="{{x.id}}">{{x.name}}</option>
                                    </select>
                                    <input id="name_{{x.id}}" type="text" [(ngModel)]="x.name" (keyup)="changedListEdit($event)" tabindex="{{x.id}}4" style="width: calc( 100% - 104px ); margin-left: 4px;">
                                </td>
                                <td style="width:12.5%;" >
                                    <input id="position_{{x.id}}" type="text" [(ngModel)]="x.position" (keyup)="changedListEdit($event)" tabindex="{{x.id}}5" style="width:96%;">
                                </td>
                                <td style="width:12.5%;" ><input id="email_{{x.id}}" type="text" [(ngModel)]="x.email" (keyup)="changedListEdit($event)" tabindex="{{x.id}}6" style="width:96%;">
                                </td>
                                <td style="width:12.5%;" ><input id="phone_{{x.id}}" type="text" [(ngModel)]="x.phone" (keyup)="changedListEdit($event)" tabindex="{{x.id}}7" style="width:96%;">
                                </td>
                                <td style="width:14.25%;">
                                    {{ x.company }}
                                    <span *ngIf="x.companyId<1">Privatperson</span>
                                </td>
                                <td style="width:14.25%;position:relative;" >
                                    {{ x.site_name }}
                                    <span style="display: inline-block;
                                                position: absolute;
                                                height: 100%;
                                                width: 65px;
                                                background-color: #f7f7f7;
                                                right: 0;
                                                top: 0;
                                                text-align: right;
                                                border-left: 1px solid gray;">
                                        <button (click)="saveListEdit();" style="margin-top:4px; margin-right:6px; width:24px;">
                                            <img src="../design/icons/disk.png" style="vertical-align: sub; margin-left: -4px;">
                                        </button>

                                        <button (click)="openContactActionMenu($event);" style="margin-top:4px; margin-right:6px; width:24px;">
                                            <img src="../design/icons/menu_small.png" style="vertical-align: sub; margin-left: -4px;">
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                </span>
            </div>
        </span>

        <span *ngIf="viewMode==3">

            <div class="leftContainer">

                <div class="leftContainerDefinition header1Row footerNone">
                    <div class="freeContainerTop form" style="height: 32px !important;">
                        <span class="currentLocation">
                            Empfängergruppen
                        </span>
                    </div>
                     <div class="freeContainerContent" style="top: 32px !important; bottom: 0 !important;">
                         <table class="data_tabel light" cellpadding="4" cellspacing="0" style="width: 100%">
                             <tr>
                                 <th>
                                  Name
                                 </th>
                                 <th style="width:24px;">
                                 </th>
                             </tr>
                             <tr *ngFor="let x of groupData" >
                                 <td (click)="editGroup(x)">
                                    {{x.name}}
                                 </td>
                                  <td (click)="deleteGroupQuestion(x)" style="width:24px;">
                                    <img src="../design/icons/delete.png">
                                 </td>
                             </tr>
                         </table>
                    </div>
                </div>
            </div>

             <div class="rightContainer">

                <div class="rightContainerDefinition header1Row footerNone">
                    <div class="freeContainerTop" style="height: 32px !important;">
                    </div>
                     <div class="freeContainerContent" style="top: 32px !important; bottom: 0 !important;">
                         <span class="form">
                             <table *ngIf="currentSelectedContactGroup!=null" style="border:none;margin-left: 15px;height:calc( 100% - 20px); margin-top:10px; width: calc( 100% - 30px);" cellspacing="0" cellpadding="4" class="form" >
                                <tr>
                                    <td>
                                        <span class="formCaption">Name</span>
                                        <input type="text"
                                               [(ngModel)]="currentSelectedContactGroup.name"
                                               style="width: calc( 100% - 7px );"
                                               tabindex="220"
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="formCaption">Beschreibung</span>
                                        <textarea type="text"
                                                  [(ngModel)]="currentSelectedContactGroup.description"
                                                  style="width: calc( 100% - 7px ); height: 60px;"
                                                  tabindex="220"
                                        ></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span *ngIf="currentSelectedContactGroup.id < 1" >
                                            Speichern Sie die neue Empfängergruppe um dieser Kotaktadressen hinzuzufügen!
                                        </span>
                                        <button (click)="saveGroup()" style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png"> Speichern</button>
                                    </td>
                                </tr>
                                <tr *ngIf="currentSelectedContactGroup.id > 0">
                                    <td>
                                        <span class="formCaption">E-Mail Empfänger hinzufügen</span>

                                <app-contact-manager
                                        *ngIf="groupContactManagerReset"
                                        [mode]="'email'"
                                        (nameStrings)="changeGroupContactString($event)"
                                        (change)="addGroupContact()"
                                        [(company)]="newGroupContactItem.company"
                                        [(site)]="newGroupContactItem.site"
                                        [(contact)]="newGroupContactItem.contact"
                                        [(connectId)]="newGroupContactItem.connectId"
                                        style="width:100%;"
                                        tabindex="210">
                                        </app-contact-manager>
                                    </td>
                                </tr>
                                <tr *ngIf="currentSelectedContactGroup.id > 0" style="height:100%;">
                                    <td>
                                       <div style="height:100%; width:calc( 100% - 4px ); background-color: white; overflow: auto;border: 1px solid lightgrey">
                                         <table class="data_tabel light" cellpadding="4" cellspacing="0" style="width: 100%">
                                             <tr>
                                                 <th>
                                                  Name
                                                 </th>
                                                 <th>
                                                  E-Mail
                                                 </th>
                                                 <th style="width:24px;">

                                                 </th>
                                             </tr>
                                             <tr *ngFor="let x of currentSelectedContactGroup.items" >
                                                 <td>
                                                    {{x.name}}
                                                 </td>
                                                 <td>
                                                    {{x.email}}
                                                 </td>
                                                 <td (click)="deleteGroupItem(x)">
                                                    <img src="../design/icons/delete.png">
                                                 </td>
                                             </tr>
                                         </table>


                                       </div>
                                    </td>
                                </tr>
                                <tr *ngIf="currentSelectedContactGroup.id < 1" style="height:100%;">
                                    <td style="vertical-align: top;">

                                    </td>
                                </tr>
                            </table>
                         </span>
                    </div>
                </div>
            </div>

        </span>
    </DIV>
</DIV>


<jw-modal id="searchArchive{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Kein aktiver Kontakt gefunden, möchten Sie im Archiv suchen?
    </div>
    <div class="modalFooter form" >
        <button (click)="searchArchive()" style="float: right; margin-top: 3px;">Ja</button>
        <button (click)="message.close('searchArchive' + overlayId)" style="float: right; margin-top: 3px;">Nein</button>
    </div>
</jw-modal>


<jw-modal id="deleteFavouriteQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Wollen Sie den Favorit wirklich löschen?
    </div>
    <div class="modalFooter form" >
        <button (click)="deleteFavourite()" style="float: right; margin-top: 3px;">Löschen</button>
        <button (click)="message.close('deleteFavouriteQuestion' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="domainUsed{{overlayId}}" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;">E-Mailendung bereits vergeben.
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form"><span [innerHTML]="contactManagerMessageContent | safe"></span>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('domainUsed'+overlayId);"
                style="margin-top: 3px;margin-left: 3px; float: right;">Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="wrongMail{{overlayId}}" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;">Falsche E-Mail
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
        Die E-Mail ist in keinem gültigen Format.
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('wrongMail'+overlayId);"
                style="margin-top: 3px;margin-left: 3px; float: right;">Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="globalFavorite{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Globale Favoriten können nur von berechtigten Personen verwaltet werden.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('globalFavorite' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="favorite{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Kontakte und Firmen können nur von berechtigten Personen oder dem Ersteller verwaltet werden.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('favorite' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="groupItemExists{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Der gewählte Kotakt befindet sich bereits in der Empfängerliste!
    </div>
    <div class="modalFooter form" ><button (click)="message.close('groupItemExists' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="groupDeleteQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sind Sie sicher das sie die Empfängergruppe endgültig Löschen wollen?!
    </div>
    <div class="modalFooter form" >
        <button (click)="deleteGroup()" style="float: right; margin-top: 3px;">Löschen</button>
        <button (click)="deleteGroupStop()" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="companyDeleteQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Wollen Sie die Firma wirklich löschen?
    </div>
    <div class="modalFooter form" >
        <button (click)="deleteCompanyContacts(); message.close('companyDeleteQuestion' + overlayId)" style="float: right; margin-top: 3px;">Löschen</button>
        <button (click)="message.close('companyDeleteQuestion' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="siteDeleteQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Wollen Sie den Standort wirklich löschen?
    </div>
    <div class="modalFooter form" >
        <button (click)="deleteCompanySite(); message.close('siteDeleteQuestion' + overlayId)" style="float: right; margin-top: 3px;">Löschen</button>
        <button (click)="message.close('siteDeleteQuestion' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>



<jw-modal id="assignContactQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Wollen Sie den Privatkontakt wirklich der gewählten Firma zuweisen? <BR>Alle Vorgänge und Dokumente des Kontakts werden dann ebenfalls der Firma zugewiesen.
    </div>
    <div class="modalFooter form" >
        <button (click)="assignContactToCompany()" style="float: right; margin-top: 3px;">Zuweisen</button>
        <button (click)="message.close('assignContactQuestion' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="mergeContactQuestion{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sind Sie sicher das Sie die Kontakte zusammenführen möchten?<br>
        Dies ist nachträglich nicht wieder Rückgangig zu machen!<br>
        Alle Dokumente und Vorgänge werden an den Zielkontakt übertragen.
    </div>
    <div class="modalFooter form" >
        <button (click)="mergeContact()" style="float: right; margin-top: 3px;">Zusammenführen</button>
        <button (click)="message.close('mergeContactQuestion' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="rightCheckMerge{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sie Besitzen nicht die Berechtigung Kontakte zusammenzuführen.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('rightCheckMerge' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="mergeSucces{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Zusammenführen erfolgreich.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('mergeSucces' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="rightCheckGroup{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sie Besitzen nicht die Berechtigung Kontaktgruppen zu verwalten.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('rightCheckGroup' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="rightCheckOwnCompany{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sie Besitzen nicht die Berechtigung Kontakte für die eigene Firma anzulegen.
    </div>
    <div class="modalFooter form" ><button (click)="message.close('rightCheckOwnCompany' + overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

<jw-modal id="deleteDialog{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Wollen Sie den Kontakt wirklich archivieren?<BR>
    </div>
    <div class="modalFooter form" >
        <button (click)="deleteContactFinish()" style="float: right; margin-top: 3px;">Archivieren</button>
        <button (click)="message.close('deleteDialog' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>
<jw-modal id="waiting{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Aktion wird durchgeführt</span><BR><BR></div>
    </div>
    <div class="modalContent" style="text-align: center;">
        <BR>
        Zusammenführen wird ausgeführt. Bitte Warten.
        <BR><BR>
        <img src="../design/icons/roller.gif">
        <BR><BR>
    </div>
    <div class="modalFooter form" style="vertical-align: center;">

    </div>
</jw-modal>

<jw-modal id="contactManagerQuestionBox{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent"><span [innerHTML]="contactManagerMessageContent | safe"></span>
        <table *ngIf="existingContact.length >= 1"  class="data_tabel" style="margin: auto; margin-top: 10px; width: fit-content; height: fit-content; border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-top: 1px solid #CCC;">

            <tr *ngFor="let x of existingContact">
                <td (click)="view({id: x.id});message.close('contactManagerQuestionBox' + overlayId);">{{x.salutationName}}&nbsp;{{x.first_name}},&nbsp;{{x.name}}<br>
                    {{x.company}}<br>
                    {{x.email}}</td>
            </tr>

        </table>
    </div>
    <div class="modalFooter form" ><button (click)="saveSwitch(); message.close('contactManagerQuestionBox'+ overlayId)" style="float: right; margin-top: 3px;">Trotzdem speichern</button><button (click)="message.close('contactManagerQuestionBox'+ overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button></div>
</jw-modal>

<jw-modal id="contactManagerActivateUser{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">Soll das Benutzerkonto zu diesem Kontakt wirklich wieder aktiviert werden?
    </div>
    <div class="modalFooter form">
        <button (click)="activateUser()" style="float: right; margin-top: 3px;">Aktivieren</button>
        <button (click)="message.close('contactManagerActivateUser' + overlayId)" style="float: right; margin-top: 3px;">Abbrechen</button>
    </div>
</jw-modal>

<jw-modal id="contactManagerMessageBox{{overlayId}}" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent"><span [innerHTML]="contactManagerMessageContent | safe"></span>
    </div>
    <div class="modalFooter form" ><button (click)="message.close('contactManagerMessageBox'+ overlayId)" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>

