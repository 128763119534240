import {Component, Input, Output, OnInit, OnChanges, EventEmitter, ViewChild} from '@angular/core';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;

@Component({
  selector: '[app-date-picker]',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit, OnChanges {

  public overlayId: string;
  public OverlayActive = false;
  public lastSelectedDate: string;
  public displayMode = 0;
  public calenderDays: any[];
  public yearList: any;
  public showRangeSelector = false;
  public yearSelectModifier = 0;
  public selectedDay;
  public selectedMonth;
  public selectedYear;
  public currentYear;
  public currentMonth;
  public todayDate;
  public x: any;
  public boundDatepickerString: string;


  @Output() change: EventEmitter<string>;

  @Output() boundDatepickerChange: EventEmitter<string>;
  @Input()
  get boundDatepicker() {
    return this.boundDatepickerString;
  }
  set boundDatepicker(value: string) {
    this.boundDatepickerString = value;
    this.boundDatepickerChange.emit(this.boundDatepickerString);
  }


  @Input() first: boolean;
  @Input() markOnSet: boolean;
  @Input() mark: boolean;
  @Input() placeholder: string;
  @Input() openAtTop: boolean;
  @Input() settabindex: any;
  public contextMenuAYears = [];
  // Model
  public modelValue: string;
  @Output() modelChange: EventEmitter<string>;
  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    if(this.modelValue != undefined ) {
      setTimeout(() => {
        if(this.change) {
          this.change.emit();
        }
      }, 200);
    }
    this.modelValue = value;
    this.updateModelValue();
  }

  updateModelValue() {
    if (this.modelValue !== undefined) {
      const dateParts = this.modelValue.split('.');
      if ( parseInt(dateParts[1]) > 0) {
        this.selectedDay    = dateParts[0];
        this.currentMonth   = this.selectedMonth = parseInt(dateParts[1]);
        this.currentYear    = this.selectedYear = parseInt(dateParts[2]);
      }
      this.changeModel(this.modelValue);
      this.updateCalendar();
    }
  }

  constructor() {
    if ( this.markOnSet === undefined ) { this.markOnSet = false; }
    if ( this.mark === undefined ) { this.mark = false; }
    this.modelChange = new EventEmitter();
    this.boundDatepickerChange = new EventEmitter();
    this.change = new EventEmitter();
    let newDateObj = new Date();
    let yearsToShow = 6;
    for(let i=0;i<yearsToShow;i++) {
      this.contextMenuAYears.push(newDateObj.getFullYear()-i);
    }
  }

  changeModel(value): void {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
    setTimeout(() => {
      if(this.boundDatepicker=="") {
        this.boundDatepicker = value
        this.boundDatepickerChange.emit(this.boundDatepicker);
      }
    }, 200);
  }
  // ENDE MODEL



  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    const todayDay = dateNow.getDate();
    let todayDayString = todayDay.toString();
    if ( todayDay < 10) { todayDayString = '0' + todayDay.toString(); }
    const todayMonth = (dateNow.getMonth() + 1);
    let todayMonthString = todayMonth.toString();
    if (todayMonth < 10) { todayMonthString = '0' + todayMonth.toString(); }
    this.todayDate = {
      day: todayDay,
      dayString: todayDayString,
      month: todayMonth,
      monthString: todayMonthString,
      year: dateNow.getFullYear() };
    this.currentMonth = todayMonth;
    this.currentYear = dateNow.getFullYear();
    this.updateCalendar();
    if (this.first === undefined) { this.first = false; }
  }

  updateCalendar() {
    let numberOfFirstDayInCurrentMonth = new Date(this.currentYear,this.currentMonth - 1, 1 ).getDay();
    if (numberOfFirstDayInCurrentMonth === 0 ) { numberOfFirstDayInCurrentMonth = 7; }
    let nextMonth = this.currentMonth + 1;
    let nextYear = this.currentYear;
    if (nextMonth === 13) { nextMonth = 1; nextYear ++; }

    let previousMonth = this.currentMonth - 1;
    let previousYear = this.currentYear;
    if (previousMonth === 0) { previousMonth = 12; previousYear --; }

    const endDayThisMonth = 32 - new Date(this.currentYear, this.currentMonth - 1, 32).getDate() ;
    const endDayPreviousMonth = 32 - new Date(previousYear, previousMonth - 1, 32).getDate();

    let dayCounter = endDayPreviousMonth + 2 - numberOfFirstDayInCurrentMonth;

    const jsonArrFull = [];
    let jsonArrRow = [];
    let currentMonth = 0;
    let monthInsert = previousMonth;
    let yearInsert = previousYear;

    if (endDayPreviousMonth < dayCounter) { currentMonth = 1; dayCounter = 1; monthInsert++; }

    // Jahreswechsel abfangen.
    if(monthInsert>12) { monthInsert = 1; yearInsert++; } else if(monthInsert<1) { monthInsert = 12; yearInsert--; }

    for (let calenderRows = 1; calenderRows <= 6; calenderRows++) {
      for (let day = 1; day <= 7; day++) {
        jsonArrRow.push({
          day: dayCounter,
          currentMonth: currentMonth,
          selected: 0,
          month: monthInsert,
          year: yearInsert
        });
        dayCounter++;
        if (currentMonth === 0 && dayCounter > endDayPreviousMonth) {
          dayCounter = 1; currentMonth = 1; monthInsert = this.currentMonth; yearInsert = this.currentYear;
        } else if (currentMonth === 1 && dayCounter > endDayThisMonth) {
          dayCounter = 1; currentMonth = 0; monthInsert = nextMonth; yearInsert = nextYear;
        }
      }
      jsonArrFull.push( jsonArrRow );
      jsonArrRow = [];
    }
    this.calenderDays = jsonArrFull;
  }

  setDate(clickedDate) {
    this.deactivateOverlay();
    let day = clickedDate.day;
    if (day.toString().length < 2) { day = '0' + day; }
    let month = clickedDate.month;
    if (month.toString().length < 2) { month = '0' + month; }
    this.modelValue = day + '.' + month + '.' + clickedDate.year;
    this.updateModelValue();
    this.datePickerChanged();
  }

  changeYearTo(changeTo) {
    this.currentYear = parseInt(changeTo);

    this.updateCalendar();
    this.changeMode(1);
  }

  changeMonthTo(changeTo) {
    this.currentMonth = parseInt(changeTo);
    this.selectedMonth = this.currentMonth;
    this.updateCalendar();
    this.changeMode(0);
  }

  changeMonth(changeWith) {
    this.currentMonth = parseInt(this.currentMonth);
    this.currentMonth += changeWith;
    if (this.currentMonth < 1) { this.currentMonth = 12; this.currentYear --;
    } else if (this.currentMonth > 12) { this.currentMonth = 1; this.currentYear ++; }
    this.updateCalendar();
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.width = '250px';
    document.getElementById(this.overlayId).style.height = '255px';
    if(this.openAtTop){
    document.getElementById(this.overlayId).style.bottom = '4%';}
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

  datePickerChanged() {
    if ( this.lastSelectedDate === '' ) { this.lastSelectedDate = this.modelValue; }
    if ( this.lastSelectedDate !== this.modelValue ) {
      // this.blurAction();
    }
    this.updateModelValue();
  }

  checkDateFields() {
    if(this.modelValue === '') {
      this.updateModelValue();
      this.datePickerChanged();
      return
    }

    if (this.modelValue === '') { return; }
    let curDate = this.modelValue;

    curDate = curDate.replace(/ +/g, ' '); // doppelte Leerzeichen entfernen
    curDate = curDate.replace(/[-\/ ]/g, '.'); // Trennzeichen normalisieren

    const curDateDiff = curDate.replace(/[. ]/g, ''); // Trennzeichen normalisieren

    let splitDate;
    let day;
    let month;
    let year;

    if ( curDate.length === 5 && curDate.substring(2,3) === '.' ) {
      day = parseInt(curDate.substring(0, 2), 10);
      month = parseInt(curDate.substring(3, 5), 10);
      year = (new Date()).getFullYear();
    } else if (curDateDiff !== curDate) {
      splitDate = curDate.split('.');
      day = parseInt(splitDate[0], 10);
      month = parseInt(splitDate[1] || 0, 10);
      year = parseInt(splitDate[2] || 0, 10);
    } else if ( curDate.length == 4 ) {
      day = parseInt(curDate.substring(0, 2), 10);
      month = parseInt(curDate.substring(2, 4), 10);
      year = (new Date()).getFullYear();
    } else if ( curDate.length == 6 ) {
      day = parseInt(curDate.substring(0, 2), 10);
      month = parseInt(curDate.substring(2, 4), 10);
      year = parseInt(curDate.substring(4, 6), 10);
    } else if ( curDate.length == 8) {
      day = parseInt(curDate.substring(0, 2), 10);
      month = parseInt(curDate.substring(2, 4), 10);
      year = parseInt(curDate.substring(4, 8), 10);
    }

    // Tag
    if (day < 10 && day > 0) {day = '0' + day; }

    // Monat
    if (month < 10 && month > 0) {month = '0' + month; }

    if (year < 1) { year = new Date().getFullYear(); }

    // Jahr
    if (year < 1000 && year > 40) { year += 1900; } else { if ( year < 1000 ) year += 2000; }
    let backDate;
    backDate = day + '.' + month + '.' + year;
    if (!this.checkdate(backDate)) {
      backDate = '';
      alert( 'Das eingegebene Datum war fehlerhaft!\n' + day + '.' + month + '.' + year);
    }
    this.modelValue = backDate;
    this.updateModelValue();
    this.datePickerChanged();
  }

  checkdate(date) {
    // (Schritt 1) Fehlerbehandlung
    if (!date) { return false; }
    date = date.toString();

    // (Schritt 2) Aufspaltung des Datums
    date = date.split('.');
    if (date.length != 3) { return false; }

    // (Schritt 3) Entfernung der fuehrenden Nullen und Anpassung des Monats
    date[0] = parseInt(date[0], 10);
    date[1] = parseInt(date[1], 10) - 1 ;

    // (Schritt 4) Behandlung Jahr nur zweistellig
    if (date[2].length == 2) { date[2] = '20' + date[2]; }

    // (Schritt 5) Erzeugung eines neuen Dateobjektes
    const kontrolldatum = new Date(date[2], date[1], date[0]);

    // (Schritt 6) Vergleich, ob das eingegebene Datum gleich dem JS-Datum ist
    if (kontrolldatum.getDate() == date[0] && kontrolldatum.getMonth() == date[1] && kontrolldatum.getFullYear() == date[2]) {
      return true; } else {
      return false; }
  }

  changeMode(mode) {
    this.displayMode = mode;

    if (this.displayMode == 2) {
      this.yearList = this.range(( this.currentYear + this.yearSelectModifier - 10), ( this.currentYear + this.yearSelectModifier + 10), 3);
    }
  }

  range(min, max, step) {
    step = step || 1;
    const input = [];
    for (let i = min; i <= max; i += step) {
      input.push(i);
    }
    return input;
  }

  setDateRange(option,quartal) {
    let startDate;
    let endDate;
    let endDay = null;
    const dateToday = new Date();
    let month;
    let monthString;
    let dayString;
    if(quartal!=0) {
      switch (quartal) {
        case -1:
          startDate = '01.01.' + option;
          endDate = '31.12.' + option
          break;
        case 1:
          startDate = '01.01.' + option;
          endDate = '31.03.' + option;
          break;
        case 2:
          startDate = '01.04.' + option;
          endDate = '30.06.' + option;
          break;
        case 3:
          startDate = '01.07.' + option;
          endDate = '30.09.' + option
          break;
        case 4:
          startDate = '01.10.' + option;
          endDate = '31.12.' + option;
          break;
      }
    }
    else {
      switch (option) {
        case 'Heute':
          endDay = new Date(dateToday.getFullYear(), (dateToday.getMonth() + 1), 0).getDate();
          month = dateToday.getMonth() + 1;
          monthString = month + ''; if (month < 10) { monthString = '0' + monthString; }
          dayString = dateToday.getDate() + ''; if (dateToday.getDate() < 10) { dayString = '0' + dayString; }
          endDate = startDate = dayString + '.' + monthString + '.' + dateToday.getFullYear();
          break;
        case 'Gestern':
          const yesterday = new Date();
          yesterday.setDate(dateToday.getDate() - 1);
          month = yesterday.getMonth() + 1;
          monthString = month + ''; if (month < 10) { monthString = '0' + monthString; }
          dayString = yesterday.getDate() + ''; if (dateToday.getDate() < 10) { dayString = '0' + dayString; }
          endDate = startDate = dayString + '.' + monthString + '.' + yesterday.getFullYear();
          break;
        case 'Aktueller Monat':
          endDay = new Date(dateToday.getFullYear(), (dateToday.getMonth() + 1), 0).getDate();
          month = dateToday.getMonth() + 1;
          monthString = month + '';
          if (month < 10) {
            monthString = '0' + monthString;
          }
          startDate = '01.' + monthString + '.' + dateToday.getFullYear();
          endDate = endDay + '.' + monthString + '.' + dateToday.getFullYear();
          break;
        case 'Vorheriger Monat':
          endDay = new Date(dateToday.getFullYear(), (dateToday.getMonth()), 0).getDate();
          month = dateToday.getMonth();
          monthString = month + '';
          if (month < 10) {
            monthString = '0' + monthString;
          }
          startDate = '01.' + monthString + '.' + dateToday.getFullYear();
          endDate = endDay + '.' + monthString + '.' + dateToday.getFullYear();
          break;
        case 'Zurücksetzen':
          startDate = '';
          endDate = '';
          break;
      }
    }
    if (this.first) {
      this.modelValue = startDate;
      this.boundDatepicker = endDate;
    } else {
      this.modelValue = endDate;
      this.boundDatepicker = startDate;
    }

    this.datePickerChanged();
    this.ngOnChanges(this.modelValue);
    this.boundDatepickerChange.emit(this.boundDatepicker);
    this.showRangeSelector = false;

  }

  onRightClick($event: MouseEvent) {
    // Wenn kein Link zu einem weiteren Datepicker dann kein Menü
    if( this.boundDatepicker==undefined ){
      return;
    }
    if( this.showRangeSelector == false ) {
      this.showRangeSelector = true;
      setTimeout(() => {
        // Wenn das Datumsfeld zu weit unten ist, passt das Auswahlmenü nicht darunter. Dann über das Datumsfeld verschieben.
        if(window.innerHeight - document.getElementById('datePickerRangeSelector' + this.overlayId).offsetTop < 400) {
          document.getElementById('datePickerRangeSelector' + this.overlayId).style.marginTop = '-380px';
        }
      }, 200);} else { this.showRangeSelector = false; }

    return false;
  }

  closeRangeSelector() {
    if(this.showRangeSelector) { this.showRangeSelector = false; }
    return;
  }

}
