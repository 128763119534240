<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Dokumente > Automatische Erkennung
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th style="width:70px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ID"  sortColumn="id"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Regelname" sortColumn="name"></span>
                        </th>
                        <th style="width:70px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Gewichtung" sortColumn="count"></span>
                        </th>
                        <th style="width:50px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Aktiv" sortColumn="active"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td (click)="edit($event,x)" style="width:70px; text-align: center;">{{ x.id }}</td>

                        <td (click)="edit($event,x)">{{ x.name }}</td>

                        <td (click)="edit($event,x)" style="width:70px; text-align: right;">{{ x.count }}</td>

                        <td (click)="edit($event,x)" style="width:50px; text-align: center;">{{ x.active }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop" class="form">
                <span style="margin-left:10px; margin-top:4px; display: inline-block;">
                    <span *ngIf="showNewItemForm">NEU FORMULAR</span>
                    <span *ngIf="currentSelected.id>0">Eintrag bearbeiten</span>
                </span>
                <button *ngIf="this.currentSelected && this.currentSelected.id>0" (click)="openTestInterface()" style="float: right; margin-right:4px; margin-top: 2px;">
                    Regel Testen
                </button>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="(currentSelected!==undefined && currentSelected.id > 0) || showNewItemForm" class="form" style="margin-top: 10px;">
                    <table cellspacing="0" cellpadding="4" style="width: calc( 100% - 24px ); margin-left:10px;" class="form">
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Regelname</span><BR>
                                <input id="ruleName" type="text" [(ngModel)]="currentSelected.name" style="width:calc( 100% - 90px );">
                                <app-checkbox [(model)]="currentSelected.active" [lable]="'Aktiv'" style="float: right;"></app-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%;">
                                <span class="formCaption">Suchwörter</span><BR>
                                <div style="width:100%; height:150px; overflow: auto; border: 1px solid #c8c8c8;">
                                    <table style="width:100%;" class="data_tabel" cellpadding="4" cellspacing="0">
                                        <tr *ngFor="let word of currentSelected.words">
                                            <td (click)="editWord(word)">{{word.name}}</td>
                                            <td style="width: 24px;" (click)="deleteWord(word)"><img src="../design/icons/delete.png"></td>
                                        </tr>
                                    </table>
                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editWord == null">
                                    <input type="text" [(ngModel)]="editorAdditionalData.newWord" style="width:calc( 100% - 38px);">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/add.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveWord()">
                                    </button>
                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editWord != null">
                                    <input type="text" [(ngModel)]="editorAdditionalData.editWord.name" style="width:calc( 100% - 38px);">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/disk.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveEditWord()">
                                    </button>
                                </div>
                            </td>

                            <td style="width:50%;">
                                <span class="formCaption">Ausgeschlossene Suchwörter</span><BR>
                                <div style="width:100%; height:150px; overflow: auto; border: 1px solid #c8c8c8;">
                                    <table style="width:100%;" class="data_tabel" cellpadding="4" cellspacing="0">
                                        <tr *ngFor="let word of currentSelected.words_not">
                                            <td (click)="editNotWord(word)">{{word.name}}</td>
                                            <td style="width:24px;" (click)="deleteNotWord(word)"><img src="../design/icons/delete.png"></td>
                                        </tr>
                                    </table>
                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editNotWord == null">
                                    <input type="text" [(ngModel)]="editorAdditionalData.newNotWord" style="width:calc( 100% - 38px);">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/add.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveNoWord()">
                                    </button>
                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editNotWord != null">
                                    <input type="text" [(ngModel)]="editorAdditionalData.editNotWord.name" style="width:calc( 100% - 38px);">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/disk.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveEditNoWord()">
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table cellspacing="0" cellpadding="4" style="width: calc( 100% - 24px ); margin-left:10px;" class="form">
                        <tr>
                            <th colspan="2">
                            Zu setzende Metadaten
                            </th>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                <span class="formCaption">Dokumentengruppe *</span>
                                <select [(ngModel)]="currentSelected.meta_fk_documentgroup"
                                        (change)="changeDocumentGroup()"
                                        id="currentSelectedMetaRightGroup"
                                        style="width:100%;"
                                        tabindex="105">
                                    <option value="0">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataDocumentGroup" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>

                            <td style="width: 50%;">
                                <span class="formCaption">Firma / Ansprechpartner</span>
                                <app-contact-manager
                                        mode="free"
                                        [(company)]="currentSelected.meta_fk_company"
                                        [(contact)]="currentSelected.meta_fk_contact"
                                        style="width: 100%;" >
                                </app-contact-manager>
                            </td>

                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Tags</span><br>
                                <span class="tagline"><span *ngFor="let x of currentSelected.tags" class="form"><button>{{x.name}} <img
                                        src="../design/icons/delete.png" (click)="tagRemove(x)"></button></span></span>
                                <select *ngIf="tagList.length < 4"
                                        [(ngModel)]="documentTag"
                                        (change)="documentTagClicked()"
                                        id="currentSelectedMetaTags"
                                        style="width: 150px;"
                                        tabindex="106">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of documentTagDataSource" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2">
                                Titel und Datumserkennung
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Titel prefix</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.meta_title"
                                       id="currentSelectedMetaTitle"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>
                            <td>
                                <span class="formCaption">Welches Datum</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.date_position"
                                       id="currentSelectedDatePosition"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2">
                                Titel Such Regel
                                <div style="width:100%; height:150px;overflow: auto;border: 1px solid #c8c8c8; text-align: left;">
                                    <table style="width:100%;" class="data_tabel" cellpadding="4" cellspacing="0">
                                        <tr *ngFor="let titleSearch of currentSelected.titleSearch">
                                            <td (click)="editTitleSearch(titleSearch)" style="width: 30%;">{{titleSearch.title_search_from}}</td>
                                            <td (click)="editTitleSearch(titleSearch)" style="width: 50%;">{{titleSearch.title_search_pattern}}</td>
                                            <td (click)="editTitleSearch(titleSearch)" style="width: 24px;">{{titleSearch.title_search_position}}</td>
                                            <td (click)="editTitleSearch(titleSearch)" style="width: 24px;">{{titleSearch.title_search_separator}}</td>
                                            <td (click)="editTitleSearch(titleSearch)" style="width: 24px;"><img src="../design/icons/delete.png"></td>
                                        </tr>
                                    </table>

                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editTitleRule != null">
                                    <input type="text" placeholder="Suchen ab Wort" [(ngModel)]="editorAdditionalData.editTitleRule.title_search_from" style="width:calc( 45% - 35px);">
                                    <input type="text" placeholder="RegEx Suchpattern" [(ngModel)]="editorAdditionalData.editTitleRule.title_search_pattern" style="width:calc( 45% - 35px);">
                                    <input type="text" placeholder="Pos" [(ngModel)]="editorAdditionalData.editTitleRule.title_search_position" style="width:4% ;">
                                    <input type="text" placeholder="Trenner" [(ngModel)]="editorAdditionalData.editTitleRule.title_search_separator" style="width:6%;">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/disk.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveEditNoWord()">
                                    </button>
                                </div>
                                <div style="margin-top: 8px;" *ngIf="editorAdditionalData.editTitleRule == null">
                                    <input type="text" placeholder="Suchen ab Wort" [(ngModel)]="editorAdditionalData.newTitleRule.title_search_from" style="width:calc( 45% - 35px);">
                                    <input type="text" placeholder="RegEx Suchpattern" [(ngModel)]="editorAdditionalData.newTitleRule.title_search_pattern" style="width:calc( 45% - 35px);">
                                    <input type="text" placeholder="Pos" [(ngModel)]="editorAdditionalData.newTitleRule.title_search_position" style="width: 4% ;">
                                    <input type="text" placeholder="Trenner" [(ngModel)]="editorAdditionalData.newTitleRule.title_search_separator" style="width: 6% ;">
                                    <button style="width: 24px; float: right;">
                                        <img src="../design/icons/disk.png" style="vertical-align: sub;margin-left:-4px;" (click)="saveEditNoWord()">
                                    </button>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: right;">
                                <button (click)="save()">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
