import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.css']
})
export class UserManagerComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public userContactData = [];
  public companyData = [];
  public teamData = [];
  public userTeams = [];
  public roleData = [];
  public selectTeam = false;
  public selectableTeams = [];
  public salutations = [];
  public titles = [];
  public nickAlreadyExists = [];
  public possibleSubstituteUsers = [{ id: 0, user: 0, vertretung: 0, priority: 0, name: '' }];
  public activeSubstituteUser = { vertretung: 0 };
  public pwChangeForm = false;
  public pwChange;
  public existingLeader = { id: ''};
  public passwordComplexity = false;
  public siteData = [];
  public currentUserSite;
  public nickCheck = false;
  public possibleEmailAddresses = [];
  public selectedEmailAddress = { id: '', target: '' };
  public siteSelected = false;
  public siteSelectedId = '';
  public startSite;
  public selectedSubstituteUserId = 0;
  public siteSearchParameterList = [];
  public selectedTeam = -1;
  public companyId;
  public dropdownDataUser = [];
  public customerDropdownDataUser = [];
  public showCopyUserForm = false;
  public getCopyUserData = false;
  public copiedUserId;
  public showChangePw = false;
  public newPassword1;
  public newPassword2;
  public newPasswordCheck = { length : false, special: false, upper: false, lower: false, digits: false, same: false };
  public documentRightGroups;
  public selectedDocumentRightGroups;
  public userRights;
  public selectedUserRights;
  public connectionsCalled = false;
  public nextTabindex;
  public backTabindex;
  public dropdownDataEntrypointMenu;
  public showProcessOption = false;
  public showErpOption = false;
  public showDocumentOption = false;
  public showInventoryOption = false;
  public deactivationDateMessageContent;
  public timeTrackingModuleActiveState;
  public dropdownWorkTimeModelData;
  public contactIdToUser;
  public saveFromExistingContact = false;

  public showExistingContactDropdown = false;
  public existingContact = [];
  public newUserTeams: any;

    initCurrentSelected(): void {
    this.currentSelected = { id : '', active: 0, salutation : 0,  title: 0, first_name : '', name : '', nick : '', e_mail: '', other_e_mail: '',  outDate : '', password: '', password_confirmation: '', phone_public: '', mobile_public: '', phone_business: '', mobile_business: '', street: '', street_number: '', postcode: '', city_name: '', position: '', profession: '', birth_date: '', company : '', site: '', default_team: '-1', fk_time_tracking_model: '-1' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'name', active : '1', text: '', type : 1, company: this.currentUserSite, site: '-1' };
  }

  initSelectedTeams(): void {
    this.checkboxData = [];
  }

  initPwChangeObject(): void {
      this.newPassword1 = '';
      this.newPassword2 = '';
      this.newPasswordCheck = { length : false, special: false, upper: false, lower: false, digits: false, same: false };
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService, public routing: Router, public route: ActivatedRoute, ) {
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.initCurrentSelected();
    this.getUserCompanySites();
    this.searchResultCount = 0;
    this.searchQuantity = 300;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'contact/search', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.userContactData = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/company', {active: 1, sort:'loginUser'}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyData = data;
        }
    );

    this.http.post<{status, count, data}>(settings.restBaseUrl + 'team', {active: 1, sort: 'name'}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.teamData =  this.teamData.concat(data.data);
        }
    );

    this.http.post<{status, count, data}>(settings.restBaseUrl + 'team/role' , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.roleData =  this.roleData.concat(data.data);
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/salutation' , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < data.length ; i++) {
            if (data[i].type === '0') {
              this.salutations.push({ id: data[i].id, name: data[i].name });
            } else {
              this.titles.push({ id: data[i].id, name: data[i].name });
            }
          }
        }
    );
      this.handleRouteParameter();
  }

    handleRouteParameter() {
        this.route.paramMap.subscribe(params => {
            if (params.get('action') == 'edit') {
                setTimeout(() => {
                    this.edit(params.get('id'));
                }, 400);
            } else if (params.get('action') == 'new') {
                this.currentSelected.company = '1';
                setTimeout( () => {
                    this.getCompanySites();
                    this.getNewUserTeams();
                    document.getElementById('NewItemFormSalutation').focus();
                }, 100);

                setTimeout(() => {
                this.showNewItemForm = true;
                this.saveFromExistingContact = true;
                this.contactIdToUser = params.get('id');
                this.currentSelected.name = params.get('name');
                }, 400);
            }
        });
    }

  ngOnInit() {
      this.checkActiveTimeTrackingModule();
      this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.companyId = data;

              this.search(false);
              setTimeout(() => {
                  document.getElementById('searchText').focus();
                  this.getDropdownUserData();
              }, 200);
          }
      );
  }

    checkExistingContact() {

            if (this.currentSelected.name.length > 2) {
                this.http.post<any>(this.settings.restBaseUrl + 'contact/existing/1', {first_name: this.currentSelected.first_name, name: this.currentSelected.name, email: this.currentSelected.email}, this.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        // @ts-ignore
                        this.existingContact = data.data;
                        if (this.existingContact.length > 0) { this.showExistingContactDropdown = true; } else { this.showExistingContactDropdown = false; }
                    }
                );
            } else {
                this.showExistingContactDropdown = false;
        }
    }

    closeExistingContactOverlay() {
        setTimeout(() => {
            this.showExistingContactDropdown = false;
        }, 300);
    }


  search(loadNextSearchResults: boolean): void {
    this.initCurrentSelected();
    this.showNewItemForm = false;
    this.siteSelected = false;
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'user/manager' , JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
          this.passwordComplexity = false;
        }
    );
  }

  getUserCompanySites() {
      this.getUserCompany();
      this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/userSite', {active: 1}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.siteSearchParameterList = data;
          }
      );
  }

    getUserCompany() {
        this.http.get<any[]>(this.settings.restBaseUrl + 'contact/company/userSite', this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.searchParameter.company = data;
            }
        );
    }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  getDropdownUserData() {
      this.dropdownDataUser = [];
      this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', {active: 1, company: this.companyId}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              const interimUserArray = data.data;
              interimUserArray.forEach((element) => {
                  if (element.id !== this.currentSelected.id) {
                      this.dropdownDataUser.push(element);
                  }
              });
          }
      );
  }
  getCustomerDropdownUserData() {
      this.customerDropdownDataUser = [];
      this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', {active: 1, company: this.currentSelected.company}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              const interimCustomerUserArray = data.data;
              interimCustomerUserArray.forEach((element) => {
                  if (element.id !== this.currentSelected.id) {
                      this.customerDropdownDataUser.push(element);
                  }
              });
          }
      );
  }

    checkActiveTimeTrackingModule() {
        this.http.get<any[]>(this.settings.restBaseUrl + 'module/17', this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                // @ts-ignore
                this.timeTrackingModuleActiveState = data.active;
                if (this.timeTrackingModuleActiveState === '1') { this.getWorktimeModels(); }
            }
        );
    }

    getWorktimeModels() {
        this.http.post<any>(this.settings.restBaseUrl + 'timeTracking/workTimeModel', {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownWorkTimeModelData = data;
            }
        );
    }

    changeSearchType() {
      this.searchParameter.company = '';
      this.siteSearchParameterList = [];
      if (this.searchParameter.type === '1') {
          this.getUserCompanySites();
      }
      setTimeout(() => {
          this.search(false);
      }, 100);
      this.currentSelectedTab = 1;
    }

  new(): void {
    this.getNewUserTeams();
    this.saveFromExistingContact = false;
    this.initCurrentSelected();
    this.initPwChangeObject();
    this.pwChangeForm = false;
    this.passwordComplexity = false;
    this.showNewItemForm = true;
    this.selectTeam = false;
    this.nickAlreadyExists = [];
    this.siteData = [];
    this.currentUserSite = null;
    this.siteSelected = false;
    if (this.searchParameter.type === '0') {
        this.currentSelected.company = this.searchParameter.company;
    } else {
        // todo: mehrfach mandant?
        this.currentSelected.company = 1;
    }
      this.getCompanySites();
      setTimeout( () => {
          document.getElementById('NewItemFormSalutation').focus();
      }, 300);
  }

  checkUserNickExistence() {
      let selectedNick;

          selectedNick = this.currentSelected.nick;

      this.nickCheck = false;
      const nickData = {
          nick: selectedNick
      };

      this.http.post<{message}>(this.settings.restBaseUrl + 'user/nickCheck', nickData, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.nickAlreadyExists = data.message;
            if (this.nickAlreadyExists !== null) {
                this.nickCheck = true;
            }
          }
      );
  }

    checkDeaktivationDate() {
        const dateArr = this.currentSelected.outDate.split('.');
        // tslint:disable-next-line:radix
        const dateToCompare = new Date( parseInt(dateArr[2]), parseInt(dateArr[1]) - 1, parseInt(dateArr[0]));
        const today = new Date();
        if (dateToCompare < today) {
            this.deactivationDateMessageContent = 'Durch das Setzen eines Datums in der Vergangenheit wird der Benutzer sofort deaktiviert. Sind Sie sicher, dass Sie fortfahren möchten?';
            this.message.open('deactivationDateWarning');
        } else {
            this.save();
        }
    }

    closeMessageBox() {
        this.message.close('deactivationDateWarning');
    }

    saveWithDeactivate(): void{

        this.http.put<any[]>(this.settings.restBaseUrl + 'user/active/' + this.currentSelected.id + '/' + '0', {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data2 => {
                setTimeout(() => {
                    this.search(false);
                }, 300);
                this.initCurrentSelected();
                this.showNewItemForm = false;
            });

        this.save();
    }

    checkContact(x)
    {
        if(x.companyId > 0){
            this.message.open('contactChoose');
            this.contactIdToUser = x.id;
            this.currentSelected.name = x.name;
        }
        else{this.message.open('privateContact');}
    }

    unsetUser()
    {
        this.contactIdToUser = '';
    }

    chooseContact()
    {
        this.saveFromExistingContact = true;
        this.message.close('contactChoose');
    }

    save(): void {

        if (this.currentSelected.salutation < 1) {
            alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Anrede aus.');
            return;
        } else if (this.currentSelected.name == null || this.currentSelected.name === '') {
            alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
            return;
        } else if (this.currentSelected.nick === '' || this.currentSelected.nick == null) {
            alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Benutzernamen ein.');
            return;
        } else if (this.nickCheck) {
            alert('Der Benutzername ist bereits vergeben.\nBitte wählen Sie einen anderen.');
            return;
        } else if (this.currentSelected.password === '' && this.showNewItemForm || this.currentSelected.password_confirmation === '' && this.showNewItemForm || this.currentSelected.password == null && this.showNewItemForm || this.currentSelected.password_confirmation == null && this.showNewItemForm || this.currentSelected.password !== this.currentSelected.password_confirmation && this.showNewItemForm) {
            alert('Alle Pflichtfelder müssen ausgefüllt sein \nund das Passwort muss mit der Passwortbestätigung übereinstimmen.');
            return;
        } else if (this.currentSelected.company === '' && this.searchParameter.type === '0' || this.currentSelected.company == null && this.searchParameter.type === '0') {
            alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Firma aus.');
            return;
        } else if (this.currentSelected.default_team === '-1' || this.showNewItemForm && this.currentSelected.default_team == null && this.searchParameter.type === '1' || this.currentSelected.default_team === '' && this.searchParameter.type === '1' || this.currentSelected.default_team < 1 && this.searchParameter.type === '1') {
            alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie ein Standardteam aus.');
            return;
        } else if (this.showNewItemForm && !this.newPasswordCheck.special || this.showNewItemForm && !this.newPasswordCheck.length || this.showNewItemForm && !this.newPasswordCheck.same || this.showNewItemForm && !this.newPasswordCheck.digits || this.showNewItemForm && !this.newPasswordCheck.lower || this.showNewItemForm && !this.newPasswordCheck.upper) {
            alert('Das Passwort entspreicht nicht den Komplexätitsregeln. Es müssen alle Punkte grün Markiert sein!');
            return;
        } else if (false && (this.currentSelected.site === '' || this.currentSelected.site < 1 || this.currentSelected.site === null)) {
            // todo: standort nur wen die Firma auch welche hat
            //alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie einen Standort aus.');
            //return;
        } else {


        if (this.currentSelected.title == null) {
            this.currentSelected.title = 0;
        }

        let endpointIdAdd = '';
        if (this.currentSelected.id > 0) {
            endpointIdAdd = '/' + this.currentSelected.id;
        }

        const contactData = {
            salutation: this.currentSelected.salutation,
            name: this.currentSelected.name,
            first_name: this.currentSelected.first_name,
            title: this.currentSelected.title,
            company: this.currentSelected.company,
            site: this.currentSelected.site,
            position: this.currentSelected.position,
            profession: this.currentSelected.profession,
            birth_date: this.currentSelected.birth_date,
            out_date: this.currentSelected.outDate
        };

        let item = {id: ''};

        this.http.put<{ message }>(this.settings.restBaseUrl + 'contact' + endpointIdAdd, contactData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data2 => {
                let ID;
                if (this.showNewItemForm || this.showCopyUserForm) {
                    ID = data2.message;
                } else {
                    ID = this.currentSelected.id;
                }
                item.id = ID;
                this.putUserItem(ID);
                if (this.showNewItemForm) {
                    this.setTeamLinkUser(ID);
                }
            }
        );

        this.currentSelectedTab = 2;
        setTimeout(() => {
            this.edit(item);
        }, 1000);
    }
    }

    putUserTimeTrackingModelLink(id) {
        this.http.put<any[]>(this.settings.restBaseUrl + 'timeTracking/workTimeModel/user' ,
            { user: id, fk_time_tracking_model: this.currentSelected.fk_time_tracking_model }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            });
    }

    deleteUserTimeTrackingModelLink(id) {
        this.http.delete<any[]>(this.settings.restBaseUrl + 'timeTracking/workTimeModel/user/' + id, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            });
    }

    saveFromExistingContactFunction()
    {
        if(this.saveFromExistingContact == false){
        this.checkDeaktivationDate();}

        if(this.saveFromExistingContact == true){

            if (this.currentSelected.nick === '' || this.currentSelected.nick == null) {
                alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Benutzernamen ein.');
                return;
            } else if (this.nickCheck) {
                alert('Der Benutzername ist bereits vergeben.\nBitte wählen Sie einen anderen.');
                return;
            } else if (this.currentSelected.password === '' && this.showNewItemForm || this.currentSelected.password_confirmation === '' && this.showNewItemForm || this.currentSelected.password == null && this.showNewItemForm || this.currentSelected.password_confirmation == null && this.showNewItemForm || this.currentSelected.password !== this.currentSelected.password_confirmation && this.showNewItemForm) {
                alert('Alle Pflichtfelder müssen ausgefüllt sein \nund das Passwort muss mit der Passwortbestätigung übereinstimmen.');
                return;
            } else if (this.currentSelected.default_team === '-1' || this.showNewItemForm && this.currentSelected.default_team == null && this.searchParameter.type === '1' || this.currentSelected.default_team === '' && this.searchParameter.type === '1' || this.currentSelected.default_team < 1 && this.searchParameter.type === '1' ) {
                alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie ein Standardteam aus.');
                return;
            } else if (this.showNewItemForm && !this.newPasswordCheck.special || this.showNewItemForm && !this.newPasswordCheck.length || this.showNewItemForm && !this.newPasswordCheck.same || this.showNewItemForm && !this.newPasswordCheck.digits || this.showNewItemForm && !this.newPasswordCheck.lower || this.showNewItemForm && !this.newPasswordCheck.upper) {
                alert('Das Passwort entspreicht nicht den Komplexätitsregeln. Es müssen alle Punkte grün Markiert sein!');
                return;
            } else if (this.currentSelected.site === '' || this.currentSelected.site < 1 || this.currentSelected.site === null ) {
                alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie einen Standort aus.');
                return;
            }

        let item = {id : ''};

        item.id = this.contactIdToUser;

        this.currentSelectedTab = 2;

        this.setTeamLinkUser(this.contactIdToUser);
        this.putUserItem(this.contactIdToUser);

        this.edit(item);
        }
    }


    putUserItem(newId) {

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
       endpointIdAdd = '/' + this.currentSelected.id;
    }
    if(this.saveFromExistingContact == true)
    {
        endpointIdAdd = '/' + newId;
    }

    if(this.currentSelected.company == '') {this.currentSelected.company = 1;}
    const userData = {
        id: newId,
        nick: this.currentSelected.nick,
        password: this.currentSelected.password,
        company: this.currentSelected.company,
        site: this.currentSelected.site,
        outDate: this.currentSelected.outDate
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'user' + endpointIdAdd, userData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            if(this.saveFromExistingContact == false)
            {
            if (this.showCopyUserForm) { this.copyUserLinks(newId); } else { this.putUserSiteLink(newId); }}
        }
    );

        this.contactIdToUser = '';
        this.saveFromExistingContact = false;
  }

    putUserSiteLink(id) {

        if(this.currentSelected.site === undefined){this.currentSelected.site = this.siteSelectedId;}

        let currentContactId;
        if (this.showNewItemForm) {
            currentContactId = id;
        } else {
            currentContactId = this.currentSelected.id;
        }
        const siteLinkData = {
            contactId: currentContactId,
            siteId: this.currentSelected.site
        };
        if (this.siteData.length > 0) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'contact/company/address/' + this.currentUserSite,
                siteLinkData,
                this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.putConnections(id);
                    this.getDropdownUserData();
                });
        } else {
            this.putConnections(id);
            this.getDropdownUserData();
        }

    }

    public copyUserLinks(id: any) : void {
        this.http.put<any>(this.settings.restBaseUrl + 'user/copy/links/' + id + '/' + this.copiedUserId, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.copiedUserId = null;
                this.showCopyUserForm = false;
                this.getCopyUserData = false;
            }
        );
    }

    putConnections(id) {
        if (this.showNewItemForm) {
            const connectionsData = {
                phone_public: this.currentSelected.phone_public,
                mobile_public: this.currentSelected.mobile_public,
                phone_business: this.currentSelected.phone_business,
                mobile_business: this.currentSelected.mobile_business,
                e_mail: this.currentSelected.e_mail,
                e_mail_public: this.currentSelected.other_e_mail,
                fk_contact: id
            };

            this.http.put<any[]>(this.settings.restBaseUrl + 'contact/connection/0/1', connectionsData, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.search(false);
                    this.initCurrentSelected();
                    this.showNewItemForm = false;
                }
            );
        } else {
            this.search(false);
            this.initCurrentSelected();
        }
        this.passwordComplexity = false;
        this.siteSelected = false;
        this.startSite = 0;
    }

    edit(item: any): void  {
        let id;
        if(item.id===undefined) { id = item; } else { id=item.id } // Aufruf direkt über ID ermögliche.
        this.showCopyUserForm = false;
        this.initCurrentSelected();
        this.siteSelected = false;
        this.currentUserSite = '';
        this.selectedSubstituteUserId = 0;
        this.http.get<any[]>(
            this.settings.restBaseUrl + 'user/manager/' + id,
            this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (this.showChangePw) {
                    this.currentSelectedDeclaration(data);
                } else {
                    this.currentSelectedDeclaration(data);
                    this.showNewItemForm = false;
                    this.pwChangeForm = false;
                    this.selectTeam = false;
                    this.nickAlreadyExists = [];
                    this.passwordComplexity = false;
                    if (this.getCopyUserData) {
                        this.copiedUserId = this.currentSelected.id;
                        this.currentSelected.salutation = '';
                        this.currentSelected.name = '';
                        this.currentSelected.first_name = '';
                        this.currentSelected.nick = '';
                        this.currentSelected.id = null;
                    }
                    if (this.searchParameter.type === '1') { this.getDropdownUserData(); } else { this.getCustomerDropdownUserData(); }
                    setTimeout(() => {
                        if (this.currentSelectedTab === 1) {
                            document.getElementById('currentSelectedSalutation').focus();
                        }
                        if (this.currentSelectedTab === 4) {
                            this.selectedSubstituteUserId = 0;
                            document.getElementById('assignableTeam').focus();
                        }
                        if (this.userTeams.length < 1) {
                            this.currentSelected.default_team = 0;
                        }
                    }, 300);
                }
                });
    }

    currentSelectedDeclaration(data) {
        this.currentSelected = data;
        if (this.currentSelected.outDate === '01.01.1970') {
            this.currentSelected.outDate = '';
        }
        if (this.currentSelected.fk_time_tracking_model == null) {
            this.currentSelected.fk_time_tracking_model = '-1';
        }
        this.initSelectedTeams();
        this.initPwChangeObject();
        this.getCompanySites();
        this.getActiveSubstituteUser();
        this.getPossibleUserSubstitution();
        this.getUserTeams();
        this.getUserSiteLink();
        this.getSelectableTeams();
    }

    checkPasswordComplexity(item) {
        const num = /[0-9]/;
        const special = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
        const upperCase = /[A-Z]/;
        const lowerCase = /[a-z]/;
        let result = true;

        if (item.length < 8) {
            result = false;
        }

        let numNums = 0;
        let numSpecials = 0;
        let numUpperCase = 0;
        let numLowerCase = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i <= item.length; i++) {
            if (num.test(item[i])) {
                numNums++;
            } else if (special.test(item[i])) {
                numSpecials++;
            } else if (upperCase.test(item[i])) {
                numUpperCase++;
            } else if (lowerCase.test(item[i])) {
                numLowerCase++;
            }
        }

        if (numNums < 1 || numSpecials < 1 || numUpperCase < 1 || numLowerCase < 1) {
            result = false;
        }
        this.passwordComplexity = result;
    }

  getUserTeams() {
      this.http.post<any[]>(this.settings.restBaseUrl + 'user/team/' + this.currentSelected.id , {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.userTeams = data;
          }
      );
  }

  openContactQuestionDialogOnDeactivateUser(item) {
        this.edit(item);
        this.message.open('contactDeactivate');
  }

    deactivateUser(changeContact) {
        this.message.close('contactDeactivate');
        this.changeActiveStatus(this.currentSelected, changeContact);
    }

    openContactQuestionDialogOnActivateUser(item) {
        this.edit(item);
        this.message.open('contactActivate');
    }

    activateUser() {
        this.message.close('contactActivate');
        this.changeActiveStatus(this.currentSelected, 1);
    }

  changeActiveStatus(item,changeContact) {
      let activeStatus = 0;
      if (item.active == 0) { activeStatus = 1; } else { activeStatus = 0; }

      setTimeout(() => {
          this.http.put<any[]>(this.settings.restBaseUrl + 'user/active/' + item.id + '/' + activeStatus, {  }, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data2 => {
                  if(changeContact == 0 || changeContact == 1) {
                      this.http.put<any[]>(this.settings.restBaseUrl + 'contact/active/' + item.id + '/' + changeContact, {}, this.httpOptions)
                          .pipe(map(data => data)).subscribe(
                          data3 => {
                              setTimeout(() => {
                                  this.search(false);
                              }, 300);
                              this.initCurrentSelected();
                              this.showNewItemForm = false;
                          });
                  } else {
                      setTimeout(() => {
                          this.search(false);
                      }, 300);
                      this.initCurrentSelected();
                      this.showNewItemForm = false;
                  }
              });
      }, 100);
  }

  checkUserTeamRole(item) {
      let leaderChange: boolean;
      // tslint:disable-next-line:triple-equals
      if (item.fk_team_role == 5 ) {
        this.http.get<any>(this.settings.restBaseUrl + 'team/leaderCheck/' + item.fk_groupid, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                   this.existingLeader = data;
                   if (this.existingLeader != null) {
                   leaderChange = window.confirm('Es existiert bereits in Leiter für dieses Team!\nSind Sie sicher, dass Sie den Leiter Wechseln möchten ?');
                   if (leaderChange) {
                       this.setUserTeamRole(item.fk_team_role, item.id);
                       this.setUserTeamRole(4, this.existingLeader.id);
                   } else {
                       this.getUserTeams();
                   }
                } else {
                       this.setUserTeamRole(item.fk_team_role, item.id);
                }
            }
        );
    } else {
          if ( item.fk_team_role < 2 ) {
              this.http.get<any>(this.settings.restBaseUrl + 'team/responsibleCheck/' + item.fk_groupid, this.httpOptions)
                  .pipe(map(data => data)).subscribe(
                  data => {
                      // tslint:disable-next-line:triple-equals
                      if (data.message < 2 ) {
                          this.message.open('teamResponsibleWarning');
                      }
                  }
              );
          }
          this.setUserTeamRole(item.fk_team_role, item.id);
      }
  }

  setUserTeamRole(role , id) {
      this.http.put<any[]>(this.settings.restBaseUrl + 'team/role/' + id + '/' + role, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.getUserTeams();
          });
  }

  setTeamLinkUser(id) {
        let autoSetValue = 0;
        let teamId;
        if (this.userTeams.length < 1 || this.showNewItemForm) {
            autoSetValue = 1;
        }
        if (this.showNewItemForm) {
            teamId = this.currentSelected.default_team;
        } else { teamId = this.selectedTeam; }

        if (this.selectedTeam > -1 || this.showNewItemForm) {
        const selectedTeamsdata = {
            fk_groupid: teamId,
            fk_userid: id,
            default_team: autoSetValue,
            fk_team_role: 3,
            standartteam: autoSetValue

        };

        this.http.put<any[]>(
            this.settings.restBaseUrl + 'user/team' , selectedTeamsdata, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedTeam = -1;
                this.getUserTeams();
                this.getSelectableTeams();
            });
    } else {
        alert('Wählen Sie ein Team aus um dieses Hinzuzufügen.');
    }
  }

  getSelectableTeams() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'user/team/selectable/' + this.currentSelected.id , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.selectableTeams = data;
        }
    );
  }

  deleteTeamLink(item) {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(
          this.settings.restBaseUrl + 'team/link/' + item.id, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getUserTeams();
            this.getSelectableTeams();
          });
    }
  }

  setStandardTeam(item) {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'user/team/standard/' + item.id + '/' + item.fk_userid , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.getUserTeams();
          this.currentSelected.default_team = item.fk_groupid;
        });
  }

  routeToTeam() {
        this.message.close('teamLeaderWarning');
        this.routing.navigate(['/process-team-management']);
  }

  getPossibleUserSubstitution() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.possibleSubstituteUsers = data;
        }
    );
  }

  getActiveSubstituteUser() {
    this.http.get<any>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.activeSubstituteUser = data;
        }
    );
  }

  setSubstituteUser(item) {
    const substituteUserData = {
      user: this.currentSelected.id,
      vertretung: item.vertretung
    };

    this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution' , substituteUserData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.getPossibleUserSubstitution();
          this.getActiveSubstituteUser();
        }
    );
  }

  unsetSubstituteUser() {
    this.http.delete<any[]>(this.settings.restBaseUrl + 'user/substitution/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        this.getPossibleUserSubstitution();
        this.getActiveSubstituteUser();
        }
    );
  }

  deleteSubstituteUser(item) {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(
          this.settings.restBaseUrl + 'user/substitution/possible/' + this.currentSelected.id + '/' + item.vertretung, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getPossibleUserSubstitution();
          });
    }
  }

  putSubstituteUserPriority() {
    this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution/priority/' + this.possibleSubstituteUsers[0].priority + '/' + this.possibleSubstituteUsers[0].id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.possibleSubstituteUsers.shift();
          if (this.possibleSubstituteUsers.length > 0) {
            this.putSubstituteUserPriority();
          } else {
            this.getPossibleUserSubstitution();
          }
        });
  }

  increaseSubstituteUserPriority(item) {
    item.priority--;
    const increasingItem = this.possibleSubstituteUsers.findIndex(i => i.user === item.user && i.vertretung === item.vertretung);

    this.possibleSubstituteUsers[increasingItem - 1].priority++;
    this.putSubstituteUserPriority();
  }

  decreaseSubstituteUserPriority(item) {
    item.priority++;
    const decreasingItem = this.possibleSubstituteUsers.findIndex(i => i.user === item.user && i.vertretung === item.vertretung);

    this.possibleSubstituteUsers[decreasingItem + 1].priority--;
    this.putSubstituteUserPriority();
  }

  putSelectedSubstituteUserAsPossible() {
      if (this.selectedSubstituteUserId < 1) {
          alert('Wählen Sie einen Kontakt aus um diesen als Vertretung hinzuzufügen.');
          return;
      } else {
          this.possibleSubstituteUsers.forEach((item) => {
              if (item.vertretung === this.selectedSubstituteUserId) {
                  alert('Der Kontakt befindet sich Bereits in der Vertretungsliste für diesen Benutzer.');
                  return;
              }
          });
      }


      if (this.selectedSubstituteUserId > 0) {
          this.http.put<any[]>(this.settings.restBaseUrl + 'user/substitution/possible/' + this.selectedSubstituteUserId + '/' + this.currentSelected.id , this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.getPossibleUserSubstitution();
              });
      }
  }

  changePassword(item) {
      this.edit(item);
      this.changeTabButton(1);
      this.showChangePw = true;
      this.pwChangeForm = true;
      this.passwordComplexity = false;
      setTimeout(() => {
          this.showChangePw = false;
          document.getElementById('changePasswordOne').focus();
      }, 500);
  }

  backPasswordChange() {
      this.showChangePw = false;
      this.pwChangeForm = false;
      this.initPwChangeObject();
  }

  saveChangedPassword() {
      if (!(this.newPasswordCheck.length && this.newPasswordCheck.special && this.newPasswordCheck.upper && this.newPasswordCheck.lower && this.newPasswordCheck.digits && this.newPasswordCheck.same)) {
          alert('Das Passwort entspreicht nicht den Komplexätitsregeln. Es müssen alle Punkte grün Markiert sein!');
          return;
      } else {
          this.http.put<{ status: string, message: string }>(this.settings.restBaseUrl + 'user/passwordChange/' + this.currentSelected.id , { type: 'coreUserManager', password: this.newPassword1 }, this.settings.httpOptions)
              .pipe(map(data => data)).subscribe(
              (data => {
                      alert('Passwort erfolgreich geändert.');
                      this.newPassword1 = '';
                      this.newPassword2 = '';
                      this.newPasswordCheck.length = false;
                      this.newPasswordCheck.special = false;
                      this.newPasswordCheck.upper = false;
                      this.newPasswordCheck.lower = false;
                      this.newPasswordCheck.digits = false;
                      this.newPasswordCheck.same = false;
                      this.pwChangeForm = false;
                  }
              ));
      }
  }

  forgotPassword(item) {
      this.edit(item);
      setTimeout(() => {
          const sendMail = window.confirm('Sind Sie sicher, dass Sie dem Benutzer eine E-Mail, zur Neuvergabe eines Passwortes, senden wollen ?');
          if (sendMail) {
              this.http.post<any[]>(this.settings.restBaseUrl + 'contact/forgotPw/' + this.currentSelected.id, {}, this.httpOptions)
                  .pipe(map(data => data)).subscribe(
                  data => {
                      this.possibleEmailAddresses = data;
                      if (this.possibleEmailAddresses.length < 2) {
                          this.selectedEmailAddress = this.possibleEmailAddresses[0];
                          this.sendforgotPwEmailForm(this.selectedEmailAddress);
                      } else {
                          document.getElementById('EmailSelectModal').style.display = 'block';
                      }
                      if (this.possibleEmailAddresses.length < 1) {
                          alert('Für diesen Benutzer ist keine E-Mail-Adresse hinterlegt.');
                      }
                  }
              );
          }
      }, 100);
  }

    checkPassword(password1, password2) {
        if (password1.length >= 8) { this.newPasswordCheck.length = true; } else { this.newPasswordCheck.length = false; }
        if (password1.replace(/[^\W]/g, '').length >= 1) { this.newPasswordCheck.special = true; } else { this.newPasswordCheck.special = false; }
        if (password1.replace(/[^A-Z]/g, '').length >= 1) { this.newPasswordCheck.upper = true; } else { this.newPasswordCheck.upper = false; }
        if (password1.replace(/[^a-z]/g, '').length >= 1) { this.newPasswordCheck.lower = true; } else { this.newPasswordCheck.lower = false; }
        if (password1.replace(/\D/g, '').length >= 2) { this.newPasswordCheck.digits = true; } else { this.newPasswordCheck.digits = false; }
        if (password1 === password2 && password2.length >= 8) { this.newPasswordCheck.same = true; } else { this.newPasswordCheck.same = false; }
    }

  sendforgotPwEmailForm(item) {
      if (this.possibleEmailAddresses.length > 1) {
          const addressSelected = window.confirm('Sind Sie sicher, dass die E-Mail an ' + item.target + ' gesendet werden soll ?');
          if (addressSelected) {
              this.selectedEmailAddress = item;
              document.getElementById('EmailSelectModal').style.display = 'none';
          }
      } else {
          this.selectedEmailAddress = item;
      }

      setTimeout(() => {
         this.selectedEmailAddress = { id: '', target: '' };
      }, 300);
  }

  closeModal() {
      document.getElementById('EmailSelectModal').style.display = 'none';
  }

  getCompanySites() {
          this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/address', { company: this.currentSelected.company, active: 1 }, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.siteData = data;
                  if (this.siteData.length < 2 && this.siteData.length > 0 || this.siteData.length > 0 && this.showNewItemForm) {
                      this.currentSelected.site = this.siteData[0].id;
                      this.siteSelected = true;
                  } else {
                      this.siteSelected = false;
                  }
                  if (this.siteData.length < 1) {
                      this.siteSelected = true;
                  }
                  if(this.currentSelected.site<1) { this.currentSelected.site = this.siteData[0].id; }
              }
          );
  }

  getUserSiteLink() {
      this.http.get<{id, fk_site_id}>(this.settings.restBaseUrl + 'contact/company/address/' + this.currentSelected.id, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                  if (data !== null) {
                      this.currentUserSite = data.id;
                      this.currentSelected.site = data.fk_site_id;
                      this.startSite = data.fk_site_id;
                  }
          }
      );
  }

  selectSite() {
      this.siteSelected = true;
      this.siteSelectedId = this.currentSelected.site;
  }

  getSearchparameterSiteslist() {
      this.search(false);
      this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/address', { company: this.searchParameter.company }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                this.siteSearchParameterList = data;
          }
      );
  }

  copyUser(item) {
      this.changeTabButton(1);
      this.edit(item);
      this.getCopyUserData = true;
      setTimeout(() => {
          setTimeout(() => { this.showCopyUserForm = true; }, 100);
      }, 100);
      setTimeout(() => {
          this.getCopyUserData = false;
      }, 500);
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxShiftStartElement = false;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }
    if (this.checkboxShiftStartElement == null) { this.checkboxShiftStartElement = clickedItem;
    } else {
      let direction = 'backwards';

      for (const item of this.data) {
        if (item === clickedItem)                       {direction = 'forward'; }
        if (item === this.checkboxShiftStartElement)    {direction = 'backwards'; }
      }

      if (direction === 'backwards') {
        const temp = this.checkboxShiftStartElement;
        this.checkboxShiftStartElement = clickedItem;
        clickedItem = temp;
      }

      let mark = false;

      for (const item of this.data) {
        if (item === this.checkboxShiftStartElement) {mark = true; }
        if (mark) {item.checked = true; }
        if (item === clickedItem) { mark = false; }
      }

      this.checkboxShiftStartElement = null;
    }
    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.selectableTeams.filter((item) => item.checked);
  }

  changeTabButton(tabId): void {
    this.selectedTeam = -1;
    this.selectTeam = false;
    this.siteSelected = false;
    this.currentSelectedTab = tabId;
    this.initSelectedTeams();
    this.pwChangeForm = false;
    this.passwordComplexity = false;
    this.initPwChangeObject();
    if (tabId === 4) {
        this.selectedSubstituteUserId = 0;
    }
  }

    resetSearch(): void {
        this.initSearchParameter();
        this.search(false);
    }

    toggleExpandedSearch(): void {
        let newValue = '106px';
        this.expandedSearch = !this.expandedSearch;
        this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
        if (this.expandedSearchPreviousPanelHeight === '80px') {
            newValue = '106px';
        }
        if (this.expandedSearchPreviousPanelHeight === '106px') {
            newValue = '80px';
        }
        document.getElementById('leftContainerContent').style.top = newValue;
    }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

    resetPassword(email) {
        this.http.put<{status: string, message: string}>(this.settings.restBaseUrl + 'user/password/reset/' + email, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    alert(data.message);
                }
            ));
    }

    testUserRights() {
        const loginEncodedData = {
            username: this.currentSelected.nick,
            password: 'admintestlogin'
        };
        this.http.post<{status: string, userid: string, message: string, session_id: string}>(this.settings.restBaseUrl + 'user/login', JSON.stringify(loginEncodedData), this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data[0].status === 'success') {
                        window.open('index.html?session_id=' + data[0].session_id + '#/process/6');
                    } else {
                        alert('Sie haben keine Berechtigung die Benutzerrechte zu testen!');
                    }
                }
            ));
    }

    getUserRights() {
            this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'document/rightgroups', {},  this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.documentRightGroups = data.data;
                }
            );
            this.http.post<any[]>(this.settings.restBaseUrl + 'user/right',{},  this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.userRights = data;
                }
            );
    }

    selectUserRight(item) {
        this.selectedUserRights.push(item);
        this.getEntrypointDropdownData();
    }

    unselectUserRight(item) {
        for (let i = 0; i < this.selectedUserRights.length; i++) {
            if (item.id === this.selectedUserRights[i].id) {
                this.selectedUserRights.splice(i--, 1);
                // tslint:disable-next-line:triple-equals
                if (item.id == 4) { this.showDocumentOption = false; }
                // tslint:disable-next-line:triple-equals
                if (item.id == 6) { this.showProcessOption = false; }
                // tslint:disable-next-line:triple-equals
                if (item.id == 14) { this.showErpOption = false; }
                // tslint:disable-next-line:triple-equals
                if (item.id == 12) { this.showInventoryOption = false; }
            }
        }
    }

    emptySelectedDocumentRightGroupsArray() {
        if (this.selectedDocumentRightGroups.length > 0) {
            this.selectedDocumentRightGroups.shift();
            this.emptySelectedDocumentRightGroupsArray();
        }
    }

    selectDocumentRightGroup(item) {
        this.selectedDocumentRightGroups.push(item);
    }

    unselectDocumentRightGroup(item) {
        for (let i = 0; i < this.selectedDocumentRightGroups.length; i++) {
            if (item.id === this.selectedDocumentRightGroups[i].id) {
                this.selectedDocumentRightGroups.splice(i--, 1);
            }
        }
    }

    putSelectedUserRights(id: any) {
        this.http.put<any[]>(this.settings.restBaseUrl + 'user/right/' + this.selectedUserRights[0].id + '/' + id, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedUserRights.shift();
                if (this.selectedUserRights.length > 0) {
                    this.putSelectedUserRights(id);
                }
            }
        );
    }

    putSelectedDocumentRightGroups(id: any) {
        this.http.put<any[]>(this.settings.restBaseUrl + 'user/documentRight/' + this.selectedDocumentRightGroups[0].id + '/' + id, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedDocumentRightGroups.shift();
                if (this.selectedDocumentRightGroups.length > 0) {
                    this.putSelectedDocumentRightGroups(id);
                }
            }
        );
    }

    getEntrypointDropdownData() {
        this.selectedUserRights.forEach((item) => {
            // tslint:disable-next-line:triple-equals
            if (item.id == 4) { this.showDocumentOption = true;
                // tslint:disable-next-line:triple-equals
            } else if (item.id == 6) { this.showProcessOption = true;
                // tslint:disable-next-line:triple-equals
            } else if (item.id == 14) { this.showErpOption = true;
                // tslint:disable-next-line:triple-equals
            } else if (item.id == 12) { this.showInventoryOption = true; }
        });
        this.dropdownDataEntrypointMenu = [{ id: '1', name: 'Vorgangsmanagement > Eigene', showOption: this.showProcessOption },
            { id: '11', name: 'Vorgangsmanagement > Team', showOption: this.showProcessOption },
            { id: '3', name: 'Dokumente > Freigabe', showOption: this.showDocumentOption },
            { id: '31', name: 'Dokumente > Suche', showOption: this.showDocumentOption },
            { id: '2', name: 'ERP > Warenwirtschaft > Ausgangsbelege', showOption: this.showErpOption },
            { id: '21', name: 'CRM > Eingangsbelege', showOption: this.showErpOption },
            { id: '22', name: 'CRM > Ausgangsbelege', showOption: this.showErpOption },
            { id: '4', name: 'Inventarverwaltung > Inventar', showOption: this.showInventoryOption }];
        if (this.currentSelected.entrypoint === '' || this.currentSelected.entrypoint == null) {
            this.currentSelected.entrypoint = '-1';
        }
    }

    private getNewUserTeams() {
        this.http.post<{status,count,data}>(this.settings.restBaseUrl + 'team' , {userCanGive:true,active:1}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.newUserTeams = data.data;
            }
        );
    }
}
