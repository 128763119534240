import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';


@Component({
  selector: 'app-erp-product-group',
  templateUrl: './erp-product-group.component.html',
  styleUrls: ['./erp-product-group.component.css']
})
export class ErpProductGroupComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public treeNodes = [{ }];
  public ekAccounts = [];
  public vkAccounts = [];
  public dropdownEKAccount = - 1;
  public dropdownVKAccount = - 1;
  public accountEKDataSource = [];
  public accountVKDataSource = [];
  public newProductGroupEKArray = [];
  public newProductGroupVKArray = [];
  public newID;
  public selectedAccount;
  public selectedGroups = [];
  public currentGroup = 0;
  public defaultAccounts = [];
  public navigateBackItem;
  public sameAccount = false;


  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', alternatives : '', can_contain_articles : '', fk_erp_product_groups : '', sort : '', active : '', create_fk_user : '', create_timestamp : '', selectable : ''};
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', fk_erp_product_groups : '0', id: '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
    this.http.post<any[]>(this.settings.restBaseUrl + 'erp/account' ,{}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.accountEKDataSource = data;
            this.accountVKDataSource = data;
          }
      );
  }

  ngOnInit() {
    this.searchParameter.fk_erp_product_groups = 0;
    this.search(false);
    this.getSelectorData();
    this.initSelectedAccount();
  }

  initSelectedAccount(): void {
      this.selectedAccount = { title: '', account_number: '', tax_rate: 0 };
  }

  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'erp/productgroup/list', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
          this.initCurrentSelected();
          this.showNewItemForm = false;
          this.currentGroup = this.searchParameter.fk_erp_product_groups;
        }
    );
  }

  getSelectorData() {
      this.http.post<any[]>(this.settings.restBaseUrl + 'erp/productgroup/tree', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.treeNodes = data;
          }
      );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
    this.selectedGroups = [];
    this.currentGroup = 0;
  }

  new(): void {
    this.initCurrentSelected();
    this.initAccountArrays();
    this.showNewItemForm = true;
    this.dropdownEKAccount = - 1;
    this.dropdownVKAccount = - 1;
    this.getDefaultAccounts(this.currentGroup);
    setTimeout(() => {
        document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save() {
      if (this.currentSelected.name == null || this.currentSelected.name === '') {
          alert('Alle Pflichtfelder müssen ausgefüllt sein.\n Bitte geben Sie einen Namen ein.');
          return;
      } else {
      if (this.currentSelected.id < 1 && this.newProductGroupEKArray.length < 1 || this.currentSelected.id < 1 && this.newProductGroupVKArray.length < 1) {
          alert('Der Warengruppe müssen Buchungskonten zugewiesen werden.\nBitte wählen Sie mindestens ein Einkaufs- und ein Verkaufskonto aus.');
          return;
      }
      if (this.currentSelected.id > 0 && this.ekAccounts.length < 1 || this.currentSelected.id > 0 && this.vkAccounts.length < 1 ) {
          alert('Der Warengruppe müssen Buchungskonten zugewiesen werden.\nBitte wählen Sie mindestens ein Einkaufs- und ein Verkaufskonto aus.');
          return;
      } else {
          const saveData = {
              id: this.currentSelected.id,
              fk_erp_product_groups: this.searchParameter.fk_erp_product_groups,
              name: this.currentSelected.name,
              alternatives: this.currentSelected.alternatives,
              can_contain_articles: this.currentSelected.can_contain_articles,
              sort: this.currentSelected.sort,
              active: this.currentSelected.active
          };
          this.http.put<any[]>(
              this.settings.restBaseUrl + 'erp/productgroup', saveData, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  // @ts-ignore
                  this.newID = data.message;
                  this.search(false);
                  this.getSelectorData();
                  this.saveEKAccount(this.newID);
              }
          );
      }
    }
  }

  initAccountArrays() {
      this.defaultAccounts = [];
      this.ekAccounts = [];
      this.vkAccounts = [];
      this.newProductGroupEKArray = [];
      this.newProductGroupVKArray = [];
  }

  edit($event: MouseEvent, item: any): void  {
    this.dropdownEKAccount = - 1;
    this.dropdownVKAccount = - 1;
    this.initAccountArrays();

    this.http.get<any[]>(
        this.settings.restBaseUrl + 'erp/productgroup/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          this.getEKAccounts();
          this.getVKAccounts();
          if ( this.currentSelected.can_contain_articles === '1' ) { this.currentSelected.can_contain_articles = true; }
          if ( this.currentSelected.can_contain_articles === '0' ) { this.currentSelected.can_contain_articles = false; }
          setTimeout(() => {
              document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

    enter($event, item) {
        this.selectedGroups.push({
            name: item.name,
            fk_erp_product_groups: item.fk_erp_product_groups,
            id: item.id
        });

        this.currentGroup = item.id;
        this.searchParameter.fk_erp_product_groups = item.id;
        setTimeout(() => {
            this.search(false);
            this.initCurrentSelected();
            this.initAccountArrays();
            }, 100) ;
    }

  back() {
      this.initAccountArrays();
      if (this.selectedGroups.length > 0) {
          this.searchParameter.fk_erp_product_groups = this.selectedGroups[this.selectedGroups.length - 1].fk_erp_product_groups;
          this.selectedGroups.pop();
          this.search(false);
          this.initCurrentSelected();
      } else {
          this.http.get<any[]>(
              this.settings.restBaseUrl + 'erp/productgroup/' + this.currentGroup,
              this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                    this.navigateBackItem = data;
                    if (this.searchParameter.fk_erp_product_groups !== '0') {
                        this.searchParameter.fk_erp_product_groups = this.navigateBackItem.fk_erp_product_groups;
                        setTimeout(() => {
                            this.search(false);
                            }, 100);
                    }
              });
      }

  }

  addEKAccount() {
      if (this.showNewItemForm) {
          if (this.dropdownEKAccount === - 1) {
              return;
          } else {
              this.newProductGroupEKArray.forEach ((item) => {
                  if ( item.ek_vk === '1' && item.fk_billcenter_book_account === this.dropdownEKAccount) {
                      this.sameAccount = true;
                  } else { return; }
              });
              // @ts-ignore
              if (this.sameAccount === true) {
                  setTimeout(() => {
                      this.dropdownEKAccount = -1;
                      alert('Konten dürfen nicht doppelt zugewiesen werden !');
                      this.sameAccount = false;
                  }, 100);
                  return;
              } else {
              this.http.get<any[]>(
                  this.settings.restBaseUrl + 'erp/productgroup/account/' + this.dropdownEKAccount, this.httpOptions)
                  .pipe(map(data => data)).subscribe(
                  data => {
                      this.selectedAccount = data;
                      const dataArray = {
                          id: this.selectedAccount.id,
                          title: this.selectedAccount.title,
                          account_number: this.selectedAccount.account_number,
                          tax_rate: this.selectedAccount.tax_rate,
                          fk_billcenter_book_account: this.dropdownEKAccount,
                          ek_vk: '1'
                      };
                      this.newProductGroupEKArray.push(dataArray);
                      this.initSelectedAccount();
                      this.dropdownEKAccount = - 1;
                      return;
                  }
              );
            }
          }
      } else {
          if (this.dropdownEKAccount === - 1) {
              return;
          } else {
              this.sameAccount = false;
              this.ekAccounts.forEach ( (item) => {
                  if ( item.ek_vk === '1' && item.fk_erp_product_groups === this.currentSelected.id && item.fk_billcenter_book_account === this.dropdownEKAccount) {
                      this.sameAccount = true;
                  }
              });
              // @ts-ignore
              if (this.sameAccount === true) {
                  setTimeout(() => {
                      this.dropdownEKAccount = -1;
                      alert('Konten dürfen nicht doppelt zugewiesen werden !');
                      this.sameAccount = false;
                      }, 100);
                  return;
              } else {
                  const saveData = {
                      fk_erp_product_groups: this.currentSelected.id,
                      fk_billcenter_book_account: this.dropdownEKAccount,
                      ek_vk: 1
                  };
                  this.http.put<any[]>(
                      this.settings.restBaseUrl + 'erp/productgroup/account/' + this.currentSelected.id + '/1', saveData, this.httpOptions)
                      .pipe(map(data => data)).subscribe(
                      data2 => {
                          this.getEKAccounts();
                          this.dropdownEKAccount = - 1;
                      }
                  );
              }

          }
      }

  }

    addVKAccount() {
        if (this.showNewItemForm) {
            if (this.dropdownVKAccount === -1) {
                return;
            } else {
                this.sameAccount = false;
                this.newProductGroupVKArray.forEach((item) => {
                    if (item.ek_vk === '0' && item.fk_billcenter_book_account === this.dropdownVKAccount) {
                        this.sameAccount = true;
                    }
                });
                // @ts-ignore
                if (this.sameAccount === true) {
                    setTimeout(() => {
                        this.dropdownVKAccount = -1;
                        alert('Konten dürfen nicht doppelt zugewiesen werden !');
                        this.sameAccount = false;
                    }, 100);
                    return;
                } else {
                    this.http.get<any[]>(
                        this.settings.restBaseUrl + 'erp/productgroup/account/' + this.dropdownVKAccount, this.httpOptions)
                        .pipe(map(data => data)).subscribe(
                        data => {
                            this.selectedAccount = data;
                            const dataArray = {
                                title: this.selectedAccount.title,
                                account_number: this.selectedAccount.account_number,
                                tax_rate: this.selectedAccount.tax_rate,
                                fk_billcenter_book_account: this.dropdownVKAccount,
                                ek_vk: '0'
                            };
                            this.newProductGroupVKArray.push(dataArray);
                            this.initSelectedAccount();
                            this.dropdownVKAccount = - 1;
                            return;
                        }
                    );
                }
            }
        } else {
            if (this.dropdownVKAccount === -1) {
                return;
            } else {
                this.sameAccount = false;
                this.vkAccounts.forEach ( (item) => {
                    if ( item.ek_vk === '0' && item.fk_erp_product_groups === this.currentSelected.id && item.fk_billcenter_book_account === this.dropdownVKAccount) {
                        this.sameAccount = true;
                    }
                });
                // @ts-ignore
                if (this.sameAccount === true) {
                    setTimeout(() => {
                        this.dropdownVKAccount = -1;
                        alert('Konten dürfen nicht doppelt zugewiesen werden !');
                        this.sameAccount = false;
                    }, 100);
                    return;
                } else {
                    const saveData = {
                        fk_erp_product_groups: this.currentSelected.id,
                        fk_billcenter_book_account: this.dropdownVKAccount,
                        ek_vk: 0
                    };
                    this.http.put<any[]>(
                        this.settings.restBaseUrl + 'erp/productgroup/account/' + this.currentSelected.id + '/0', saveData, this.httpOptions)
                        .pipe(map(data => data)).subscribe(
                        data2 => {
                            this.getVKAccounts();
                            this.dropdownVKAccount = -1;
                        }
                    );
                }
            }
        }
    }

    saveEKAccount(value) {
        if (this.newProductGroupEKArray.length === 0) {
            this.saveVKAccount(value);
            return;
        } else {
        const saveData = {
        fk_erp_product_groups: value,
        fk_billcenter_book_account: this.newProductGroupEKArray[0].fk_billcenter_book_account,
        ek_vk: this.newProductGroupEKArray[0].ek_vk
        };
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'erp/productgroup/account/' + value + '/' + this.newProductGroupEKArray[0].ek_vk, saveData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data2 => {
                this.newProductGroupEKArray.shift();
                if (this.newProductGroupEKArray.length > 0) {
                    this.saveEKAccount(value);
                } else {
                    this.saveVKAccount(value);
                    this.dropdownEKAccount = - 1;
                }
            }
            );
        }
    }
    saveVKAccount(value) {
        const saveData = {
            fk_erp_product_groups: value,
            fk_billcenter_book_account: this.newProductGroupVKArray[0].fk_billcenter_book_account,
            ek_vk: this.newProductGroupVKArray[0].ek_vk
        };
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'erp/productgroup/account/' + value + '/' + this.newProductGroupVKArray[0].ek_vk, saveData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data2 => {
                this.newProductGroupVKArray.shift();
                if (this.newProductGroupVKArray.length > 0) {
                    this.saveVKAccount(value);
                } else {
                    this.dropdownVKAccount = - 1;
                    this.showNewItemForm = false;
                    this.initAccountArrays();
                }
            }
        );
    }


    getEKAccounts() {
        const accountData = {
          ek_vk: 1,
          fk_erp_product_groups: this.currentSelected.id
      };
        this.http.post<any[]>(this.settings.restBaseUrl + 'erp/productgroup/account', accountData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.ekAccounts = data;
            });
    }

    getVKAccounts() {
        const accountData = {
            ek_vk: 0,
            fk_erp_product_groups: this.currentSelected.id
        };
        this.http.post<any[]>(this.settings.restBaseUrl + 'erp/productgroup/account', accountData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.vkAccounts = data;
            });
    }

    deleteEKAccount(item) {
        const deleteItems = window.confirm('Soll die Zuweisung zu dem markierten Konto wirklich gelöscht werden?');
        if (deleteItems) {
            if (this.showNewItemForm) {
                this.deleteNewItemAccountEK(item);
            } else {
                this.http.delete<any>(this.settings.restBaseUrl + 'erp/productgroup/account/' + item.id, this.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        this.getEKAccounts();
                    });
            }
        }
    }

    deleteVKAccount(item) {
        const deleteItems = window.confirm('Soll die Zuweisung zu dem markierten Konto wirklich gelöscht werden?');
        if (deleteItems) {
            if (this.showNewItemForm) {
                this.deleteNewItemAccountVK(item);
            } else {
                this.http.delete<any>(this.settings.restBaseUrl + 'erp/productgroup/account/' + item.id, this.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                        this.getVKAccounts();
                    });
            }
        }
    }

    deleteNewItemAccountEK(item) {
        this.newProductGroupEKArray = this.newProductGroupEKArray.filter(element => element !== item);
    }

    deleteNewItemAccountVK(item) {
        this.newProductGroupVKArray = this.newProductGroupVKArray.filter(element => element !== item);
    }

    getDefaultAccounts(value) {
        const searchData = {
            fk_erp_product_groups: value
        };

        this.http.post<any[]>(this.settings.restBaseUrl + 'erp/productgroup/account/default', searchData , this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.defaultAccounts = data;

                this.defaultAccounts.forEach((item) => {
                    if (item.ek_vk === '1') {
                        this.newProductGroupEKArray.push(item);
                    } else {
                        this.newProductGroupVKArray.push(item);
                    }
                });
            });
    }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'erp/productgroup/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich akiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'erp/productgroup/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'erp/productgroup/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
          this.getSelectorData();
        });
  }

    changeSortAccounts(item, sortAction): void  {
        const sortData = {
            fk_erp_product_groups: item.fk_erp_product_groups,
            ek_vk: item.ek_vk
        };
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'erp/productgroup/account/sort/' + item.id + '/' + sortAction, sortData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            this.getEKAccounts();
            this.getVKAccounts();
            });
    }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

}
