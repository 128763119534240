import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-document-right-group',
  templateUrl: './document-right-group.component.html',
  styleUrls: ['./document-right-group.component.css']
})
export class DocumentRightGroupComponent implements OnInit {
  public data: any[];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdownData;
  public httpOptions = { };
  public selectedDocumentRightGroupUserList;
  public addToDocumentRightGroupUserId = 0;
  public dropdownDataUser;
  public companyId;
  public securityLevelRight = false;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', comment : '', min_retention_years : '', active : ''};
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , active : '1', text: '', sort: 'sort'};
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Action1'}, { value : '2', name : 'Action2'}];
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.initCurrentSelected();
    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
  }

  ngOnInit() {
      this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.companyId = data;

              this.search(false);
              setTimeout(() => {
                  document.getElementById('searchText').focus();
                  this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'user', {active: 1, company: this.companyId}, this.httpOptions)
                      .pipe(map(data2 => data2)).subscribe(
                      data2 => {
                          this.dropdownDataUser = data2.data;
                      }
                  );
              }, 200);
          }
      );
  }


  search(loadNextSearchResults: boolean): void {

      this.http.post<{status, data}>(this.settings.restBaseUrl + 'document/securityRight', JSON.stringify(this.searchParameter), this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.securityLevelRight = data.data;
          }
      );


    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'document/rightgroups', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
       document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
        alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
        return;
    } else if (this.currentSelected.min_retention_years == null || this.currentSelected.min_retention_years === '') {
        alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie eine Laufzeit ein.\n0 entspricht keiner Begrenzung.');
        return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    const saveData = {
        name: this.currentSelected.name,
        comment: this.currentSelected.comment,
        min_retention_years: this.currentSelected.min_retention_years
    };
    this.http.put<any[]>(
          this.settings.restBaseUrl + 'document/rightgroups' + endpointIdAdd, saveData, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.search(false);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          }
      );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'document/rightgroups/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          this.getDocumentRightGroupUserList(item);
          setTimeout(() => {
             document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

  getDocumentRightGroupUserList(item) {
      this.http.post<any[]>(this.settings.restBaseUrl + 'document/rightgroups/user', { id: item.id, active: 1 }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
                this.selectedDocumentRightGroupUserList = data;
          }
      );
  }

  changeMainGroup(group, groupId, user): void {

    if (group != null) {
    if (group === groupId) {       this.http.put<any[]>(
        this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: '0', userId: user }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
            data => {
                this.getDocumentRightGroupUserList(this.currentSelected);
            });}
    if (group !== groupId) { let changeGroup= window.confirm('Dem User ist bereits eine Hauptgruppe zugewiesen. Soll diese ersetzt werden?');
    if (changeGroup) {
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: groupId, userId: user }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.getDocumentRightGroupUserList(this.currentSelected);
            });
    }
    }
    } if (group === null) {
          this.http.put<any[]>(
              this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: groupId, userId: user }, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {  alert('Hauptgruppe gesetzt');
                  this.getDocumentRightGroupUserList(this.currentSelected);
              });

        }
  }




  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'document/rightgroups/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

    switchSecurityLevel(item, securityLevel): void
    {
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'document/rightgroups/securityLevel/' +  item.id + '/' + securityLevel, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.search(false);
            });

    }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'document/rightgroups/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'document/rightgroups/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

    checkIfUserAlreadyAssigned() {
        // tslint:disable-next-line:triple-equals
        if (this.addToDocumentRightGroupUserId == 0) {
            alert('Es ist kein Benutzer Ausgewählt. Bitte Wählen Sie einen Benutzer aus um Ihn hinzuzufügen.');
            return;
        } else {
            this.http.post<{status, message}>(this.settings.restBaseUrl + 'document/rightgroups/user/check', {fk_document_right_groups: this.currentSelected.id, fk_core_user: this.addToDocumentRightGroupUserId}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    if (data.message > 0) {
                        alert('Der Benutzer ist dieser Dokumentenrechtegruppe bereits zugewiesen.');
                        return;
                    } else {
                        this.assignUserToDocumentRightGroup();
                    }
                }
            );
        }
    }

    assignUserToDocumentRightGroup() {
        this.http.put<any[]>(this.settings.restBaseUrl + 'user/documentRight/' + this.currentSelected.id + '/' + this.addToDocumentRightGroupUserId, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.getDocumentRightGroupUserList(this.currentSelected);
                this.addToDocumentRightGroupUserId = 0;
                }
        );
    }

    deleteDocumentRightGroupUserLink(item) {
        const deleteItem = window.confirm('Sind Sie sicher, dass Sie diesen Benutzer aus dieser Dokumentenrechtegruppe entfernen möchten?');
        if (deleteItem) {
            this.http.delete<any[]>(this.settings.restBaseUrl + 'user/documentRight/' + this.currentSelected.id + '/' + item.contactID , this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.getDocumentRightGroupUserList(this.currentSelected);
                    this.addToDocumentRightGroupUserId = 0;
                }
            );
        }
    }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

}
