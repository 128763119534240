<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <div style="width:100%;">
                   <span class="currentLocation">
                        ERP > Warengruppen
                    </span>
                </div>
                <div style="width:100%; margin-top:6px; margin-left: 8px;">
                    <span class="form search">
                    <span app-product-group-selector style="margin-left: -10px;" [(model)]="searchParameter.fk_erp_product_groups" [data]="treeNodes" (callback)="search(false)"></span>

                    <button (click)="back()"
                            title="zur vorherigen Warengruppe"
                            style="min-width:24px !important;width:24px;height:30px; "
                            tabindex="4">
                        <img src="../design/icons/bullet_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; "
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:15px; margin-top: 3px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                        </span>
                    <table class="data_tabel" style="width: calc( 100% - 4px ); margin-top: -2px;" cellpadding="4" cellspacing="0" >
                        <tr>

                            <th>&nbsp;Name</th>
                            <th style="width:100px;">&nbsp;Untergruppen</th>
                        </tr>
                    </table>
                </div>

            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" (dblclick)="enter($event,x)" [ngClass]="{'selectable':x.selectable==1 }">&nbsp;{{ x.name }}</td>
                        <td style="width:100px; text-align: center;" (click)="edit($event,x)" (dblclick)="enter($event,x)" [ngClass]="{'selectable':x.selectable==1 }">&nbsp;{{ x.sub_count }}</td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:5px; margin-top:6px;">
                    <span *ngIf="showNewItemForm && searchParameter.fk_erp_product_groups == 0">Neue Hauptwarengruppe anlegen</span>
                    <span *ngIf="showNewItemForm && searchParameter.fk_erp_product_groups > 0">Neue Warengruppe anlegen</span>
                    <span *ngIf="!showNewItemForm && currentSelected.id > 0">Warengruppe bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Alternative Suchwörter</span>

                                <textarea type="text"
                                          [(ngModel)]="currentSelected.alternatives"
                                          id="currentSelectedAlternatives"
                                          class="cp-textbox mceNoEditor"
                                          style="width:calc(100% + 1px); border: 1px solid #ceced2; border-radius: 3px;"
                                          rows="4"
                                          tabindex="102" >
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>
                                    <input type="checkbox"
                                       [(ngModel)]="currentSelected.can_contain_articles"
                                       id="currentSelectedCanContainArticles"
                                       tabindex="103">
                                    Warengruppe kann Artikel enthalten
                                </label>
                            </td>
                            <td class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="104">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                    <table class="form_tabel light" cellspacing="0" cellpadding="4" style="width: 101.5%; margin-left: -10px; margin-top: 30px; margin-bottom: 0;">
                        <tr>
                            <th>Einkaufskonten *</th>
                            <th>Verkaufskonten *</th>
                        </tr>
                    </table>
                    <table class="form_tabel light" style="height:2%; width:101.5%; margin: 0px; margin-left: -12px; margin-top: 2px;" cellpadding="4" cellspacing="0">
                        <tr style="height:96%">
                            <td style="vertical-align: top; background-color: white !important; width:50%; border-right:1px solid #e8e8e8;">
                                <select style="width:96%; margin-left: 6px;" [(ngModel)]="dropdownEKAccount" (ngModelChange)="addEKAccount()">
                                    <option value="-1">Bitte wählen um hinzuzufügen</option>
                                    <option *ngFor="let account of accountEKDataSource" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                </select>
                            </td>
                            <td>
                                <select style="width:96%; margin-left: 8px;" [(ngModel)]="dropdownVKAccount" (ngModelChange)="addVKAccount()">
                                    <option value="-1">Bitte wählen um hinzuzufügen</option>
                                    <option *ngFor="let account of accountVKDataSource" value="{{account.id}}">{{account.account_number}} - {{account.title}}</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                    <table style="height:100%; width:100%; margin: 0px; margin-left: -7px; margin-top: 2px;" cellpadding="4" cellspacing="0">
                        <tr style="height:96%" *ngIf="!showNewItemForm">
                            <td style="vertical-align: top; background-color: white !important; width:50%; border-right:1px solid #e8e8e8;">
                                <table class="data_tabel" style="width: 100%">
                                    <tr *ngFor="let x of ekAccounts"
                                        [contextMenu]="ekAccountMenu"
                                        [contextMenuValue]="x">
                                        <td><b>{{x.title}}</b><br>{{x.account_number}} - {{x.tax_rate}} % </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="vertical-align: top; width: 100%">
                                <table class="data_tabel" style="width: 100%">
                                    <tr *ngFor="let x of vkAccounts"
                                        [contextMenu]="vkAccountMenu"
                                        [contextMenuValue]="x">
                                        <td><b>{{x.title}}</b><br>{{x.account_number}} - {{x.tax_rate}} % </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr style="height:96%" *ngIf="showNewItemForm">
                            <td style="vertical-align: top; background-color: white !important; width:50%; border-right:1px solid #e8e8e8;">
                                <table class="data_tabel" style="width: 100%">
                                    <tr *ngFor="let x of newProductGroupEKArray"
                                        [contextMenu]="ekAccountMenu"
                                        [contextMenuValue]="x">
                                        <td><b>{{x.title}}</b><br>{{x.account_number}} - {{x.tax_rate}} % </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="vertical-align: top;">
                                <table class="data_tabel" style="width: 100%">
                                    <tr *ngFor="let x of newProductGroupVKArray"
                                        [contextMenu]="vkAccountMenu"
                                        [contextMenuValue]="x">
                                        <td><b>{{x.title}}</b><br>{{x.account_number}} - {{x.tax_rate}} % </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>

</context-menu>

<context-menu #ekAccountMenu>
    <ng-template contextMenuItem let-item (execute)="deleteEKAccount($event.value)">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'start')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'up')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'down')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'end')" >
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>

<context-menu #vkAccountMenu>
    <ng-template contextMenuItem let-item (execute)="deleteVKAccount($event.value)">
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'start')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'up')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'down')">
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSortAccounts($event.value,'end')" >
        <span *ngIf="currentSelected.id > 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
