import {Component, Input, Output, OnInit, OnChanges, EventEmitter, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: '[app-product-group-selector]',
  templateUrl: './product-group-selector.component.html',
  styleUrls: ['./product-group-selector.component.css']
})
export class ProductGroupSelectorComponent implements OnInit, OnChanges {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');

  public overlayId: string;
  public OverlayActive = false;
  public search = [{ id: '', name: '', nodes: [], selectable: '', selected: false, length }];
  public httpOptions: { };
  public productGroupString;
  public showSearch = false;
  public all;
  public selectedData;
  public modelValue = '0';
  public compareModelValue = '0';
  public dataSource = [{ id: '', name: '', nodes: [], selectable: '', selected: false, length }];
  public selectSuggestIndex = - 1;


  // MODEL
  @Output() modelChange: EventEmitter<string>;
  @Output() callback: EventEmitter<any> = new EventEmitter();

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
    this.getProductGroupName();
  }

  @Input()
  get data() {
    return this.dataSource;
  }
  set data(value) {
    this.dataSource = value;
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.modelValue = value;
    this.compareModelValue = value;
    if (this.modelValue === '0') { this.productGroupString = ''; }
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.modelChange = new EventEmitter();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: settings.sessionId
      })
    };
  }

  changeModel(value): void {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  // ENDE MODEL


  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  selectProductGroup(group): void {
          this.compareModelValue = this.modelValue;
          this.productGroupString = group.name;
          this.changeModel(group.id);
          this.callSearch();
          this.deactivateOverlay();
  }

  changeProductGroupStringManually(event): void {
    if (event.which === 27 || event.key === 27) {
      if (this.OverlayActive) {
        this.selectSuggestIndex = - 1;
        this.deactivateOverlay();
        return;
      }
    }
    if (event.which === 38 || event.key === 38) {
        if (this.selectSuggestIndex === -1) { return; }
        if (this.selectSuggestIndex > - 1) { this.search[this.selectSuggestIndex].selected = false; }
        if (this.selectSuggestIndex === - 1) { this.selectSuggestIndex = this.search.length; }
        this.selectSuggestIndex--;
        this.search[this.selectSuggestIndex].selected = true;
        return;
    }
    if (event.which === 40 || event.key === 40) {
      if (this.selectSuggestIndex === this.search.length - 1) { return; }
      if (this.selectSuggestIndex > - 1 ) {  this.search[this.selectSuggestIndex].selected = false; }
      this.selectSuggestIndex++;
      this.search[this.selectSuggestIndex].selected = true;
      return;
    }
    if (event.which === 13 || event.key === 13) {
      if (this.selectSuggestIndex > -1 && this.showSearch) {
        this.clickOnSuggestItem(this.search[this.selectSuggestIndex]);
        this.deactivateOverlay();
        return;
      }
      if (this.productGroupString.length === 0 && this.selectSuggestIndex === - 1) {
        this.modelValue = '0';
        this.modelChange.emit(this.modelValue);
        this.callSearch();
      }
    }
    if (this.productGroupString.length > 2) {
      if (!this.OverlayActive) { this.showSearch = true; }
      this.activateOverlay();

      let endpoint = 'erp/productgroup/list';
      if (this.all !== undefined) { endpoint += '/0'; }

      this.http.post<any[]>(this.settings.restBaseUrl + endpoint, {text: this.productGroupString} , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            // @ts-ignore
            this.search = data.data;
          }
      );
    } else {
      this.deactivateOverlay();
      this.showSearch = false;
      this.selectSuggestIndex = -1;
    }
  }

  clickOnSuggestItem(item) {
      this.selectSuggestIndex = -1;
      this.modelValue = item.id;
      this.modelChange.emit(this.modelValue);
      this.productGroupString = item.name;
      this.callSearch();
  }

  getProductGroupName() {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'erp/productgroup/' + this.modelValue,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // @ts-ignore
          this.selectedData = data;
          this.productGroupString = this.selectedData.name;
          if (this.modelValue !== this.compareModelValue) {
            this.compareModelValue = this.modelValue;
            this.modelChange.emit(this.modelValue);
            this.callSearch();
            this.deactivateOverlay();
          } else { return; }
        });
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    this.selectSuggestIndex = - 1;
    document.getElementById(this.overlayId).style.width = '250px';
    document.getElementById(this.overlayId).style.height = '255px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    this.selectSuggestIndex = - 1;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    this.showSearch = false;
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

  callSearch() {
    this.callback.emit();
  }

  blurAction() {
    this.selectSuggestIndex = -1;
    this.getProductGroupName();
  }
}
