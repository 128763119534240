import { Component, OnInit, ViewChild  } from '@angular/core';
import {SettingsService} from '../../services/settings.service';

@Component({
  selector: 'app-erp-account-system-management',
  templateUrl: './erp-account-system-management.component.html',
  styleUrls: ['./erp-account-system-management.component.css']
})
export class ErpAccountSystemManagementComponent implements OnInit {
  sessionId: any;
  public url: string;
  constructor( public settings: SettingsService ) {
    this.url = '../app/view.php?session_id=' + this.settings.sessionId + '#/book_account_control';
  }

  ngOnInit() {

  }

}
