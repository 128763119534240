import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-admin-spam',
  templateUrl: './admin-spam.component.html',
  styleUrls: ['./admin-spam.component.css']
})
export class AdminSpamComponent implements OnInit {
  public helpTab = 0; // remove if done. Change the View of the information Tab
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataMailbox;
  public dropdownDataCategory: any[];
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public fileToUpload: File = null;
  public userData;
  public mailDropdown = -1;
  public spamData;
  public rightMailText;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', text : '', category1 : '', category2 : '', timestampString : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { text: ''};
  }

  initCheckboxActionDropdownData(): void {
    this.checkboxActionDropdownData = [{ value : '1', name : 'Action1'}, { value : '2', name : 'Action2'}];
  }

  constructor(public http: HttpClient,
              public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    this.initCheckboxActionDropdownData();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(this.settings.restBaseUrl + 'mail/mailbox', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataMailbox = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'template/category/suggest', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategory = data;
        }
    );
  }

  ngOnInit() {
    this.search();
    document.getElementById('searchText').focus();
  }

  loadSpam()
  {
    this.rightMailText = '';
    this.http.post<any[]>(this.settings.restBaseUrl + 'mail/spam/' + this.mailDropdown, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.spamData = data;
        }
    );
  }

  search(): void {

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'admin/rightGroupList', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.searchResultCount = data.count;
          this.data = data.data;
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search();
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search();
  }

  edit($event: MouseEvent, item: any): void  {
          this.rightMailText = item.EmailText;
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'template/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search();
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  markSpam(item)
  {
    this.http.put<{data, message}>(
        this.settings.restBaseUrl + 'mail/unmarkSpam/' + this.mailDropdown, JSON.stringify(item), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.loadSpam();
        });
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'template/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search();
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search();
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  checkboxSwap($event, item) {
    if ($event.shiftKey) {
      this.checkboxRangeSelection(item);
      if ($event.target.type === 'checkbox') {item.checked = false; }
      return;
    }

    this.checkboxShiftStartElement = false;

    if ($event.target.type !== 'checkbox') {item.checked = !item.checked; }
    this.handleCheckboxChanged(true);
    if ($event.target.type !== 'checkbox') { return false; }
  }

  checkboxSwapAll(): void  {
    for (const item of this.data) {
      item.checked = this.checkboxAllState;
    }
    this.handleCheckboxChanged(false);
  }

  checkboxRangeSelection(clickedItem): void  {
    if (this.checkboxAllState) { return; }
    if (this.checkboxShiftStartElement == null) { this.checkboxShiftStartElement = clickedItem;
    } else {
      let direction = 'backwards';

      for (const item of this.data) {
        if (item === clickedItem)                       {direction = 'forward'; }
        if (item === this.checkboxShiftStartElement)    {direction = 'backwards'; }
      }

      if (direction === 'backwards') {
        const temp = this.checkboxShiftStartElement;
        this.checkboxShiftStartElement = clickedItem;
        clickedItem = temp;
      }

      let mark = false;

      for (const item of this.data) {
        if (item === this.checkboxShiftStartElement) {mark = true; }
        if (mark) {item.checked = true; }
        if (item === clickedItem) { mark = false; }
      }

      this.checkboxShiftStartElement = null;
    }
    this.handleCheckboxChanged(true);
  }

  handleCheckboxChanged(clearAllState): void  {
    if (clearAllState) { this.checkboxAllState = false; }
    this.checkboxData = this.data.filter((item) => item.checked);
  }

  checkboxActionDropdownChanged(): void {
    if (this.checkboxActionDropdown === '1') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action1_Parameter1'},
        {value: '2', name: 'Action1_Parameter2'},
        {value: '3', name: 'Action1_Parameter3'}];
    } else if (this.checkboxActionDropdown === '2') {
      this.checkboxActionParameterDropdownData = [
        {value: '1', name: 'Action2_Parameter1'},
        {value: '2', name: 'Action2_Parameter2'},
        {value: '3', name: 'Action2_Parameter3'}];
    }
  }

  handleCheckboxAction(): void  {
    this.handleCheckboxChanged(false);
    let alertString =   'Checkboxes Action: ' + this.checkboxActionDropdown +
        ' Parameter: ' + this.checkboxActionParameterDropdown +
        ' - Boxes: ';
    for (const item of this.checkboxData) {
      alertString += ' - ' + item.id;
    }
    alert(alertString);
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(); }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
}
