<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>
        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    System > E-Mail Warteschlange
                    <span *ngIf="searchResultCount>data.length" style="float:right; margin-right:10px;">{{data.length}} von {{searchResultCount}} Einträgen</span>
                    <span *ngIf="searchResultCount<=data.length" style="float:right; margin-right:10px;">{{searchResultCount}} Einträge</span>
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">


                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:200px;">
                            <option value="0">Nicht gesendet/Fehler</option>
                            <option value="1">Gesendet</option>
                            <option value="-1">Alle</option>
                        </select>



                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.year"
                                style="width:80px;"
                                tabindex="2">
                            <option value="">Alle Jahre</option>
                            <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.create_timestamp" name="Erstellt"  sortColumn="create_timestamp"></span>
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ID"  sortColumn="id"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="titel"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_user"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td (click)="edit($event,x)" style="width:80px;">{{ x.create_timestamp_string }}</td>

                        <td (click)="edit($event,x)" style="width:80px;">{{ x.id }}
                        <span style="display: inline-block;float: right;" *ngIf="x.active == '2'"><img src="../design/icons/stop.png"></span>
                        </td>

                        <td (click)="edit($event,x)">
                            Von <B>{{ x.from_mail_address }}</B> an <B>{{ x.main_mail_address }}</B><img *ngIf="x.attachments>0" style="float: right;" src="../design/icons/attach.png">
                            <BR><span style="font-size: 1.2em;">{{ x.name }}</span></td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.create_user }}</td>

                        <td style="width:20px;">
                            <img (click)="delete(x)" src="../design/icons/delete.png">
                        </td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>


        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
            </div>

            <div id="rightContainerContent">
                    <table style="width:100%; height: 100%;" class="data_tabel light" cellpadding="6" cellspacing="0">
                        <tr>
                            <td><b>Titel:</b></td>
                            <td colspan="3">{{currentSelected.titel}}</td>
                        </tr>

                        <tr>
                            <td style="width:115px;"><b>ID:</b></td>
                            <td style="width:50%">{{currentSelected.id}}</td>
                            <td style="width:115px;"><b>TID:</b></td>
                            <td style="width:50%">{{currentSelected.tid}}</td>
                        </tr>

                        <tr>
                            <td><b>Ersteller:</b></td>
                            <td>{{currentSelected.create_user}}</td>
                            <td><b>Zeit:</b></td>
                            <td>{{currentSelected.create_timestamp_string}}</td>
                        </tr>

                        <tr>
                            <td><b>Gesendet:</b></td>
                            <td>{{currentSelected.send_time_string}}</td>
                            <td><b>Gelesen:</b></td>
                            <td>{{currentSelected.mail_read_string}}</td>
                        </tr>
                        <tr>
                            <td><b>Absender:</b></td>
                            <td colspan="3">{{currentSelected.from_mail_address}}</td>
                        </tr>

                        <tr>
                            <td><b>HauptEmpfänger:</b></td>
                            <td colspan="3">{{currentSelected.main_mail_address}}</td>
                        </tr>

                        <tr>
                            <td><b>Empfänger:</b></td>
                            <td colspan="3">{{currentSelected.to_address}}</td>
                        </tr>

                        <tr *ngIf="currentSelected.cc">
                            <td><b>CC Empfänger:</b></td>
                            <td colspan="3">{{currentSelected.cc}}</td>
                        </tr>

                        <tr *ngIf="currentSelected.bcc">
                            <td><b>BCC Empfänger:</b></td>
                            <td colspan="3">{{currentSelected.bcc}}</td>
                        </tr>

                        <ng-container *ngIf="currentSelected.attachments != undefined && currentSelected.attachments.length > 0">
                            <tr>
                                <th colspan="3" style="text-align: left;">Anhänge</th><th>kb</th>
                            </tr>
                            <tr *ngFor="let x of currentSelected.attachments" >
                                <td colspan="3">{{x.name}}</td>
                                <td style="width:50px; text-align: right;">{{(x.size / 1000 ) | number:'1.2-2'}}</td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="currentSelected.errorMessage!=null && currentSelected.errorMessage!=''"  >
                            <td colspan="4">
                                    <B>Fehlermeldung:</B>
                                    <div class="error" style="padding: 10px;">
                                        {{currentSelected.errorMessage}}<BR>
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height: 100%; border-top: 2px solid gray;">
                                <div style="margin-left: 10px; height: 100%;">
                                    <span app-resize-iframe [(model)]="currentSelected.html_text"></span>
                                </div>
                            </td>
                        </tr>
                    </table>

            </div>
        </div>
    </as-split-area>
</as-split>
