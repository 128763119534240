<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header1Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Vorgangsmanagement > Zusammenführen
                </span>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>

                <table style="border:none;margin-left: 15px;margin-top:10px; margin-bottom: 10px; width: calc( 100% - 30px);" cellspacing="0" cellpadding="4" class="form" >
                    <tr>
                        <td colspan="4" style="height:45px;">
                            <span class="formCaption">Titel</span>
                            <input type="text"
                                   id="titleTextField"
                                   [(ngModel)]="formData.titel"
                                   style="width: calc( 100% - 7px );"
                                   tabindex="220">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="height:45px;">
                            <button (click)="initCurrentSelected()">Ergebnis anzeigen</button>
                            <button style="float: right;margin-left:4px;" (click)="checkForOpenWorkdocuments()">Zusammenführen</button>
                            <button style="float: right" (click)="cancelMerge()">Abbrechen</button>
                        </td>
                    </tr>
                </table>

                <table class="data_tabel light" style="width: 100%; border-top:1px solid gray;" cellpadding="4" cellspacing="0">
                    <tr>
                        <th style="width:80px;">
                            Tid
                        </th>
                        <th>
                           Titel
                        </th>
                        <th style="width:150px;">
                            Datum
                        </th>
                    </tr>
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td (click)="view($event,x)" style="width:80px;">{{ x.tid }}</td>

                        <td (click)="view($event,x)">{{ x.titel }}</td>

                        <td (click)="view($event,x)" style="width:150px;">{{ x.send_time_string }}</td>
                    </tr>
                </table>
            </div>

            <div id="leftContainerBottom">

            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="currentSelected.tid>1" style="margin-left:10px; margin-top:4px;">Tid: {{currentSelected.tid}}</div>
                <div *ngIf="currentSelected.tid<2" style="margin-left:10px; margin-top:4px;">Ergebniss nach dem Zusammenführen</div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <span app-process-view [tid]="currentSelected.tid" [list]="list"></span>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal class="jw-modal shadow" id="editorWarning">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div [innerHTML]="editorWarningString | safe" class="form" style="margin:20px; line-height: 1.4;">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
