<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Firma > Grunddaten
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.active"
                            style="width:80px;"
                            tabindex="2">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                    </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="4">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="5">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Name" sortColumn="site_name"></span>
                        </th>
                        <th style="width:250px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Ort" sortColumn="city"></span>
                        </th>
                        <th style="width:250px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Straße" sortColumn="street"></span>
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">
                        <td (click)="edit(x)">&nbsp;{{ x.site_name }}</td>
                        <td (click)="edit(x)" style="width:250px;">{{ x.city }}</td>
                        <td (click)="edit(x)" style="width:250px;">{{ x.address }}</td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="showNewItemForm" style="margin-left:5px; margin-top:6px;">
                    <span>Neuen Standort anlegen</span>
                </div>
                <div *ngIf="currentSelected.id>0" style="margin-left:10px; margin-top:0px;">
                    <span>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Standort bearbeiten</button>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Standortbenutzer</button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px; overflow-x: hidden; height: 100%;">
                    <table style="width:99%; height: 100%;" cellpadding="6" *ngIf="currentSelectedTab === 1">
                        <tr style="height: 30px;">
                            <td style="width: 50%;">
                                <span class="formCaption">Name *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.site_name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>

                            <td style="width: 50%;">
                                <span class="formCaption">Kürzel *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.site_short_name"
                                       id="currentSelectedShortName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="102" >
                            </td>
                        </tr>

                        <tr style="height: 30px;">
                            <td style="width: 50%;">
                                <span class="formCaption">PLZ *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.postcode"
                                       id="currentSelectedCity"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="103" >

                            </td>

                            <td style="width: 50%;">
                                <span class="formCaption">Ort *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.city"
                                       id="currentSelectedPostCode"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="104" >
                            </td>
                        </tr>

                        <tr style="height: 30px;">
                            <td style="width: 50%;">
                                <span class="formCaption">Straße *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.street"
                                       id="currentSelectedStreet"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="105" >
                            </td>

                            <td style="width: 50%;">
                                <span class="formCaption">Hausnummer *</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.street_number"
                                       id="currentSelectedStreetNumber"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="106" >
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <span class="formCaption">E-Mail Signatur</span>
                                <editor [(ngModel)]="currentSelected.email_signature"
                                        class="noForm"
                                        style="width: calc(100% + 7px); height: 100%"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit"
                                        tabindex="107"
                                >
                                </editor>
                            </td>
                        </tr>

                        <tr style="height: 50px;">
                            <td colspan="2">
                                <span class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="108">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <div style="left: 0; right: 0; top: 0; height: 56px; position: absolute; overflow-y: hidden; overflow-x: hidden;" *ngIf="currentSelectedTab === 2">
                    <table style="width:100%; margin-left: -5px;" class="form_tabel light" cellpadding="4" cellspacing="0">
                        <tr>
                            <td>
                                <select [(ngModel)]="addToSiteUserId"
                                        id="addToSiteUserId"
                                        class="cp-textbox"
                                        style="width: calc(100% - 8px); margin-left: 10px;"
                                        tabindex="101" >
                                    <option value="-1">Benutzer Wählen</option>
                                    <option *ngFor="let x of selectableUsers" value="{{x.id}}">{{x.name}},{{x.first_name}}</option>
                                </select>
                            </td>
                            <td style="width: 100px;" class="form">
                                <button (click)="checkIfUserAlreadyAssigned()" style="margin-left: 10px; width: 110px;" tabindex="102">
                                    <img src="../design/icons/add.png"> Hinzufügen
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table style="width: calc(102% + 1px); margin-left: -4px;" class="form_tabel light" cellpadding="2" cellspacing="0">
                                    <tr>
                                        <th style="width: 300px;">Name</th>
                                        <th>Position</th>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    </div>
                    <div style="left: 0; right: 0; bottom: 0; top: 55px; position: absolute; overflow-y: auto; overflow-x: hidden;" *ngIf="currentSelectedTab === 2">
                    <table style="width:100%; margin-left: -5px;" class="form_tabel light" cellpadding="6" cellspacing="0">
                        <tr>
                            <td>
                                <table style="width: calc(102% + 4px); margin-left: -4px; margin-top: -6px;" class="data_tabel" cellpadding="2" cellspacing="0">
                                    <tr *ngFor="let x of currentSiteUsers">
                                        <td style="width: 300px;">
                                            &nbsp;{{x.name}}
                                        </td>
                                        <td>
                                            &nbsp;{{x.position}}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>
</context-menu>
