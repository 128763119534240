<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div *ngIf="viewEditor" class="header1Row footerNone" id="leftContainerDefinition">
            <div id="leftContainerTop">
                <div class="editorHeader">
                    <span *ngIf="viewEditor=='new' && ( formData.email == '' || formData.noMail )"><img style="width:16px" src="../design/icons/page_add.png"> Neuer Vorgang</span>
                    <span *ngIf="viewEditor=='new' && formData.email != '' && !formData.noMail"><img style="width:16px" src="../design/icons/email_out.png"> Neuer Vorgang anlegen und E-Mail schreiben</span>
                    <span *ngIf="viewEditor=='email'"><img style="width:16px" src="../design/icons/email_out.png"> E-Mail schreiben aus Vorgang {{currentSelected.header.tid}}</span>
                    <span *ngIf="viewEditor=='reply'"><img style="width:16px" src="../design/icons/email_reply.png"> E-Mail an Hauptkontakt schreiben</span>
                    <span *ngIf="viewEditor=='reply-item'"><img src="../design/icons/email_reply.png"> E-Mail an Kontakt schreiben</span>
                    <span *ngIf="viewEditor=='forward'"><img style="width:16px" src="../design/icons/email_out.png"> E-Mail Weiterleiten</span>
                    <span *ngIf="viewEditor=='edit'"><img src="../design/icons/page_edit.png"> Vorgang bearbeiten</span>
                    <span *ngIf="viewEditor=='comment'"><img src="../design/icons/page_edit.png"> Kommentar anhängen</span>
                    <span *ngIf="viewEditor=='changeMainData'"><img src="../design/icons/user_go.png"> Zuständiger und/oder Team/Projekt ändern</span>
                    <span *ngIf="viewEditor=='time'"><img src="../design/icons/time.png"> Arbeitszeit anhängen</span>
                    <span *ngIf="viewEditor=='call'"><img src="../design/icons/telephone.png"> Telefonat anhängen</span>
                    <span *ngIf="viewEditor=='extern_work'"><img src="../design/icons/car.png"> Außentermin erstellen</span>
                    <span *ngIf="viewEditor=='intern_work'"><img src="../design/icons/house.png"> Arbeitsauftrag erstellen</span>
                    <span *ngIf="viewEditor=='state'"><img src="../design/icons/brick.png"> Status ändern</span>
                    <span *ngIf="viewEditor=='escalate'"><img src="../design/icons/exclamation.png"> Vorgang eskalieren</span>
                    <span *ngIf="viewEditor=='bill'"><img src="../design/icons/money.png"> Bisher angefallene Arbeitszeit abrechnen</span>
                    <span *ngIf="viewEditor=='closeWithWorktime'"><img src="../design/icons/stop.png"> Vorgang mit nicht abgerechneten Arbeitswerten schließen</span>
                    <span *ngIf="viewEditor=='document'"><img style="width:16px" src="../design/icons/page_white_get.png"> Dokument anhängen</span>
                    <span *ngIf="viewEditor=='withdrawWorkdocument'"><img src="../design/icons/delete.png"> Arbeitsbeleg für "{{withdrawWorkdocumentSelect.titel}}
                        " widerrufen</span>
                    <span *ngIf="formData.finalize == 0 && formData.id > 0" style="color: #ffc080;"> - zur späteren Bearbeitung gespeichert.</span>
                </div>
            </div>

            <div id="leftContainerContent" style="background-color: #f5f5f5;">
                <table cellpadding="4" cellspacing="0" class="form" style="border:none;height:calc( 100% - 20px); margin-left: 15px;margin-top:10px; width: calc( 100% - 30px);">
                    <tr *ngIf="viewEditor=='bill'">
                        <td colspan="4" style="height:40px;">
                            <span class="formCaption">Abzurechnende Arbeitszeit</span>
                            <table cellpadding="6" cellspacing="0"
                                   style="border: 1px solid #dddddd;background-color: white;width: 100%;">
                                <tr *ngFor="let worktimeEntry of currentSelected.worktime;">
                                    <td style="width:10%; white-space: nowrap;"><b style="margin-left: 10px;">{{worktimeEntry.type}} </b>
                                    </td>
                                    <td> {{worktimeEntry.value}} {{worktimeEntry.unit}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor=='new'">
                        <td colspan="4" style="height:40px;">
                            <div class="formCaption" style="float:left; margin-top: 4px;">Betrifft</div>
                            <div class="formCaption" style="float:right; display: inline-block;">
                                <label>
                                    <input (change)="changeNoContact()"
                                           [(ngModel)]="formData.noContact"
                                           [checked]="isCheckedNoContact"
                                           style="vertical-align: middle;"
                                           type="checkbox"> Kein Betreffender
                                </label>
                                <label>
                                    <input (change)="changeSendMail()"
                                           [(ngModel)]="formData.noMail"
                                           [checked]="isCheckedNoMail"
                                           style="vertical-align: middle;"
                                           type="checkbox"> Keine E-Mail
                                </label>
                            </div>

                            <div style="width: 100% ;float: left; clear:both;">
                                <table style="border-collapse: collapse; border: none; width:100%;">
                                    <tr>
                                        <td>
                                            <app-contact-manager
                                                    [(company)]="formData.company"
                                                    [(contact)]="formData.contact"
                                                    [(site)]="formData.site"
                                                    [mode]="'contact'"
                                                    style="width:100%;"
                                                    tabindex="210">
                                            </app-contact-manager>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="(viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward' ) && toggleTo == 1 && formData.noMail!=true">
                        <td colspan="4" style="height:40px;">
                            <div class="formCaption" style="float:left;">VON</div>
                            <div class="error" style="width: calc(100% - 4px );float: left; clear:both;border: 1px solid #ceced2; height:22px;">
                                <div style="margin: 3px 3px 3px 4px;" [innerHTML]="getFromText() | safe"></div>
                            </div>
                        </td>
                    </tr>


                    <tr *ngIf="viewEditor=='new' && toggleTo == 1  && formData.noMail!=true ">
                        <td colspan="4" style="height:40px;">
                            <div class="formCaption" style="float:left; margin-top: 4px;">AN</div>

                            <div style="width: 100% ;float: left; clear:both;">
                                <table style="border-collapse: collapse; border: none; width:100%;">
                                    <tr>
                                        <td>
                                            <app-contact-manager
                                                    (addContact)="addTo()"
                                                    (nameStrings)="setToNames($event)"
                                                    (onChange)="addTo()"
                                                    [(company)]="newTo.company"
                                                    [(connectId)]="newTo.connectId"
                                                    [(contact)]="newTo.contact"
                                                    [(site)]="newTo.site"
                                                    [mode]="'email'"
                                                    style="width:100%;"
                                                    tabindex="210">
                                            </app-contact-manager>
                                        </td>
                                        <td *ngIf="formData.noMail == false" style="width: 95px; text-align: right;" class="form">
                                            <button (click)="toggleCCValue()" [ngClass]="{'selected' : toggleCC }" style="min-width:40px;margin-right: 1px; ">CC</button>
                                            <button (click)="toggleBCCValue()"
                                                    [ngClass]="{'selected' : toggleBCC }" style="min-width:40px;margin-right: 4px;">BCC</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor=='call'">
                        <td colspan="4" style="height:40px;">
                            <span class="formCaption">Kontakt</span>
                            <app-contact-manager
                                    (change)="changeTo()"
                                    (nameStrings)="handelContactStringChange($event)"
                                    [(company)]="formData.company"
                                    [(connectId)]="formData.connectId"
                                    [(contact)]="formData.contact"
                                    [(site)]="formData.site"
                                    [mode]="'phone'"
                                    style="width: 100%;"
                                    tabindex="200">
                            </app-contact-manager>
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor=='extern_work' || viewEditor=='intern_work'">
                        <td colspan="4" style="height:40px;">
                            <span class="formCaption">Kontakt</span>
                            <app-contact-manager
                                    (change)="changeTo()"
                                    (nameStrings)="handelContactStringChange($event)"
                                    [(company)]="formData.company"
                                    [(connectId)]="formData.connectId"
                                    [(contact)]="formData.contact"
                                    [(site)]="formData.site"
                                    [mode]="'full'"
                                    style="width: 100%;"
                                    tabindex="200">
                            </app-contact-manager>
                        </td>
                    </tr>



                    <tr *ngIf=" (viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward' ) && toggleTo == 1">
                        <td colspan="4" style="height:40px;">
                            <div class="formCaption" style="float:left;">AN</div>
                            <div style="width: calc( 100% - 90px );float: left; clear:both;">
                                <app-contact-manager
                                        (nameStrings)="setToNames($event)"
                                        (onChange)="addTo()"
                                        [(company)]="newTo.company"
                                        [(connectId)]="newTo.connectId"
                                        [(contact)]="newTo.contact"
                                        [(site)]="newTo.site"
                                        [mode]="'email'"
                                        style="width:100%;"
                                        tabindex="210">
                                </app-contact-manager>
                            </div>
                            <span>
                                <div class="form" style="width: 85px; float: right; text-align: right;">
                                    <button (click)="toggleCCValue()" *ngIf="formData.noMail == false"
                                            [ngClass]="{'selected' : toggleCC }"
                                            style="min-width:40px;margin-right: 1px; ">CC</button>
                                    <button (click)="toggleBCCValue()" *ngIf="formData.noMail == false"
                                            [ngClass]="{'selected' : toggleBCC }"
                                            style="min-width:40px;margin-right: 4px;">BCC</button>
                                </div>
                                </span>
                        </td>
                    </tr>
                    <tr *ngIf="formData.to && formData.to.length>0 && ( viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward')">
                        <td colspan="4" style="height:10px; ">
                            <div style="width:100%;margin-top: -8px; font-size: 11px;line-height: 2; ">
                                    <span (click)="deleteTo(x)"
                                          *ngFor="let x of formData.to"
                                          [contextMenuValue]="x"
                                          [contextMenu]="basicMenu"
                                          class="itemBox"
                                          style="line-height: 1.4em; height: 32px;">
                                        <b>{{x.name}}</b>
                                        <img src="../design/icons/delete.png"
                                             style="float: right; margin-top:1px; margin-left:4px;"><BR>{{x.email}}
                                    </span>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf=" ( viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward' || viewEditor=='extern_work' || viewEditor=='intern_work' ) && !formData.noMail && toggleCC == 1">
                        <td colspan="4" style="height:40px;">
                            <span class="formCaption">CC</span>
                            <app-contact-manager
                                    (nameStrings)="setCCNames($event)"
                                    (onChange)="addCC()"
                                    [(company)]="newCC.company"
                                    [(contact)]="newCC.contact"
                                    [(site)]="newCC.site"
                                    [mode]="'email'"
                                    style="width:100%;"
                                    tabindex="210">
                            </app-contact-manager>
                        </td>
                    </tr>
                    <tr *ngIf="formData && formData.cc && formData.cc.length>0 && ( viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward')  && !formData.noMail ">
                        <td colspan="4" style="height:10px; ">
                            <div style="width:100%;margin-top: -8px; font-size: 11px;line-height: 2; ">
                                    <span (click)="deletecc(x)"
                                          *ngFor="let x of formData.cc"
                                          class="itemBox"
                                          style="line-height: 1.4em; height: 32px;">
                                        <b>{{x.name}}</b>
                                        <img src="../design/icons/delete.png"
                                             style="float: right; margin-top:1px; margin-left:4px;"><BR>{{x.email}}
                                    </span>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf=" ( viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward' || viewEditor=='extern_work' || viewEditor=='intern_work' ) && !formData.noMail && toggleBCC == 1">
                        <td colspan="4" style="height:40px;">
                            <span class="formCaption">BCC</span>
                            <app-contact-manager
                                    (nameStrings)="setBCCNames($event)"
                                    (onChange)="addBCC()"
                                    [(company)]="newBCC.company"
                                    [(contact)]="newBCC.contact"
                                    [(site)]="newBCC.site"
                                    [mode]="'email'"
                                    style="width:100%;"
                                    tabindex="210">
                            </app-contact-manager>
                        </td>
                    </tr>
                    <tr *ngIf="formData.bcc && formData.bcc.length>0 && ( viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='reply-item'|| viewEditor=='forward')  && !formData.noMail ">
                        <td colspan="4" style="height:10px; ">
                            <div style="width:100%;margin-top: -8px; font-size: 11px;line-height: 2;">
                                <span (click)="deleteBcc(x)" *ngFor="let x of formData.bcc"
                                      class="itemBox" style="line-height: 1.4em; height: 32px;"><b>{{x.name}}</b> <img
                                        src="../design/icons/delete.png"><BR>{{x.email}}</span>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor=='new' || viewEditor=='edit' || viewEditor=='new-email' || viewEditor=='email'|| viewEditor=='reply' || viewEditor=='reply-item' || viewEditor=='forward'">
                        <td colspan="4" style="height:45px;">
                            <span class="formCaption">Titel</span>
                            <input (blur)="editorIntervallSave()"
                                   (keydown)="setEditorChanged()"
                                   [(ngModel)]="formData.titel"
                                   style="width: calc( 100% - 7px );"
                                   tabindex="220"
                                   type="text">
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor!='state' && viewEditor!='withdrawWorkdocument'">
                        <td colspan="4">
                            <editor (blur)="editorIntervallSave()"
                                    (onKeyPress)="setEditorChanged()"
                                    [(ngModel)]="formData.text"
                                    [init]="settings.tinyMceInit"
                                    apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                    class="noForm"
                                    initialValue=""
                                    style="height:100%;">
                            </editor>
                        </td>
                    </tr>
                    <tr *ngIf="viewEditor=='withdrawWorkdocument'">
                        <td colspan="4">
                            <editor [(ngModel)]="withdrawWorkdocumentSelect.text"
                                    [init]="settings.tinyMceInit"
                                    apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                    class="noForm"
                                    initialValue=""
                                    style="height:100%;">
                            </editor>
                        </td>
                    </tr>
                    <tr *ngIf="viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='forward' || viewEditor=='reply-item' || viewEditor == 'document' ">
                        <td colspan="4" style="height:10px; vertical-align: top;">
                            <div class="form" style="margin-top:1px;">
                                <span class="formCaption">Anhänge -
                                    <span *ngIf="combinedAttachmentSize>20"
                                          style="color: red; font-weight: bold;">{{combinedAttachmentSize}} MB </span>
                                    <span *ngIf="combinedAttachmentSize<=20">{{combinedAttachmentSize}} MB </span>
                                    von maximal 20 MB angehängt. </span><BR>
                                <button style="width: 150px;"><label for="inputId"><img
                                        src="../design/icons/folder.png"> Anhänge hochladen</label></button>
                                <button (click)="attachFromDMS(currentSelected.header)"
                                        style="width: 150px;margin-left:-5px;"><img
                                        src="../design/icons/attach.png"> Anhänge aus DMS
                                </button>
                                <button *ngIf="fileUploading" style="font-weight: bold;"><img
                                        src="../design/icons/roller.gif" style="width:16px;"> Wird hochgeladen
                                </button>
                                <input (change)="uploadAttachment($event);" id="inputId" multiple style="position: fixed; top: -100em"
                                       type="file">
                                <span *ngFor="let att of formData.attachment" [contextMenuValue]="att"
                                      [contextMenu]="attachmentContactMenu">
                                    <span *ngIf="att.inline!=1" class="itemBox" style="width: min-content;">
                                        <span *ngIf="att.edit==1">
                                        <span class="show" style="width: calc( 100% - 38px );">
                                            <input (keydown)="saveAttachmentAfterRenameKeydown($event,att)"
                                                   [(ngModel)]="att.name"
                                                   id="addInput{{att.id}}"
                                                   type="text">
                                        </span>
                                            <span (click)="saveAttachmentAfterRename(att)" class="del">
                                                <img src="../design/icons/disk.png">
                                            </span>
                                        </span>
                                        <span [style.visibility]="att.edit!=1 ? 'visible' : 'hidden'"
                                              style="width: max-content; float: left">
                                            <span (click)="viewAttachment(att)" class="show">
                                                <span *ngIf="att.icon!=null"><img src="{{att.icon}}"> </span>
                                                <span *ngIf="att.icon==null"><img src="../design/icons/page_white.png"> </span>
                                                {{att.name}} - {{(att.size / 1000000).toFixed(2)}}MB
                                            </span>
                                            <span (click)="deleteAttachment(att)" class="del">
                                                <img src="../design/icons/cross_black.png">
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="viewEditor=='bill' || viewEditor=='new' || viewEditor=='new-email' || viewEditor=='email' || viewEditor=='reply' || viewEditor=='forward' || viewEditor=='call' || viewEditor=='state' || viewEditor=='comment' || viewEditor=='time' || viewEditor=='closeWithWorktime' || viewEditor=='document' || viewEditor === 'changeMainData' ">
                        <td *ngIf="formData.type=='team'" style="width:25%;height:20px;">
                            <span class="formCaption">Team</span>
                            <select (change)="teamChanged()"
                                    [(ngModel)]="formData.fk_team_id"
                                    [disabled]="currentSelected.header.assigned != settings.data.userId && currentSelected.header.assigned!='0' && ( currentSelected.header.subMdi == 1 || (viewEditor!='new' && viewEditor != 'changeMainData')) ? true : null"
                                    style="width:100%;">
                                <option *ngIf="formData.fk_team_id=='-1'" value="-1">Team wählen</option>
                                <optgroup class="myTeamHead" label="Meine"></optgroup>
                                <option *ngFor="let x of teams" [ngClass]="{ 'myTeam' : x.fk_team_role > 2  }"  [value]="x.id">
                                    <span *ngIf="x.fk_team_role==1">👁 </span>
                                    {{x.name}}
                                </option>
                                <option disabled>───────────────</option>
                                <option value="-2">In Projekt verschieben</option>
                            </select>
                        </td>
                        <td *ngIf="formData.type=='project'" style="width:25%;height:20px;">
                            <span class="formCaption">Projekt</span>
                            <select (change)="projectChanged()"
                                    [(ngModel)]="formData.project"
                                    [disabled]="currentSelected.header.assigned!='0' && ( currentSelected.header.subMdi == 1 ||  (viewEditor!='new' && viewEditor != 'changeMainData') ) ? true : null"
                                    style="width:100%;">
                                <option *ngIf="formData.project=='-1'" value="-1">Projekt wählen</option>
                                <option *ngFor="let x of dropdownDataProject" [value]="x.id">{{x.name}}</option>
                                <option disabled>───────────────</option>
                                <option value="-2">Aus Projekt entfernen</option>
                            </select>
                        </td>
                        <td style="width:25%;">
                            <span class="formCaption">Zuständiger Mitarbeiter</span>
                            <select [(ngModel)]="formData.assigned"
                                    [disabled]="currentSelected.header.assigned != settings.data.userId && currentSelected.header.assigned!='0' && ( currentSelected.header.subMdi == 1 ||  (viewEditor!='new' && viewEditor != 'changeMainData') ) ? true : null"
                                    style="width:100%;">
                                <option value="0">Kein Zuständiger</option>
                                <option *ngFor="let x of teamusers" value="{{x.user_id}}">{{x.user_name}}</option>
                            </select>
                        </td>
                        <td style=";width:25%;">
                            <span class="formCaption">Kategorie</span>
                            <select [disabled]="currentSelected.processRight == false  && currentSelected.header.id>0 ? true : null" (change)="categoryChanged()" [(ngModel)]="formData.kategorie" style="width:100%;" >
                                <option *ngFor="let x of categorys" [value]="x.id">{{x.name}}</option>
                            </select>
                        </td>
                        <td style="width:25%;">
                            <span class="formCaption">Status</span>
                            <select [disabled]="currentSelected.processRight == false && currentSelected.header.id>0 ? true : null"[(ngModel)]="formData.state_fk_id" style="width:100%;">
                                <option *ngFor="let x of states" [value]="x.id"><span
                                        *ngIf="x.required_to_close==1">* </span>{{x.state_name}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="viewEditor=='call' || viewEditor=='time'">
                        <td class="form" colspan="4" style="height:45px;">
                            <span class="formCaption">Arbeitszeit</span><br>
                            <span [(billingType)]="formData.fk_process_billing_type" [(time)]="formData.time_duration" [autostart]="formData.autoStartClock"
                                  app-billing-clock></span>
                        </td>
                    </tr>

                    <tr>
                        <td class="form">
                            <select *ngIf="viewEditor=='bill'" [(ngModel)]="formData.costCentre" style="width:100%;" tabindex="201">
                                <option value="0">Kostenstelle wählen</option>
                                <option *ngFor="let x of costCentres" value="{{x.id}}">{{x.fullname}}</option>
                            </select>
                        </td>
                        <td>
                            <span app-date-picker
                                  openAtTop = true
                                  *ngIf="viewEditor=='bill'"
                                  [(model)]="formData.receiptDate"
                                  [placeholder]="'Belegdatum'"
                                  id="receiptDate"
                                  tabindex="202"
                                  style="width:100%;">
                            </span>
                        </td>
                        <td class="form" colspan="2" style="height:28px;">
                            <button (click)="save(1)"
                                    *ngIf="viewEditor != 'withdrawWorkdocument' && viewEditor != 'changeMainData'"
                                    style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png"><span
                                    *ngIf="(viewEditor =='new' && formData.noContact !== true && formData.noMail === false) || viewEditor == 'reply' || viewEditor == 'email' || viewEditor == 'forward' || viewEditor == 'reply-item'"> E-Mail Senden und</span>
                                Speichern
                            </button>
                            <button (click)="withdrawWorkdocument()" *ngIf="viewEditor == 'withdrawWorkdocument'"
                                    style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png">
                                Speichern
                            </button>
                            <button (click)="checkCharacters()" *ngIf="viewEditor == 'changeMainData'"
                                    style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png">
                                Speichern
                            </button>
                            <button (click)="message.open('discardMDI');"
                                    *ngIf="viewEditor != 'closeWithWorktime' && formData.mdi != 0" style="float: right;margin-right: 4px;"><img
                                    src="../design/icons/cross.png"> Löschen
                            </button>
                            <button (click)="clearEditorData()"
                                    *ngIf="viewEditor != 'closeWithWorktime' && formData.mdi == 0" style="float: right;margin-right: 4px;"><img
                                    src="../design/icons/cross.png"> Abbrechen
                            </button>
                            <button (click)="returnToFormerEditor()" *ngIf="viewEditor == 'closeWithWorktime'"
                                    style="float: right;margin-right: 4px;"><img src="../design/icons/cross.png">
                                Abbrechen
                            </button>
                            <button (click)="exitEditor(false)"
                                    *ngIf="this.formData.text != '' &&( viewEditor =='new' || viewEditor == 'reply' || viewEditor == 'email' || viewEditor == 'forward' || viewEditor == 'reply-item' || viewEditor == 'comment'|| viewEditor == 'time' || viewEditor == 'call' )" style="float: right;margin-right: 4px;"><img
                                    src="../design/icons/page_save.png"> Später weiter bearbeiten
                            </button>
                        </td>
                    </tr>
                </table>

            </div>
        </div>

        <div *ngIf="!viewEditor" class="header4Row footer1Row" id="leftContainerDefinition">
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}}
                    Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span>
                </span>
                <span class="currentLocation">
                    Vorgangsmanagement > <select #modSel (change)="goTo(modSel)" class="modul_selector">
                        <option [selected]="list==9" value="process/9">Projekte</option>
                        <option [selected]="list==6" value="process/6">Eigene</option>
                        <option [selected]="list==3" value="process/3">Team</option>
                        <option [selected]="list==1" value="process/1">Verlauf</option>
                    </select><span *ngIf="list==9"> >
                    <select (change)="changeProject()" [(ngModel)]="project" class="modul_selector">
                        <option *ngFor="let x of userProjectList" value="{{x.id}}">{{x.name}}</option>
                    </select></span>
                </span>

                <span class="form search">
                    <input (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           [ngClass]="{ 'error' : searchParameter.text != '' }"
                           autocomplete="off"
                           id="processSearchText"
                           placeholder="Suchtext"
                           style="width:130px;"
                           tabindex="1"
                           type="text">

                    <app-contact-manager (onChange)="contactChanged()"
                                         [(company)]="searchParameter.company"
                                         [(contact)]="searchParameter.contact" [(site)]="searchParameter.site"
                                         [markOnSet]="true"
                                         [mode]="'free'"
                                         id="processSearchContact"
                                         style="width:178px; margin-right:2px; margin-left: -1px;">
                    </app-contact-manager>

                    <select (change)="getDropdownSearchParameterCategoryData()"
                            *ngIf="searchTeamDropdownDatasource && searchTeamDropdownDatasource.length > 1 && list != 9"
                            [(ngModel)]="searchParameter.fk_team_id"
                            [ngClass]="{ 'error' : searchParameter.fk_team_id > 0 }"
                            id="searchTeam"
                            name="searchTeam"
                            style="width: 100px;vertical-align: bottom; margin-left: -2px;"
                            tabindex="3">
                        <option value="-1">Meine Teams</option>
                        <option disabled>----------- Teams ------------</option>
                        <ng-container *ngFor="let x of searchTeamDropdownDatasource">
                            <option *ngIf="x != undefined && x.fk_team_role != undefined && (( list != 6 && x.fk_team_role!=2) || ( list == 6 && x.fk_team_role!=1 )) && x.fk_team_role > 0"  [value]="'t'+x.fk_groupid">
                                <span *ngIf="x.fk_team_role==1">👁 </span>{{x.name}}
                            </option>
                        </ng-container>
                        <option *ngIf="list!=6" value="-2">Alle Teams</option>
                        <option disabled>----------- Projekte ------------</option>
                        <ng-container *ngFor="let x of searchTeamDropdownDatasource">
                             <option *ngIf="x != undefined && !x.fk_team_role"  [value]="'p'+x.id">
                                {{x.name}}
                            </option>
                        </ng-container>


                    </select>

                    <select [(ngModel)]="searchParameter.type"
                            (change)="deactivateMailView()"
                            [ngClass]="{ 'error' : searchParameter.type != '-1' }"
                            id="searchType"
                            name="searchType"
                            style="width: 150px;vertical-align: bottom; margin-left: -1px"
                            tabindex="3">
                        <option disabled>----------- Vorgänge ------------</option>
                        <option value="-1">Alle Vorgänge</option>
                        <option value="29">Aufgaben</option>
                        <option value="98">Durch E-Mail erzeugt</option>
                        <option disabled>------ Einzelnen E-Mails ------</option>
                        <option value="99">Alle E-Mails</option>
                        <option value="28">Eingegangene E-Mails</option>
                        <option value="1">Gesendet E-Mails</option>
                    </select>


                    <button (click)="resetSearch(false)" (contextmenu)="resetSearch($event)" id="processSearchReset"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px; margin-right: 4px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-left:-1px;">
                    </button>

                    <app-list-search-filter (callback)="favoriteSelected()" [(model)]="searchParameter" [filter]="searchParameterFilter"
                                            [list]="list"></app-list-search-filter>


                    <button *ngIf="list==6" (click)="toggeleMarkedFilter()" id="processSearchToggleMarked" style="min-width:24px !important;width:24px;height:30px; margin-left:1px; margin-right: 2px;" tabindex="4" tooltip="Auf zur Bearbeitung markiert filtern">
                        <img *ngIf="searchParameter.markedFilter" src="../design/icons/accept.png" style="margin-left:-1px;">
                        <img *ngIf="!searchParameter.markedFilter" src="../design/icons/accept_inactive.png" style="margin-left:-1px;">
                    </button>

                    <button (click)="search(0)" [disabled]="showLoading ? 'disabled': null"
                            id="processSearchButton"
                            style="min-width: 80px; margin-left: 1px;"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="tutorial.start('process')" *ngIf="false" id="processSearchHelp"
                            style="min-width:24px !important;width:24px;height:30px;"
                            tabindex="4">
                        <img src="../design/icons/help.png" style="margin-left:-1px;">
                    </button>

                    <button *ngIf="allTicketCount > 0" (click)="switchToTeams()">
                       {{allTicketCount}} Vorgänge in Teams gefunden
                    </button>

                    <button (click)="handleProcessAction('new','')" id="processNewButton"
                            style="float:right; margin-right:8px; width: 80px; min-width: 80px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                   <button (click)="toggleFastNewProcessEditor()" *ngIf="false" id="processSearchFastEditorToggle"
                           style="min-width:24px !important;width:24px; vertical-align: bottom !important; float:right;"
                           tabindex="3">
                        <img *ngIf="fastNewProcessEditor" src="../design/icons/lightning.png" style="margin-left:-1px;">
                        <img *ngIf="!fastNewProcessEditor" src="../design/icons/lightning_inactiv.png"
                             style="margin-left:-1px;">
                    </button>

                    <button (click)="toggleListViewMode()" *ngIf="list==9" id="processSearchBoardViewToggle"
                            style="min-width:24px !important;width:24px; vertical-align: bottom !important; float:right;  "
                            tabindex="3">
                        <img *ngIf="listViewMode==1" src="../design/icons/text_align_justify.png"
                             style="margin-left:-1px;">
                        <img *ngIf="listViewMode==2" src="../design/icons/text_columns.png" style="margin-left:-1px;">
                    </button>

                    <span (change)="tableOptionChanged()" *ngIf="listViewMode==1" [(model)]="tableSettings" app-options
                          id="processListFilter" style="float: right; ;"></span>

                    <div *ngIf="expandedSearch" style="margin-top: 3px;">
                        <select (change)="search(0)"
                                *ngIf="list!=1"
                                [(ngModel)]="searchParameter.active"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }"
                                style="width:137px;">
                            <option value="1">Aktive</option>
                            <option value="2">Verborgene</option>
                            <option value="3">Aktive mit offener Arbeitszeit</option>
                            <option value="4">Aktive mit offenen Arbeitsbelegen</option>
                            <option value="0">Archivierte</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(0)" *ngIf="list==1"
                                [(ngModel)]="searchParameter.active"
                                [ngClass]="{ 'error' : searchParameter.active != '-1' }"
                                style="width:63px;">
                            <option value="-1">Alle</option>
                            <option value="5">Nur mit Arbeitszeit</option>
                        </select>

                        <span [(boundDatepicker)]="searchParameter.dateTo"
                              [(model)]="searchParameter.dateFrom"
                              [markOnSet]="true"
                              [placeholder]="'Datum von'"
                              app-date-picker
                              first="true"
                              id="dateFrom"
                              tabindex="102"
                              style="margin-left: -1px;">
                        </span>

                        <span [(boundDatepicker)]="searchParameter.dateFrom"
                              [(model)]="searchParameter.dateTo"
                              [markOnSet]="true"
                              [placeholder]="'Datum bis'"
                              app-date-picker
                              id="dateTo"
                              tabindex="102"
                              style="margin-left: 2px;">
                        </span>

                        <select (change)="search(0)"
                                *ngIf="list!=6"
                                [(ngModel)]="searchParameter.searchAssigned"
                                [ngClass]="{ 'error' : searchParameter.searchAssigned != '-1' }"
                                id="searchAssigned"
                                name="searchAssigned"
                                style="width:120px; margin-left:2px; margin-right: 1px;"
                                tabindex="4">
                            <option value="-1">Alle Zuständigen</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                        <span class="form">
                            <select (change)="getDropdownSearchParameterStateData()"
                                    [(ngModel)]="searchParameter.category"
                                    [ngClass]="{ 'error' : searchParameter.category != '-1' }"
                                    id="searchCategory"
                                    name="searchCategory"
                                    style="width: 112px; margin-left: 2px;border-top-left-radius: 6px; border-bottom-left-radius: 6px;"
                                    tabindex="5">
                                <option value="-1">Alle Kategorien</option>
                                <option *ngFor="let x of dropdownSearchParameterCategoryData"
                                        [value]="x.id">{{x.name}}</option>
                            </select>
                            <button (click)="changeSearchCategorySelectType()"
                                    style="min-width:24px; margin-left:-6px; border-radius: unset !important;; border-top-right-radius: 3px !important; border-bottom-right-radius: 3px !important;">
                                <img *ngIf="searchParameter.categoryType==0" src="../design/icons/select_first.png"
                                     tooltip="Nur Vorgänge mit dieser aktuellen Kategorie.">
                                <img *ngIf="searchParameter.categoryType==1" src="../design/icons/select_all.png"
                                     tooltip="Alle Vorgänge die diese Kategorie enthalten.">
                            </button>
                        </span>
                        <select (change)="search(0)"
                                *ngIf="searchParameter.category > 0"
                                [(ngModel)]="searchParameter.state_fk_id"
                                [ngClass]="{ 'error' : searchParameter.state_fk_id != '-1' }"
                                id="searchState"
                                name="searchState"
                                style="width: 87px; margin-left: 2px;"
                                tabindex="6">
                            <option value="-1">Alle Status</option>
                            <option *ngFor="let x of dropdownSearchParameterStateData"
                                    [value]="x.id">{{x.state_name}}</option>
                        </select>

                        <span *ngIf="false && (settings.data.userId === '1' || settings.data.userId === '822')"
                              class="form" style="margin-right:6px;display: inline-block; float:right;">
                            <select (change)="getDropdownSearchMoveUserData()"
                                    *ngIf="searchTeamDropdownDatasource && searchTeamDropdownDatasource.length > 1"
                                    [(ngModel)]="move.fk_team_id"
                                    id="moveTeam"
                                    name="moveTeam"
                                    style="width: 60px;vertical-align: bottom;"
                                    tabindex="3">
                                <option value="-1">Ziel Team wählen</option>
                                <option *ngFor="let x of searchTeamDropdownDatasource"
                                        [value]="x.fk_groupid">
                                    <span *ngIf="x.fk_team_role==1">👁 </span>{{x.name}}
                                </option>
                            </select>

                            <select [(ngModel)]="move.fk_user"
                                    id="moveUser"
                                    name="moveUser"
                                    style="width: 60px;vertical-align: bottom;"
                                    tabindex="3">
                                <option value="-1">Ziel Benutzer wählen</option>
                                <option *ngFor="let x of moveUserDropdownDatasource"
                                        [value]="x.user_id">{{x.user_name}}</option>
                            </select>
                            <select [(ngModel)]="move.type"
                                    id="moveType"
                                    name="moveType"
                                    style="width: 60px;vertical-align: bottom;"
                                    tabindex="3">
                                <option value="1">Eingegangen</option>
                                <option value="2">Gesendet</option>
                            </select>
                            <button (click)="moveMailsToUser()" style="min-width:50px !important;"><img
                                    src="../design/icons/user_go.png"> Move</button>
                        </span>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall"
                                   style="margin:0px; padding: 0px; margin-left:2px;" type="checkbox">
                        </th>
                        <th *ngIf="tableSettings.tid.state" style="width:60px;color:#FFFF00">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="TID" sortColumn="tid"></span>
                        </th>
                        <th *ngIf="tableSettings.prio.state && (list ==3 || list == 9)" style="width:30px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Prio" sortColumn="priority_group"></span>
                        </th>
                        <th *ngIf="tableSettings.prio.state && list ==6 " style="width:30px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Prio" sortColumn="priority_user"></span>
                        </th>
                        <th *ngIf="tableSettings.title.state">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Titel" sortColumn="titel"></span>
                            <span style="float:right;display: inline-block;margin-right:43px;">
                                <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort" style="float: right;"
                                      app-sort-switch desc="1" name="Zuletzt geändert" sortColumn="last_changed"></span>
                                <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort" style="float: right;margin-right:5px; "
                                      app-sort-switch desc="1" name="Eingegangen" sortColumn="send_time"></span>
                            </span>
                        </th>
                        <th *ngIf="tableSettings.group.state" style="width:91px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Gruppe" sortColumn="process_group_name"></span>
                        </th>
                        <th *ngIf="tableSettings.team.state" style="width:94px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Team/Projekt" sortColumn="fk_team_id"></span>
                        </th>
                        <th *ngIf="tableSettings.assigned_user.state" style="width:93px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Zuständiger" sortColumn="assigned_user"></span>
                        </th>
                        <th *ngIf="tableSettings.state.state" style="width:143px;">
                            <span (onSortChanged)="searchSortChange(0)" [(model)]="searchParameter.sort"
                                  app-sort-switch name="Kategorie/Status"
                                  sortColumn="category_name, state_name"></span>
                        </th>
                        <th style="width:6px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div *ngIf="data.length < 1 && showLoading" class="loading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 35%; width: 50px;">
                </div>
                <span *ngIf="listViewMode==1">
                    <div *ngIf="!viewEditor" class="form">
                    <span id="startOfProcessList"></span>
                    <table cellpadding="0" cellspacing="0" class="data_tabel noContext" style="width: calc( 100% - 2px);"
                           cdkDropList [cdkDropListData]="data" (cdkDropListDropped)="onDrop($event)">

                        <tr *ngFor="let x of data"
                            [contextMenuValue]="x"
                            [contextMenu]="basicMenu"
                            [ngClass]="{'selected' : currentSelected.header.id == x.id || currentSelected.header.tid == x.tid}"
                            id="typeList" id="scrollTo{{x.tid}}"
                            style="background: white;"
                            cdkDrag cdkDragLockAxis="y" [cdkDragData]="x"
                            [cdkDragDisabled]="searchParameter.sort!='priority_user' && searchParameter.sort!='priority_user DESC' && searchParameter.sort!='priority_group' && searchParameter.sort!='priority_group DESC'">
                            <div class="cdk-drop-custom-placeholder" *cdkDragPlaceholder></div>
                            <td (click)="checkboxSwap($event,x);" style="width:24px;">
                                <input (change)="handleCheckboxChanged(0)"
                                       (click)="checkboxSwap($event,x);"
                                       [(ngModel)]="x.checked"
                                       id="actionCheckbox{{x.id}}"
                                       type="checkbox">
                            </td>

                            <td (click)="view($event,x)"
                                *ngIf="tableSettings.tid.state" style="width:70px; text-align: center;">{{ x.tid }}</td>

                            <td (click)="view($event,x)" *ngIf="tableSettings.prio.state && list==6" style="width:40px; text-align: right;">
                                <div style="margin-right: 4px;">{{ x.priority_user }}</div>
                            </td>

                            <td (click)="view($event,x)" *ngIf="tableSettings.prio.state && (list==3 || list==9)" style="width:40px; text-align: right;">
                                <div style="margin-right: 4px;">{{ x.priority_group }}</div>
                            </td>

                            <td (click)="view($event,x)"
                                *ngIf="tableSettings.title.state" style="border-right:none;">
                                <table border="0" cellpadding="0" cellspacing="0"
                                       style="
                                       width: calc(100% - 12px);
                                       border: unset;
                                       min-height: 45px;">
                                    <tr>
                                       <td [ngClass]="{
                                            'newMarker' : x.new == 1 && list==6 ,
                                            'escalationMarker' : x.escalation > 0 && list==6 ,
                                            'unassignedMarker' : x.assigned == 0 && list==3 ,
                                            'sameTidMarker' : currentSelected.data[0].tid == x.tid && list==1,
                                            'linkMarker' : x.main == 0 && list==6
                                            }" rowspan="2" style="width:2px !important; min-width: 2px!important; border:unset; ">
                                            <span *ngIf="x.new == 1 && list==6"      style=" display:inline-block;width:100%; height:100%;"
                                                  tooltip="Ungelesener Vorgang"></span>
                                            <span *ngIf="x.assigned == 0 && list==3" style=" display:inline-block;width:100%; height:100%;"
                                                  tooltip="Nicht zugewiesener Vorgang"></span>
                                            <span *ngIf="x.escalation > 0 && list==6" style=" display:inline-block;width:100%; height:100%;"
                                                  tooltip="Eskalierter Vorgang"></span>
                                        </td>
                                        <td rowspan="2" style="width:32px; border:none;">
                                            <img *ngIf="x.mdi!='0'" src="../design/icons/page_white_e.png" style="margin-left:6px;margin-top:-2px;margin-right:-2px;vertical-align: bottom; width:22px !important;">
                                            <img *ngIf="x.icon && x.mdi=='0'" src="{{x.icon}}" style="margin-left:6px;margin-top:-2px;margin-right:-2px;vertical-align: bottom; width:22px !important;">
                                        </td>
                                        <td colspan="2" style="border: none; vertical-align: bottom;">
                                            <span style="display: inline-block; margin-left: 5px; vertical-align: middle; width: 100%;">
                                                <B>
                                                    <span *ngIf="!x.draft == true && x.mdi > 0" style="color:red;">Entwurf</span>
                                                    <span *ngIf="x.mdi == 0" style="color: black;display: inline-block;">{{x.type_name}}</span>
                                                    <span style="color: #3862ae;"> {{ x.sender_user}}</span>
                                                </B>
                                                    <span [ngClass]="{'bold':searchParameter.sort == 'last_changed DESC' || searchParameter.sort == 'last_changed' }" style="color: #3862ae;font-size:0.9em; float: right; margin-left:10px;"> {{ x.last_changed}} </span>
                                                    <span [ngClass]="{'bold':searchParameter.sort == 'send_time DESC' || searchParameter.sort == 'send_time' }" style="color: #3862ae;font-size:0.9em; float: right;"> {{ x.send_time}} </span>
                                                 <br>
                                                <div *ngIf="(searchParameter.type == 99 || searchParameter.type == 28 || searchParameter.type == 1) && searchFinished"
                                                     style="font-size:10px;">
                                                    <span *ngIf="x.typ=='28' && x.from_address!=''"> <B>VON:</B> {{x.from_address}}</span>
                                                    <span *ngIf="x.typ=='1' && ( x.to_addresses != '' )"> <B>AN:</B> {{x.to_addresses}}</span>sss
                                                    <span *ngIf="x.typ=='1' && x.cc_address!=''"> <B>CC:</B> {{x.cc_address}}</span>
                                                    <span *ngIf="x.typ=='1' && x.to_bcc_address!=''"> <B>BCC:</B> {{x.to_bcc_address}}</span>
                                                </div>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td [options]="{'show-delay': '500' ,  placement: 'bottom', 'tooltip-class': 'new-tooltip-class' }"
                                            style="border: none;font-size: 15px; position: relative;max-width: 0; "
                                            tooltip="{{ x.titel }}">
                                            <span *ngIf="x.titleEdit!=1"
                                                  style="font-size:13px;display: inline-block; margin-left:5px; width:100%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;  ">

                                                <img *ngIf="x.assigned!=settings.data.userId && list == 6"
                                                     src="../design/icons/group_go.png"
                                                     style="margin-left:8px;margin-right:-2px;width: 20px; vertical-align: sub;"
                                                     tooltip="Vertretung für {{ x.assigned_user }}">
                                                <img *ngIf="x.hide_time!='0'" src="../design/icons/eye_inactive.png"
                                                     style="margin-right:-2px;width: 20px; vertical-align: sub;margin-left:6px; width:15px;"
                                                     tooltip="Verborgen bis zum {{x.hide_time}}">
                                                <img *ngIf="x.marked!=null && list==6"
                                                     src="../design/icons/accept.png"
                                                     style="vertical-align: bottom; margin-right: -2px;"
                                                     tooltip="Zur Bearbeitung markiert">
                                                <span *ngIf="x.mdi < 1 && x.draft > 0 && x.main==1 && list == 6"
                                                      style="color:orange;"> Entwurf: </span>
                                                {{ x.titel }}<span *ngIf="!x.titel">(Kein Titel)</span>
                                            </span>

                                            <span *ngIf="x.titleEdit==1" class="form">
                                                <input (blur)="saveProcessTitle(x)" (keydown.enter)="saveProcessTitle(x)" [(ngModel)]="x.titel"
                                                       id="titelEdit{{x.id}}"
                                                       style="margin-left:5px; width:calc( 100% - 55px );"
                                                       type="text">
                                                <button (click)="saveProcessTitle(x)"
                                                        style="border-top: 1px solid lightgray !important;position: absolute; width: 32px; min-width: 32px;">
                                                    <img src="../design/icons/disk.png">
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td style="width:45px; border-left:none; vertical-align: top;">
                                <span style="float:right; display: inline-block;margin-top:3px;">
                                        <img *ngIf="x.date_count == 1" src="../design/icons/calendar.png"
                                             style="width:16px;vertical-align: baseline;"
                                             tooltip="Es existiert 1 Termin.">
                                        <img *ngIf="x.date_count > 1" src="../design/icons/calendar.png"
                                             style="width:16px;vertical-align: baseline;"
                                             tooltip="Es existieren {{x.date_count}} Termine.">
                                    <span (onClick)="viewFullscreenFromUiElement($event)" *ngIf="x.attachment_id > 0" [process]="x"
                                          app-process-attachment-icon></span>
                                    <img (click)="fastClose(x.tid)"
                                         *ngIf="x.second_process < 1 && x.creator == '0'" src="../design/icons/cross_black.png">
                                </span>
                            </td>

                            <td (click)="view($event,x)" *ngIf="tableSettings.group.state" style="width:100px;">
                                <div style=" margin-left: 4px;">{{ x.process_group_name }}</div>
                            </td>

                            <td (click)="view($event,x)" *ngIf="tableSettings.team.state" style="width:100px; font-size: 0.9em;">
                                <div style=" margin-left: 4px;">
                                    <span *ngIf="x.project_name"><b>Projekt:</b><BR> {{ x.project_name }}</span>
                                    <span *ngIf="!x.project_name">{{ x.team_name }}</span>
                                </div>
                            </td>

                            <td (click)="view($event,x)"
                                *ngIf="tableSettings.assigned_user.state" style="width:100px;font-size: 0.9em;"><div style=" margin-left: 4px;">{{ x.assigned_user }}</div></td>

                            <td (click)="view($event,x)"
                                *ngIf="tableSettings.state.state" style="width:150px;">
                                <table cellpadding="0" cellspacing="0" style="border:none; width:100%; font-size: 0.9em; ">
                                    <tr>
                                        <td colspan="2" style="border:none !important;">
                                            <div style="margin-left: 4px;">
                                            <b>{{ x.category_name }}</b><BR>
                                            {{ x.state_name }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style="height:4px;">
                                        <td style="border: 1px solid #515151 !important;background-color: #00a000 !important; height: 4px; width:{{ x.progress }}%;"></td>
                                        <td style="border: 1px solid #8c8c8c !important;background-color: white !important; height:4px; width:{{ 100 - x.progress }}%;"></td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <button (click)="search(1)" *ngIf="data.length > 0 && data.length<searchResultCount"
                            style="margin:20px auto; margin-left: 42%; display: block;">
                        <img *ngIf="showLoading" src="../design/icons/roller.gif" style="height:16px;">
                        Weitere Ergebnisse Anzeigen
                    </button>
                    <div *ngIf="searchStarted && data.length < 1 && !showLoading" class="dialog shadow"
                         style="width:320px;margin: 100px auto; ">
                        <div class="dialogHeader">
                            <div>
                                <img src="../design/icons/exclamation.png"
                                     style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
                            </div>
                        </div>
                        <div class="dialogContent form">
                            Es wurden keine Vorgänge gefunden. <span *ngIf="searchParameter.active != '-1'">Wollen Sie auch im Archiv Suchen?</span>
                        </div>
                        <div class="dialogFooter form" style="text-align: right">
                            <button (click)="searchInArchive()" *ngIf="searchParameter.active != '-1'"
                                    style="float: right;margin-right: 3px;margin-top: 3px;">
                                <img src="../design/icons/magnifier.png"> Archiv Suche </button>
                        </div>
                    </div>
                    <span id="endOfProcessList"></span>
                </div>
               </span>
                <span *ngIf="listViewMode==2"
                      style="overflow: hidden; display: block; height: 100%; vertical-align: top;">

                    <div (click)="showListWithFilter($event,b)" *ngFor="let b of boardDefinition"
                         style="overflow: hidden;width: calc( 25% - 2px); height: 24px; text-align: center; vertical-align: bottom; border-right: 1px solid lightgrey; border-bottom: 1px solid lightgrey; display: inline-block; background-color: #ececec; line-height: 1.7em; ">
                    {{b.name}}
                    </div>

                    <div *ngFor="let b of boardDefinition"
                         style="overflow: auto;width: calc( 25% - 2px);height:calc( 100% - 26px); vertical-align: top; border-right: 1px solid lightgrey; display: inline-block; ">
                        <table cellpadding="0" cellspacing="0" class="data_tabel" style="width:100%;">
                            <tr (click)="view($event,x)"
                                *ngFor="let x of data | searchfilter: b.filter"
                                [ngClass]="{'selected' : currentSelected.header.id == x.id}"
                                [options]="{'show-delay': '500' ,  placement: 'bottom'}" tooltip="{{ x.titel }}">
                                <td style="border: none !important;">
                                    <table border="0" cellpadding="2" cellspacing="0" style="border: unset; margin-left:6px; margin-top: 4px; width: calc( 100% - 12px );" class="card">
                                        <tr>
                                            <td colspan="2" style="border:unset;position: relative;max-width: 0;">
                                            <span *ngIf="x.titleEdit!=1" style="display: inline-block; width:100%;">{{x.titel}}</span>
                                            <span *ngIf="x.titleEdit==1" class="form">
                                                <input (blur)="saveProcessTitle(x)" (keydown.enter)="saveProcessTitle(x)" [(ngModel)]="x.titel"
                                                       id="titelEdit{{x.id}}"
                                                       style="margin-left:5px; width:calc( 100% - 55px );"
                                                       type="text">
                                                <button (click)="saveProcessTitle(x)"
                                                        style="border-top: 1px solid lightgray !important;position: absolute; width: 32px; min-width: 32px;">
                                                    <img src="../design/icons/disk.png">
                                                </button>
                                            </span>
                                           </td></tr>
                                        <tr><td colspan="2" style="border:unset;font-size: 9px;padding-bottom: 0;"><B>Zuständig: </B>{{ x.assigned_user }}</td></tr>
                                        <tr><td colspan="2" style="border:unset;font-size: 9px;padding-top: 0;"><B>Status: </B>{{ x.state_name }}</td></tr>
                                        <tr><td colspan="2" style="border:unset; padding: 0; height: 2px;">
                                            <table border="0" cellpadding="0" cellspacing="0" style="width:100%;border:unset;">
                                                <tr style="height:1px;">
                                                    <td style="height: 2px; background-color: green !important; width:{{ x.progress }}%;"></td>
                                                    <td style="background-color:white;border: 1px solid lightgrey; height:2px; width:{{ 99 - x.progress }}%; "></td>
                                                </tr>
                                            </table>
                                        </td></tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                </span>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div class="header1Row footerNone" id="rightContainerDefinition">
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">

                    <div *ngIf="currentSelected.header.tid>0 && currentSelected.header.mainMdi == 0 && currentSelected.processRight == false" class="form actionBar">
                    <button (click)="checkProcessActionsConditions('escalate', '')" tooltip="Vorgang eskalieren">
                        <img src="../design/icons/exclamation.png"> Eskalieren
                    </button>
                    <button (click)="checkProcessActionsConditions('comment','')" tooltip="Kommentar schreiben">
                        <img src="../design/icons/page_edit.png">
                    </button>
                    </div>

                    <div *ngIf="currentSelected.header.tid>0 && currentSelected.header.mainMdi == 0 && currentSelected.processRight == true" class="form actionBar">
                        <button (click)="handleProcessAction('reply-last',  currentSelected.data[0])"
                                *ngIf="currentSelected.data[processMainIndex].contact_username != null && currentSelected.data[processMainIndex].contact_username != ''"
                                tooltip="{{currentSelected.data[processMainIndex].contact_username}}<br>eine E-Mail schreiben"><img
                                src="../design/icons/email_reply.png"></button>
                        <button (click)="checkProcessActionsConditions('email','')"
                                tooltip="Neue E-Mail <br>aus Vorgang"><img
                                src="../design/icons/email_add.png"></button>
                        <button *ngIf="!settings.dev_mode" (click)="checkProcessActionsConditions('calendar','')" tooltip="Termin erstellen"><img
                                src="../design/icons/calendar.png"></button>

                        <button *ngIf="settings.dev_mode" (click)="createCalendarEntryFromProcess()" tooltip="Termin erstellen"><img
                                src="../design/icons/calendar.png"></button>

                        <button (click)="checkProcessActionsConditions('call','')" tooltip="Anruf tätigen"><img
                                src="../design/icons/call.png"></button>
                        <button (click)="checkProcessActionsConditions('time','')" tooltip="Zeit erfassen"><img
                                src="../design/icons/time.png"></button>
                        <button (click)="checkProcessActionsConditions('comment','')" tooltip="Kommentar schreiben"><img
                                src="../design/icons/page_edit.png"></button>
                        <button (click)="checkProcessActionsConditions('changeMainData','')"
                                tooltip="Zuständigen und/oder Team/Projekt ändern"><img
                                src="../design/icons/user_go.png"></button>
                        <!--<button tooltip="Für Besprechung markieren" (click)="checkProcessActionsConditions('meeting','')"><img src="../design/icons/comments_add.png"></button>-->
                        <button (click)="showProcessInFullscreen()" tooltip="Vollbild öffnen"><img
                                src="../design/icons/arrow_out.png"></button>
                        <button (click)="addNewSubTasks()" tooltip="Teilaufgabe hinzufügen"><img
                                src="../design/icons/brick_add.png"></button>
                        <button (click)="toggleShowMoreProcessActions()" style=" width:60px;"><img
                                src="../design/icons/arrow_downBig.png"> Mehr
                        </button>
                        <div (click)="closeShowMoreProcessActions()" *ngIf="showMoreProcessActions"
                             style="z-index: 9;position: fixed;top:0; left:0;bottom:0;right:0;">

                        </div>
                        <div *ngIf="showMoreProcessActions" class="moreTab shadow">
                            <div class="form">
                                <button (click)="checkProcessActionsConditions('bill','')">
                                    <img src="../design/icons/money.png"> Abrechnen
                                </button>
                                <br>
                                <button (click)="checkProcessActionsConditions('extern_work','')">
                                    <img src="../design/icons/car.png"> Außentermin erstellen
                                </button>
                                <br>
                                <button (click)="checkProcessActionsConditions('intern_work','')">
                                    <img src="../design/icons/house.png"> Arbeitsauftrag erstellen
                                </button>
                                <br>
                                <button (click)="checkProcessActionsConditions('escalate', '')"
                                        tooltip="Vorgang eskalieren">
                                    <img src="../design/icons/exclamation.png"> Eskalieren
                                </button>
                                <BR>
                                <button (click)="checkProcessActionsConditions('document','')">
                                    <img src="../design/icons/page_white_get.png"> Dokument anhängen
                                </button>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="currentSelected.header.tid>0 && currentSelected.header.mainMdi == 0" class="form actionBar" style="float: right; margin-top: -22px;">

                        <button (click)="clearHiddenState()" *ngIf="currentSelected.header.hidden>0"
                                tooltip="Verborgen aufheben."><img
                                src="../design/icons/eye.png"></button>
                        <button (click)="toggleShowProcessOverview()" style="float: right;"><img
                                src="../design/icons/report.png"></button>
                        <div *ngIf="showProcessOverview" class="overviewTab shadow">
                            <table cellpadding="4" cellspacing="0" class="data_tabel" style="width:100%;">
                                <ng-container *ngFor="let x of currentSelected.data">
                                    <tr *ngIf="showInfosInProgress|| x.information === '0'" (click)="showProcessEntry(x)">
                                        <td style="width: 20px;"><img src="{{x.icon}}" style="margin-top: 2px;"></td>
                                        <td style="width: 120px;">{{x.send_time_string}}</td>
                                        <td style="overflow: hidden;max-width: 0;white-space: nowrap;text-overflow: ellipsis;">{{x.titel}}</td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                        <button (click)="toggleShowInfosInProgress()" *ngIf="showInfosInProgress"
                                style="float: right;" tooltip="Informationen Verbergen"><img
                                src="../design/icons/information.png"></button>
                        <button (click)="toggleShowInfosInProgress()" *ngIf="!showInfosInProgress"
                                style="float: right;" tooltip="Informationen anzeigen"><img
                                src="../design/icons/information_off.png"></button>

                        <button (click)="toggleProgressSort()" *ngIf="processSortReverse === '1'"
                                style="float: right;" tooltip="Sortierung ändern"><img
                                src="../design/icons/sort_double_arrow_up.png"
                                style="width:24px; height:24px;margin-top: -4px;margin-left: -3px;"></button>
                        <button (click)="toggleProgressSort()" *ngIf="processSortReverse === '0'"
                                style="float: right;" tooltip="Sortierung ändern"><img
                                src="../design/icons/sort_double_arrow_down.png"
                                style="width:24px; height:24px;margin-top: -4px;margin-left: -3px;"></button>
                    </div>
                </div>
            </div>

            <div id="rightContainerContent">
                <span *ngIf="currentSelected.header!==undefined && currentSelected.header.tid > 0"
                      [ngClass]="{'processShowFullscreen' : showFullscreen === 1}">

                    <table style="width:100%; height:100%; border-collapse: collapse; border:none; margin: 0; padding: 0;">
                        <tr>
                            <td style="height:60px; background-color: #d6d6d6; border-bottom: 1px solid #717171;">

                                <table style="width:100%;">
                                    <tr>
                                        <td style="height:28px; vertical-align: bottom;">
                                            <b style="height:20px; margin-left: 10px; margin-right: 75px; display: inline-block;">Betrifft:</b>
                                        </td>
                                        <td colspan="3">
                                            <span *ngIf="currentSelected.header.contact  == 0"
                                                  style=" margin-bottom:-4px;margin-left: 4px; display: inline-block; width:100%;">
                                                Keinem Kontakt zugewiesen.
                                                <button (click)="contactEditActivate()"
                                                        *ngIf="currentSelected.processRight == true"
                                                        style="float: right; margin-right: 14px; height:22px;"
                                                        tooltip="Dem Vorgang einen Kontakt Zuweisen">
                                                    <img src="../design/icons/user_red.png">
                                                </button>
                                            </span>
                                            <span *ngIf="currentSelected.header.contact != 0"
                                                  style="display: inline-block;margin-left:4px; width:100%;">
                                                <app-contact-manager [(contact)]="currentSelected.contact"
                                                                     id="processSelectedContact"
                                                                     mode="contact"
                                                                     settabindex="20"
                                                                     style="width: calc( 100% - 50px );"
                                                >
                                                </app-contact-manager>
                                                <button (click)="message.open('deleteProcessContact')"
                                                        *ngIf="currentSelected.processRight && currentSelected.header.contact > 0 && currentSelected.contact == currentSelected.header.contact"
                                                        style="float: right; margin-right: 14px; height:22px;"
                                                        tooltip="Kontakt vom Vorgang Entfernen">
                                                    <img src="../design/icons/delete.png">
                                                </button>
                                                 <button (click)="message.open('changeProcessContact');"
                                                         *ngIf="currentSelected.processRight && currentSelected.contact > 0 && currentSelected.contact != currentSelected.header.contact"
                                                         style="float: right; margin-right: 14px; height:22px;"
                                                         tooltip="Änderung am Kontakt Speichern">
                                                    <img src="../design/icons/disk.png">
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 150px;"><b
                                                style="margin-left: 10px;">TID:</b> {{currentSelected.header.tid}}</td>
                                        <td>
                                            <table [hidden]="viewEditor" class="form" style="width:100%;">
                                                <tr>
                                                    <td style="width: 25%;">
                                                        <select (change)="projectChanged()"
                                                                [(ngModel)]="formData.project"
                                                                [disabled]="null" [hidden]="formData.project=='0'"
                                                                style="width:98% !important;">
                                                            <option *ngIf="formData.project=='-1'"
                                                                    value="-1">Projekt wählen</option>
                                                            <option *ngFor="let x of dropdownDataProject"
                                                                    value="{{x.id}}">{{x.name}}</option>
                                                            <option disabled>───────────────</option>
                                                            <option value="-2">Aus Projekt entfernen</option>
                                                        </select>
                                                        <select (change)="teamChanged()"
                                                                [(ngModel)]="formData.fk_team_id"
                                                                [disabled]="currentSelected.header.project!=0 || (currentSelected.header.assigned=='0' && currentSelected.processRightRole>3) ? null : true" [hidden]="formData.project!='0' "
                                                                style="width:98% !important;">
                                                            <option *ngIf="formData.fk_team_id=='-1'"
                                                                    value="-1">Team wählen</option>
                                                            <option *ngFor="let x of teams" value="{{x.id}}">
                                                                {{x.name}}
                                                            </option>
                                                            <option disabled>───────────────</option>
                                                            <option value="-2">In Projekt verschieben</option>
                                                        </select>
                                                    </td>
                                                    <td style="width: 25%;">
                                                        <select [(ngModel)]="formData.assigned" [disabled]="currentSelected.header.project!=0 || (currentSelected.header.assigned=='0' && currentSelected.processRightRole>3) ? null : true" style="width:98% !important;">
                                                            <option value="0">Kein Zuständiger</option>
                                                            <option *ngFor="let x of teamusers" value="{{x.user_id}}">{{x.user_name}}</option>
                                                        </select>
                                                    </td>
                                                    <td style="width: 25%;">
                                                        <select (change)="categoryChanged()" [(ngModel)]="formData.kategorie" [disabled]="currentSelected.processRight || (currentSelected.header.assigned=='0' && currentSelected.processRightRole!=1) ? null : true" style="width:98% !important;">
                                                            <option *ngFor="let x of categorys" value="{{x.id}}">{{x.name}}</option>
                                                        </select>
                                                    </td>
                                                    <td style="width: 25%;">
                                                        <select [(ngModel)]="formData.state_fk_id" [disabled]="currentSelected.processRight || (currentSelected.header.assigned=='0' && currentSelected.processRightRole!=1) ? null : true" style="width:98% !important;"><option
                                                                *ngFor="let x of states" value="{{x.id}}"><span
                                                                *ngIf="x.required_to_close==1">* </span>{{x.state_name}}</option></select>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <button *ngIf="currentSelected.processRight == true || currentSelected.header.assigned == 0 " (click)="quickSaveTeamCategory()" [hidden]="viewEditor" style="min-width: 24px; height: 24px;">
                                                <img src="../design/icons/disk.png" style="vertical-align: bottom;">
                                            </button>
                                        </td>
                                        <td style="width:80px; text-align: right;">
                                            <span style="margin-right:10px;">
                                                <button (click)="showMoreTime = !showMoreTime"
                                                        *ngIf="currentSelected.worktimelength > 1" style="height: 24px;">Arbeitszeiten</button>
                                                <button (click)="showMoreTime = !showMoreTime"
                                                        *ngIf="currentSelected.worktimelength == 1" style="height: 24px;">
                                                     <span *ngFor="let worktimeEntry of currentSelected.worktime;">
                                                        {{worktimeEntry.value}} {{worktimeEntry.unit}}
                                                    </span>
                                                </button>
                                                <div *ngIf="showMoreTime" class="shadow"
                                                     style="position: fixed;top: 105px;right: 15px; z-index: 999999999;background-color: white;border: 1px solid darkgray !important;">
                                                    <div (click)="showMoreTime = !showMoreTime" style="margin:10px;">
                                                        <table style="width:100%;">
                                                            <tr *ngFor="let worktimeEntry of currentSelected.worktime;">
                                                                <td><b>{{worktimeEntry.type}} </b></td><td> {{worktimeEntry.value}} {{worktimeEntry.unit}}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                        <td *ngIf="showFullscreen==1" style="width:150px; text-align: right;">
                                           <button (click)="closeProcessInFullscreen()"
                                                   style="margin-left:10px; height:26px !important; ">
                                               <img src="../design/icons/cross.png" style="vertical-align: bottom;">
                                               <span style="vertical-align: bottom;"> Schließen [ESC]</span>
                                           </button>
                                        </td>
                                    </tr>
                                </table>

                            </td>
                        </tr>

                        <tr *ngIf="currentSelected.subTasks!=undefined && currentSelected.subTasks.length > 0">
                            <td style="height: 1%; padding: 0; border-bottom: 1px solid #5c5c5c;">
                                <div style="height:24px; width: 100%;">
                                    <table cellpadding="2" cellspacing="0" class="data_tabel light" style="width: 100%; height:24px;">
                                        <tr>
                                            <th colspan="3">
                                                Teilaufgaben
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div style="max-height:240px; overflow: auto">
                                    <table cellpadding="2" cellspacing="0" class="data_tabel light" style="width: 100%;">
                                        <tr *ngFor="let t of currentSelected.subTasks">
                                            <td (click)="toggleSubTaskState(t);" style="width:22px;height:26px;">
                                                <img *ngIf="t.active=='0'"
                                                     src="../design/icons/accept_inactive.png">
                                                <img *ngIf="t.active=='1'" src="../design/icons/accept.png">
                                            </td>
                                            <td (click)="editSubTask(t);">
                                                <div *ngIf="t.edit!='1'">
                                                    <span>{{t.create_timestamp_string}}
                                                        - {{t.create_username}}</span><br>
                                                    <span style="font-weight: bold;">{{t.name}}</span>
                                                </div>
                                                <span *ngIf="t.edit=='1'" class="form">
                                                    <input [(ngModel)]="t.name" (blur)="saveSubTask(null, t)" (keydown)="saveSubTask( $event, t)"
                                                           id="subTaskEdit{{t.id}}" style="width:calc( 100% - 8px );"
                                                           type="text">
                                                </span>
                                            </td>
                                            <td *ngIf="currentSelected.processRight" class="form" style="width:20px;">
                                                 <button (click)="saveSubTask(null, t)" *ngIf="t.edit=='1'"
                                                         style="width: 30px; min-width: 30px;">
                                                    <img src="../design/icons/disk.png">
                                                 </button>
                                                 <button (click)="deleteSubTask(t)" *ngIf="t.edit!='1'"
                                                         style="width: 30px; min-width: 30px;">
                                                        <img src="../design/icons/delete.png">
                                                 </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 0;">

				                    <div class="processScrollBox"
                                         id="fullscreenProcessScrollBox"
                                         tabindex="-1">
                                        <div class="form" style="margin-top: 20px; margin-bottom: 20px;">
                                            <span *ngFor="let x of currentSelected.data">
                                                <div *ngIf="showInfosInProgress || x.information === '0'"
                                                     class="card form"
                                                     id="processEntry{{x.id}}"
                                                     style="scroll-margin: 15px;">

                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                           style="width: 100%; border: unset;">
                                                        <tr>
                                                            <td [ngClass]="{'newMarker' : x.new == 1 && list==6 , 'linkMarker' : quickMark == x.id && list==6 }"
                                                                style="width:4px; border: none;"></td>
                                                            <td style="border: none;" class="form">

                                                                    <button (click)="viewPrintView(x)"
                                                                            *ngIf="x.typ!=8 && x.typ!=26 && x.typ!=11 && x.typ!=31 && x.typ!=6 && x.typ!=19" style="border-top: 1px solid lightgray !important;position: absolute; right:10px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Drucken"><img
                                                                            src="../design/icons/printer.png"></button>
                                                            <span *ngIf="currentSelected.processRight == true" class="form" >
                                                                    <button (click)="openWorkingDocument( x.id, currentSelected.header.tid, x.typ,1)"
                                                                            *ngIf="x.typ==8 || x.typ==26"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:10px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Arbeitsbeleg Drucken"><img
                                                                            src="../design/icons/printer.png"></button>
                                                                    <button *ngIf="x.typ==8 || x.typ==26" (click)="openWorkingDocument( x.id, currentSelected.header.tid, x.typ,2)"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:46px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Ausfüllbarer Arbeitsbeleg Herunterladen"><img
                                                                            src="../design/icons/download.png"></button>
                                                                    <button (click)="openWithdrawWorkDocumentEditor(x)"
                                                                            *ngIf="x.typ==9 && x.time_charged == 0 && x.time_no_charge == 0|| x.typ==27 && x.time_charged == 0 && x.time_no_charge == 0"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:10px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Arbeitsbeleg Widerrufen"><img
                                                                            src="../design/icons/delete.png"></button>

                                                                    <button (click)="handleProcessAction('createNewTicketFromIncomingMail',x)"
                                                                            *ngIf="x.typ == 28"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:100px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Neues Ticket aus dieser E-Mail erstellen."><img
                                                                            src="../design/icons/page_copy.png"></button>

                                                                    <button (click)="handleProcessAction('forward',x)"
                                                                            *ngIf="x.typ==1 || x.typ==28"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:40px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Weiterleiten">
                                                                        <img src="../design/icons/email_out.png" style="height:16px;"></button>
                                                                    <button (click)="handleProcessAction('reply-item',x)"
                                                                            *ngIf="x.from_address != '' && ( x.typ==1 || x.typ==28 )"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:70px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="{{x.contact_username}} eine Antwort schreiben"><img
                                                                            src="../design/icons/email_reply.png"></button>
                                                                    <button (click)="checkProcessActionsConditions('mdiEdit',x)"
                                                                            *ngIf="x.mdi != '0' && ( x.main == '1' || x.typ ==  '2' || x.typ ==  '3' || x.typ ==  '4' || x.typ ==  '4' || x.typ ==  '29' || x.typ ==  '1')"
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:130px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Vorlage Bearbeiten"><img
                                                                            src="../design/icons/page_edit.png"></button>

                                                                    <button (click)="checkProcessActionsConditions('edit',x)"
                                                                            *ngIf="x.mdi == '0' && x.main == '1' "
                                                                            style="border-top: 1px solid lightgray !important;position: absolute; right:130px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"
                                                                            tooltip="Bearbeiten"><img
                                                                        src="../design/icons/page_edit.png"></button>
                                                                </span>
                                                                <div class="header">
                                                                    <span class="info" style="padding-top: 10px;">
                                                                        <img src="{{x.icon}}">
                                                                        <img *ngIf="x.active==0 && x.main==1"
                                                                             src="../design/icons/lock.png"
                                                                             style="vertical-align: sub;margin-left: 4px;"
                                                                             tooltip="Vorgang ist geschlossen.">
                                                                        <img *ngIf="x.typ==1 && x.mailOutgoingQueue.length > 0 && x.mailOutgoingQueue[0].send == 0"
                                                                             src="../design/icons/tick_inactive.png"
                                                                             style="margin-left: 4px;width:unset !important;height:unset !important;"
                                                                             tooltip="E-Mail wurde noch nicht versendet!">
                                                                        <img *ngIf="x.typ==1 && x.mailOutgoingQueue.length > 0 && x.mailOutgoingQueue[0].send == 2"
                                                                             src="../design/icons/delete.png"
                                                                             style="margin-left: 4px;width:unset !important;height:unset !important;"
                                                                             tooltip="Fehler beim versenden der E-Mail! Bitte kontaktieren Sie die Symbicom AG!">
                                                                        <img *ngIf="x.typ==1 && x.mailOutgoingQueue.length > 0 && x.mailOutgoingQueue[0].send == 1 && x.mailOutgoingQueue[0].mail_read_time == null"
                                                                             src="../design/icons/tick.png"
                                                                             style="margin-left: 4px;width:unset !important;height:unset !important;"
                                                                             tooltip="E-Mail versendet: <br>{{x.mailOutgoingQueue[0].mail_send_time}}">
                                                                        <img *ngIf="x.typ==1 && x.mailOutgoingQueue.length > 0 && x.mailOutgoingQueue[0].send == 1 &&  x.mailOutgoingQueue[0].mail_read_time != null"
                                                                             src="../design/icons/tickdouble.png"
                                                                             style="margin-left: 4px;width:unset !important;height:unset !important;"
                                                                             tooltip="E-Mail versendet: <br>{{x.mailOutgoingQueue[0].mail_send_time}} <br>und gelesen: <br>{{x.mailOutgoingQueue[0].mail_read_time}}">

                                                                        <span style="color:black; display: inline-block; margin-left:6px;">{{x.type_name}} - </span>
                                                                        {{x.send_time_string}} | <span (click)="openAddress(x.user)"
                                                                                                       style="cursor: pointer; ">{{x.contact_username}}</span>
                                                                    </span>
                                                                    <span *ngIf="x.main!='1' && x.time_duration != 0" class="title"
                                                                          style="float:left;"><B>{{x.time_name}}
                                                                        :</B> {{x.time_value }} {{x.time_unit}} -&nbsp;</span>
                                                                    <span *ngIf="x.from_address" style="font-size: 0.8em;"><B
                                                                            style="display: inline-block;width:32px;">VON:</B> {{x.from_address}}
                                                                        <br></span>
                                                                    <span *ngIf="x.typ!=29 && ( x.to_addresses )" style="font-size: 0.8em;">
                                                                        <B style="display: inline-block;width:32px;">AN:</B> {{x.to_addresses}}
                                                                    </span>
                                                                    <span *ngIf="x.cc_address" style="font-size: 0.8em;"><br><B
                                                                            style="display: inline-block;width:32px;">CC:</B> {{x.cc_address}}</span>
                                                                    <span *ngIf="x.to_bcc_address" style="font-size: 0.8em;"><br><B
                                                                            style="display: inline-block;width:32px;">BCC:</B> {{x.to_bcc_address}}</span>
                                                                    <span class="title">
                                                                        <span *ngIf="x.mdi > 0 && x.main==0 && list == 6"
                                                                              style="color:orange; display: inline-block;"> Entwurf: </span>
                                                                        <span *ngIf="x.mdi > 0 && x.main==1 && list == 6"
                                                                              style="color:red; display: inline-block;"> Entwurf: </span>
                                                                        <span *ngIf="x.titel!==''"> {{x.titel}}</span>
                                                                        <span *ngIf="x.titel==''"> (Kein Titel)</span>
                                                                    </span>

                                                                    <div *ngIf="x.create_username" style="font-size: 0.8em;margin-bottom: 3px;">Erstellt von: {{x.create_username}}</div>

                                                                    <span>
                                                                        <span>
                                                                            <div *ngFor="let a of x.attachemnts;"
                                                                                 style="display: inline-block; margin-right:6px;">
                                                                              <div *ngIf="a.inline==0" [contextMenuValue]="a"
                                                                                   [contextMenu]="attachmentMenu"
                                                                                   class="attachmentBox">
                                                                                    <div (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)" *ngIf="a.icon!=null"
                                                                                         class="attachmentBoxImage"><img
                                                                                            src="{{a.icon}}"></div>
                                                                                    <div (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)" *ngIf="a.icon==null"
                                                                                         class="attachmentBoxImage"><img
                                                                                            src="../design/icons/page_white.png"></div>
                                                                                    <div (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)"
                                                                                         *ngIf="a.ending=='pdf' || a.ending=='jpg' || a.ending=='png' || a.ending=='gif' || a.ending=='bmp' || a.ending=='wav' || a.ending=='mp3'"
                                                                                         class="attachmentBoxText">{{a.name}}
                                                                                        <br> {{a.size}} </div>
                                                                                    <div (click)="downloadFile(a)"
                                                                                         *ngIf="a.ending!='pdf' && a.ending!='jpg' && a.ending!='png' && a.ending!='gif' && a.ending!='bmp' && a.ending!='wav' && a.ending!='mp3'"
                                                                                         class="attachmentBoxText">{{a.name}}
                                                                                        <br> {{a.size}}</div>
                                                                              </div>
                                                                              <app-attachment-menu (callback)="favoriteSelected()" *ngIf="a.inline==0"
                                                                                                   [attachment]="a"
                                                                                                   [processData]="currentSelected">
                                                                              </app-attachment-menu>
                                                                            </div>
                                                                                <div *ngIf="checkForInlineAttachments(x.attachemnts)"
                                                                                     style="font-size: 12px;"
                                                                                     tooltip="Es gibt verborgene Inline Anhänge. Klicken um diese anzuzeigen!">
                                                                                    <div (click)="viewInline($event,x.attachemnts)"
                                                                                         style="cursor: pointer; ">
                                                                                        <img src="../design/icons/information.png"
                                                                                             style="vertical-align: text-bottom;"> Verborgene Anhänge. Hier klicken zum Einblenden.
                                                                                    </div>
                                                                                </div>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="x.typ==1 && x.mailOutgoingQueue.length > 0 && x.mailOutgoingQueue[0].send == 2"
                                                                     class="marked"
                                                                     style="margin: 20px; padding: 10px; border: 1px solid gray">
                                                                    <B>Es ist ein Fehler beim Versenden der E-Mail aufgetreten!</B><BR>
                                                                    {{x.mailOutgoingQueue[0].errorMessage}}
                                                                </div>
                                                                <div class="text">
                                                                    <span *ngIf="x.mail_uid>0 || x.typ == 1" [(model)]="x.text"
                                                                          app-resize-iframe></span>
                                                                    <span *ngIf="x.mail_uid==0 && x.typ != 1" [innerHTML]="x.text | safe"
                                                                          class="noMailText"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                            </td>
                        </tr>
                    </table>
                </span>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal class="jw-modal shadow" id="changeProcessContact">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Soll der Kontakt für den Vorgang gespeichert werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="processContactSave(false);" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
        <button (click)="processContactSave(true);" style="margin-top: 3px;margin-right: 3px;">Speichern</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="deleteProcessContact">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Soll der Kontakt für den Vorgang wirklich entfernt werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="processContactSave(false);" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
        <button (click)="processContactDelete();" style="margin-top: 3px;margin-right: 3px;">Entfernen</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="discardMDI">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Soll der Entwurf wirklich gelöscht werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('discardMDI');" style="margin-top: 3px;margin-right: 3px;">Zurück</button>
        <button (click)="exitEditor(true);" style="margin-top: 3px;margin-right: 3px;">Löschen</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="closeWithWorktimeWarning">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;">
            Arbeitszeiten nicht abrechenbar!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        {{closeWithWorktimeMessage}}
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('closeWithWorktimeWarning');" style="margin-top: 3px;margin-right: 3px;">
            Abbrechen
        </button>
        <button (click)="openWorktimeAcknowledged = true; handleProcessAction('closeWithWorktime', ''); message.close('closeWithWorktimeWarning'); "
                style="margin-top: 3px;margin-right: 3px;">Weiter
        </button>
    </div>
</jw-modal>


<jw-modal (click)="closeFullscreen()" class="jw-fullscreen shadow" id="fullscreenReading">
    <div style="height:25px; width:100%;">
        <div style="margin-top:3px; margin-left:4px; display: inline-block;">{{fullscreenFilename}}</div>
        <span style="float: right; display: inline-block; margin-right:4px; margin-top:3px;"><img (click)="closeFullscreen()" src="../design/icons/cross_black.png"></span>
    </div>
    <div [innerHTML]="iframeFullscreenHTML | safe" style="text-align:center;background-color:#212121; height:calc( 100% - 25px ); width:100%; border: 1px solid #575757;">
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="deleteMDI">
             <div class="modalHeader">
                 <div>
                     <img src="../design/icons/information_mark.png" style="vertical-align: text-bottom; margin-right:4px;"> Entwurf entfernen?
                 </div>
             </div>
             <div class="form" style="margin:20px; line-height: 1.4;">
                 Sind Sie sicher das die Entwurf Markierung bei den gewählten Vorgängen entfernt werden soll?<br>
             </div>
             <div class="modalFooter form" style="text-align: right">
                 <button (click)="stopDeleteMDI()"  style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
                 <button (click)="startDeleteMDI()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
            </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="massClose">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information_mark.png" style="vertical-align: text-bottom; margin-right:4px;"> Tickets schließen
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Sind Sie sicher das Sie die gewählten Vorgängen schließen möchten?<br>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('massClose');" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
        <button (click)="startMassAction()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="markSpam">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information_mark.png" style="vertical-align: text-bottom; margin-right:4px;"> Als Spam markieren
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Sind Sie sicher das Sie die gewählten Vorgängen als Spam markieren möchten?<br>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('markSpam');" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
        <button (click)="startSpamAction()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="unmarkSpam">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information_mark.png" style="vertical-align: text-bottom; margin-right:4px;"> Spam markierung entfernen
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Sind Sie sicher das Sie die gewählten Vorgängen aus dem Spam Ordner entfernen möchten?<br>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('unmarkSpam');" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
        <button (click)="startRemoveSpamAction()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
    </div>
</jw-modal>


<jw-modal class="jw-modal shadow" id="deleteAction">
        <span *ngIf="deleteActionState==1">
            <div class="modalHeader">
                <div>
                    <img src="../design/icons/information.png" style="vertical-align: text-bottom; margin-right:4px;"> Information
                </div>
            </div>
            <div class="form" style="margin:20px; line-height: 1.4;">
                Sie müssen mindestens einen Vorgang markieren um diese Aktion zu starten!<br>
            </div>
            <div class="modalFooter form" style="text-align: right">
                <button (click)="closeDeleteAction()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
            </div>
        </span>
        <span *ngIf="deleteActionState==2">
             <div class="modalHeader">
                 <div>
                     <img src="../design/icons/information_mark.png" style="vertical-align: text-bottom; margin-right:4px;"> Wirklich Löschen?
                 </div>
             </div>
             <div class="form" style="margin:20px; line-height: 1.4;">
                 Sind Sie sicher das die markierten Vorgänge unwiederruflich gelöscht werden sollen?<br>
             </div>
             <div class="modalFooter form" style="text-align: right">
                 <button (click)="closeDeleteAction()" style="margin-top: 3px;margin-right: 3px;">Abbrechen</button>
                 <button (click)="startDeleteAction()" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
            </div>
        </span>
</jw-modal>

<jw-modal class="jw-modal shadow" id="telephoneWithoutWorkingTime">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information.png" style="vertical-align: text-bottom; margin-right:4px;"> Information
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Soll die erfasste Arbeitszeit Abgerechnet werden?<br>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('telephoneWithoutWorkingTime');" style="margin-top: 3px;margin-right: 3px;"> Abbrechen</button>
        <button (click)="doSaveTelephone(false);" style="margin-top: 3px;margin-right: 3px;">Nein</button>
        <button (click)="doSaveTelephone(true);" style="margin-top: 3px;margin-right: 3px;">Ja</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="changeToForeignTeam">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information.png" style="vertical-align: text-bottom; margin-right:4px;">
            Information
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Sie haben als Ziel ein Team gewählt in dem Sie kein Mitglied sind.<br>
        Soll trotzdem gespeichert werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('changeToForeignTeam');" style="margin-top: 3px;margin-right: 3px;">Abbrechen
        </button>
        <button (click)="save(3);" style="margin-top: 3px;margin-right: 3px;">Weiter</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="assignedNotChanged">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/information.png" style="vertical-align: text-bottom; margin-right:4px;">
            Information
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
       Sie haben keinen Zuständigen gewählt! Wollen Sie den Eintrag wirklich speichern?<BR>
        Der Vorgang verbleibt dann ohne Bearbeiter im Team Eingang.
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('assignedNotChanged');" style="margin-top: 3px;margin-right: 3px;">Abbrechen </button>
        <button (click)="save(7); message.close('assignedNotChanged');" style="margin-top: 3px;margin-right: 3px;">Speichern</button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="workingTimeOnOwnOrNoContact">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;">
            Arbeitszeiten nicht abrechenbar!
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        {{cantBillWorktimeMessageString}}
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('workingTimeOnOwnOrNoContact');" style="margin-top: 3px;margin-right: 3px;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="editorWarning">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div [innerHTML]="editorWarningString | safe" class="form" style="margin:20px; line-height: 1.4;">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<context-menu #attachmentMenu>
    <ng-template (execute)="downloadFile($event.value)" contextMenuItem>
        <span style="color: black;">
           Anhang herunterladen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item [visible]="isItemEndingPDF" (execute)="sendToDMS( null, $event.value)" >
        <span style="color: black;">
           Anhang ins DMS verschieben
        </span>
    </ng-template>
</context-menu>

<context-menu #attachmentContactMenu>
    <ng-template (execute)="renameAttachment($event.value)" contextMenuItem>
        <span style="color: black;">
          Umbenennen
        </span>
    </ng-template>
</context-menu>

<context-menu #basicMenu>
    <ng-template let-item [visible]="isItemProcessNotMarked" (execute)="mark($event.value, 1)" contextMenuItem >
        <img src="../design/icons/accept.png"
             style="vertical-align: middle">&nbsp;&nbsp;Zur Bearbeitung markieren<span
            style="float:right;  margin-top: 0px; width:60px;"></span>
    </ng-template>
    <ng-template let-item [visible]="isItemProcessMarked" (execute)="mark($event.value, 0)" contextMenuItem>
        <img src="../design/icons/accept_inactive.png"
             style="vertical-align: middle">&nbsp;&nbsp;Bearbeitung beenden<span
            style="float:right;  margin-top: 0px; width:60px;"></span>
    </ng-template>

    <ng-template (execute)="renameProcess($event.value)" contextMenuItem>
        <img src="../design/icons/font.png" style="vertical-align: middle">&nbsp;&nbsp;Umbenennen<span
            style="float:right; margin-top: 0px; width:60px;">ALT + R</span>
    </ng-template>

    <ng-template [visible]="list==3 && !isItemAssigned" contextMenuItem let-item>
        <ng-template (execute)="startContextmenuAction($event.value,'assignToMe')">
            <img src="../design/icons/user_go.png" style="vertical-align: middle">&nbsp;&nbsp;Mir zuweisen <span
                style="float:right;margin-top: 0px; width:60px;">ALT + T</span>
        </ng-template>
    </ng-template>

    <ng-template (execute)="startSearchForContact($event.value)" contextMenuItem>
        <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Alle Vorgänge zu diesem
        Kontakt
    </ng-template>

    <ng-template [visible]="!isItemActive" contextMenuItem let-item>
        <ng-template (execute)="activate($event.value)">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </ng-template>
    </ng-template>

    <ng-template [visible]="isItemActive" contextMenuItem let-item>
        <ng-template (execute)="deactivate($event.value)" >
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </ng-template>
    </ng-template>
</context-menu>

