import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsService} from "../../services/settings.service";
import {ModalService} from "../../_modal";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-whistleblower',
  templateUrl: './whistleblower-report.component.html',
  styleUrls: ['./whistleblower-report.component.css']
})
export class WhistleblowerReportComponent implements OnInit {

  public rightContainerWidth;
  public leftContainerWidth;
  public reporttext;
  public editorWarningString;
  public text;

  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router, public route: ActivatedRoute) {

    this.setRightContainerWidth(40);
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  saveReport()
  {
    this.text = {reporttext: this.reporttext};

    this.http.put<{data, message, message2}>(
        this.settings.restBaseUrl + 'whistleblower' , JSON.stringify(this.text), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.editorWarningString = 'Die Meldung wurde Erfolgreich übermittelt. <br> Mit folgenden Daten können Sie sich Anmelden <br> um den Status Ihrer Meldung zu verfolgen. <br><br> Loginname: ' + data.message + '<br> Passwort: ' + data.message2 + '<br><br> Notieren Sie sich die Daten gut, <span style=\"color: red;\"> das ist die einzige Möglichkeit Ihre Meldung zu verfolgen. <\/span>';
          this.message.open('editorWarning');
        });

  }

  ngOnInit(): void {
  }

}