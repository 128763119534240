import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-erp-receipt-type',
  templateUrl: './erp-receipt-type.component.html',
  styleUrls: ['./erp-receipt-type.component.css']
})
export class ErpReceiptTypeComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataPrintTemplate: any[];
  public checkboxShiftStartElement: any;
  public httpOptions = { };
  public currentSelectedTab = 1;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', ek_vk : '', sort : '', fk_erp_print_template : '', fk_erp_number_range : '', fk_erp_number_range_gid : '', active : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', ek_vk : '0' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    this.http.post<any[]>(settings.restBaseUrl + 'erp/receipt/printTemplate', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataPrintTemplate = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'erp/receipt/type', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
        document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {

    if (this.currentSelected.name == null || this.currentSelected.name === '') {
        alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
        return;
    } else if (this.currentSelected.fk_erp_print_template == null || this.currentSelected.fk_erp_print_template === '') {
        alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Vorlage aus.');
        return;
    } else if (this.searchParameter.ek_vk === '-1' && this.currentSelected.ek_vk == null || this.searchParameter.ek_vk === '-1' &&  this.currentSelected.ek_vk === '') {
        alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie einen Belegtyp aus.');
        return;
    }

    if (this.searchParameter.ek_vk !== '' && this.searchParameter.ek_vk != null && this.searchParameter.ek_vk !== '-1') {
        this.currentSelected.ek_vk = this.searchParameter.ek_vk;
    }
    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'erp/receipt/type' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'erp/receipt/type/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          setTimeout(() => {
              document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'erp/receipt/type/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'erp/receipt/type/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'erp/receipt/type/sort/' + itemId + '/' + sortAction , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
