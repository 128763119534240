<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    System > Spamliste
                </span>
                <span class="form search">
                <select (change)="loadSpam()"
                        [(ngModel)]="mailDropdown"
                        id="searchUser"
                        style="width:150px; float:right; margin-right:8px;"
                        tabindex="4">
                    <option value="-1">Bitte Postfach wählen</option>
                    <option *ngFor="let x of dropdownDataMailbox" [value]="x.id">{{x.email}}</option>
                </select>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                           Betreff
                        </th><th>Absender</th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr style="width: 99%" *ngFor="let x of spamData"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList"
                        [contextMenuValue]="x"
                        [contextMenu]="basicMenu"
                    >

                        <td style="width: 49%;" (click)="edit($event,x)">{{ x.Subject }}</td>
                        <td style="width: 49%;" (click)="edit($event,x)">{{ x.From}}</td>

                    </tr>
                </table>
            </div>

            <div id="leftContainerBottom">
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">
                    <span *ngIf="currentSelected.id > 0">Benutzerliste zur Rechtegruppe</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div class="text">
                    <span class="noMailText" [innerHTML]="rightMailText"></span>
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

<context-menu #basicMenu>
    <ng-template (execute)="markSpam($event.value)" contextMenuItem>
        <img src="../design/icons/email.png"
             style="vertical-align: middle">&nbsp;&nbsp;Spam Markierung aufheben<span
            style="float:right;  margin-top: 0px;"></span>
    </ng-template></context-menu>