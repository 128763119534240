import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { UiModule } from '../ui/ui.module';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from '../_modal';
import { PipesModule } from '../pipes/pipes.module';
import { MeetingGroupComponent } from './meeting-group/meeting-group.component';
import { InventoryPlaceComponent } from './inventory-place/inventory-place.component';
import { ErpBookingTextTemplateComponent } from './erp-booking-text-template/erp-booking-text-template.component';
import { AdminRightComponent } from './admin-right/admin-right.component';
import {UnlockComponent} from "./unlock/unlock.component";
import {AdminSpamComponent} from "./admin-spam/admin-spam.component";


@NgModule({
  declarations: [ MeetingGroupComponent, InventoryPlaceComponent, ErpBookingTextTemplateComponent, AdminRightComponent, UnlockComponent, AdminSpamComponent],
  imports: [
    CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule, BrowserAnimationsModule, ModalModule, PipesModule,
    AngularSplitModule,
    ContextMenuModule
  ],
})
export class AdminModule { }
