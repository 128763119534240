import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import { ModalService } from 'src/app/_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

    public rightContainerWidth;
    public leftContainerWidth;
    public httpOptions = { };
    public editorWarningString = '';
    // Suchleiste
    public searchParameter;
    public searchParameterFilter;
    public dropdownDataYears = [];
    public dropdownDataUser;
    public dropdownDataCategory: any[];
    public expandedSearch = false;
    public expandedSearchPreviousPanelHeight = '';
    public readonly searchQuantity: number;
    public searchResultCount: number;
    // Liste
    public data = [];
    public tableSettings;
    public checkboxShiftStartElement: any;
    public checkboxData: any;
    public checkboxAllState: boolean;
    public checkboxActionDropdown = '-1';
    public checkboxActionDropdownData;
    public checkboxActionParameterDropdown = '-1';
    public checkboxActionParameterDropdownData;
    // New und Edit Formular
    public currentSelected;
    public showNewItemForm: boolean;
    public currentSelectedTab = 2; // Optional > entfernen, wenn keine Tabs im Editor Bereich genutzt wereden
    public formError = false;
    public dropDownCategoryData;
    public deleteRight = false;
    public login: any;
    capsActive: any;
    loginData = {'username':'','password':''};
    public messageContent: string;

   constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router) {
    this.login = { 'show' : false, item: null, edit:0};
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.searchResultCount = 0;
    this.searchQuantity = 200;
    this.checkboxAllState = false;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.initCurrentSelected();
    this.initSearchParameter();
    this.initDropdownDataYear();
    this.initTableSettings();
    this.http.post<any[]>(
        this.settings.restBaseUrl + 'crm/password/category', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropDownCategoryData = data;
        });
       this.http.post<any[]>(
           this.settings.restBaseUrl + 'crm/password/user', {}, this.httpOptions)
           .pipe(map(data => data)).subscribe(
           data => {
               this.dropdownDataUser = data;
           });
     this.http.get<{ message }>(
         this.settings.restBaseUrl + 'user/right/passwort/verwaltung', this.httpOptions)
         .pipe(map(data => data)).subscribe(
         data => {
           if(data.message=='1') {
             this.deleteRight = true;
           }
         });
  }

  ngOnInit() {
    this.searchPublic();
    document.getElementById('searchText').focus();
  }

  search(loadNextSearchResults: boolean) {
    if(this.searchParameter.public==2) {
        // Wenn suche und es ist gerade schon search public 2. Dann Seach public ausschalten.
       this.searchParameter.public = -1;
    }

    if (loadNextSearchResults) {
      this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else {
      this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0;
    }

    this.checkExtendedSearch();

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'crm/password', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data:{status, count, data}) => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element) {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch() {
    this.initSearchParameter();
    this.search(false);
  }

  new() {
    this.initCurrentSelected();
    this.currentSelected.edit = true;
    this.currentSelectedTab = 1;
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

    show(item: any,edit: boolean) {
           if(item.public === '1') {
               this.get(item,edit)
           } else {
               this.initCurrentSelected();
               this.showLogin(item,edit);
               setTimeout( () => {
                   document.getElementById('password').focus();
               }, 300);

           }
    }

    showLogin(item: any,edit: boolean) {
       this.loginData.username = this.settings.data.nick;
       this.loginData.password = '';
       this.login.show = true;
       this.login.item = item;
       this.login.edit = edit;
    }

    startLogin() {
        const getPasswordWithLoginEncodedData = {
            username: this.loginData.username,
            password: this.loginData.password,
            id: this.login.item.id
        };
        this.http.post<{status,message}>(
            this.settings.restBaseUrl + 'crm/password/login' , JSON.stringify(getPasswordWithLoginEncodedData), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if(data.status == 'error') {
                    if ( data.message === -10) {
                        this.messageContent = 'Sie müssen Benutzername und Passwort ausfüllen!';
                    } else if ( data.message === -6) {
                        this.messageContent = 'Das System befindet sich im Wartungsmodus. Bitte versuchen Sie es später nocheinmal!';
                    } else if (data.message === -4) {
                        this.messageContent = 'Sie haben zu oft versucht sich mit einem flaschem Passwort anzumelden. Der Login wurde für 20 Sekunden gesperrt.';
                    } else if (data.message === -3) {
                        this.messageContent = 'Ihr Konto ist deaktiviert. Bitte wenden Sie sich an Ihren Systembetreuer..';
                    } else {
                        this.messageContent = 'Der Benutzername oder das Passwort ist falsch.';
                        setTimeout( () => {
                            document.getElementById('password').focus();
                        }, 300);
                    }
                    this.message.open('messageBox');
                } else {
                    this.loginData.username = '';
                    this.loginData.password = '';
                    this.currentSelected = data;
                    this.currentSelected.edit = this.login.edit;
                    if (this.currentSelected.public === '1'){
                        this.currentSelected.public = true;
                    }else{
                        this.currentSelected.public = false;
                    }
                    this.showNewItemForm = false;
                    if( this.currentSelected.edit == true ) { document.getElementById('currentSelectedName').focus(); }
                    this.login = { 'show' : false, item: null, edit:0};
                }
            });

    }

  get(item: any,edit: boolean): void  {
           this.initCurrentSelected();
           this.http.get<any[]>(
               this.settings.restBaseUrl + 'crm/password/' + item.id, this.httpOptions)
               .pipe(map(data => data)).subscribe(
               data => {
                   this.login = { 'show' : false, item: null, edit:0};
                   this.currentSelected = data;
                   this.currentSelected.edit = edit;
                   if (this.currentSelected.public === '1'){
                       this.currentSelected.public = true;
                   }else{
                       this.currentSelected.public = false;
                   }
                   this.showNewItemForm = false;
                   if( this.currentSelected.edit == true ) { document.getElementById('currentSelectedName').focus(); }
               });
  }

  save() {
    if( this.currentSelected.name === '' ||
        this.currentSelected.fk_type ==='-1' ||
        this.currentSelected.fk_contact_company === '0' ||
        this.currentSelected.username === ''||
        this.currentSelected.password ==='' ) {
      this.formError = true;
      this.editorWarningString = 'Sie müssen alle Pflichtfelder ausfüllen! <br>Diese sind mit einem * markiert.';
      this.message.open('editorWarning');
      return;
    }
    if (this.currentSelected.public === true){
      this.currentSelected.public = 1;
    }else{
      this.currentSelected.public = 0;
    }
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'crm/password/',
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.currentSelectedTab = 2;
          this.formError = false;
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  delete(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(
          this.settings.restBaseUrl + 'crm/password/' + item.id , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }

  favoriteSelected() {
    setTimeout(() => {
      this.search(false);
    }, 100);
  }

  tableOptionChanged() {
    this.settings.set( this.constructor.name , JSON.stringify(this.tableSettings), 1);
  }

  private checkExtendedSearch() {
    // Wenn ein Parameter in der erweiterten Suche aktiv ist, dann diese auch aufklappen.
    if( this.searchParameter.year !=='' || this.searchParameter.user !== '')
    {
      if(this.expandedSearch === false) {
        this.toggleExpandedSearch();
      }
    }
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', fk_contact : 0,fk_contact_company : 0, comments : '', username : '', password : '', edit: false, 'fk_type':'-1' };
    this.formError = false;
  }

  initSearchParameter(): void {
    this.searchParameter = {
      company: '0', site: '0', category: '-1', fk_contact: '0', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'create_timestamp DESC', public : '-1',  active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : ''
    };
    this.searchParameterFilter = {
      company: '0', site: '0', fk_contact: '0', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : ''
    };
    // Fix to clear ContactManager
    setTimeout(() => {
      this.searchParameter.contact = '';
      setTimeout(() => {
        this.searchParameter.contact = 0;
      }, 200);
    }, 100);
  }

  initTableSettings(): void {
    const sessionSettings = this.settings.get(this.constructor.name);
    if (sessionSettings) {
      this.tableSettings = JSON.parse(sessionSettings);
    } else {
      this.tableSettings = {
        create_timestamp: {name: 'Erstellt am', state: false, locked: false},
        fk_type: {name: 'Kategorie', state: true, locked: false},
        name: {name: 'Name', state: true, locked: true},
        fk_contact_company: {name: 'Firma', state: true, locked: false},
        create_fk_user: {name: 'Ersteller', state: false, locked: false}
      };
    }
  }

  initDropdownDataYear() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }

  searchPublic() {
       if(this.searchParameter.public == -1) {
           this.initSearchParameter();
           this.searchParameter.public = 1;
           this.search(false);
           this.searchParameter.public = 2;
       }
  }


    checkForReturn($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            this.startLogin();
        }
    }

    closeMessageBox() {
        this.messageContent = '';
        this.message.close('messageBox');
    }
}
