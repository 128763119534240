import { HostListener, Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-process-category-and-status-management',
  templateUrl: './process-category-and-status-management.component.html',
  styleUrls: ['./process-category-and-status-management.component.css']
})
export class ProcessCategoryAndStatusManagementComponent implements OnInit {

    public searchParameterCategory;
    public searchParameterStatus;
    public readonly searchQuantity: number;
    public searchResultCount: number;
    public selectedCategory;
    public selectedStatus;
    public expandedSearch = false;
    public expandedSearchPreviousPanelHeight = '';
    public rightContainerWidth;
    public leftContainerWidth;
    public checkboxShiftStartElement: any;
    public checkboxAllState: boolean;
    public httpOptions = {};
    public categoryData = [];
    public statusData = [];
    public newItemFormSelect = false;
    public formType = '0';
    public categoryProject = [ { id: '', project_name: '', team_name: '', sort: '', base_category: '' } ];
    public categoryTeam = [ { id: '', project_name: '', team_name: '', sort: '', base_category: '' } ];
    public statusProgress;
    public searchActive = '1';
    public keyDownValue;
    public dropdownTeamData;
    public dropdownProjectTypeData;
    public teamProjectTypesFilterSwitch = '-1';
    public teamProjectTypesFilterValue = '-1';
    public teamProjectTypeSelectSwitch = 1;
    public teamProjectTypeNewSelect;
    public newCategorySelectArray;
    public newCategoryTeamData;
    public newCategoryProjectTypeData;

    @HostListener('keydown', ['$event'])
    onKeyDown($event: KeyboardEvent) {
        this.keyDownValue = $event.code;
        setTimeout(() => {
            this.keyDownValue = null;
        }, 500);
    }

    initSelectedCategory(): void {
        this.selectedCategory = {id: '', name: '', fk_groupid: '', active: ''};
        this.teamProjectTypeSelectSwitch = 1;
        this.newCategorySelectArray = this.dropdownTeamData;
    }

    initSelectedStatus(): void {
        this.selectedStatus = {
            id: '',
            name: '',
            progress: 0,
            sort: '',
            fk_category: 0,
            required_to_close: 0,
            send_customer_notification: false,
            autoclosed: false,
            start: false,
            active: ''
        };
    }

    initSearchParameterCategory(): void {
        this.searchParameterCategory = {
            loadFrom: 0,
            loadQuantity: this.searchQuantity,
            sort: 'name',
            active: '1',
            text: '',
            team: '-1',
            projectType: '-1'
        };
    }

    initSearchParameterStatus(): void {
        this.searchParameterStatus = {
            loadFrom: 0,
            loadQuantity: this.searchQuantity,
            sort: 'sort',
            active: '1',
            text: '',
            fk_category: '',
            contextSearch: false
        };
    }

    constructor(public http: HttpClient, public settings: SettingsService) {
        this.searchResultCount = 0;
        this.searchQuantity = 50;
        this.checkboxAllState = false;
        this.checkboxShiftStartElement = null;
        this.setRightContainerWidth(40);
        this.httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json', token: settings.sessionId})};

        this.initSearchParameterCategory();
        this.initSearchParameterStatus();
        this.initSelectedCategory();
        this.initSelectedStatus();

        this.http.post<{ teams, project_types }>(settings.restBaseUrl + 'process/category/belonging', { select: 'true' }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.dropdownTeamData = data.teams;
                this.newCategoryTeamData = data.teams;
                this.dropdownProjectTypeData = data.project_types;
                this.newCategoryProjectTypeData = data.project_types;
            }
        );
    }

    ngOnInit() {
        this.search(false);
        document.getElementById('searchText').focus();
    }


    search(loadNextSearchResults: boolean): void {
        let teamValue;
        let projectTypeValue;
        this.searchParameterStatus.contextSearch = false;
        // this.resetUsedCategoryTables();
        this.searchParameterCategory.active = this.searchActive;
        if (loadNextSearchResults) {
            this.searchParameterCategory.loadFrom = this.searchParameterCategory.loadFrom + this.searchQuantity;
        } else {
            this.searchResultCount = 0;
            this.categoryData = [];
            this.searchParameterCategory.loadFrom = 0;
        }
        if (this.teamProjectTypesFilterSwitch === 'team') {
            teamValue = this.teamProjectTypesFilterValue;
            projectTypeValue = '-1';
        } else if (this.teamProjectTypesFilterSwitch === 'projectType') {
            projectTypeValue = this.teamProjectTypesFilterValue;
            teamValue = '-1';
        } else if (this.teamProjectTypesFilterSwitch === '-1') {
            projectTypeValue = '-1';
            teamValue = '-1';
        }

        const searchParameterData = {
            loadFrom: 0,
            loadQuantity: this.searchQuantity,
            sort: this.searchParameterCategory.sort,
            active: this.searchParameterCategory.active,
            text: this.searchParameterCategory.text,
            team: teamValue,
            projectType: projectTypeValue,
            teamProjectTypesFilter: this.teamProjectTypesFilterSwitch
        };

        this.http.post<{ status, count, data }>(this.settings.restBaseUrl + 'process/category', searchParameterData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (this.searchResultCount === 0) {
                    this.searchResultCount = data.count;
                }
                this.categoryData = data.data;
                if (this.teamProjectTypesFilterSwitch !== '-1' && this.teamProjectTypesFilterValue === '-1') {
                    const result = this.categoryData.reduce((unique, o) => {
                        if (!unique.some(obj => obj.id === o.id && obj.name === o.name)) {
                            unique.push(o);
                        }
                        return unique;
                    }, []);
                    this.categoryData = result;
                }
                if (this.statusData.length > 0) {
                    this.searchStatus(false);
                }
            }
        );
    }

    searchStatus(loadNextSearchResults: boolean): void {
        // this.resetUsedCategoryTables();
        this.searchParameterStatus.active = this.searchActive;
        this.searchParameterStatus.fk_category = this.selectedCategory.id;
        if (this.searchParameterStatus.contextSearch) {
            this.searchParameterStatus.fk_category = '';
        }

        this.http.post<{ status, count, data }>(this.settings.restBaseUrl + 'process/category/state', JSON.stringify(this.searchParameterStatus), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (this.searchResultCount === 0) {
                    this.searchResultCount = data.count;
                }
                if (this.searchParameterStatus.contextSearch) {
                    this.categoryData = data.data;
                    this.statusData = [];
                    this.initSelectedCategory();
                    this.initSearchParameterStatus();
                } else {
                    this.statusData = data.data;
                }
            }
        );
    }

    startTextSearchFromContextmenu(element): void {
        this.searchParameterCategory.text = element.name;
        this.search(false);
    }

    startTextSearchFromStatusContextmenu(element): void {
        this.searchParameterCategory.text = 'Kategorien mit ' + element.state_name + ' (Status)';
        this.searchParameterStatus.contextSearch = true;
        this.searchParameterStatus.text = element.state_name;
        this.searchStatus(false);
    }

    resetSearch(): void {
        this.searchActive = '1';
        this.teamProjectTypesFilterSwitch = '-1';
        this.teamProjectTypesFilterValue = '-1';
        this.searchParameterStatus.contextSearch = false;
        this.initSearchParameterCategory();
        this.initSearchParameterStatus();
        this.initSelectedCategory();
        this.initSelectedStatus();
        this.search(false);
        this.searchStatus(false);
    }

    toggleExpandedSearch(): void {
        let newValue = '106px';
        this.expandedSearch = !this.expandedSearch;
        this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
        if (this.expandedSearchPreviousPanelHeight === '80px') {
            newValue = '106px';
        }
        if (this.expandedSearchPreviousPanelHeight === '106px') {
            newValue = '80px';
        }
        document.getElementById('leftContainerContent').style.top = newValue;
    }

    new(): void {
        this.newItemFormSelect = !this.newItemFormSelect;
    }

    saveCategory(): void {
        if (this.selectedCategory.name == null || this.selectedCategory.name === '') {
            alert('Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie einen Kategorienamen ein.');
            return;
        }
        const categoryData = {
            name: this.selectedCategory.name,
            groupId: this.selectedCategory.groupId,
            teamProjectTypeSwitch: this.teamProjectTypeSelectSwitch,
            teamProjectTypeValue: this.teamProjectTypeNewSelect
        };

        let endpointIdAdd = '';
        if (this.selectedCategory.id > 0) {
            endpointIdAdd = '/' + this.selectedCategory.id;
        }
        this.http.put<{status, message}>(
            this.settings.restBaseUrl + 'process/category' + endpointIdAdd,
            categoryData,
            this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                if (this.selectedCategory.id < 1) { this.selectedCategory.id = data.message; }
                this.search(false);
                this.editCategory(this.selectedCategory);
                this.initSelectedStatus();
                this.statusData = [];
                this.formType = '0';
            }
        );
    }

    saveStatus(): void {
        let startStates = 0;
        let closeStates = 0;
        this.statusData.forEach((item) => {
            if (item.id !== this.selectedStatus.id) {
                if (item.start === '1') { startStates++; }
                if (item.autoclosed === '1') { closeStates++; }
            }
        });
        if (startStates < 1 && this.selectedStatus.start === false) {
            alert('Die Kategorie muss einen Startstatus besitzen. Bestimmen Sie einen Startstatus bevor sie andere Änderungen vornehmen.');
        } else if (closeStates < 1 && this.selectedStatus.autoclosed === false) {
            alert('Die Kategorie muss einen Status zum schließen des Vorgangs besitzen. Bestimmen Sie einen Endstatus bevor sie andere Änderungen vornehmen.');
        } else if (this.selectedStatus.name == null || this.selectedStatus.name === '') {
            alert('Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie einen Statusnamen ein.');
            return;
        } else if (startStates > 0 && startStates < 2 && this.selectedStatus.start === true) {
            alert('Es sind mehr als ein Startstatus für diese Kategorie gewählt. Eine Kategorie darf nur einen Startstatus besitzen. Entfernen Sie alle überschüssigen Startstatusmarkierungen.');
        } else if (startStates > 1 && this.selectedStatus.start === true) {
            alert('Es sind bereits zwei Stati als Startstatus markiert. Sie müssen die Anzahl reduzieren um einen anderen Startstatus zuweisen zu können.');
            return;
        } else if (closeStates > 0 && closeStates < 2 && this.selectedStatus.autoclosed === true) {
            alert('Es sind mehr als ein Status zum schließen des Vorgangs für diese Kategorie gewählt. Eine Kategorie darf nur einen Status zum schließen des Vorgangs besitzen. Entfernen Sie alle überschüssigen Endstatusmarkierungen.');
        } else if (closeStates > 1 && this.selectedStatus.autoclosed === true) {
            alert('Es sind bereits zwei Stati als Status zum schließen des Vorgangs markiert. Sie müssen die Anzahl reduzieren um einen anderen Status zum schließen des Vorgangs zuweisen zu können.');
            return;
        }
        setTimeout(() => {
            const saveData = {
                name: this.selectedStatus.name,
                progress: this.selectedStatus.progress,
                fk_category: this.selectedCategory.id,
                required_to_close:  this.selectedStatus.required_to_close,
                send_customer_notification: this.selectedStatus.send_customer_notification,
                autoclosed: this.selectedStatus.autoclosed,
                start: this.selectedStatus.start
            };

            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/state/' + this.selectedStatus.id,
                saveData,
                this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data2 => {
                    // this.search(false);
                    // this.editCategory(this.selectedCategory);
                    this.searchStatus(false);
                    setTimeout(() => {
                        this.editStatus(null, {id: this.selectedStatus.id} );
                    }, 300);
                    // this.statusData = [];
                    this.formType = '0';
                }
            );
            }, 300);
    }

    editCategory(item: any): void {
        if (this.keyDownValue === 'ControlLeft' && this.selectedCategory.id > 0) {
            const mergeMessage = window.confirm('Sind Sie sicher, dass Sie "' + item.name + '" mit "' + this.selectedCategory.name + '" zusammenführen wollen ? Der Vorgang kann nicht rückgängig gemacht werden.');
            if (mergeMessage) { this.mergeCategories(item); }
        } else {
            this.keyDownValue = null;
            this.searchParameterStatus.contextSearch = false;
            this.searchParameterStatus.text = '';
            this.resetUsedCategoryTables();
            this.http.get<any[]>(
                this.settings.restBaseUrl + 'process/category/' + item.id,
                this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.selectedCategory = data;
                    this.formType = '0';
                    this.initSelectedStatus();
                    this.searchStatus(false);
                    this.getCategoryBelongingList();
                    if (this.selectedStatus.id > 1) {
                        this.initSelectedStatus();
                    }
                    setTimeout(() => {
                        document.getElementById('selectedCategoryName').focus();
                    }, 300);
                });
        }
    }

    editStatus($event: MouseEvent, item: any): void {
        if (this.keyDownValue === 'ControlLeft' && this.selectedStatus.id > 0) {
            const mergeMessage = window.confirm('Sind Sie sicher, dass Sie "' + item.state_name + '" mit "' + this.selectedStatus.name + '" zusammenführen wollen ? Der Vorgang kann nicht rückgängig gemacht werden.');
            if (mergeMessage) { this.mergeStates(item); }
        } else {
            this.http.get<any[]>(
                this.settings.restBaseUrl + 'process/category/state/' + item.id,
                this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.selectedStatus = data;
                    this.formType = '0';
                    this.statusProgress = this.selectedStatus.progress;
                    if (this.selectedStatus.start === '1') {
                        this.selectedStatus.start = true;
                    } else {
                        this.selectedStatus.start = false;
                    }
                    if (this.selectedStatus.send_customer_notification === '1') {
                        this.selectedStatus.send_customer_notification = true;
                    } else {
                        this.selectedStatus.send_customer_notification = false;
                    }
                    if (this.selectedStatus.autoclosed === '1') {
                        this.selectedStatus.autoclosed = true;
                    } else {
                        this.selectedStatus.autoclosed = false;
                    }
                    if (this.selectedStatus.required_to_close === '1') {
                        this.selectedStatus.required_to_close = true;
                    } else {
                        this.selectedStatus.required_to_close = false;
                    }
                    setTimeout(() => {
                        document.getElementById('selectedStatusName').focus();
                    }, 300);
                });
        }
    }

    getCategoryBelongingList() {
        this.http.post<[{teams, projects}]>(this.settings.restBaseUrl + 'process/category/belonging/' + this.selectedCategory.id, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                // @ts-ignore
                this.categoryTeam = data.teams;
                // @ts-ignore
                this.categoryProject = data.project_types;
            }
        );
    }

    deactivateCategory(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/active/' + item.id + '/0', {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    setTimeout(() => {
                        this.search(false);
                    }, 300);
                    this.initSelectedCategory();
                });
        }
    }

    activateCategory(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/active/' + item.id + '/1', {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    setTimeout(() => {
                        this.search(false);
                    }, 300);
                    this.initSelectedCategory();
                });
        }
    }

    deactivateState(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/state/active/' + item.id + '/0', {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.initSelectedStatus();
                    setTimeout(() => {
                        this.searchStatus(false);
                        this.initSelectedStatus();
                    }, 300);
                });
        }
    }

    activateState(item): void {
        const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
        if (deleteItems) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/state/active/' + item.id + '/1', {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    setTimeout(() => {
                        this.searchStatus(false);
                    }, 300);
                    this.initSelectedStatus();
                });
        }
    }

    stateChangeSort(itemId, sortAction): void {
        this.http.put<any[]>(
            this.settings.restBaseUrl + 'process/category/state/sort/' + itemId + '/' + sortAction + '/' + this.selectedCategory.id, {}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.initSelectedStatus();
                setTimeout(() => {
                    this.searchStatus(false);
                }, 300);
            });
    }

    selectFormType(item) {
        this.formType = item;
        this.newItemFormSelect = false;
        if (item === 'state') {
            if (this.selectedCategory.id < 1) {
                alert('Ohne gewählte Kategorie kann kein neuer Status angelegt werden.\nWählen Sie eine Kategorie aus um einen Status anlegen zu können.');
                this.formType = '0';
                return;
            }
            this.initSelectedStatus();
            this.statusProgress = '';
            setTimeout(() => {
                document.getElementById('selectedStatusName').focus();
            }, 300);
        } else {
            this.initSelectedStatus();
            this.initSelectedCategory();
            setTimeout(() => {
                document.getElementById('selectedCategoryName').focus();
                this.teamProjectTypeNewSelect = this.dropdownTeamData[0].id;
            }, 300);
        }
    }

    setRightContainerWidth(width): void {
        this.rightContainerWidth = width;
        this.leftContainerWidth = 100 - this.rightContainerWidth;
    }

    searchOnReturnPressed(event): void {
        if (event.which === 13) {
            this.search(false);
        }
    }

    resetUsedCategoryTables() {
        this.categoryProject = [];
        this.categoryTeam = [];
    }

    checkProgress() {
        if (this.selectedStatus.progress > 100) {
            this.selectedStatus.progress = 100;
        }
    }

    checkProgressValue(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        // tslint:disable-next-line:triple-equals
        if (charCode > 31 && (charCode < 48 || charCode > 57 ) && (charCode < 96 || charCode > 105) || charCode === 16 || event.shiftKey == 1) {
            return false;
        } else {
            return true;
            this.statusProgress = this.selectedStatus.progress;
        }
    }

    mergeCategories(item) {
        if (this.selectedCategory != null && this.selectedCategory.id !== item.id) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/merge/' + this.selectedCategory.id + '/' + item.id, {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.search(false);
                    this.getCategoryBelongingList();
                    this.keyDownValue = null;
                });
        }
    }

    mergeStates(item) {
        if (this.selectedStatus != null && this.selectedStatus.id !== item.id) {
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'process/category/state/merge/' + this.selectedStatus.id + '/' + item.id, {}, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.search(false);
                    this.editCategory(this.selectedCategory);
                    this.keyDownValue = null;
                });
        }
    }

    resetTeamProjectTypeValue() {
        this.teamProjectTypesFilterValue = '-1';
        this.search(false);
    }
    resetNewTeamProjectTypeValue() {
        switch (this.teamProjectTypeSelectSwitch) {
            case 1:
                setTimeout(() => {
                    this.teamProjectTypeSelectSwitch = this.newCategoryTeamData[0].id;
                }, 200);
                break;
            case 2:
                setTimeout(() => {
                    this.teamProjectTypeSelectSwitch = this.newCategoryProjectTypeData[0].id;
                }, 200);
                break;
        }
    }
}
