<div *ngIf="active" id="tutorialOverlay" style="position: fixed; left:0; right:0; bottom:0; top:0;">
    <div id="tutorialOverlayTop"
         [style.height]="posY + 'px'"
         style="position: absolute; left:0; right:0; height:{{posY}}px; top:0; background: rgba(0, 0, 0, 0.5);"
    >
    </div>
    <div id="tutorialOverlayLeft"
         [style.width]="posX + 'px'"
         [style.height]="height + 'px'"
         [style.top]="posY + 'px'"
         style="position: absolute; left:0; background: rgba(0, 0, 0, 0.5);"
    ></div>

    <div [style.left]="xPosLeftBox + 'px'"
         [style.top]="yPosTutorialBox + 'px'"
         class="tutorialContentBox ">
        <div *ngIf="direction=='left'" class="leftArrow">⯇</div>
        <div class="tutorialContent shadow">
            <div style="margin:10px; overflow: auto;">{{currentTutorialContent}}<BR>
                <button *ngIf="activeHint<(tutorialContent.length-1)" style="margin-top:10px;float: right;" (click)="nextHint()">Weiter</button>
                <button style="margin-top:10px; margin-right:10px;float: right;" (click)="closeTutorial()">Beenden</button>

            </div>
        </div>
    </div>

    <div id="tutorialOverlayRight"
         [style.left]="xPosLeftBox + 'px'"
         [style.height]="height + 'px'"
         [style.top]="posY + 'px'"
         style="position: absolute; right:0; background: rgba(0, 0, 0, 0.5);"
    ></div>
    <div id="tutorialOverlayBottom"
         [style.top]="yPosBottomBox + 'px'"
         style="position: absolute; left:0; right:0; bottom:0; background: rgba(0, 0, 0, 0.5);"
    ></div>

</div>
