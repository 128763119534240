import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.css']
})
export class TimeTrackingComponent implements OnInit {

  public data = [];                                        // enthält Daten, die in der Liste dargestellt werden
  public searchParameter;                                  // enthält bei Suche mitgesendete Parameter
  public readonly searchQuantity: number;                  // Menge an Datensätzen welche bei bei Suche zurückgegeben werden sollen
  public searchResultCount: number;                        // Menge an Datensätzen die bei Suche zurückgegeben wurden
  public currentSelected;                                  // enthält Daten des momentan gewählten Eintrags
  public showNewItemForm: boolean;                         // Variable zum ein- und ausblenden des Neu-Formulars
  public expandedSearch = false;                           // Variable zum ein- und ausblenden der erweiterten Suchparameter
  public expandedSearchPreviousPanelHeight = '';           // Höhe des linken Container-Headers in Pixel
  public rightContainerWidth;                              // Breite des rechten Containers in Prozent
  public leftContainerWidth;                               // Breite des linken Containers in Prozent
  public dropdownDataYears = [];                           // enthält die Jahreszahlen für das Suchparameter-dropdown
  public dropdownDataUser;                                 // enthält Daten der Benutzer für das Suchparameter-dropdown
  public dropdownDataType: any[];                          // enthält Daten der Status für Suchparameter-dropdown
  public httpOptions = { };                                // enthält http-Optionen
  public messageboxTitle: string;                          // enthält Titel für Modals
  public messageboxContent: string;                        // enthält den Textinhalt für Modals
  public itemToDelete;                                     // enthält die ID des zu löschenden Eintrages

  // Initialisierung von currentSelected
  initCurrentSelected(): void {
    this.currentSelected = { id : '', fk_core_user: '-1', text: '', name : '', timeString : '', dateString : '', state : '', fk_time_tracking_state: '-1', fk_time_tracking_device: 0, fk_time_tracking_uid: 0 };
  }

  // Initialisierung von searchParameter
  initSearchParameter(): void {
    this.searchParameter = { company: '', site: '', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'timestamp DESC', text: '', year : '', user : '-1', dateFrom : '', dateTo : '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService, public router: Router, public message: ModalService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<{status,message,data}>(settings.restBaseUrl + 'user/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data.data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'timetracking/type', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataType = data;
        }
    );
  }


  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'timetracking', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedUser').focus();
    }, 300);
  }

  save(): void {
    if (this.currentSelected.fk_core_user === '-1' || this.currentSelected.fk_core_user == null) {
      this.messageboxTitle = 'Kein Benutzer gewählt !';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte wählen Sie einen Benutzer aus.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.timeString === '' || this.currentSelected.timeString == null) {
      this.messageboxTitle = 'Zeitwert fehlt !';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie einen Zeitwert ein.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.dateString === '' || this.currentSelected.dateString == null) {
      this.messageboxTitle = 'Datum fehlt !';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte geben Sie ein Datum an.';
      this.message.open('messageBox');
      return;
    } else if (this.currentSelected.fk_time_tracking_state === '-1' || this.currentSelected.fk_time_tracking_state == null) {
      this.messageboxTitle = 'Kein Status gewählt !';
      this.messageboxContent = 'Alle Pflichtfelder müssen ausgefüllt sein. Bitte wählen Sie einen Status aus.';
      this.message.open('messageBox');
      return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<any[]>(this.settings.restBaseUrl + 'timetracking' + endpointIdAdd, JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data2 => {
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'timetracking/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          setTimeout(() => {
            document.getElementById('currentSelectedTimeString').focus();
          }, 300);
        });
  }

  closeMessageBox() {
    this.message.close('messageBox');
  }

  openDeleteMessageBox(item) {
    this.itemToDelete = item;
    this.message.open('deleteMessageBox');
  }

  closeDeleteMessageBox() {
    this.itemToDelete = null;
    this.message.close('deleteMessageBox');
  }

  delete(item): void  {
      this.http.delete<any[]>(this.settings.restBaseUrl + 'timetracking/' + item.id , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.initCurrentSelected();
            this.showNewItemForm = false;
            this.itemToDelete = null;
            this.message.close('deleteMessageBox');
            setTimeout(() => {
              this.search(false);
            }, 300);
          });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }
}
