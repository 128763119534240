import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-core-base-data-management',
  templateUrl: './core-base-data-management.component.html',
  styleUrls: ['./core-base-data-management.component.css']
})
export class CoreBaseDataManagementComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public addToSiteUserId = '-1';
  public currentSiteUsers = [];
  public companyId: string;
  public selectableUsers = [];

  initCurrentSelected(): void {
    this.currentSelected = { id : '', type: '', site_name : '', site_short_name : '', postcode: '', city: '', street: '', street_number: '', fk_company_id: 1, email_signature: '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'site_name', active: '1' };
  }

  constructor(public http: HttpClient,
              public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }

  ngOnInit() {
    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = data;

          this.search(false);
        }
    );
    document.getElementById('searchText').focus();

    setTimeout(() => {
      if (this.data.length === 1) {
        this.edit(this.data[0]);
      }
    }, 300);

  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/company/sites/' + this.companyId, JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.site_name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.currentSelectedTab = 1;
    this.showNewItemForm = true;
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.data.length > 0) { this.currentSelected.type = 11; } else { this.currentSelected.type = 10; }
    if (this.currentSelected.site_name == null || this.currentSelected.site_name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    } else if (this.currentSelected.site_short_name == null || this.currentSelected.site_short_name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie ein Kürzel ein.');
      return;
    }  else if (this.currentSelected.email_signature == null || this.currentSelected.email_signature === '') {
        alert('Sie haben einen Standort ohne E-Mail Signatur\n' +
            'angelegt/gespeichert. Dies ist möglich, bedenken Sie\n' +
            'aber das Sie als Firma verpflichtet sind an jede E-Mail\n' +
            'gewisse Informationen anzuhängen');
    }

    let signatureDataId = this.currentSelected.id;
    const companySiteData = {
      type: this.currentSelected.type,
      site_name: this.currentSelected.site_name,
      site_short_name: this.currentSelected.site_short_name,
      postcode: this.currentSelected.postcode,
      city: this.currentSelected.city,
      street: this.currentSelected.street,
      street_number: this.currentSelected.street_number,
      fk_contact_id: 0,
      fk_company_id: this.companyId
    };

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/company/sites' + endpointIdAdd, companySiteData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.currentSelected.id < 1 ) { signatureDataId = data.message; }
          if (this.currentSelected.email_signature.length > 0) {
              const signatureData = {
                  id: signatureDataId,
                  email_signature: this.currentSelected.email_signature,
              };
              this.http.put<any[]>(this.settings.restBaseUrl + 'contact/site/data' + endpointIdAdd, signatureData,
                  this.httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  data2 => {
                      this.search(false);
                      this.initCurrentSelected();
                      this.showNewItemForm = false;
                      this.settings.initSettings();
                  }
              );
          } else {
              this.http.delete<any[]>(this.settings.restBaseUrl + 'contact/site/data/' + this.currentSelected.id,
                  this.httpOptions)
                  .pipe(map(data3 => data3)).subscribe(
                  data3 => {
                      this.search(false);
                      this.initCurrentSelected();
                      this.showNewItemForm = false;
                      this.settings.initSettings();
                  }
              );
          }

        }
    );
  }

  edit(item: any): void  {
    this.addToSiteUserId = '-1';
    this.getSelectableUsers();
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'contact/company/sites/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.http.get<{id, email_signature}>(
              this.settings.restBaseUrl + 'contact/site/data/' + item.id,
              this.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
                if (data2) {
                  this.currentSelected.email_signature = data2.email_signature;
                }
                this.showNewItemForm = false;
                this.getSiteUsers();
                if ( this.currentSelectedTab === 1) {
                  setTimeout(() => {
                    document.getElementById('currentSelectedName').focus();
                    if (this.currentSelected.email_signature == null) { this.currentSelected.email_signature = ''; }
                  }, 100);
                } else {
                  document.getElementById('addToSiteUserId').focus();
                }
              });
        });
  }

  getSiteUsers() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/sites/user/' + this.currentSelected.id , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSiteUsers = data;
        }
    );
  }

  getSelectableUsers() {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/company/user' , {company: this.companyId}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.selectableUsers = data.data;
        }
    );
  }

  checkIfUserAlreadyAssigned() {
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'contact/company/sites/user/checkAssigned/' + this.addToSiteUserId + '/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (data.message === true) {
            const assigned = window.confirm('Der Benutzer ist bereits einem anderen Standort zugewiesen.\n' +
                'Sind Sie sicher,dass sie den Benutzer diesem Standort zuweisen möchten?\nDie Zuweisung zum anderen Standort wird dadurch gelöscht.');
            if (assigned) {
              this.addUserSiteLink();
            } else { return; }
          } else {
            this.addUserSiteLink();
          }
        }
    );
  }

  addUserSiteLink() {
    let duplicate = false;
    this.currentSiteUsers.forEach((item) => {
      if (item.fk_contact_id === this.addToSiteUserId) {
        duplicate = true;
      }
    });
    if (duplicate) {
      alert('Der User ist bereits diesem Standort zugewiesen.');
      return;
    } else {
      this.http.put<any[]>(this.settings.restBaseUrl + 'contact/company/address' , {contactId: this.addToSiteUserId, siteId: this.currentSelected.id}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getSiteUsers();
          }
      );
    }
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'contact/company/sites/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'contact/company/sites/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
