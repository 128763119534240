<DIV style="display: inline-block; vertical-align: middle; margin-bottom: 1px; margin-right: -1px;" id="{{overlayId}}Panel">

    <table style="width:130px;" cellpadding="0" cellspacing="0">
        <tr>
            <td>
                <input type="text"
                       id="{{overlayId}}dateTextBox"
                       name="{{overlayId}}dateTextBox"
                       style="width:100%; height:20px;
                       font-size: 12px; line-height: 21px; vertical-align: bottom;
                       padding-left: 4px;
                       -webkit-border-top-left-radius: 3px;
                       -webkit-border-bottom-left-radius: 3px;
                       -moz-border-radius-topleft: 3px;
                       -moz-border-radius-bottomleft: 3px;
                       border-top-left-radius: 3px;
                       border-bottom-left-radius: 3px;
                       z-index: 10;"
                       placeholder="{{placeholder}}"
                       (blur)="checkDateFields();"
                       (keydown)="closeRangeSelector()"
                       (contextmenu)="onRightClick($event)"
                       [(ngModel)]="modelValue"
                       [ngClass]="{ 'error' : markOnSet &&  modelValue !== '' ||  mark }"
                       [tabindex]="settabindex"
                       autocomplete="off">
            </td>
            <td style="width:14px;">
                <span class="form">
                <button (click)="toggleOverlay()" style="margin-top: 0px; margin-left:-2px; background-color: #eeeeee !important; width:25px; height:25px; min-width: 25px !important; z-index: 20;">
                    <img src="../design/icons/calendar.png" style="width:16px; height: 16px; margin-left:-1px;margin-top: 0px; " data-context-menu="datePickerContextMenu">
                </button>
                </span>
            </td>
        </tr>
    </table>

    <div class="datePickerRangeSelector shadow" [hidden]="!showRangeSelector" id="datePickerRangeSelector{{overlayId}}">
        <table class="data_tabel" style="width:100%; margin: 0;" cellpadding="4" cellspacing="0">
            <tr class="light">
                <th style="text-align: left;" colspan="5">
                    <B style="margin-top: 4px; display: inline-block;">Bereich setzen</B><button style="float:right;width: 20px;height: 20px;" (click)="closeRangeSelector()"><img src="../design/icons/cross_black.png" style="margin-left:-4px; width:13px;"></button>
                </th>
            </tr>
            <tr>
                <td (click)="setDateRange('Heute',0)" colspan="5">
                    Heute
                </td>
            </tr>
            <tr>
                <td (click)="setDateRange('Gestern',0)" colspan="5">
                    Gestern
                </td>
            </tr>
            <tr>
                <td (click)="setDateRange('Aktueller Monat',0)" colspan="5">
                    Aktueller Monat
                </td>
            </tr>
            <tr>
                <td (click)="setDateRange('Vorheriger Monat',0)" colspan="5">
                    Vorheriger Monat
                </td>
            </tr>
            <ng-container *ngFor="let year of contextMenuAYears">
            <tr>
                <td (click)="setDateRange(year,-1)" style="width:40%;text-align: center;">
                    {{year}}
                </td>
                <td (click)="setDateRange(year,1)" style="width:15%;text-align: center;">
                    Q1
                </td>
                <td (click)="setDateRange(year,2)" style="width:15%;text-align: center;">
                    Q2
                </td>
                <td (click)="setDateRange(year,3)" style="width:15%;text-align: center;">
                    Q3
                </td>
                <td (click)="setDateRange(year,4)" style="width:15%;text-align: center;">
                    Q4
                </td>
            </tr>
            </ng-container>

            <tr>
                <td (click)="setDateRange('Zurücksetzen',0)" colspan="5">
                    Zurücksetzen
                </td>
            </tr>
        </table>
    </div>


    <div id="{{overlayId}}" class="shadow" style="overflow: hidden;width:0px; height:0px; visibility:hidden; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">

        <table style="width: 100%;height: 100%;" class="datePicker" *ngIf="displayMode==0">
            <tr>
                <td colspan="7" style="text-align: center;">
                    <div style="float: left;margin-left: 4px; width:25px;height:16px;display: inline-block;" (click)="changeMonth(-1)">&#11164;</div>
                    <div style="display: inline-block;height:16px;" (click)="changeMode(1)"><b><span *ngIf="currentMonth<10">0</span>{{currentMonth}}.{{currentYear}}</b></div>
                    <div style="float: right; margin-right: 4px; width:25px;height:16px;display: inline-block;" (click)="changeMonth(1)">&#11166;</div>
                </td>
            </tr>
            <tr>
                <th>Mo</th>
                <th>Di</th>
                <th>Mi</th>
                <th>Do</th>
                <th>Fr</th>
                <th>Sa</th>
                <th>So</th>
            </tr>

            <tr *ngFor="let rows of calenderDays">
                <td *ngFor="let x of rows ;let i = index"
                    [attr.data-index]="i"
                    [ngClass]="{
                    'lastNextMonth' : !x.currentMonth && i < 5,
                    'lastNextMonthWe' : !x.currentMonth && i > 4,
                    'we' : x.currentMonth && i > 4,
                    'currentSelected' : selectedDay == x.day && selectedMonth == x.month,
                    'today' : todayDate.dayString == x.day && todayDate.monthString == x.month}"
                    (click)="setDate(x)">{{x.day}}</td>
            </tr>

            <tr>
                <th colspan="7" (click)="setDate(todayDate)"><span>Heute: {{todayDate.dayString}}.{{todayDate.monthString}}.{{todayDate.year}}</span></th>
            </tr>
        </table>


        <table style="width: 100%;height: 100%;" class="datePicker" *ngIf="displayMode==1">
            <tr>
                <th colspan="3" style="height:25px;text-align: center;"><div style="display: inline-block;height:16px;" (click)="changeMode(2)">{{currentYear}}</div></th>
            </tr>
            <tr>
                <td [ngClass]="{'currentSelected' : currentMonth == 1}" (click)="changeMonthTo(1)">Januar</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 2}" (click)="changeMonthTo(2)">Februar</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 3}" (click)="changeMonthTo(3)">März</td>
            </tr>
            <tr>
                <td [ngClass]="{'currentSelected' : currentMonth == 4}" (click)="changeMonthTo(4)">April</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 5}" (click)="changeMonthTo(5)">Mai</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 6}" (click)="changeMonthTo(6)">Juni</td>

            </tr>
            <tr>
                <td [ngClass]="{'currentSelected' : currentMonth == 7}" (click)="changeMonthTo(7)">Juli</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 8}" (click)="changeMonthTo(8)">August</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 9}" (click)="changeMonthTo(9)">September</td>

            </tr>
            <tr>
                <td [ngClass]="{'currentSelected' : currentMonth == 10}" (click)="changeMonthTo(10)">Oktober</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 11}" (click)="changeMonthTo(11)">November</td>
                <td [ngClass]="{'currentSelected' : currentMonth == 12}" (click)="changeMonthTo(12)">Dezember</td>
            </tr>
            <tr>
                <th colspan="3" (click)="changeMode(0)" style="height:25px;"><span>Zurück</span></th>
            </tr>
        </table>

        <table style="width: 100%;height: 100%;" class="datePicker" *ngIf="displayMode==2">
            <tr>
                <th colspan="3" style="text-align: center;"><div style="float: left;margin-left: 4px; width:25px;height:16px;display: inline-block;" (click)="yearSelectModifier=yearSelectModifier-20">&#11164;</div><div style="float: right; margin-right: 4px; width:25px;height:16px;display: inline-block;" (click)="yearSelectModifier=yearSelectModifier+20">&#11166;</div></th>
            </tr>
            <tr *ngFor="let n of yearList" >
                <td (click)="changeYearTo(n)">{{n}}</td>
                <td [ngClass]="{'currentSelected' : (n + 1)==currentYear}" (click)="changeYearTo(n+1)">{{n+1}}</td>
                <td (click)="changeYearTo(n + 2)">{{n+2}}</td>
            </tr>
            <tr>
                <th colspan="3" (click)="changeMode(1)"  style="height:25px;"><span>Zurück</span></th>
            </tr>
        </table>

    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>
</DIV>


