<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header1Row footer1Row" style="height: 90%; margin-right: 150px; margin-left: 150px; margin-bottom: 50px;">
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Meldeportal
                </span>
            </div>

            <div id="leftContainerContent">
                <div class="loading"></div>
                <table cellpadding="0" cellspacing="0" class="data_tabel noContext" style="width: calc( 100% - 2px); height: 98%;">
                    <tr><td colspan="4">
                <editor onfocus="let elem = this.getElementsByTagName('iframe')[0];elem.tabIndex = this.tabIndex;setTimeout(() => { elem.contentWindow.document.body.focus(); }, 100);"
                        [(ngModel)]="reporttext"
                        [init]="settings.tinyMceInit"
                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                        tabindex="104"
                        style="height:95%;">
                </editor></td></tr></table>
            </div>

            <div id="leftContainerBottom">
                <button (click)="saveReport()"
                        style="float: right; margin-right: 1px;"><img src="../design/icons/email_go.png">
                    Absenden
                </button>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
    </div>
</jw-modal>