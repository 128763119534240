<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>
        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    MODUL > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="template" selected>Inventory_place</option>
                        <option value="pfad-2">Pfad 2</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:100px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">

                        <select (change)="loadSiteData()"
                                [(ngModel)]="searchParameter.company"
                                [ngClass]="{ 'error' : searchParameter.company != '0' }"
                                class="cp-textbox"
                                style="width:170px;"
                                tabindex="3">
                             <option value="0">Alle Firmen</option>
                             <option [ngClass]="{'marked' : x.inventory == 1}" *ngFor="let x of companyData" value="{{x.id}}">{{x.name}}</option>
                        </select>
                        <select [(ngModel)]="searchParameter.site"
                                [ngClass]="{ 'error' : searchParameter.site != '0' }"
                                *ngIf="siteData.length > 1"
                                class="cp-textbox"
                                style="width:130px;"
                                tabindex="3">
                             <option value="0">Alle Standorte</option>
                             <option [ngClass]="{'marked' : x.inventory == 1}" *ngFor="let x of siteData" value="{{x.id}}">{{x.site_short_name}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;"></span>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th *ngIf="tableSettings.sort.state" style="width: 200px">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Etage" sortColumn="level"></span>
                        </th>
                        <th *ngIf="tableSettings.sort.state" style="width: 200px">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="RAUM" sortColumn="room"></span>
                        </th>
                        <th *ngIf="tableSettings.sort.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Standort" sortColumn="fk_contact_addresses"></span>
                        </th>
                        <th style="width: 24px">
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td style="width: 200px"  *ngIf="tableSettings.sort.state" (click)="edit($event,x)" >{{ x.level }}</td>

                        <td style="width: 200px"  *ngIf="tableSettings.sort.state" (click)="edit($event,x)" >{{ x.room }}</td>

                        <td  *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)" >{{ x.company_name }} - {{ x.site_name }}</td>

                        <td style="width: 24px">
                            <img (click)="deactivate(x)" src="../design/icons/delete.png">
                        </td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">


                    <span *ngIf="showNewItemForm || currentSelected.id>0">
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">
                            <span *ngIf="showNewItemForm">NEU</span>
                            <span *ngIf="currentSelected.id>0">BEARBEITEN</span>
                        </button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="( showNewItemForm || currentSelected.id>0) && currentSelectedTab == 1" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Raum *</span>
                                <input [(ngModel)]="currentSelected.room"
                                       [ngClass]="{ 'error' : currentSelected.room == '' && formError }"
                                       type="text"
                                       id="currentSelectedName"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                            <td>
                                <span class="formCaption">Etage *</span>
                                <input [(ngModel)]="currentSelected.level"
                                       [ngClass]="{ 'error' : currentSelected.room == '' && formError }"
                                       type="text"
                                       id="currentSelectedLevel"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Beschreibung </span>
                                <editor [(ngModel)]="currentSelected.description "
                                        tabindex="103"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        [init]="settings.tinyMceInit"
                                        [ngClass]="{ 'error' : currentSelected.text == '' && formError, 'noForm' : true }">
                                </editor>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Firma *</span><BR>
                                <select (change)="loadSiteDataEditor(1)"
                                        [(ngModel)]="currentSelected.fk_contact_company"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="3"
                                        [ngClass]="{'marked' : formError && currentSelected.fk_contact_company == 0}">
                                    <option value="0">Firma wählen</option>
                                    <option [ngClass]="{'marked' : x.inventory == 1}" *ngFor="let x of companyData" value="{{x.id}}">{{x.name}}</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption" *ngIf="siteDataEditor.length > 1">Standort *</span><BR>
                                <select [(ngModel)]="currentSelected.fk_contact_addresses"
                                        [ngClass]="{ 'error' : currentSelected.fk_contact_addresses == '0' }"
                                        *ngIf="siteDataEditor.length > 1"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="3">
                                    <option value="0">Standort wählen</option>
                                    <option [ngClass]="{'marked' : x.inventory == 1}" *ngFor="let x of siteDataEditor" value="{{x.id}}">{{x.site_short_name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td></td>

                            <td colspan="2">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                <img src="../design/icons/disk.png"> Speichern
                            </button>

                                <button (click)="cancel()" style="width: 120px; margin-right:4px; float: right;" tabindex="104">
                                    <img src="../design/icons/cross.png"> Abbrechen
                                </button>



                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSelectedTab == 3" style="margin: 20px;">
                    <b>Mit den checkboxen verbundene scope Daten (checkboxData)</b><BR>
                    <HR>
                    <ol>
                        <li *ngFor="let obj of checkboxData">{{obj | json}}</li>
                    </ol>
                    <HR>
                    <B>Scope Daten für die Liste Liste (data)</B><BR>
                    <HR>
                    <ol>
                        <li *ngFor="let obj of data">{{obj | json}}</li>
                    </ol>
                    <HR>
                    <B>Suchparameter der Liste (searchParameter)</B><BR>
                    <HR>
                    <pre>{{searchParameter | json}}</pre>
                    <HR>
                    <B>Formulardaten (currentSelected)</B><BR>
                    <HR>
                    <pre>{{currentSelected | json}}</pre>
                </div>
                <!-- ENTERNEN WENN FERTIG ENDE-->
            </div>
        </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
