<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footer1Row" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    MODUL > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="template" selected>Template</option>
                        <option value="pfad-2">Pfad 2</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2"
                            [ngClass]="{ 'error' : searchParameter.year != '' }">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <app-contact-manager
                            mode="contact"
                            onChange="ngOnChanges()"
                            [(contact)]="searchParameter.fk_contact"
                            [markOnSet]="true"
                            style="width:250px;" >
                    </app-contact-manager>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;"></span>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4"
                                [ngClass]="{ 'error' : searchParameter.user != '' }">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th *ngIf="tableSettings.sort.state" style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Prio." sortColumn="sort"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Kategorie 1" sortColumn="category1"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="DATUM" sortColumn="timestamp"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_fk_user"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        id="{{x.id}}"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td  *ngIf="tableSettings.sort.state" (click)="edit($event,x)" style="width:80px;">{{ x.sort }}</td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)">{{ x.name }}</td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)" style="width:150px;">{{ x.category1_string }}</td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)" style="width:150px;">{{ x.timestamp_string }}</td>

                        <td  *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)" style="width:150px;">{{ x.create_user }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">


                    <span *ngIf="showNewItemForm || currentSelected.id>0">
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">
                            <span *ngIf="showNewItemForm">NEU</span>
                            <span *ngIf="currentSelected.id>0">BEARBEITEN</span>
                        </button>
                    </span>
                    <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Hilfe</button>
                    <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 3 }" style="margin-right:5px;" (click)="changeTabButton(3)">Debug Data</button>

                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="( showNewItemForm || currentSelected.id>0) && currentSelectedTab == 1" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input [(ngModel)]="currentSelected.name"
                                       [ngClass]="{ 'error' : currentSelected.name == '' && formError }"
                                       type="text"
                                       id="currentSelectedName"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>

                            <td>
                                <span class="formCaption">Datum *</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.timestampString"
                                      id="currentSelectedTimestamp"
                                      [settabindex]="102"
                                      [mark]="formError && currentSelected.timestampString ==''" >
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Kontakt *</span><br>
                                <app-contact-manager id="processSelectedContact"
                                                     mode="free"
                                                     [(contact)]="currentSelected.fk_contact"
                                                     [settabindex]="103"
                                                     [mark]="formError && currentSelected.fk_contact == 0"
                                                     style="width: 100%;">
                                </app-contact-manager>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Text *</span>
                                <editor onfocus="let elem = this.getElementsByTagName('iframe')[0];elem.tabIndex = this.tabIndex;setTimeout(() => { elem.contentWindow.document.body.focus(); }, 100);"
                                        [(ngModel)]="currentSelected.text"
                                        [init]="settings.tinyMceInit"
                                        [ngClass]="{ 'error' : currentSelected.text == '' && formError, 'noForm' : true }"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        tabindex="104">
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                <span class="formCaption">Kategorie 1 *</span>
                                <select [(ngModel)]="currentSelected.category1"
                                        id="currentSelectedCategory1"
                                        style="width:100%;"
                                        tabindex="105"
                                        [ngClass]="{ 'error' : currentSelected.category1 == '-1' && formError }">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataCategory" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td style="width: 50%;">
                                <span class="formCaption">Kategorie 2 *</span>
                                <span app-suggest-text-input
                                      [(model)]="currentSelected.category2"
                                      [mark]="currentSelected.category2 =='' && formError"
                                      [settabindex]="106"
                                      id="currentSelectedCategory2"
                                      endpoint="template/category">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="2">
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- ENTERNEN WENN FERTIG -->
                <div *ngIf="currentSelectedTab == 2" style="margin: 20px;">


                            <b>WICHTIG:</b> Wenn diese Datei im ausgelieferten Zustand genutzt wird, kann sie nach der
                            Anpassung der Rest Endpunkte Inkonsistenz in der Datenbank erzeugen da nur ID und NAME beim Speichern berücksichtigt werden!
                            Um weitere Datenfelder zu schreiben muss das Formular entsprechend erweitert werden.<BR><BR>
                            <B>TODO: </B>Folgende Punkte dienen als Leitfaden zur Nutzung der Vorlage. Bitte alle nacheinander abarbeiten.<BR>
                            Löschen Sie die fertiggestellten Punkte aus der Quelldatei um die Übersicht zu behalten und nichts zu vergessen.<BR>
                            <BR><B>Tipp:</B> Am besten für mindestens jeden durchnummerierten Hauptpunkt ein Commit in Git erstellen.
                            <BR><BR><B>Namen:</B> Alle Name müssen immer nach den entsprechenden Feldnamen der Datenbank
                            Tabelle vergeben werden. Zum Beispiel ID="" und NAMEN="" Felder bei HTML Elementen oder Variablen in
                            PHP oder TypeScript.
                            <BR><BR><B>Style Klassen:</B> Je nachdem ob und wie viele Kopf oder Fusszeilen der rechte und linke
                            Bereiche besitzt muss dementsprechend in den SPANs rightContainerDefinition und
                            leftContainerDefinition die entsprechenden CSS Klassen gesetzt werden.<BR><BR>
                            Diese sind headerNone, header1Row, header2Row, header3Row, header4Row, footerNone und
                            footer1Row.
                            <ol>
                                <li style="list-style-type: decimal; font-size: 1.3em;">Anlegen eines neuen Angular Moduls und/oder einer Komponente.</li>
                                <ul>
                                    <li>Beim Modul nicht vergessen die Importe und Deklarationen in der .module.ts hinzuzufügen.</li>
                                    <li>Route in der app-routing.module.ts hinzufügen. Kompilieren und testen.</li>
                                    <li>Kopieren des Inhalts der beiden Template Dateien. Die HTML Datei kann komplett kopiert werden.
                                        Bei der .ts Datei muss der Name der Klasse und die darüber liegende Angular dekotatoren erhalten bleiben.</li>
                                    <li>Die Route erneut aufrufen und <B>die Funktion des kopierten Templates testen!</B></li>
                                </ul>
                                <li style="list-style-type: decimal; font-size: 1.3em;">Anpassung der Liste.</li>

                                <ul>
                                    <li>Definieren welche Datenfelder in der Liste angezeigt werden können und welche standardmäßig ausgeblendet sind.</li>
                                    <li>Rest endpunkt POST anpassen das diese Daten geliefert werden und Endpunkt testen.
                                    </li>
                                    <li>Die Spalten in den ngFor Block der Liste einfügen.</li>
                                    <li>Tabellen Kopf Beschriftung anpassen.</li>
                                    <li><B>Die Funktion testen! Auch das löschen der Daten aus der Datenbank prüfen.</B></li>
                                </ul>

                                <li style="list-style-type: decimal; font-size: 1.3em;">Anpassung der Suche.</li>
                                <ul>
                                    <li>Falls keine Suche gewünscht wird dann das Formular komplett entfernen und die Höhe
                                        des Listenkopfes laut UI Design Guide anpassen.
                                    </li>
                                    <li>Suchfelder dem Suchbereich hinzufügen oder entfernen.</li>
                                    <li>Prüfen das die Suchdaten bei der Rest Anfrage mitgesendet werden. Dazu Anpassung von this.searchParameter</li>
                                    <li>Rest Endpunkt POST anpassen das die Suchparameter berücksichtigt werden.</li>
                                    <li><B>Die Funktion auch mit Kombinationen der Suchparameter testen!</B></li>
                                </ul>
                                <li style="list-style-type: decimal; font-size: 1.3em;">Daten und Formular erweitern zum
                                    Bearbeiten und neu Anlegen der Datensätze
                                </li>
                                <ul>
                                    <li>Formular um benötigte Felder erweitern.</li>
                                    <li>Prüfen das die Daten bei der Rest Anfrage mitgesendet werden.</li>
                                    <li>Die beiden Befehle zum Anlegen und Aktualisieren der Daten im Rest Endpunkt an die
                                        neuen Daten anpassen.
                                    </li>
                                    <li>Pflichtfelder definieren, markieren (*) und eine Fehlerabfrage Implementieren.
                                        Eventuell mit Message Box bei speziellen Problemen Informieren sonst die
                                        Fehlerhaften oder leeren Felder laut UI Design Guide farblich Hervorheben.
                                    </li>
                                    <li><B>Die Funktion testen! Auch die geschriebenen Daten in der Datenbank prüfen und ein
                                        besonderes Augenmerk auf die Fehleranzeige der Pflichtfelder legen.</B></li>
                                </ul>
                                <li style="list-style-type: decimal; font-size: 1.3em;">Aktionen wenn gewünscht
                                    implementieren
                                </li>
                                <ul>
                                    <li>Gewünschte Aktionen der Dropdown Liste hinzufügen und diese Implementieren.</li>
                                    <li><B>Die Funktion testen!</B></li>
                                </ul>
                                <li style="list-style-type: decimal; font-size: 1.3em;">Abschließende Arbeiten.</li>
                                <ul>
                                    <li>Den im HTML Template als <B>ENTERNEN WENN FERTIG </B>kommentierten Bereich
                                        entfernen.
                                    </li>
                                    <li>Wenn benötigt weitere Sonderfunktionalität implementieren.</li>
                                    <li>Wenn keine checkboxe Aktionen Implementiert wurden dann den entsprechenden Code
                                        sowohl aus der js als auch aus der HTML Datei entfernen.
                                    </li>
                                    <li>Wenn alles funktioniert die javascript Modul Datei in die Script.js Datei überführen
                                        und die Modul Datei entfernen. Auch die Verlinkung auf die alte Modul js Datei aus
                                        der view.php entfernen.
                                    </li>
                                    <li><B>Kompletter abschließender Test des neuen Moduls!</B></li>
                                </ul>
                            </ol>
                        </div>

                        <div *ngIf="currentSelectedTab == 3" style="margin: 20px;">
                            <b>Mit den checkboxen verbundene scope Daten (checkboxData)</b><BR>
                            <HR>
                            <ol>
                                <li *ngFor="let obj of checkboxData">{{obj | json}}</li>
                            </ol>
                            <HR>
                            <B>Scope Daten für die Liste Liste (data)</B><BR>
                            <HR>
                            <ol>
                                <li *ngFor="let obj of data">{{obj | json}}</li>
                            </ol>
                            <HR>
                            <B>Suchparameter der Liste (searchParameter)</B><BR>
                            <HR>
                            <pre>{{searchParameter | json}}</pre>
                            <HR>
                            <B>Formulardaten (currentSelected)</B><BR>
                            <HR>
                            <pre>{{currentSelected | json}}</pre>
                        </div>
                        <!-- ENTERNEN WENN FERTIG ENDE-->
                    </div>
                </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="saveOnExit" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form">
        Es gibt noch ungespeicherte Daten!<BR>
        Sollen diese gesichert werden?
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Weiter
        </button>
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Speichern
        </button>
    </div>
</jw-modal>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
