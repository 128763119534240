import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-global-favorite',
  templateUrl: './core-global-favorite-management.component.html',
  styleUrls: ['./core-global-favorite-management.component.css']
})
export class GlobalFavoriteComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
    public formData: any;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public siteData = [];
  public company = [];
  public checkboxShiftStartElement: any;
  public checkboxAllState: boolean;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public addToSiteUserId = '-1';
  public currentSiteUsers = [];
  public companyId: string;
  public selectableUsers = [];
  public fkCompany: any;
  public fkContact: any;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', type: '', site_name : '', site_short_name : '', postcode: '', city: '', street: '', street_number: '', fk_company_id: 1, email_signature: '', site: '0', email: '', contact: '0', company: '0' };
    this.formData = {contact: '0'};
  }
    initFormData(): void {
        this.formData = {
            id: undefined,
            itemId: undefined,
            tid: undefined,
            bcc: [],
            company: undefined,
            site: undefined,
            contact: undefined,
            noMail: false,
            email: '',
            connectId: undefined,
            titel: '',
            text: '',
            attachment: [],
            type : 'team',
            editor : '',
            typ : undefined,
            time_duration: 0,
            fk_process_billing_type: undefined,
            attachmentsExist: false
        };
    }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'site_name', active: '1', contact: 0, company: -1, site: 0, text: '' };
  }

  constructor(public http: HttpClient,
              public settings: SettingsService) {
    this.initCurrentSelected();
    this.initFormData();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }
  }
  ngOnInit() {
    this.http.get<string>(this.settings.restBaseUrl + 'user/company/intern', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyId = data;

          this.search(false);
          this.siteAdresses();
        }
    );
    document.getElementById('searchText').focus();



    setTimeout(() => {
      if (this.data.length === 1) {
        this.edit(this.data[0]);
      }
    }, 300);

  }

  siteAdresses(): void {
      this.http.get<{status, count, data}>(this.settings.restBaseUrl + 'contact/globalFavorite/getSiteAdresses',  this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.siteData = data.data;
          }
      );
  }

  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/globalFavorite/globalFavoriteList', this.searchParameter,  this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.currentSelected = '';
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.site_name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.currentSelectedTab = 1;
    this.showNewItemForm = true;
    this.handleContactStringChange(0);
    this.handleCompanyStringChange(0);
    setTimeout(() => {
      document.getElementById('currentSelectedName').focus();
    }, 300);
  }

    handleCompanyStringChange(event: any) {
        this.fkCompany = event;
    }

    handleContactStringChange(event: any) {
        this.fkContact = event;
    }

  save(): void {
    this.http.put<{status, count, data}>(this.settings.restBaseUrl + 'contact/globalFavorite/saveSiteAdresses', {contact: this.fkContact, company: this.fkCompany, id: this.currentSelected.id, site: this.currentSelected.site} , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.search(false);
          }
      );
  }

  edit(item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'contact/company/sites/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.http.get<{id, email_signature}>(
              this.settings.restBaseUrl + 'contact/site/data/' + item.id,
              this.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
                if (data2) {
                  this.currentSelected.email_signature = data2.email_signature;
                }
                this.showNewItemForm = false;
                this.getSiteUsers();
                if ( this.currentSelectedTab === 1) {
                  setTimeout(() => {
                    document.getElementById('currentSelectedName').focus();
                    if (this.currentSelected.email_signature == null) { this.currentSelected.email_signature = ''; }
                  }, 100);
                } else {
                  document.getElementById('addToSiteUserId').focus();
                }
              });
        });
      if(item.type === '1'){this.formData.contact = item.id2;}

      if(item.type === '0'){this.formData.contact = '';}
      setTimeout(() => {
          this.currentSelected.site = item.fk_site;  }, 300);
  }

  getSiteUsers() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/sites/user/' + this.currentSelected.id , {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSiteUsers = data;
        }
    );
  }

  getSelectableUsers() {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/company/user' , {company: this.companyId}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.selectableUsers = data.data;
        }
    );
  }

  checkIfUserAlreadyAssigned() {
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'contact/company/sites/user/checkAssigned/' + this.addToSiteUserId + '/' + this.currentSelected.id , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (data.message === true) {
            const assigned = window.confirm('Der Benutzer ist bereits einem anderen Standort zugewiesen.\n' +
                'Sind Sie sicher,dass sie den Benutzer diesem Standort zuweisen möchten?\nDie Zuweisung zum anderen Standort wird dadurch gelöscht.');
            if (assigned) {
              this.addUserSiteLink();
            } else { return; }
          } else {
            this.addUserSiteLink();
          }
        }
    );
  }

  addUserSiteLink() {
    let duplicate = false;
    this.currentSiteUsers.forEach((item) => {
      if (item.fk_contact_id === this.addToSiteUserId) {
        duplicate = true;
      }
    });
    if (duplicate) {
      alert('Der User ist bereits diesem Standort zugewiesen.');
      return;
    } else {
      this.http.put<any[]>(this.settings.restBaseUrl + 'contact/company/address' , {contactId: this.addToSiteUserId, siteId: this.currentSelected.id}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.getSiteUsers();
          }
      );
    }
  }

  delete(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.delete<any[]>(
          this.settings.restBaseUrl + 'contact/globalFavorite/' + item.id, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.search(false);
          });
    }
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

}
