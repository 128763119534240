<div id="adminContainerDefinition">
    <div id="adminContainerBox">
        <div id="adminContainerTop">
            <span style="display:inline-block;margin-top:7px; margin-left:10px; height:20px; font-size: 1.2em;">Einstellungen</span>
            <span (click)="changeSystemState()" *ngIf="isAdmin==1" style="float: right; vertical-align: sub; margin-top: 10px; margin-right: 10px;">
                <img *ngIf="maintenance==='1'" src="../design/icons/control_play.png" tooltip="Wartungsmodus ist Aktiv!">
                <img *ngIf="maintenance==='0'" src="../design/icons/control_stop.png" tooltip="Wartungsmodus ist Inaktiv!">
            </span>
        </div>
        <div id="adminContainerContent">
            <table class="data_tabel" style="width:100%;" cellpadding="4" cellspacing="0">
                <tr *ngFor="let x of adminMenuEntries" >
                    <th *ngIf="x.icon!=''" style="background: #dddddd; text-align: left; color: black;border-bottom: 1px solid gray;border-top: 1px solid lightgray;">
                        <span *ngIf="x.icon!=''" style="font-size: 1.2em; font-weight: bold;">
                            <img style="vertical-align:middle;width:16px;height:16px; margin-right:6px; margin-left: 6px;" src="../design/icons/{{x.icon}}">
                            {{x.name}}
                        </span>
                    </th>
                    <td *ngIf="x.icon==''" (click)="handleEntryClick(x)" [ngClass]="{'marked':x.route==currentActiveRoute}">
                        <span style="margin-left: 8px;">{{x.name}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
