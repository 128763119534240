<div id="login_position">
    <div id="login_box" @fadeIn>
        <span *ngIf="pwr==0">
            <div id="login_top">{{settings.appVersion}}</div>
            <div id="login_form" class="form" *ngIf="!dbNeedUpdates">
                    <div class="label">
                        Benutzer
                        <span class="blinkingWhiteToRed" *ngIf="capsActive" style=" display:inline-block; float: right; margin-right:10px;">Feststelltaste ist aktiviert!</span>
                    </div>
                    <div class="textfild">
                        <input readonly
                               onfocus="this.removeAttribute('readonly');" autocomplete="off" id="username" name="value_480B861BE049E2EE358C18DD78999AF6" type="text" [(ngModel)]="loginData.username" >
                    </div>
                    <div class="label">
                        Passwort
                    </div>
                    <div class="textfild">
                        <input readonly
                               onfocus="this.removeAttribute('readonly');" autocomplete="off" id="password" name="value_480B861BE049E2EE358C18DD78999AF6" type="password" [(ngModel)]="loginData.password" (keyup)="checkLoginPassword()" (keydown)="checkForReturn($event)">
                    </div>

                    <span class="link">
                    <span *ngIf="false" (click)="changePwr(1)" style="text-decoration: underline;cursor: pointer;">Passwort vergessen?</span>
                </span>
                <div class="form">
                    <button (click)="startReport()" style="margin-top: 12px;width:150px;">Meldung nach HinSchG</button>
                    <button style="float: right; margin-right:5px; margin-top: 12px;" (click)="startLogin()"><img src="../design/icons/key.png" style="margin-right:4px;">Anmelden</button>
                </div>
                <span style="display: inline-block; margin-top:12px;">
                © {{copyrightDate | date: 'y'}} Symbicom AG<br>
                </span>

            </div>
            <div *ngIf="dbNeedUpdates" style="display: inline-block; height: 60px; margin-top: 60px; margin-left: 30px; font-size: 20px;">
                Die Datenbank muss <BR>aktualisiert werden!<br>
                <A href="../system/databaseUpdate.php" style="text-decoration: none;">&nbsp;&nbsp;&nbsp;</A>
            </div>

        </span>
        <span *ngIf="pwr=='1'">
            <div class="passwordResetTextBlock">
                Geben Sie Ihren <b>Benutzernamen</b> oder Ihre <br><b>E-Mail Adresse</b> ein um das Ändern Ihres Passwortes zu starten.
                Wir senden Ihnen dann eine E-Mail zu.
            </div>
            <div class="passwordResetForm form">
                <input [(ngModel)]="userToResetPassword" id="idUserToResetPassword" type="text" style="width:310px;" tabindex="50">
                <button (click)="resetPassword()"> Weiter </button><button (click)="pwr=''" style="margin-right: 5px;"> Zurück </button>
            </div>
        </span>
        <span *ngIf="pwr=='2'">
            <div class="passwordResetTextBlock">
                Bitte prüfen Sie Ihr E-Mail Postfach auf eine neue Mail. <BR><BR>Diese enthält einen Link mit der Sie Ihr Passwort zurücksetzen können.

            </div>
        </span>
        <span *ngIf="pwr=='3'">
            <div class="passwordResetForm form" style="margin-top:77px; display: inline-block; ">
                <div class="label" style="color: white; width:140px; display: inline-block;margin-left:10px;">
                    Passwort
                </div>
                <div class="label" style="color: white;float:right; width:147px;">
                    Wiederholung
                </div>
                <input [(ngModel)]="newPassword1" type="password" style="width:146px;" (keyup)="checkPassword()" (keydown)="checkForReturn($event)" tabindex="60" id="changePasswordOne">
                <input [(ngModel)]="newPassword2" type="password" style="width:146px;float: right;" (keyup)="checkPassword()" (keydown)="checkForReturn($event)" tabindex="61">
                <BR>
                <div style="margin-top: 6px; margin-bottom: 6px; color: white !important;">
                    <div class="checkText"><img *ngIf="!newPasswordCheck.length" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.length" src="../design/icons/accept.png"> 8 Zeichen lang </div>
                    <div class="checkText"><img *ngIf="!newPasswordCheck.special" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.special" src="../design/icons/accept.png">   Sonderzeichen </div>
                    <br>
                    <div class="checkText"><img *ngIf="!newPasswordCheck.upper" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.upper" src="../design/icons/accept.png">   Großbuchstaben </div>
                    <div class="checkText"><img *ngIf="!newPasswordCheck.lower" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.lower" src="../design/icons/accept.png">  Kleinbuchstaben </div>
                    <br>
                    <div class="checkText"><img *ngIf="!newPasswordCheck.digits" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.digits" src="../design/icons/accept.png">  2 Ziffern </div>
                    <div class="checkText"><img *ngIf="!newPasswordCheck.same" src="../design/icons/exclamation.png"><img *ngIf="newPasswordCheck.same" src="../design/icons/accept.png">  Passwörter gleich </div>
                    <br>

                </div>
                <button (click)="saveNewPassword()"> Weiter </button>
            </div>
        </span>
        <span *ngIf="pwr=='4'">
            <div class="passwordResetTextBlock">
                Ihr Passwort wurde erfolgreich geändert. <BR><BR>Sie können Sich nun wieder Anmelden.

            </div>
            <div class="passwordResetForm form">
                <button (click)="changePwr(0)" style="margin-top:18px;" id="returnToLogin"> Weiter </button>
            </div>
        </span>
    </div>

    <div id="login_glas">

        <div *ngIf="maintenance" style="width: 80%;
                    margin: auto 20px;
                    text-align: center;
                    margin-top: 20px;
                    padding: 20px;
                    background: orange;
                    border: 1px solid black;">
            Das System befindet sich im Wartungsmodus. Login ist nicht möglich. <BR>Bitte versuchen Sie es später noch einmal.
        </div>
    </div>
</div>

<jw-modal id="messageBox" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/information.png"><span style=""> Hinweis</span><BR><BR></div>
    </div>
    <div class="modalContent">{{messageContent}}</div>
    <div class="modalFooter form" ><button (click)="closeMessageBox()" style="float: right; margin-top: 3px;">OK</button></div>
</jw-modal>
