<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Wissensdatenbank > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="template" selected>Template</option>
                        <option value="pfad-2">Pfad 2</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <select (change)="changeDropdownCategory1()"
                                                    [(ngModel)]="searchParameter.fk_knowhow_category1"
                                                    style="width:120px;"
                                                    tabindex="2">
                        <option value="-1">Alle Kategorien</option>
                        <option *ngFor="let x of dropdownDataCategory1; let i = index" [value]="x.id">{{x.name}}</option>
                    </select>

                    <select (change)="search(false)"
                                       [(ngModel)]="searchParameter.fk_knowhow_category2"
                                       style="width:120px;"
                                       tabindex="2">
                        <option value="-1">Alle Unterkategorien</option>
                        <option *ngFor="let x of dropdownDataCategory2; let i = index" [value]="x.id">{{x.name}}</option>
                    </select>

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.year"
                                style="width:80px;"
                                tabindex="2">
                            <option value="">Alle Jahre</option>
                            <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4">
                                <option value="">Alle Benutzer</option>
                                <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>

                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="KATEGORIE" sortColumn="fk_knowhow_category1"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="UNTERKAT." sortColumn="fk_knowhow_category2"></span>
                        </th>
                        <th style="width:100px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="DATUM" sortColumn="create_timestamp"></span>
                        </th>
                        <th style="width:100px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_fk_user"></span>
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="show($event,x)">{{ x.name }}</td>

                        <td (click)="show($event,x)" style="width:150px;">{{ x.kategorie_string1 }}</td>

                        <td (click)="show($event,x)" style="width:150px;">{{ x.kategorie_string2 }}</td>

                        <td (click)="show($event,x)" style="width:100px;">{{ x.create_timestamp_string }}</td>

                        <td (click)="show($event,x)" style="width:100px;">{{ x.create_user }}</td>

                        <td style="width:20px;">
                            <img (click)="edit($event,x)" src="../design/icons/pencil.png">
                        </td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div *ngIf="showItem" style=" font-size: 1.2em;margin-top: 4px;margin-left: 10px;">
                    {{ currentSelected.name }}
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showItem" >
                    <div style="margin: 20px;" [innerHTML]="currentSelected.text | safe"></div>
                </div>

                <div *ngIf="showNewItemForm || currentSelected.id>0 && false" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Name</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:calc( 100% - 5px );"
                                       tabindex="101" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Text</span>

                                <editor [(ngModel)]="currentSelected.text"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit">
                                </editor>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%">
                                <span class="formCaption">Kategorie </span><br>
                                <select (change)="changeDropdownCurrentSelectedCategory1()"
                                        [(ngModel)]="currentSelected.fk_knowhow_category1"
                                        style="width:100%;"
                                        tabindex="2">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataCategory1; let i = index" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td style="width: 50%">
                                <span class="formCaption">Unterkategorie</span><br>
                                <select [(ngModel)]="currentSelected.fk_knowhow_category2"
                                        style="width:100%;"
                                        tabindex="2">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownCurrentSelectedDataCategory2; let i = index" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span><img style="width:150px;+" [src]="currentSelected.document_filepath" /></span>
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
