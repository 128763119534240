import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  public data: any[];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownRightgroups;
  public httpOptions = { };
  public currentSelectedTab = 1;
  public modulePreset = 1;
  public rightGroupCheckValue;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', sort : '', active : '', modul : this.modulePreset, modul_dependency : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', modul_dependency : '1', modul: this.modulePreset };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.initCurrentSelected();
    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'tag/rightGroupSelect', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownRightgroups = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'tag', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.initCurrentSelected();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    this.currentSelected.active = 1;
    this.currentSelected.modul_dependency = this.searchParameter.modul_dependency;
    this.rightGroupCheckValue = this.searchParameter.modul_dependency;
    setTimeout(() => {
        document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
          let mandatoryFields = true; let noReassign = true; let nameExist = false;
          if (this.currentSelected.name == null || this.currentSelected.name === '') {
              alert('Alle Pflichtfelder müssen ausgefüllt sein.\nGeben Sie einen Namen ein.');
              mandatoryFields = false;
              return;
          }

          if (this.currentSelected.modul_dependency == null || this.currentSelected.modul_dependency === '' || this.currentSelected.modul_dependency < 0) {
              alert('Alle Pflichtfelder müssen ausgefüllt sein.\nWählen Sie eine Rechtegruppe aus.');
              mandatoryFields = false;
              return;
          }

          for (const currentTag of this.data) {
              if(currentTag.name.toLowerCase() === this.currentSelected.name.toLowerCase()) {
                  alert('Den Tag Namen gibt es bereits in dieser Dokumentegruppe!');
                  return;
              }
          }

          if (this.currentSelected.id > 0) {
              if (this.rightGroupCheckValue !== this.currentSelected.modul_dependency) {

                  this.http.post<{status, message}>(this.settings.restBaseUrl + 'tag/assigned/' + this.currentSelected.id , this.httpOptions)
                      .pipe(map(data => data)).subscribe(
                      data => {
                          if (data.message === true) {
                              noReassign = false;
                              alert('Der Tag kann nicht einer anderen Rechtegruppe zugewiesen werden.\nWenn Sie einen gleichnamigen Tag erstellen möchten, deaktivieren\nSie diesen Tag und ' +
                                  'und erstellen Sie einen neuen Tag in der entsprechenden Gruppe.');
                              return;
                          }
                      }
                  );
              }
          }

          this.http.post<{status, message}>(this.settings.restBaseUrl + 'tag/nameCheck/' + this.currentSelected.modul_dependency + '/' + this.currentSelected.name + '/' + this.currentSelected.id , this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  if (data.message === true) {
                      nameExist = true;
                      alert('Der Name des Tags ist Bereits in dieser Rechtegruppe vergeben,\nwählen Sie einen anderen Namen aus.');
                      return;
                  } else {
                      setTimeout(() => {
                          if (mandatoryFields && noReassign && !nameExist) {
                              this.http.put<any[]>(
                                  this.settings.restBaseUrl + 'tag' ,
                                  JSON.stringify(this.currentSelected),
                                  this.httpOptions)
                                  .pipe(map(data2 => data2)).subscribe(
                                  data2 => {
                                      this.search(false);
                                      this.initCurrentSelected();
                                      this.showNewItemForm = false;
                                  }
                              );
                          }
                      }, 300);
                  }
              }
          );
        }

  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'tag/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          this.rightGroupCheckValue = this.currentSelected.modul_dependency;
          setTimeout(() => {
              document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'tag/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'tag/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'tag/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
