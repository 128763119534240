<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footer1Row" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Vorgänge > Statistik >
                    <select class="modul_selector">
                        <option>Arbeitszeit</option>
                    </select>
                    <span *ngIf="searchResultCount>data.length" style="float:right; margin-right:10px;">{{data.length}} von {{searchResultCount}} Einträgen</span>
                    <span *ngIf="searchResultCount<=data.length" style="float:right; margin-right:10px;">{{searchResultCount}} Einträge</span>
                </span>
                <span class="form search">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.month"
                            *ngIf="searchParameter.year>0"
                            style="width:80px;"
                            tabindex="2">
                        <option value="-1">Alle Monate</option>
                        <option value="1">Januar</option>
                        <option value="2">Februar</option>
                        <option value="3">März</option>
                        <option value="4">April</option>
                        <option value="5">Mai</option>
                        <option value="6">Juni</option>
                        <option value="7">Juli</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Dezember</option>
                    </select>

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.company"
                            id="searchCompany"
                            style="width:150px;"
                            tabindex="4">
                        <option value="-1">Alle Kunden</option>
                        <option *ngFor="let x of dropdownDataCompany" [value]="x.id">{{x.name}}</option>
                    </select>


                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.user"
                            id="searchUser"
                            style="width:150px;"
                            tabindex="4">
                        <option value="-1">Alle Mitarbeiter</option>
                        <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                    </select>

                     <select (change)="search(false)"
                            [(ngModel)]="searchParameter.groupBy"
                            id="searchGroupBy"
                            style="width:150px;"
                            tabindex="4">
                        <option value="-1">Nicht Gruppieren</option>
                        <option value="1">Nach Firma</option>
                        <option value="2">Nach Mitarbeiter</option>
                    </select>

                    <select  (change)="search(false)"
                             [(ngModel)]="searchParameter.displayType"
                             *ngIf="searchParameter.month>0"
                             id="displayType"
                             style="width:150px;"
                             tabindex="4">
                        <option value="-1">Monat</option>
                        <option value="1">Tage</option>
                    </select>


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>


                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">




                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:80px;">
                            Monat
                        </th>
                        <th *ngIf="searchParameter.groupBy!=2" >
                           Kunde
                        </th>
                        <th *ngIf="searchParameter.groupBy!=1" style="width:220px;">
                         Mitarbeiter
                        </th>
                        <th style="width:100px;">
                            Stunden
                        </th>
                        <th style="width:100px;">
                         Umsatz
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>

                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="text-align: right; width:80px;"><span *ngIf="x.time_day>0">{{ x.time_day | number:'2.0-0'}}.</span>{{ x.time_month  | number:'2.0-0'}}.{{ x.time_year }}&nbsp;&nbsp;</td>

                        <td *ngIf="searchParameter.groupBy!=2" (click)="edit($event,x)">{{ x.company_name }}</td>

                        <td  *ngIf="searchParameter.groupBy!=1" (click)="edit($event,x)" style="width:220px;">{{ x.user_name }}</td>

                        <td (click)="edit($event,x)" style="width:100px;">{{ x.worktime/60/60 | number:'1.2-2'}}</td>

                        <td (click)="edit($event,x)" style="width:100px;">{{ x.money | number:'1.2-2'}} €</td>

                    </tr>
                </table>

            </div>

            <div id="leftContainerBottom">



            </div>
        </div>

    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <table *ngIf="statisticEntries.length>0" class="data_tabel" style="width:100%; margin-top:12px; font-size: 8px;">
                    <tr>
                        <th style="width:100px;">
                            Datum
                        </th>
                        <th style="width:90px;">
                            Tid
                        </th>
                        <th  style="width:40px;">
                            AWs
                        </th>
                        <th>
                            Titel
                        </th>
                        <th style="width:24px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="rightContainerContent">
                <div style="height:25%; overflow: auto;">
                    <table class="data_tabel" style="width:100%;">
                        <tr *ngFor="let x of statisticEntries">
                            <td (click)="loadProcess(x)" style="text-align: center" style="width:100px; text-align: center">
                                <b>{{x.date_string}}</b>
                            </td>
                            <td (click)="loadProcess(x)" style="text-align: center" style="width:90px; text-align: center;">
                                {{x.tid}}
                            </td>
                            <td (click)="loadDocument(x)" style="text-align: right" style="width:40px; text-align: right;">
                                {{x.aw}}&nbsp;&nbsp;
                            </td>
                            <td (click)="loadDocument(x)">
                                {{x.mainTitel}}
                            </td>
                            <td (click)="loadDocument(x)" style="text-align: center" style="width:24px;">
                                <img *ngIf="x.typ==12" src="../design/icons/doc_pdf.gif" >
                            </td>
                        </tr>
                    </table>
                </div>

                <div id="bottom-content" style="height:75%; background: #3B3B3B; overflow: hidden;">
                    <iframe id="documentView" name="documentView" style="z-index:-999999;width:99.5%;height:99.5%;overflow: hidden; border: none;" src="">
                    </iframe>
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
