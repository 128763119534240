import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';


@Component({
  selector: 'app-process-manually-assign',
  templateUrl: './process-manually-assign.component.html',
  styleUrls: ['./process-manually-assign.component.css']
})
export class ProcessManuallyAssignComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = { };
  public fileToLoad;
  public openProcessWorkDocuments = [];
  public selectedDocumentId = '0';

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', tid : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { company: '', site: '', contact: '', loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'sort', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(50);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();


  }

  ngOnInit() {
    this.search(false);
    this.getOpenWorkDocuments();
    setTimeout (() => {
      document.getElementById('searchText').focus();
      if (this.data && this.data.length > 0) { this.edit(this.data[0]); }
    }, 300);
  }

  getOpenWorkDocuments() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'process/workdocument/open', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.openProcessWorkDocuments = data;
        }
    );
  }

  search(loadNextSearchResults: boolean): void {
    this.http.post<any[]>(this.settings.restBaseUrl + 'process/workdocument/list', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data;
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  save(): void {
    if (this.selectedDocumentId === '0') { alert('Sie müssen eine Vorgangsnummer Wählen!'); return; }
    const saveData = {
      entryId: this.selectedDocumentId,
      documentId: this.currentSelected.id
    };

    this.http.put<any[]>(
        this.settings.restBaseUrl + 'process/workdocument', saveData, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.search(false);
          this.getOpenWorkDocuments();
          this.initCurrentSelected();

          setTimeout(() => {
            if (this.data.length < 1 || !this.data) {
              this.fileToLoad = null;
              // @ts-ignore
              document.getElementById('documentView').src = 'about:blank';
            } else {
              if (this.data && this.data.length > 0) { this.edit(this.data[0]); }
            }
            this.selectedDocumentId = '0';
          }, 300);
        }
    );
  }

  edit(item: any): void  {
    this.http.get<{id, filepath}>(
        this.settings.restBaseUrl + 'process/workdocument/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.fileToLoad = this.currentSelected.filepath;
          setTimeout(() => { document.getElementById('processId').focus(); }, 300);
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
