<DIV style="display: inline-block; vertical-align: middle; margin-bottom: 1px; margin-right: -1px;" id="{{overlayId}}Panel">
    <button class="round_button" (click)="toggleOverlay(999)" style="margin-right:10px;" >
        <img src="../design/icons/menu.png" style="margin-top:1px; width:24px; height:24px; margin-left: 1px;">
    </button>
    <div id="{{overlayId}}" style="position: absolute; z-index: 999999">
        <div *ngIf="moduleOpen" style="text-align: left;margin-left: 318px;font-size: 30px;color: white; height: 25px; margin-top:-12px;">⯅</div>
        <div *ngIf="moduleOpen" class="shadow" style="text-align:left; overflow: hidden; width:fit-content; height:fit-content; visibility:visible; color: #000000; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
            <div *ngIf="moduleOpen && moduleOpen>98 && expandedMenu==''" style="max-width: 656px; background-color: #497edc; margin: 6px;">
                <div style="margin: 10px; display: inline-block;">
                    <span *ngFor="let x of menuStructure" style="display: inline-block; width:200px; height:150px; background: white;margin:6px;-webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px; " >
                        <table class="data_tabel light" style="width:100%;" cellpadding="4" cellspacing="0">
                            <tr><th (click)="expandMenu(x)">
                                {{x.name}}
                                <span style="float:right" *ngIf="x.menuPoints.length > 3" >►</span></th></tr>
                        </table>
                        <div style="overflow-y: auto; display: inline-block; height:119px;width:100%;">
                            <table class="data_tabel light" style="width:100%;" cellpadding="3" cellspacing="0">

                                <ng-container *ngFor="let y of x.menuPoints">
                                    <tr *ngIf="y.main>0">
                                        <td  (click)="callMenuUrl(y,false)">
                                            <img *ngIf="y.main==2" src="../design/icons/add.png" style="vertical-align: sub;">
                                            {{y.name}}
                                        </td>
                                        <td  (click)="callMenuUrl(y,true)" style="width:18px;">
                                            <img style="width:14px; vertical-align: bottom;" src="../design/icons/application_xp.png" tooltip="Menüpunkt in neuem Fenster öffnen.">
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="x.id==6">
                                    <tr *ngFor="let p of projects">
                                        <td (click)="callMenuUrl(p,false)">{{p.name}}</td>
                                        <td  (click)="callMenuUrl(p,true)"style="width:18px;">
                                            <img style="width:14px; vertical-align: bottom;" src="../design/icons/application_xp.png" tooltip="Menüpunkt in neuem Fenster öffnen.">
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </span>
                </div>
            </div>

            <div *ngIf="moduleOpen && moduleOpen>98 && expandedMenu!=''" style="width: 656px; height:fit-content;background-color: #497edc; margin: 6px;">
                <div style="margin: 10px; display: inline-block;">
                    <span style="display: inline-block; width:200px; height:300px; background: white;margin:6px;-webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px; " >
                        <table class="data_tabel light" style="width:100%;" cellpadding="4" cellspacing="0">
                                <tr><th colspan="2" (click)="expandMenu('')">
                                    {{expandedMenu.name}}
                                    <span style="float:right" >◄</span></th></tr>
                                    <ng-container *ngFor="let y of expandedMenu.menuPoints">
                                        <tr (mouseenter) ="mouseEnter(y)" (mouseleave) ="mouseEnter('')">
                                            <td  (click)="callMenuUrl(y,false)">
                                                <img *ngIf="y.main==2" src="../design/icons/add.png" style="vertical-align: sub; margin-left: -1px;">
                                                {{y.name}}
                                            </td>
                                            <td  (click)="callMenuUrl(y,true)" style="width:18px;">
                                                <img style="width:14px; vertical-align: bottom;" src="../design/icons/application_xp.png" tooltip="Menüpunkt in neuem Fenster öffnen.">
                                            </td>
                                        </tr>
                                    </ng-container>
                        </table>
                    </span>
                    <span style="display: inline-block; width:410px; float: right; height:300px; background: white;margin:6px;-webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px; " >
                        <span style="display: inline-block; margin:20px;">{{menuDescriptionText}}</span>
                    </span>
                </div>
            </div>
        </div>
        </div>
    <DIV name="bottomOverlayPanel"  (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}"   style="margin-top:0px !important; z-index: 9;">
    </DIV>
</DIV>
