                <span *ngIf="processData.header!==undefined && processData.header.tid > 0" [ngClass]="{'processShowFullscreen' : showFullscreen == '1'}" >
                    <div id="fullscreenProcessScrollBox" tabindex="-1" class="processScrollBox">
                        <div class="form" style="margin-top: 20px; margin-bottom: 20px;">
                            <div style="width: 100%; " >
                            <span *ngFor="let x of processData.data" >
                                <div class="card form" *ngIf="showInfosInProgress || x.information === '0'" id="processEntry{{x.id}}" style="scroll-margin: 15px;">

                                    <table style="width: 100%; border: unset;" cellpadding="0" cellspacing="0" border="0">
                                        <tr>
                                            <td [ngClass]="{'newMarker' : x.new == 1 && list==6 , 'linkMarker' : quickMark == x.id && list==6 }" style="width:4px; border: none;" id="markerArea{{x.id}}"></td>
                                            <td style="border: none;" class="form">
                                                <span *ngIf="hideIcons">
                                                <button *ngIf="x.typ!=8 && x.typ!=26" (click)="viewPrintView(x)" tooltip="Drucken" style="border-top: 1px solid lightgray !important;position: absolute; right:10px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/printer.png"></button>
                                                <button *ngIf="x.typ==8 || x.typ==26" (click)="openWorkingDocument( x.id, processData.header.tid, x.typ)" tooltip="Arbeitsbeleg Drucken" style="border-top: 1px solid lightgray !important;position: absolute; right:10px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/printer.png"></button>

                                                <button (click)="handleProcessAction('forward',x)" tooltip="Weiterleiten" style="border-top: 1px solid lightgray !important;position: absolute; right:40px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/email_out.png"></button>
                                                <button (click)="handleProcessAction('reply',x)" *ngIf="x.from_address != ''" tooltip="Antworten" style="border-top: 1px solid lightgray !important;position: absolute; right:70px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/email_reply.png"></button>
                                                <button (click)="addNewSubTasks(x)" *ngIf="x.main == '1'" tooltip="Teilaufgabe hinzufügen" style="border-top: 1px solid lightgray !important;position: absolute; right:100px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/brick_add.png"></button>
                                                <button *ngIf="x.main == '1'" tooltip="Bearbeiten" style="border-top: 1px solid lightgray !important;position: absolute; right:130px; top:-1px; width: 32px; min-width: 32px;border-radius: 0px 0px 3px 3px !important;-moz-border-radius: 0px 0px 3px 3px !important;-webkit-border-radius: 0px 0px 3px 3px !important;"><img src="../design/icons/page_edit.png"></button>
                                                </span>

                                                <div class="header">
                                                    <span class="info">
                                                        <img src="{{x.icon}}">
                                                        <img tooltip="E-Mail wurde <br>noch nicht versendet!" *ngIf="x.typ==1 && x.mailOutgoingQueue.send != 1" src="../design/icons/tick_inactive.png" style="margin-left: 4px">
                                                        <img tooltip="E-Mail versendet: <br>{{x.mail_send_time}}"*ngIf="x.typ==1 && x.mailOutgoingQueue.send == 1 && x.mailOutgoingQueue.mail_read_time == ''" src="../design/icons/tick.png" style="margin-left: 4px">
                                                        <img tooltip="E-Mail versendet: <br>{{x.mail_send_time}} <br>und gelesen: <br>{{x.mailOutgoingQueue.mail_read_time}}"*ngIf="x.typ==1 && x.mailOutgoingQueue.send == 1 && x.mailOutgoingQueue.mail_read_time != ''" src="../design/icons/tickdouble.png" style="margin-left: 4px">


                                                        {{x.send_time_string}} | <span style="cursor: pointer; " (click)="openAddress(x.user)">{{x.contact_username}}</span><span *ngIf="x.from_address"> - {{x.from_address}}</span>
                                                    </span>
                                                    <span *ngIf="x.main!='1' && x.time_duration != 0" class="title" style="float:left;"><B>{{x.time_name}}:</B> {{x.time_value }} {{x.time_unit}} -&nbsp;</span>
                                                    <span *ngIf="x.titel!==''" class="title"> {{x.titel}}</span>
                                                    <div *ngIf="x.create_username" style="font-size: 0.8em;margin-bottom: 3px;">Erstellt von: {{x.create_username}}</div>

                                                    <span>
                                                        <span>
                                                            <span *ngFor="let a of x.attachemnts; " >
                                                                <div *ngIf="a.inline==0" class="attachmentBox" style="width:200px;">
                                                                    <div *ngIf="a.ending=='pdf'" class="attachmentBoxImage" (click)="sendToDMS($event,a)" ><img src="../design/icons/folder_page_white.png"></div>
                                                                    <div *ngIf="a.icon!=''" class="attachmentBoxImage" (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)" ><img src="{{a.icon}}"></div>
                                                                    <div *ngIf="a.icon==''" class="attachmentBoxImage" (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)" ><img src="../design/icnos/page_white.png"></div>
                                                                    <div (click)="viewFullscreen($event,a.path + '/' + a.name, a.name)"  class="attachmentBoxText">{{a.name}} <br> {{a.size}} </div>
                                                                </div>
                                                            </span>
                                                                <div *ngIf="checkForInlineAttachments(x.attachemnts)" class="attachmentBox" style="min-width:fit-content;" tooltip="Es gibt Versteckte Anhänge. Klicken um diese anzuzeigen!">
                                                                    <div class="attachmentBoxImage" (click)="viewInline($event,x.attachemnts)" >
                                                                        <img src="../design/icons/information.png">
                                                                    </div>
                                                                </div>
                                                        </span>
                                                    </span>
                                                </div>

                                                <div class="text">
                                                    <span *ngIf="x.mail_uid>0" app-resize-iframe [(model)]="x.text"></span>
                                                    <span *ngIf="x.mail_uid==0" class="noMailText" [innerHTML]="x.text | safe"></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                     <div *ngIf="x.subTasks && x.subTasks.length > 0"  style="width: calc( 100% + 2px ); margin-top: 5px; ">
                                        <table class="data_tabel light" cellspacing="0" cellpadding="4" style="width: 100%;">
                                            <tr>
                                                <th></th>
                                                <th>
                                                    Teilaufgaben
                                                </th>
                                            </tr>
                                            <tr *ngFor="let t of x.subTasks">
                                                <td style="width:22px;" (click)="toggleSubTaskState(t);">
                                                    <img *ngIf="t.active=='0'" src="../design/icons/accept_inactive.png">
                                                    <img *ngIf="t.active=='1'" src="../design/icons/accept.png">
                                                </td>
                                                <td>
                                                    <span *ngIf="t.edit!='1'">
                                                        <span>{{t.create_timestamp_string}} - {{t.create_username}}</span><br>
                                                        <span style="font-weight: bold;">{{t.name}}</span>
                                                    </span>
                                                    <span *ngIf="t.edit=='1'" class="form">
                                                        <input id="subTaskEdit{{t.id}}" type="text" style="width:calc( 100% - 45px);" [(ngModel)]="t.name" (keydown.enter)="saveSubTask(t)" (blur)="saveSubTask(t)"  >

                                                        <button (click)="saveSubTask(t)" style="border-top: 1px solid lightgray !important;position: absolute; width: 32px; min-width: 32px;">
                                                            <img src="../design/icons/disk.png">
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </span>
