import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  public tutorial: any;
  public active = false;
  constructor() { }
  public displayComponent: any;

  start(id) {
    const content = [
      { elementId: 'processSearchText', content: 'Hier können Sie einen Suchtext eingeben. Es wird dann nach Vorgängen mit diesem Text im Titel oder Text gesucht.' },
      { elementId: 'processSearchContact', content: 'Mit diesem Element kann in der Liste nach allen Vorgängen zu diesem Kontakt gefiltert werden.' },
      { elementId: 'processSearchExpandedToggle', content: 'Öffnet die erweiterten Suchparameter' },
      { elementId: 'processSearchReset', content: 'Setzt alle Suchßparameter zurück.' },
      { elementId: 'processSearchButton', content: 'Startet die Suche.' },
      { elementId: 'processListFilter', content: 'Hier können Sie einstellen welche zusätzlichen Splaten in der Liste angezeigt werden.' },
      { elementId: 'processNewButton', content: 'Schaltfläche zum anlegen neuer Vorgänge.' }
    ];
    this.displayComponent.setContent(content);
    this.displayComponent.displayContent();
  }

  setDisplayComponent(DisplayComponent) {
    this.displayComponent = DisplayComponent;
  }
}
