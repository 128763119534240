import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { PipesModule } from '../pipes/pipes.module';
import { ModalModule } from '../_modal';
import { MyDefaultTooltipOptions } from '../tooltip-options';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { TimeTrackingStateManagementComponent } from './time-tracking-state-management/time-tracking-state-management.component';
import { TimeTrackingTypeManagementComponent } from './time-tracking-type-management/time-tracking-type-management.component';
import { TimeTrackingAbsenceTypeManagementComponent } from './time-tracking-absence-type-management/time-tracking-absence-type-management.component';
import { TimeTrackingAbsenceComponent } from './time-tracking-absence/time-tracking-absence.component';
import { TimeTrackingErrorComponent } from './time-tracking-error/time-tracking-error.component';
import { TimeTrackingUserStatementComponent } from './time-tracking-user-statement/time-tracking-user-statement.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'time-tracking', component: TimeTrackingComponent }
];

@NgModule({
    declarations: [
        TimeTrackingComponent,
        TimeTrackingStateManagementComponent,
        TimeTrackingTypeManagementComponent,
        TimeTrackingAbsenceTypeManagementComponent,
        TimeTrackingAbsenceComponent,
        TimeTrackingErrorComponent,
        TimeTrackingUserStatementComponent
    ],
    imports: [
        CommonModule, FormsModule, UiModule, EditorModule,
        PipesModule, ModalModule,
        TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
        AngularSplitModule,
        ContextMenuModule,
        RouterModule.forChild(routes)
    ],
    exports: [
    ]
})


export class TimeTrackingModule { }
