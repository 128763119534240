import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PipesModule } from '../pipes/pipes.module';
import { ModalModule } from '../_modal';
import { WhistleblowerComponent } from './whistleblower/whistleblower.component';
import { WhistleblowerReportComponent } from './whistleblower-report/whistleblower-report.component';




@NgModule({
  declarations: [
    WhistleblowerComponent, WhistleblowerReportComponent
  ],
  imports: [
    CommonModule,
    AngularSplitModule,
    ContextMenuModule,
    EditorModule,
    FormsModule,
    ModalModule,
    PipesModule,
    UiModule
  ]
})
export class WhistleblowerModule { }
