<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footer1Row" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    MODUL > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="template" selected>Template</option>
                        <option value="pfad-2">Pfad 2</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">



                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <app-contact-manager
                            mode="email"
                            [(company)]="searchParameter.company" onChange="ngOnChanges()"
                            [(site)]="searchParameter.site"
                            [(contact)]="searchParameter.contact"
                            style="width:250px;" >
                    </app-contact-manager>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.user"
                                id="searchUser"
                                style="width:150px;"
                                tabindex="4">
                            <option value="">Alle Benutzer</option>
                            <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:24px; text-align: center;">
                            <input type="checkbox" (change)="checkboxSwapAll()" [(ngModel)]="checkboxAllState" id="checkbocall" style="margin:0px; padding: 0px; margin-left:2px;">
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ID"  sortColumn="id"></span>
                        </th>
                        <th style="width:80px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="SORT" sortColumn="sort"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_fk_user"></span>
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">

                <BR><BR><BR>
                <app-contact-manager
                        [(company)]="testcompany"
                        [(site)]="testsite"
                        [(contact)]="testcontact"
                        (nameStrings)="onNameString($event)"
                        style="width:400px;" >
                </app-contact-manager>
                <BR>
                <BR><input type="text" [(ngModel)]="testcompany"> > Company
                <BR><input type="text" [(ngModel)]="testsite"> > Site
                <BR><input type="text" [(ngModel)]="testcontact"> > Contact
                <br>
                <BR><input type="text" [(ngModel)]="nameTest.company"> > Company Name
                <BR><input type="text" [(ngModel)]="nameTest.company_short"> > Company Short Name
                <br>
                <BR><input type="text" [(ngModel)]="nameTest.site"> > Site Name
                <BR><input type="text" [(ngModel)]="nameTest.site_short"> > Site Short Name
                <br>
                <BR><input type="text" [(ngModel)]="nameTest.contact_salutation"> > Contact Salutation
                <BR><input type="text" [(ngModel)]="nameTest.contact_title"> > Contact Title
                <BR><input type="text" [(ngModel)]="nameTest.contact_first_name"> > Contact First_Name
                <BR><input type="text" [(ngModel)]="nameTest.contact_name"> > Contact Name
                <br>
                <BR><input type="text" [(ngModel)]="nameTest.phone"> > Telefon
                <BR><input type="text" [(ngModel)]="nameTest.email"> > EMail
                <br>
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="checkboxSwap($event,x);" style="width:24px;">
                            <input type="checkbox"
                                   (click)="checkboxSwap($event,x);"
                                   (change)="handleCheckboxChanged(0)"
                                   [(ngModel)]="x.checked"
                                   id="actionCheckbox{{x.id}}">
                        </td>

                        <td (click)="edit($event,x)" style="width:80px;">{{ x.id }}</td>

                        <td (click)="edit($event,x)" style="width:80px;">{{ x.sort }}</td>

                        <td (click)="edit($event,x)">{{ x.name }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.create_user }}</td>

                        <td style="width:20px;">
                            <img *ngIf="x.active==1" (click)="deactivate(x)" src="../design/icons/delete.png">
                            <img *ngIf="x.active==0" (click)="activate(x)" src="../design/icons/accept.png">
                        </td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">
                    <span *ngIf="showNewItemForm">NEU FORMULAR</span>

                    <span *ngIf="currentSelected.id>0">
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Eintrag bearbeiten</button>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Zweites Tab</button>
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>

                            <td>
                                <span class="formCaption">Datum</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.timestampString"
                                      id="currentSelectedTimestamp"
                                      tabindex="102">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Text</span>

                                <editor [(ngModel)]="currentSelected.text"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="<p>Initial content of the editor</p>"
                                        [init]="settings.tinyMceInit"
                                >
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Kategorie 1</span>

                                <select [(ngModel)]="currentSelected.category1"
                                        id="currentSelectedCategory1"
                                        style="width:100%;"
                                        tabindex="104">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of dropdownDataCategory" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td>
                                <span class="formCaption">Kategorie 2</span>

                                <span app-suggest-text-input
                                      [(model)]="currentSelected.category2"
                                      id="currentSelectedCategory2"
                                      endpoint="template/category/suggest"
                                      tabindex="5">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="form-group">
                                    <label for="file">Choose File</label>
                                    <input type="file"
                                           id="file"
                                           (change)="handleFileInput($event.target.files)">
                                </div>
                            </td>
                        </tr>


                        <tr>
                            <td colspan="2">
                                <span><img style="width:150px;+" [src]="currentSelected.document_filepath" /></span>
                                <button (click)="save()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- ENTERNEN WENN FERTIG -->
                <div class="form" style="background: #444444; left:10px; right:10px; border: 1px solid #444444; overflow: auto; height:30px; position: absolute; bottom:410px;">
                    <button (click)="helpTab = 0" style="margin-top: 3px; margin-left: 10px;">Hilfe</button>
                    <button (click)="helpTab = 1" style="margin-top: 3px;">Debug Data</button>
                </div>

                <div style="background: #ffe38e; left:10px; right:10px; border: 1px solid #444444; overflow: auto; height:400px; position: absolute; bottom:10px;">
                    <div *ngIf="helpTab==0" style="margin: 20px;">
                        <b>WICHTIG:</b> Wenn diese Datei im ausgelieferten Zustand genutzt wird, kann sie nach der
                        Anpassung der Rest Endpunkte
                        Inkonsistenz in der Datenbank erzeugen da nur ID und NAME beim Speichern berücksichtigt werden!
                        Um weitere Datenfelder
                        zu schreiben muss das Formular entsprechend erweitert werden.<BR><BR>
                        <B>TODO: </B>Folgende Punkte dienen als Leitfaden zur Nutzung des Scriptes. Bitte alle
                        nacheinander abarbeiten.<BR>
                        Löschen Sie die fertiggestellten Punkte aus der Quelldatei um die Übersicht zu behalten und
                        nichts zu vergessen.<BR>
                        <BR><B>Tipp:</B> Am besten für mindestens jeden durchnummerierten Hauptpunkt ein Commit in Git
                        erstellen.
                        <BR><BR><B>Namen:</B> Alle Name müssen immer nach den entsprechenden Feldnamen der Datenbank
                        Tabelle vergeben werden. Zum Beispiel ID und NAMEN Felder bei HTML Elementen oder Variablen in
                        PHP oder JavaScript.
                        <BR><BR><B>Style Klassen:</B> Je nachdem ob und wieviele Kopf oder Fusszeilen die beiden
                        Bereiche Besitzen muss dementsprechend in den SPANs rightContainerDefinition und
                        leftContainerDefinition die entsprechenden CSS Klassen gesetzt werden.
                        Diese sind headerNone, header1Row, header2Row, header3Row, header4Row, footerNone und
                        footer1Row.
                        <ol>
                            <li style="list-style-type: decimal; font-size: 1.3em;">Anlegen der Dateien für das neue
                                Modul.
                            </li>
                            <ul>
                                <li>Kopieren der beiden Dateien templateController.js und template.html.
                                    Die html Datei in den entsprechenden Modul Ordner kopieren. Der Kontroller bleibt in
                                    app.
                                </li>
                                <li>Namen beider Dateien an das neue Modul anpassen. Die Regeln zur Namens Vorgaben aus
                                    dem Coding Guide beachten!
                                </li>
                                <li>Die .js Datei in der datei view.php einbinden.</li>
                                <li>In der script.js einen Angular Route für die neue Seite anlegen.</li>
                                <li>Die route aufrufen und <B>die Funktion testen!</B></li>
                            </ul>
                            <li style="list-style-type: decimal; font-size: 1.3em;">Anpassung der Liste.</li>

                            <ul>
                                <li>Definieren welche Datenfelder in der Liste angezeigt werden sollen.</li>
                                <li>Rest endpunkt /list anpassen das diese Daten geliefert werden und Endpunkt testen.
                                </li>
                                <li>Die Spalten in den ng-repeat Block der Liste einfügen.</li>
                                <li>Tabellen Kopf Beschriftung anpassen.</li>
                                <li>Löschen Bedingung in der Liste über ng-show setzen oder Button komplett entfernen.
                                    Dann auch den Tabellenkopf anpassen.
                                </li>
                                <li><B>Die Funktion testen! Auch das löschen der Daten aus der Datenbank prüfen.</B>
                                </li>
                            </ul>

                            <li style="list-style-type: decimal; font-size: 1.3em;">Anpassung der Suche.</li>
                            <ul>
                                <li>Falls keine Suche gewünscht wird dann das Formular komplett entfernen und die Höhe
                                    des Listenkopfes laut UI Design Guide anpassen.
                                </li>
                                <li>Gewünschte Suchfelder dem Suchbereich hinzufügen.</li>
                                <li>Prüfen das die Suchdaten bei der Rest Anfrage mitgesendet werden.</li>
                                <li>Rest Endpunkt /list anpassen das die Suchparameter berücksichtigt werden.</li>
                                <li><B>Die Funktion auch mit Kombinationen der Suchparameter testen!</B></li>
                            </ul>
                            <li style="list-style-type: decimal; font-size: 1.3em;">Daten und Formular erweitern zum
                                Bearbeiten und neu Anlegen der Datensätze
                            </li>
                            <ul>
                                <li>Formular um benötigte Felder erweitern.</li>
                                <li>Prüfen das die Daten bei der Rest Anfrage mitgesendet werden.</li>
                                <li>Die beiden Befehle zum Anlegen und Aktualisieren der Daten im Rest Endpunkt an die
                                    neuen Daten anpassen.
                                </li>
                                <li>Pflichtfelder definieren, markieren (*) und eine Fehlerabfrage Implementieren.
                                    Eventuell mit Message Box bei speziellen Problemen Informieren sonst die
                                    Fehlerhaften oder leeren Felder laut UI Design Guide farblich Hervorheben.
                                </li>
                                <li><B>Die Funktion testen! Auch die geschriebenen Daten in der Datenbank prüfen und ein
                                    besonderes Augenmerk auf die Fehleranzeige der Pflichtfelder legen.</B></li>
                            </ul>
                            <li style="list-style-type: decimal; font-size: 1.3em;">Aktionen wenn gewünscht
                                implementieren
                            </li>
                            <ul>
                                <li>Gewünschte Aktionen der Dropdown Liste hinzufügen und diese Implementieren.</li>
                                <li><B>Die Funktion testen!</B></li>
                            </ul>
                            <li style="list-style-type: decimal; font-size: 1.3em;">Abschließende Arbeiten.</li>
                            <ul>
                                <li>Den im HTML Template als <B>ENTERNEN WENN FERTIG </B>kommentierten Bereich
                                    entfernen.
                                </li>
                                <li>Wenn benötigt weitere Sonderfunktionalität implementieren.</li>
                                <li>Wenn keine checkboxe Aktionen Implementiert wurden dann den entsprechenden Code
                                    sowohl aus der js als auch aus der HTML Datei entfernen.
                                </li>
                                <li>Wenn alles funktioniert die javascript Modul Datei in die Script.js Datei überführen
                                    und die Modul Datei entfernen. Auch die Verlinkung auf die alte Modul js Datei aus
                                    der view.php entfernen.
                                </li>
                                <li><B>Kompletter abschließender Test des neuen Moduls!</B></li>
                            </ul>
                        </ol>
                    </div>

                    <div *ngIf="helpTab==1" style="margin: 20px;">
                        <b>Mit den checkboxen verbundene scope Daten (checkboxData)</b><BR>
                        <HR>
                        <ol>
                            <li *ngFor="let obj of checkboxData">{{obj | json}}</li>
                        </ol>
                        <HR>
                        <B>Scope Daten hinter der Liste (data)</B><BR>
                        <HR>
                        <ol>
                            <li *ngFor="let obj of data">{{obj | json}}</li>
                        </ol>
                    </div>
                </div>
                <!-- ENTERNEN WENN FERTIG ENDE-->

            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
