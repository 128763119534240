<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Firma > Rechte
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search()"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                           NAME
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>


                <table style="width:100%; margin-left: 0; margin-right: 0;" class="data_tabel" cellspacing="0" cellpadding="4" cellspacing="0">
                    <ng-template ngFor let-x [ngForOf]="data" let-i="index">
                        <tr *ngIf="(data[(i-1)] == undefined || data[(i-1)].module!=x.module)">
                            <td colspan="2" style="font-weight: bold; font-size: 1.2em; background-color: #dddddd">&nbsp;{{x.module}}</td>
                        </tr>
                        <tr [ngClass]="{'selected' : currentSelected.id == x.id}">
                            <td (click)="edit($event,x)">&nbsp;{{x.name}}</td>

                        </tr>
                    </ng-template>
                </table>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">
                    <span *ngIf="currentSelected.id > 0">Benutzerliste zur Rechtegruppe</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" >
                    <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                        <tr>
                            <th style="width: calc( 50% - 24px)">Nachname</th>
                            <th style="width: 25%">Vorname</th>
                            <th style="width: 25%">Benutzername</th>
                            <th style="width: 24px;"></th>
                        </tr>
                        <tr *ngFor="let x of userData"
                            id="userList">
                            <td (click)="edit($event,x)">{{ x.name }}</td>
                            <td (click)="edit($event,x)">{{ x.first_name }}</td>
                            <td (click)="edit($event,x)">{{ x.nick }}</td>
                            <td (click)="delete($event,x)"><img src="../design/icons/delete.png"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </as-split-area>
</as-split>

