import {Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges, ViewChild} from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../services/settings.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-process-view]',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.css']
})
export class ProcessViewComponent implements  OnInit, OnChanges {

  @Input() tid: any;
  @Input() list: any;
  @Input() mark: any;
  public processData;
  public processId;
  showInfosInProgress: boolean;
  quickMark: any;
  showFullscreen: string;
  hideIcons = false;
  formerMark: any;
  constructor(public http: HttpClient, public settings: SettingsService, public route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mark > 0) { this.scrollToElement(); }
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'tid':
            if (this.tid > 0 ) {
              this.loadProcess();
            }
            break;
        }
      }
    }
  }

  ngOnInit(): void {
    this.processData = { header: {}, data: []};
    if (this.tid > 0 ) {
        this.loadProcess();
      }
  }

  public loadProcess() {
    if ( this.tid < 2 ) {
      this.http.get<{status, count, data}>(
          this.settings.restBaseUrl + 'process/merge/result', this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.processData = data;
          });
    } else {

      this.http.get<{status, count, data}>(
          this.settings.restBaseUrl + 'process/' + this.list + '/' + this.tid, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.processData = data;
            if (this.mark > 0) { this.scrollToElement(); }
          });
    }
  }

  scrollToElement() {
    if (this.formerMark > 0) { this.unsetFormerMarkStyle(); }
    setTimeout(() => {
      document.getElementById('processEntry' + this.mark).scrollIntoView({behavior: 'smooth'});
      document.getElementById('markerArea' + this.mark).style.background = '#FFBA75';
      setTimeout(() => { this.formerMark = this.mark; }, 100);
    }, 300);
  }

  unsetFormerMarkStyle() {
    document.getElementById('markerArea' + this.formerMark).style.background = '#FFFFFF';
  }

  viewPrintView(x: any) {

  }

  openWorkingDocument(id: any, tid: any, typ: any) {

  }

  handleProcessAction(forward: string, x: any) {

  }

  addNewSubTasks(x: any) {

  }

  openAddress(user: PublicKeyCredentialUserEntity) {

  }

  sendToDMS($event: MouseEvent, a: any) {

  }

  viewFullscreen($event: MouseEvent, s: string, name: any) {

  }

  checkForInlineAttachments(attachemnts: any) {

  }

  viewInline($event: MouseEvent, attachemnts: any) {

  }

  toggleSubTaskState(t: any) {

  }

  saveSubTask(t: any) {

  }
}
