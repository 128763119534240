import { Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { map, filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {ModalService} from '../../_modal';

@Component({
  selector: '[app-billing-clock]',
  templateUrl: './billing-clock.component.html',
  styleUrls: ['./billing-clock.component.css']
})
export class BillingClockComponent implements OnInit, OnChanges {

  public dropdownDataBillingType: any;
  public workingUnits: any;
  public timerRunning: boolean;
  public timer: number;
  public currentBillingData: { unit: 'laden', divider: 60 };
  public uiElementId: string;
  public timeReadonly = true;
  public internalBillingType: any;
  public calculationStartTimestamp;
  public calculationPauseTimestamp;
  public calculationCumulatedPauseSeconds;

  @Input() time: any;
  @Input() autostart: any;
  @Input() billingType: any;
  @Input() mode: string;
  @Input() timeNoCharge: any;

  @Output() timeChange = new EventEmitter<number>();
  @Output() billingTypeChange = new EventEmitter<number>();
  @Output() timeWithoutCharge = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'time':
            if ( changes[propName].currentValue === changes[propName].previousValue) { return; } // nur wenn der change von außen komme.
            if ( changes[propName].currentValue === 0 && this.mode !== 'manual') {
              setTimeout(() => {
                clearInterval(this.timer);
                if(this.autostart==1) { this.changeTimer('start'); } else { this.changeTimer('stop');}
              }, 300);
            } else {
              this.calculationCumulatedPauseSeconds = 0;
              this.calculationStartTimestamp  = ((Math.floor(this.getCurrentTimestamp() / 1000 ) - this.time) * 1000);
            }
            break;
          case 'billingType':
            break;
        }
      }
    }
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    if(this.time === undefined) this.time = 0;
    this.calculationPauseTimestamp = 0;
  }

  ngOnInit(): void {
    this.http.post<any['data']>(this.settings.restBaseUrl + 'process/billingtype', {active: 1, currentCompany: 1}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataBillingType = data.data;

          // wenn von außen gesetzt dann prüfen und setzen sonst auf das erste
          if ( this.billingType > 0) {
            this.internalBillingType = this.billingType;
            this.currentBillingData = data.data[0];
            setTimeout(() => { // this will make the execution after the above boolean has changed
              // clearInterval(this.timer);
              this.changeSelect();
            }, 300);
          } else {
            this.internalBillingType = data.data[0].id;
            this.billingTypeChange.emit(data.data[0].id);
            this.currentBillingData = data.data[0];
          }
        }
    );
    const dateNow = new Date();
    this.uiElementId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    if (this.mode === 'manual') {
      this.timerRunning = false;
      this.timeReadonly = false;
    } else  {
      console.log('autostart ' + this.autostart);
      if(this.autostart==1) { this.changeTimer('start'); } else { this.changeTimer('stop');}
    }
    this.timeNoCharge = 0;
  }

  changeTimer(action: string) {
    console.log('changeTimer > ' + action);
    if ( action === 'start' ) {
      if ( this.calculationPauseTimestamp > 0) {
        const pauseTimeDifference = this.getCurrentTimestamp() - this.calculationPauseTimestamp;
        this.calculationPauseTimestamp = 0;
        this.calculationStartTimestamp += pauseTimeDifference;
      } else {
        this.calculationStartTimestamp = this.getCurrentTimestamp() - ( this.time * 1000 ) ;
      }
      this.timerRunning = true;
      this.timer = setInterval(() => {
        this.time = Math.floor((this.getCurrentTimestamp() - this.calculationStartTimestamp) / 1000);
        this.timeChange.emit( this.time );
        this.checkTimerToWorkunit();
      }, 1000 );
    } else if ( action === 'pause' ) {
      clearInterval(this.timer);
      this.timerRunning = false;
      this.calculationPauseTimestamp = this.getCurrentTimestamp();
    } else if ( action === 'reset' ) {
      clearInterval(this.timer);
      this.timerRunning = false;
      this.timeChange.emit(0);
      this.calculationStartTimestamp = 0;
      this.calculationPauseTimestamp = 0;
    } else if ( action === 'stop' ) {
      clearInterval(this.timer);
      this.timerRunning = false;
      this.calculationPauseTimestamp = 0;
      this.calculationStartTimestamp = 0;
      this.calculationPauseTimestamp = 0;
    }
  }

  getCurrentTimestamp() {
    return Date.now();
  }

  checkTimerToWorkunit() {
    this.workingUnits = Math.ceil(this.time / this.currentBillingData.divider );
  }

  changeSelect() {
    for (const bt of this.dropdownDataBillingType) {
      if ( bt.id === this.internalBillingType) {
        this.currentBillingData = bt;
        this.billingTypeChange.emit(this.internalBillingType);
        this.checkTimerToWorkunit();
      }
    }
  }

  changeToManualTime() {
    this.message.open('timeModeChange');
    setTimeout(function(){ document.getElementById('setTimeManual').focus();; }, 100);
  }

  setManualTime() {
    this.message.close('timeModeChange');
    this.changeTimer('pause');
    this.timeReadonly = false;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      document.getElementById('workingUnitsElement').focus();
    }, 100);
  }

  cancelManualTime() {
    this.message.close('timeModeChange');
  }

  setManualEntry() {
     if (this.mode !== 'manual') {
       if ( this.timeReadonly === true ) { return; }
       this.timeReadonly = true;
     }
     const newSecondsToSet = this.workingUnits * this.currentBillingData.divider;
     this.timeChange.emit( newSecondsToSet );
     this.timeWithoutCharge.emit(this.timeNoCharge);
  }
}
