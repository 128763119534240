import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-process-project-type-management',
  templateUrl: './process-project-type-management.component.html',
  styleUrls: ['./process-project-type-management.component.css']
})
export class ProcessProjectTypeManagementComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = { };
  public dropdownDataCategories = [];
  public projectTypeCategories = [];
  public categoryAtSelect;
  public currentSelectedTab = 1;
  public categoryAddId = -1;
  public billingTypes: any;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', description : '', fk_category: '0', active : '', sort : '' };
  }

  initSearchParameter(): void {
    this.searchParameter = {sort : 'sort', active : '1', text: '' };
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.http.post<{status, data, count}>(settings.restBaseUrl + 'process/category', {active: 1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.dropdownDataCategories = data.data;
        }
    );

    this.initSearchParameter();
  }

  ngOnInit() {
      // Abrechnungsarten laden
      this.http.post<{ status, message, data }>(this.settings.restBaseUrl + 'process/billingtype', {},this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.billingTypes = data.data;
          }
      );
    this.search(false);
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'process/project/types', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initCurrentSelected();
    this.initSearchParameter();
    this.search(false);
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
        document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    }
    if (this.currentSelected.fk_category === '0' || this.currentSelected.fk_category == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Standardkategorie aus.');
      return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'process/project/types' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const newId = data.message;
          this.setStandardCategory(newId);
          this.search(false);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        }
    );
  }

    setStandardCategory(id) {
        // tslint:disable-next-line:variable-name
        let fk_projectType;
        let endpointIdAdd = '';
        if (this.currentSelected.id > 0) {
            fk_projectType = this.currentSelected.id;
            if (this.categoryAtSelect != null && this.categoryAtSelect > 0) {
                endpointIdAdd = '/' + this.currentSelected.id;
            }
        } else { fk_projectType = id; }


        const categoryData = {
            select: 'project_type',
            fk_team: 0,
            fk_category: this.currentSelected.fk_category,
            fk_project_type: fk_projectType,
            base_category: 1
        };

        this.http.put<any>(this.settings.restBaseUrl + 'process/category/link' + endpointIdAdd, categoryData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
            }
        );
    }


  edit($event: MouseEvent, item: any): void  {
    this.projectTypeCategories = [];
    this.getProjectTypeCategories(item.id);
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'process/project/types/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.categoryAtSelect = this.currentSelected.fk_category;
          if (this.currentSelected.fk_category == null) {
                this.currentSelected.fk_category = '0';
          }
          this.showNewItemForm = false;
          if (this.currentSelectedTab === 1) {
              setTimeout(() => {
                  document.getElementById('currentSelectedName').focus();
              }, 300);
          }
        });
  }

    getProjectTypeCategories(id) {
        this.http.post<any[]>(this.settings.restBaseUrl + 'process/category/link', { fk_project_type: id }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.projectTypeCategories = data;
            }
        );
    }

    deleteCategoryLink(item) {
        const deleteItems = window.confirm('Soll diese Kategoriezuordnung wirklich gelöscht werden?');
        if (deleteItems) {
            this.http.delete<any[]>(this.settings.restBaseUrl + 'process/category/link/' + item.linkId, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.getProjectTypeCategories(this.currentSelected.id);
                    this.categoryAddId = -1;
                });
        }
    }

    putProjectTypeCategoryLink() {
        let base = 0;
        if (this.projectTypeCategories.length < 1) {
            base = 1;
        }
        const linkData = {
            fk_team: 0,
            fk_project_type: this.currentSelected.id,
            fk_category: this.categoryAddId,
            base_category: base
        };

        this.http.put<any[]>(this.settings.restBaseUrl + 'process/category/link', linkData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.getProjectTypeCategories(this.currentSelected.id);
                this.categoryAddId = -1;
            });
    }

    checkProjectTypeCategoryLinK() {
        if (this.categoryAddId < 1 ) {
            alert('Bitte wählen Sie eine Kategorie aus.')
            return;
        } else {
            let categoryCheck = false;
            this.projectTypeCategories.forEach((element) => {
                if (element.fk_category === this.categoryAddId) {
                    categoryCheck = true;
                }
            });
            setTimeout(() => {
                if (categoryCheck) {
                    alert('Diese Kategorie befindet sich bereits in der Liste.');
                    return;
                } else { this.putProjectTypeCategoryLink(); }
            }, 200);
        }
    }

    changeMainCategory(item) {
        this.http.put<any[]>(this.settings.restBaseUrl + 'process/category/link/changeMain/' + item.linkId, { fk_project_type: this.currentSelected.id }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.projectTypeCategories.forEach((element) => {
                    element.base_category = 0;
                });
                item.base_category = 1;
                this.currentSelected.fk_category = item.fk_category;
            });
    }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'process/project/types/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'process/project/types/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'process/project/types/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }

    changeTabButton(tabId): void {
        this.currentSelectedTab = tabId;
    }
}
