<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">

        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Vorgänge > Arbeitsdokumente manuell Zuweisen

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1">

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME" sortColumn="name"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td (click)="edit(x)">{{ x.name }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="currentSelected.id > 0">Arbeitsdokument zuweisen</span>
                </div>
            </div>

            <div id="rightContainerContent" style="overflow-y: hidden;">
                <div class="form" style="margin-left:10px;">
                    <div style="height:45px; position: absolute; left:0px; right:0px; top:0px;">
                        <span class="form">
                            <select *ngIf="currentSelected.id > 0" name="processId" id="processId" class="form" style="width: 300px; margin-top: 7px; margin-left: 10px;" [(ngModel)]="selectedDocumentId" tabindex="101">
                                <option value="0">Vorgangsnummer wählen</option>
                                <option *ngFor="let x of openProcessWorkDocuments" value="{{x.id}}">{{x.name}}</option>
                            </select>

                            <button *ngIf="currentSelected.id > 0" (click)="save()" style="width: 100px; margin-left: 5px;" tabindex="102">
                                Zuweisen
                            </button>
                        </span>
                    </div>
                    <div style="position: absolute;top:40px;left:0px;right:0px;bottom:0px;">
                        <iframe [src]="fileToLoad | safeurl" id="documentView" name="documentView" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>
