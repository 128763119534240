import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { ErpAccountSystemManagementComponent } from './erp-account-system-management/erp-account-system-management.component';
import { ErpCostCentreComponent } from './erp-cost-centre/erp-cost-centre.component';
import { ErpNumberRangeComponent } from './erp-number-range/erp-number-range.component';
import { ErpPrintTemplateComponent } from './erp-print-template/erp-print-template.component';
import { ErpProductGroupComponent } from './erp-product-group/erp-product-group.component';
import { ErpReceiptTypeComponent } from './erp-receipt-type/erp-receipt-type.component';
import { ErpStoreComponent } from './erp-store/erp-store.component';
import { ErpUnitComponent } from './erp-unit/erp-unit.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ErpOutgoingBillComponent } from './erp-outgoing-bill/erp-outgoing-bill.component';
import {PipesModule} from '../pipes/pipes.module';

@NgModule({
  declarations: [
    ErpAccountSystemManagementComponent,
    ErpCostCentreComponent,
    ErpNumberRangeComponent,
    ErpPrintTemplateComponent,
    ErpProductGroupComponent,
    ErpReceiptTypeComponent,
    ErpStoreComponent,
    ErpUnitComponent,
    ErpOutgoingBillComponent,
  ],
    imports: [
        CommonModule, HttpClientModule, FormsModule, BrowserModule, UiModule, EditorModule,
        AngularSplitModule,
        ContextMenuModule, PipesModule
    ]
})
export class ErpModule { }
