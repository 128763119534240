<DIV style="display: inline-block; vertical-align: middle; margin-bottom: 1px; width:100%;" id="{{overlayId}}TextSuggestBoxPanel" >

    <table style="width:100%;" cellpadding="0" cellspacing="0">
        <tr>
            <td class="form">
                <input type="text"
                       id="{{overlayId}}TextSuggestBox"
                       [disabled]="disabled"
                       name="{{overlayId}}TextSuggestBox"
                       style="width:100%; height:20px; font-size: 12px; line-height: 21px; vertical-align: bottom; padding-left: 4px; border:1px solid #ceced2; border-right: 3px #ffffff;-webkit-border-top-left-radius: 3px;
                        -webkit-border-bottom-left-radius: 3px;
                        -moz-border-radius-topleft: 3px;
                        -moz-border-radius-bottomleft: 3px;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;z-index: 10;"
                       tabindex="{{settabindex}}"
                       (click)="clear()"
                       [(ngModel)]="searchText"
                       (keyup)="search($event)"
                       (blur)="checkExistence()"
                       [ngClass]="{ 'error' : mark }">
            </td>
            <td style="width:14px;">
                <button style="margin-top: 0px; margin-left:-2px; width:16px; min-width: 16px; max-width: 16px; height:24px; min-width: 14px !important; z-index: 20;border: 1px solid #ceced2;" ng-disabled="ngDisabled">
                    <img (click)="openSelectWindow()" src="../design/icons/sort_arrow_down.png" style="width:20px; height: 20px; margin-left:-9px;" data-context-menu="datePickerContextMenu">
                </button>
            </td>
        </tr>
    </table>

    <div id="{{overlayId}}" class="shadow" style="overflow: hidden;width:0px; height:fit-content; max-height: 200px; visibility:hidden; overflow: auto; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;">
        <div style="width: 100%;height: 100%;overflow-y: auto;">
            <div class="hover" *ngFor="let x of data;let xindex=index;" style="width:100%; height: 20px; border-bottom: 1px solid #dedede;" (click)="select(x);" >
                <div [ngClass]="{ 'selected' : xindex == markedWithKeyboard }" style="width:100%; height:20px;">
                    <span style="display: inline-block; margin-top:3px; margin-left:10px;">{{x.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" ></DIV>
</DIV>
