import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TopBarComponent } from './top-bar/top-bar.component';
import { ContactManagerComponent } from './contact-manager/contact-manager.component';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { HttpClientModule } from '@angular/common/http';
import { SortSwitchComponent } from './sort-switch/sort-switch.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { ProductGroupSelectorComponent } from './product-group-selector/product-group-selector.component';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SuggestTextInputComponent } from './suggest-text-input/suggest-text-input.component';
import { UitemplateComponent } from './uitemplate/uitemplate.component';
import { InlineTextEditorComponent } from './inline-text-editor/inline-text-editor.component';
import { ResizeIframeComponent } from './resize-iframe/resize-iframe.component';
import { PipesModule } from '../pipes/pipes.module';
import { OptionsComponent } from './options/options.component';
import { MenuComponent } from './menu/menu.component';
import { TopBarNotificationComponent } from './top-bar-notification/top-bar-notification.component';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { TutorialComponent } from './tutorial/tutorial.component';
import { ProcessAttachmentIconComponent } from './process-attachment-icon/process-attachment-icon.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from '../_modal';
import { MyDefaultTooltipOptions } from '../tooltip-options';
import { CalendarPickerComponent } from './calendar-picker/calendar-picker.component';
import { BillingClockComponent } from './billing-clock/billing-clock.component';
import { ProcessTimelineComponent } from './process-timeline/process-timeline.component';
import { KanbanBoardComponent } from './kanban-board/kanban-board.component';
import { ListSearchFilterComponent } from './list-search-filter/list-search-filter.component';
import { AttachmentMenuComponent } from './attachment-menu/attachment-menu.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormComponent } from './form/form.component';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import {ProcessProgressComponent} from './process-progress/process-progress.component';

@NgModule({
    declarations: [
        TopBarNotificationComponent,
        TopBarComponent,
        ContactManagerComponent,
        SortSwitchComponent,
        AdminMenuComponent,
        ProductGroupSelectorComponent,
        TreeViewComponent,
        DatePickerComponent,
        DropdownComponent,
        SuggestTextInputComponent,
        UitemplateComponent,
        InlineTextEditorComponent,
        ResizeIframeComponent,
        OptionsComponent,
        MenuComponent,
        TutorialComponent,
        ProcessAttachmentIconComponent,
        CalendarPickerComponent,
        BillingClockComponent,
        ProcessTimelineComponent,
        KanbanBoardComponent,
        ListSearchFilterComponent,
        AttachmentMenuComponent,
        CheckboxComponent,
        FormComponent,
        CalendarViewComponent,
        CalendarComponent,
        TimePickerComponent,
        ProcessProgressComponent
    ],
    imports: [
        CommonModule, FormsModule, DragDropModule, HttpClientModule, EditorModule, PipesModule, BrowserModule, BrowserAnimationsModule, ModalModule,
        TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
        ContextMenuModule
    ],
    exports: [
        TopBarComponent,
        ContactManagerComponent,
        SortSwitchComponent,
        AdminMenuComponent,
        ProductGroupSelectorComponent,
        TreeViewComponent,
        DatePickerComponent,
        DropdownComponent,
        SuggestTextInputComponent,
        UitemplateComponent,
        InlineTextEditorComponent,
        ResizeIframeComponent,
        OptionsComponent,
        TutorialComponent,
        ProcessAttachmentIconComponent,
        BillingClockComponent,
        ProcessTimelineComponent,
        ListSearchFilterComponent,
        AttachmentMenuComponent,
        CheckboxComponent,
        FormComponent,
        CalendarViewComponent,
        CalendarPickerComponent,
        TimePickerComponent,
        ProcessProgressComponent
    ]
})
export class UiModule { }
