<DIV style="display: inline-block; vertical-align: middle; margin-bottom: 1px; margin-right: -1px; width:100%;" id="{{overlayId}}Panel">

    <table id="{{overlayId}}DropdownTable" style="width: 100%" cellspacing="0" cellpadding="0" border="0">
        <tr>
            <td>
                <div *ngIf="!searchMode"
                     (click)="activateSearchMode()"
                     [ngClass]="{'suggest-dropdown-short': inputClass === true, 'suggest-dropdown-long': inputClass === false}"
                     style="height:22px !important; font-weight: bold;">
                    <span class="dropdownDisplaySpan" [innerHTML]="searchText | safe"></span>

                </div>
                <input *ngIf="searchMode"
                       id="{{overlayId}}Dropdown"
                       name="{{overlayId}}Dropdown"
                       [ngClass]="{'suggest-dropdown-short': inputClass === true, 'suggest-dropdown-long': inputClass === false}"
                       [(ngModel)]="searchText"
                       (ngModelChange)="handleModelChange($event)"
                       (keyup)="handleKeyboardInput($event)"
                       (blur)="endSearch()"
                       (focus)="cleatCurrentText()"
                       tabindex="{{tabIndexValue}}">
            </td>
            <td style="width:22px;">
                <span class="form">
                <button *ngIf="!manualAdd" (click)="clickOnOpenButton()" style="margin-top: 0px;  margin-left:-5px; background-color: #eeeeee !important; width:25px; height:25px; min-width: 25px !important; z-index: 20;"><img src="../design/icons/bullet_arrow_down.png" style="width:16px; height: 16px; margin-left:-1px;margin-top: 1px;" data-context-menu="datePickerContextMenu"></button>
                <button *ngIf="!manualAdd && addMode" (click)="toggleManualAdd()" style="margin-top: 0px; margin-left:-5px; background-color: #eeeeee !important; width:25px; height:25px; min-width: 25px !important; z-index: 20;"><img src="../design/icons/add.png" style="width:16px; height: 16px; margin-left:-1px;margin-top: 2px;" data-context-menu="datePickerContextMenu"></button>
                <button *ngIf="manualAdd && addMode || manualAdd && !textMode" (click)="putItem(searchText)" style="margin-top: 0px; margin-left:-5px; background-color: #eeeeee !important; width:25px; height:25px; min-width: 25px !important; z-index: 20;"><img src="../design/icons/disk.png" style="width:16px; height: 16px; margin-left:-1px;margin-top: 2px;" data-context-menu="datePickerContextMenu"></button>
                <button *ngIf="manualAdd" (click)="toggleManualAdd()" style="margin-top: 0px; margin-left:-5px; background-color: #eeeeee !important; width:25px; height:25px; min-width: 25px !important; z-index: 20;"><img src="../design/icons/arrow_undo.png" style="width:16px; height: 16px; margin-left:-1px;margin-top: 2px;" data-context-menu="datePickerContextMenu"></button>
                </span>
            </td>
        </tr>
    </table>
    <div id="{{overlayId}}" class="shadow" style="overflow: auto;height:fit-content; max-height: 50%; visibility:hidden; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999; margin-top: -1px; margin-left: 3px;">
        <table class="data_tabel" style="width: 100%;" cellspacing="0" cellpadding="4">
            <tr [ngClass]="{ true: 'selected' }[x.selected]" *ngFor="let x of filteredDropdownDataSource"  (click)="handleClickEvent(x);">
                <td>
                    <span *ngIf="x.icon"><img src="{{x.icon}}"></span>
                    <span *ngIf="x.img"><img src="{{x.img}}"></span>
                    <span *ngIf="mark && mark.field" style="display: inline-block; width:22px;">
                        <img style="vertical-align: text-bottom;" src="{{mark.condition[x[mark.field]].icon}}">
                    </span>

                    {{x[alternateName]}}</td>
            </tr>
        </table>
    </div>


    <DIV (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}" >
    </DIV>
</DIV>
