import { Injectable } from '@angular/core';
import {SettingsService} from "./settings.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public httpOptions;
  public modules;
  public userData;
  public userSettings;
  public userRights;

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.loadUserData();
    this.loadModuleData();
    this.loadUserSettings();
    this.loadUserRights();
  }

  loadUserData() {

  }

  loadModuleData() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'module', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.modules = data;
        }
    );
  }

  loadUserSettings() {

  }

  loadUserRights() {

  }

  public getUserId() {
    return 1;
  }

  public getModules() {
    return this.modules;
  }

  public getModuleActive(moduleId) {
    this.modules.forEach ((item) => {
      if ( item.id === moduleId) {
        return item.active;
      }
    });
    return this.modules.size();
  }
}
