<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header2Row footerNone" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    Vorgangsmanagement > Arbeitsbeleg abschließen
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th>
                            Vorgangstitel - Arbeitsbeleg Typ - Vorgangsnummer - Belegnummer
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : selectedDocument.id == x.id}"
                        id="typeList">

                        <td (click)="edit(x)">{{ x.name }}</td>
                    </tr>
                </table>
                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="selectedDocument.tid > 0">Abschlussdaten eingeben</span>
                </div>
            </div>

            <div id="rightContainerContent" style="overflow-y: hidden;">
                <div *ngIf="selectedDocument.tid > 0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Abschlusstext*</span>
                                <br>
                                <textarea   [(ngModel)]="currentSelected.text"
                                            id="currentSelectedText"
                                            rows="5"
                                            class="form"
                                            style="width: calc(100% - 10px); border: 1px solid #ceced2; border-radius: 3px; resize: none;"
                                            tabindex="101">
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="width: 100%; margin-bottom: -17px;" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td>
                                            <span class="formCaption">Datum*</span>
                                        </td>
                                        <td>
                                            <span class="formCaption" style="padding-left: 20px;">Arbeitszeit*</span>
                                        </td>
                                        <td>
                                            <span class="formCaption">Erledigt von*</span>
                                        </td>
                                        <td>
                                            <span class="formCaption"> </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span app-date-picker
                                                  [(model)]="currentSelected.date"
                                                  id="currentSelectedDate"
                                                  style="float: left;"
                                                  tabindex="102">
                                            </span>
                                        </td>
                                        <td>
                                            <span app-billing-clock  [(time)]="currentSelected.time_duration" [(billingType)]="currentSelected.fk_process_billing_type" [(timeNoCharge)]="currentSelected.time_no_charge" [mode]="'manual'" style="width: 100%; float: left; margin-left: 16px;"></span>
                                        </td>
                                        <td>
                                            <select [(ngModel)]="currentSelected.engineer"
                                                    id="currentSelectedengineer"
                                                    style="width: calc(100% - 4px);"
                                                    tabindex="105">
                                                <option value="-1">Bitte wählen</option>
                                                <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.name}}</option>
                                            </select>
                                        </td>
                                        <td class="form">
                                            <button (click)="save()" style="width: 100px; float: right; margin-right: 0;" tabindex="106">
                                            <img src="../design/icons/disk.png"> Speichern
                                        </button></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="position: absolute;top:180px;left:0px;right:0px;bottom:0px; border-top: 1px solid gray;">
                    <iframe [src]="fileToLoad | safeurl" id="documentView" name="documentView" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="withoutWorkingTime" class="jw-modal shadow">
            <div class="modalHeader">
                <div>
                    <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Achtung!
                </div>
            </div>
            <div style="margin:20px; line-height: 1.4;" class="form">
                Sind Sie sicher das der Arbeitsbeleg ohne Arbeitszeit abgeschlossen werden soll?<br>
            </div>
            <div class="modalFooter form" style="text-align: right">
                <button  (click)="message.close('withoutWorkingTime');" style="margin-top: 3px;margin-right: 3px;">Zurück</button>
                <button  (click)="doSaveWithoutWorkingTime();" style="margin-top: 3px;margin-right: 3px;">Ja</button>
            </div>
</jw-modal>
