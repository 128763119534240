<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <span app-admin-menu></span>

        <div id="leftContainerDefinition" class="header3Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    ERP > Nummernkreise

                    <span *ngIf="searchResultCount>data.length" style="float:right; margin-right:10px;">{{data.length}} von {{searchResultCount}} Einträgen</span>
                    <span *ngIf="searchResultCount<=data.length" style="float:right; margin-right:10px;">{{searchResultCount}} Einträge</span>
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:140px;"
                           tabindex="1">

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.year"
                            style="width:80px;"
                            tabindex="2">
                        <option value="">Alle Jahre</option>
                        <option *ngFor="let x of dropdownDataYears; let i = index" [value]="x">{{x}}</option>
                    </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="NAME"  sortColumn="name"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="BESCHREIBUNG" sortColumn="description"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="PREFIX" sortColumn="add_before"></span>
                        </th>

                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" ng-show="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width:150px;">&nbsp;{{ x.name }}</td>

                        <td (click)="edit($event,x)">{{ x.description }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.add_before }}</td>


                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:5px; margin-top:6px;">
                    <span *ngIf="showNewItemForm">Nummernkreis anlegen</span>
                    <span *ngIf="!showNewItemForm && currentSelected.id > 0">Nummernkreis bearbeiten</span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="showNewItemForm || currentSelected.id>0" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="101" >
                            </td>

                            <td>
                                <span class="formCaption">Gid</span><br>
                                <input type="text"
                                       [(ngModel)]="currentSelected.gid"
                                       id="currentSelectedGid"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="102" >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Beschreibung</span>

                                <textarea type="text"
                                          [(ngModel)]="currentSelected.description"
                                          id="currentSelectedText"
                                          style="width:calc(100% + 1px); border: 1px solid #ceced2; border-radius: 3px;"
                                          rows="4"
                                          tabindex="103" >
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Prefix</span>

                                <input type="text"
                                       [(ngModel)]="currentSelected.add_before"
                                       id="currentSelectedAddBefore"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="104" >
                            </td>
                            <td>
                                <span class="formCaption">Endung</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.add_after"
                                       id="currentSelectedAddAfter"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="105" >
                            </td>
                        </tr>
                        <tr>
                        <td>
                            <span class="formCaption">Nummernlänge</span>

                            <input type="text"
                                   [(ngModel)]="currentSelected.number_length"
                                   id="currentSelectedNumberLength"
                                   class="cp-textbox"
                                   style="width:100%;"
                                   tabindex="106" >
                        </td>
                        <td>
                            <span class="formCaption">Jahr</span>
                            <input type="text"
                                   [(ngModel)]="currentSelected.year"
                                   id="currentSelectedNumberCounter"
                                   class="cp-textbox"
                                   style="width:100%;"
                                   tabindex="107" >
                        </td>
                    </tr>
                        <tr>
                            <td colspan="2" class="form">
                                <button (click)="save()" style="width: 100px; float: right; margin-right: -7px;" tabindex="108">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>
</context-menu>
