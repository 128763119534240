import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { KeysPipe } from './keys.pipe';
import { SafeurlPipe } from './safeurl.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { PrettyjsonPipe } from './prettyjson.pipe';
import { SearchfilterPipe } from './searchfilter.pipe';


@NgModule({
  declarations: [SafePipe, KeysPipe, SafeurlPipe, PrettyjsonPipe, SearchfilterPipe],
  imports: [
    CommonModule, BrowserModule,
  ],
  exports: [
    SafePipe, KeysPipe, SafeurlPipe, PrettyjsonPipe, SearchfilterPipe
  ],
})
export class PipesModule { }
